<template>

  <div class="bg-principal bg-white mt-2">

    <div class="row col-12 col-sm-10 mx-auto px-0 p-sm-3 pb-sm-10">

      <!-- CARDS DEUDAS DESK -->
      <div class="col-12 col-sm-10 col-lg-12 mx-auto order-1 order-sm-1 p-0 p-sm-3">

        <div class="d-none d-sm-flex row">

          <v-card class="col-12 col-sm-12 col-lg-3 my-3 mx-auto" elevation="5"
                  v-for="(item, index) of statistics" :key="index"
                  style="border-radius: 10px"
          >
            <v-card-text class="px-sm-0 px-xl-1 text-center">
              <p class="text-blue card-number gotham-ultra">{{ item.amount }}</p>
              <p class="text-gray card-text open-sans-regular" v-html="item.desc"></p>
            </v-card-text>
          </v-card>

        </div>


        <div class="d-none d-sm-flex row col-12 col-sm-10 col-lg-8 mx-auto p-0 p-sm-3">
          <p class="section-title open-sans-italic text-blue name-title">Fabiola liquidó sus deudas</p>
          <p class="ml-auto section-title open-sans-bold-italic text-blue desc-title">con 70% de descuento</p>
        </div>


        <!-- CARDS DEUDAS MOBILE -->
        <div class="d-sm-none mb-8 mb-sm-0">
          <carousel-statistics :items="statistics"/>
        </div>

        <div class="d-sm-none mb-8 mb-sm-0">
          <p class="section-title open-sans-italic text-blue name-title">Fabiola liquidó sus deudas</p>
          <p class="ml-auto section-title open-sans-bold-italic text-blue desc-title">con 70% de descuento</p>
        </div>

      </div>



      <!-- VIDEO -->
      <div class="col-12 col-sm-10 col-lg-7 mx-auto order-2 order-sm-2 p-0">

        <!-- <div class="d-none d-sm-flex row col-12 col-sm-10 col-lg-8 mx-auto p-0 p-sm-3">
          <p class="section-title open-sans-italic text-blue">Fabiola liquidó sus deudas</p>
          <p class="ml-auto section-title open-sans-bold-italic text-blue">con 70% de descuento</p>
        </div> -->

        <video
            v-on:play="play=true"
            controls
            width="100%"
            class="d-sm-none"
            src="/videos/entrevista_v.mp4"
            poster="/img/financial_freedom/images/v_women.png"
        ></video>

        <div class="d-block d-sm-none row col-12 col-sm-10 col-lg-8 mx-auto p-0 p-sm-3" :class="!play? 'v-phone' :''">
          <p class="section-title open-sans-italic" :class="play ? 'd-none' :''">
            Fabiola liquidó sus deudas <br> <span class="ml-auto section-title open-sans-bold-italic">con 70% de descuento</span>
          </p>
        </div>

        <video
            controls
            autoplay
            muted
            width="100%"
            class="d-none d-sm-block video-size"
            src="/videos/entrevista_h.mp4"
        ></video>

      </div>


      <!-- REVIEWS -->
      <div class="col-12 col-sm-10 col-lg-5 mx-auto order-2 order-sm-2 p-0 p-sm-3 py-5">

        <div class="d-none d-sm-flex row" v-for="(item, index) of reviews" :key="index">
          <div class="col-4 col-sm-8 col-lg-4 mx-auto text-center">
            <img :src="item.picture" :alt="index">
          </div>
          <div class="col-8 col-sm-12 col-lg-8">
            <p class="p-0 text-gray simple-text open-sans-regular">{{ item.comment }} <br> <span
                class="text-blue simple-text open-sans-bold-italic">{{ item.person }}</span></p>
          </div>
        </div>

        <div class="d-sm-none">
          <carousel-reviews :items="reviews"/>
        </div>

      </div>


    </div>

  </div>

</template>

<script>
import CarouselStatistics from "@/views/components/financial_freedom/CarouselStatistics";
import CarouselReviews from "@/views/components/financial_freedom/CarouselReviews";

export default {
  name: "Reviews",
  components: {CarouselReviews, CarouselStatistics},
  data: () =>
      ({
        play: false,

        reviews:
            [
              {
                comment:
                    'Increíble asesoría, el servicio fue\n' +
                    'excelente, tan bueno que el\n' +
                    'programa lo concluí con anticipación,\n' +
                    'logrando casi un 70% de descuento.',
                person: 'Alexander Mendez',
                picture: '/img/financial_freedom/images/alexander_mendez.png'
              },
              {
                comment:
                    'Una excelente atención de inicio a fin.\n' +
                    'los ejecutivos siempre tienen una\n' +
                    'solución satisfactoria y rápida a la\n' +
                    'necesidades de cada cliente.',
                person: 'Eduardo Albor',
                picture: '/img/financial_freedom/images/eduardo_albor.png'
              },
            ],
        statistics:
            [
              {
                amount: '+12,000',
                desc: 'personas han <br> liquidado sus deudas'
              },
              {
                amount: '+80',
                desc: 'millones de <br> deudas liquidadas'
              },
              {
                amount: '+80%',
                desc: 'en descuentos en <br> el programa'
              }
            ]
      })
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';


.name-title{
  text-align: initial;
  margin-bottom: 0;
  @media(min-width: $tablet), (min-width: $large-desktop){
    text-align: center;
  }
}
.desc-title{
  text-align: center;
  padding-left: 28%;
}

.video-size{
  padding: 0 50px 50px 50px;
}

.v-phone {
  position: absolute;
  width: 90%;
  left: 0;
  right: 0;
  bottom: 4rem;
  margin: auto;
}


@font-face {
  font-family: OpenSans-Italic;
  src: url(/fonts/HomepageNew/OpenSans-Italic.ttf);
}

@font-face {
  font-family: OpenSans-Bold-Italic;
  src: url(/fonts/HomepageNew/OpenSans-BoldItalic.ttf);
}

/*Fonts*/

.open-sans-regular {
  font-family: OpenSans-Regular;
}

.open-sans-italic {
  font-family: OpenSans-Italic;
}

.open-sans-bold-italic {
  font-family: OpenSans-Bold-Italic;
}

.gotham-ultra {
  font-family: Gotham-Ultra;
}

/*Colors*/
.text-gray {
  color: #808080;
}

.text-blue {
  color: #2a3d99 !important;
}

@media (max-width: 575px) {
  .section-title {
    font-size: 1.2rem;
    color: #FFFFFF;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-title {
    font-size: 1.2rem;
  }

  .card-number {
    font-size: 2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .section-title {
    font-size: 1.4rem;
  }

  .simple-text {
    font-size: 1.2rem;
  }

  .card-number {
    font-size: 2rem;
  }

  .card-text {
    font-size: 1.2rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {

  .section-title {
    font-size: 1.4rem;
  }

  .simple-text {
    font-size: 1rem;
  }

  .card-number {
    font-size: 1.8rem;
  }

  .card-text {
    font-size: 1.1rem;
  }
}

@media (min-width: 1200px) {
  .section-title {
    font-size: 1.4rem;
  }

  .simple-text {
    font-size: 1rem;
  }

  .card-number {
    font-size: 2.2rem;
  }

  .card-text {
    font-size: 1.2rem;
  }

  .card-number {
    line-height: 1.3rem;
  }

  .card-text {
    line-height: 1.3rem;
  }

}

@media (min-width: 2560px) {
  .section-title {
    font-size: 2.3rem;
  }

  .simple-text {
    font-size: 1.8rem;
  }

  .card-number {
    font-size: 2.3rem;
  }

  .card-text {
    font-size: 2rem;
  }
}

</style>