<template>
    <div>
        <section style="background-color:#3953D3;">
        <div class="row justify-content-center">
        <div class="col-lg-10" style="background-color:#FFFFFF; width:80%; margin-top:5rem; margin-bottom:10rem; border-radius:1rem; height: 100%;">
                <div class="container pt-lg-md-3">
                    <div class="row justify-content-center">
                         <div class="col-lg-12" style="text-align: center; text-transform: uppercase;  margin-top:5rem; margin-bottom:5rem;">
                                    <div class="text-center text-muted mb-4" v-if="urlContract && urlManifestFA && urlLetterPayment && urlAccountAper && urlLetterBeneficiarie">
                                        <small style="font-size:20px;">Descarga los documentos al dar click en los siguientes enlaces, 
                                            te pedimos que los imprimas y los firmes lo más parecido a tu identificación oficial.</small>
                                         <div class="row" >
                                            <div class="col">
                                            <p style="margin-top:2rem; font-size:20px;" v-if="urlContract">
                                            <img src="https://dbmenos.com/img/captures_contract/Firma-Contrato.png" style="width:35rem; margin-bottom:1rem;">
                                            <br>
                                            <a :href="urlContract" target="_blank"><b><i class="fa fa-download" aria-hidden="true"></i> DESCARGAR CONTRATO DE DEPOSITO DE DINERO</b></a>
                                            </p>
                                            </div>
                                            <div class="col">
                                             <p style="margin-top:2rem; font-size:20px;" class="col" v-if="urlManifestFA">
                                            <img src="https://dbmenos.com/img/captures_contract/Firma-Manifesto.png" style="width:35rem; margin-bottom:1rem;">
                                            <br>
                                            <a :href="urlManifestFA" target="_blank"><b><i class="fa fa-download" aria-hidden="true"></i> DESCARGAR MANIFESTO FINAMIGO</b></a>
                                            </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                             <p style="margin-top:2rem; font-size:20px;" class="col" v-if="urlLetterPayment">
                                            <img src="https://dbmenos.com/img/captures_contract/Firma-Carta-Pagos.png" style="width:35rem; margin-bottom:1rem;">
                                            <br>
                                            <a :href="urlLetterPayment" target="_blank"><b><i class="fa fa-download" aria-hidden="true"></i> DESCARGAR CARTA DE PAGO PARA FINAMIGO</b></a>
                                            </p>
                                            </div>
                                            <div class="col">
                                            <p style="margin-top:2rem; font-size:20px;" class="col" v-if="urlAccountAper">
                                            <img src="https://dbmenos.com/img/captures_contract/Firma-Apertura-Cuenta.png" style="width:35rem; margin-bottom:1rem;">
                                            <br>
                                            <a :href="urlAccountAper" target="_blank"><b><i class="fa fa-download" aria-hidden="true"></i> DESCARGAR SOLICITUD DE APERTURA DE CUENTA</b></a>
                                            </p>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                             <p style="margin-top:2rem; font-size:20px;" class="col" v-if="urlLetterBeneficiarie">
                                            <img src="https://dbmenos.com/img/captures_contract/Firma-Beneficiario.png" style="width:35rem; margin-bottom:1rem;">
                                            <br>
                                            <a :href="urlLetterBeneficiarie" target="_blank"><b><i class="fa fa-download" aria-hidden="true"></i> DESCARGAR CARTA DE BENEFICIARIOS</b></a>
                                            </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-center text-muted mb-4" v-if="!(urlContract && urlManifestFA && urlLetterPayment && urlAccountAper && urlLetterBeneficiarie)">
                                        <h5>No se Encontro Ningun Archivo, comúnicate con tu asesor</h5>
                                        <img src="https://dbmenos.com/img/theme/close-circle.png">
                                    </div>
                                </div>
                                </div>
                         </div>
                    </div>
                </div>
        </section>
       
    </div>
</template>

<script>
    import Vue from "vue";
    import modal from "@/components/Modal"
    import {V} from "@/V"
    import BaseInput from '../components/BaseInput.vue';
    export default {
        name: "home",
        components: {
            modal,
            BaseInput
        },
        mounted() {
        this.getFilesClient();
        },
        data() {
            return {
                V: V,
                client: {},
                userFiles: [],
                files: {
                GUIDE_SEND:null
                },
                banks: [],
                SERVER: "https://s3.amazonaws.com/dbmenos.frontend/",
                leadModal: false,
                estimatedDiscountModal: false,
                averageDiscount: 0,
                urlContract:"",
                urlLetterPayment:"",
                urlManifestFA:"",
                urlLetterBeneficiarie:"",
                urlAccountAper:""
            }
        },
        methods: {
     async getFilesClient() {
        this.$showLoader(true)
      if (this.$route.params['pathMatch']) {
        let url =
          V.SERVER +
          V.OPS.URLS_FILES_CONTRACT_HIGH_RISK +
          "?token=" +
          this.$route.params['pathMatch'];
          this.$showLoader(false)
         await this.$axios.get(url).then(async (res) => {
            this.$showLoader(false)
            let data = res.data;
            if (data.success) {
                this.$showLoader(false)
                this.urlContract = data.urlContract;
                this.urlLetterPayment = data.urlLetterPayment;
                this.urlManifestFA = data.urlManifestFA;
                this.urlLetterBeneficiarie = data.urlLetterBeneficiaries;
                this.urlAccountAper = data.urlAccountAper;
            } else {
              switch (data.reason) {
                case 1:
                this.$showLoader(false)
                this.$notify({
                    text: "Documentos no encontrados",
                    type: "error",
                });
              }
            }
        });
      }
        },
        }
    };
</script>
