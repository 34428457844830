<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto p-0 p-sm-3">

      <div class="col-12 col-sm-6 my-sm-5 d-flex align-items-center">

        <div class="text-center bg-image">
          <p class="text-image">
            ¿Como funciona?
          </p>
          <img :src="images[step-1]" alt="image">
        </div>

      </div>

      <div class="col-12 col-sm-6 my-sm-5 p-0">

        <v-stepper
            v-model="step"
            vertical
            height="100%"
            elevation="0"
        >
          <v-stepper-step
              class="p-sm-0"
              step="1"
              color="#00b2ce"
              @click="step = 1"
          >
            <p :class="(step === 1) ? 'primary-text-active' : 'primary-text-inactive'">Registro</p>
            <p class="mt-3 secondary-text">Llena tu solicitud en línea. Es más <br> rápido si lo haces con tu INE.</p>
          </v-stepper-step>

          <v-stepper-content step="1">
          </v-stepper-content>

          <v-stepper-step
              class="p-sm-0"
              step="2"
              color="#00b2ce"
              @click="step = 2"
          >
            <p :class="(step === 2) ? 'primary-text-active' : 'primary-text-inactive'">Contacto</p>
            <p class="mt-3 secondary-text">En un plazo máximo de 24h te <br> ofreceremos el mejor plan de pagos.</p>
          </v-stepper-step>

          <v-stepper-content step="2">
          </v-stepper-content>

          <v-stepper-step
              class="p-sm-1"
              step="3"
              color="#00b2ce"
              @click="step = 3"
          >
            <p :class="(step === 3) ? 'primary-text-active' : 'primary-text-inactive'">Tu préstamo</p>
            <p class="mt-3 secondary-text">Recibe tu préstamo inmediato y <br> comienza a liquidar tu deuda.</p>
          </v-stepper-step>

          <v-stepper-content step="3">
          </v-stepper-content>

        </v-stepper>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Steps",
  data: () =>
      ({
        step: 1,
        images:
            [
              "/img/loans/register.png",
              "/img/loans/contact.png",
              "/img/loans/loan.png"
            ],
      })
}
</script>

<style scoped>

.primary-text-active {
  font-family: OpenSans-Bold;
  color: #00B2CE;
}

.primary-text-inactive {
  font-family: OpenSans-Bold;
  color: #4d4d4d;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #4d4d4d;
}

.text-image {
  font-family: OpenSans-ExtraBold;
  color: #00B2CE;
}

@media (min-width: 320px) and (max-width: 374px) {
}

@media (min-width: 375px) and (max-width: 424px) {
}

@media (min-width: 425px) and (max-width: 767px) {
  img {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  /*::v-deep .v-stepper__step__step {
    width: 4rem;
    height: 2rem;
  }*/
}

@media (min-width: 1024px) and (max-width: 1439px) {
  /*::v-deep .v-stepper__step__step {
    width: 4rem;
    height: 2rem;
  }*/

  img {
    width: 60%;
  }

  .primary-text-active, .primary-text-inactive {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1.2rem;
  }

  .text-image {
    font-size: 1.4rem;
  }

}

@media (min-width: 1440px) and (max-width: 2559px) {
  /*::v-deep .v-stepper__step__step {
    width: 4rem;
    height: 2rem;
  }*/

  img {
    width: 60%;
  }

  .primary-text-active, .primary-text-inactive {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1.2rem;
  }

  .text-image {
    font-size: 1.4rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  /*::v-deep .v-stepper__step__step {
    width: 4rem;
    height: 2rem;
  }*/

  .bg-image{
    margin: auto;
  }

  img {
    width: 70%;
  }

  .primary-text-active, .primary-text-inactive {
    font-size: 1.8rem;
  }

  .secondary-text {
    font-size: 1.4rem;
  }

  .text-image {
    font-size: 1.8rem;
  }
}

</style>