<template>

  <div>

    <principal @showSection="showSection"/>
    
    <video-explication v-if="step===1"/>

    <video-women v-if="step===2"/>

  </div>

</template>

<script>

import Principal from "@/views/components/simulator/Principal";
import Error from "@/views/Error";
import VideoExplication from "@/views/components/simulator/VideoExplication";
import VideoWomen from "@/views/components/simulator/VideoWomen";
import SocialMedia from "@/views/components/landing_page/SocialMedia";
import Applications from "@/views/components/landing_page/Applications";
import FooterTemplate from "@/views/components/templates/FooterTemplate";
import HeaderTemplate from "@/views/components/templates/HeaderTemplate";

export default {
  name: "Simulator",
  components: {HeaderTemplate, FooterTemplate, Applications, SocialMedia, VideoWomen, VideoExplication, Error, Principal},
  data: () => ({
    step: 1,
  }),
  methods:
      {
        showSection: function (step)
        {
          this.step = step;
        }
      }
}
</script>

<style scoped>

</style>