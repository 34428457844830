<template>
  <div class="text-center">
    <section>
      <div class="d-block d-sm-none">
        <section>
          <div class="container-fluid" id="main-container">
            <div class="row">
              <div class="col">
                <img
                  src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Chico_DB Menos.png"
                  id="guy-background"
                />
                <div class="d-flex justify-content-end">
                  <div id="quotexs">
                    <span id="spanxs">
                      "Hacía el pago mínimo y mi deuda no bajaba. ¡Entré al
                      programa, y en <b id="spanbxs">15 meses </b> la liquidé
                      con
                      <b id="spanbxs">70% de descuento!</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-container-calculator"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="quote-calculator">
                    <span id="spanCalxs"> Calcula tu descuento </span>
                    <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 1rem"
                    >
                      <p class="speech-bubble-mobile" style="margin-top: -0.5rem; ">
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                    <h2 id="hAmountxs" style="margin-top: -1.5rem; margin-bottom:-1rem;">
                      ${{
                        simpleCalc.amount < 300
                          ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                            ? Number(simpleCalc.amount * 1000)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " ó menos"
                            : Number(simpleCalc.amount * 1000)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                      }}
                    </h2>
                    <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 8%"
                    >
                      <vue-slider 
                     :width="width"
                      :height="height"
                      :min="30"
                      :max="300"
                      :step="5"
                      :tooltip="'none'"
                      :process-style="processStyle"
                      v-model.number="simpleCalc.amount"
                    >
                    <template v-slot:dot="{ focus }" >
                      <div :class="['custom-dot', { focus }]"></div>
                    </template>
                    </vue-slider>
                    </div>
                    <p id="spanwhoxs" style="margin-top: -1rem">
                      <span>Selecciona a quién le debes: </span>
                    </p>
                    <div
                      class="row justify-content-center"
                      style="
                        padding: 2%;
                        margin-top: -5%;
                        margin-bottom: -3%;
                        align-items: center;
                      "
                    >
                      <div
                        class="col-md-3 col-sm-3 col-3 text-center"
                        style="align-items: center; margin-top: 1rem"
                        v-for="(bank, index) in banks"
                        :key="index"
                      >
                        <div
                          @click="m_switchSelected(index)"
                          style="margin-top: 10%"
                        >
                          <div class="g-item">
                            <img
                            v-bind:class="{'change-my-color-y': bank.selected, 'change-my-color-w': !bank.selected}"
                              v-bind:src="bank.url_w_mobile"
                              :title="bank.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p id="spaninstxs" style="margin-top: 1rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <base-button
                      v-on:click="m_startinglead()"
                      style="
                        background-color: #f3d338;
                        color: #2a3d9a;
                        border: none;
                        font-size: 100%;
                        font-family: Gotham-Black;
                      "
                      class="btndisxs"
                    >
                      Dame mi descuento
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="main-container-comments">
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imgxs"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsx">
                    <span id="spanopxs">
                      La opinión de<b id="spanopbxs"> nuestros graduados </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding:2%; align-items: center;">
                <div class="col-12">
                  <a
                    href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                    target="_blank"
                  >
                    <img src="img/Comentarios/Escritorio/6.png" style="width:100%"/>
                  </a>
                </div>
                <div class="col-12">
                  <a
                    href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                    target="_blank"
                  >
                    <img src="img/Comentarios/Escritorio/3.png" style="width:100%"/>
                  </a>
                </div>
                <div class="col-12">
                  <a
                    href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                    target="_blank"
                  >
                    <img src="img/Comentarios/Escritorio/1.png" style="width:100%"/>
                  </a>
                </div>
                <div class="col-12">
                  <a
                    href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                    target="_blank"
                  >
                    <img src="img/Comentarios/Escritorio/4.png" style="width:100%"/>
                  </a>
                </div>
                <div class="col-12">
                  <a
                    href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                    target="_blank"
                  >
                    <img src="img/Comentarios/Escritorio/5.png" style="width:100%"/>
                  </a>
                </div>
                <div class="col-12">
                  <a
                    href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                    target="_blank"
                  >
                    <img src="img/Comentarios/Escritorio/2.png" style="width:100%"/>
                  </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="main-container-comments">
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imgxs"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsx">
                    <span id="spanopxs">
                      La que la prensa<b id="spanopbxs"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%">
                  <div class="col-6">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>

                  <div class="col-12">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-sm-block d-md-none">
        <section>
          <div class="container-fluid" id="main-container">
            <div class="row">
              <div class="col">
                <img
                  src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Chico_DB Menos.png"
                  id="guy-background"
                />
                <div class="d-flex justify-content-end">
                  <div id="quote">
                    <span id="spansm">
                      "Hacía el pago mínimo y mi deuda no bajaba. ¡Entré al
                      programa, y en <b id="spanbsm">15 meses </b> la liquidé
                      con
                      <b id="spanbsm">70% de descuento!</b>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-calculator">
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="quote-calculator">
                    <span id="spanCalsm"> Calcula tu descuento </span>
                    <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 1rem"
                    >
                      <p class="speech-bubble-mobile" style="margin-top: -0.5rem">
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                    <h2 id="hAmountsm" style="margin-top: -1.5rem; margin-bottom:-1rem;">
                      ${{
                        simpleCalc.amount < 300
                          ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                            ? Number(simpleCalc.amount * 1000)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " ó menos"
                            : Number(simpleCalc.amount * 1000)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                      }}
                    </h2>
                    <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 5%"
                    >
                     <vue-slider 
                     :width="width"
                      :height="height"
                      :min="30"
                      :max="300"
                      :step="5"
                      :tooltip="'none'"
                      :process-style="processStyle"
                      v-model.number="simpleCalc.amount"
                    >
                    <template v-slot:dot="{ focus }" >
                      <div :class="['custom-dot', { focus }]"></div>
                    </template>
                    </vue-slider>
                    </div>
                    <p id="spanwhosm" style="margin-top: -1rem">
                      <span>Selecciona a quién le debes: </span>
                    </p>
                    <div
                      class="row justify-content-center"
                      style="
                        padding: 1%;
                        margin-top: -1%;
                        margin-bottom: -3%;
                        align-items: center;
                      "
                    >
                      <div
                        class="col-3 text-center"
                        style="align-items: center"
                        v-for="(bank, index) in banks"
                        :key="index"
                      >
                        <div
                          @click="m_switchSelected(index)"
                          style="margin-top: 10%"
                        >
                          <div class="g-item">
                            <img
                            v-bind:class="{'change-my-color-y': bank.selected, 'change-my-color-w': !bank.selected}"
                              v-bind:src="bank.url_w_mobile"
                              style="height: 100%; width: 100%"
                              :title="bank.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p id="spaninstsm" style="margin-top: 2rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <base-button
                      v-on:click="m_startinglead()"
                      style="
                        background-color: #f3d338;
                        color: #2a3d9a;
                        border: none;
                        font-size: 100%;
                        font-family: Gotham-Black;
                      "
                      class="btndissm"
                    >
                      Dame mi descuento
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="main-container-comments">
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imgsm"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsm">
                    <span id="spanopsm">
                      La opinión de<b id="spanopbsm"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 2%; align-items: center">
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="main-container-comments">
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imgsm"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsm">
                    <span id="spanopsm">
                      La que la prensa<b id="spanopbsm"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%">
                  <div class="col-6">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>

                  <div class="col-12">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-md-block d-lg-none">
        <section>
          <div class="container-fluid" id="main-container">
            <div class="row">
              <div class="col">
                <img
                  src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Chico_DB Menos.png"
                  id="guy-background"
                />
                <div class="d-flex justify-content-end">
                  <div id="quote">
                    <p id="spanmd">
                      <span
                        >"Hacía el pago mínimo y mi deuda no bajaba. ¡Entré al
                        programa, y en <b id="spanbmd">15 meses </b> la liquidé
                        con <b id="spanbmd">70% de descuento!</b> "</span
                      >
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-calculator">
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="quote-calculator">
                    <span id="spanCalmd"> Calcula tu descuento </span>
                    <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 1rem"
                    >
                      <p class="speech-bubble-mobile" style="margin-top: -0.5rem">
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                    <h2 id="hAmountmd" style="margin-top: -1.5rem; margin-bottom:-1rem;">
                      ${{
                        simpleCalc.amount < 300
                          ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                            ? Number(simpleCalc.amount * 1000)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " ó menos"
                            : Number(simpleCalc.amount * 1000)
                                .toFixed(0)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                      }}
                    </h2>
                    <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 5%"
                    >
                    <vue-slider 
                     :width="width"
                      :height="height"
                      :min="30"
                      :max="300"
                      :step="5"
                      :tooltip="'none'"
                      :process-style="processStyle"
                      v-model.number="simpleCalc.amount"
                    >
                    <template v-slot:dot="{ focus }" >
                      <div :class="['custom-dot', { focus }]"></div>
                    </template>
                    </vue-slider>
                    </div>
                    <p id="spanwhomd">
                      <span>Selecciona a quién le debes: </span>
                    </p>
                    <div
                      class="row justify-content-center"
                      style="
                        padding: 2%;
                        margin-top: -3%;
                        margin-bottom: -3%;
                        align-items: center;
                      "
                    >
                      <div
                        class="col-md-3 col-sm-3 col-3 text-center"
                        style="align-items: center"
                        v-for="(bank, index) in banks"
                        :key="index"
                      >
                        <div
                          @click="m_switchSelected(index)"
                          style="margin-top: 10%"
                        >
                          <div class="g-item">
                            <img
                              v-bind:class="{'change-my-color-y': bank.selected, 'change-my-color-w': !bank.selected}"
                              v-bind:src="bank.url_w_mobile"
                              style="height: 100%; width: 100%"
                              :title="bank.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p id="spaninstmd" style="margin-top: 2rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <base-button
                      v-on:click="m_startinglead()"
                      style="
                        background-color: #f3d338;
                        color: #2a3d9a;
                        border: none;
                        font-size: 100%;
                        font-family: Gotham-Black;
                      "
                      class="btndismd"
                    >
                      Dame mi descuento
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="main-container-comments">
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imgmd"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptmd">
                    <span id="spanopmd">
                      La opinión de<b id="spanopbmd"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 2%; align-items: center">
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="main-container-comments">
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imgmd"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptmd">
                    <span id="spanopmd">
                      La que la prensa<b id="spanopbmd"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-lg-block d-xl-none">
        <section style="background-color: #ebebeb">
          <div class="container-grid" id="main-container-des">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quote-des" style="left: 90%">
                    <span id="spanlg" style="font-size: 10pt">
                      "Hacía el pago mínimo y mi deuda no bajaba. ¡Entré al
                      programa, y en <b id="spanblg">15 meses </b> la liquidé
                      con
                      <b id="spanblg">70% de descuento!</b>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div id="quote-calculator-desc">
                  <span id="spanCallg" style="font-size: 15pt">
                    Calcula tu descuento</span
                  >
                  <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 1rem"
                    >
                      <p class="speech-bubble" style="margin-top: -0.5rem; animation:pulse 2s infinite">
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                  <h2 id="hAmountlg" style="margin-top: -1.5rem; margin-bottom:-1rem;">
                    ${{
                      simpleCalc.amount < 300
                        ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                          ? Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                            " ó menos"
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : Number(simpleCalc.amount * 1000)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                    }}
                  </h2>
                  <div
                    class="row justify-content-center"
                    style="align-items: center; padding: 10%"
                  >
                   <vue-slider 
                     :width="width"
                      :height="height"
                      :min="30"
                      :max="300"
                      :step="5"
                      :tooltip="'none'"
                      :process-style="processStyle2"
                      v-model.number="simpleCalc.amount"
                    >
                    <template v-slot:dot="{ focus }" >
                      <div :class="['custom-dot-d', { focus }]"></div>
                    </template>
                    </vue-slider>
                  </div>

                  <p id="spanwholg" style="margin-top: -1rem">
                    <span style="font-size: 10pt"
                      >Selecciona a quién le debes:
                    </span>
                  </p>
                  <div
                      class="row justify-content-center"
                      style="margin-bottom:2rem; margin-top:-1rem; align-items: center;"
                    >
                      <div
                        class="col-3 text-center"
                        style="align-items: center"
                        v-for="(bank, index) in banks"
                        :key="index"
                      >
                        <div
                        @click="m_switchSelected(index)"
                        style="margin-top: 20%"
                      >
                        <div class="g-item" >
                          <img
                            v-bind:class="{'change-my-color-y-d': bank.selected, 'change-my-color-w': !bank.selected}"
                              v-bind:src="bank.url_blue"
                              :title="bank.name"
                          />
                        </div>
                      </div>
                      </div>
                    </div>
                  <p id="spaninstlg" style="font-size: 8pt">
                    <span>
                      Si tu institución financiera o banco no se encuentra aquí,
                      por el momento no podemos ayudarte.</span
                    >
                  </p>
                  <base-button
                    v-on:click="m_startinglead()"
                    style="
                      background-color: #2a3d9a;
                      color: white;
                      border: none;
                      font-size: 100%;
                      font-family: Gotham-Black;
                    "
                    class="btndislg"
                  >
                    Dame mi descuento
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-container-comments-lg"
          >
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imglg"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <div id="quoteoptlg">
                    <span id="spanoplg">
                      La opinión de<b id="spanopblg"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 2%; align-items: center">
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-container-comments-lg"
          >
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imglg"
                  src="img/icons/Ilustracion de prensa.png"
                  style="width: 30%"
                />
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <div id="quoteoptlg">
                    <span id="spanoplg">
                      La que la prensa<b id="spanopblg"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-xl-block" style="">
        <section section style="background-color: #e4e4e4">
          <div class="container-grid" id="main-container-desxl">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quote-desxl" style="left: 100%">
                    <span id="spanlg" style="font-size: 15pt">
                      "Hacía el pago mínimo y mi deuda no bajaba. ¡Entré al
                      programa, y en <b id="spanblg">15 meses </b> la liquidé
                      con
                      <b id="spanblg">70% de descuento!</b>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div id="quote-calculator-desc">
                  <span id="spanCallg"> Calcula tu descuento</span>
                  <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 1rem"
                    >
                      <p class="speech-bubble" style="margin-top: -0.5rem; animation:pulse 2s infinite">
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                  <h2 id="hAmountlg" style="font-size: 30pt; margin-top: -1.5rem; margin-bottom: -1rem">
                    ${{
                      simpleCalc.amount < 300
                        ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                          ? Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                            " ó menos"
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : Number(simpleCalc.amount * 1000)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                    }}
                  </h2>
                  <div
                    class="row justify-content-center"
                    style="align-items: center; padding: 6%"
                  >
                    <vue-slider 
                     :width="width"
                      :height="height"
                      :min="30"
                      :max="300"
                      :step="5"
                      :tooltip="'none'"
                      :process-style="processStyle2"
                      v-model.number="simpleCalc.amount"
                    >
                    <template v-slot:dot="{ focus }" >
                      <div :class="['custom-dot-d', { focus }]"></div>
                    </template>
                    </vue-slider>
                  </div>

                  <p id="spanwholg" style="font-size: 12pt; margin-top: -1rem">
                    <span>Selecciona a quién le debes: </span>
                  </p>
                  <div
                      class="row justify-content-center"
                      style="margin-bottom:2rem; margin-top:-1rem; align-items: center;"
                    >
                      <div
                        class="col-3 text-center"
                        style="align-items: center"
                        v-for="(bank, index) in banks"
                        :key="index"
                      >
                        <div
                        @click="m_switchSelected(index)"
                        style="margin-top: 20%"
                      >
                        <div class="g-item" >
                          <img
                            v-bind:class="{'change-my-color-y-d': bank.selected, 'change-my-color-w': !bank.selected}"
                              v-bind:src="bank.url_blue"
                              :title="bank.name"
                          />
                        </div>
                      </div>
                      </div>
                    </div>
                  <p id="spaninstlg">
                    <span>
                      Si tu institución financiera o banco no se encuentra aquí,
                      por el momento no podemos ayudarte.</span
                    >
                  </p>
                  <base-button
                    v-on:click="m_startinglead()"
                    style="
                      background-color: #2a3d9a;
                      color: white;
                      border: none;
                      font-size: 100%;
                      font-family: Gotham-Black;
                    "
                    class="btndislg"
                  >
                    Dame mi descuento
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-container-comments-lg"
          >
            <div class="row text-center">
              <div class="col-6">
                <img
                  id="reviews-imgmd"
                  src="img/icons/Ilustracion reviews.png"
                  style="width: 25%; margin-left: 15rem"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptmd" style="left: -10rem">
                    <span id="spanopmd">
                      La opinión de<b id="spanopbmd"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 2%; align-items: center">
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-container-comments-lg"
          >
            <div class="row">
              <div class="col-6">
                <img
                  id="reviews-imgmd"
                  src="img/icons/Ilustracion de prensa.png"
                  style="width: 25%; margin-left: 15rem"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptlg" style="left: -5rem">
                    <span id="spanoplg">
                      La que la prensa<b id="spanopblg"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <modal
      :show.sync="leadModal"
      body-classes="p-0"
      modal-classes="modal-dialog-centered modal-sm"
    >
      <card
        type="secondary"
        shadow
        header-classes="bg-white pb-5"
        body-classes="px-lg-5 py-lg-5"
        style="opacity: 1 !important"
        class="border-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <small>Ingresa tus datos para obtener tu descuento</small>
          </div>
          <form role="form">
            <base-input
              alternative
              class="mb-3"
              v-model="leadInfo.name"
              :valid="m_hasValidName()"
              placeholder="Nombre Completo"
              addon-left-icon="ni ni-circle-08"
            ></base-input>
            <base-input
              alternative
              class="mb-3"
              type="email"
              v-model="leadInfo.email"
              :valid="m_hasValidEmail()"
              placeholder="Correo electrónico"
              addon-left-icon="ni ni-email-83"
            ></base-input>
            <base-input
              alternative
              type="Phone"
              v-model="leadInfo.phone"
              :valid="m_hasValidPhone()"
              placeholder="Teléfono"
              addon-left-icon="fa fa-phone"
            ></base-input>
             <div v-if="leadSent" class="text-center text-muted mb-4">
            <small>Para recibir el cálculo de tu descuento enviamos un código por WhatsApp colócalo para continuar.</small>
          </div>
            <base-input
              alternative
              v-model="leadValidationCode"
              v-if="leadSent"
              :valid="leadValidationCode.length === 4"
              placeholder="Código de verificación"
              addon-left-icon="fa fa-check"
            ></base-input>
            <div class="text-justify">
              <p>
                Al continuar, aceptas los
                <a
                  href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf"
                  target="_blank"
                  >Términos y condiciones</a
                >. Consulta nuestro
                <a
                  href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf"
                  target="_blank"
                  >Aviso de privacidad</a
                >
              </p>
            </div>
            <div class="text-center">
              <base-button
                type="primary"
                class="my-4"
                v-on:click="m_insertLead()"
                v-bind:disabled="
                  !m_hasValidName() || !m_hasValidPhone() || !m_hasValidEmail()
                "
                >Quiero mi descuento</base-button
              >
            </div>
          </form>
        </template>
      </card>
    </modal>
    <modal
      :show.sync="estimatedDiscountModal"
      gradient="primary"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <h6 slot="header" class="modal-title" id="modal-title-notification">
        Gracias {{ leadInfo.name }}
      </h6>

      <div class="py-3 text-center">
        <i class="ni ni-bell-55 ni-3x"></i>
        <h4 class="heading mt-4">Tenemos tu descuento</h4>
        <p>En Grupo DB Menos podemos ofrecerte un descuento estimado del</p>
        <h5 class="text-white">{{ averageDiscount.toFixed(2) }}%</h5>
        <p>Por lo que puedes liquidar tu deuda con tan solo</p>
        <h3 class="text-white">
          $
          {{
            ((100 - averageDiscount) * 10 * simpleCalc.amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}
        </h3>
      </div>
      <template>
        <router-link to="/register" class="text-light">
          <base-button block type="white"
            >Quiero tomar el descuento</base-button
          >
        </router-link>
      </template>
    </modal>
  </div>
</template>
<script>
import Vue from "vue";
import modal from "@/components/Modal";
import { V } from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import { LazyYoutube } from "vue-lazytube";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('VueSlider', VueSlider)

Vue.use(VueFacebookPixel);
export default {
  name: "registerNew",
  components: {
    modal,
    VueFacebookPixel,
    LazyYoutube,
    VueSlider
  },
  created() {
    fbq("track", "PageView");
    fbq("track", "page_view");
    this.createMarketingLandingMobile1();
    this.m_insertMarketingLead1();
    this.m_getBanks();
    this.width ='100%';
    this.height = 7;

    this.processStyle = {
      backgroundColor: '#f3d338',
    }
    this.sliderStyle = {
      width:'18px',
      height:'18px',
      backgroundColor: '#f3d338',
    }

    this.processStyle2 = {
      backgroundColor: '#2a3d9a',
    }
    this.sliderStyle2 = {
      width:'18px',
      height:'18px',
      backgroundColor: '#2a3d9a',
    }
  },
  data() {
    return {
      styleObject: {
        background: 'red'
      },
      V: V,
      disabled: true,
      dataMark: "",
      from: null,
      typeapp: {
        appStore: 0,
        playStore: 0,
      },
      simpleCalc: {
        amount: 100,
        banks: [],
      },
      banks: [],
      bancos:[],
      leadInfo: {
        name: "",
        email: "",
        phone: "",
      },
      leadValidationCode: "",
      leadSent: false,
      sameBankDebtPaysheet: false,
      leadModal: false,
      estimatedDiscountModal: false,
      averageDiscount: 0,
      videosModal: false,
    };
  },
  methods: {
    // PATH ?utm_source=SOURCE&utm_medium=MEDIUM&utm_campaign=CAMPAIGN&utm_term=TERM&utm_content=CONTENT
    createMarketingLandingMobile1() {
      let marketing =
        this.$route.query.utm_source +
        "_" +
        this.$route.query.utm_medium +
        "_" +
        this.$route.query.utm_campaign;
      if (marketing != "undefined_undefined_undefined") {
      if(marketing == this.$route.query.utm_source+"_undefined_undefined"){
        marketing = this.$route.query.utm_source;
          } else if(marketing == this.$route.query.utm_source+"_"+this.$route.query.utm_medium+"_undefined"){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_medium;
          }else{
            marketing = marketing;
          }

      console.log(marketing);
        let url = V.SERVER + V.OPS.SET_MARKETING_LANMOBILES;
        return this.$axios
          .post(url, { marketing: marketing, type: 1 })
          .then((response) => {
            this.$showLoader(false);
            let data = response.data;
            if (data.success) {
              console.log(data.row);
            }
          });
      } else {
        console.log("No Marketing");
      }
    },
    async m_insertMarketingLead1() {
      let marketing =
        this.$route.query.utm_source +
        "_" +
        this.$route.query.utm_medium +
        "_" +
        this.$route.query.utm_campaign;
      if (marketing !== "undefined_undefined_undefined") {
        if(marketing == this.$route.query.utm_source+"_undefined_undefined"){
        marketing = this.$route.query.utm_source;
          } else if(marketing == this.$route.query.utm_source+"_"+this.$route.query.utm_medium+"_undefined"){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_medium;
          }else{
            marketing = marketing;
          }
        console.log(marketing);
        this.$store.commit("setMarketing", marketing);
        let url = V.SERVER + V.OPS.INSERT + V.TABLES.MARKETING_LEADS;
        await this.$axios.post(url, { from: marketing });
      }
    },
    getVideos() {
      this.videosModal = true;
    },
    m_startinglead() {
      if (!this.averageDiscount == 0) {
        this.leadModal = true;
        Vue.analytics.fbq.event("StartingLead", { starting: true });
        gtag("event", "Escoger-monto-deuda", {
          event_category: "Landing-liquidar-deuda-1",
          event_label: "escoger-monto",
        });
        console.log("Start Lead");
      } else {
        this.$notify({
          text: "Debes seleccionar un banco para poder continuar",
          type: "error",
        });
      }
    },
    m_hasValidEmail() {
      let regex = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|es|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b"
      );
      return regex.test(this.leadInfo.email);
    },
    m_hasValidPhone() {
       return (
                !isNaN(this.leadInfo.phone) &&
                this.leadInfo.phone.length > 9 &&
                this.leadInfo.phone.length < 11
            );
    },
    m_hasValidName() {
      return this.leadInfo.name.length > 2;
    },
    m_getBanks() {
      let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS_LANDINGS;
      this.$http.post(url, {}).then((res) => {
        if (res.body.success) {
          this.banks = res.body.rows;
          this.banks.forEach((b) => {
            b.selected = false;
          });
        }
      });
    },
    m_switchSelected(index) {
      let bank = this.banks[index];
      bank.selected = !bank.selected;
      console.log(bank);
      Vue.set(this.banks, index, bank);
      let count = 0;
      let neg = 0;
      this.banks.forEach((bank) => {
        if (bank.selected) {
          count += 1;
          neg += bank.neg;
        }
      });
      if (count > 0) {
        this.averageDiscount = neg / count;
      } else {
        this.averageDiscount = 0;
      }
    },
   m_insertLead() {
      if (this.leadSent) {
        let marketing =
          this.$route.query.utm_source +
          "_" +
          this.$route.query.utm_medium +
          "_" +
          this.$route.query.utm_campaign;
       if (marketing == "undefined_undefined_undefined") {
        marketing = null;
      }else if(marketing == this.$route.query.utm_source+"_undefined_undefined"){
        marketing = this.$route.query.utm_source;
          } else if(marketing == this.$route.query.utm_source+"_"+this.$route.query.utm_medium+"_undefined"){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_medium;
          }else if(marketing == this.$route.query.utm_source+"_undefined_"+this.$route.query.utm_campaign){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_campaign;
          }else{
            marketing = marketing;
          }
        let _data = {
          phone: this.leadInfo.phone,
          code: this.leadValidationCode,
          marketing: marketing,
        };

        let calculate={
          estimated:this.averageDiscount,
          total:this.simpleCalc.amount
        }
        Vue.analytics.fbq.event("LeadComplete", {
          content_name:
            this.leadInfo.name +
            " " +
            this.leadInfo.phone +
            " lead validated successfully",
        });

        let url = V.SERVER + V.OPS.VALIDATE_LEAD_SMS_CODE;
        this.$http.post(url, _data).then(
          (res) => {
            if (res.data.success === true) {
               this.leadModal = false;
              this.leadSent = false;              
              localStorage.setItem('phone', JSON.stringify(_data))
              localStorage.setItem('calculate', JSON.stringify(calculate))
              this.$router.push({ path: "/regis" });
              window.location.reload()
              console.log(this.leadModal)
              gtag("event", "Datos-validados", {
                event_category: "Landing-liquidar-deuda-1",
                event_label: "datos-validados",
              });
            } else {
              alert("Código inválido, intenta nuevamente");
            }
          },
          (er) => {
            //                        console.log("err")
            //                        console.log(er)
          }
        );
      } else {
        this.leadSent = true;
        let marketing =
          this.$route.query.utm_source +
          "_" +
          this.$route.query.utm_medium +
          "_" +
          this.$route.query.utm_campaign;
        if (marketing == "undefined_undefined_undefined") {
        marketing = null;
      }else if(marketing == this.$route.query.utm_source+"_undefined_undefined"){
        marketing = this.$route.query.utm_source;
          } else if(marketing == this.$route.query.utm_source+"_"+this.$route.query.utm_medium+"_undefined"){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_medium;
          }else if(marketing == this.$route.query.utm_source+"_undefined_"+this.$route.query.utm_campaign){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_campaign;
          }else{
            marketing = marketing;
          }
        let headers = {
            "device":'web'
          };
        let _data = {
          name: this.leadInfo.name,
          email: this.leadInfo.email,
          phone: this.leadInfo.phone,
          amount: this.simpleCalc.amount * 1000,
          banks: [],
          same_bank_debt_paysheet: this.sameBankDebtPaysheet,
          device:'web'
        };
        if (marketing) {
          _data.marketing = marketing;
        }
        if (_data.amount > 100000) {
          _data.amount = this.simpleCalc.amount * 2000;
        }
        Vue.analytics.fbq.event("LeadStarting", {
          content_name:
            this.leadInfo.name +
            " " +
            this.leadInfo.phone +
            " lead successful started",
        });
        let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS;
        gtag("event", "conversion", {
          send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
        });
        gtag("event", "Datos-contacto", {
          event_category: "Landing-liquidar-deuda-1",
          event_label: "datos-contacto",
        });
        this.banks.forEach((bank) => {
          if (bank.selected) _data.banks.push(bank.id);
        });
        _data.banks = JSON.stringify(_data.banks);
        this.$http.post(url, _data,{headers: headers}).then(
          (res) => {
            if (res.data.success) {
              this.leadSent = res.data.success;
              localStorage.setItem('Lead', JSON.stringify(_data))
            } else {
              alert("Algo salió mal :(, intenta nuevamente");
            }
          },
          (er) => {
            //                        console.log("err")
            //                        console.log(er)
          }
        );
      }
    },
  },
};
</script>

<style>
.custom-dot-d {
    padding: 20%;
    margin-top:-0.5rem;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: #1f3ac0;
    transition: all .3s;
    animation: pulse 2s infinite;
  }
  .custom-dot-d:hover {
    animation: pulse2 0s infinite;
  }
  .custom-dot-d.focus {
    animation: pulse2 0s infinite;
  }
@font-face {
  font-family: bahnschrift;
  src: url(/fonts/Bahnschrift_font/bahnschrift.ttf);
}
@font-face {
  font-family: Roboto-ThinItalic;
  src: url(/fonts/Roboto_font/Roboto-ThinItalic.ttf);
}
@font-face {
  font-family: Roboto-Thin;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}
@font-face {
  font-family: Roboto-Regular;
  src: url(/fonts/Roboto_font/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto-MediumItalic;
  src: url(/fonts/Roboto_font/Roboto-MediumItalic.ttf);
}
@font-face {
  font-family: Roboto-Medium;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}
@font-face {
  font-family: Roboto-LightItalic;
  src: url(/fonts/Roboto_font/Roboto-LightItalic.ttf);
}
@font-face {
  font-family: Roboto-Light;
  src: url(/fonts/Roboto_font/Roboto-Light.ttf);
}
@font-face {
  font-family: Roboto-Italic;
  src: url(/fonts/Roboto_font/Roboto-Italic.ttf);
}
@font-face {
  font-family: Roboto-BoldItalic;
  src: url(/fonts/Roboto_font/Roboto-BoldItalic.ttf);
}
@font-face {
  font-family: Roboto-Bold;
  src: url(/fonts/Roboto_font/Roboto-Bold.ttf);
}
@font-face {
  font-family: Roboto-BlackItalic;
  src: url(/fonts/Roboto_font/Roboto-BlackItalic.ttf);
}
@font-face {
  font-family: Roboto-Black;
  src: url(/fonts/Roboto_font/Roboto-Black.ttf);
}
@font-face {
  font-family: Gotham-Black;
  src: url(/fonts/Gotham_Font/Gotham-Black.otf);
}
@font-face {
  font-family: Gotham-Thin;
  src: url(/fonts/Gotham_Font/Gotham-Thin.otf);
}
@font-face {
  font-family: Gotham-ThinItalic;
  src: url(/fonts/Gotham_Font/Gotham-ThinItalic.otf);
}
@font-face {
  font-family: Gotham-ultra;
  src: url(/fonts/Gotham_Font/gotham-ultra.ttf);
}
@font-face {
  font-family: Gotham-UltraItalic;
  src: url(/fonts/Gotham_Font/Gotham-UltraItalic.otf);
}
@font-face {
  font-family: Gotham-Bold;
  src: url(/fonts/Gotham_Font/GothamBold.ttf);
}
@font-face {
  font-family: Gotham-BoldItalic;
  src: url(/fonts/Gotham_Font/GothamBoldItalic.ttf);
}
@font-face {
  font-family: Gotham-Book;
  src: url(/fonts/Gotham_Font/GothamBook.ttf);
}
@font-face {
  font-family: Gotham-BookItalic;
  src: url(/fonts/Gotham_Font/GothamBookItalic.ttf);
}
@font-face {
  font-family: Gotham-Light;
  src: url(/fonts/Gotham_Font/GothamLight.ttf);
}
@font-face {
  font-family: Gotham-Medium;
  src: url(/fonts/Gotham_Font/GothamMedium.ttf);
}
@font-face {
  font-family: Gotham-LightItalic;
  src: url(/fonts/Gotham_Font/GothamLightItalic.ttf);
}
@font-face {
  font-family: Metropolis-ExtraLight;
  src: url(/fonts/Metropolis/Metropolis-ExtraLight.otf);
}
@font-face {
  font-family: HomePage-Book;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Book.ttf);
}
@font-face {
  font-family: HomePage-Bold;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Bold.ttf);
}
.change-my-color-y{
  filter: grayscale(120%) brightness(75%) sepia(250%) hue-rotate(1deg) saturate(1900%) contrast(0.7);
}
.change-my-color-y-d{
  filter: grayscale(120%) brightness(210%) sepia(200%) hue-rotate(14deg) saturate(4300%) contrast(0.7);
}
.blob-mobile {
  margin: 10px;
  height: 5px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse2 2s infinite;
}

@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
.blob-desk {
  margin: 10px;
  height: 5px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(29, 32, 198, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}
#main-container {
  background-color: #efefef;
}
#guy-background {
  width: 100%;
}
#quote {
  position: absolute;
  color: #808080;
  width: 45%;
  top: 10%;
  right: 10%;
}
#quotexs {
  position: absolute;
  color: #808080;
  width: 40%;
  top: 12%;
  right: 8%;
  line-height: 70%;
}
#spanxs {
  font-family: Metropolis-ExtraLight;
  font-size: 8pt;
}
#spanbxs {
  font-family: HomePage-Bold;
  font-size: 8pt;
  line-height: 11pt;
}
#spansm {
  font-family: Metropolis-ExtraLight;
  font-size: 12pt;
}
#spanbsm {
  font-family: HomePage-Bold;
  font-size: 12pt;
}
#spanmd {
  font-family: Metropolis-ExtraLight;
  font-size: 14pt;
}
#spanbmd {
  font-family: HomePage-Bold;
  font-size: 14pt;
}
#main-container-calculator {
  background-color: #092699;
  padding: 0;
  height: 100%;
}
#quote-calculator {
  margin-bottom: 10%;
  padding: 3%;
}
#spanCalxs {
  color: white;
  font-size: 120%;
  font-family: HomePage-Bold;
}
#spanDebxs {
  color: white;
  font-size: 100%;
  font-family: HomePage-Book;
}
#hAmountxs {
  color: white;
  margin-top: -4%;
  font-family: Gotham-Ultra;
}
#sliderxs {
  width: 80%;
  margin-top: -3%;
}
#spanwhoxs {
  color: white;
  margin-top: -2%;
  font-family: HomePage-Book;
}
#spaninstxs {
  font-size: 80%;
  color: white;
  margin-top: -5%;
  font-family: HomePage-Book;
}
#spanCalsm {
  color: white;
  font-size: 150%;
  font-family: HomePage-Bold;
}
#spanDebsm {
  color: white;
  font-size: 120%;
  font-family: HomePage-Book;
}
#hAmountsm {
  color: white;
  font-size: 250%;
  margin-top: -3%;
  font-family: Gotham-Ultra;
}
#slidersm {
  width: 80%;
  margin-top: -1%;
  margin-left: 10%;
}
#spanwhosm {
  color: white;
  margin-top: -1%;
  font-size: 120%;
  font-family: HomePage-Book;
}
#spaninstsm {
  font-size: 80%;
  color: white;
  margin-top: -5%;
  font-family: HomePage-Book;
}
#spanCalmd {
  color: white;
  font-size: 200%;
  font-family: HomePage-Bold;
}
#spanDebmd {
  color: white;
  font-size: 150%;
  font-family: HomePage-Book;
}
#hAmountmd {
  color: white;
  font-size: 250%;
  margin-top: -3%;
  font-family: Gotham-Ultra;
}
#slidermd {
  width: 80%;
  margin-top: -1%;
  margin-left: 10%;
}
#spanwhomd {
  color: white;
  margin-top: -1%;
  font-size: 150%;
  font-family: HomePage-Book;
}
#spaninstmd {
  font-size: 100%;
  color: white;
  margin-top: -10%;
  font-family: HomePage-Book;
}
#main-container-comments {
  align-items: center;
  padding: 5%;
  height: 100%;
}
#reviews-imgxs {
  width: 100%;
  right: 20%;
}
#quoteoptsx {
  position: absolute;
  color: #808080;
  width: 100%;
  top: 40%;
  right: 10%;
  line-height: 100%;
}
#quote-comments {
  margin-bottom: 10%;
  padding: 4%;
}
#spanopxs {
  font-size: 150%;
  font-family: HomePage-Bold;
}
#spanopbxs {
  font-family: HomePage-Book;
}
#spanopsm {
  font-size: 150%;
  font-family: HomePage-Bold;
}
#spanopbsm {
  font-family: HomePage-Book;
}
#quoteoptsm {
  position: absolute;
  color: #808080;
  width: 100%;
  top: 40%;
  right: 10%;
  line-height: 100%;
}
#reviews-imgsm {
  width: 70%;
  right: 20%;
}
#spanopmd {
  font-size: 150%;
  font-family: HomePage-Bold;
}
#spanopbmd {
  font-family: HomePage-Book;
}
#quoteoptmd {
  position: absolute;
  color: #808080;
  width: 100%;
  top: 40%;
  right: 10%;
  line-height: 100%;
}
#reviews-imgmd {
  width: 50%;
  right: 20%;
}
#fb-postlg {
  margin-top: 1rem;
}
#fb-postxs {
  margin-top: 2rem;
}
#main-container-des {
  height: 100%;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background Man.png");
}
#main-container-desxl {
  height: 100;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background Man 1500px_Mesa de trabajo 1.png");
}
#quote-des {
  position: absolute;
  color: #808080;
  width: 40%;
  top: 15%;
  right: -25%;
}
#quote-calculator-desc {
  margin-top: -1%;
  margin-left: 20%;
  padding: 10%;
}
#spanlg {
  color: #808080;
  font-family: Metropolis-ExtraLight;
  font-size: 10rem;
}
#spanblg {
  color: #808080;
  font-family: HomePage-Bold;
  font-size: 20rem;
}
#spanCallg {
  color: #2a3d9a;
  font-size: 10pt;
  font-family: HomePage-Bold;
}
#spanDeblg {
  color: #2a3d9a;
  font-size: 110%;
  font-family: HomePage-Book;
}
#hAmountlg {
  color: #2a3d9a;
  margin-top: -2%;
  font-family: Gotham-Ultra;
}
#sliderlg {
  width: 80%;
  margin-top: 1%;
  margin-left: 10%;
}
#spanwholg {
  color: #2a3d9a;
  font-size: 20pt;
  margin-top: 1%;
  font-family: HomePage-Book;
}
#spaninstlg {
  font-size: 90%;
  color: #2a3d9a;
  margin-top: -5%;
  font-family: HomePage-Book;
}
#quote-des {
  position: absolute;
  color: #808080;
  width: 40%;
  top: 10%;
  right: -25%;
}
#quote-desxl {
  position: absolute;
  color: #808080;
  width: 25%;
  top: 8%;
  right: -20%;
}
#quote-calculator-desc {
  margin-top: -8%;
  margin-left: 20%;
  padding: 15%;
}
#spanCallg {
  color: #2a3d9a;
  font-size: 150%;
  font-family: HomePage-Bold;
}
#spanDeblg {
  color: #2a3d9a;
  font-size: 110%;
  font-family: HomePage-Book;
}
#hAmountlg {
  color: #2a3d9a;
  margin-top: -2%;
  font-family: Gotham-Ultra;
}
#sliderlg {
  width: 100%;
  margin-top: 1%;
  margin-left: 10%;
}
#spanwholg {
  color: #2a3d9a;
  font-size: 110%;
  margin-top: 1%;
  font-family: HomePage-Book;
}
#spaninstlg {
  font-size: 90%;
  color: #2a3d9a;
  margin-top: -5%;
  font-family: HomePage-Book;
}
#spanoplg {
  font-size: 150%;
  margin-left: -20%;
  font-family: HomePage-Bold;
}
#spanopblg {
  font-family: HomePage-Book;
}
#quoteoptlg {
  position: absolute;
  color: #808080;
  width: 80%;
  top: 40%;
  right: 30%;
}
#reviews-imglg {
  width: 40%;
  margin-left: 10rem;
}
#main-container-comments-lg {
  height: 100%;
  padding: 2rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background 1500 px.png");
}

.tooltip-inner {
  border-radius: 2rem;
  animation: pulse2 2s infinite;
  background: #f3d338;
  background: -webkit-linear-gradient(
    top,
    #f3d338 0%,
    #f3d338 44%,
    #f3d338 100%
  );
  background: -moz-linear-gradient(top, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  background: linear-gradient(to bottom, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3d338', endColorstr='#f3d338',GradientType=0 );
  color: #2a3d9a !important;
  font-family: "Roboto", Arial, sans-serif;
}

.tooltip-inner-des {
  filter: drop-shadow(0px 0px 1px black);
  border-radius: 1rem;
  animation: pulse 2s infinite;
  background: #f3d338;
  background: -webkit-linear-gradient(
    top,
    #f3d338 0%,
    #f3d338 44%,
    #f3d338 100%
  );
  background: -moz-linear-gradient(top, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  background: linear-gradient(to bottom, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f3d338', endColorstr='#f3d338',GradientType=0 );
  color: #2a3d9a !important;
  font-family: "Roboto", Arial, sans-serif;
}

.speech-bubble {
  background: #f3d338; /* el color de fondo */
  color: #2a3d9a !important;
  font-family: HomePage-Bold;
  font-size: 15px;
  padding: 5px 1px 1px 1px;
  text-align: center;
  width: 250px;
  -moz-border-radius: 10px;
  animation: pulse 2s infinite;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.speech-bubble:after {
  border: solid 10px transparent;
  border-top-color: #f3d338; /* igual al color de fondo */
  border-bottom: 0;
  bottom: -10px; /* igual al borde + el padding-bottom de la definición anterior */
  content: "";
  display: block;
  height: 0;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 0;
}

.speech-bubble-mobile {
  background: #f3d338; /* el color de fondo */
  color: #2a3d9a !important;
  font-family: HomePage-Bold;
  font-size: 15px;
  padding: 5px 1px 1px 1px;
  text-align: center;
  width: 250px;
  -moz-border-radius: 10px;
  animation: pulse2 2s infinite;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}
.speech-bubble-mobile:after {
  border: solid 10px transparent;
  border-top-color: #f3d338; /* igual al color de fondo */
  border-bottom: 0;
  bottom: -10px; /* igual al borde + el padding-bottom de la definición anterior */
  content: "";
  display: block;
  height: 0;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 0;
}
.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #f3d338 !important;
}
.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #f3d338 !important;
}
.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #f3d338 !important;
}
.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #f3d338 !important;
}

.custom-tooltip {
  display: inline-block;
  opacity: 1;
  position: relative;
}

#sliders {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  height: 15px;
  width: 80%;
  cursor: pointer;
  border-radius: 10px; /*iOS */
}

#sliders::-webkit-slider-runnable-track {
  background: #ddd;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
#sliders::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background: #f1b833;
  border-radius: 100%;
  border: 0px solid #f1b833;
  text-shadow: 1px 1px 1px #2a3d9a;
  box-shadow: -100vw 0 0 99.5vw #f3d338;
}

#sliders::-moz-range-track {
  height: 40px;
  background: #f3d338;
}

#sliders::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #f3d338;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  box-sizing: border-box;
}

#sliders::-ms-fill-lower {
  background: #f3d338;
}

#sliders::-ms-thumb {
  background: #f3d338;
  border: 2px solid #f3d338;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

#sliders::-ms-ticks-after {
  display: none;
}

#sliders::-ms-ticks-before {
  display: none;
}

#sliders::-ms-track {
  background: #f3d338;
  color: transparent;
  height: 40px;
  border: none;
}

#sliders::-ms-tooltip {
  display: none;
}

#sliders-d {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  height: 15px;
  width: 90%;
  cursor: pointer;
  border-radius: 10px; /*iOS */
}

#sliders-d ::-webkit-slider-runnable-track {
  background: #ddd;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
#sliders-d::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background:#837ff3;
  border-radius: 100%;
  border: 2px solid #837ff3;
  box-shadow: -100vw 0 0 99.5vw #2a3d9a;
}

#sliders-d ::-moz-range-track {
  height: 40px;
  background: #f3d338;
}

#sliders-d::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #f3d338;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  box-sizing: border-box;
}

#sliders-d::-ms-fill-lower {
  background: #f3d338;
}

#sliders-d::-ms-thumb {
  background: #f3d338;
  border: 2px solid #f3d338;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

#sliders-d::-ms-ticks-after {
  display: none;
}

#sliders-d::-ms-ticks-before {
  display: none;
}

#sliders-d::-ms-track {
  background: #f3d338;
  color: transparent;
  height: 40px;
  border: none;
}

#sliders-d::-ms-tooltip {
  display: none;
}
</style>
