<template>

  <div class="bg-principal col-12 mx-auto d-sm-none pt-3 text-center bg-border" style="background-color: #FFFFFF">
    <div class="row py-3">
      <div class="col-6 p-0 item-border" @click="contact">
        <i class="fa fa-whatsapp mr-1" style="color: #3452a9"></i>
        <span class="item">Contáctanos</span>
      </div>
      <div class="col-6 p-0" @click="simulator">
        <span class="item">Simula tu descuento</span>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "BarTemplate",
  methods:
      {
        contact() {
          window.open("https://api.whatsapp.com/send/?phone=%2B5215544401008&text=Hola%21+me+interesa+informaci%C3%B3n+de+DB+Menos&app_absent=0")
        },
        simulator() {
          // this.$router.push({ path: "/simula-tu-plan-de-ahorro" });

          if (this.$route.name !== 'calculator') {
            this.$router.push({ path: "/calculator"});
          } else {
            this.$router.push({ path: "/simula-tu-plan-de-ahorro" });
          }
          //console.log((this.$route.name));
        }
      }
}
</script>

<style scoped>

span {
  font-weight: normal !important;
}

.item {
  font-family: OpenSans-Bold;
  color: #3452a9;
}

.bg-border {
  border-style: solid;
  border-color: #848485;
  border-width: 1px 0px 0px 0px;
}

.item-border {
  border-style: solid;
  border-color: #3452a9;
  border-width: 0px 1px 0px 0px;
}


</style>