<template>

  <div class="bg-principal sticky">

    <b-navbar toggleable="lg"
              type="dark"
              class="col-12 bg-navbar">

      <b-navbar-brand :href="home">
        <img :src="logo" alt="logo" class="logo">
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse" class="navbar-toogle my-3">
        <i class="fa fa-bars" style="color: #2b2b58"></i>
      </b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>

        <b-navbar-nav class="m-0 p-0 ml-auto">

          <div class="ml-auto">
            <b-navbar-toggle target="nav-collapse">
              <div>
                <i class="fa fa-close" style="color: #ff5252"></i>
              </div>
            </b-navbar-toggle>
          </div>

          <b-nav-item :href="home" class="d-sm-none">
            <i class="fa fa-home mr-3"></i>
            <span class="item item-text-blue">Inicio</span>
          </b-nav-item>

          <b-nav-item :href="contact" target="_blank" v-if="!session" class="test">
            <i class="fa fa-whatsapp mr-3"></i>
            <span class="item item-text-blue">Contáctanos</span>
          </b-nav-item>

          <b-nav-item :href="referAFriend" target="_blank" v-if="!session">
            <i class="fa fa-user-secret d-md-none mr-3"></i>
            <span class="item item-text-gray">Refiere a un amigo</span>
          </b-nav-item>

          <b-nav-item @click="versionVideo = 1, dialog = true">
            <i class="fa fa-group d-md-none mr-2"></i>
            <span class="item item-text-gray">Conócenos</span>
          </b-nav-item>

          <b-nav-item @click="versionVideo = 2, dialog = true">
            <i class="fa fa-gears d-md-none mr-2"></i>
            <span class="item item-text-gray">Cómo funciona</span>
          </b-nav-item>

          <b-nav-item :href="register" v-if="!session">
            <i class="fa fa-user mr-3"></i>
            <span class="item item-text-gray">Inicia sesión</span>
          </b-nav-item>

          <b-nav-item :href="simulator" class="item-button" v-if="!session">
            <i class="fa fa-money d-md-none mr-2"></i>
            <!-- <span class="item item-text-white">Simula tu ahorro</span> -->
            <span class="item item-text-white">Simula tu descuento</span>
          </b-nav-item>

          <b-nav-item v-on:click="logout" v-if="session">
            <i class="fa fa-arrow-left d-md-none mr-3"></i>
            <span class="item item-text-gray">Cerrar sesión</span>
          </b-nav-item>

          <b-nav-item class="item-button" v-on:click="goProfile" v-if="session">
            <i class="fa fa-user-circle-o d-md-none mr-2"></i>
            <span class="item item-text-white">
              {{
                this.user ? this.user.lastname_1 ? this.user.name + ' ' + this.user.lastname_1 : this.user.name : 'Perfil'
              }}
            </span>
          </b-nav-item>

        </b-navbar-nav>

      </b-collapse>

    </b-navbar>

    <!-- Modal --->

    <div class="text-center">

      <v-dialog v-model="dialog" width="800" persistent>

        <v-card>

          <v-card-text>

            <div class="text-end">
              <v-icon
                  class="mt-3"
                  color="#2A3D99"
                  @click="dialog=false, versionVideo = 0"
              >
                mdi-close
              </v-icon>
            </div>

            <p class="title-modal">
              {{ versionVideo === 1 ? 'Conócenos' : 'Cómo funciona' }}
            </p>

            <p class="text-modal">
              {{
                versionVideo === 1
                    ? 'DB Menos es una plataforma digital enfocada en liquidar deudas atrasadas con tarjetas de crédito, departamentales o préstamos personales.'
                    : 'A través de un proceso digital de registro en línea en nuestra página web o mediante un Asistente Virtual Inteligente en WhatsApp, creamos un plan de liquidación de deuda a tu medida, una cuenta y un contrato digital.'
              }}
            </p>

            <div class="text-center">
              <video
                  v-if="versionVideo === 1"
                  autoplay
                  width="90%"
                  controls="true"
                  src="/img/HomepageNew/Videos/Quiénes somos/Quiénes somos - DB Menos.mp4"

              />
              <video
                  v-if="versionVideo === 2"
                  autoplay
                  width="90%"
                  controls="true"
                  src="/img/HomepageNew/Videos/Qué hacemos/Proceso automático MATI - WhatsApp.mp4"
              />
            </div>

          </v-card-text>

        </v-card>

      </v-dialog>

    </div>

    <bar-template/>

  </div>

</template>

<script>

import {V} from "@/V";
import moment from "moment";
import BarTemplate from "@/views/components/templates/BarTemplate";

export default {
  name: "HeaderTemplate",
  components: {BarTemplate},
  async created() {
    this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
    this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    this.$forceUpdate();
    this.getAccountData();
  },
  mounted() {
    this.$root.$on("refreshHeader", (query) => {
      this.$forceUpdate();
      this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
      this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    });
  },
  data: () => ({


    logo: '/img/HomepageNew/Desktop/Logotipo azul para fondo blanco.png',

    home: '/',
    contact: 'https://api.whatsapp.com/send/?phone=%2B5215544401008&text=Hola%21+me+interesa+informaci%C3%B3n+de+DB+Menos&app_absent=0',
    referAFriend: 'https://mate.dbmenos.com/#/login',
    register: '/register',
    // simulator: '/simula-tu-plan-de-ahorro',
    simulator: '/calculator',

    dialog: false,
    versionVideo: 1,

    session: null,
    user: {},
    account: {},
  }),
  methods:
      {
        async logout() {
          this.$store.commit("setUser", {});
          localStorage.clear();
          await this.checkSession();
          this.getAccountData();
          this.$root.$emit("refreshHeader");
          this.$router.push("/").catch((err) => {
          });
        },
        async checkSession() {
          let _session = localStorage.getItem(this.$v.STORE_KEYS.SESSION);
          if (_session) {
            this.session = _session;
            let currentDate = moment();
            let expiresDate = moment(this.session.expires, "YYYY-MM-DD");

            if (expiresDate.isSame(currentDate) || expiresDate.isBefore(currentDate)) {
              this.logout();
            } else {
              try {
                let validSession = await this.verifySession();
                if (!validSession) {
                  this.logout();
                }
              } catch (error) {
                this.logout();
              }
            }
          } else {
            this.session = {session: ""};
          }
        },
        getAccountData() {
          let _account = this.$store.getters.getAccount;

          if (_account) {
            this.account = _account;
          } else {
            this.account = {};
          }
        },
        goProfile() {
          let url = V.SERVER + V.OPS.GET_SIGNATURE_INFO;

          this.$axios.get(url, this.session).then((response) => {
            let data = response.data;
            if (data[V.API.SUCCESS]) {
              this.$router.push("/profile").catch((err) => {
              });
            }
          });
        },
      }
}
</script>

<style scoped>

.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

.logo {
  width: 9rem;
}

.bg-navbar {
  background-color: #FFFFFF !important;
}

.item-text-blue, .fa-whatsapp {
  color: #2a3d99;
}

.item-text-gray, .fa-user {
  color: #8f8f8f;
}

.item-text-white {
  color: #fff;
}

.item-button {
  border-radius: 10px;
  /* background-color: #2a3d99 !important; */
  background-color: #8ac43f !important;
}

span {
  font-size: 1rem;
}

.nav-item:hover {
  box-shadow: 0px 2px #BBBBBB !important;
}

/*Modal*/
.title-modal {
  font-family: OpenSans-Bold;
  color: #2b2b58;
}

.text-modal {
  font-family: OpenSans-Regular;
  color: #808080;
}

@media (min-width: 992px) {
  .icon-close {
    display: none;
  }
}


@media (max-width: 319px) {
  .item-button {
    background-color: #FFFFFF !important;
  }

  .item-text-white {
    color: #8f8f8f;
  }

  .fa {
    color: #2b2b58;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
  }
}

@media (min-width: 320px) and (max-width: 374px) {

  .item-button {
    background-color: #FFFFFF !important;
  }

  .item-text-white {
    color: #8f8f8f;
  }

  .fa {
    color: #2b2b58;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .item-button {
    background-color: #FFFFFF !important;
  }

  .item-text-white {
    color: #8f8f8f;
  }

  .title-modal {
    font-size: .9rem;
  }

  .text-modal {
    font-size: .8rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .item-button {
    background-color: #FFFFFF !important;
  }

  .item-text-white {
    color: #8f8f8f;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: .9rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .item-button {
    background-color: #FFFFFF !important;
  }

  .item-text-white {
    color: #8f8f8f;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  .item-button {
    text-transform: uppercase;
  }

  i {
    font-size: 1.3rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
  }

  .nav-item {
    padding: 0 !important;
    margin: 0 !important;
  }

}

@media (min-width: 1440px) and (max-width: 2559px) {

  .item {
    font-size: 1.2rem;
  }

  .item-button {
    text-transform: uppercase;
  }

  i {
    font-size: 1.3rem;
  }

  .title-modal {
    font-size: 1.1rem;
  }

  .text-modal {
    font-size: 1.1rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .item {
    font-size: 2rem;
  }

  .item-button {
    text-transform: uppercase;
  }

  i {
    font-size: 2rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
  }
}

@media (min-width: 2561px) {
  .item {
    font-size: 2rem;
  }

  .item-button {
    text-transform: uppercase;
  }

  i {
    font-size: 2rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
  }
}

</style>