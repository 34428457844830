<template>
  <div id="calculator-dev">
    <div class="bg-header"></div>
    <div id="app">
      <div class="bg-db">

        <!-- POPUP INSTRUCCIONES -->
        <template>
          <div
            class="popsteps-container"
            >
              <v-dialog
                persistent
                v-model="instructions"                
              >
                <PrevSteps @closeDialog="closeDialog"/>
              </v-dialog>

          </div>
        </template>

        <!-- STEPPER -->
        <v-app id="inspire">
          <div class="mx-auto">
            <v-stepper v-model="e1" value="3" class=" card-container">
              <v-stepper-header>

                <v-divider class="divider0"></v-divider>
                <v-stepper-step
                  :complete="e1 > 1"
                  step="1"
                >
                </v-stepper-step>
          
                <v-divider class="divider1"></v-divider>
          
                <v-stepper-step
                  :complete="e1 > 2"
                  step="2"
                >
                </v-stepper-step>
          
                <v-divider class="divider2"></v-divider>
          
                <v-stepper-step step="3">
                </v-stepper-step>

                <v-divider class="divider3"></v-divider>

              </v-stepper-header>
          
              <v-stepper-items>
                <v-stepper-content step="1" class="container-step1">
                  <v-card
                    class="mb-12 card-border"
                    color="none"
                    height="auto"
                  >
                  <div class="row justify-content-center">
                      <div>
                          <div class="row row-grid justify-content-center">
                              <div class="" >

                                  <card class="border-0" body-classes="py-5">

                                    <div class="title text-blue">
                                      <p>Para ayudarte agrega a tus acreedores</p>
                                      <p class="font-weight-bold" for="amount">¿Cuánto debes?</p>
                                    </div>

                                    <ul class="list-styles" v-for="(debt, indexdeb) in debts" :key="indexdeb">
                                      <!-- LI POR QUE SE AGREGAN EL INPUT Y EL SELECT DE NUEVO -->
                                      <li  class="list flex-list ">
                                        <!-- ITERAR INDICE DE debts -->
                                        <div class="flex-align" >
                                          
                                          <!-- INPUT PARA SELECT -->
                                          <div class="col m-input drop-styles input-size">
                                            <div>
                                              <base-dropdown class="nav-item" menu-classes="dropdown-menu-lg" style="margin-bottom: 20px; margin-left: -10px">
                                                  <a slot="title" class="nav-link" data-toggle="" role="button">
                                                      <base-button type="primary" class="btn-drop">
                                                          <span>{{debt.bank}}</span>
                                                          <span class="btn-arrow">▼</span>
                                                      </base-button>
                                                  </a>
                                                  
                                                  <div class="dropdown-menu-inner" style="overflow-x: hidden; overflow-y: scroll; height: 250px">
                                                    <ul>
                                                      <li v-for="(bank, index) in banks" :key="index" style="list-style-type: none" class="drop-li">
                                                        <a v-on:click="m_bankSelected(indexdeb, bank)" class="hover-opc media d-flex align-items-center">
                                                          <div class="">
                                                            <p class="drop-opc">{{bank.name}}</p>
                                                          </div>
                                                        </a>
                                                      </li>
                                                    </ul>                                                    
                                                  </div>

                                              </base-dropdown>
                                            </div>
                                          </div>

                                          <!-- INPUT PARA MONTO -->
                                          <div class="col m-input input-size">
                                            <div class="input-group">
                                              <span class="input-group-text">$</span>
                                              <input
                                                id="amount"
                                                v-model="debt.amount"
                                                type="number"
                                                min="0"
                                                placeholder="Ingresa el monto"
                                                class="form-control"
                                                >
                                            </div>
                                          </div>

                                          <!-- REMOVER INPUTS -->
                                          <div
                                            class="input-remove"                                                    
                                          >
                                            <a v-on:click="m_spliceDebt(index)" n block ><span class="bank-x">x</span></a>
                                          </div>

                                        </div>
                                      </li>
                                    </ul>
                                  </card>
                              </div>
                          </div>
                      </div>
                      <div class="row justify-content-center">
                          <div class="">

                            <div class="btn-container">
                              <div class="btn-box">
                                <base-button tag="a"
                                            v-on:click="m_appendDebt()"

                                            class="btn-blue text-white"
                                            type="white"
                                            icon="fa fa-plus">
                                    Agregar
                                </base-button>

                                <div class="add-acounts">
                                  <p>Cuentas agregadas</p>
                                </div>

                              </div>
                            </div>

                            <div class="carousel-ctn">
                              <div class="grid-ctn">
                              <ul class="list bank-grid">

                                <li v-for="(data, index) of debts" :key="index">
                                  
                                  <div class="bank-card" v-if="data.amount === 0 ? false : true">

                                    <div class="bank-remove">
                                      <a v-on:click="m_spliceDebt(index)" n block ><span class="bank-x">x</span></a>
                                    </div>

                                    <div class="bank-img">
                                      <img class="img-responsive" v-bind:src="data.img"/>
                                    </div>

                                    <div class="bank-text">
                                      <p>{{formatCurrency(data.amount)}}</p>
                                    </div>

                                  </div>
                                </li>

                              </ul>
                            </div>
                            </div>

                            <div class="btn-container">
                              <div class="btn-box">  
                                <base-button tag="a"
                                            v-on:click="m_calculateDiscount()"
                                            class="btn-green text-white"
                                            type="success"
                                            icon="fa fa-check">
                                    Calcula mi descuento
                                </base-button>
                              </div>
                            </div>

                          </div>
                      </div>
                  </div>
                </v-card>
         
                  <!-- <v-btn
                    color="primary"
                    @click="e1 = 2"
                  >
                    Continue
                  </v-btn>
          
                  <v-btn text>
                    Cancel
                  </v-btn> -->

                </v-stepper-content>
          
                <v-stepper-content step="2">
                  <v-card
                    class=""
                    color="none"
                    height="auto"
                  >
                    <div class="debts-acc">
                      <div class="text-blue debt-title">
                        <p class="fw-bold text-center">Elige las cuentas que quieres pagar y la duración de tu programa</p>
                      </div>

                      <div class="cards-acc">
                        <div class="carousel-ctn">
                          <ul class="list debt-grid">

                            <!-- <li v-for="(debt, id) in calculatorDebts.debts" :key="id"> -->
                            <li v-for="(debt, id) in calculatorAcc" :key="id">

                              <a
                                class="debt-container"                                
                                @change="handleChange"
                                >
                                <!-- check-card -->
                                <label class="container-check check-card">
                                  <input
                                    type="checkbox" 
                                    :id="id"
                                    :value="debt.id"
                                    checked
                                    class="checkbox"
                                  >
                                  <span class="checkmark"></span>
                                </label>

                                <div class="bank-img">
                                  <img class="img-responsive" v-bind:src="debt.img"/>
                                </div>

                                <div class="card-debt">
                                  <p>Deuda:</p>
                                  <h4>{{ formatCurrency(debt.amount) }}</h4>
                                </div>
  <!-- ELIMINAR -->
<!-- <div class="btn-cards-ctn">
  <div>
    <div >
        <base-button
        v-on:click="m_spliceCalculatorDebts(debt.id, debt.bank)"
        type="danger" 
        icon="fa fa-minus"
        >

        </base-button>
    </div>
  </div>

  <div >
    <div >
        <base-button
          v-on:click="m_pushCalculatorDebts(debt)"          
          class="btn-green text-white"
          icon="fa fa-plus"
        >
        </base-button>
    </div>
  </div>
</div> -->
  <!-- ELIMINAR -->
                                <div class="card-pay">
                                  <p>Pagarás:</p>
                                  
                                  <h4>$ 
                                  {{ formatDecPost(debt.willPay).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") }}
                                  </h4>
                                </div>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div class="range-acc">
                        <input
                          v-model.number="period"
                          type="range"
                          name="range-months"
                          id="range-months"
                          step="1" 
                          min="12"
                          max="24"                         
                          v-on:input="m_ReturnDiscountNew()"
                          >                          
                        <p>{{period}} Meses</p>
                      </div>

  <!-- ELIMINAR -->
  <!-- <div class="row">
    <div class="col">
        <base-button
        v-on:click="m_discountStep2()" 
          n 
          block type="success" 
          class="mt-4">
            OK - BANCOS
        </base-button>
    </div>
  </div> -->
  <!-- ELIMINAR -->

                    </div>
                </v-card>
          
                  <!-- <v-btn
                    color="primary"
                    @click="e1 = 3"
                  >
                    Continue
                  </v-btn>
          
                  <v-btn text>
                    Cancel
                  </v-btn> -->

                </v-stepper-content>
          
                <v-stepper-content step="3" class="container-step3">
                  <v-card
                    class="mb-12"
                    color="none"
                    height="auto"
                  >
                    <div class="">
                      <div class="text-blue">
                        <p class="fw-bold text-center">Para registrarte en nuestro programa el 1er. paso es dejarnos los siguientes datos:</p>
                      </div>
                      <div class="form-data">

                        <v-card-text>
                          <v-form v-model="valid" ref="form">

                            <v-text-field
                                label="Nombre"
                                v-model="lead.name"
                                :rules="rules.name"
                                required
                                dense
                                solo
                            ></v-text-field>

                            <v-text-field                                
                                label="Correo"
                                v-model="lead.email"
                                :rules="rules.email"
                                required
                                dense
                                solo
                            ></v-text-field>

                            <v-text-field
                                label="Teléfono"
                                v-model="lead.phone"
                                :rules="rules.phone"
                                required
                                dense
                                solo
                            ></v-text-field>

                            <div class="text-center mt-5">
                              <v-btn 
                                tag="a"                          
                                class="mb-3 mb-sm-0 btn-green text-white"
                                :loading="loading"
                                @click="getDiscount"
                                >
                                  Iniciar mi descuento
                              </v-btn>
                            </div>

                          </v-form>
                        </v-card-text>

                      </div>

                    </div>                          
                  </v-card>
          
                  <!-- <v-btn
                    color="primary"
                    @click="e1 = 3"
                  >
                    Continue
                  </v-btn>
          
                  <v-btn text>
                    Cancel
                  </v-btn> -->


                  <!-- POPUP CUENTA YA EXISTENTE -->
                  <div v-if="dialogUser">
                    <v-dialog
                      persistent
                      v-model="dialogUser"
                      max-width="800"
                    >
                      <div class="alert-close">
                        <a @click="remove()"><span class="close-x">×</span></a>
                      </div>

                      <v-card >
                        <div class="alert-container">
                          <div class="alert-card">
                            <v-card-text class="alert-img">
                              <img src="/img/alert_account.png" alt="Existing account">
                            </v-card-text>
                    
                            <v-card-text class="alert-text">
                              Ya existe una cuenta asociada a este teléfono y correo. ¡Da click aquí!
                            </v-card-text>
                    
                            <v-card-actions class="alert-btn-box">
                    
                              <v-btn
                                text                  
                                href="/register"
                                class="btn btn-green btn-green-pop text-white alert-btn"
                                height="100%"
                              >
                                Iniciar sesión
                              </v-btn>

                            </v-card-actions>
                          </div>
                        </div>              
                      </v-card>
                    </v-dialog>
                  </div>


                  <!-- POPUP CODIGO WHATSAPP -->
                  <v-dialog
                    v-model="dialog_code"
                    width="400"
                    persistent

                  >
                    <v-card>
                      <v-card-title>
                        <p style="word-break: break-word !important;">
                          Para recibir el cálculo de tu descuento enviamos un código por WhatsApp colócalo para continuar.
                        </p>
                      </v-card-title>

                      <v-card-text class="mt-5">

                        <v-form v-model="valid" ref="formCode">

                          <v-text-field
                              label="Código"
                              v-model="lead.code"
                              :rules="rules.code"
                              type="number"
                              class="input-code"
                              required

                          ></v-text-field>

                        </v-form>

                      </v-card-text>
                      <v-spacer></v-spacer>

                      <div class="text-right">
                        <v-btn
                            color="#ff0033"
                            text
                            @click="dialog_code = false"
                        >
                          Cancelar
                        </v-btn>

                        <v-btn
                            color="primary"
                            text
                            @click="insertLead"
                        >
                          Calcular
                        </v-btn>
                      </div>

                    </v-card>
                  </v-dialog>

                </v-stepper-content>
              </v-stepper-items>                            
            </v-stepper>                          
          </div>
        </v-app>

        <!-------------------- POP-UP ---------------------------------------------------->
        <div class="card-program" v-if="popBool">
          <div class="popup-container mx-auto">
            <div class="popup-title">
              <p>Programa DB Menos</p>
            </div>
            <ul class="list">

              <li class="program-container">
                <p class="program-title">En total debes:</p>
                <p class="program-info">{{ formatCurrency(calculatorDebts.totalDebt) }}</p>
              </li>
              <li class="program-container">
                <p class="program-title"> Con DB Menos paga: </p>
                <p class="program-info">{{ formatCurrency(calculatorDebts.willPay) }}</p>
              </li>
              <li class="program-container">
                <p class="program-title">Mensualidad de:</p>
                <p class="program-info">{{ formatCurrency(calculatorDebts.monthlyFee) }}</p>
              </li>
              <li class="program-container">
                <p class="program-title">Duración del programa:</p>
                <p class="program-info">{{ period }} meses</p>
              </li>
              <li class="program-container">
                <p class="program-title">Descuento obtenido:</p>
                <p class="program-info">
                  {{
                    formatDecPost(calculatorDebts.discount)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}
                  %
                </p>
              </li>

            </ul>
          </div>

          <div class="btn-container">
            <div class="btn-box">
              <base-button tag="a"                          
                @click="step3"
                class="mb-3 mb-sm-0 btn-green text-white"
                type="white">
                  Lo quiero
              </base-button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import firebase from 'firebase/app';
  import 'firebase/firestore';
  import 'firebase/analytics';
  import 'firebase/messaging';
  import BaseDropdown from "@/components/BaseDropdown";
  import Vue from "vue";
  import {V} from "@/V"
  import modal from "@/components/Modal";
  import flatPickr from "vue-flatpickr-component";
  import StepperComponent from "./components/calculator_steps/Stepper.vue";
  import PrevSteps from "./components/calculator_steps/PrevSteps.vue"

  export default {
      name: "home",
      props:
          {
            version:
              {
                request: false,
                default: "v1"
              }
          },
      components: {
      BaseDropdown,
      modal,
      flatPickr,
      StepperComponent,
      PrevSteps,
      },
      async created() {
          firebase.analytics().logEvent("calculator");
          gtag('event', 'calculator', {'send_to': 'AW-718930865/2GivCITQvKcBELGH6NYC'});
          fbq('track', 'calculator');
          /*Events v2*/
          fbq('track', 'landing_view_automated');
          firebase.analytics().logEvent("landing_view_automated");
          gtag('event', 'landing_view_automated', {'send_to': 'AW-718930865/2GivCITQvKcBELGH6NYC'});
          this.$showLoader(true)
          this.$showLoader(false)
          this.m_registerMarketing()
          this.getDay();
      },
      data() {
        return {
          e1: 1,            
          step: 1,
          calculatorAcc: [],
          rangeMonths: 0,
          checked:  true,
          payBool: true,
          popBool: false,
          instructions: true,
          checkState: true,
          // valid: false,
          // loading: false,
          play: false,
          // dialog: false,
          // leadSent: false,
          length: 4,
          dialog: false,
          dialog_code: false,
          dialogUser: false,
          valid: false,
          loading: false,
          leadSent: false,
          lead: {
              name: null,
              phone: null,
              email: null,
              banks: [],
              debt: null,
              code: ''
          },

          rules: {
              name: [v => !!v || 'El nombre es requerido'],
              phone: [
                v => !!v || 'El teléfono es requerido',
                v => (v && v.length == 10) || 'El número debe tener 10 dígitos',
              ],
              email: [
                v => !!v || 'El correo es requerido',
                v => /.+@.+/.test(v) || 'Formato invalido'
              ],
              banks: [v => !!v || 'El o los banco(s) son requeridos'],
              debt: [
                v => !!v || 'La deuda es requerida',
                v => v >= 10000 || 'La deuda mínima es de  10,000 MXN'
              ],
              code: [
                v => !!v || 'El código es requerido',
                v => (v && v.length == 4) || 'El código es de 4 dígitos',
              ],
            },

          debtData: [
            { bank: '', amount: null, img:"", boolDef: false },
          ],
          calculatorDebts: [],
          dbData: [
            {
              dbTitle: 'En total debes:'
            },
            {
              dbTitle: 'Con DB Menos paga:'
            },
            {
              dbTitle: 'Mensualidad de:'
            },
            {
              dbTitle: 'Duración del programa:'
            },
            {
              dbTitle: 'Descuento obtenido:'
            },
          ],
          debts: [{
              bankid: 0,
              bank: "Selecciona el banco",
              amount: 0,
              last_bank_payment_date :"",              
              // edit: true,
              edit: false,
              img: ""
          }],
          today:"",
          period: 18,
          overf: 'hidden',
          banksmodal: false,
          cambiardebts: false,
          reportListo: false,
          notCredit: false,
          bandera: true,
          error: false,
          fullPage: true,
          disableCuenta: false,
          period: 12,
          periodStart: null,
          overf: "hidden",
          banksmodal: false,
          digitAccount: "",
          dueMonthsBank: "",
          sliderRange: "",
          maxSlider: "",
          minSlider: "",
          calcData: {
              period: 18,
          },
          urlAsesor: "",
          phone: "",
          jsonCalculator: "",
        }
      },
      computed: {
          user() { return this.$store.state.user },
          banks() { return this.$store.state.banks },
          //getMarketing() { return this.$store.state.marketing }
      },
  methods: {

    handleChange(e) {
      const { value, checked, id } = e.target;
      //console.log(this.calculatorDebts.debts)
      //console.log(id)
      //console.log(value);

      let found = this.calculatorAcc.find(e => e.id == value);
        // console.log(found);

      if (!checked) {
        this.calculatorDebts.debts.splice(id, 1);
        // console.log(this.calculatorDebts);
        // console.log(this.calculatorAcc);
        this.$forceUpdate();


                let _debts = JSON.parse(JSON.stringify(this.calculatorDebts.debts))
// console.log("Array de datos" + (_debts));
                for (let d=0 ; d<_debts.length ; d++) {
                  _debts[d].amount = Number(_debts[d].amount)
// console.log("Deduda total:"+(_debts[d].amount = Number(_debts[d].amount)));
                  delete _debts[d].edit
// console.log(delete _debts[d].edit);                    
                }
                let user = this.user
                if (user === undefined || user === null) {
                    user = {
                        name: "",
                        lastname: "",
                        id: 1,
                    }
                }

                let _data = {
                    name: user.name,
                    lastname: user.lastname,
                    userid: user.id,
                    period: this.period,
                    debts: _debts
                }

                if (_data.name === undefined) _data.name = ""
                if (_data.lastname === undefined) _data.lastname = ""
               this.$axios
        .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR, _debts)
        .then(
          (res) => {
            let data = res.data;
            this.discount = [];
            this.calculatorDebts = [];
// console.log("Calculadora de deudas ⤋");
// console.log(this.calculatorDebts);
            this.accounts = [];
            this.sliderRange = [];
            this.period = data.row.optimalperiod;
            this.periodOptimal = data.row.optimalperiod;
            if (data.success) {
              this.reportListo = true;
              for (let i = 0; i < data.row.programs.length; i++) {
                this.sliderRange.push(data.row.programs[i].period);
                data.row.programs[i].addProgram = true;
                this.calculatorDebts.push(data.row.programs[i]);
                this.accounts = data.row.programs[i].debts;
                this.accounts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = this.calculatorDebts[
                      p
                    ].monthlyFee;
                    this.calculatorDebts.willPay = this.calculatorDebts[
                      p
                    ].willPay;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[
                      p
                    ].totalDebt;
                     this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                  }
                }
              }

              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);

              this.$forceUpdate();
            }
          },
          (er) => {
            alert("Algo salió mal, intenta nuevamente");
            //console.log(er);
          }
        );


      } else {
        this.calculatorDebts.debts.push(found);
        // console.log(this.calculatorDebts);
        this.$forceUpdate();

        let _debts = JSON.parse(JSON.stringify(this.calculatorDebts.debts))
// console.log("Array de datos" + (_debts));
                for (let d=0 ; d<_debts.length ; d++) {
                  _debts[d].amount = Number(_debts[d].amount)
//console.log("Deduda total:"+(_debts[d].amount = Number(_debts[d].amount)));
                  delete _debts[d].edit
//console.log(delete _debts[d].edit);                    
                }
                let user = this.user
                if (user === undefined || user === null) {
                    user = {
                        name: "",
                        lastname: "",
                        id: 1,
                    }
                }

                let _data = {
                    name: user.name,
                    lastname: user.lastname,
                    userid: user.id,
                    period: this.period,
                    debts: _debts
                }

                if (_data.name === undefined) _data.name = ""
                if (_data.lastname === undefined) _data.lastname = ""
               this.$axios
        .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR, _debts)
        .then(
          (res) => {
            let data = res.data;
            this.discount = [];
            this.calculatorDebts = [];
// console.log("Calculadora de deudas ⤋");
// console.log(this.calculatorDebts);
            this.accounts = [];
            this.sliderRange = [];
            this.period = data.row.optimalperiod;
            this.periodOptimal = data.row.optimalperiod;
            if (data.success) {
              this.reportListo = true;
              for (let i = 0; i < data.row.programs.length; i++) {
                this.sliderRange.push(data.row.programs[i].period);
                data.row.programs[i].addProgram = true;
                this.calculatorDebts.push(data.row.programs[i]);
                this.accounts = data.row.programs[i].debts;
                this.accounts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = this.calculatorDebts[
                      p
                    ].monthlyFee;
                    this.calculatorDebts.willPay = this.calculatorDebts[
                      p
                    ].willPay;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[
                      p
                    ].totalDebt;
                     this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                  }
                }
              }

              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);

              this.$forceUpdate();
            }
          },
          (er) => {
            alert("Algo salió mal, intenta nuevamente");
            // console.log(er);
          }
        );
      }
    },

    remove() {
      return this.dialogUser = !this.dialogUser;
    },

    //Cerrar el dialogo de instrucciones
    closeDialog() {
      this.instructions = false
    },
    clearForm() {
      this.$refs.form.reset();
    },
    calcDiscount(index) {
      let bank = this.banks[index];
      bank.selected = !bank.selected;

      Vue.set(this.banks, index, bank);

      let count = 0;
      let neg = 0;

      this.banks.forEach((bank) => {
        if (bank.selected) {
          count += 1;
          neg += bank.neg;
        }
      });

      this.averageDiscount = (count > 0) ? neg / count : 0;
    },
    getDiscount() {
      this.loading = true
      //console.log(this.loading);
      if (this.$refs.form.validate()) {
        for (const item of this.lead.banks) {
          this.calcDiscount(item);
        }
        this.insertLead()
        // this.dialog_code = true
      }
      this.loading = false
    },
    getMarketing() {
      let marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_${this.$route.query.utm_campaign}`;

      if (marketing === "undefined_undefined_undefined") {
        marketing = null;
      } else if (marketing === `${this.$route.query.utm_source}_undefined_undefined`) {
        marketing = this.$route.query.utm_source;
      } else if (marketing === `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_undefined`) {
        marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}`
      } else if (marketing === `${this.$route.query.utm_source}_undefined_${this.$route.query.utm_campaign}`) {
        marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_campaign}`;
      }
      return marketing;
    },
    insertLead() {

    if (this.leadSent) {

      if (this.$refs.formCode.validate()) {

        let _data = {
          phone: this.lead.phone,
          code: this.lead.code,
          marketing: this.getMarketing(),
        };

        let calculate = {
          estimated: this.averageDiscount,
          total: this.lead.debt
        }

        let _dataLead = {
          name: this.lead.name,
          email: this.lead.email,
          phone: this.lead.phone,
          amount: this.lead.debt,
          banks: JSON.stringify(this.lead.banks),
          same_bank_debt_paysheet: false,
          device: (this.version === 'v1') ? 'web-r' : 'web'
        };

        Vue.analytics.fbq.event("LeadComplete", {
          content_name: this.lead.name + " " + this.lead.phone + " lead validated successfully",
        });

        let url = V.SERVER + V.OPS.VALIDATE_LEAD_SMS_CODE;

        this.$http.post(url, _data).then((response) => {

              if (response.data.success) {

                this.leadModal = false;
                this.leadSent = false;

                localStorage.setItem('phone', JSON.stringify(_data))
                localStorage.setItem('calculate', JSON.stringify(calculate))
                localStorage.setItem('Lead', JSON.stringify(_dataLead))
                localStorage.setItem('marketing', this.getMarketing())

                localStorage.setItem('ph', JSON.stringify(this.lead.phone))
                localStorage.setItem('email', JSON.stringify(this.lead.email))

                //Flujo anterior
                //this.$router.push({path: "/regis"});

                //Flujo nuevo
                this.$router.push({name: "plan-personalizado"});
                window.location.reload()

                gtag("event", "Datos-validados", {
                  event_category: "Landing-liquidar-deuda-1",
                  event_label: "datos-validados",
                });

              } else {
                alert("Algo salió mal :(, intenta nuevamente");
              }

            }
        );

      }

    } else {

      this.leadSent = true;

      let headers = {"device": 'web'};

      if (this.version === "v2") {
        this.lead.email = "any@any.com"
      }

      let _data = {
        name: this.lead.name,
        email: this.lead.email,
        phone: this.lead.phone,
        amount: this.lead.debt,
        banks: JSON.stringify(this.lead.banks),
        same_bank_debt_paysheet: false,
        device: (this.version === 'v1') ? 'web-r' : 'web'
      };

      Vue.analytics.fbq.event("LeadStarting", {
        content_name: this.lead.name + " " + this.lead.phone + " lead successful started",
      });

      let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS;

      gtag("event", "conversion", {send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"});

      gtag("event", "Datos-contacto", {
        event_category: "Landing-liquidar-deuda-1",
        event_label: "datos-contacto"
      });

      this.$http.post(url, _data, {headers: headers}).then((response) => {

            if (response.data.success) {
              this.leadSent = response.data.success;
              this.dialog_code = true;
            } else {
              // alert("Algo salió mal :(, intenta nuevamente");
              this.dialogUser = true;
              this.dialog_code = false;
            }
          },
      );
    }
    },
    addPopup() {
      //this.e1 = 3;
      return this.popBool = !this.popBool;
    },
    step3() {
      if (this.e1 === 2 && this.popBool === true) {
        this.popBool = false
        return this.e1 = 3;
      }
      return this.e1 === 3 ? this.popBool = false : this.popBool = true;
    },
    getDay() {
        let f = new Date();
        let month = f.getMonth() + 1;
        if (month < 10) month = "0" + month;
        let today = new Date();
        this.today = this.$moment(today).format("YYYY-MM-DD");
    },              
    startProgram() {
        this.e1 = 3;
        if(!this.user){
        firebase
            .analytics()
            .logEvent("calculator_complete", this.jsonCalculator);

        gtag("event", "starting_leads_2", {
            send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
        });
        fbq("track", "calcuator_complete");

        this.$router.push({
            path: "/register",
        });
        }else{
            let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATOR_CR;
            for (let p = 0; p < this.calculatorDebts.length; p++) {
            if (this.period === this.calculatorDebts[p].period) {
            this.jsonCalculator = JSON.parse(
                JSON.stringify(this.calculatorDebts[p])
            );
            }
        }
        this.$axios.post(url, this.jsonCalculator).then((response) => {
            let data = response.data;
            if (data[this.$v.API.SUCCESS]) {
            firebase
                .analytics()
                .logEvent("calculator_complete", this.jsonCalculator);

            gtag("event", "starting_leads_2", {
                send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
            });
            fbq("track", "calcuator_complete");

            this.$router.push({
                path: "/start",
            });
            }
        });
        }
    },
    async m_insertMarketing() {
        let from = this.$route.query.marketing
        if (from !== undefined && from.length > 0) {
            //console.log(from)
            this.$store.commit('setMarketing', from)
            localStorage.setItem('Marketing', from)
        }
    },
    formatDecPost(val, pos) {
        if (!pos) pos = 2;
        if (val && Number(val)) return Number(val).toFixed(pos);
        return "0.00";
        },
        formatCurrency(value) {
        return this.$formatCurrency(value);
        },
    m_appendDebt() {
        this.debts.push({
            bankid: 0,
            bank: "Selecciona el banco",
            amount: 0,
            // edit: true,
            edit: false,
            img: ""
        })
    },
    m_spliceDebt(index) {
      this.debts.splice(index, 1)
      this.debtData.splice(index, 1)
    },

//////////////PARA TESTING - USAR BOTONES
    m_spliceCalculatorDebts(index) {
      this.minus = false;
      this.calculatorDebts.debts.splice(index, 1);
      this.$forceUpdate();
      // console.log(this.calculatorDebts.debts);
      // console.log(this.calculatorDebts);
    },
    m_pushCalculatorDebts(index) {
      this.minus = true;
      this.calculatorDebts.debts.push(index);
      this.$forceUpdate();
      // console.log(this.calculatorDebts.debts);
      // console.log(this.calculatorDebts);
    },
//////////////PARA TESTING - USAR BOTONES

    m_bankSelected(debt_index, bank) {
      this.debts[debt_index].bank = bank.name
      this.debts[debt_index].bankid = bank.id
      this.debts[debt_index].img = bank.url
    //console.log(debt_index);
    //console.log(bank);
      
    },
    back() {
    this.reportListo = false;
    },
    /*
    m_editDebt(index, edit) {
        this.debts[index].edit = edit
        this.$forceUpdate()
    },*/
    m_editDebt() {
      this.$forceUpdate()
      //console.log(this.debts);
    },
    getBank(id) {
        for (let i=0 ; i<this.banks.length ; i++)
            if (this.banks[i].id === id)
                return this.banks[i]
        return null
    }, 
    m_ReturnDiscountNew() {
        this.discount = [];
        this.accounts = [];
        this.sliderRange = [];
        this.periodOptimal = this.periodOptimal;
        this.period = this.period;
        this.calculatorDebts = this.calculatorDebts;
    // console.log(this.calculatorDebts);
        this.reportListo = true;
        for (let i = 0; i < this.calculatorDebts.length; i++) {
            for (let p = 0; p < this.calculatorDebts.length; p++) {
            if (this.period === this.calculatorDebts[p].period) {
                this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                this.calculatorDebts.monthlyFee = this.calculatorDebts[p].monthlyFee;
                this.calculatorDebts.willPay = this.calculatorDebts[p].willPay;
                this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
                this.calculatorDebts.discount = this.calculatorDebts[p].discount;
            }
            }
        }
        let myArray = this.sliderRange;
        this.maxSlider = Math.max.apply(Math, myArray);
        this.minSlider = Math.min.apply(Math, myArray);

        this.$forceUpdate();
      },
    m_calculateDiscount() {            
      for (let d=0 ; d<this.debts.length ; d++) {
        let debt = this.debts[d]
          if (debt.bankid === 0) {
                this.$notify({
                      text: "Necesitas seleccionar el banco correspondiente",
                      type: "error",
                  });
              return false
          } else if (debt.amount < 1500) {
              this.$notify({
                      text: "Tienes una deuda menor a $1500, solo podemos negociar mayores",
                      type: "error",
              });
              return false
          }
          /*
          else if (debt.edit) {
              this.$notify({
                      text: "Estás editando una deuda, cuando termines de editar puedes continuar",
                      type: "error",
                  });
              return false
          }*/
      }

      //Funcion de btn de Listo
      this.$forceUpdate()
      //console.log(this.debts); 

      // Next step
      this.e1 = 2;

      //Para mostrar el popup en el step2
      if (this.e1 === 2) {
        this.popBool = !this.popBool;
      }

      let _debts = JSON.parse(JSON.stringify(this.debts))
//console.log("Array de datos" + (_debts));
      for (let d=0 ; d<_debts.length ; d++) {
        _debts[d].amount = Number(_debts[d].amount)
//console.log("Deduda total:"+(_debts[d].amount = Number(_debts[d].amount)));
        delete _debts[d].edit
//console.log(delete _debts[d].edit); 
      }
      let user = this.user
      if (user === undefined || user === null) {
          user = {
              name: "",
              lastname: "",
              id: 1,
          }
      }

      let _data = {
          name: user.name,
          lastname: user.lastname,
          userid: user.id,
          period: this.period,
          debts: _debts
      }

      if (_data.name === undefined) _data.name = ""
      if (_data.lastname === undefined) _data.lastname = ""
      this.$axios
      .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR, _debts)
      .then(
        (res) => {
          let data = res.data;
          this.discount = [];
          this.calculatorDebts = [];
// console.log("Calculadora de deudas ⤋");
// console.log(this.calculatorDebts);
//this.calculatorAcc = [];
          this.accounts = [];
          this.sliderRange = [];
          this.period = data.row.optimalperiod;
          this.periodOptimal = data.row.optimalperiod;
          if (data.success) {
            this.reportListo = true;
            for (let i = 0; i < data.row.programs.length; i++) {
              this.sliderRange.push(data.row.programs[i].period);
              data.row.programs[i].addProgram = true;
              this.calculatorDebts.push(data.row.programs[i]);
              this.accounts = data.row.programs[i].debts;
              this.accounts.sort(function (a, b) {
                return b.amount - a.amount;
              });
              for (let p = 0; p < this.calculatorDebts.length; p++) {
                if (this.period === this.calculatorDebts[p].period) {
                  this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                  this.calculatorDebts.monthlyFee = this.calculatorDebts[
                    p
                  ].monthlyFee;
                  this.calculatorDebts.willPay = this.calculatorDebts[
                    p
                  ].willPay;
                  this.calculatorDebts.totalDebt = this.calculatorDebts[
                    p
                  ].totalDebt;
                    this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                }
            }
          }
          let myArray = this.sliderRange;
          this.maxSlider = Math.max.apply(Math, myArray);
            this.minSlider = Math.min.apply(Math, myArray);

//NUEVAS LINEAS solo para mostrar las cards y no se borren

this.calculatorAcc = [...this.accounts];
// console.log("calculatorACC");
// console.log(this.calculatorAcc);
            

          this.$forceUpdate();
        }
        },
        (er) => {
          alert("Algo salió mal, intenta nuevamente");
          //console.log(er);
        }
      );
    },
    async m_registerMarketing() {
        let marketing = this.$route.query.marketing
        this.$store.commit('setMarketing', marketing)
        let url = V.SERVER + V.OPS.INSERT + V.TABLES.MARKETING_LEADS
        await this.$axios.post(url, {from: marketing})
    },

    m_discountStep2() {
                let _debts = JSON.parse(JSON.stringify(this.calculatorDebts.debts))
// console.log("Array de datos" + (_debts));
                for (let d=0 ; d<_debts.length ; d++) {
                  _debts[d].amount = Number(_debts[d].amount)
// console.log("Deduda total:"+(_debts[d].amount = Number(_debts[d].amount)));
                  delete _debts[d].edit
// console.log(delete _debts[d].edit);                    
                }
                let user = this.user
                if (user === undefined || user === null) {
                    user = {
                        name: "",
                        lastname: "",
                        id: 1,
                    }
                }

                let _data = {
                    name: user.name,
                    lastname: user.lastname,
                    userid: user.id,
                    period: this.period,
                    debts: _debts
                }

                if (_data.name === undefined) _data.name = ""
                if (_data.lastname === undefined) _data.lastname = ""
               this.$axios
        .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR, _debts)
        .then(
          (res) => {
            let data = res.data;
            this.discount = [];
            this.calculatorDebts = [];
// console.log("Calculadora de deudas ⤋");
// console.log(this.calculatorDebts);
            this.accounts = [];
            this.sliderRange = [];
            this.period = data.row.optimalperiod;
            this.periodOptimal = data.row.optimalperiod;
            if (data.success) {
              this.reportListo = true;
              for (let i = 0; i < data.row.programs.length; i++) {
                this.sliderRange.push(data.row.programs[i].period);
                data.row.programs[i].addProgram = true;
                this.calculatorDebts.push(data.row.programs[i]);
                this.accounts = data.row.programs[i].debts;
                this.accounts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = this.calculatorDebts[
                      p
                    ].monthlyFee;
                    this.calculatorDebts.willPay = this.calculatorDebts[
                      p
                    ].willPay;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[
                      p
                    ].totalDebt;
                     this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                  }
                }
              }

              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);

              this.$forceUpdate();
            }
          },
          (er) => {
            alert("Algo salió mal, intenta nuevamente");
            // console.log(er);
          }
        );
    },

    
  },
  };
</script>


<style lang="scss" scoped>
  @import '../views/scss/CalculatorDev.scss'; 
</style>