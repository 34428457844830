<template>
    <stepper :options="stepperOptions">
        <div slot="step-1">
            <!-- <h3>Step 1</h3> -->
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque 
        </div>
        
        <div slot="step-2">
            <!-- <h3>Step 2</h3> -->
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque 
        </div>
        
        <div slot="step-3">
            <!-- <h3>Step 3</h3> -->
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque 
        </div>
        
        <div slot="custom-step-name">
            <!-- <h3>Step 4</h3> -->
            Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque 
        </div>
    </stepper>
</template>

<script>
import Stepper from 'vuejs-stepper'
export default {
  components: { Stepper },
  data () {
    return {
      stepperOptions: {
        headers: [
          {title: 'Title One'},
          {title: 'Title Two'},
        //   {title: 'Title Three'},
          {title: 'Title Three', stepName: 'custom-step-name'}
        ],
        prevText: 'Previous',
        nextText: 'Next'
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.top{
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    padding: 10px;
    width: 100%;
    justify-content: center;
}
.step-header{
    display: flex;
    flex-direction: column;
}
.header-indicator{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.step-header.start > .header-indicator{
    align-self: flex-end;
}
.step-header-line{
    display: inline-flex;
    width: 100px;
    height: 3px;
    background-color: grey;
}
.step-header-content{
    display: inline-flex;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: grey;
    color: white;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.step-header.active .step-header-content,
.step-header.active .step-header-line {
    background-color: rebeccapurple;
}
.title{
    display: inline-flex;
    align-self: flex-end;
    text-transform: capitalize;
    width: 100px;
    position: relative;
    right: -10%;
    justify-content: center;
}
.step-header.start > .title{
    right: -35%;
}

.steps-item{
    min-width: 100%;
}
.body{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    min-width:100%;
    flex-shrink: 0;
}
.foot{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.next-button{
    margin-left: auto;
}
button{
    padding: 7px 15px;
    color: white;
    background-color: green;
    border: 0;
    outline: 0;
    border-radius: 5px;
}
.stepper{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.stepper-slide-1-enter-active {
    animation: slide-in .3s;
}
.stepper-slide-1-leave-active {
     animation: slide-out .3s;
}
@keyframes slide-in {
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slide-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}

.stepper-slide-2-enter-active {
    animation: slide2-in .3s;
}
.stepper-slide-2-leave-active {
    animation: slide2-out .3s;
}
@keyframes slide2-in {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(0);
    }
}
@keyframes slide2-out {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(100%);
    }
}
</style>