<template>

  <div class="bg-principal">

    <div class="row col-12 sol-sm-10 mx-auto">

      <div class="col-12 col-sm-6 d-flex align-items-center order-2 order-sm-1 d-flex justify-content-sm-end">
        <img src="/img/loans/team.png" alt="team">
      </div>

      <div class="col-12 col-sm-6 d-flex align-items-center order-1 order-sm-2">

        <div class="row">

          <div class="col-12 p-0">
            <p class="text-center text-sm-left secondary-text">
              <span class="secondary-text-bold">GRACIAS</span> <br>
              por dejarnos tus datos. Nuestro <br>
              equipo te contactará en menos de <br>
              24h para ofrecerte tu préstamo.
            </p>
          </div>

          <div class="col-12 text-center text-sm-left">
            <v-btn
                color="#8ac43f"
                elevation="2"
                depressed
                dark
                v-on:click="redirect"
            >
                  <span class="text-button">
                    <b class="simple-text" style="text-transform: none">Regresar al sitio</b>
                  </span>

            </v-btn>
          </div>

        </div>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Thanks",
  methods:
      {
        redirect()
        {
          this.$router.push({path: "/prestamo-para-consolidar-tu-deuda"})
        }
      }
}
</script>

<style scoped>

.bg-principal {
  background-color: #00B2CE;
  padding: 5rem 1rem;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.secondary-text-bold {
  font-family: Gotham-Ultra;
  color: #FFFFFF;
}

@media (min-width: 320px) and (max-width: 374px) {
  .secondary-text {
    font-size: 1.2rem;
  }

  .secondary-text-bold {
    font-size: 1.8rem;
  }

  .secondary-text-bold {
    display: block !important;
  }

  .secondary-text br {
    display: none;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .secondary-text {
    font-size: 1rem;
  }

  .secondary-text-bold {
    font-size: 1.5rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .secondary-text {
    font-size: 1.1rem;
  }

  .secondary-text-bold {
    font-size: 1.7rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .secondary-text {
    font-size: 1rem;
  }

  .secondary-text-bold {
    font-size: 1.7rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .secondary-text {
    font-size: 1.2rem;
  }

  .secondary-text-bold {
    font-size: 3.8rem;
  }

  .simple-text {
    font-size: 1.1rem;
  }

  img {
    width: 50%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .secondary-text {
    font-size: 1.4rem;
  }

  .secondary-text-bold {
    font-size: 1.8rem;
  }

  .simple-text {
    font-size: 1.2rem;
  }

  img {
    width: 50%;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .secondary-text {
    font-size: 2rem;
  }

  .secondary-text-bold {
    font-size: 3rem;
  }

  .simple-text {
    font-size: 1.5rem;
  }

  img {
    width: 50%;
  }
}

</style>