<template>
  <section
    class="section section-shaped section-lg my-0"
    style="background-color: #3953d3; background-size: cover; opacity: 0.8"
  >
    <div class="container" style="padding-bottom: 15px">
      <div class="row justify-content-center">
        <div class="col-lg-10" style="opacity: 0.95">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-10 py-lg-10"
            class="border-0"
          >
            <div class="row justify-content-around align-items-center">
              <div class="col-lg-4 col-md-4 col-sm-5 col-8 text-center">
                <img
                  src="https://dbmenos.com/img/brand/DB Menos logo 2020_membrete.png"
                  class="img-fluid"
                  alt="Db menos Logo"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div class="container pt-lg-md" style="min-height: 730px">
      <div class="row justify-content-center">
        <div class="col-lg-10" style="opacity: 0.95">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-10 py-lg-10"
            class="border-0"
          >
            <template>
              <div v-if="!dontUrl" class="text-center">
                <h5>Reedirigiendo a Descargar la App</h5>
                <img src="https://dbmenos.com/img/theme/checkbox-marked-circle.png"/>
              </div>
              <div v-if="dontUrl" class="text-center">
                <h5>
                  No logramos validar el enlace, Comúnicate con tu asesor
                </h5>
                <img src="https://dbmenos.com/img/theme/close-circle.png" />
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { V } from "@/V";
import { VBModalPlugin } from "bootstrap-vue";
export default {
  name: "apps",
  components: {},
  created() {
    this.getAppDBMenos();
  },
  data() {
    return {
      V: V,
      propuesta: "",
      dataProposal: "",
      window: {
        width: 0,
        height: 0,
      },
      dontUrl: false,
      user: {
        name: null,
        address: null,
        address_2: null,
        phone: null,
        email: null,
      },
      downloadComplete:false,
      urlAndroid: "https://play.google.com/store/apps/details?id=com.dbmenos.dbm_android_client&hl=es",
      urliOS:"https://apps.apple.com/mx/app/db-menos/id1503253844?l=en"
    };
  },
  methods: {
    async getAppDBMenos() {
        this.$showLoader(true)
        console.log(this.$route.params['pathMatch'])
        if(this.$route.params['pathMatch'] == 'android'){
            this.$showLoader(false)
            window.location.href = this.urlAndroid;
            this.dontUrl = false;
        }else if(this.$route.params['pathMatch'] == 'iOS'){
            this.$showLoader(false)
             window.location.href = this.urliOS;
             this.dontUrl = false;
        }else{
            this.$showLoader(false)
            this.dontUrl = true;
        }
    },
  },
};
</script>
