<template>
  <div class="profile-page">
    <section class="section-profile-cover section-shaped my-0">
      <div class="shape shape-style-1 shape-primary shape-skew alpha-4" style="background-color: #FFFF;">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </section>
    <section class="section section-skew">
      <div class="container">
        <card shadow class="card-profile mt--300" no-body>
          <div class="px-4">
            <div class="row justify-content-center">
              <div class="col-lg-2 order-lg-2">
                <div class="card-profile-image">
                  <a href="#">
                    <img
                      v-if="avatarUrl"
                      :src="avatarUrl"
                      class="rounded-circle preview"
                      width="250px"
                      height="250px"
                    />
                    <img
                      v-if="!avatarUrl"
                      :src="'img/profile.jpg'"
                      class="rounded-circle no-avatar"
                      width="250px"
                      height="250px"
                    />
                  </a>
                  <input
                    type="file"
                    style="display: none"
                    id="user_avatar"
                    @change="setAvatar"
                  />
                </div>
                <label
                  for="user_avatar"
                  v-if="!avatar"
                  v-b-tooltip.hover.right
                  title="Recomendamos una imagen cuadrada"
                  >Editar</label
                >
                <span
                  id="user_upload_avatar"
                  v-if="avatar"
                  @click="uploadAvatar"
                  >Subir</span
                >
                <span
                  id="cancel_avatar"
                  v-if="avatar"
                  @click="[(avatar = null), (avatarUrl = null)]"
                  >Cancelar</span
                >
              </div>
              <div
                class="col-lg-5 order-lg-3 text-lg-right align-self-lg-center"
              >
                <div class="card-profile-actions py-4 mt-lg-0">
                  <base-button
                    type="info"
                    size="sm"
                    class="mr-4"
                    v-if="editBasicInfoMode"
                    v-on:click="m_updateProfile()"
                    >Guardar datos</base-button
                  >
                  <base-button
                    type="info"
                    size="sm"
                    class="mr-4"
                    v-else
                    v-on:click="editBasicInfoMode = true"
                    >Editar</base-button
                  >
                  <a
                    v-if="!editBasicInfoMode"
                    type="default"
                    size="sm"
                    class="float-right"
                    href="https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos"
                    target="_blank"
                    >¡Quiero contactar a mi asesor!</a
                  >
                </div>
                <br />
                <div class="col text-center">
                  <strong>SALDO DEL AHORRO A LA FECHA</strong>
                  <br />
                  {{ format(user ? user.balance : 0) }}
                </div>
              </div>
              <div class="col-lg-5 order-lg-1">
                <div class="card-profile-stats d-flex justify-content-center">
                  <div class="px-1">
                    <span class="heading">{{
                      user ? user.debt_count : 0
                    }}</span>
                    <span class="description">Deudas</span>
                  </div>
                  <div class="px-1">
                    <span class="heading">{{
                      user ? user.payment_count : 0
                    }}</span>
                    <span class="description">Pagos</span>
                  </div>
                  <div class="px-1">
                    <span class="heading text-orange">{{
                      format(user ? user.total_debt : 0)
                    }}</span>
                    <span class="description">Deuda</span>
                  </div>
                </div>
                <div class="col text-center">
                  <strong>ESTADO DEL PROGRAMA:</strong>
                  <br />
                  <span
                    v-if="user && user.program_status === -1"
                    style="color: red"
                    >Atrasado</span
                  >
                  <span
                    v-if="user && user.program_status === 0"
                    style="color: blue"
                    >Al corriente</span
                  >
                  <span
                    v-if="user && user.program_status === 1"
                    style="color: green"
                    >Adelantado</span
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 mt-2">
                <div class="mt-5 text-center" style="margin-bottom: 40px">
                  <h3 v-if="user && user.name !== undefined">
                    {{ user.name }} {{ user.lastname_1 }} {{ user.lastname_2 }}
                  </h3>
                  <div class="h6 font-weight-300">
                    <strong class="font-weight-600">IFE/INE/Pasaporte:</strong>
                    {{ user ? user.id_number : "" }} |
                    <strong class="font-weight-600">Telefono:</strong>
                    {{ user ? user.phone : "" }} |
                    <strong class="font-weight-600">Dirección:</strong>
                    {{
                      user
                        ? `${user.addr_street} ${user.addr_extnum}, ${
                            user.addr_intnum ? user.addr_intnum + "," : ""
                          } ${user.addr_sublocality}, ${user.locality}, ${
                            user.addr_postal_code
                          }`
                        : ""
                    }}
                  </div>
                </div>
                <div
                  class="py-3 mt-4 mb-4 border-top border-bottom text-center"
                  v-if="editBasicInfoMode"
                >
                  <div class="row justify-content-center">
                    <div class="col-lg-9">
                      <h4 class="text-center" style="margin-bottom: 30px">
                        Editar mis Datos Basicos
                      </h4>
                      <div class="row">
                        <div class="col-lg-3 col-sm-4">
                          <base-input
                            id="name"
                            placeholder="Nombre"
                            v-model="user.name"
                          ></base-input>
                        </div>
                        <div class="col-lg-3 col-sm-4">
                          <base-input
                            placeholder="Primer apellido"
                            v-model="user.lastname_1"
                          ></base-input>
                        </div>
                        <div class="col-lg-3 col-sm-4">
                          <base-input
                            placeholder="Segundo apellido"
                            v-model="user.lastname_2"
                          ></base-input>
                        </div>
                        <div class="col-lg-3 col-sm-4">
                          <base-input
                            placeholder="Teléfono"
                            v-model="user.phone"
                          ></base-input>
                        </div>
                      </div>
                      <div class="row justify-content-center">
                        <base-button
                          type="danger"
                          class="my-4"
                          v-on:click="
                            [
                              (editBasicInfoMode = false),
                              $store.dispatch('user/getUser'),
                            ]
                          "
                          >Cancelar</base-button
                        >
                        <base-button
                          type="primary"
                          class="my-4"
                          v-on:click="
                            [(editBasicInfoMode = false), updateProfile()]
                          "
                          >Actualizar datos</base-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-12 mt-2">
                <div class="row">
                  <div class="col-lg-12">
                    <div
                      class="card card-stats mb-4 mb-xl-4"
                      style="border-radius: 2rem"
                    >
                      <div class="card-body">
                        <div class="row">
                          <div class="col text-center">
                            <strong>
                              <h5>FECHA DE INGRESO AL PROGRAMA:</h5>
                            </strong>
                            <p>
                              <strong>{{ this.FechaCompletoInicio }}</strong>
                            </p>
                            <strong>
                              <h5>DURACIÓN DEL PROGRAMA:</h5>
                            </strong>
                            <p>
                              <strong
                                >{{
                                  user ? user.program_duration : 0
                                }}
                                Meses</strong
                              >
                            </p>
                            <strong>
                              <h5>ESTO ES LO QUE LLEVAS EN DB MENOS:</h5>
                            </strong>
                            <p>
                              <strong>{{ rangoMeses }} Meses</strong>
                            </p>
                            <strong>
                              <h5>FECHA FINAL DEL PROGRAMA:</h5>
                            </strong>
                            <p>
                              <strong>{{ fechaFinal }}</strong>
                            </p>

                            <base-progress
                              type="success"
                              :value="
                                Math.ceil(
                                  (rangoMeses * 100) /
                                    this.user.program_duration
                                )
                              "
                              label="Ya casi llegas, sigue así"
                            ></base-progress>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="col">
                            <strong>DÍA DE DEPOSITO MENSUAL</strong>
                            <br />
                            {{ user ? user.payday : 0 }} de cada mes
                          </div>
                          <div class="col-auto">
                            <div
                              class="icon icon-shape text-white rounded-circle shadow"
                              style="background-color: rgb(57, 83, 211)"
                            >
                              <i class="fas fa-money-bill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="card card-stats mb-4 mb-xl-4">
                      <div class="card-body">
                        <div class="row">
                          <div class="col">
                            <strong>MONTO DE PAGO MENSUAL</strong>
                            <br />
                            {{ format(user ? user.monthly_savings : 0) }}
                          </div>
                          <div class="col-auto">
                            <div
                              class="icon icon-shape text-white rounded-circle shadow"
                              style="background-color: rgb(57, 83, 211)"
                            >
                              <i class="fas fa-money-bill"></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--
                            <div class="col-lg-12 border-top mt-2 py-3">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div
                                                class="col-lg-12 col-sm-12"
                                                style="text-align: center;text-transform: uppercase;"
                                            >
                                                <label>Identificación oficial</label>
                                            </div>
                                            <div
                                                class="col-lg-12 col-sm-12 d-flex flex-column align-items-center"
                                            >
                                                <upload-files-form
                                                    id-input="doc_1"
                                                    file-type="ID"
                                                    v-if="uploadFileIdMode || (files && !files.includes('ID'))"
                                                ></upload-files-form>
                                                <a
                                                    @click="openDocument(files['ID'].filename)"
                                                    v-if="files && !uploadFileIdMode && files.includes('ID')"
                                                    style="cursor: pointer"
                                                >
                                                    <img
                                                        v-lazy="'img/icons/document.png'"
                                                        class="rounded-circle"
                                                        width="150px"
                                                    />
                                                </a>
                                                <button
                                                    class="edit-file"
                                                    @click="uploadFileIdMode = !uploadFileIdMode"
                                                    v-if="files && files.includes('ID')"
                                                >{{ uploadFileIdMode ? 'Cancelar' : 'Editar' }}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div
                                                class="col-lg-12 col-sm-12"
                                                style="text-align: center;text-transform: uppercase;"
                                            >
                                                <label>Comprobante de domicilio</label>
                                            </div>
                                            <div
                                                class="col-lg-12 col-sm-12 d-flex flex-column align-items-center"
                                            >
                                                <upload-files-form
                                                    id-input="doc_2"
                                                    file-type="ADDRESS"
                                                    v-if="uploadFileAddressMode || (files && !files.includes('ADDRESS'))"
                                                ></upload-files-form>
                                                <a
                                                    @click="openDocument(files['ADDRESS'].filename)"
                                                    v-if="!uploadFileAddressMode && files && files.includes('ADDRESS')"
                                                    style="cursor: pointer"
                                                >
                                                    <img
                                                        v-lazy="'img/icons/document.png'"
                                                        class="rounded-circle"
                                                        width="150px"
                                                    />
                                                </a>
                                                <button
                                                    class="edit-file"
                                                    @click="uploadFileAddressMode = !uploadFileAddressMode"
                                                    v-if="files && files.includes('ADDRESS')"
                                                >{{ uploadFileAddressMode ? 'Cancelar' : 'Editar' }}</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="row">
                                            <div
                                                class="col-lg-12 col-sm-12"
                                                style="text-align: center;text-transform: uppercase;"
                                            >
                                                <label>Buro de Credito</label>
                                            </div>
                                            <div
                                                class="col-lg-12 col-sm-12 d-flex flex-column align-items-center"
                                            >
                                                <upload-files-form
                                                    id-input="doc_3"
                                                    file-type="CREDIT_INFO"
                                                    v-if="uploadFileCreditMode || (files && !files.includes('CREDIT_INFO'))"
                                                ></upload-files-form>
                                                <a
                                                    @click="openDocument(files['CREDIT_INFO'].filename)"
                                                    v-if="!uploadFileCreditMode && files && files.includes('CREDIT_INFO')"
                                                    style="cursor: pointer"
                                                >
                                                    <img
                                                        v-lazy="'img/icons/document.png'"
                                                        class="rounded-circle"
                                                        width="150px"
                                                    />
                                                </a>
                                                <button
                                                    class="edit-file"
                                                    @click="uploadFileCreditMode = !uploadFileCreditMode"
                                                    v-if="files && files.includes('CREDIT_INFO')"
                                                >{{ uploadFileCreditMode ? 'Cancelar' : 'Editar' }}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            !-->
              <button
                id="show-btn-"
                class="btn btn-primary btn-lg btn-block"
                @click="showModal"
              >
                ¿Comó depositar?
              </button>
              <b-modal size="lg" ref="my-modal" hide-footer title="¿Comó depositar?">
                <div class="d-block" >
                  <div v-if="user.clabe == 'En proceso' ">
                  <span class="display-4 text-blue"><b>Transferencia</b></span>
                  <br />
                  <img src="img/banks/BBVA.png" 
                      width="100px"
                      height="100px"
                      style="margin-top:1.5rem; margin-bottom:1.5rem;" />
                  <div class="CLABE" style="font-size:20px;">
                    <span class="font-weight-light">CLABE: </span>
                    <span><b>012180001105538407</b></span>
                  </div>
                  <div class="Beneficiario" style="font-size:20px;">
                     <span class="font-weight-light">Beneficiario: </span>
                    <span><b>Financiera Mexicana para el Desarrollo Rural</b></span>
                  </div>
                  <div class="Beneficiario" style="font-size:20px;">
                     <span class="font-weight-light">Concepto (Referencia BBVA): </span>
                    <span><b>{{ this.user.bbva }}</b></span>
                  </div>
                  <div class="Beneficiario" style="font-size:20px;">
                     <span class="font-weight-light">Monto a depositar: </span>
                    <span><b>${{ this.user.monthly_savings }}</b></span>
                  </div>
                </div>
                <br />
                <div v-if="user.clabe != 'En proceso' " style="margin-top:-1rem;">
                  <span class="display-4 text-blue" ><b>Transferencia</b></span>
                  <div style="font-size:25px; margin-bottom:1rem; margin-top:1rem;">
                    <span><b>Selecciona banco STP</b></span>
                  </div>
                  <div class="CLABE" style="font-size:20px;">
                    <span class="font-weight-light">CLABE: </span>
                    <span><b>{{ this.user.clabe }}</b></span>
                  </div>
                  <div class="Beneficiario" style="font-size:20px;">
                     <span class="font-weight-light">Monto a depositar: </span>
                    <span><b>${{ this.user.monthly_savings }}</b></span>
                  </div>
                  <div class="Beneficiario" style="font-size:20px;">
                     <span class="font-weight-light">Beneficiario: </span>
                    <span><b>{{this.user.name}} {{this.user.lastname_1}} {{this.user.lastname_2}}</b></span>
                  </div>
                </div>
                </div>
                <hr />
                <div class="d-block" >
                  <h1 class="display-4 text-blue"><b> Depósito </b>(practicaja)</h1>
                  <img src="img/banks/BBVA.png" 
                      width="100px"
                      height="100px"
                      style="margin-top:1.5rem; margin-bottom:1.5rem;" />
                  <div style="font-size:20px;">
                    <span class="font-weight-light" >1. </span>
                    <span><b>Pago de servicios</b></span>
                    <br />
                    <span class="font-weight-light">2. Ir a opción</span>
                    <span>
                      <b> Otro</b>
                    </span>
                    <br />
                    <span class="font-weight-light"
                      >3. Anotar número de Convenio </span
                    >
                    <span>
                      <b>1436295</b>
                    </span>
                    <br />
                    <span class="font-weight-light"
                      >4. Teclear la referencia </span
                    >
                    <span>
                      <b>{{ this.user.bbva }}</b>
                    </span>
                    <br />
                    <span class="font-weight-light"
                      >5. Ingresa la cantidad </span
                    >
                    <span>
                      <b>${{ this.user.monthly_savings }}</b>
                    </span>
                  </div>
                </div>
                <b-button
                  class="mt-3"
                  variant="outline-danger"
                  block
                  @click="hideModal"
                  >Cerrar Ventana</b-button
                >
              </b-modal>
              <br />
              <div class="container mt--9 text-center" style="margin-top: 2rem">
                <div class="row">
                  <div class="col-lg-12 center">
                    <div class="row align-items-center">
                      <div class="col-lg-12 center">
                        <h1 class="display-4 anuncio">
                          Controla 24/7 tu plan desde tu celular
                        </h1>
                        <span>Descarga nuestra aplicación DB Menos</span>

                        <br />

                        <a
                          href="https://play.google.com/store/apps/details?id=com.dbmenos.dbm_android_client&hl=es"
                          target="_blank"
                        >
                          <img class="imgGoogle" src="img/googleplay.png" width="100px"/>
                        </a>
                        <a
                          href="https://apps.apple.com/mx/app/db-menos/id1503253844?l=en"
                          target="_blank"
                        >
                          <img class="imgApple" src="img/appstore.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="container mt--7 text-center"
                v-if="user.step_active > 0"
              >
                <div class="row">
                  <div class="col-lg-12 center">
                    <div class="row align-items-center">
                      <div class="col-lg-12 center">
                        <span
                          >Descarga Nuestra APK de Bloqueador de Llamadas DB
                          Menos</span
                        >
                        <br />
                        <base-button
                          type="success"
                          size="lg"
                          class="mr-4"
                          style="margin-bottom: 1rem"
                          v-on:click="showModalAPK()"
                          >Descargar APK</base-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <b-modal
                ref="my-modalAPK"
                class="text-center"
                size="lg"
                hide-footer
                title="Como descargar e Instalar la APK del Bloqueador de Llamadas DBMenos"
              >
                <div class="row text-center">
                  <div class="col-12">
                    <span
                      >Si deseas ver un comó instalarlo de una forma más detallada, <a href="https://dbmenos.s3-us-west-1.amazonaws.com/documentos/Tutorial+Descargar+e+Instalar+APK+dbmenos.pdf" target="_blank">CLICK AQUÍ</a></span
                    >
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-12">
                    <br>
                    <span
                      >1. Entra desde tu celular a dbmenos.com e inicia sesión y
                      al entrar a tu perfil descarga la APK dando click en el
                      siguiente botón:</span
                    >
                    <div>
                      <a
                        href="https://dbmenos.s3-us-west-1.amazonaws.com/documentos/dbmenos_app.apk"
                        target="_blank"
                      >
                        <img class="imgApple" src="img/download-apk.png" />
                      </a>
                    </div>
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-12">
                    <span
                      >2. Una vez descargada la Apk nos arrojará una alerta de
                      Abrir</span
                    >
                    <img
                      style="
                        width: 20rem;
                        margin-bottom: 1rem;
                        margin-top: 1rem;
                      "
                      src="img/tutorialApk/1.jpeg"
                    />
                  </div>
                </div>
                <div class="row text-center">
                  <div class="col-12">
                    <span>3. Seleccionamos el Instalador de Paquetes</span>
                    <br />
                    <img
                      style="
                        width: 15rem;
                        margin-bottom: 1rem;
                        margin-top: 1rem;
                      "
                      src="img/tutorialApk/2.jpeg"
                    />
                  </div>
                  <div class="row text-center">
                  <div class="col-12">
                    <span
                      >4. En caso de que nos pida alguna Configuración de
                      Seguridad de Chrome para instalar la aplicación debemos
                      darle click en Configuración, despúes activar la tarea
                      de permitir la Instalación de Apps y listo nos
                      regresaremos en la fecha y continuaremos con la instalación</span
                    >
                    <br>
                    <span
                      >*En caso de que no nos aparezca este mensaje podemos
                      continuar con el siguiente paso*</span
                    >
                  </div>
                  </div>
                  <div class="container text-center">
                    <div class="row" style="margin-top:1rem; margin-bottom:1rem;">
                        <div class="col">
                        <img src="img/tutorialApk/3.jpeg" />
                        </div>
                        <div class="col">
                        <img src="img/tutorialApk/4.jpeg" />
                        </div>
                        <div class="col">
                        <img src="img/tutorialApk/5.jpeg" />
                        </div>
                    </div>
                    </div>
                    <div class="row text-center">
                  <div class="col-12">
                    <span
                      >5. A continuación, le daremos en instalar y nos arrojara una alerta de Play Protect, en este caso se le dara click en Instalar de Todas Formas y continuara instalando</span
                    >
                    <br>
                    <span
                      >*Si llega a fallar y no se instala con éxito la app, necesitas desinstalar la aplicación de dbmenos ya instalada y repetir desde el paso 2*</span
                    >
                  </div>
                  </div>
                  <div class="container text-center">
                    <div class="row" style="margin-top:1rem; margin-bottom:1rem;">
                        <div class="col">
                        <img src="img/tutorialApk/6.jpeg" />
                        </div>
                        <div class="col">
                        <img src="img/tutorialApk/7.jpeg" />
                        </div>
                    </div>
                    </div>
                     <div class="row text-center">
                  <div class="col-12">
                    <span
                      >6. Si todo salió bien nos mostrará una ventana en la cual le daremos Abrir para poder mostrar la aplicación e iniciar sesión con su usuario</span
                    >
                  </div>
                  </div>
                  <div class="container text-center">
                    <div class="row" style="margin-top:1rem; margin-bottom:1rem;">
                        <div class="col">
                        <img src="img/tutorialApk/8.jpeg" />
                        </div>
                        <div class="col">
                        <img src="img/tutorialApk/9.jpeg" />
                        </div>
                    </div>
                    </div>
                     <div class="row text-center">
                  <div class="col-12">
                    <span
                      >7. Una vez iniciada la sesión en la aplicación, con su télefono y contraseña o código nos iremos al menú inferior y le daremos click en el apartado que dice MENÚ, y ahi nos aparecerá el bloqueador de llamadas para poder utilizarlo nos pedirán aceptar unos permisos</span
                    >
                  </div>
                  </div>
                  <div class="container text-center">
                    <div class="row" style="margin-top:1rem; margin-bottom:1rem;">
                        <div class="col">
                        <img src="img/tutorialApk/11.jpeg" />
                        </div>
                        <div class="col">
                        <img src="img/tutorialApk/12.jpeg" />
                        </div>
                        <div class="col">
                        <img src="img/tutorialApk/13.jpeg" />
                        </div>
                    </div>
                    </div>
                    <div class="row text-center">
                  <div class="col-12">
                    <span>8. Listo ya podrás útilizar nuestro bloqueador de llamadas, Ingresa los números de los bancos o instituciones que deseas bloquear para así no poder recibir ninguna llamada de ellos.</span
                    >
                  </div>
                  </div>
                </div>
              </b-modal>
            </div>

            <div class="row py-3">
              <div class="col-lg-12 border-top">
                <tabs fill class="flex-column flex-md-row">
                  <div class="card shadow">
                    <div class="card-body p-0">
                      <tab-pane title="DEUDAS" class="px-3">
                        <span slot="title">MIS CUENTAS</span>
                        <div class="row">
                          <div
                            class="col-12 py-3 border-bottom"
                            v-for="account in accounts"
                            :key="account.id"
                          >
                            <div class="text-right py-2" v-if="account.paid">
                              <div class="text-right">
                                <button
                                  class="btn mr-2"
                                  v-if="account.paid_auth"
                                  @click="openDocument(account.paid_auth)"
                                >
                                  Autorizacion
                                </button>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-12 text-center">
                                <div
                                  class="icon icon-shape bg-secondary rounded-circle text-white"
                                  style="margin-top: 10px"
                                >
                                  <div class="g-item">
                                    <img
                                      class="img-responsive"
                                      v-bind:src="getBank(account.bank).url"
                                      style="
                                        height: 100%;
                                        width: 100%;
                                        object-fit: contain;
                                      "
                                    />
                                  </div>
                                </div>
                                <div>{{ getBank(account.bank).name }}</div>
                                <br />
                                <div class="text-left">
                                  Deuda: {{ format(account.amount) }}
                                </div>

                                <div
                                  class="d-flex justify-content-between"
                                  style="
                                    width: 100%;
                                    height: 1rem;
                                    padding: 5px;
                                    background: #0000ff;
                                    color: black;
                                  "
                                  :style="{
                                    width:
                                      account.discount > 0
                                        ? Math.ceil(
                                            (64 /
                                              accounts[accounts.length - 1]
                                                .amount) *
                                              account.amount
                                          ) + '%'
                                        : '0',
                                  }"
                                ></div>
                                <div class="text-left">
                                  Descuento hasta la Fecha:
                                  {{
                                    account.discount > 0
                                      ? Math.ceil(
                                          100 -
                                            (account.discount /
                                              account.amount) *
                                              100
                                        ) + "%"
                                      : "0%"
                                  }}
                                </div>

                                <div
                                  class="d-flex justify-content-between"
                                  style="
                                    width: 100%;
                                    height: 1rem;
                                    padding: 5px;
                                    background: #ffec33;
                                    color: black;
                                    text-align: left;
                                  "
                                  :style="{
                                    width:
                                      account.discount > 0
                                        ? Math.ceil(
                                            100 -
                                              (account.discount /
                                                account.amount) *
                                                100
                                          ) + '%'
                                        : '0%',
                                  }"
                                ></div>
                                <br />
                                <div>
                                  <base-button
                                    block
                                    type="primary"
                                    @click="
                                      showModalInfo(
                                        getBank(account.bank).name,
                                        getBank(account.bank).url,
                                        account.id,
                                        account.amount,
                                        account.discount,
                                        Math.ceil(
                                          100 -
                                            (account.discount /
                                              account.amount) *
                                              100
                                        ),
                                        account.account
                                      )
                                    "
                                    >Más Información</base-button
                                  >
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  class="text-right py-2"
                                  v-if="account.paid"
                                >
                                  <span class="text-right mr-3"
                                    >Fecha de Pago:
                                    {{
                                      new Date(
                                        account.paid
                                      ).toLocaleDateString()
                                    }}</span
                                  >

                                  <button
                                    class="btn"
                                    v-if="account.paid_file"
                                    @click="openDocument(account.paid_file)"
                                  >
                                    Finiquito
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </tab-pane>
                      <b-modal ref="my-modalInfo" hide-footer>
                        <div class="text-center">
                          <img
                            v-bind:src="this.BankImg"
                            style="height: 40%; width: 40%; object-fit: contain"
                          />
                          <br />
                          <br />
                          <h1 class="display-4">
                            {{ format(this.totAccount) }}
                          </h1>
                          <p>
                            <small>Total adeudado</small>
                          </p>
                          <h1 class="display-4">
                            {{ format(this.totDAccount) }}
                          </h1>
                          <p>
                            <small>Con DB Menos</small>
                          </p>
                          <h1 class="display-4">{{ this.AhoPorce }}</h1>
                          <p>
                            <small>Ahorro</small>
                          </p>
                          <h1 class="display-4">{{ this.AccoNum }}</h1>
                          <p>
                            <small>Cuenta</small>
                          </p>
                          <button
                            class="btn btn-primary btn-lg btn-block"
                            @click="hideModalInfo"
                          >
                            Cerrar
                          </button>
                        </div>
                      </b-modal>
                      <tab-pane title="MIS PAGOS">
                        <div class="p-4 text-center">
                          <base-button
                            block
                            type="primary"
                            @click="showModalPayment"
                            >SUBIR NUEVO PAGO</base-button
                          >
                          <hr />
                          <div class="table-responsive">
                            <table class="table align-items-center table-flush">
                              <thead class="thead-light">
                                <tr>
                                  <th class="text-center" scope="col">Fecha</th>
                                  <th class="text-center" scope="col">
                                    Monto Abonado
                                  </th>
                                  <th class="text-center" scope="col">
                                    Validado
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(payment, index) in payments"
                                  :key="index"
                                >
                                  <td class="text-center">
                                    {{ formatDate(payment.date) }}
                                  </td>
                                  <td class="text-center">
                                    {{ format(payment.amount) }}
                                  </td>
                                  <td class="text-center">
                                    {{ payment.validated ? "SI" : "NO" }}
                                  </td>
                                </tr>

                                <tr v-if="payments && !payments.length">
                                  <td colspan="4" class="text-center">
                                    No se encontraron pagos realizados.
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <b-modal
                            ref="my-modalPayment"
                            hide-footer
                            title="NUEVO PAGO"
                          >
                            <div class="row">
                              <div class="col-md-6">
                                <div class="form-group">
                                  <flat-pickr
                                    v-validate="'required'"
                                    v-model="paymentForm.date"
                                    :config="{}"
                                    class="form-control"
                                    placeholder="Fecha del pago"
                                    data-vv-as="Fecha"
                                    name="date"
                                    style="background-color: #ffffff"
                                  ></flat-pickr>
                                  <span
                                    v-show="errors.has('paymentForm.date')"
                                    style="color: red"
                                    >{{
                                      errors.first("paymentForm.date")
                                    }}</span
                                  >
                                </div>
                              </div>
                              <div class="col-md-6">
                                <div class="form-group">
                                  <input
                                    v-validate="'required|decimal:3'"
                                    data-vv-as="Monto"
                                    v-model="paymentForm.amount"
                                    type="text"
                                    class="form-control"
                                    placeholder="Monto del pago"
                                    name="amount"
                                  />
                                  <span
                                    v-show="errors.has('paymentForm.amount')"
                                    style="color: red"
                                    >{{
                                      errors.first("paymentForm.amount")
                                    }}</span
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="form-group">
                                  <b-form-file
                                    name="file"
                                    v-model="file"
                                    :state="Boolean(file)"
                                    placeholder="Seleccione un archivo o arrastre aquí"
                                    drop-placeholder="Arrastre aquí..."
                                    @change="processFile($event)"
                                  ></b-form-file>
                                </div>
                              </div>
                            </div>
                            <div class="form-group">
                              <button
                                class="btn btn-primary btn-lg btn-block"
                                @click="newPayment"
                              >
                                ENVIAR
                              </button>
                            </div>
                          </b-modal>
                        </div>
                      </tab-pane>
                    </div>
                  </div>
                </tabs>
              </div>
            </div>
          </div>
        </card>
      </div>
    </section>
  </div>
</template>



<script>
import UploadFilesForm from "@/views/components/UploadFilesForm.vue";
import Tabs from "@/components/Tabs/Tabs.vue";
import TabPane from "@/components/Tabs/TabPane.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import BaseDropdown from "../components/BaseDropdown";
import Dropbox from "../components/Dropbox";
import UserFilesTable from "./Tables/UserFilesTable";
import moment, { max } from "moment";
import numeral from "numeral";
import { V } from "../V";
import Vue from "vue";
import VueSimpleAlert from "vue-simple-alert";
import FunctionalCalendar from "vue-functional-calendar";
import firebase from 'firebase/app';
    import 'firebase/messaging';

Vue.use(FunctionalCalendar, {
  dayNames: ["Lu", "Ma", "Mi", "Ju", "Vi", "Sa", "Do"],
  monthNames: [
    "Enero",
    "Febrero",
    "Marzo",
    "Abril",
    "Mayo",
    "Junio",
    "Julio",
    "Agosto",
    "Septiembre",
    "Octubre",
    "Noviembre",
    "Diciembre",
  ],
});

Vue.use(VueSimpleAlert);
export default {
  components: {
    Tabs,
    UploadFilesForm,
    TabPane,
    flatPickr,
  },
  mounted() {},
  async created() {
    this.$showLoader(true);
    await this.$store.dispatch("getProfile");
    await this.$store.dispatch("getUserFiles");
    await this.$store.dispatch("getAccounts");
    await this.$store.dispatch("getPayments");
    this.$showLoader(false);

    this.getAvatar();

    this.notifyMe(this.tokenFCM);
  },
  updated: function () {
    //this.getAvatar();
  },
  watch: {
    user(val) {
      if (val) {
        this.getAvatar();
        this.$alert(
          "Recuerda pagar los días " +
            this.user.payday +
            " de todos los meses, \n La cantidad de " +
            this.format(this.user.monthly_savings)
        );
      }
    },
  },
  data: () => ({
    paymentForm: {
      date: "",
      amount: undefined,
      image: {},
    },
    profilePicUrl: "",
    formErrors: [],
    modalApkTuto: false,
    paymentTableData: [],
    editBasicInfoMode: false,
    editProfilePictureMode: false,
    uploadFileIdMode: false,
    newPaymentModal: false,
    uploadFileAddressMode: false,
    uploadFileCreditMode: false,
    modalReferencia: false,

    pagination: {
      default: 1,
    },
    file: null,
    avatar: null,
    avatarUrl: "",
    fechaFinal: "",
    rangoMeses: "",
    idAccount: "",
    totAccount: "",
    totDAccount: "",
    AhoPorce: "",
    AccoNum: "",
    BankNam: "",
    BankImg: "",
    FechaCompletoInicio: "",
    diaInicio: "",
    mesInicio: "",
    aInicio: "",
    idU: "",
    tokenFCM: "",
    today: "",
  }),
  computed: {
    user() {
      return this.$store.state.profile;
    },
    //user() { return this.$store.state.user.profile },
    session() {
      return this.$store.state.app.SESSION;
    },
    files() {
      return this.$store.state.userFiles;
    },
    accounts() {
      return this.$store.state.accounts;
    },
    payments() {
      return this.$store.state.payments;
    },
  },
  methods: {
    calcularfechaFinal() {
      var meses = new Array(
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre"
      );
      var e = new Date(this.user.program_creation);
      e.setMonth(e.getMonth() + this.user.program_duration);

      this.fechaFinal =
        e.getDay() + "/" + meses[e.getMonth()] + "/" + e.getFullYear();

      var date1 = new Date(this.user.program_creation);
      this.diaInicio = date1.getDate();
      this.mesInicio = meses[date1.getMonth()];
      this.aInicio = date1.getFullYear();
      this.FechaCompletoInicio =
        this.diaInicio + "/" + this.mesInicio + "/" + this.aInicio;
      var date2 = new Date();
      var year1 = date1.getFullYear();
      var year2 = date2.getFullYear();
      var month1 = date1.getMonth();
      var month2 = date2.getMonth();
      if (month1 === 0) {
        month1++;
        month2++;
      }
      var r = (year2 - year1) * 12 + (month2 - month1);
      this.rangoMeses = r + "";
    },
    styleWidth(account) {
      var numeroMayor = this.accounts.sort((a, b) => {
        return a.amount - b.amount;
      });
    },
    showModalInfo(
      BancName,
      BancIma,
      idA,
      totalAde,
      totalDB,
      AhorroPor,
      AccountNo
    ) {
      this.$refs["my-modalInfo"].show();
      this.idAccount = idA + "";
      this.totAccount = "$" + totalAde + "";
      this.totDAccount = "$" + totalDB + "";
      this.AhoPorce = AhorroPor + "%";
      this.AccoNum = AccountNo + "";
      this.BankNam = BancName + "";
      this.BankImg = BancIma + "";
    },
    hideModalInfo() {
      this.$refs["my-modalInfo"].hide();
    },
    showModalPayment() {
      this.$refs["my-modalPayment"].show();
    },
    showModalAPK() {
      this.$refs["my-modalAPK"].show();
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideModalPayment() {
      this.$refs["my-modalPayment"].hide();
    },
    format(num) {
      return numeral(num).format("$0,0.00");
    },
    updateProfile() {
      if (this.user.id === 0) {
        return false;
      }
      let data = {
        id: this.user.id,
        name: this.user.name,
        lastname_1: this.user.lastname_1,
        lastname_2: this.user.lastname_2,
        phone: this.user.phone,
      };
      this.$axios.post(V.SERVER + V.OPS.UPDATE + V.TABLES.USERS, data).then(
        (res) => {
          if (res.body.success) {
            localStorage.setItem("ACCOUNT", JSON.stringify(this.user));
            this.editBasicInfoMode = false;
          } else {
            switch (res.body.reason) {
              case 5:
                this.request_status = "Ya está registrado ese correo";
                break;
              default:
                this.request_status = "Algo salió mal, intenta nuevamente";
                break;
            }
          }
        },
        (er) => {
          console.log("err");
          console.log(er);
        }
      );
    },

    getBank(id) {
      this.calcularfechaFinal();
      let profile = this.$store.state.user.profile;
      return this.$store.state.banks.filter((i) => i.id === id)[0];
    },
    async newPayment(e) {
      e.preventDefault();
      try {
        await this.$validator.validate();
      } catch (exception) {
        return;
      }

      if (
        this.paymentForm.date &&
        this.paymentForm.amount &&
        this.paymentForm.image
      ) {
        let filename = new FormData();
        let f = new Date();
        let month = f.getMonth() + 1;
        let day = f.getDate();
        let minute = f.getMinutes();
        let hour = f.getHours();
        let second = f.getSeconds();
        if (month < 10) month = "0" + month;
        if (day < 10) day = "0" + day;
        if (minute < 10) minute = "0" + minute;
        if (hour < 10) hour = "0" + hour;
        if (second < 10) second = "0" + second;
        this.today =
          "payment_" +
          this.user.id +
          "_" +
          f.getFullYear() +
          "-" +
          month +
          "-" +
          day +
          " " +
          hour +
          ":" +
          minute +
          ":" +
          second +
          ".png";
        filename.append("file", this.paymentForm.image, this.today);
        let headers = {
          amount: this.paymentForm.amount,
          "payment-date": this.paymentForm.date,
        };

        this.$showLoader(true);
        this.$axios
          .post(V.SERVER + V.OPS.UPLOAD_PAYMENT, filename, { headers: headers })
          .then((res) => {
            this.$showLoader(false);
            this.$store.dispatch("getPayments");
            this.paymentForm.date = "";
            this.paymentForm.amount = undefined;
            this.paymentForm.image = {};
            this.hideModalPayment();
            this.$alert("Pago creado exitosamente");
          })
          .then(() => {
            this.errors.clear();
          })
          .catch((e) => {
            console.log(e);
            this.$showLoader(false);
            4;
            this.$alert("No se logró subir, intente nuevamente");
          });
      } else
        this.$alert(
          "Falta llenar algunos campos. Verifícalo e intenta nuevamente"
        );
    },

    notifyMe(tokenFCM) {
      var messaging = firebase.messaging();
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          messaging
            .getToken()
            .then((tokenFCM) => {
              if (tokenFCM) {
                this.tokenFCM = tokenFCM;
                let url = V.SERVER + V.OPS.SET_FCM_TOKEN;
                this.$axios.post(url, { token: tokenFCM }).then((response) => {
                  let data = response.data;
                  if (data[V.API.SUCCESS]) {
                  }
                });
              } else {
                // Show permission request.
                console.log(
                  "No Instance ID token available. Request permission to generate one."
                );
              }
            })
            .catch((err) => {
              console.log("An error occurred while retrieving token. ", err);
              showToken("Error retrieving Instance ID token. ", err);
            });
        }
      });
    },
    setToken(token) {
      let url = V.SERVER + V.OPS.SET_FCM_TOKEN;
      this.$axios.post(url, { token: token }).then((response) => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          console.log("Token Enviado");
        }
      });
    },

    processFile(e) {
      let files = e.target.files;
      if (!files.length) return;
      for (let i = files.length - 1; i >= 0; i--) {
        this.paymentForm.image = files[i];
      }
    },
    openDocument(filename) {
      window.open(
        V.SERVER +
          V.OPS.GET_USER_FILE +
          "?filename=" +
          filename +
          "&session=" +
          this.session.session +
          "&redirect=true"
      );
    },
    async getAvatar() {
      if (this.user) {
        try {
          const res = await this.$axios.get(
            V.SERVER + V.OPS.GET_PROFILE_PICTURE_URL + "?user=" + this.user.id
          );
          this.avatarUrl = res.data;
          this.$forceUpdate();
        } catch (error) {
          console.log('Error');
        }
      }
    },
    setAvatar(e) {
      // this.calcularfechaFinal();
      this.avatarUl = null;
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;

      for (let i = files.length - 1; i >= 0; i--) {
        this.avatar = files[i];
      }

      this.avatarUrl = URL.createObjectURL(this.avatar);

      document.getElementById("user_avatar").value;
    },
    uploadAvatar() {
      this.$showLoader(true);
      this.calcularfechaFinal();
      let avatarData = new FormData();
      avatarData.append("image", this.avatar);
      this.$axios
        .post(V.SERVER + V.OPS.UPLOAD_FILE, avatarData, {
          headers: {
            "Content-Type": "multipart/form-data",
            "file-type": "PROFILE",
            "user-id": String(this.user.id),
          },
        })
        .then((res) => {
          this.$store.dispatch("getProfile");
          this.$store.dispatch("getUserFiles");
          this.avatar = null;
          this.$alert("Foto cargada exitosamente");
          this.$showLoader(false);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
    },
  },
};
</script>

<style>
.CLABE {
  margin-bottom: 0.5rem;
}
.Beneficiario {
  margin-bottom: 0.5rem;
}
.card.card-inverse.card-primary {
  background-color: rgba(57, 83, 211, 0.85);
  color: white;
  text-align: center;
}

.profile-page .card-profile .card-profile-image img {
  transform: translate(-50%, -10%) !important;
}

.section-profile-cover {
  height: 394px !important;
  background-size: cover;
  background-position: center center;
}

label[for="user_avatar"] {
  position: absolute;
  bottom: -61px;
  right: 3px;
  background-color: #3953d3;
  border-radius: 30px;
  color: white;
  padding: 16px 6px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
  z-index: 900000000;
}

span#user_avatar {
  position: absolute;
  bottom: -13px;
  right: -37px;
  background-color: #4caf50;
  border-radius: 30px;
  color: white;
  padding: 7px 19px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
  z-index: 900000000;
}
#anuncio {
  font-family: "Segoe UI";
}
span#cancel_avatar {
  position: absolute;
  bottom: -49px;
  right: -11px;
  background-color: #f44336;
  border-radius: 30px;
  color: white;
  padding: 7px 8px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 10px;
  cursor: pointer;
  z-index: 900000000;
}

span#user_upload_avatar {
  position: absolute;
  bottom: -55px;
  left: 5px;
  background-color: #080bbd;
  border-radius: 30px;
  color: white;
  padding: 7px 8px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  cursor: pointer;
  z-index: 900000000;
}

.edit-file {
  border: none;
  margin-top: 10px;
  background-color: #4b5f83;
  color: white;
  text-transform: uppercase;
  padding: 1px 9px;
  border-radius: 2px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
}

@media (min-width: 300px) {
  .imgGoogle {
    width: 70%;
  }
  .imgApple {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .imgGoogle {
    width: 60%;
    margin-right:5rem;
  }
  .imgApple {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .imgGoogle {
    width: 50%;
  }
  .imgApple {
    width: 40%;
  }
}

@media (min-width: 1200px) {
  .imgGoogle {
    width: 30%;
  }
  .imgApple {
    width: 25%;
  }
}
</style>
