<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0"
               style="background-image: url('img/dbm/faq.jpg'); background-size: cover; opacity: 0.80">
        <div class="shape shape-style-1 shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-6" style="margin-top: 60px;">
                <h6 class="display-1 text-center" style="color: #3953d3">¡Déjanos responder tus preguntas!</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- 1st Hero Variation -->
    </div>
    <section class="section section-lg bg-secondary">
      <div class="container">
        <div class="row justify-content-center text-center mb-lg">
          <div class="col-lg-12">
            <h2 class="display-3" style="color: #3953d3;">¿DB Menos paga mis deudas y yo le pago a DB Menos?</h2>
          </div>
          <div class="col-lg-12" style="margin-top: 20px">
            <h6>No, eso incrementaría tu deuda; con DB Menos harás depósitos a tu cuenta, DB Menos hará la negociación y
              reconstruyes tu historial</h6>
          </div>
        </div>
        <div class="row justify-content-center text-center mb-lg">
          <div class="col-lg-12">
            <h2 class="display-3" style="color: #3953d3;">¿Dónde deposito?</h2>
          </div>
          <div class="col-lg-12" style="margin-top: 20px">
            <h6>En tu cuenta bancaría; DB Menos creará tu cuenta digital, y tu dinero será vigilado por la comisión
              nacional bancaria y de valores (CBNV)</h6>
          </div>
        </div>

        <!--<div class="row">
            <div class="col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div class="px-4">
                    <img v-lazy="'img/theme/ic_person.png'"
                         class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                         style="width: 200px;">
                    <div class="pt-4 text-center">
                        <h5 class="title">
                            <span class="d-block mb-1">Plan personalizado</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div class="px-4">
                    <img v-lazy="'img/theme/ic_document.png'"
                         class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                         style="width: 200px;">
                    <div class="pt-4 text-center">
                        <h5 class="title">
                            <span class="d-block mb-1">Sin filas ni burocracia</span>
                        </h5>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-lg-4 mb-5 mb-lg-0">
                <div class="px-4">
                    <img v-lazy="'img/brand/favicon.png'"
                         class="rounded-circle img-center img-fluid shadow shadow-lg--hover"
                         style="width: 200px;">
                    <div class="pt-4 text-center">
                        <h5 class="title">
                            <span class="d-block mb-1">Desde la palma de tu mano</span>
                        </h5>
                    </div>
                </div>
            </div>
        </div>-->
      </div>
    </section>
    <!--<section class="section bg-ligth">
        <div class="container">
            <div class="row row-grid align-items-center">
                <div class="col-md-5">
                    <h2 class="display-3">¿Qué necesito para </h2><span class="display-3 font-weight-bold text-blue">SOLUCIONAR</span><h2 class="display-3"> mis deudas?</h2>
                </div>
                <div class="col-md-7">
                    <div class="pl-md-5">
                        <p class="font-weight-bold text-warning">
                            <icon class="fa fa-user" size="md" style="margin: 10px" type="primary" shadow rounded></icon>Ser mayor de 18 años</p>
                        <p class="font-weight-bold text-warning">
                            <icon class="fa fa-money" size="md" style="margin: 10px" type="primary" shadow rounded></icon>Deuda mayor a $10,000.00 pesos</p>
                        <p class="font-weight-bold text-warning">
                            <icon class="fa fa-clock-o" size="md" style="margin: 10px" type="primary" shadow rounded></icon>Tener pagos atrasados</p>
                        <p class="font-weight-bold text-warning">
                            <icon class="fa fa-credit-card" size="md" style="margin: 10px" type="primary" shadow rounded></icon>Deudas con tarjetas de crédito o prestamos personales</p>
                    </div>
                </div>
            </div>
        </div>
    </section>-->

  </div>
</template>

<script>
import Vue from "vue";
import modal from "@/components/Modal"
import {V} from "@/V"

export default {
  name: "home",
  components: {
    modal
  },
  created() {
    this.m_getBanks()
  },
  data() {
    return {
      V: V,
      simpleCalc: {
        amount: 10,
        banks: []
      },
      leadInfo: {
        name: "",
        email: "",
        phone: ""
      },
      banks: [],
      SERVER: "https://s3.amazonaws.com/dbmenos.frontend/",
      leadModal: false,
      estimatedDiscountModal: false,
      averageDiscount: 0
    }
  },
  methods: {
    m_getBanks() {
      let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS
      this.$http.post(url, {}).then(res => {
        if (res.body.success) {
          this.banks = res.body.rows
          console.log("banks loaded")
          this.banks.forEach(b => {
            b.selected = false
          })
        }
      }, er => {
        console.log("err")
        console.log(er)
      })
    },
    m_switchSelected(index) {
      let bank = this.banks[index]
      bank.selected = !bank.selected
      Vue.set(this.banks, index, bank)
      let count = 0
      let neg = 0
      this.banks.forEach(bank => {
        if (bank.selected) {
          count += 1
          neg += bank.neg
        }
      })
      if (count > 0) {
        this.averageDiscount = neg / count
      } else {
        this.averageDiscount = 0
      }
      console.log(this.averageDiscount)
    },
    m_hasValidEmail() {
      let regex = new RegExp('[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b')
      return (regex.test(this.leadInfo.email))
    },
    m_hasValidPhone() {
      let regex = new RegExp('^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$')
      return (regex.test(this.leadInfo.phone) && this.leadInfo.phone.length === 10)
    },
    m_hasValidName() {
      return this.leadInfo.name.length > 2
    },
    m_insertLead() {
      this.estimatedDiscountModal = true
      let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS
      let _data = {
        name: this.leadInfo.name,
        email: this.leadInfo.email,
        phone: this.leadInfo.phone,
        amount: this.simpleCalc.amount * 1000,
        device: 'web',
        banks: []
      }
      this.banks.forEach(bank => {
        if (bank.selected) _data.banks.push(bank.id)
      })
      _data.banks = JSON.stringify(_data.banks)
      this.$http.post(url, _data).then(res => {
      }, er => {
        console.log("err")
        console.log(er)
      })
    }
  }
};
</script>
