<template>
    <div class="position-relative" v-if="this.client">
        <section class="section-shaped my-4" style="background-color:#3953D3;">
            <div class="shape shape-style-1 shape-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container shape-container" style=" margin:-12rem;">
                <div class="col px-0">
                    <div class="row"></div>
                </div>
            </div>
        </section>

        <section class="section section-lg pt-lg-0 mt--300 sta" style="background-color:#3953D3;">
            <!--
            <div class="mt-3">
                <b-pagination v-model="pag" :total-rows="rows" align="center"></b-pagination>
            </div>
            !-->
            <div v-if="!emailExist" class="container mt--7" v-bind:style="{cursor:pointer2}">
                <div class="col-lg-12">
                    <h1 class="display-3 text-white">
                        Verifica que tus datos sean los correctos para poder continuar.
                    </h1>
                </div>
                <div class="row">
                    <div class="col-lg-12 center">
                        <card shadow type="secondary">
                            <div slot="header" class="border-0">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h6 id="tit">INFORMACIÓN GENERAL</h6>
                                    </div>
                                </div>
                            </div>
                            <template>
                                <form @submit.prevent>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-lg-4">
                                                <base-input
                                                    alternative
                                                    label="Nombre"
                                                    v-bind:disabled="client.name"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.name"
                                                    v-on:input="calculate()"
                                                />
                                            </div>
                                            <div class="col-lg-4">
                                                <base-input
                                                    alternative
                                                    v-bind:disabled="client.lastname_1"
                                                    label="Primer apellido"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.lastname_1"
                                                    v-on:input="calculate()"
                                                />
                                            </div>
                                            <div class="col-lg-4">
                                                <base-input
                                                    alternative
                                                    label="Segundo apellido"
                                                    v-bind:disabled="client.lastname_2"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.lastname_2"
                                                    v-on:input="calculate()"
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-4" v-bind:class="isPhoneValid">
                                                <base-input
                                                    alternative
                                                    label="Teléfono"
                                                    placeholder="10 dígitos"
                                                    v-bind:disabled="client.phone"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.phone"
                                                    :maxlength="10"
                                                />
                                            </div>
                                            <div
                                                class="col-lg-5"
                                                v-bind:class="isEmailValid"
                                                style="alignment: bottom"
                                            >
                                                <base-input
                                                    alternative
                                                    label="Correo electrónico"
                                                    v-bind:disabled="client.email"
                                                    input-classes="form-control-alternative"
                                                    type="email"
                                                    v-model="client.email"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </template>
                            <hr class="my-4" />
                            <template>
                                <form @submit.prevent>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <base-input
                                                    alternative
                                                    label="Calle"
                                                    v-bind:disabled="client.addr_street"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_street"
                                                />
                                            </div>
                                            <div class="col-md-2">
                                                <base-input
                                                    alternative
                                                    label="Número exterior"

                                                    v-bind:disabled="client.addr_extnum"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_extnum"
                                                />
                                            </div>
                                            <div class="col-md-2">
                                                <base-input
                                                    alternative
                                                    label="Número interior"
                                                    v-bind:disabled="client.addr_intnum"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_intnum"
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-3">
                                                <base-input
                                                    alternative
                                                    v-bind:disabled="client.addr_postal_code"
                                                    label="Código postal"
                                                    input-classes="form-control-alternative"
                                                    :maxlength="5"
                                                    v-model="client.addr_postal_code"
                                                    v-on:input="postalCodeUpdated"
                                                    v-on:focus="alertCP"
                                                />
                                            </div>
                                            <div class="col-lg-1">
                                                <base-dropdown v-if="postalCodes.length > 1" >
                                                    <a
                                                        class="dropdown-item"
                                                        v-for="(postalCode, index) in postalCodes"
                                                        :key="index"
                                                        v-on:click="postalCodeSelected(postalCode)"
                                                    >{{postalCode.postal_code}}</a>
                                                </base-dropdown>
                                            </div>
                                            <div class="col-lg-3">
                                                <base-input
                                                    alternative
                                                    label="Colonia"
                                                    v-bind:disabled="client.addr_sublocality"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_sublocality"
                                                />
                                            </div>
                                            <div class="col-lg-1">
                                                <base-dropdown v-if="sublocalities.length > 0">
                                                    <a
                                                        class="dropdown-item"
                                                        v-for="(sublocality, index) in sublocalities"
                                                        :key="index"
                                                        v-on:click="sublocalitySelected(sublocality)"
                                                    >{{sublocality}}</a>
                                                </base-dropdown>
                                            </div>
                                            <div class="col-lg-4">
                                                <base-input
                                                    alternative
                                                    label="Ciudad"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.locality"
                                                />
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <b-alert
                                            :show="dismissCountDown_cp"
                                            dismissible
                                            fade
                                            variant="primary"
                                            @dismissed="dismissCountDown_cp=0"
                                            @dismiss-count-down="countDownChanged(1)"
                                        >Puedes seleccionar una Colonia de la lista, después de introducir el código postal.</b-alert>
                                    </div>
                                </form>
                            </template>
                            <!--
                            <small>Al momento de generar tu RFC y CURP, verifica que sean correctos.</small>
                            <br />
                            <small>(Se generará con tan solo poner tu Fecha de Nacimiento y Lugar de Nacimiento)</small>
                            <hr class="my-4" />
                            <template>
                                <form @submit.prevent>
                                    <div class="pl-lg-12 text-center">
                                        <div class="row">
                                            <div class="col-lg-6 text-center separar">
                                                <base-dropdown @change="calculate()">
                                                    <base-button
                                                        slot="title"
                                                        type="outline"
                                                        class="dropdown-toggle"
                                                        size="sm"
                                                        style="margin-top: 15px; white-space: normal"
                                                        :class="gender == null ? 'text-danger' : ''"
                                                    >{{gender ? 'Género ' + gender : "Selecciona género"}}</base-button>
                                                    <a
                                                        class="dropdown-item"
                                                        v-on:click="setGender('Masculino')"
                                                    >Masculino</a>
                                                    <a
                                                        class="dropdown-item"
                                                        v-on:click="setGender('Femenino')"
                                                    >Femenino</a>
                                                </base-dropdown>
                                                <br />
                                                <base-dropdown @change="calculate()">
                                                    <base-button
                                                        slot="title"
                                                        type="outline"
                                                        class="dropdown-toggle"
                                                        size="sm"
                                                        style="margin-top: 10px; white-space: normal"
                                                        :class="stateOfBirth === null ? 'text-danger' : ''"
                                                    >{{stateOfBirth ? 'Nacido en ' + stateOfBirth:"Estado de nacimiento"}}</base-button>
                                                    <a
                                                        class="dropdown-item"
                                                        v-for="(state, index) in mxStates"
                                                        :key="index"
                                                        v-on:click="setMxState(state)"
                                                    >{{state.state}}</a>
                                                </base-dropdown>
                                            </div>
                                            <div class="col-lg-5">
                                                <label
                                                    class="form-control-label"
                                                >Fecha de nacimiento</label>
                                                <flat-pickr
                                                    v-on:input="calculate()"
                                                    v-model="client.birthday"
                                                    :config="{}"
                                                    class="form-control form-control-alternative"
                                                    data-vv-as="Fecha"
                                                    name="date"
                                                    style="background-color: #FFFFFF"
                                                />
                                            </div>
                                            <div
                                                class="row text-center align-items-center calcular"
                                            >
                                                <div class="col-lg-6">
                                                    <base-button
                                                        slot="title"
                                                        class="btn calcularbtn"
                                                        size="lg"
                                                        style="background-color:#FFD527; border-color:#FFD527; color:#3953D3;"
                                                        v-on:click="calculate"
                                                    >Calcular CURP y RFC</base-button>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <base-input
                                                    alternative
                                                    label="RFC"
                                                    placeholder="13 dígitos"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.rfc"
                                                    onchange="calcular()"
                                                />
                                            </div>
                                            <div class="col-lg-3">
                                                <base-input
                                                    alternative
                                                    label="CURP"
                                                    placeholder="18 dígitos"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.curp"
                                                    onchange="calcular()"
                                                />
                                            </div>
                                            <div class="col-lg-6 text-center">
                                                <base-input
                                                    alternative
                                                    label="Identificación oficial"
                                                    placeholder="INE o pasaporte"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.id_number"
                                                    v-on:focus="alertINE"
                                                />
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <b-alert
                                            :show="dismissCountDown_curp"
                                            dismissible
                                            fade
                                            variant="warning"
                                            @dismissed="dismissCountDown_curp=0"
                                            @dismiss-count-down="countDownChanged(2)"
                                        >Falta llenar algunos campos. Complétalos para poder calcular tu CURP y RFC.</b-alert>
                                        <b-alert
                                            :show="dismissCountDown_ine"
                                            dismissible
                                            fade
                                            variant="primary"
                                            @dismissed="dismissCountDown_ine=0"
                                            @dismiss-count-down="countDownChanged(3)"
                                        >
                                            <img class="pr-5" src="/img/ine.png" />
                                            13 dígitos en el caso de la INE.
                                        </b-alert>
                                        <b-alert
                                            :show="dismissCountDown_ine"
                                            dismissible
                                            fade
                                            variant="primary"
                                            @dismissed="dismissCountDown_ine=0"
                                        >*También puede ser el número de pasaporte.</b-alert>
                                    </div>
                                </form>
                            
                            </template>
                            <small>Te pedimos tomes una foto de tu IFE en una superficie plana sin que se vea tu mano ni flash.</small>
                            <br />
                            <small>(Sera la mejor foto de tu vida)</small>
                            <hr class="my-4" />
                            !-->
                            <template>
                                <form @submit.prevent>
                                <!--
                                    <div class="container pt-lg-md-3">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-5 py-4">
                                                <div
                                                    class="col-lg-12 col-sm-12"
                                                    style="text-align: center;text-transform: uppercase;"
                                                >
                                                    <label>Identificación frente</label>
                                                </div>
                                                <div>
                                                    <dropbox
                                                        text
                                                        type="ID"
                                                        :userid="this.client.id"
                                                        :files="this.userFiles"
                                                        v-on:uploadFile="uploadFileFrente"
                                                    ></dropbox>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 py-4">
                                                <div
                                                    class="col-lg-12 col-sm-12"
                                                    style="text-align: center;text-transform: uppercase;"
                                                >
                                                    <label>Identificación reverso</label>
                                                </div>
                                                <div>
                                                    <dropbox
                                                        text
                                                        type="ID_B"
                                                        :userid="this.client.id"
                                                        :files="this.userFiles"
                                                        v-on:uploadFile="uploadFileReverso"
                                                    ></dropbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="my-4" />

                                    !-->
                                    <b-alert
                                        :show="dismissCountDown_doc_success"
                                        dismissible
                                        fade
                                        variant="success"
                                        @dismissed="dismissCountDown_doc_success=0"
                                        @dismiss-count-down="countDownChanged(5)"
                                    >{{ fileToUpload }} cargado exitosamente.</b-alert>
                                    <b-alert
                                        :show="dismissCountDown_doc_failure"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_doc_failure=0"
                                        @dismiss-count-down="countDownChanged(6)"
                                    >Error en la carga de {{ fileToUpload }}</b-alert>

                                    <b-alert
                                        :show="dismissCountDown_end"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_end=0"
                                        @dismiss-count-down="countDownChanged(4)"
                                    >Campos incompletos:{{ incompletos }}</b-alert>

                                    <div class="row d-flex justify-content-end">
                                        <div class="col-6 text-right my-2">
                                            <!--
                                            <a
                                                :href="urlAsesor"
                                                target="_blank"
                                                class="nav-link navbar-brand"
                                            >
                                                <base-button
                                                    slot="title"
                                                    type="primary"
                                                    class="btn"
                                                    size="sm"
                                                >Contacta a tu asesor</base-button>
                                            </a>
                                            !-->
                                            <base-button
                                                slot="title"
                                                type="primary"
                                                class="btn"
                                                size="lg"
                                                v-on:click="finalCheck"
                                            >Continuar</base-button>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
            <div v-if="emailExist" class="container mt--7" v-bind:style="{cursor:pointer2}">
                <div class="col-lg-12">
                    <h1 class="display-3 text-white">
                    El correo <b>{{lead.email}}</b> ya se encuentra con otro perfil, te pedimos que ingreses otro correo para poder continuar.
                    </h1>
                </div>
                <div class="row">
                    <div class="col-lg-12 center">
                        <card shadow type="secondary">
                            <div slot="header" class="border-0">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h6 id="tit">INFORMACIÓN GENERAL</h6>
                                    </div>
                                </div>
                            </div>
                            <template>
                                <form @submit.prevent>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div
                                                class="col-lg-12"
                                                v-bind:class="isEmailValid"
                                                style="alignment: bottom"
                                            >
                                                <base-input
                                                    alternative
                                                    label="Correo electrónico"
                                                    input-classes="form-control-alternative"
                                                    type="email"
                                                    v-model="client.email"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </template>
                            <template>
                                <form @submit.prevent>
                                    <b-alert
                                        :show="dismissCountDown_doc_success"
                                        dismissible
                                        fade
                                        variant="success"
                                        @dismissed="dismissCountDown_doc_success=0"
                                        @dismiss-count-down="countDownChanged(5)"
                                    >{{ fileToUpload }} cargado exitosamente.</b-alert>
                                    <b-alert
                                        :show="dismissCountDown_doc_failure"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_doc_failure=0"
                                        @dismiss-count-down="countDownChanged(6)"
                                    >Error en la carga de {{ fileToUpload }}</b-alert>

                                    <b-alert
                                        :show="dismissCountDown_end"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_end=0"
                                        @dismiss-count-down="countDownChanged(4)"
                                    >Campos incompletos:{{ incompletos }}</b-alert>

                                    <div class="row d-flex justify-content-end">
                                        <div class="col-6 text-right my-2">
                                            <base-button
                                                slot="title"
                                                type="primary"
                                                class="btn"
                                                size="lg"
                                                v-on:click="finalCheck"
                                            >Continuar</base-button>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </card>
                    </div>
                </div>
            </div>

            <!--
            <div v-if="pag==2" class="container mt--7" v-bind:style="{cursor:pointer2}">
                <div class="row">
                    <div class="col-lg-12 center">
                        <card shadow type="secondary">
                            <div slot="header" class="border-0">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h6 id="tit">DIRECCIÓN</h6>
                                    </div>
                                </div>
                                <small>
                                    Solo ingresa tu calle, numero
                                    y colocar el CP, lo demás lo hacemos nosotros por ti,
                                </small>
                                <br />
                                <small>Solo verifica que tus datos sean correctos.</small>
                            </div>
                            <template>
                                <form @submit.prevent>
                                    <div class="pl-lg-4">
                                        <div class="row">
                                            <div class="col-md-8">
                                                <base-input
                                                    alternative
                                                    label="Calle"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_street"
                                                />
                                            </div>
                                            <div class="col-md-2">
                                                <base-input
                                                    alternative
                                                    label="Número exterior"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_extnum"
                                                />
                                            </div>
                                            <div class="col-md-2">
                                                <base-input
                                                    alternative
                                                    label="Número interior"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_intnum"
                                                />
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-3">
                                                <base-input
                                                    alternative
                                                    label="Código postal"
                                                    input-classes="form-control-alternative"
                                                    :maxlength="5"
                                                    v-model="client.addr_postal_code"
                                                    v-on:input="postalCodeUpdated"
                                                    v-on:focus="alertCP"
                                                />
                                            </div>
                                            <div class="col-lg-1">
                                                <base-dropdown v-if="postalCodes.length > 1">
                                                    <a
                                                        class="dropdown-item"
                                                        v-for="(postalCode, index) in postalCodes"
                                                        :key="index"
                                                        v-on:click="postalCodeSelected(postalCode)"
                                                    >{{postalCode.postal_code}}</a>
                                                </base-dropdown>
                                            </div>
                                            <div class="col-lg-3">
                                                <base-input
                                                    alternative
                                                    label="Colonia"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.addr_sublocality"
                                                />
                                            </div>
                                            <div class="col-lg-1">
                                                <base-dropdown v-if="sublocalities.length > 0">
                                                    <a
                                                        class="dropdown-item"
                                                        v-for="(sublocality, index) in sublocalities"
                                                        :key="index"
                                                        v-on:click="sublocalitySelected(sublocality)"
                                                    >{{sublocality}}</a>
                                                </base-dropdown>
                                            </div>
                                            <div class="col-lg-4">
                                                <base-input
                                                    alternative
                                                    label="Ciudad"
                                                    input-classes="form-control-alternative"
                                                    v-model="locality"
                                                />
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <b-alert
                                            :show="dismissCountDown_cp"
                                            dismissible
                                            fade
                                            variant="primary"
                                            @dismissed="dismissCountDown_cp=0"
                                            @dismiss-count-down="countDownChanged(1)"
                                        >
                                            Puedes seleccionar una Colonia de la lista, después de introducir el código postal.
                                           
                                        </b-alert>
                                        <div class="row d-flex justify-content-end">
                                            <div class="col-6 text-right my-2">
                                                <base-button
                                                    slot="title"
                                                    type="secondary"
                                                    class="btn"
                                                    size="sm"
                                                    v-on:click="decrem"
                                                >Anterior</base-button>
                                                <base-button
                                                    slot="title"
                                                    type="primary"
                                                    class="btn"
                                                    size="sm"
                                                    v-on:click="increm"
                                                >Siguiente</base-button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
            
            <div v-if="pag==3" class="container mt--7" v-bind:style="{cursor:pointer2}">
                <div class="row">
                    <div class="col-lg-12 center">
                        <card shadow type="secondary">
                            <div slot="header" class="border-0">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h6 id="tit">INFORMACIÓN ADICIONAL</h6>
                                    </div>
                                </div>
                            </div>
                            <template>
                                <form @submit.prevent>
                                    <div class="pl-lg-4 text-center">
                                        <div class="row">
                                            <div class="col-lg-2 text-center">
                                                <base-dropdown>
                                                    <base-button
                                                        slot="title"
                                                        type="outline"
                                                        class="dropdown-toggle"
                                                        size="sm"
                                                        style="margin-top: 15px; white-space: normal"
                                                        :class="gender == null ? 'text-danger' : ''"
                                                    >{{gender ? 'Género ' + gender : "Selecciona género"}}</base-button>
                                                    <a
                                                        class="dropdown-item"
                                                        v-on:click="setGender('Masculino')"
                                                    >Masculino</a>
                                                    <a
                                                        class="dropdown-item"
                                                        v-on:click="setGender('Femenino')"
                                                    >Femenino</a>
                                                </base-dropdown>
                                                <base-dropdown>
                                                    <base-button
                                                        slot="title"
                                                        type="outline"
                                                        class="dropdown-toggle"
                                                        size="sm"
                                                        style="margin-top: 10px; white-space: normal"
                                                        :class="stateOfBirth === null ? 'text-danger' : ''"
                                                    >{{stateOfBirth ? 'Nacido en ' + stateOfBirth:"Estado de nacimiento"}}</base-button>
                                                    <a
                                                        class="dropdown-item"
                                                        v-for="(state, index) in mxStates"
                                                        :key="index"
                                                        v-on:click="setMxState(state)"
                                                    >{{state.state}}</a>
                                                </base-dropdown>
                                            </div>
                                            <div class="col-lg-2">
                                                <label
                                                    class="form-control-label"
                                                >Fecha de nacimiento</label>
                                                <flat-pickr
                                                    v-model="client.birthday"
                                                    :config="{}"
                                                    class="form-control form-control-alternative"
                                                    data-vv-as="Fecha"
                                                    name="date"
                                                    style="background-color: #FFFFFF"
                                                />
                                            </div>
                                            <div
                                                class="row text-center align-items-center calcular"
                                            >
                                                <div class="col-lg-4">
                                                    <base-button
                                                        slot="title"
                                                        type="info"
                                                        class="btn"
                                                        size="sm"
                                                        v-on:click="calculate"
                                                    >Calcular CURP y RFC</base-button>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <base-input
                                                    alternative
                                                    label="RFC"
                                                    placeholder="13 dígitos"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.rfc"
                                                />
                                            </div>
                                            <div class="col-lg-4">
                                                <base-input
                                                    alternative
                                                    label="CURP"
                                                    placeholder="18 dígitos"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.curp"
                                                />
                                            </div>
                                            <div class="col-lg-2 text-center">
                                                <base-dropdown>
                                                    <base-button
                                                        slot="title"
                                                        type="outline"
                                                        class="dropdown-toggle"
                                                        size="sm"
                                                        style="margin-top: 15px; white-space: normal"
                                                        :class="client.civil_status === null ? 'text-danger' : ''"
                                                    >{{client.civil_status ? client.civil_status : "Estado civil"}}</base-button>
                                                    <a
                                                        class="dropdown-item"
                                                        v-on:click="clientCivilStatusSelected('Casado')"
                                                    >Casado</a>
                                                    <a
                                                        class="dropdown-item"
                                                        v-on:click="clientCivilStatusSelected('Soltero')"
                                                    >Soltero</a>
                                                </base-dropdown>
                                                <base-dropdown>
                                                    <base-button
                                                        slot="title"
                                                        type="outline"
                                                        class="dropdown-toggle"
                                                        size="sm"
                                                        style="margin-top: 10px; white-space: normal"
                                                        :class="client.economic_activity === null ? 'text-danger' : ''"
                                                    >{{client.economic_activity ? getEconomicActivity() : "Actividad económica"}}</base-button>
                                                    <a
                                                        class="dropdown-item"
                                                        v-for="(activity, index) in economicActivities"
                                                        :key="index"
                                                        v-on:click="setEconomicActivity(activity.id)"
                                                    >{{activity.activity}}</a>
                                                </base-dropdown>
                                            </div>
                                            <div class="col-lg-5 text-center">
                                                <base-input
                                                    alternative
                                                    label="Identificación oficial"
                                                    placeholder="INE o pasaporte"
                                                    input-classes="form-control-alternative"
                                                    v-model="client.id_number"
                                                    v-on:focus="alertINE"
                                                />
                                            </div>
                                        </div>
                                        <hr class="my-4" />
                                        <b-alert
                                            :show="dismissCountDown_curp"
                                            dismissible
                                            fade
                                            variant="warning"
                                            @dismissed="dismissCountDown_curp=0"
                                            @dismiss-count-down="countDownChanged(2)"
                                        >Falta llenar algunos campos. Complétalos para poder calcular tu CURP y RFC.</b-alert>
                                        <b-alert
                                            :show="dismissCountDown_ine"
                                            dismissible
                                            fade
                                            variant="primary"
                                            @dismissed="dismissCountDown_ine=0"
                                            @dismiss-count-down="countDownChanged(3)"
                                        >
                                            <img class="pr-5" src="/img/ine.png" />
                                            13 dígitos en el caso de la INE.
                                        </b-alert>
                                        <b-alert
                                            :show="dismissCountDown_ine"
                                            dismissible
                                            fade
                                            variant="primary"
                                            @dismissed="dismissCountDown_ine=0"
                                        >*También puede ser el número de pasaporte.</b-alert>
                                        <div class="row d-flex justify-content-end">
                                            <div class="col-6 text-right my-2">
                                                <base-button
                                                    slot="title"
                                                    type="secondary"
                                                    class="btn"
                                                    size="sm"
                                                    v-on:click="decrem"
                                                >Anterior</base-button>
                                                <base-button
                                                    slot="title"
                                                    type="primary"
                                                    class="btn"
                                                    size="sm"
                                                    v-on:click="increm"
                                                >Siguiente</base-button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
    
            <div v-if="pag==2" class="container mt--7" v-bind:style="{cursor:pointer2}">
                <div class="row">
                    <div class="col-lg-12 center">
                        <card shadow type="secondary">
                            <div slot="header" class="border-0">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h6 id="tit">CARGA DE IDENTIFICACIÓN OFICIAL</h6>
                                    </div>
                                </div>
                                <small>Te pedimos tomes una foto de tu IFE en una superficie plana sin que se vea tu mano ni flash.</small>
                                <br />
                                <small>(Sera la mejor foto de tu vida)</small>
                            </div>
                            <template>
                                <form @submit.prevent>
                                    <div class="container pt-lg-md-3">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-5 py-4">
                                                <div
                                                    class="col-lg-12 col-sm-12"
                                                    style="text-align: center;text-transform: uppercase;"
                                                >
                                                    <label>Identificación frente</label>
                                                </div>
                                                <div>
                                                    <dropbox
                                                        text
                                                        type="ID"
                                                        :userid="this.client.id"
                                                        :files="this.userFiles"
                                                        v-on:uploadFile="uploadFileFrente"
                                                    ></dropbox>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 py-4">
                                                <div
                                                    class="col-lg-12 col-sm-12"
                                                    style="text-align: center;text-transform: uppercase;"
                                                >
                                                    <label>Identificación reverso</label>
                                                </div>
                                                <div>
                                                    <dropbox
                                                        text
                                                        type="ID_B"
                                                        :userid="this.client.id"
                                                        :files="this.userFiles"
                                                        v-on:uploadFile="uploadFileReverso"
                                                    ></dropbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="my-4" />
                                    <b-alert
                                        :show="dismissCountDown_doc_success"
                                        dismissible
                                        fade
                                        variant="success"
                                        @dismissed="dismissCountDown_doc_success=0"
                                        @dismiss-count-down="countDownChanged(5)"
                                    >{{ fileToUpload }} cargado exitosamente.</b-alert>
                                    <b-alert
                                        :show="dismissCountDown_doc_failure"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_doc_failure=0"
                                        @dismiss-count-down="countDownChanged(6)"
                                    >Error en la carga de {{ fileToUpload }}</b-alert>

                                    <b-alert
                                        :show="dismissCountDown_end"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_end=0"
                                        @dismiss-count-down="countDownChanged(4)"
                                    >Campos incompletos:{{ incompletos }}</b-alert>
        
                                       <b-alert
                                        :show="dismissCountDown_edad"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_edad=0"
                                        @dismiss-count-down="countDownChanged(7)"
                                    >Tienes que tener más de 18 años, Edita tu Fecha de Nacimiento</b-alert>
                                   

                                    <div class="row d-flex justify-content-end">
                                        <div class="col-6 text-right my-2">
                                            <base-button
                                                slot="title"
                                                type="secondary"
                                                class="btn"
                                                size="sm"
                                                v-on:click="decrem"
                                            >Anterior</base-button>
                                            <base-button
                                                slot="title"
                                                type="primary"
                                                class="btn"
                                                size="sm"
                                                v-on:click="finalCheck"
                                            >Guardar</base-button>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
            !-->
            <!--
            <div v-if="pag==5" class="container mt--7" v-bind:style="{cursor:pointer2}">
                <div class="row">
                    <div class="col-lg-12 center">
                        <card shadow type="secondary">
                            <div slot="header" class="border-0">
                                <div class="row align-items-center">
                                    <div class="col-8">
                                        <h6 id="tit">CARGA DE DOCUMENTACIÓN RESTANTE</h6>
                                    </div>
                                </div>
                            </div>
                            <template>
                                <form @submit.prevent>
                                    <div class="container pt-lg-md-3">
                                        <div class="row justify-content-center">
                                            <div class="col-lg-5 py-4">
                                                <div
                                                    class="col-lg-12 col-sm-12"
                                                    style="text-align: center;text-transform: uppercase;"
                                                >
                                                    <label>Comprobante de Domicilio</label>
                                                </div>
                                                <div>
                                                    <dropbox
                                                        text
                                                        type="ADDRESS"
                                                        :userid="this.client.id"
                                                        :files="userFiles"
                                                        v-on:uploadFile="uploadFileComprobante"
                                                    ></dropbox>
                                                </div>
                                            </div>
                                            <div class="col-lg-5 py-4">
                                                <div
                                                    class="col-lg-12 col-sm-12"
                                                    style="text-align: center;text-transform: uppercase;"
                                                >
                                                    <label>CURP</label>
                                                </div>
                                                <div>
                                                    <dropbox
                                                        text
                                                        type="CURP"
                                                        :userid="this.client.id"
                                                        :files="userFiles"
                                                        v-on:uploadFile="uploadFileCurp"
                                                    ></dropbox>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <hr class="my-4" />
                                    <b-alert
                                        :show="dismissCountDown_doc_success"
                                        dismissible
                                        fade
                                        variant="success"
                                        @dismissed="dismissCountDown_doc_success=0"
                                        @dismiss-count-down="countDownChanged(5)"
                                    >{{ fileToUpload }} cargado exitosamente.</b-alert>
                                    <b-alert
                                        :show="dismissCountDown_doc_failure"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_doc_failure=0"
                                        @dismiss-count-down="countDownChanged(6)"
                                    >Error en la carga de {{ fileToUpload }}</b-alert>
                                    <b-alert
                                        :show="dismissCountDown_end"
                                        dismissible
                                        fade
                                        variant="danger"
                                        @dismissed="dismissCountDown_end=0"
                                        @dismiss-count-down="countDownChanged(4)"
                                    >Campos incompletos:{{ incompletos }}</b-alert>
                                    <div class="row d-flex justify-content-end">
                                        <div class="col-6 text-right my-2">
                                            <base-button
                                                slot="title"
                                                type="secondary"
                                                class="btn"
                                                size="sm"
                                                v-on:click="decrem"
                                            >Anterior</base-button>
                                            <base-button
                                                slot="title"
                                                type="primary"
                                                class="btn"
                                                size="sm"
                                                v-on:click="finalCheck"
                                            >Guardar</base-button>
                                        </div>
                                    </div>
                                </form>
                            </template>
                        </card>
                    </div>
                </div>
            </div>
            !-->
        </section>
    </div>
</template>

<script>
 import firebase from 'firebase/app';
    import 'firebase/firestore';
    import 'firebase/analytics';
    import 'firebase/messaging';
import flatPickr from "vue-flatpickr-component";
import BaseDropdown from "../components/BaseDropdown";
import UploadFilesForm from "@/views/components/UploadFilesForm.vue";
import Dropbox from "../components/Dropbox";
import { V } from "../V";
export default {
    data() {
        return {
            emailExist:false,
            rows: 100,
            client: {},
            auxClient: {},
            pag: 1,
            gender: null,
            stateOfBirth: null,
            postalCodes: [],
            locality: "",
            sublocalities: [],
            beneficiary: {},
            genderBeneficiary: null,

            dismissSecs: 8,
            //            dismissCountDown_telefono: 0,
            dismissCountDown_cp: 0,
            dismissCountDown_edad: 0,
            dismissCountDown_curp: 0,
            dismissCountDown_end: 0,
            dismissCountDown_ine: 0,
            dismissCountDown_doc_success: 0,
            dismissCountDown_doc_failure: 0,

            pointer: "pointer",
            pointer2: "default",
            regEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
            regPhone: /[0-9]{10}?/,

            userFiles: [],
            filetype: null,
            archivo: null,
            files: {
                ID: null,
                ID_B: null
            },
            fileId: true,
            fileId_b: true,
            tokenFCM: "",
            urlAsesor: "",
            phone: "",
            lead:null
        };
    },
    created() {
        this.getAsesor();
        fbq("track", "lead_complete_automated");
        firebase.analytics().logEvent("lead_complete_automated");
        gtag("event", "lead_complete_automated", {
            send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
        });
        fbq("track", "step_one");
        firebase.analytics().logEvent("step_one");
        gtag("event", "step_one", {
            send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
        });
        
        this.getFilesInfo();
        this.client = JSON.parse(
            JSON.stringify(JSON.parse(localStorage.getItem(V.STORE_KEYS.USER)))
        );

        this.lead = JSON.parse(localStorage.getItem("Lead"));

        if (this.client) {
            this.getClientProfile();
        } else {
            this.$router.push("/register");
        }
        //        console.log('start')
        //this.client = JSON.parse(JSON.stringify(JSON.parse(localStorage.getItem(V.STORE_KEYS.USER))))
        //console.log(this.client)
    },
    watch: {
        user(val) {
            this.notifyMe();
            this.getAsesor();
        }
    },
    computed: {
        mxStates() {
            return this.$store.state.mxStates;
        },
        economicActivities() {
            let activities = this.$store.state.economicActivities;
            if (!activities) this.$store.dispatch("downloadEconomicActivities");
            return activities;
        },
        incompletos: function() {
            return (
                (!this.client.name ? " - Nombre" : "") +
                (!this.client.lastname_1 ? " - Primer apellido" : "") +
                (!this.client.lastname_2 ? " - Segundo apellido" : "") +
                (!this.regPhone.test(this.client.phone) ? " - Teléfono" : "") +
                (!this.regEmail.test(this.client.email) ? " - Email" : "") +
                (!this.client.birthday ? " - Fecha de nacimiento" : "") +
                (!this.client.gender ? " - Género" : "") +
                (!this.client.rfc ? " - RFC" : "") +
                (!this.client.curp ? " - CURP" : "") +
                (!this.calcularEdad
                    ? " - No eres mayor a 18 años (Edita tu fecha de nacimiento por favor)"
                    : "")
            );
        },
        isEmailValid: function() {
            return !this.client.email
                ? ""
                : this.regEmail.test(this.client.email)
                ? "has-success"
                : "has-danger";
        },
        isPhoneValid: function() {
            return !this.client.phone
                ? ""
                : this.regPhone.test(this.client.phone)
                ? "has-success"
                : "has-danger";
        },
        fileToUpload: function() {
            return this.archivo;
        }
    },
    methods: {
        calcularEdad() {
            var fecha = new Date(this.client.birthday);
            var hoy = new Date();
            var ed = parseInt((hoy - fecha) / 365 / 24 / 60 / 60 / 1000);

            if (ed < 18) {
                this.dismissCountDown_edad = this.dismissSecs;
                return false;
            } else {
                this.dismissCountDown_edad = 0;
                return true;
            }
        },
        getFilesInfo() {
            this.getAsesor();
            this.$axios.defaults.headers.common["device"] = "web";
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.USER_FILES;
            this.$axios.post(url).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.userFiles = data.rows;
                    for (var i = 0; i < this.userFiles.length; i++) {
                        if (this.userFiles[i].type == "ID") {
                            //                            console.log("Sí hay " + 'ID' + " en la BD")
                            this.files.ID = true;
                        }
                        if (this.userFiles[i].type == "ID_B") {
                            //                            console.log("Sí hay " + 'ID_B' + " en la BD")
                            this.files.ID_B = true;
                        }
                    }
                } else {
                    console.log("error d_userFiles");
                }
            });
        },
        increm: function() {
            this.pag++;
            /* Marketing events */
            let event;
            switch (this.pag) {
                case 1:
                    event = "step_one";
                    break;
                case 2:
                    event = "step_two";
                    break;
                case 3:
                    event = "step_three";
                    break;
                case 4:
                    event = "step_four";
                    break;
                case 5:
                    event = "step_five";
                    break;
                case 6:
                    event = "step_six";
                    break;
            }
            fbq("track", event);
            firebase.analytics().logEvent(event);
            gtag("event", event, {
                send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
            });
            /********************/
            if (this.pag - 1 === 4) return this.set_beneficiary();
            this.updateClientProfile();
        },
        decrem: function() {
            if (--this.pag === 0) {
                this.$router.push({
                    path: "/"
                });
            }
        },
        getAsesor() {
            this.$axios.defaults.headers.common["device"] = "web";
            let url = this.$v.SERVER + this.$v.OPS.GET_CONSULTANT_INFO;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[this.$v.API.SUCCESS]) {
                    this.phone = data.row.phone;
                    this.urlAsesor = "https://wa.me/52" + this.phone;
                   // window.open(this.urlAsesor);
                }
            });
        },
        finalCheck() {
            this.$forceUpdate();
            this.$axios.defaults.headers.common["device"] = "web";

                console.log(this.client)
            if (
                this.calcularEdad() &&
                this.client.name &&
                this.client.lastname_1 &&
                this.client.lastname_2 &&
                this.regPhone.test(this.client.phone) &&
                this.client.email &&
                this.client.birthday &&
                this.client.gender &&
                this.client.rfc &&
                this.client.curp &&
                this.client.addr_extnum &&
                this.client.addr_postal_code &&
                this.client.addr_sublocality 
            ) {
                this.updateClientProfile();
            } else {
                console.log('incompleto')
                this.dismissCountDown_end = this.dismissSecs;
            }
        },
        getClientProfile() {
            this.getAsesor();
            this.$axios.defaults.headers.common["device"] = "web";
            let url =
                this.$v.SERVER +
                this.$v.OPS.GET_PROFILE;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[this.$v.API.SUCCESS]) {
                    this.client = response.data[this.$v.API.ROW];
                    if (data.row.locality){this.locality = data.row.locality;}
                    console.log(this.lead.email)
                    if(this.lead.email) { this.client.email = this.lead.email; }
                    if(this.lead.phone) { this.client.phone = this.lead.phone; }
                    if (data.row.gender) { this.gender = data.row.gender; }
                    console.log(this.client.email)
                    this.finalCheck()
                }
            });
        },
        updateClientProfile() {
            //this.getAsesor();
            if(this.lead) {this.lead.email = this.client.email;}
            localStorage.setItem('Lead', JSON.stringify(this.lead))
            this.$axios.defaults.headers.common["device"] = "web";
            this.client.device = 'web'
            let url =
                this.$v.SERVER + this.$v.OPS.UPDATE + this.$v.TABLES.USERS;
            this.$axios.post(url, this.client).then(response => {
                let data = response.data;

                console.log(data[this.$v.API.REASON])
                if (data[this.$v.API.SUCCESS]) {
                this.$router.push({
                    path: "/AuthorizationBC"
                });
                }else if(data[this.$v.API.REASON] == 5){
                    this.emailExist=true;
                    console.log('Email Repetido')
                }
            });
        },
        async set_beneficiary() {
            this.getAsesor();
            this.$axios.defaults.headers.common["device"] = "web";
            if (this.beneficiary) {
                if (this.client.id > 0) {
                    this.beneficiary.user = this.client.id;
                } else {
                    this.beneficiary.user = data[this.$v.API.ROW].id;
                }
                let url = this.$v.SERVER + this.$v.OPS.SET_BENEFICIARY;
                let response = await this.$axios.post(url, this.beneficiary);
                let data = response.data;
                if (!data[this.$v.API.SUCCESS]) {
                    if (data[this.$v.API.REASON] === 10) {
                        console.log(
                            "Usuario duplicado, contacta con el administrador"
                        );
                    } else {
                        console.log("Intenta nuevamente");
                    }
                } else {
                }
            }
        },
        countDownChanged(dismissCountDown, type) {
            switch (type) {
                case 1:
                    this.dismissCountDown_cp = dismissCountDown;
                    break;
                case 2:
                    this.dismissCountDown_curp = dismissCountDown;
                    break;
                case 3:
                    this.dismissCountDown_ine = dismissCountDown + 5;
                    break;
                case 4:
                    this.dismissCountDown_end = dismissCountDown - 4;
                    break;
                case 5:
                    this.dismissCountDown_doc_success = dismissCountDown - 4;
                    break;
                case 6:
                    this.dismissCountDown_doc_failure = dismissCountDown - 4;
                    break;
                case 7:
                    this.dismissCountDown_edad = dismissCountDown + 5;
                    break;
            }
        },
        alertCP() {
            this.dismissCountDown_cp = this.dismissSecs;
        },
        alertINE() {
            this.dismissCountDown_ine = this.dismissSecs;
        },
        calculate() {
            let diff = Object.keys(this.auxClient).length === 0;
            let keys = [
                "name",
                "lastname_1",
                "lastname_2",
                "birthday",
                "gender"
            ];
            for (let i = 0; i < keys.length; i++) {
                if (this.client[keys[i]] !== this.auxClient[keys[i]]) {
                    diff = true;
                    break;
                }
            }
            if (diff) {
                this.auxClient = JSON.parse(JSON.stringify(this.client));
                if (
                    this.client.name &&
                    this.client.lastname_1 &&
                    this.client.lastname_2 &&
                    this.client.birthday &&
                    this.client.gender &&
                    this.client.state_of_birth
                ) {
                    this.dismissCountDown_curp = 0;
                    this.calculateRFC_CURP();
                } else {
                    this.dismissCountDown_curp = this.dismissSecs;
                }
            }
        },
        setGender(gender) {
            this.client.gender = gender.substring(0, 1).toUpperCase();
            this.gender = gender;
            this.$forceUpdate();
            //            this.calculateRFC_CURP()
        },
        setBeneficiaryGender(gender) {
            this.beneficiary.gender = gender.substring(0, 1).toUpperCase();
            this.genderBeneficiary = gender;
            this.$forceUpdate();
        },
        beneficiaryCivilStatusSelected(civiStatus) {
            if (civiStatus == "Soltero") this.beneficiary.civil_status = "S";
            else if (civiStatus == "Casado")
                this.beneficiary.civil_status = "M";
            this.$forceUpdate();
        },
        setMxState(state) {
            this.client.state_of_birth = state.code;
            this.stateOfBirth = state.state;
            this.$forceUpdate();
            //            this.calculateRFC_CURP()
        },
        setEconomicActivity(activity) {
            this.client.economic_activity = activity;
            this.$forceUpdate();
        },
        getEconomicActivity() {
            for (let i = 0; i < this.economicActivities.length; i++)
                if (
                    this.economicActivities[i].id ===
                    this.client.economic_activity
                )
                    return this.economicActivities[i].activity;
            return null;
        },
        postalCodeUpdated(pcode) {
            if (pcode.length > 3) {
                let url =
                    this.$v.SERVER +
                    this.$v.OPS.GET_POSTAL_CODE +
                    "?pc=" +
                    pcode;
                this.$axios.get(url).then(response => {
                    let data = response.data;
                    if (data[this.$v.API.SUCCESS]) {
                        this.postalCodes = data[this.$v.API.ROWS];
                        if (this.postalCodes.length === 1)
                            this.postalCodeSelected(this.postalCodes[0]);
                        this.$forceUpdate();
                    }
                });
            } else {
                this.postalCodes = [];
                this.locality = "";
                this.sublocalities = [];
                this.client.addr_sublocality = "";
            }
        },
        postalCodeSelected(postalCode) {
            this.client.addr_postal_code = postalCode.postal_code;
            this.sublocalities = postalCode["sublocality"].split(";");
            if (this.sublocalities.length === 1)
                this.client.addr_sublocality = this.sublocalities[0];
            this.locality = postalCode["locality"];
        },
        clientCivilStatusSelected(civiStatus) {
            if (civiStatus == "Soltero") this.client.civil_status = "S";
            else if (civiStatus == "Casado") this.client.civil_status = "M";
            this.$forceUpdate();
        },
        calculateRFC_CURP() {
            let complete = true;
            let params = {
                name: this.client.name,
                lastname_1: this.client.lastname_1,
                lastname_2: this.client.lastname_2,
                state: this.client.state_of_birth,
                birthday: this.client.birthday,
                gender: this.client.gender.substring(0, 1)
            };
            Object.values(params).forEach(value => {
                if (!value) complete = false;
            });
            if (complete) {
                let keys = Object.keys(params);
                let url = this.$v.SERVER + this.$v.OPS.GET_RFC_CURP + "?";
                for (let k = 0; k < keys.length; k++)
                    url += keys[k] + "=" + params[keys[k]] + "&";
                this.$axios.get(url).then(response => {
                    let data = response.data;
                    if (data.hasOwnProperty("RFC"))
                        this.client.rfc = data["RFC"];
                    if (data.hasOwnProperty("CURP"))
                        this.client.curp = data["CURP"];
                    this.$forceUpdate();
                });
            }
        },
        sublocalitySelected(sublocality) {
            this.client.addr_sublocality = sublocality;
            this.$forceUpdate();
        },
        uploadFileFrente(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let headers = {
                "user-id": this.client.id,
                "file-type": "ID"
            };
            this.$showLoader(true);
            this.dismissCountDown_doc_success = 0;
            this.dismissCountDown_doc_failure = 0;
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.fileType = null;
                        this.files.ID = true;
                        this.fileId = false;
                        this.dismissCountDown_doc_success = this.dismissSecs;
                    } else {
                        this.dismissCountDown_doc_failure = this.dismissSecs;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        uploadFileReverso(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let headers = {
                "user-id": this.client.id,
                "file-type": "ID_B"
            };
            this.$showLoader(true);
            this.dismissCountDown_doc_success = 0;
            this.dismissCountDown_doc_failure = 0;
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    this.$alert(
                        "¡OJO!, Regresa y verificar que tus datos sean correctos antes de guardar tu registro"
                    );
                    if (response.data.success) {
                        this.fileType = null;
                        this.files.ID_B = 1;
                        this.fileId_b = false;
                        this.dismissCountDown_doc_success = this.dismissSecs;
                    } else {
                        this.dismissCountDown_doc_failure = this.dismissSecs;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        uploadFileComprobante(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let headers = {
                "user-id": this.client.id,
                "file-type": "ADDRESS"
            };
            this.$showLoader(true);
            this.dismissCountDown_doc_success = 0;
            this.dismissCountDown_doc_failure = 0;
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.fileType = null;
                        this.dismissCountDown_doc_success = this.dismissSecs;
                    } else {
                        this.dismissCountDown_doc_failure = this.dismissSecs;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        uploadFileCurp(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let headers = {
                "user-id": this.client.id,
                "file-type": "CURP"
            };
            this.$showLoader(true);
            this.dismissCountDown_doc_success = 0;
            this.dismissCountDown_doc_failure = 0;
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.fileType = null;
                        this.dismissCountDown_doc_success = this.dismissSecs;
                    } else {
                        this.dismissCountDown_doc_failure = this.dismissSecs;
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        edad() {
            var fecha = new Date(this.client.birthday);
            var hoy = new Date();
            var ed = parseInt((hoy - fecha) / 365 / 24 / 60 / 60 / 1000);
            if (ed < 18) {
                this.dismissCountDown_edad = 0;
                this.$alert(
                    "Tienes que ser mayor de 18 años - POR FAVOR EDITA TU FECHA DE NACIMIENTO, PARA PODER CONTINUAR"
                );
            } else {
                this.dismissCountDown_edad = this.dismissSecs;
            }
        }
    },
    components: {
        flatPickr,
        BaseDropdown,
        UploadFilesForm,
        Dropbox
    }
};
</script>

<style>
#tit {
    font-weight: 300;
    font-size: large;
}
@media (min-width: 300px) {
    .calcular {
        margin: 5%;
        padding: 6%;
    }
    .calcularbtn {
        margin-left: 1.3rem;
    }
    .separar {
        margin-bottom: 1rem;
    }
    .sta {
        margin-top: -420px;
    }
}
@media (min-width: 370px) {
    .calcular {
        margin: 5%;
        padding: 6%;
    }
    .calcularbtn {
        margin-left: 2.8rem;
    }
    .separar {
        margin-bottom: 1rem;
    }
    .sta {
        margin-top: -420px;
    }
}
@media (min-width: 420px) {
    .calcular {
        margin: 5%;
        padding: 6%;
    }
    .calcularbtn {
        margin-left: 4.2rem;
    }
    .separar {
        margin-bottom: 1rem;
    }
    .sta {
        margin-top: -420px;
    }
}
@media (min-width: 768px) {
    .calcular {
        margin: 5%;
        padding: 3%;
        align-content: center;
    }
    .calcularbtn {
        margin-left: 13.5rem;
    }

    .sta {
        margin-top: -410px;
    }
}

@media (min-width: 992px) {
    .calcular {
        margin-left: 70%;
    }
    .calcularbtn {
        margin-left: 20.5rem;
    }
}

@media (min-width: 1200px) {
    .calcular {
        margin-left: 70%;
    }
    .calcularbtn {
        margin-left: 23rem;
    }
}
</style>
