<template>
    <div class="position-relative">
        <!-- shape Hero -->
        <section class="section-shaped my-0" style="background-color:#3953D3;">
            <div class="shape shape-style-1 shape-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div class="container shape-container" style=" margin:-10rem;">
                <div class="col px-0">
                    <div class="row"></div>
                </div>
            </div>
        </section>
        <section class="section section-lg pt-lg-0 mt--300 subir" style="background-color:#3953D3;">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="col-lg-12">
                            <card
                                class="border-0"
                                shadow
                                body-classes="py-5"
                                style="margin-top: 45px; opacity: 0.95"
                            >
                                <br />
                                <div class="row justify-content-center">
                                    <img style="width:40rem;" src="img/brand/DB Menos logo azul 2020_.png" />
                                    <label
                                        class="text-center h2"
                                        style="width: 100%"
                                    >Selecciona con quien tienes deudas</label>
                                    <hr />
                                    <div
                                        class="col-md-3 col-sm-3 col-4 text-center"
                                        v-for="(bank, index) in banks"
                                        :key="bank.id"
                                    >
                                        <div
                                            v-on:click="m_switchSelected(index)"
                                            class="icon icon-shape rounded-circle text-white"
                                            style="margin-top: 12px; padding:2px !important"
                                            v-bind:class="{'bg-success': bank.selected, 'bg-secondary': !bank.selected}"
                                        >
                                            <div class="g-item">
                                                <img
                                                    class="img-responsive"
                                                    v-bind:src="bank.url"
                                                    :title="bank.name"
                                                    style="height: 100%; width: 100%; object-fit: contain"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <br />
                                <div class="text-center">
                                    <b-form-checkbox v-model="bancos" value="1">Tengo deuda con otros bancos</b-form-checkbox>
                                    <br />
                                    <b-form-checkbox  v-model="financieras" value="2">Tengo deuda con financieras</b-form-checkbox>
                                    <br />
                                    <b-form-checkbox  v-model="persona" value="3">Tengo deuda con persona física</b-form-checkbox>
                                </div>
                                <div class="row">
                                    <base-button
                                        block
                                        type="primary"
                                        class="mt-5"
                                        v-on:click="m_startinglead()"
                                    >Continuar</base-button>
                                </div>
                                <br />
                            </card>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
import Vue from "vue";
import modal from "@/components/Modal";
import { V } from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
Vue.use(VueFacebookPixel);
export default {
    components: {
        modal,
        VueFacebookPixel
    },

    data() {
        return {
            V: V,
            simpleCalc: {
                amount: 80,
                banks: []
            },
            leadInfo: {
                name: "",
                email: "",
                phone: ""
            },
            leadValidationCode: "",
            leadSent: false,
            banks: [],
            sameBankDebtPaysheet: false,
            leadModal: false,
            estimatedDiscountModal: false,
            averageDiscount: 0,
            discountdoc: "",
            financieras:false,
            persona:false,
            bancos:false,
            selectedBanco:false,
        };
    },
    created() {
        this.m_getBanks();
        //Vue.analytics.fbq.init(V.FACEBOOK_PIXEL_ID, {})
    },
    methods: {
        m_hasValidEmail() {
            let regex = new RegExp(
                "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|es|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b"
            );
            return regex.test(this.leadInfo.email);
        },
        m_hasValidPhone() {
            /*let regex = new RegExp('^(?:(?:\\+?1\\s*(?:[.-]\\s*)?)?(?:\\(\\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\\s*\\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\\s*(?:[.-]\\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\\s*(?:[.-]\\s*)?([0-9]{4})(?:\\s*(?:#|x\\.?|ext\\.?|extension)\\s*(\\d+))?$')
                return (regex.test(this.leadInfo.phone) && this.leadInfo.phone.length === 10)*/
            return (
                !isNaN(this.leadInfo.phone) &&
                this.leadInfo.phone.length > 9 &&
                this.leadInfo.phone.length < 11
            );
        },
        m_hasValidName() {
            return this.leadInfo.name.length > 2;
        },
        m_getBanks() {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS;
            this.$http.post(url, {}).then(
                res => {
                    if (res.body.success) {
                        this.banks = res.body.rows;
                        this.banks.forEach(b => {
                            b.selected = false;
                            this.selectedBanco =false;
                        });
                    }
                },
                er => {
                    //                    console.log("err")
                    //                    console.log(er)
                }
            );
        },
        m_switchSelected(index) {
            let bank = this.banks[index];
            bank.selected = !bank.selected;
            Vue.set(this.banks, index, bank);
            let count = 0;
            let neg = 0;
            this.banks.forEach(bank => {
                if (bank.selected) {
                    count += 1;
                    neg += bank.neg;
                    this.selectedBanco =true;
                }
            });
            if (count > 0) {
                this.averageDiscount = neg / count;
                this.selectedBanco =true;
            } else {
                this.averageDiscount = 0;
                this.selectedBanco =false;
            }
        },
        m_startinglead() {
           if(this.financieras && this.bancos && this.persona && this.selectedBanco){
               this.$router.push({
                    path: "/register"
                });
           } 
           else if(this.financieras && this.bancos && this.persona && this.selectedBanco==false){
                this.$router.push({
                    path: "/windowsProceso"
                });
           }
           else if(this.financieras && this.selectedBanco==true){
               this.$router.push({
                    path: "/register"
                });
           }
            else if(this.bancos && this.selectedBanco==true){
               this.$router.push({
                    path: "/register"
                });
           }
           else if(this.persona && this.selectedBanco==true){
               this.$router.push({
                    path: "/register"
                });
           }
           else if(this.financieras && this.selectedBanco==false){
               this.$router.push({
                    path: "/windowsProceso"
                });
           }
            else if(this.bancos && this.selectedBanco==false){
               this.$router.push({
                    path: "/windowsProceso"
                });
           }
           else if(this.persona && this.selectedBanco==false){
             this.$router.push({
                    path: "/windowsProceso"
                });
           }
           else if(this.financieras==false && this.bancos==false && this.persona==false && this.selectedBanco==true){
             this.$router.push({
                    path: "/register"
                });
           }
        }
    }
};
</script>