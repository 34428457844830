<template>
    <v-app>
        <v-container fluid>
            <!-- <div class="home"> -->
                <SideNavigation/>
                <ConsultaHistorial/>
                <!-- <DownloadCSV/> -->
            <!-- </div> -->
        </v-container>
    </v-app>
</template>

<script>
import ConsultaHistorial from './ConsultaHistorial.vue';
import DownloadCSV from './DownloadCSV.vue';
import SideNavigation from './SideNavigation.vue';

export default {
    name: 'Home',
    components: {
    SideNavigation,
    DownloadCSV,
    ConsultaHistorial
},
    data() {
        return {
            
        }
    },
}
</script>

<style lang="scss" scoped>
@import './scss/variables.scss';
.home{
    width: 100vw;
    height: 100vh;
    background-color: $bg-grey;
}

</style>