<template>
  <footer class="footer"  style="background-size: cover;
            background-repeat: no-repeat;
            background-image: url('img/brand/Header_1440_100.png');">
    <div class="container">
      <div class="row align-items-center justify-content-md-between">
        <div class="col-md-12 col-sm-12" style="margin-top:5%;">
          <ul class="nav nav-footer justify-content-center">
            <li class="nav-item" style="color: white; font-size: 100%">
              &copy; {{ year }} DBMenos
            </li>
            <p style="margin-top: -1%; color:white;">|</p>
            <a
              href="https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos"
              target="_blank"
              style="color: white; font-size: 100%"
              >Contáctanos</a
            >
            <p style="margin-top: -1%; color:white;">|</p>
            <router-link to="/legal" style="color: white; font-size: 100%"
              >Legal</router-link
            >
            <p style="margin-top: -1%; color:white;">|</p>
            <router-link to="/questions" style="color: white; font-size: 100%"
              >faq</router-link
            >
          </ul>
          <ul class="nav nav-footer justify-content-center" style="padding: 5%">
            <a
              class="nav-link nav-link-icon"
              href="https://www.facebook.com/grupodbmenos/"
              target="_blank"
              data-toggle="tooltip"
              title="Siguenos en Facebook"
            >
              <i class="fa fa-facebook fa-lg text-white" aria-hidden="true"></i>
            </a>
            <a
              class="nav-link nav-link-icon"
              href="https://www.instagram.com/db_menos/"
              target="_blank"
              data-toggle="tooltip"
              title="Siguenos en Instagram"
            >
              <i
                class="fa fa-instagram fa-lg text-white"
                aria-hidden="true"
              ></i>
            </a>
            <a
              class="nav-link nav-link-icon"
              href="https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw"
              target="_blank"
              data-toggle="tooltip"
              title="Siguenos en Youtube"
            >
              <i
                class="fa fa-youtube-play fa-lg text-white"
                aria-hidden="true"
              ></i>
            </a>
            <a
              class="nav-link nav-link-icon"
              href="https://www.linkedin.com/company/grupo-db-menos/"
              target="_blank"
              data-toggle="tooltip"
              title="Siguenos en Linkedin"
            >
              <i class="fa fa-linkedin fa-lg text-white" aria-hidden="true"></i>
            </a>
            <a
              class="nav-link nav-link-icon"
              href="https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos"
              target="_blank"
              data-toggle="tooltip"
              title="Mandar mensaje por whatsapp"
            >
              <i class="fa fa-whatsapp fa-lg text-white" aria-hidden="true"></i>
            </a>
            <router-link
              to="/blogs"
              style="font-family: Roboto-Black; color: white; padding: 0.5%"
              >BLOG</router-link
            >
          </ul>
          <ul class="nav nav-footer justify-content-center">
            <p
              style="color: white; padding: 0.5%"
              >Av. Homero 407, Polanco, Polanco V Secc, Miguel Hidalgo, CP 11550 Ciudad de México, CDMX, México</p
            >
          </ul>
        </div>

        <!--div class="col-md-6 col-sm-12">
                    <ul class="nav nav-footer justify-content-end" style="visibility: hidden">
                        <li>
                            <a  href="https://www.facebook.com/grupodbmenos/" target="_blank"
                            class="btn btn-neutral btn-icon-only btn-facebook btn-round btn-lg" data-toggle="tooltip"
                            data-original-title="Siguenos en Facebook">
                                <i class="fa fa-facebook-square"></i>
                            </a>
                            <a  href="https://www.instagram.com/db_menos/" target="_blank"
                            class="btn btn-neutral btn-icon-only btn-dribbble btn-lg btn-round" data-toggle="tooltip"
                            data-original-title="Siguenos en Instagram">
                                <i class="fa fa-instagram"></i>
                            </a>
                            <a  href="https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw" target="_blank"
                            class="btn btn-neutral btn-icon-only btn-youtube btn-lg btn-round" data-toggle="tooltip"
                            data-original-title="Siguenos en Youtube">
                                <i class="fa fa-youtube"></i>
                            </a>
                            <a  href="https://www.linkedin.com/company/grupo-db-menos/" target="_blank"
                            class="btn btn-neutral btn-icon-only btn-linkedin btn-lg btn-round" data-toggle="tooltip"
                            data-original-title="Siguenos Linkedin">
                                <i class="fa fa-linkedin"></i>
                            </a>
                            
                        </li>
                    </ul>
                </div-->
      </div>
    </div>
  </footer>
</template>
<script>
import { V } from "../V";
export default {
  async created() {
    this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
    this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    this.$forceUpdate();
    //await this.checkSession()
  },
  data() {
    return {
      year: new Date().getFullYear(),
      session: null,
      user: {},
    };
  },
};
</script>
<style>
</style>
