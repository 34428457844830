<template>

  <div class="bg-principal mb-5">

    <div class="row">
      <div class="col-12 col-sm-10 mx-auto">
        <p class="font-weight-bold text-center text-md-left f-title mb-0">Nuestros clientes</p>
        <p class="text mt-0">Casos de éxito que solucionaron sus deudas.</p>
      </div>
    </div>

    <div class="row col-12 col-sm-11 mx-auto">
      <div class="col-12 col-sm-6 text-center" v-for="item of images">
        <img :src="item.image" :alt="item.index" width="80%">
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "OurClients",
  data: () =>
      ({
        images:
            [
              {id: 1, image: "/img/HomepageNew/Mobile/Abel García opinión.png"},
              {id: 2, image: "/img/HomepageNew/Mobile/Claudia Damián opinión.png"},
              {id: 3, image: "/img/HomepageNew/Mobile/Eduardo Albor opinión.png"},
              {id: 4, image: "/img/HomepageNew/Mobile/Alexander Mendez opinión.png"}
            ]
      })
}
</script>

<style scoped>


.bg-principal {
  background-color: #2a3d99;
}

.f-title {
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

@media (min-width: 320px) and (max-width: 374px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
    text-align: center;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  .f-title {
    font-size: 2rem;
  }

  .text {
    font-size: 1.7rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  .f-title {
    font-size: 2rem;
  }

  .text {
    font-size: 1.7rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .f-title {
    font-size: 3rem;
  }

  .text {
    font-size: 2.7rem;
  }
}

</style>