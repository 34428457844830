<template>

  <section>

    <v-card class="mt-5" style="border-radius: 10px" elevation="0">

      <v-card-title>
        <p class="mx-auto list-bold text-uppercase" style="color:#2A3D99">Programa DB Menos</p>
      </v-card-title>

      <v-card-text>

        <div class="row border-bottom mb-1">
          <div class="col-12 col-sm-6 p-0">
            <p class="list">En total debes:</p>
          </div>
          <div class="col-12 col-sm-6 p-0">
            <p class="text-sm-right list-bold" style="color: #000000">{{ proposal.totalDebt }}</p>
          </div>
        </div>

        <div class="row border-bottom mb-1">
          <div class="col-12 col-sm-6 p-0">
            <p class="list">Con DB Menos paga:</p>
          </div>
          <div class="col-12 col-sm-6 p-0">
            <p class="text-sm-right list-bold" style="color: #2A3D99">{{ proposal.willPay }}</p>
          </div>
        </div>

        <div class="row border-bottom mb-1">
          <div class="col-12 col-sm-6 p-0">
            <p class="list">Mensualidad de:</p>
          </div>
          <div class="col-12 col-sm-6 p-0">
            <p class="text-sm-right list-bold" style="color: #000000">{{ proposal.monthlyFee }}</p>
          </div>
        </div>

        <div class="row border-bottom mb-1">
          <div class="col-12 col-sm-6 p-0">
            <p class="list">Duración del programa:</p>
          </div>
          <div class="col-12 col-sm-6 p-0">
            <p class="text-sm-right list-bold" style="color:#000000;"> {{ proposal.period }} meses</p>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-sm-6 p-0">
            <p class="list">Descuento obtenido:</p>
          </div>
          <div class="col-12 col-sm-6 p-0">
            <p class="text-sm-right list-bold" style="color: #8ac43f">{{ proposal.percent }}%</p>
          </div>
        </div>

      </v-card-text>

    </v-card>

  </section>

</template>

<script>
export default {
  name: "ProgramDBM",
  props:
      {
        proposal:
            {
              required: true,
              default: {}
            }
      },
}
</script>

<style scoped>

.bg-principal {
  background: linear-gradient(to bottom, #2A3D99, #29AAE1) !important;
}

.primary-text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.primary-text-bold {
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.secondary-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.simple-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.simple-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.list {
  font-family: OpenSans-Regular;
}

.list-bold {
  font-family: OpenSans-Bold;
}

.title-modal {
  font-family: OpenSans-Bold;
  color: #2A3D99;
}

.text-modal {
  font-family: OpenSans-Regular;
  color: gray;
}

ul {
  list-style: none;
}

.btn-action {
  text-transform: none
}

.text-alert {
  color: #ff5252;
}

.icon-camera, .icon-eye, .icon-check {
  width: 1rem;
}

@media (max-width: 319px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .secondary-text br {
    display: none;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .secondary-text br {
    display: none;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .secondary-text br {
    display: none;
  }

  .title-modal {
    font-size: .9rem;
  }

  .text-modal {
    font-size: .8rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .8rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.2rem;
    color: #2a3d98;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
  }

  .secondary-text br {
    display: none;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: .9rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .9rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.4rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: .8rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.6rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  /*.bg-principal {
    margin-top: 6rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.7rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.5rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1.1rem;
  }

  .text-modal {
    font-size: 1.1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1.2rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

@media (min-width: 2561px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

</style>