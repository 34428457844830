<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto my-5">

      <div class="col-12 col-sm-4 d-flex align-items-center justify-content-center">
        <img src="/img/loans/women.png" alt="women" width="80%">
      </div>

      <div class="col-12 col-sm-8">

        <p class="text-center text-sm-left primary-text">Preguntas frecuentes</p>

        <v-expansion-panels>
          <v-expansion-panel v-for="(item, index) of questions"
                             class="mt-5" :key="index">
            <v-expansion-panel-header color="#f1f1f1" class="p-1 border">
              <p class="secondary-text pt-3">{{ item.title }}</p>
              <template v-slot:actions>
                <v-icon color="#4d4d4d">
                  $expand
                </v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="p-0">
              <p class="mt-3 secondary-text">{{ item.answer }}</p>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>


      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "FrequentQuestions",
  data: () =>
      ({
        questions:
            [
              {
                title: '¿Para qué sirve un crédito de consolidación de deuda?',
                answer: 'La consolidación de deuda es un proceso de combinar todas tus deudas, pagando una sola mensualidad a una tasa de interés más baja que la de un banco tradicional. De esta manera la liquidación de tu deuda es más fácil de manejar para ti.  '
              },
              {
                title: '¿Toman en cuenta mi buró de crédito? ',
                answer: 'Sí. Tomamos en cuenta tu nivel de endeudamiento para ofrecerte un plan que sea accesible para ti. '
              },
              {
                title: '¿Cuál es el interés que pagaré?',
                answer: 'El interés lo calculamos con base en tu monto aprobado. Ese te lo proporcionará nuestro asesor en el momento de contactarte. Ten por seguro que es menor que el de un banco. '
              },
            ]
      })
}
</script>

<style scoped>

.primary-text {
  font-family: OpenSans-ExtraBold;
  color: #00B2CE;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #4d4d4d;
}

.border {
  border-style: solid !important;
  border-color: #00B2CE !important;
  border-width: 0px 0px 0px 10px !important;
}

@media (min-width: 320px) and (max-width: 374px) {
  img {
    width: 90%;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  img {
    width: 80%;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  img {
    width: 60%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .primary-text {
    font-size: 1.2rem;
  }

  img {
    width: 90%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .primary-text {
    font-size: 1.4rem;
  }

  img {
    width: 60%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .primary-text {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1.2rem;
  }

  img {
    width: 60%;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .primary-text {
    font-size: 2rem;
  }

  .secondary-text {
    font-size: 1.6rem;
  }

  img {
    width: 50%;
  }
}

</style>