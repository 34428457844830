<template>

  <div class="bg-principal">

    <div class="row" >
      <div class="col-12 col-sm-10 mx-auto mb-0">
        <p class="font-weight-bold text-center f-title">La prensa opina</p>
      </div>
    </div>

    <div class="row col-12 col-sm-11 mx-auto d-flex align-items-center pt-0">
      <div class="col-12 col-sm-3 text-center mt-0 pt-0" v-for="item of images">
        <a :href="item.url" target="_blank">
          <img :src="item.image" :alt="item.index" :class="(item.id !==1 ) ? 'mb-xs-5' : ''">
        </a>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "Reviews",
  data: () =>
      ({
        images:
            [
              {
                id: 1,
                image: '/img/HomepageNew/Desktop/Forbes logo.svg',
                url: 'https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/'
              },
              {
                id: 2,
                image: '/img/HomepageNew/Desktop/El Economista logo.svg',
                url: 'https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html'
              },
              {
                id: 3,
                image: '/img/HomepageNew/Desktop/El heraldo.png',
                url: 'https://www.youtube.com/watch?v=dIpm0veIGBc'
              },
              {
                id: 4,
                image: '/img/HomepageNew/Desktop/Nacional financiera logo.svg',
                url: 'https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es'
              },
            ]
      })
}
</script>

<style scoped>

.bg-principal {
  background-color: #FFFFFF;
}

.f-title {
  font-family: OpenSans-Bold;
  color: #2a3d9a;
}

@media (min-width: 320px) and (max-width: 374px) {
  .f-title {
    font-size: 1.4rem;
  }

}

@media (min-width: 375px) and (max-width: 424px) {
  .f-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .f-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .f-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .f-title {
    font-size: 2rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .f-title {
    font-size: 2rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .f-title {
    font-size: 3rem;
  }
}

</style>