<template>

  <div class="bg-principal py-sm-5">

    <div class="row py-sm-5">

      <div class="col-12 col-md-8 col-lg-6 mx-auto pt-0 mt-0">

        <v-stepper v-model="step" elevation="0" style="border-radius: 1rem;">

          <v-stepper-header class="shadow-none px-5">

            <v-stepper-step
                :complete="this.validF1"
                :color="(this.auxColor && !this.validF1) ? '#2A3D99' : (this.validF1) ? '#8ac43f': '#ff0033'"
                step="1"
                @click="step = 1"
            />

            <v-divider></v-divider>

            <v-stepper-step
                :complete="this.validF2"
                :color="(this.auxColor && !this.validF2) ? '#2A3D99' : (this.validF2) ? '#8ac43f': '#ff0033'"
                step="2"
                @click="step = 2"
            />

            <v-divider></v-divider>

            <v-stepper-step
                :complete="this.validF3"
                :color="(this.auxColor && !this.validF3) ? '#2A3D99' : (this.validF3) ? '#8ac43f': '#ff0033'"
                step="3"
                @click="step = 3"
            />

          </v-stepper-header>

          <v-stepper-items>

            <div>
              <p class="text-center my-3 secondary-text" v-if="step===1">
                Para ayudarte agrega a tus acreedores <br>
                <span class="secondary-text-bold">¿Cuánto debes?</span>
              </p>

              <p class="text-center my-3 secondary-text-bold" v-else-if="step===2">
                Elige las cuentas que quieres pagar <br>
                y la duración de tu programa
              </p>

              <p class="text-center my-3 secondary-text-bold" v-else>
                Para registrarte en nuestro programa el <br>
                1er. paso es dejarnos a los siguientes datos
              </p>
            </div>

            <v-stepper-content step="1" class="p-0">

              <v-card>

                <v-card-text>

                  <v-form v-model="validF1" ref="form1">

                    <div class="row col-12 col-sm-10 mx-auto text-center">

                      <v-select
                          v-model="debt.bank"
                          label="Selecciona el banco"
                          :items="banks"
                          item-text="name"
                          item-value="id"
                          :rules="rules.bank"
                          dense
                          solo
                          class="col-12 col-sm-6 p-1"
                      />

                      <v-select
                          v-model="debt.amount"
                          label="Selecciona el monto"
                          :items="amounts"
                          item-text="name"
                          item-value="value"
                          :rules="rules.amount"
                          dense
                          solo
                          class="col-12 col-sm-6 p-1"
                      />

                    </div>

                    <!-- List Debts -->
                    <v-row v-for="(item, index) of my_debts" :key="index" class="col-12 col-sm-8 mx-auto">
                      <div class="col-12 d-flex justify-content-between p-0">
                        <span> {{ item.bank }}</span>
                        <span> {{ item.amount }}</span>
                        <div>
                          <v-icon
                              v-on:click="removeDebt(index)"
                              large
                              color="#ff0033">
                            mdi-close
                          </v-icon>
                        </div>
                      </div>
                    </v-row>

                  </v-form>

                  <div class="row">
                    <div class="col-12 col-sm-10 mx-auto text-center">
                      <v-btn
                          dark
                          elevation="2"
                          color="#2a3d98"
                          v-on:click="addDebt"
                          class="btn-action"
                      >+ Agregar
                      </v-btn>
                    </div>

                    <div class="col-12 col-sm-10 mx-auto text-center">
                      <v-btn
                          dark
                          elevation="2"
                          color="#8ac43f"
                          v-on:click="calculateDiscount"
                          class="btn-action"
                      >Calcular mi descuento
                      </v-btn>
                    </div>
                  </div>

                </v-card-text>

              </v-card>

            </v-stepper-content>

            <v-stepper-content step="2" class="p-0">

              <carousel-cards :debts="program_debts" :slider="slider"/>

            </v-stepper-content>

            <v-stepper-content step="3" class="p-0">

              <v-card>

                <v-card-text>

                  <v-form v-model="validF1" ref="form1">

                    <div class="row col-12 col-sm-10 mx-auto text-center">

                      <v-text-field
                          v-model="client.name"
                          label="Nombre"
                          placeholder="Nombre"
                          :rules="rules.name"
                          solo
                          dense
                          class="col-12"
                      />

                      <v-text-field
                          v-model="client.phone"
                          label="Teléfono"
                          placeholder="Teléfono"
                          :rules="rules.phone"
                          type="number"
                          pattern="\d*"
                          solo
                          dense
                          class="col-12"
                      />

                      <v-text-field
                          v-model="client.email"
                          label="Correo"
                          placeholder="Correo"
                          :rules="rules.email"
                          solo
                          dense
                          class="col-12"
                      />

                    </div>

                  </v-form>

                  <div class="row col-12 col-sm-10 mx-auto">
                    <v-btn
                        dark
                        elevation="2"
                        color="#8ac43f"
                        v-on:click="startMyProgram"
                        class="btn-action mx-auto"
                    >Iniciar mi programa
                    </v-btn>
                  </div>

                </v-card-text>

              </v-card>

            </v-stepper-content>

          </v-stepper-items>

        </v-stepper>

      </div>

      <div class="col-12 col-md-8 col-lg-4 mx-auto" v-if="step === 2">

        <program-d-b-m :proposal="proposal"/>

      </div>

    </div>

    <!--Pin buró de crédito (Dialog)-->
    <div class="text-center">
      <v-dialog
          persistent
          width="500px"
          v-model="dialog"
      >

        <v-card>
          <v-card-text>

            <div class="text-end">
              <v-icon
                  class="mt-3"
                  color="#2A3D99"
                  @click="dialog=false"
              >
                mdi-close
              </v-icon>
            </div>

            <div class="row">
              <div class="col-12 my-5 text-center">
                <p class="title-modal">Autorización de buró de crédito</p>
                <p class="text-modal text-left">Para comprobar que no eres un robot, te enviaremos un código a tu
                  teléfono.</p>

                <a href="/files/aviso_de_privacidad_grupo_dbmenos_2018.pdf" target="_blank">
                  <img src="/img/new_process/icons/eye.svg" alt="eye" class="d-inline icon-eye">
                  <p class="d-inline link" style="color: #2A3D99">
                    <ins>Aviso de privacidad</ins>
                  </p>
                </a>
              </div>
            </div>

            <v-form v-model="validF3" ref="form3">
              <div class="row">
                <div class="col-12">
                  <v-text-field
                      v-model="client.code"
                      label="Código"
                      type="number"
                      :rules="rules.code"
                      pattern="\d*"
                      solo
                  ></v-text-field>
                </div>
              </div>
            </v-form>

            <div class="row my-1">
              <div class="col-12">
                <v-btn
                    dark
                    block
                    elevation="2"
                    color="#8ac43f"
                    style="text-transform: none"
                    v-on:click="validatePin"
                >Enviar
                </v-btn>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!--Redirect to session (Dialog)-->
    <div class="text-center">
      <v-dialog
          v-model="alert"
          width="500"
          persistent
      >
        <v-card>
          <v-card-text>
            <div class="pt-5">
              <p class="font-weight-bold text-alert"
                 style="color: #ff5252">
                Oh Oh...
              </p>
              <p class="font-weight-bold text-alert">
                {{ message }}
              </p>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  text
                  v-on:click="redirectToSession"
              >
                Inicia sesión
              </v-btn>
            </v-card-actions>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!--Status messages(Snack Bar)-->
    <div class="text-center ">
      <v-snackbar v-model="snackbar">
        {{ message }}
        <template v-slot:action="{ attrs }">
          <v-btn
              color="#ff0033"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>

</template>

<script>

import VideoModal from "@/views/components/simulator/VideoModal";
import Carousel from "@/views/components/simulator/Carousel";
import {V} from "@/V";
import Dropbox from "@/components/Dropbox";
import IneImage from "@/views/components/simulator/IneImage";
import {mapActions} from 'vuex'
import Alert from "@/views/components/simulator/Alert";
import CarouselCards from "@/views/components/registration_steps/CarouselCards";
import ProgramDBM from "@/views/components/registration_steps/ProgramDBM";
import Vue from "vue";

export default {
  name: "ProposalStep",
  components: {ProgramDBM, CarouselCards, Alert, IneImage, Dropbox, Carousel, VideoModal},
  computed:
      {
        banks() {
          //Bank Catalog
          return this.$store.state.banks;
        },
      },
  data: () => ({

    //Validation Steps
    step: 1,

    validF1: false,
    validF2: false,
    validF3: false,

    //Snack Bar
    alert: false,
    message: '',
    snackbar: false,

    //Modals
    modal: false,
    dialog: false,

    //Color
    auxColor: true,

    //Props
    client:
        {
          id: '',
          name: '',
          last_name_1: '',
          last_name_2: '',
          curp: '',
          rfc: '',
          date_of_birth: '',
          gender: '',
          phone: '',
          email: '',
          code: '',
          address: {
            state: '',
            city: '',
            suburb: '',
            cp: '',
            street: '',
            number: ''
          },
          files: [],
        },

    debt: {bank: '', amount: ''},

    slider: {label: 'Meses', val: 12, color: '#F3B90D'},

    my_debts: [],

    program_debts:
        [//Info de ejemplo
          {bank: 1, debt: '12 000', pay: '10 000', percent: '70'},
          {bank: 2, debt: '10 000', pay: '8 000', percent: '70'},
        ],

    proposal:
        {
          totalDebt: 0,
          willPay: 0,
          monthlyFee: 0,
          period: '',
          percent: 0,
        },

    //Rules Of Form
    rules:
        {
          name: [v => !!v || 'El banco es requerido'],
          debt: [v => !!v || 'La deuda es requerida'],
          code: [v => !!v || 'El código es requerido'],
        },

    //Amount Catalogs

    sublocalities: [],

    amounts:
        [
          {id: 1, name: '10,000 a 30,000', value: 10000},
          {id: 2, name: '30,000 a 50,000', value: 30000},
          {id: 3, name: '50,000 a 100,000', value: 50000},
          {id: 4, name: '100,000 a 300,000', value: 100000},
          {id: 5, name: '300,000 a 500,000', value: 300000},
          {id: 6, name: 'más de 500,000', value: 500000},
        ],

    averageDiscount: 0
  }),
  methods:
      {
        addDebt() {
          if (this.debt.bank !== '' && this.debt.amount !== '') {

            const d =
                {
                  bank: this.debt.bank,
                  amount: this.debt.amount
                }

            this.my_debts.push(d);

            this.debt.bank = ''
            this.debt.amount = ''

          }
        },

        removeDebt(index) {
          this.my_debts.splice(index, 1);
        },

        calculateDiscount() {

          if (this.my_debts.length !== 0) {
            for (const item of this.my_debts) {

              let bank = this.banks[item.bank];
              bank.selected = !bank.selected;

              Vue.set(this.banks, item.bank, bank);

              let count = 0;
              let neg = 0;

              this.banks.forEach((bank) => {
                if (bank.selected) {
                  count += 1;
                  neg += bank.neg;
                }
              });

              this.averageDiscount = (count > 0) ? neg / count : 0;

            }
          }
        },
        iWantIt() {

        },
        startMyProgram() {

        },
        validatePin() {

        },
        redirectToSession() {

        }
      },
  watch:
      {}
}
</script>

<style scoped>

.bg-principal {
  background: linear-gradient(to bottom, #2A3D99, #29AAE1) !important;
}

.primary-text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.primary-text-bold {
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.secondary-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.simple-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.simple-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.list {
  font-family: OpenSans-Regular;
}

.list-bold {
  font-family: OpenSans-Bold;
}

.title-modal {
  font-family: OpenSans-Bold;
  color: #2A3D99;
}

.text-modal {
  font-family: OpenSans-Regular;
  color: gray;
}

ul {
  list-style: none;
}

.btn-action {
  text-transform: none
}

.text-alert {
  color: #ff5252;
}

.icon-camera, .icon-eye, .icon-check {
  width: 1rem;
}

@media (max-width: 319px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .secondary-text br {
    display: none;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .secondary-text br {
    display: none;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .secondary-text br {
    display: none;
  }

  .title-modal {
    font-size: .9rem;
  }

  .text-modal {
    font-size: .8rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .8rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.2rem;
    color: #2a3d98;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
  }

  .secondary-text br {
    display: none;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: .9rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .9rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.4rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: .8rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.6rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  /*.bg-principal {
    margin-top: 6rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.7rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.5rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1.1rem;
  }

  .text-modal {
    font-size: 1.1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1.2rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

@media (min-width: 2561px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

</style>