<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto py-5">

      <div class="col-12 py-sm-5">

        <p class="text-center primary-text">¿Por qué consolidar tu <br> deuda con nosotros?</p>

        <div class="row">
          <div class="col-12 col-sm-4 d-flex py-5 px-sm-5" :class="(index===1)?'border':''" v-for="(item,index) of items">
            <div class="row align-self-stretch">

              <div class="col-4 col-sm-12 p-0 h-75">
                <div class="text-center mb-3">
                  <img :src="item.image" :alt="index" class="img-item mt-5">
                </div>
              </div>
              <div class="col-8 col-sm-12 p-0 d-flex align-items-center justify-content-center">
                <p class="secondary-text-bold p-sm-0 test w-75 text-md-center">
                  {{ item.textUp }}<span v-html="item.textDown" class="secondary-text">
              </span>
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "WhyWithUs",
  data: () =>
      ({

        items:
            [
              {
                image: '/img/loans/graphic.png',
                textUp: "Interés más bajo ",
                textDown: "que <br>un préstamo bancario."
              },
              {
                image: '/img/loans/money.png',
                textUp: "Aprobación en 72 hrs",
                textDown: ''
              },
              {
                image: '/img/loans/calendar.png',
                textUp: "Plan de pagos",
                textDown: "<br>personalizado."
              },
            ]
      })
}
</script>

<style scoped>

.bg-principal {
  background-color: #00b4d0;
}

.primary-text {
  font-family: OpenSans-ExtraBold;
  color: #FFFFFF;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.secondary-text-bold {
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.border {
  border-style: solid;
  border-color: #FFFFFF;
}
.test
{
  line-height: 1.2rem;
}
@media (min-width: 320px) and (max-width: 374px) {
  .img-item {
    width: 70%;
  }

  .border {
    border-width: 2px 0px !important;
  }


}

@media (min-width: 375px) and (max-width: 424px) {
  .img-item {
    width: 70%;
  }

  .border {
    border-width: 2px 0px !important;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .img-item {
    width: 60%;
  }

  .border {
    border-width: 2px 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .img-item {
    width: 50%;
  }

  .border {
    border-width: 0px 2px !important;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .img-item {
    width: 30%;
  }

  .primary-text {
    font-size: 1.2rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1rem;
  }

  .border {
    border-width: 0px 2px !important;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  .img-item {
    width: 30%;
  }

  .primary-text {
    font-size: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1rem;
  }

  .border {
    border-width: 0px 2px !important;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .img-item {
    width: 25%;
  }

  .primary-text {
    font-size: 2rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.6rem;
  }

  .border {
    border-width: 0px 3px !important;
  }
}

</style>