<template>

  <div>

    <v-card elevation="0">

      <v-card-text>

        <p class="text-center" style="color:#2A3D99" v-if="version === 'v2'">
          Elimina las cuentas que no deseas liquidar
        </p>

        <v-carousel height="370"
                    hide-delimiter-background
                    hide-delimiters>
          <v-carousel-item v-for="(item, index) in debts" :key="index">

            <v-card elevation="3" class="mt-5 mx-auto" style="width: 80%">
              <v-card-actions>
                <v-spacer/>
                <v-btn style="z-index: 2; position: absolute; right: 10px; top: 8px" color="#FF0033" fab dark x-small
                       v-on:click="removeDebt(item.account)">
                  <v-icon dark>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </v-card-actions>
              <v-card-text>
                <div class="text-center mb-2">
                  <img :src="image(item.bank)" :alt="item.bank" class="img-bank">
                </div>
                <div class="">
                  <p class="list">Debes: <br>
                    <span class="list-bold" style="color: #000">{{ item.debt }}</span>
                  </p>
                  <p class="list">Con DB Menos paga: <br>
                    <span class="list-bold" style="color: #2A3D99">{{ item.pay }}</span>
                  </p>
                  <p class="list">Descuento del: <br>
                    <span class="list-bold" style="color: #8ac43f">{{ item.percent }}%</span>
                  </p>
                </div>
              </v-card-text>
            </v-card>
          </v-carousel-item>
        </v-carousel>

        <div class="col-12 col-sm-10 mx-auto" v-if="version === 'v1'">
          <v-slider
              v-model="slider.val"
              :label="slider.label"
              :thumb-color="slider.color"
              thumb-label="always"
              class="mt-3"
          ></v-slider>
        </div>

        <!--
        <div class="row" v-if="version === 'v2'">
          <div class="col-12 mt-2 text-center">
            <v-btn
                dark
                elevation="2"
                color="#2a3d99"
                class="btn-action"
                @click="toFollow"
                :disabled="btn"
            >Continuar
            </v-btn>
          </div>
        </div>
        -->

      </v-card-text>

    </v-card>

    <!--Snak bar-->
    <div class="text-center ">
      <v-snackbar
          v-model="snackbar"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="#ff0033"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>

</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  name: "CarouselCards",
  computed:
      {
        ...mapState(['program_debts'])
      },
  props:
      {
        version: {required: false, default: 'v1'},
        debts: [],
        slider: {required: false, default: null, type: Object},
      },
  data: () => ({
    //Snack Bar
    alert: false,
    message: '',
    snackbar: false,
  }),
  methods: {

    ...mapActions(['addDebts']),

    //Mensaje de estado de las peticiones
    showMessage(message, status = true) {
      this.snackbar = status
      this.message = message
    },
    //Elimina una cuenta del programa
    removeDebt(account) {

      if (this.program_debts.length > 1) {

        const aux = this.program_debts.filter((item) => item.account !== account)

        this.addDebts(aux)

        this.$emit('calculator', this.program_debts)

      } else {
        this.showMessage('Al menos debe de existir una cuenta por agregar al programa')
      }
    },

    toFollow() {
      //this.$emit('updateClientProfile')
    },
    image(idBank) {
      let prefix = '/img/new_process/desktop/banks/'
      switch (idBank) {
        case 1:
          prefix += 'bbva.svg'
          break
        case 2:
          prefix += 'citibanamex.svg'
          break
        case 3:
          prefix += 'santander.svg'
          break
        case 4:
          prefix += 'hsbc.svg'
          break
        case 5:
          prefix += 'banorte.svg'
          break
        case 6:
          prefix += 'sears.svg'
          break
        case 7:
          prefix += 'liverpool.svg'
          break
        case 8:
          prefix += 'american_express.svg'
          break
        case 9:
          prefix += 'scotiabank.svg'
          break
        case 10:
          prefix += 'global_card.svg'
          break
        case 11:
          prefix += 'inbursa.svg'
          break
        case 12:
          break
        case 13:
          prefix += 'invex.svg'
          break
        case 14:
          prefix += 'credito_familiar.svg'
          break
        case 15:
          prefix += 'suburbia.svg'
          break
        case 16:
          prefix += 'waltmart.svg'
          break
        case 17:
          prefix += 'credomatic.svg'
          break
        case 18:
          prefix += 'secorse.svg'
          break
        case 19:
          prefix += 'recremex.svg'
          break
        case 20:
          prefix += 'c_a.svg'
          break
        case 21:
          prefix += 'palacio_de_hierro.svg'
          break
      }

      return prefix
    }
  }
}
</script>

<style scoped>

.list {
  font-family: OpenSans-Regular;
}

.list-bold {
  font-family: OpenSans-Bold;
}

.btn-action {
  text-transform: none
}

@media (min-width: 320px) and (max-width: 374px) {
  .list {
    font-size: 1rem;
  }

  .list-bold {
    font-size: 1.2rem;
  }

  .img-bank {
    width: 50%;
  }

  .btn-action {
    width: 95%;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .list {
    font-size: 1rem;
  }

  .list-bold {
    font-size: 1.2rem;
  }

  .img-bank {
    width: 40%;
  }

  .btn-action {
    width: 95%;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .list {
    font-size: 1rem;
  }

  .list-bold {
    font-size: 1rem;
  }

  .img-bank {
    width: 40%;
  }

  .btn-action {
    width: 95%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .list {
    font-size: 1.2rem;
  }

  .list-bold {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 40%;
  }

  .btn-action {
    width: 80%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .list {
    font-size: 1.2rem;
  }

  .list-bold {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 30%;
  }

  .btn-action {
    width: 80%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .list {
    font-size: 1.2rem;
  }

  .list-bold {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 30%;
  }

  .btn-action {
    width: 80%;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .list {
    font-size: 1.5rem;
  }

  .list-bold {
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 15%;
  }

  .btn-action {
    width: 80%;
  }
}

</style>