<template>
  <section class="section section-sm">
    <div class="container pt-lg-md" style="padding:5rem;">
      <div class="row justify-content-center">
        <card
          class="col-lg-12 text-center"
          style="margin: 5rem; border: 0; border-radius: 10px"
        >
          <h1 style="color: #2a3d9a; font-size: 3rem" class="text-black">
            ¡Estamos cerca!
          </h1>
          <h2 class="display-2" style="color: #5f6062; font-size: 2rem">
            Sube el reverso de tu INE
          </h2>
        </card>
        <div class="col-lg-12" style="margin-bottom: 10%">
          <div style="text-align: center; text-transform: uppercase">
            <div>
              <dropbox
                text
                :ocrFile="true"
                type="ID_B"
                :files="this.userFiles"
                v-on:uploadFile="uploadFileTrasera"
                v-on:resentUploadFile="uploadFileTrasera"
              ></dropbox>
            </div>
          </div>
        </div>
        <div class="d-block d-sm-none text-center" style="margin-top: 2rem">
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.dbmenos.dbm_android_client&hl=es"
              target="_blank"
            >
              <img src="img/googleplay.png" style="width: 50%" />
            </a>

            <a
              href="https://apps.apple.com/mx/app/db-menos/id1503253844?l=en"
              target="_blank"
            >
              <img style="width: 40%" src="img/appstore.png" />
            </a>
          </div>
        </div>
        <div class="d-none d-sm-block d-md-none" style="margin-top: 2rem">
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.dbmenos.dbm_android_client&hl=es"
              target="_blank"
            >
              <img src="img/googleplay.png" style="width: 50%" />
            </a>

            <a
              href="https://apps.apple.com/mx/app/db-menos/id1503253844?l=en"
              target="_blank"
            >
              <img style="width: 40%" src="img/appstore.png" />
            </a>
          </div>
        </div>
        <div class="d-none d-md-block d-lg-none" style="margin-top: 2rem">
          <div>
            <a
              href="https://play.google.com/store/apps/details?id=com.dbmenos.dbm_android_client&hl=es"
              target="_blank"
            >
              <img src="img/googleplay.png" style="width: 50%" />
            </a>

            <a
              href="https://apps.apple.com/mx/app/db-menos/id1503253844?l=en"
              target="_blank"
            >
              <img style="width: 40%" src="img/appstore.png" />
            </a>
          </div>
        </div>
        <!--
        <div class="text-center">
          <base-button type="primary" class="my-4" v-on:click="m_register()"
            >Continuar</base-button
          >
        </div>
        !-->
      </div>
    </div>
  </section>
</template>
<script>
import firebase from 'firebase/app';
    import 'firebase/firestore';
    import 'firebase/analytics';
    import 'firebase/messaging';
import Register from "@/views/RegisterOCRINEA.vue";
import Vue from "vue";
import VueResource from "vue-resource";
import { V } from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import Dropbox from "../components/Dropbox";
Vue.use(VueFacebookPixel);
Vue.use(VueResource);

export default {
  components: {
    Register,
    Dropbox,
    VueFacebookPixel,
  },
  created() {
    //this.discountdoc = JSON.parse(localStorage.getItem("discountdoc"));
    //this.phone = JSON.parse(localStorage.getItem("phone"));
    //this.from = JSON.parse(localStorage.getItem("Marketing"));
    //this.cal = JSON.parse(localStorage.getItem("calcData"));
    //this.dataCal = JSON.parse(localStorage.getItem("calculate"));
    firebase.analytics().logEvent("lead_complete_2");
    fbq("track", "lead_complete_2");
    gtag("event", "lead_complete_2", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });
    //document.location.reload();
    fbq("track", "starting_lead_automated");
    firebase.analytics().logEvent("starting_lead_automated");
    gtag("event", "starting_lead_automated", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });

    this.client = JSON.parse(
            JSON.stringify(JSON.parse(localStorage.getItem(V.STORE_KEYS.USER)))
        );
        if (this.client) {
            this.getClientProfile();
        } else {
            this.$router.push("/register");
        }
  },
  data() {
    return {
      dataCal: null,
      userFiles: [],
      lore: "Register",
      registerOrNot: false,
      a: true,
      b: false,
      login: {
        phone: "",
        willLogin: false,
        code: "",
      },
      signup: {
        name: "",
        lastname_1: "",
        lastname_2: "",
        email: "",
        phone: "",
        password: "",
        password_val: "",
        device: "",
      },
      V: V,
      leadInfo: {
        phone: "",
        code: "",
        name: "",
        lastname_1: "",
        lastname_2: "",
        email: "",
        device: "",
      },
      leadValidationCode: "",
      leadSent: false,
      loginSent: false,
      leadDat: false,
      from: "",
      cal: "",
      request_status: "",
      discountdoc: "",
      new_user: false,
      device: "",
      regEmail:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regPhone: /[0-9]{10}?/,
      phone: {
        phone: "",
        code: "",
      },
    };
  },
  computed: {
    isEmailValid: function () {
      return !this.signup.email
        ? ""
        : this.regEmail.test(this.signup.email)
        ? "has-success"
        : "has-danger";
    },
    isPhoneValid: function () {
      return !this.signup.phone
        ? ""
        : this.regPhone.test(this.signup.phone)
        ? "has-success"
        : "has-danger";
    },
    getMarketing() {
      return this.$store.state.marketing;
    },
  },
  methods: {
    getClientProfile() {
      this.$axios.defaults.headers.common["device"] = "web";
            let url =this.$v.SERVER + this.$v.OPS.GET_PROFILE;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[this.$v.API.SUCCESS]) {
                    this.client = response.data[this.$v.API.ROW];
                }
            });
        },
    async uploadFileTrasera(obj) {
      let file = obj.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$showLoader(true);
      let body = {
        phone: this.client.phone,
        filename: obj.file.name,
      };
      await this.$axios
        .post(V.SERVER + V.PREREGISTER.UPLOAD_FILE, body)
        .then((response) => {
          let data = response.data;
          if (response.data.success) {
            let fileURL = data[V.API.ROW].url;
            this.$axios.put(fileURL, file).then((putObjectResponse) => {
              if (putObjectResponse.status === 200) {
                this.$notify({
                  text: "INE Cargada Correctamente",
                  type: "success",
                });
                this.$axios
                  .post(V.SERVER + V.PREREGISTER.PREUSER_OCR_IDB, body)
                  .then(async (response) => {
                    this.$showLoader(false);
                    if (response.data.success) {
                      let user = response.data.row;
                      console.log(user)
                      this.$router.push({
                        path: "/start2",
                      });
                    } else {
                      this.$showLoader(false);
                      this.$notify({
                        text: "Error, intenta nuevamente",
                        type: "warn",
                      });
                    }
                  })
                  .catch((e) => {
                    console.log(e);
                    this.$showLoader(false);
                    //alert("No se logró subir, intente nuevamente");
                  });
              } else {
                this.$notify({
                  text: "No se logró subir la INE",
                  type: "warn",
                });
              }
            });
          } else {
            this.$notify({
              text: "Error, intenta nuevamente",
              type: "warn",
            });
          }
        })
        .catch((e) => {
          console.log(e);
          this.$showLoader(false);
          //alert("No se logró subir, intente nuevamente");
        });
    },
    cambiarComponentRegister() {
      this.lore = "Register";
      this.a = true;
      this.b = false;
      this.comienzo = false;
    },
    cambiarComponentLogin() {
      this.lore = "Login";
      this.b = true;
      this.a = false;
      this.comienzo = false;
    },
    saveCalculation() {
      let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATION;
      let json = {
        calculation: JSON.parse(localStorage.getItem("discountdoc")),
      };
      this.$axios.post(url, json).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.$router.push({
            path: "/start",
          });
        }
      });
    },
    m_hasValidEmail() {
      let regex = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|es|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b"
      );
      return regex.test(this.leadInfo.email);
    },
    m_hasValidPhone() {
      return (
        !isNaN(this.leadInfo.phone) &&
        this.leadInfo.phone.length > 9 &&
        this.leadInfo.phone.length < 11
      );
    },
    m_register() {
      if (this.leadInfo.name.length === 0) {
        this.request_status = "Necesitas ingresar tu nombre";
      } else if (this.leadInfo.lastname_1.length === 0) {
        this.request_status = "Necesitas ingresar un primer apellido";
      } else if (this.leadInfo.lastname_2.length === 0) {
        this.request_status = "Necesitas ingresar un segundo apellido";
      } else if (this.leadInfo.email.length === 0) {
        this.request_status = "Necesitas ingresar un correo electrónico";
      } else if (this.leadValidationCode.length > 6) {
        this.request_status = "El código solamente debe contener 5 digítos";
      } else {
        this.request_status = "";
        this.$showLoader(true);
        var _data = {
          phone: this.phone.phone,
          code: this.phone.code,
          name: this.leadInfo.name,
          lastname_1: this.leadInfo.lastname_1,
          lastname_2: this.leadInfo.lastname_2,
          email: this.leadInfo.email,
        };
        let url = V.SERVER + V.OPS.REGISTER;

        this.$http.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success == true) {
              let user = res.data.row;
              let session = user.session;
              this.$alert("Se registro Correctamente");
              localStorage.clear("phone");
              delete user.session;
              this.$store.commit("setUser", user);
              localStorage.setItem(this.$v.STORE_KEYS.SESSION, session.session);
              localStorage.setItem(
                this.$v.STORE_KEYS.USER,
                JSON.stringify(user)
              );
              this.$axios.defaults.headers.common[this.$v.STORE_KEYS.SESSION] =
                session[this.$v.STORE_KEYS.SESSION];
              this.$axios.defaults.headers.common["device"] = "web";
              this.login.phone = this.leadInfo.phone;
              this.login.code = this.leadInfo.code;
              this.new_user = true;

              this.$root.$emit("refreshHeader");
              Vue.analytics.fbq.event("RegisterComplete", {
                content_name:
                  this.leadInfo.name +
                  " " +
                  this.leadInfo.phone +
                  " registration successful",
              });
              firebase.analytics().logEvent("register_complete");
              gtag("event", "register_complete", {
                send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
              });
              fbq("track", "register_complete");

              if (this.new_user) {
                if (localStorage.getItem("discountdoc")) this.saveCalculation();
                else this.$router.push("calculator").catch((err) => {});
              } else if (user.contract) {
                // Si tiene contrato
                this.$router.push("profile"); // A perfil
              } else {
                let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
                this.$axios.get(url, session).then((response) => {
                  let data = response.data;
                  if (data[V.API.SUCCESS]) {
                    if (
                      !data.row.basic_information ||
                      !data.row.basic_information2 ||
                      !data.row.address_information ||
                      !data.row.official_id
                    ) {
                      this.$router.push("/start");
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      !data.row.credit_report_signature
                    ) {
                      this.$router.push({
                        path: "/AuthorizationBC",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      !data.row.credit_report &&
                      !data.row.program_calculation
                    ) {
                      this.$router.push({
                        path: "/calcular",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      data.row.credit_report &&
                      data.row.program_calculation &&
                      !data.row.contract
                    ) {
                      this.$router.push({
                        path: "/start2",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      data.row.credit_report &&
                      data.row.program_calculation &&
                      data.row.contract &&
                      data.row.active
                    ) {
                      this.$router.push({
                        path: "/profile",
                      });
                    } else {
                      let url = V.SERVER + V.OPS.GET_PROGRAM_CALCULATION;
                      this.$axios.get(url, session).then((response) => {
                        let data = response.data;
                        if (data[V.API.SUCCESS]) {
                          if (data.row.debts) {
                            // Si !(contrato && firma) pero sí cálculos guardados
                            this.$router.push("/start"); // A completar registro
                          } else {
                            this.$router.push("/calculator").catch((err) => {});
                          }
                        } else if (data.reason === 1) {
                          this.$router.push("/calculator").catch((err) => {});
                        }
                      });
                    }
                  }
                });
              }
            } else {
              switch (res.data.reason) {
                case 7:
                  this.request_status = "Datos inválidos, intenta nuevamente";
                  break;
                case 6:
                  this.request_status = "Datos incorrectos, intenta nuevamente";
                  break;
                default:
                  this.request_status = "Algo salió mal, intenta nuevamente";

                  break;
              }
            }
          },
          (er) => {
            console.log(er);
          }
        );
      }
    },
    m_insertLead() {
      if (this.leadSent) {
        this.$showLoader(true);
        var _data = {
          phone: this.leadInfo.phone,
          code: this.leadValidationCode,
        };
        gtag("event", "conversion", {
          send_to: "AW-718930865/eqePCI6_sacBELGH6NYC",
          event_callback: null,
        });

        let url = V.SERVER + V.OPS.VALIDATE_PREREGISTER;

        this.$http.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success === true) {
              this.$alert("Código Correcto");
              this.leadDat = true;
            } else {
              this.$alert("Código inválido, intenta nuevamente");
            }
          },
          (er) => {
            console.log(er);
          }
        );
      } else {
        this.$showLoader(true);
        var _data = {
          phone: this.leadInfo.phone,
        };
      }
      let url = V.SERVER + V.OPS.PREREGISTER;
      gtag("event", "conversion", {
        send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
      });
      this.$http.post(url, _data).then(
        (res) => {
          this.$showLoader(false);
          if (res.data.success) {
            this.$alert("Código Envíado");
            this.leadSent = res.data.success;
            this.leadSent = true;
          } else if (!this.leadSent) {
            this.$alert("Algo salió mal, tal vez tu número ya este registrado");
          }
        },
        (er) => {
          console.log(er);
        }
      );
    },
  },
};
</script>
<style>
@media (min-width: 1400px) {
  .tamaño {
    height: 50rem;
  }
  .tarjeta {
    margin-top: 6rem;
  }
}
</style>
