<template>
    <div>
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0" style="background-color:#FFFF;">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="text-center" style="margin-top:3rem; padding:4rem; background-color: #092699; color:white; text-transform: uppercase">
                    <p style="margin-top:2.5rem;font-size: 18px;">Con<b> DB Menos </b> dile adiós a tus deudas </p>
                    <router-link to="/" class="btn btn-info" style="border:none; font-size: 18px;  margin-bottom:-2rem;">
                            ¡Unirme ahora!
                        </router-link>
                         <h1 class="display-3 text-white" style="margin-top:2rem; padding:2rem;">{{title}}</h1>
                    </div>
                <div class="container shape-container  text-center" style="margin-top:15rem;">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-12">
                                <h1 class="display-3 text-white" style="margin-top:-6rem; padding:2rem;">{{title}}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section
                class="section section-lg pt-lg-0 mt--300 subir"
                style="background-color:#092699;"
            >
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-12">
                            <div class="row row-grid justify-content-center">
                                <div v-for="info in infos" :key="info.url_link" class="col-lg-12">
                                    <b-card
                                        class="text-center"
                                        @click="showModalInfo(info.url_link)"
                                    >
                                        <h2>{{info.title}}</h2>
                                        <br />
                                        <img
                                            img-alt="Imagen no Encontrada"
                                            :src="'https://www.dbmenos.com/img/blog/'+ info.og_picture"
                                            style="width:20rem; heigth:10rem;"
                                        />
                                        <hr />
                                        <b-card-text>{{info.description}}</b-card-text>
                                        <hr />
                                        <div align="right">
                                            <span class="h6">{{formatDate(info.created)}}</span>
                                        </div>
                                    </b-card>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import VueResource from "vue-resource";
import { V } from "@/V";
import VueSignaturePad from "vue-signature-pad";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import moment, { max } from "moment";
Vue.use(VueFacebookPixel);
export default {
    metaInfo: {
        title: "DBMenos",
        titleTemplate: "%s | Blog"
    },

    components: {
        VueFacebookPixel
    },
    computed: {
        banks() {
            return this.$store.state.banks;
        },
        nombre_banco: function() {
            return this.getBank(this.account.bank).name;
        }
    },
    created() {
        //Vue.analytics.fbq.init(V.FACEBOOK_PIXEL_ID, {})
        this.getBlogEntry();
    },
    mounted() {},
    data() {
        return {
            infos: [],
            title: "Blog | DBMENOS",
            idB: "",
            url_linkB:"",
            titleB: "",
            descriptionB: "",
            fechaB: "",
            og_pictureB: "",
            postB: "",
            miniURL: ""
        };
    },
    methods: {
        formatDate(date) {
            return moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
        },
        showModalInfo(nameBlog) {
            this.url_linkB = nameBlog;
            let query = null;
            if (this.url_linkB) {
                this.$router.push("/blog" + "/" + this.url_linkB);
            } else {
                this.$router.push({
                    name: "blogs",
                    query: query
                });
            }
        },
        hideModalInfo() {
            this.$refs["my-modalInfo"].hide();
        },
        async getBlogEntry() {
            this.$showLoader(true);
            let url = V.SERVER + V.OPS.GET_BLOG_ENTRIES;
            let response = await this.$axios.get(url);
            this.$showLoader(false);
            let data = response.data;
            if (data.success) {
                this.infos = [];
                for (let i = 0; i < data.rows.length; i++) {
                    this.infos.push(data.rows[i]);
                    this.miniURL =
                        "https://www.dbmenos.com/img/blog/" +
                        data.rows[i].og_picture;
                }
                this.entry = data.row;
                console.log(this.infos.url_link)
                this.$forceUpdate();
            }
        }
    }
};
</script>
<style>
.ql-align-center {
    text-align: center;
}
.ql-align-justify {
    text-align: justify;
}
iframe {
    display: table;
    margin: 0 auto;
}
img {
    max-width: 90%;
    height: auto;
}
p {
    padding: 0 20px;
}
</style>
