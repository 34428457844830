<template>
  <div class="text-center">
    <v-dialog
        v-model="alert"
        width="500"
        persistent
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>
        <v-card-text>

          <div class="pt-5">
            <p class="font-weight-bold text-alert"
               style="color: #ff5252">
              {{ title }}
            </p>
            <p class="font-weight-bold text-alert">
              {{ message }}
            </p>
          </div>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="error"
                text
                @click="alert = false"
            >
              Aceptar
            </v-btn>
          </v-card-actions>

        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "Alert",
  data: () => ({
    alert: true,
    title: 'Oh Oh...',
    message: 'ya existe una cuenta asociada a este usuario.'
  })
}
</script>

<style scoped>

.text-alert {
  color: #ff5252;
}

</style>