<template>
  <section
    class="section section-shaped section-lg my-5 tamaño"
  >
    <div class="shape shape-style-1 shape-skew">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
      <div class="container pt-lg-md text-center">
        <div class="row justify-content-center">
        <div class="col-9 text-center">
          <div style="width: 100%; margin-top:3rem; border-radius:2rem;">
            <div class="card-body text-center">
              <img
                src="img/brand/DB Menos logo azul 2020_.png"
                style="width: 30rem; margin-top: -8rem"
              />
              <base-input
                alternative
                type="Number"
                v-model="leadInfo.phone"
                placeholder="Ingresa Télefono"
                :valid="m_hasValidPhone()"
                v-if="(!forgotten_password && !validate_cod_newpass && !nextRegister)"
                addon-left-icon="fa fa-phone"
                aria-hidden="true"
              ></base-input>
              <base-input
                alternative
                type="Number"
                style="margin-top:0.5rem;" 
                :disable="user_not_password"
                v-model="leadInfo.code"
                v-if="(validate_cod_newpass || no_user && !nextRegister)"
                placeholder="Código"
                addon-left-icon="fa fa-mobile"
                aria-hidden="true"
              ></base-input>
              <base-input
                alternative
                type="Password"
                v-model="leadInfo.password"
                v-if="(user_password && !validate_cod_newpass ) || forgotten_password"
                placeholder="Contraseña"
                addon-left-icon="fa fa-key"
                aria-hidden="true"
              ></base-input>
              <div class="row" v-if="user_password && !validate_cod_newpass && !forgotten_password" v-on:click="forgottenPassword()">
                <div class="col-12">
                  <i class="fa fa-lock fa-2x" aria-hidden="true"></i>
                </div>
                <div class="col-12">
                  <p class="text-muted" style="font-size:1rem; margin-bottom:0.5rem; margin-top:-0.5rem;"><strong>¿Olvidaste tu contraseña?</strong></p>
                </div>
              </div>
              <base-input
                alternative
                type="Password"
                v-model="leadInfo.validatedNewPass"
                v-if="forgotten_password"
                placeholder="Repetir Contraseña"
                addon-left-icon="fa fa-key"
                aria-hidden="true"
              ></base-input>
              <div>
                <div v-if="nextRegister" style="margin-top:1rem;">
                            <base-input
                                alternative
                                v-model="leadInfo.name"
                                placeholder="Nombre"
                                addon-left-icon="fa fa-user-circle"
                                aria-hidden="true"
                            ></base-input>
                            <base-input
                                alternative
                                v-model="leadInfo.lastname_1"
                                placeholder="Apellido Paterno"
                                addon-left-icon="fa fa-user-circle"
                                aria-hidden="true"
                            ></base-input>
                            <base-input
                                alternative
                                v-model="leadInfo.lastname_2"
                                placeholder="Apellido Materno"
                                addon-left-icon="fa fa-user-circle"
                                aria-hidden="true"
                            ></base-input>
                            <base-input
                                alternative
                                class="mb-3"
                                type="email"
                                v-model="leadInfo.email"
                                :valid="m_hasValidEmail()"
                                placeholder="Correo electrónico"
                                addon-left-icon="ni ni-email-83"
                            ></base-input>
                            <base-input
                                alternative
                                type="password"
                                v-model="leadInfo.password"
                                placeholder="Contraseña"
                                addon-left-icon="fa fa-key"
                                aria-hidden="true"
                            ></base-input>
                            <base-input
                                alternative
                                class="mb-3"
                                type="password"
                                v-model="leadInfo.validatedNewPass"
                                placeholder="Repetir Contraseña"
                                addon-left-icon="fa fa-key"
                            ></base-input>
                        </div>
              </div>
                  <base-button
                    type="primary"
                    class="my-1"
                    style="width: 100%"
                    v-if="!user_not_password && !forgotten_password && !validate_cod_newpass && !no_user && !user_password && !nextRegister"
                    v-on:click="m_checkStatusUser()"
                    >Continuar</base-button>
                    <base-button
                    type="primary"
                    class="my-1"
                    style="width: 100%"
                    v-if="!forgotten_password && !validate_cod_newpass && user_password"
                    v-on:click="m_loginWhats()"
                    >Iniciar</base-button>
                    <base-button
                    type="primary"
                    class="my-1"
                    style="width: 100%"
                    v-if="no_user && !nextRegister"
                    v-on:click="m_insertLead()"
                    >Continuar</base-button>
                    <base-button
                    type="primary"
                    class="my-1"
                    style="width: 100%"
                    v-if="nextRegister"
                    v-on:click="m_register()"
                    >Continuar</base-button>
                    <base-button
                    type="primary"
                    class="my-1"
                    v-if="!forgotten_password && validate_cod_newpass"
                    style="width: 100%"
                    v-on:click="validatedCode()"
                    >Validar Código</base-button>
                    <base-button
                    type="primary"
                    class="my-1"
                    v-if="forgotten_password && !validate_cod_newpass"
                    style="width: 100%"
                    v-on:click="setPassword()"
                    >Guardar Contraseña</base-button>
            </div>
          </div>
        </div>
        </div>
      </div>
  </section>
</template>

<script>
import firebase from 'firebase/app';
    import 'firebase/firestore';
    import 'firebase/analytics';
    import 'firebase/messaging';
import Register from "@/views/RegisterOCRINEA.vue";
import Vue from "vue";
import VueResource from "vue-resource";
import { V } from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
Vue.use(VueFacebookPixel);
Vue.use(VueResource);

export default {
  components: {
    Register,
    VueFacebookPixel,
  },
  created() {
    this.discountdoc = JSON.parse(localStorage.getItem("discountdoc"));
    this.from = JSON.parse(localStorage.getItem("Marketing"));
    this.cal = JSON.parse(localStorage.getItem("calcData"));
    this.lead = JSON.parse(localStorage.getItem("Lead"));
    this.leadCode = JSON.parse(localStorage.getItem("LeadCode"));

    firebase.analytics().logEvent("lead_complete_2");
    fbq("track", "lead_complete_2");
    gtag("event", "lead_complete_2", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });

    fbq("track", "starting_lead_automated");
    firebase.analytics().logEvent("starting_lead_automated");
    gtag("event", "starting_lead_automated", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });
    if(this.leadCode && this.lead){
      this.m_checkStatusUser();
    }
  },
  data() {
    return {
      lead: null,
      leadCode:null,
      lore: "Register",
      no_user:false,
      user_not_password:false,
      user_password:false,
      forgotten_password:false,
      registerOrNot: false,
      validate_cod_newpass: false,
      nextRegister:false,
      login: {
        phone: "",
        willLogin: false,
        code: "",
      },
      signup: {
        name: "",
        lastname_1: "",
        lastname_2: "",
        email: "",
        phone: "",
        password: "",
        password_val: "",
        device: "",
      },
      V: V,
      leadInfo: {
        phone: "",
        code: "",
        name: "",
        newPassword:"",
        validatedNewPass:"",
        lastname_1: "",
        lastname_2: "",
        email: "",
        device: "",
        whatsapp: true,
      },
      leadValidationCode: "",
      leadSent: false,
      loginSent: false,
      leadDat: false,
      from: "",
      cal: "",
      request_status: "",
      discountdoc: "",
      new_user: false,
      device: "",
      session:"",
      regEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regPhone: /[0-9]{10}?/,
    };
  },
  computed: {
    isEmailValid: function () {
      return !this.signup.email
        ? ""
        : this.regEmail.test(this.signup.email)
        ? "has-success"
        : "has-danger";
    },
    isPhoneValid: function () {
      return !this.signup.phone
        ? ""
        : this.regPhone.test(this.signup.phone)
        ? "has-success"
        : "has-danger";
    },
    getMarketing() {
      return this.$store.state.marketing;
    },
  },
  methods: {
    cambiarComponentRegister() {
      this.lore = "Register";
      this.a = true;
      this.b = false;
      this.comienzo = false;
    },
    cambiarComponentLogin() {
      this.lore = "Login";
      this.b = true;
      this.a = false;
      this.comienzo = false;
    },
    saveCalculation() {
      let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATION;
      let json = {
        calculation: JSON.parse(localStorage.getItem("discountdoc")),
      };
      this.$axios.post(url, json).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.$router.push({
            path: "/start",
          });
        }
      });
    },
    m_hasValidEmail() {
      let regex = new RegExp(
        "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|es|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b"
      );
      return regex.test(this.leadInfo.email);
    },
    m_hasValidPhone() {
        return (
                !isNaN(this.leadInfo.phone) &&
                this.leadInfo.phone.length > 9 &&
                this.leadInfo.phone.length < 11
            );
    },
    m_register() {
      //this.lead.email = this.leadInfo.email
        if (this.leadInfo.name.length === 0 || this.leadInfo.lastname_1.length === 0 || this.leadInfo.lastname_2.length === 0 || this.leadInfo.email.length === 0 ) {
          this.$notify({
            text: "Faltan datos por Ingresar para Continuar el Registro",
            type: "error",
         });
        }  else if (!this.regEmail.test(this.leadInfo.email)) {
          this.$notify({
            text: "El Correo Electronico no es validó",
            type: "error",
         });
        }else if(!(this.leadInfo.password === this.leadInfo.validatedNewPass)){
          this.$notify({
            text: "Las contraseñas no coinciden",
            type: "error",
         });
        }  else {
          this.$showLoader(true);
          this.$axios.defaults.headers.common["device"] = "web";
          var _data = {
            phone: this.leadInfo.phone,
            code: this.leadInfo.code,
            name: this.leadInfo.name,
            lastname_1: this.leadInfo.lastname_1,
            lastname_2: this.leadInfo.lastname_2,
            email: this.leadInfo.email,
            password: this.leadInfo.password,
            device:'web'
          };
          let url = V.SERVER + V.OPS.REGISTER;

          this.$http.post(url, _data).then(
            (res) => {
              this.$showLoader(false);
              if (res.data.success == true) {
                let user = res.data.row;
                let session = user.session;
                console.log(_data)
                this.$notify({
                  text: "Se registro Correctamente",
                  type: "success",
                });
                delete user.session;
                this.$store.commit("setUser", user);
                localStorage.setItem(
                  this.$v.STORE_KEYS.SESSION,
                  session.session
                );
                localStorage.setItem(
                  this.$v.STORE_KEYS.USER,
                  JSON.stringify(user)
                );
                this.$axios.defaults.headers.common[
                  this.$v.STORE_KEYS.SESSION
                ] = session[this.$v.STORE_KEYS.SESSION];
                this.$axios.defaults.headers.common["device"] = "web";
                this.login.phone = this.leadInfo.phone;
                this.login.code = this.leadInfo.code;
                this.new_user = true;

                this.$root.$emit("refreshHeader");
                Vue.analytics.fbq.event("RegisterComplete", {
                  content_name:
                    this.leadInfo.name +
                    " " +
                    this.leadInfo.phone +
                    " registration successful",
                });
                firebase.analytics().logEvent("register_complete");
                gtag("event", "register_complete", {
                  send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
                });
                fbq("track", "register_complete");

                if (this.new_user) {
                 this.$router.push("regis").catch((err) => {});
                } else if (user.contract) {
                  // Si tiene contrato
                  this.$router.push("profile"); // A perfil
                } else {
                  let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
                  this.$axios.get(url, session).then((response) => {
                    let data = response.data;
                    if (data[V.API.SUCCESS]) {
                      if (
                      !data.row.basic_information ||
                      !data.row.basic_information2 ||
                      !data.row.address_information
                    ) {
                      this.$router.push("/regis");
                    }else if (
                      data.row.basic_information ||
                      data.row.basic_information2 ||
                      data.row.address_information
                    ) {
                      this.$router.push("/start");
                    } else if (
                        data.row.basic_information &&
                        data.row.basic_information2 &&
                        data.row.address_information &&
                        !data.row.official_id &&
                        !data.row.credit_report_signature
                      ) {
                        this.$router.push({
                          path: "/AuthorizationBC",
                        });
                      } else if (
                        data.row.basic_information &&
                        data.row.basic_information2 &&
                        data.row.address_information &&
                        data.row.official_id &&
                        data.row.credit_report_signature &&
                        !data.row.credit_report &&
                        !data.row.program_calculation
                      ) {
                        this.$router.push({
                          path: "/calcular",
                        });
                      } else if (
                        data.row.basic_information &&
                        data.row.basic_information2 &&
                        data.row.address_information &&
                        data.row.official_id &&
                        data.row.credit_report_signature &&
                        data.row.credit_report &&
                        data.row.program_calculation &&
                        !data.row.contract
                      ) {
                        this.$router.push({
                          path: "/start2",
                        });
                      } else if (
                        data.row.basic_information &&
                        data.row.basic_information2 &&
                        data.row.address_information &&
                        data.row.official_id &&
                        data.row.credit_report_signature &&
                        data.row.credit_report &&
                        data.row.program_calculation &&
                        data.row.contract &&
                        data.row.active
                      ) {
                        this.$router.push({
                          path: "/profile",
                        });
                      }
                      // } else {
                      //   let url = V.SERVER + V.OPS.GET_PROGRAM_CALCULATION;
                      //   this.$axios.get(url, session).then((response) => {
                      //     let data = response.data;
                      //     if (data[V.API.SUCCESS]) {
                      //       if (data.row.debts) {
                      //         // Si !(contrato && firma) pero sí cálculos guardados
                      //         this.$router.push("/start"); // A completar registro
                      //       } else {
                      //         this.$router
                      //           .push("/calculator")
                      //           .catch((err) => {});
                      //       }
                      //     } else if (data.reason === 1) {
                      //       this.$router.push("/calculator").catch((err) => {});
                      //     }
                      //   });
                      // }
                    }
                  });
                }
              } else {
                switch (res.data.reason) {
                  case 7:
                    this.$notify({
                      text: "Error en Inición de Sesión, Intenta nuevamente",
                      type: "error",
                    });
                    break;
                  case 6:
                    this.$notify({
                      text: "Datos Incorrectos, Intenta nuevamente",
                      type: "error",
                    });
                    break;
                  case 5:
                    this.$notify({
                      text: "El correo ya existe ingresa otro diferente",
                      type: "error",
                    });
                    break;
                  default:
                    this.$notify({
                      text: "Algo salió mal, Intenta nuevamente",
                      type: "error",
                    });
                    break;
                }
              }
            },
            (er) => {
              console.log(er);
            }
          );
          }
    },
    m_insertLead() {
        this.$showLoader(true);
        var _data = {
          phone: this.leadInfo.phone,
          code: this.leadInfo.code,
        };
        gtag("event", "conversion", {
          send_to: "AW-718930865/eqePCI6_sacBELGH6NYC",
          event_callback: null,
        });

        let url = V.SERVER + V.OPS.VALIDATE_PREREGISTER;

        this.$http.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success === true) {
              localStorage.setItem("phone", JSON.stringify(_data));
              this.$notify({
                text: "Código Correcto",
                type: "success",
                });
              this.nextRegister=true;
            } else {
              this.$notify({
                text: "Código inválido, intenta nuevamente",
                type: "error",
                });
            }
          },
          (er) => {
            console.log(er);
          }
        );
    },
    forgottenPassword(){
      this.$showLoader(true);
      console.log('User forgotten')
      let url = V.SERVER + V.OPS.REQUEST_FORGOTTEN_PASSWORD_CODE
      this.leadInfo.whatsapp == true;
        var _data = {
          phone: this.leadInfo.phone,
          whatsapp: this.leadInfo.whatsapp,
        };
        this.$http.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success) {
            this.validate_cod_newpass=true;
                this.$notify({
                text: "Código enviado",
                type: "success",
              });
              }else{
                this.$notify({
                text: "Algo salió mal, Intenta nuevamente",
                type: "error",
              });
              }
           });
    },
     validatedCode(){
       this.$showLoader(true);
      console.log('Validated Code')
      let url = V.SERVER + V.OPS.VALIDATE_SMS_CODE
        var _data = {
          phone: this.leadInfo.phone,
          code: this.leadInfo.code,
        };
        this.$http.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success) {
              this.validate_cod_newpass=false;
              let user = res.data.row;
              this.session = user.session;
              delete user.session;
              this.forgotten_password=true;
                this.$notify({
                text: "Código validado",
                type: "success",
              });
              }else{
                switch (res.data.reason) {
                  case 8:
                    this.$notify({
                    text: "Código Caducado, Solicita Otro",
                    type: "info",
                    });
                    break;
                  case 6:
                    this.$notify({
                    text: "Datos incorrectos, intenta nuevamente",
                    type: "error",
                    });
                    break;
                  default:
                    this.$notify({
                    text: "Algo salió mal, intenta nuevamente",
                    type: "error",
                    });
                    break;
                }
              }
           });
    },
    setPassword(){
      if(this.leadInfo.password === this.leadInfo.validatedNewPass){
      console.log('Set password')
      this.$showLoader(true);
      let headers = {
      "session":this.session.session
    };
      let url = V.SERVER + V.OPS.SET_PASSWORD
        var _data = {
          password: this.leadInfo.password,
        };
        this.$http.post(url, _data,{headers: headers}).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success) {
            this.forgotten_password=true;
                this.$notify({
                text: "Contraseña Actualizada",
                type: "success",
              });
              let user = res.data.row;
              let session = user.session;
              delete user.session;

              this.$store.commit("setUser", user);
              localStorage.setItem(this.$v.STORE_KEYS.SESSION, session.session);
              localStorage.setItem(
                this.$v.STORE_KEYS.USER,
                JSON.stringify(user)
              );
              this.$axios.defaults.headers.common[this.$v.STORE_KEYS.SESSION] =
                session[this.$v.STORE_KEYS.SESSION];
              this.$axios.defaults.headers.common["device"] = "web";
              this.$root.$emit("refreshHeader");
              if (this.new_user) {
                this.$router.push("regis").catch((err) => {});
              } else if (user.contract) {
                // Si tiene contrato
                this.$router.push("profile"); // A perfil
              } else {
                let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
                this.$axios.get(url, session).then((response) => {
                  let data = response.data;
                  if (data[V.API.SUCCESS]) {
                    if (
                      !data.row.basic_information ||
                      !data.row.basic_information2 ||
                      !data.row.address_information
                    ) {
                      this.$router.push("/regis");
                    }else if (
                      data.row.basic_information ||
                      data.row.basic_information2 ||
                      data.row.address_information
                    ) {
                      this.$router.push("/start");
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      !data.row.credit_report_signature
                    ) {
                      this.$router.push({
                        path: "/AuthorizationBC",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      !data.row.credit_report
                    ) {
                      this.$router.push({
                        path: "/calcular",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      data.row.credit_report &&
                      data.row.program_calculation &&
                      !data.row.contract &&
                      !data.row.active
                    ) {
                      this.$router.push({
                        path: "/start2",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      data.row.credit_report &&
                      data.row.program_calculation &&
                      data.row.contract &&
                      data.row.active
                    ) {
                      this.$router.push({
                        path: "/profile",
                      });

                    }
                    // } else {
                    //   let url = V.SERVER + V.OPS.GET_PROGRAM_CALCULATION;
                    //   this.$axios.get(url, session).then((response) => {
                    //     let data = response.data;
                    //     if (data[V.API.SUCCESS]) {
                    //       if (data.row.debts) {
                    //         // Si !(contrato && firma) pero sí cálculos guardados
                    //         this.$router.push("/start"); // A completar registro
                    //       } else {
                    //         this.$router.push("/calculator").catch((err) => {});
                    //       }
                    //     } else if (data.reason === 1) {
                    //       this.$router.push("/calculator").catch((err) => {});
                    //     }
                    //   });
                    // }
                  }
                });
              }
            }else{
                switch (res.data.reason) {
                  case 6:
                    this.$notify({
                    text: "Datos incorrectos, intenta nuevamente",
                    type: "error",
                    });
                    break;
                  default:
                    this.$notify({
                    text: "Algo salió mal, intenta nuevamente",
                    type: "error",
                    });
                    break;
                }
              }
           });
           }else{
             this.$notify({
                    text: "Las contraseñas no coinciden",
                    type: "error",
                    });
           }
    },
    m_checkStatusUser(){
      if(this.leadInfo.phone){
        this.leadInfo.whatsapp == true;
        var _data = {
          login: this.leadInfo.phone,
          whatsapp: this.leadInfo.whatsapp,
        };
        this.$showLoader(true);
        let url = V.SERVER + V.OPS.CHECK_USER_LOGIN;
        this.$http.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success) {
            console.log(res.body.row)
            if(res.body.row.userStatus === 0){
                console.log('User new')
                this.no_user = true;
            }else if(res.body.row.userStatus === 1){
              localStorage.setItem("phone", JSON.stringify(_data));
                console.log('User no password')
                this.user_not_password = true;
                this.validate_cod_newpass=true;
            }else if(res.body.row.userStatus === 2){
                console.log('User password')
                this.user_password = true;
            }
            }else{
                this.$notify({
                text: "Algo salió mal, Intenta nuevamente",
                type: "error",
              });
              }
          }
        );
        }else{
          this.$notify({
                text: "Falta Ingresar Télefono",
                type: "error",
              });
        }
    },
    m_loginWhats() {
        this.$showLoader(true);
        let _data = {
          login: this.leadInfo.phone,
          password: this.leadInfo.password,
        };
        let url = V.SERVER + V.OPS.NEW_LOGIN;

        gtag("event", "conversion", {
          send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
        });
        localStorage.setItem("phone", JSON.stringify(_data));
        this.$axios.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success) {
              let user = res.data.row;
              let session = user.session;
              delete user.session;

              this.$store.commit("setUser", user);
              localStorage.setItem(this.$v.STORE_KEYS.SESSION, session.session);
              localStorage.setItem(
                this.$v.STORE_KEYS.USER,
                JSON.stringify(user)
              );
              this.$axios.defaults.headers.common[this.$v.STORE_KEYS.SESSION] =
                session[this.$v.STORE_KEYS.SESSION];
              this.$axios.defaults.headers.common["device"] = "web";
              this.$root.$emit("refreshHeader");
              if (this.new_user) {
                this.$router.push("regis").catch((err) => {});
              } else if (user.contract) {
                // Si tiene contrato
                this.$router.push("profile"); // A perfil
              } else {
                let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
                this.$axios.get(url, session).then((response) => {
                  let data = response.data;
                  if (data[V.API.SUCCESS]) {
                    if (
                      !data.row.basic_information ||
                      !data.row.basic_information2 ||
                      !data.row.address_information
                    ) {
                      this.$router.push("/regis");
                    }else if (
                      data.row.basic_information ||
                      data.row.basic_information2 ||
                      data.row.address_information
                    ) {
                      this.$router.push("/start");
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      !data.row.credit_report_signature
                    ) {
                      this.$router.push({
                        path: "/AuthorizationBC",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      !data.row.credit_report
                    ) {
                      this.$router.push({
                        path: "/calcular",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      data.row.credit_report &&
                      data.row.program_calculation &&
                      !data.row.contract &&
                      !data.row.active
                    ) {
                      this.$router.push({
                        path: "/start2",
                      });
                    } else if (
                      data.row.basic_information &&
                      data.row.basic_information2 &&
                      data.row.address_information &&
                      data.row.official_id &&
                      data.row.credit_report_signature &&
                      data.row.credit_report &&
                      data.row.program_calculation &&
                      data.row.contract &&
                      data.row.active
                    ) {
                      this.$router.push({
                        path: "/profile",
                      });
                    }
                    // } else {
                    //   let url = V.SERVER + V.OPS.GET_PROGRAM_CALCULATION;
                    //   this.$axios.get(url, session).then((response) => {
                    //     let data = response.data;
                    //     if (data[V.API.SUCCESS]) {
                    //       if (data.row.debts) {
                    //         // Si !(contrato && firma) pero sí cálculos guardados
                    //         this.$router.push("/start"); // A completar registro
                    //       } else {
                    //         this.$router.push("/calculator").catch((err) => {});
                    //       }
                    //     } else if (data.reason === 1) {
                    //       this.$router.push("/calculator").catch((err) => {});
                    //     }
                    //   });
                    // }
                  }
                });
              }
            } else {
              switch (res.data.reason) {
                case 7:
                  this.$notify({
                    text: "Contraseña Incorrecta, Intenta Nuevamente",
                    type: "error",
                  });
                  break;
                case 6:
                  this.$notify({
                    text: "Datos Incorrectos, Intenta Nuevamente",
                    type: "error",
                  });
                  break;
                default:
                  this.$notify({
                    text: "Algo salió mal, Intenta Nuevamente",
                    type: "error",
                  });
                  break;
              }
            }
          },
          (er) => {
            console.log(er);
          }
        );
    },
  },
};
</script>
<style>
@media (min-width: 1400px) {
  .tamaño {
    height: 50rem;
  }
  .tarjeta {
    margin-top: 4rem;
    border-radius: 10rem;
  }
}
</style>
