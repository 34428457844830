<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0" style="background-color: #092699">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container d-flex text-center">
          <div class="col px-0">
            <div class="row">
              <div class="col-lg-12">
                <h1 class="display-3 text-white">{{ title }}</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        class="section section-lg pt-lg-0 mt--300 subir justify-content-center"
        style="background-color: #092699"
      >
        <div class="container justify-content-center">
          <div class="row justify-content-center">
            <div class="col-lg-12 justify-content-center">
              <div class="row row-grid justify-content-center">
                <div v-for="info in infos" :key="info.id" class="col-lg-12">
                  <div class="jumbotron" @click="showModalInfo(info.id)">
                  <div class="container-image">
                    <img
                      :src="'https://www.dbmenos.com/img/postWebinar/' + info.og_picture"
                      alt="Imagen no Encontrada"
                      class="image"
                    />
                    <div class="overlay" >
                      <h1 class="text-black overlay-title" >{{ info.title }}</h1>
                      <h1 class="text-black overlay-description" >{{ info.description }}</h1>
                      <br>
                      <h1 class="text-black overlay-date">El día {{ formatDate(info.date) }} a las {{ formatHour(info.hour) }} con duración de {{ info.duration }}</h1>


                    <a class="btn btn-primary btn-lg" :href="info.link" role="button"
                      >Unirse</a
                    >
                    </div>
                  </div>
                  
                    <div align="right" style="margin-top:2rem">
                      <br>
                      <span class="h6">Creado el {{ formatDate(info.created) }}</span>
                    </div>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueResource from "vue-resource";
import { V } from "@/V";
import VueSignaturePad from "vue-signature-pad";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import moment, { max } from "moment";
Vue.use(VueFacebookPixel);
export default {
  metaInfo: {
    title: "DBMenos",
    titleTemplate: "%s | Video Conferencias",
  },

  components: {
    VueFacebookPixel,
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    nombre_banco: function () {
      return this.getBank(this.account.bank).name;
    },
  },
  created() {
    //Vue.analytics.fbq.init(V.FACEBOOK_PIXEL_ID, {})
    this.getBlogEntry();
  },
  mounted() {},
  data() {
    return {
      infos: [],
      title: "VIDEO CONFERENCIAS | DBMENOS",
      idB: "",
      titleB: "",
      descriptionB: "",
      fechaB: "",
      og_pictureB: "",
      postB: "",
      miniURL: "",
    };
  },
  methods: {
    formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");    
      },
    formatHour(hour) {
      return moment(hour, "H:m").format("LT");    
      },
    showModalInfo(idBlog) {
      this.idB = idBlog + "";
      console.log(this.idB)
      let query = null;
      if (this.idB > 0) {
        this.$router.push("/postWebinar" + "?id=" + this.idB);
      } else {
        this.$router.push({
          name: "postWebinars",
          query: query,
        });
      }
    },
    hideModalInfo() {
      this.$refs["my-modalInfo"].hide();
    },
    async getBlogEntry() {
      this.$showLoader(true);
      let url = V.SERVER + V.OPS.GET_BLOG_WEBINAR;
      let response = await this.$axios.get(url);
      this.$showLoader(false);
      let data = response.data;
      if (data.success) {
        this.infos = [];
        for (let i = 0; i < data.rows.length; i++) {
          this.infos.push(data.rows[i]);
          this.miniURL =
            "https://www.dbmenos.com/img/blog/" + data.rows[i].og_picture;
        }
        this.entry = data.row;
        this.$forceUpdate();
      }
    },
  },
};
</script>
<style>
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
iframe {
  display: table;
  margin: 0 auto;
}
img {
  max-width: 90%;
  height: auto;
}
p {
  padding: 0 20px;
}

.container-image {
  position: relative;
  width: auto;
  height: auto;
  text-align: center;
}
.overlay-title {
  font-size: 4rem;
}
.overlay-description {
  font-size: 2rem;
}
.overlay-date {
  font-size: 1.5rem;
}
.overlay {
  position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  padding: 6rem;
  text-align: center;
  border-radius:1rem;
}
@media screen and (max-width: 770px) {
  .overlay {
    position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 4rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 3rem;
}
.overlay-description {
  font-size: 1.5rem;
}
.overlay-date {
  font-size: 1rem;
}
}
@media screen and (max-width: 430px) {
  .overlay {
    position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 2rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 1.5rem;
}
.overlay-description {
  font-size: 1rem;
}
.overlay-date {
  font-size: 0.8rem;
}
}
@media screen and (max-width: 380px) {
  .overlay {
    position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 1rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 2rem;
}
.overlay-description {
  font-size: 1rem;
}
.overlay-date {
  font-size: 0.7rem;
}
}
@media screen and (max-width: 325px) {
  .overlay {
    position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 0.5rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 1.5rem;
}
.overlay-description {
  font-size: 0.8rem;
}
.overlay-date {
  font-size: 0.5rem;
}
}
.image {
  width: 100%;
  height: auto;
  text-align: center;
  filter: blur(3px);
}

.container-image .overlay {
  opacity: 1;
}
</style>
