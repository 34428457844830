import Vue from "vue"

import axios from 'axios'

import store from './store'

import App from "./App.vue"
import router from "./router"

import vuetify from "./plugins/vuetify"

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

import Argon from "./plugins/argon-kit"
import BootstrapVue from 'bootstrap-vue'
import es from 'vee-validate/dist/locale/es'
import VeeValidate, { Validator } from 'vee-validate'
import Vuelidate from "vuelidate"
import {V} from "./V"
import numeral from 'numeral'
import moment from 'moment'
import Notification from 'vue-notification'
import VueMeta from 'vue-meta'
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/messaging';
import VueSidebarMenuAkahon from "vue-sidebar-menu-akahon";

try{
  
const firebaseConfig = {
  apiKey: "AIzaSyD4-nLhA-OWMCBABY2qDql00vn3kUIGHks",
  authDomain: "dbmenosflutter.firebaseapp.com",
  databaseURL: "https://dbmenosflutter.firebaseio.com",
  projectId: "dbmenosflutter",
  storageBucket: "dbmenosflutter.appspot.com",
  messagingSenderId: "AAAAcfuwNKA:APA91bGKdGaoBDNB4VVs3h-u0GYBHLoP_WnWCDuwaL0AZEUplQVKm5m6I5OuZDvMU25qC3iqnkYWKih74Aie9DAxiRA9W-oW1dGXYWME0nxknhbetwxezt96-Td2GXSmw-ZSMoanJbdx",
  appId: "1:489553933472:web:2262b7ebb876ee828d45c5",
  measurementId: "G-R8EEY4T1VP"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const messaging = firebase.messaging();
messaging.usePublicVapidKey(
  "BOztnAGtPF6_55_nsZ6Ai6E-6IthhXfiVq5k5UwdgxIxghMn4G65ZJce14XNuKxzCEPjfuTb44PS-hix64Y_sGE"
);
}catch(e){
  console.log(e);
}

/*
const tokenDivId = 'token_div';
const permissionDivId = 'permission_div';

function resetUI() {
  showToken('loading...');
  // [START get_token]
  // Get Instance ID token. Initially this makes a network call, once retrieved
  // subsequent calls to getToken will return from cache.
  messaging.getToken().then((currentToken) => {
    if (currentToken) {
      sendTokenToServer(currentToken);
    } else {
      // Show permission request.
      console.log('No Instance ID token available. Request permission to generate one.');
      // Show permission UI.
      updateUIForPushPermissionRequired();
      setTokenSentToServer(false);
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    showToken('Error retrieving Instance ID token. ', err);
    setTokenSentToServer(false);
  });
  // [END get_token]
}

function showToken(currentToken) {
  // Show token in console and UI.
  const tokenElement = document.querySelector('#token');
  if (tokenElement) {
    tokenElement.textContent = currentToken;
  }
}
messaging
  .requestPermission()
  .then(function () {
    return messaging.getToken();
  })
  .then(function (token) {
    console.log(token);
  })
  .catch(function (err) {
    console.log("Error Occurred." + err);
  });
// Send the Instance ID token your application server, so that it can:
// - send messages back to this app
// - subscribe/unsubscribe the token from topics
function sendTokenToServer(currentToken) {
  if (!isTokenSentToServer()) {
    console.log('Sending token to server...');
    // TODO(developer): Send the current token to your server.
    setTokenSentToServer(true);
  } else {
    console.log('Token already sent to server so won\'t send it again ' +
      'unless it changes');
    console.log(token);
  }

}

function isTokenSentToServer() {
  return window.localStorage.getItem('sentToServer') === '1';
}

function setTokenSentToServer(sent) {
  window.localStorage.setItem('sentToServer', sent ? '1' : '0');
}

function requestPermission() {
  console.log('Requesting permission...');
  // [START request_permission]
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      // TODO(developer): Retrieve an Instance ID token for use with FCM.
      // [START_EXCLUDE]
      // In many cases once an app has been granted notification permission,
      // it should update its UI reflecting this.
      resetUI();
      // [END_EXCLUDE]
    } else {
      console.log('Unable to get permission to notify.');
    }
  });
  // [END request_permission]
}

function deleteToken() {
  // Delete Instance ID token.
  // [START delete_token]
  messaging.getToken().then((currentToken) => {
    messaging.deleteToken(currentToken).then(() => {
      console.log('Token deleted.');
      setTokenSentToServer(false);
      // [START_EXCLUDE]
      // Once token is deleted update UI.
      resetUI();
      // [END_EXCLUDE]
    }).catch((err) => {
      console.log('Unable to delete token. ', err);
    });
    // [END delete_token]
  }).catch((err) => {
    console.log('Error retrieving Instance ID token. ', err);
    showToken('Error retrieving Instance ID token. ', err);
  });

}

// Add a message to the messages element.
function appendMessage(payload) {
  const messagesElement = document.querySelector('#messages');
  const dataHeaderELement = document.createElement('h5');
  const dataElement = document.createElement('pre');
  dataElement.style = 'overflow-x:hidden;';
  dataHeaderELement.textContent = 'Received message:';
  dataElement.textContent = JSON.stringify(payload, null, 2);
  messagesElement.appendChild(dataHeaderELement);
  messagesElement.appendChild(dataElement);
}


resetUI();

*/
const instance = axios.create({
  baseURL: V.SERVER
})

instance.interceptors.request.use(function (config) {
  let session = localStorage.getItem(V.STORE_KEYS.SESSION)
  if (session) {
    config.headers.common.session = session
    config.headers.common['device'] = 'web'
  }

  return config
}, function (error) {
  console.log(error)
})

Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(Argon)

Vue.prototype.$axios = axios
Vue.prototype.$showLoader = showLoader
Vue.prototype.$moment = moment
Vue.prototype.$validateSession = validateSession
Vue.prototype.$formatDate = formatDate
Vue.prototype.$formatCurrency = formatCurrency
Vue.prototype.$getLS = getLS
Vue.prototype.$setLS = setLS
Vue.prototype.$v = V

Vue.use(Notification)
Vue.use(VeeValidate)
Vue.use(Vuelidate)
Validator.localize('es', es)
Vue.use(VueMeta)
Vue.component('vue-sidebar-menu-akahon', VueSidebarMenuAkahon);


Vue.prototype.$axios = instance

new Vue({
  router,
  render: h => h(App),
  store: store,
  vuetify,
  async beforeCreate() {
    await this.$store.dispatch('downloadBanks')
    await this.$store.dispatch('downloadEconomicActivities')
  }
}).$mount("#app")

function setLS(key, json) {
  localStorage.setItem(key, JSON.stringify(json))
}

function getLS(key) {
  let item = localStorage.getItem(key)
  if (item) {
    try {
      return JSON.parse(item)
    } catch (e) {
      return item
    }
  } else if (key === V.STORE_KEYS.SESSION) {
    router.push("/login")
  }
  return null
}

function showLoader(show) {
  this.$store.commit('setShowLoader', show)
}

function validateSession(json) {
  if (!json['success']) {
    if (json['reason'] === 7) {
      // Session expired
      router.push("/login")
    }
  }
}

function formatDate(date) {
  return moment(date).format("DD MMM, YYYY")
}

function formatCurrency(num) {
  return numeral(num).format('$ 0,0.00')
}