<template>

  <div class="bg-principal">

    <!--
    <div class="row col-12 col-sm-10 mx-auto pl-5 mt-5">
      <div class="col-12">
        <img :src="logo" alt="logo" class="ml-4 img-logo">
      </div>
    </div>
    -->

    <div class="row col-12 col-sm-10 mx-auto">
      <div class="col-12 col-sm-3">
        <p class="category">Descarga la app</p>
        <div class="col-12" v-for="(item,index) of apps">
          <a :href="item.url" target="_blank">
            <img :src="item.image" :alt="index" width="80%">
          </a>
        </div>
      </div>
      <div class="col-12 col-sm-3">
        <p class="category">Recursos</p>
        <ul>
          <li v-for="(item,index) of resources" :key="index" class="my-3">
            <a :href="item.url" target="_blank" class="item">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-sm-3">
        <p class="category">Nosotros</p>
        <ul>
          <li v-for="(item,index) of our" :key="index" class="my-3">
            <a :href="item.url" target="_blank" class="item">{{ item.name }}</a>
          </li>
        </ul>
      </div>
      <div class="col-12 col-sm-3">
        <p class="category">Síguenos</p>

        <div class="row px-5">
          <div class="col-2" v-for="(item,index) of follow">
            <a :href="item.url" target="_blank" :key="index">
              <i :class="item.icon"></i>
            </a>
          </div>
        </div>
      </div>

      <hr>

      <p class="simple-text mx-auto">
        Av. Homero 407, Polanco, Piso 11, Miguel Hidalgo, C.P. 11550, CDMX, México. © <span
          class="simple-text-bold">{{ year }} DBMenos</span>
      </p>

    </div>


  </div>

</template>

<script>
export default {
  name: "FooterTemplate",
  data: () => ({

    year: new Date().getFullYear(),

    logo: '/img/HomepageNew/Desktop/Logotipo azul para fondo blanco.png',

    apps:
        [
          {
            image: '/img/HomepageNew/Desktop/Google Play logo.svg',
            url: 'https://play.google.com/store/apps/details?id=com.dbmenos.dbm_android_client&hl=es'
          },
          {
            image: '/img/HomepageNew/Desktop/App Store Logo.svg',
            url: 'https://apps.apple.com/mx/app/db-menos/id1503253844?l=en'
          },
        ],
    resources:
        [
          {name: 'Blog', url: 'https://www.dbmenos.com/blogs'},
          {name: 'Podcast', url: 'https://open.spotify.com/show/4usDD9kLizbU3LUq2IYbyN?si=02befb03d2f646ef&nd=1'},
          {
            name: 'Qué es es una reparadora de crédito',
            url: 'https://dbmenos.com/como-funciona-una-reparadora-de-credito'
          },
        ],
    our:
        [
          {
            name: 'Contáctanos',
            url: 'https://api.whatsapp.com/send/?phone=%2B5215544401008&text=Hola%21+me+interesa+informaci%C3%B3n+de+DB+Menos&app_absent=0'
          },
          {name: 'Legal', url: 'https://dbmenos.com/legal'},
          {name: 'Preguntas frecuentes', url: 'https://dbmenos.com/questions'},
          {name: 'Simula tu plan', url: 'https://dbmenos.com/simula-tu-plan-de-ahorro'},
        ],
    follow:
        [
          {icon: 'fa fa-facebook fa-lg', url: 'https://www.facebook.com/grupodbmenos/'},
          {icon: 'fa fa-instagram', url: 'https://www.instagram.com/db_menos/'},
          {icon: 'fa fa-youtube-play', url: 'https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw'},
          {icon: 'fa fa-spotify', url: 'https://open.spotify.com/show/4usDD9kLizbU3LUq2IYbyN?si=02befb03d2f646ef&nd=1'},
          {icon: 'fa fa-linkedin', url: 'https://www.linkedin.com/company/grupo-db-menos/'},
        ]
  }),
}
</script>

<style scoped>

.bg-principal {
  padding-top: 3rem;
  background-color: #f2f2f2;
}

.category {
  font-family: OpenSans-Bold;
}

.item {
  font-family: OpenSans-Regular;
  color: #000000;
}

.simple-text {
  font-family: OpenSans-Regular;
}

.simple-text-bold {
  font-family: OpenSans-Bold;
}

ul {
  list-style: none;
  font-weight: normal;
}

i {
  font-weight: normal;
  color: #000000;
}

.img-logo {
  width: 8rem;
}

@media (min-width: 1024px) and (max-width: 1439px) {

  .img-logo {
    width: 10rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .category {
    font-size: 1.5rem;
  }

  .item {
    font-size: 1.2rem;
  }

  .fa {
    font-size: 1.5rem;
  }

  .img-logo {
    width: 10rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .category {
    font-size: 2rem;
  }

  .item {
    font-size: 1.8rem;
  }

  .fa {
    font-size: 2rem;
  }

  .img-logo {
    width: 20rem;
  }
}

</style>