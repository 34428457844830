<template>

  <div class="bg-principal">

    <div class="row title-section">
      <div class="col-12 col-sm-10 mx-auto">
        <p class="font-weight-bold text-center text-md-left f-title mb-0">¿Cómo le hacemos?</p>
        <p class="primary-text mt-0">
          A través de un proceso digital de registro, ya sea en nuestra <span class="font-weight-bold">página web</span> o
          mediante un Asistente Virtual Inteligente en <span class="font-weight-bold">WhatsApp.</span>
        </p>
      </div>
    </div>

    <div class=" text-center band-section band">
      <img src="/img/HomepageNew/Mobile/Laptop.png" alt="laptop" class="img-lap img-band">
      <img src="/img/HomepageNew/Mobile/Smartphone.png" alt="smart-phone" class="img-phone img-band">
    </div>

    <div class="row col-12 col-sm-10 mx-auto list-section">

      <div class="col-12 col-sm-7 p-0">
        <ol>
          <li class="secondary-text" v-for="item of bullets">
            {{ item.text }}
          </li>
        </ol>
      </div>

      <div class="col-12 col-sm-5 text-center">
        <p class="secondary-text text-left">Soluciona tus deudas en 5 minutos</p>
        <div class="col-8 col-sm-6 mx-auto">
          <v-btn
              depressed
              color="#2a3d99"
              elevation="2"
              x-large
              dark
              v-on:click="openWhatsApp"
          >
            <img src="/img/HomepageNew/Mobile/WhatsApp logo.svg" alt="whatsapp" width="8%" class="mr-3">
            Pruébame
          </v-btn>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "HowDoWeDoIt",
  data: () =>
      ({
        bullets:
            [
              {id: 1, text: "Creamos un plan de ahorro acorde a tu situación."},
              {id: 2, text: "Negociamos hasta obtener el mejor descuento."},
              {id: 3, text: "Te ayudamos a reincorporarte al sistema financiero."}
            ]
      }),
  methods:
      {
        openWhatsApp() {
          window.open("https://api.whatsapp.com/send/?phone=%2B5215544401008&text=Hola%21+me+interesa+informaci%C3%B3n+de+DB+Menos&app_absent=0")
        },
      }
}
</script>

<style scoped>

.bg-principal {
  background-color: #FFFFFF;

}

.f-title {
  font-family: OpenSans-Bold;
  color: #2a3d9a;
}

.primary-text {
  font-family: OpenSans-Regular;
  color: #525f7f;
}

.secondary-text {
  font-family: OpenSans-Regular;
  font-weight: normal;
  color: #525f7f;
}

.band {
  background: linear-gradient(90deg, rgba(42, 61, 153, 1) 0%, rgba(41, 170, 225, 1) 100%);
  z-index: -1;
}

@media (min-width: 320px) and (max-width: 374px) {

  .title-section {
    margin-bottom: 2rem;
  }

  .list-section {
    margin-top: 4rem;
  }

  .f-title {
    font-size: 1.4rem;
  }

  .primary-text {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text {
    font-size: 1rem;
  }

  .band {
    height: 5rem;
  }

  .img-band {
    position: relative;
    top: -20px;
  }

  .img-lap {
    width: 12rem;
  }

  .img-phone {
    width: 7rem;
  }

}

@media (min-width: 375px) and (max-width: 424px) {

  .title-section {
    margin-bottom: 2rem;
  }

  .list-section {
    margin-top: 4rem;
  }

  .f-title {
    font-size: 1.4rem;
  }

  .primary-text {
    font-size: 1rem;
  }

  .secondary-text {
    font-size: 1rem;
  }

  .band {
    height: 5.5rem;
  }

  .img-band {
    position: relative;
    top: -20px;
  }

  .img-lap {
    width: 14rem;
  }

  .img-phone {
    width: 9rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .title-section {
    margin-bottom: 2rem;
  }

  .list-section {
    margin-top: 4rem;
  }

  .f-title {
    font-size: 1.4rem;
  }

  .primary-text {
    font-size: 1rem;
  }

  .secondary-text {
    font-size: 1rem;
  }

  .band {
    height: 5.7rem;
  }

  .img-band {
    position: relative;
    top: -22px;
  }

  .img-lap {
    width: 15rem;
  }

  .img-phone {
    width: 10rem;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {

  .title-section {
    margin-bottom: 2rem;
  }

  .list-section {
    margin-top: 4rem;
  }

  .f-title {
    font-size: 1.4rem;
  }

  .primary-text {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text {
    font-size: 1rem;
  }


  .band {
    height: 5.7rem;
  }

  .img-band {
    position: relative;
    top: -3.2rem;
  }

  .img-lap {
    width: 18rem;
  }

  .img-phone {
    width: 13rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  .title-section {
    margin-bottom: 3.5rem;
  }

  .list-section {
    margin-top: 8rem;
  }

  .f-title {
    font-size: 2rem;
  }

  .primary-text {
    font-size: 1.7rem;
  }

  .secondary-text {
    font-size: 1.5rem;
  }

  .band {
    height: 7rem;
  }

  .img-band {
    position: relative;
    top: -5rem;
  }

  .img-lap {
    width: 30rem;
  }

  .img-phone {
    width: 20rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  .title-section {
    margin-bottom: 3.5rem;
  }

  .list-section {
    margin-top: 9rem;
  }

  .f-title {
    font-size: 2rem;
  }

  .primary-text {
    font-size: 1.7rem;
  }

  .secondary-text {
    font-size: 1.5rem;
  }

  .band {
    height: 7rem;
  }

  .img-band {
    position: relative;
    top: -5rem;
  }

  .img-lap {
    width: 30rem;
  }

  .img-phone {
    width: 20rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .title-section {
    margin-bottom: 3.5rem;
  }

  .list-section {
    margin-top: 9rem;
  }

  .f-title {
    font-size: 3rem;
  }

  .primary-text {
    font-size: 2.7rem;
  }

  .secondary-text {
    font-size: 2.5rem;
  }

  .band {
    height: 12rem;
  }

  .img-band {
    position: relative;
    top: -5rem;
  }

  .img-lap {
    width: 40rem;
  }

  .img-phone {
    width: 25rem;
  }

}

</style>