<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0" style="background-image: url('https://cdn.pixabay.com/photo/2015/08/10/14/21/graphics-882726_960_720.png')">
          <div class="shape shape-style-1 shape-default shape-skew">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <span></span>
          </div>
          <div class="container shape-container" style="padding-top:100px;padding-bottom:25px;" >
              <object data="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf" type="application/pdf" width="100%" height="700"></object>
          </div>
      </section>
    </div>
   
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
  components: {
    Modal
  },
  data() {
    return {
      modals: {
        modal1: false,
        modal2: false,
        modal3: false
      }
    };
  }
};
</script>
<style>
</style>
