<template>

  <div class="bg-principal d-flex align-items-center">

    <div class="row col-12 col-sm-10 mx-auto">

      <div class="col-12 col-lg-6 order-2 order-sm-1">

        <div class="row">
          <div class="col-12">
            <p class="primary-text">Por el momento no podemos ayudarte</p>
            <p class="text-justify secondary-text">
              Esto es porque no trabajamos con las <br>
              instituciones a las que debes. Si crees que hay <br>
              algún error puedes contactar a un asesor.
            </p>

            <!--
            <v-btn
                dark
                elevation="2"
                color="#2A3D99"
                class="ml-5 btn-action"
                v-on:click="adviser"
            ><span class="text-btn">Contactar a un asesor</span>
            </v-btn>-->
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <p class="text-justify secondary-text">
              También te invitamos a conocer nuestro <br>
              programa de referidos.
            </p>
            <v-btn
                dark
                elevation="2"
                color="#8ac43f"
                class="ml-5 btn-action"
                v-on:click="friend"
            ><span class="text-btn">Refiere a un amigo y gana $800</span>
            </v-btn>
          </div>
        </div>

      </div>

      <div class="col-12 col-lg-6 d-flex align-items-center flex-row-reverse flex-md-row order-1 order-sm-2">
        <img src="/img/new_process/desktop/space_marine.png" alt="astronaut">
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Error",
  methods:
      {
        adviser()
        {
          window.open("https://api.whatsapp.com/send/?phone=525544401008&text&app_absent=0")
        },
        friend() {
          window.open("https://mate.dbmenos.com/#/login")
        }
      }
}
</script>

<style scoped>

.bg-principal
{
  min-height: 100vh;
}

.primary-text {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.btn-action
{
  text-transform: none
}

@media (min-width: 320px) and (max-width: 374px) {

  .bg-principal {
    margin-top: 5rem
  }

  .primary-text {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text {
    font-size: .9rem;
    text-align: center;
  }

  .secondary-text br
  {
    display: none;
  }

  .btn-action {
    width: 90%;
  }

  .text-btn {
    font-size: .6rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .bg-principal {
    margin-top: 5rem
  }

  .primary-text {
    font-size: 1rem;
    text-align: left;
  }

  .secondary-text {
    font-size: .9rem;
    text-align: center;
  }

  .secondary-text br
  {
    display: none;
  }

  .btn-action {
    width: 90%;
  }

  .text-btn {
    font-size: .7rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .bg-principal {
    margin-top: 5rem
  }

  .primary-text {
    font-size: 1rem;
    text-align: left;
  }

  .secondary-text {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text br
  {
    display: none;
  }

  .btn-action {
    width: 90%;
  }

  .text-btn {
    font-size: .7rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .bg-principal {
    margin-top: 5rem
  }

  .primary-text {
    font-size: 1rem;
    text-align: left;
  }

  .secondary-text {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text br
  {
    display: none;
  }

  .btn-action {
    width: 50%;
  }

  .text-btn {
    font-size: .7rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  .bg-principal {
    margin-top: 6rem
  }

  .primary-text {
    font-size: 1.2rem;
    text-align: left;
  }

  .secondary-text {
    font-size: 1rem;
    text-align: center;
  }

  .btn-action {
    width: 60%;
  }

  .text-btn {
    font-size: .7rem;
  }

}

@media (min-width: 1440px) and (max-width: 2559px) {

  .bg-principal {
    margin-top: 6rem
  }

  .primary-text {
    font-size: 2rem;
    text-align: left;
  }

  .secondary-text {
    font-size: 1.5rem;
    text-align: center;
  }

  .btn-action {
    width: 60%;
  }

  .text-btn {
    font-size: 1rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .bg-principal {
    margin-top: 7rem
  }

  .primary-text {
    font-size: 2.5rem;
    text-align: left;
  }

  .secondary-text {
    font-size: 2rem;
    text-align: center;
  }

  .btn-action {
    width: 50%;
  }

  .text-btn {
    font-size: 1.5rem;
  }
}

</style>