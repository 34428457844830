import Vue from 'vue'
import Vuex from 'vuex'
import {
    V
} from './V'

import axios from "axios";
import id from "bootstrap-vue/src/mixins/id";
/*const instance = axios.create({
  baseURL: V.SERVER
});*/

axios.interceptors.request.use(config => {
    let session = localStorage.getItem(V.STORE_KEYS.SESSION)
    if (session) {
        config.headers.common[V.STORE_KEYS.SESSION] = session
        config.headers.common['device'] = 'web'
    }
    return config
}, error => {
    console.log(error)
})

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        axios: axios,
        user: null,
        profile: null,
        marketing: null,
        userFiles: [],
        accounts: [],
        payments: [],
        showLoader: false,
        banks: [],
        economicActivities: [],
        relationBeneficiary: [],
        mxStates: [],
        device: null,
        video: {
            play: true,
            version: 1
        },
        program_debts: []
    },
    mutations: {
        addDebts(state, debts) {
            state.program_debts = debts
        },
        changeStatusVideo(state, settings) {
            state.video.play = settings.play;
            state.video.version = settings.version;
        },
        setUser(state, value) {
            state.user = value
        },
        setProfile(state, value) {
            state.profile = value
        },
        setMarketing(state, value) {
            state.marketing = value
        },
        setUserFiles(state, value) {
            state.userFiles = value
        },
        setAccounts(state, value) {
            state.accounts = value
        },
        setPayments(state, value) {
            state.payments = value
        },
        setBanks(state, value) {
            state.banks = value
        },
        setEconomicActivities(state, value) {
            state.economicActivities = value
        },
        setRelationBeneficiary(state, value) {
            state.relationBeneficiary = value
        },
        setMxStates(state, value) {
            state.mxStates = value
        },
        setShowLoader(state, value) {
            state.showLoader = value
        },
    },
    getters: {
        getBanks(state) {
            return state.banks
        },
        getRelationBeneficiary(state) {
            return state.relationBeneficiary
        },
        getEconomicActivities(state) {
            return state.economicActivities
        },
        getMxStates(state) {
            return state.mxStates
        },
        getShowLoader(state) {
            return state.showLoader
        },
    },
    actions: {
        addDebts({commit}, debts) {
            commit('addDebts', debts)
        },
        changeStatusVideo({commit}, settings) {
            commit('changeStatusVideo', settings)
        },
        loadUser({
                     commit
                 }) {
            let user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER))
            if (user) {
                commit('setUser', user)
            }
        },
        showLoader({
                       commit
                   }) {
            commit('setShowLoader', true)
        },
        hideLoader({
                       commit
                   }) {
            commit('setShowLoader', false)
        },
        async downloadBanks({
                                commit
                            }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setBanks', response.data[V.API.ROWS])
                } else {
                    console.log("error d_banks")
                }
            })
        },
        async downloadEconomicActivities({
                                             commit
                                         }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.ECONOMIC_ACTIVITIES
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setEconomicActivities', response.data[V.API.ROWS])
                } else {
                    console.log("error d_economicActivities")
                }
            })
        },
        async downloadRelationBeneficiary({
                                              commit
                                          }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.RELATION_BENEFICIARY
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setRelationBeneficiary', response.data[V.API.ROWS])
                } else {
                    console.log("error d_relationBeneficiary")
                }
            })
        },
        async downloadMxStates({
                                   commit
                               }) {
            let url = V.SERVER + V.OPS.GET_MX_STATES
            axios.get(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setMxStates', response.data[V.API.ROWS])
                } else {
                    console.log("error d_MxStates")
                }
            })
        },
        async getProfile({
                             commit
                         }) {
            let url = V.SERVER + V.OPS.SELECT + V.VIEWS.PROFILE
            axios.get(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setProfile', response.data[V.API.ROW])
                } else {
                    console.log("error d_UserProfile")
                }
            })
        },
        async getUserFiles({
                               commit
                           }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.USER_FILES
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setUserFiles', response.data[V.API.ROW])
                } else {
                    console.log("error d_userFiles")
                }
            })
        },
        async getAccounts({
                              commit
                          }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.ACCOUNTS
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setAccounts', response.data[V.API.ROWS])
                    //                    console.log(response.data[V.API.ROWS])
                } else {
                    console.log("error d_accounts")
                }
            })
        },
        async getPayments({
                              commit
                          }) {
            let url = V.SERVER + V.OPS.SELECT + V.TABLES.PAYMENTS
            axios.post(url).then(response => {
                if (response.data[V.API.SUCCESS]) {
                    commit('setPayments', response.data[V.API.ROWS])
                } else {
                    console.log("error d_accounts")
                }
            })
        },
    }
})

export default store