<template>

  <div class="bg-principal text-center py-5">

    <p class="primary-text text-blue">
      Sube tu INE para consultar tu buró de crédito <br>
      y calcular con exactitud tu descuento.
    </p>

    <p class="secondary-text text-gray">
      Revisamos tu buró para buscar las cuentas con las que podemos <br>
      ayudarte a liquidar y con ello, ofrecerte un plan con descuento.
    </p>

    <div>
      <div class="row col-12 col-sm-8 mx-auto">
        <div class="col-12 col-lg-6">
          <dropbox
              text
              :ocrFile="true"
              type="ID"
              :files="this.userFiles"
              v-on:uploadFile="uploadFileFrente"
              v-on:resentUploadFile="uploadFileFrente"
          ></dropbox>
        </div>
        <div class="col-12 col-lg-6">
          <dropbox
              text
              :ocrFile="true"
              type="ID_B"
              :files="this.userFiles"
              v-on:uploadFile="uploadFileTrasera"
              v-on:resentUploadFile="uploadFileTrasera"
          ></dropbox>
        </div>
      </div>
    </div>

    <video-modal/>

    <v-btn
        class="btn-text"
        color="#2a3d99">
      <span class="text-white text-transform">Siguiente</span>
    </v-btn>

  </div>

</template>

<script>

import {V} from "@/V";
import Dropbox from "@/components/Dropbox";
import firebase from "firebase";
import Vue from "vue";
import VideoModal from "@/views/components/simulator/VideoModal";

import {mapState, mapActions} from 'vuex'

export default {
  name: "UploadIneFile",
  components: {VideoModal, Dropbox},
  mounted() {
    this.changeStatusVideo({play: false, version: 0})
  },
  created() {
    this.discountdoc = JSON.parse(localStorage.getItem("discountdoc"));
    this.phone = JSON.parse(localStorage.getItem("phone"));
    console.log(this.phone)
    this.from = JSON.parse(localStorage.getItem("Marketing"));
    this.cal = JSON.parse(localStorage.getItem("calcData"));
    this.dataCal = JSON.parse(localStorage.getItem("calculate"));
    firebase.analytics().logEvent("lead_complete_2");
    fbq("track", "lead_complete_2");
    gtag("event", "lead_complete_2", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });
    //document.location.reload();
    fbq("track", "starting_lead_automated");
    firebase.analytics().logEvent("starting_lead_automated");
    gtag("event", "starting_lead_automated", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });
  },
  data() {
    return {
      dataCal: null,
      userFiles: [],
      lore: "Register",
      registerOrNot: false,
      a: true,
      b: false,
      login: {
        phone: "",
        willLogin: false,
        code: "",
      },
      signup: {
        name: "",
        lastname_1: "",
        lastname_2: "",
        email: "",
        phone: "",
        password: "",
        password_val: "",
        device: "",
      },
      V: V,
      leadInfo: {
        phone: "",
        code: "",
        name: "",
        lastname_1: "",
        lastname_2: "",
        email: "",
        device: "",
      },
      leadValidationCode: "",
      leadSent: false,
      loginSent: false,
      leadDat: false,
      from: "",
      cal: "",
      request_status: "",
      discountdoc: "",
      new_user: false,
      device: "",
      regEmail:
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regPhone: /[0-9]{10}?/,
      phone: {
        phone: "",
        code: "",
      },
    };
  },
  computed: {
    isEmailValid: function () {
      return !this.signup.email
          ? ""
          : this.regEmail.test(this.signup.email)
              ? "has-success"
              : "has-danger";
    },
    isPhoneValid: function () {
      return !this.signup.phone
          ? ""
          : this.regPhone.test(this.signup.phone)
              ? "has-success"
              : "has-danger";
    },
    getMarketing() {
      return this.$store.state.marketing;
    },
  },
  methods: {

    ...mapActions(['changeStatusVideo']),

    async uploadFileFrente(obj) {
      let file = obj.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$showLoader(true);
      let body = {
        phone: this.phone.phone ? this.phone.phone : this.phone.login,
        filename: obj.file.name,
      };
      await this.$axios
          .post(V.SERVER + V.PREREGISTER.UPLOAD_FILE, body)
          .then((response) => {
            let data = response.data;
            if (response.data.success) {
              let fileURL = data[V.API.ROW].url;
              this.$axios.put(fileURL, file).then((putObjectResponse) => {
                if (putObjectResponse.status === 200) {
                  this.$notify({
                    text: "INE Cargada Correctamente",
                    type: "success",
                  });
                  this.$axios
                      .post(V.SERVER + V.PREREGISTER.PREUSER_OCR, body)
                      .then(async (response) => {
                        this.$showLoader(false);
                        if (response.data.success) {
                          let user = response.data.row;
                          let session = user.session;
                          //localStorage.removeItem("phone");
                          delete user.session;
                          this.$store.commit("setUser", user);
                          localStorage.setItem(
                              this.$v.STORE_KEYS.SESSION,
                              session.session
                          );
                          localStorage.setItem(
                              this.$v.STORE_KEYS.USER,
                              JSON.stringify(user)
                          );
                          this.$axios.defaults.headers.common[
                              this.$v.STORE_KEYS.SESSION
                              ] = session[this.$v.STORE_KEYS.SESSION];
                          this.$router.push({
                            path: "/start",
                          });
                        } else {
                          this.$showLoader(false);
                          this.$notify({
                            text: "Error, intenta nuevamente",
                            type: "warn",
                          });
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        this.$showLoader(false);
                        //alert("No se logró subir, intente nuevamente");
                      });
                } else {
                  this.$notify({
                    text: "No se logró subir la INE",
                    type: "warn",
                  });
                }
              });
            } else {
              this.$notify({
                text: "Error, intenta nuevamente",
                type: "warn",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.$showLoader(false);
            //alert("No se logró subir, intente nuevamente");
          });
    },
    async uploadFileTrasera(obj) {
      let file = obj.file;
      let formData = new FormData();
      formData.append("file", file);
      this.$showLoader(true);
      let body = {
        phone: this.client.phone,
        filename: obj.file.name,
      };
      await this.$axios
          .post(V.SERVER + V.PREREGISTER.UPLOAD_FILE, body)
          .then((response) => {
            let data = response.data;
            if (response.data.success) {
              let fileURL = data[V.API.ROW].url;
              this.$axios.put(fileURL, file).then((putObjectResponse) => {
                if (putObjectResponse.status === 200) {
                  this.$notify({
                    text: "INE Cargada Correctamente",
                    type: "success",
                  });
                  this.$axios
                      .post(V.SERVER + V.PREREGISTER.PREUSER_OCR_IDB, body)
                      .then(async (response) => {
                        this.$showLoader(false);
                        if (response.data.success) {
                          let user = response.data.row;
                          console.log(user)
                          this.$router.push({
                            path: "/start2",
                          });
                        } else {
                          this.$showLoader(false);
                          this.$notify({
                            text: "Error, intenta nuevamente",
                            type: "warn",
                          });
                        }
                      })
                      .catch((e) => {
                        console.log(e);
                        this.$showLoader(false);
                        //alert("No se logró subir, intente nuevamente");
                      });
                } else {
                  this.$notify({
                    text: "No se logró subir la INE",
                    type: "warn",
                  });
                }
              });
            } else {
              this.$notify({
                text: "Error, intenta nuevamente",
                type: "warn",
              });
            }
          })
          .catch((e) => {
            console.log(e);
            this.$showLoader(false);
            //alert("No se logró subir, intente nuevamente");
          });
    },
    cambiarComponentRegister() {
      this.lore = "Register";
      this.a = true;
      this.b = false;
      this.comienzo = false;
    },
    cambiarComponentLogin() {
      this.lore = "Login";
      this.b = true;
      this.a = false;
      this.comienzo = false;
    },
    saveCalculation() {
      let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATION;
      let json = {
        calculation: JSON.parse(localStorage.getItem("discountdoc")),
      };
      this.$axios.post(url, json).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.$router.push({
            path: "/start",
          });
        }
      });
    },
    m_hasValidEmail() {
      let regex = new RegExp(
          "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|es|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b"
      );
      return regex.test(this.leadInfo.email);
    },
    m_hasValidPhone() {
      return (
          !isNaN(this.leadInfo.phone) &&
          this.leadInfo.phone.length > 9 &&
          this.leadInfo.phone.length < 11
      );
    },
    m_register() {
      if (this.leadInfo.name.length === 0) {
        this.request_status = "Necesitas ingresar tu nombre";
      } else if (this.leadInfo.lastname_1.length === 0) {
        this.request_status = "Necesitas ingresar un primer apellido";
      } else if (this.leadInfo.lastname_2.length === 0) {
        this.request_status = "Necesitas ingresar un segundo apellido";
      } else if (this.leadInfo.email.length === 0) {
        this.request_status = "Necesitas ingresar un correo electrónico";
      } else if (this.leadValidationCode.length > 6) {
        this.request_status = "El código solamente debe contener 5 digítos";
      } else {
        this.request_status = "";
        this.$showLoader(true);
        var _data = {
          phone: this.phone.phone,
          code: this.phone.code,
          name: this.leadInfo.name,
          lastname_1: this.leadInfo.lastname_1,
          lastname_2: this.leadInfo.lastname_2,
          email: this.leadInfo.email,
        };
        let url = V.SERVER + V.OPS.REGISTER;

        this.$http.post(url, _data).then(
            (res) => {
              this.$showLoader(false);
              if (res.data.success == true) {
                let user = res.data.row;
                let session = user.session;
                this.$alert("Se registro Correctamente");
                localStorage.clear("phone");
                delete user.session;
                this.$store.commit("setUser", user);
                localStorage.setItem(this.$v.STORE_KEYS.SESSION, session.session);
                localStorage.setItem(
                    this.$v.STORE_KEYS.USER,
                    JSON.stringify(user)
                );
                this.$axios.defaults.headers.common[this.$v.STORE_KEYS.SESSION] =
                    session[this.$v.STORE_KEYS.SESSION];
                this.$axios.defaults.headers.common["device"] = "web";
                this.login.phone = this.leadInfo.phone;
                this.login.code = this.leadInfo.code;
                this.new_user = true;

                this.$root.$emit("refreshHeader");
                Vue.analytics.fbq.event("RegisterComplete", {
                  content_name:
                      this.leadInfo.name +
                      " " +
                      this.leadInfo.phone +
                      " registration successful",
                });
                firebase.analytics().logEvent("register_complete");
                gtag("event", "register_complete", {
                  send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
                });
                fbq("track", "register_complete");

                if (this.new_user) {
                  if (localStorage.getItem("discountdoc")) this.saveCalculation();
                  else this.$router.push("calculator").catch((err) => {
                  });
                } else if (user.contract) {
                  // Si tiene contrato
                  this.$router.push("profile"); // A perfil
                } else {
                  let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
                  this.$axios.get(url, session).then((response) => {
                    let data = response.data;
                    if (data[V.API.SUCCESS]) {
                      if (
                          !data.row.basic_information ||
                          !data.row.basic_information2 ||
                          !data.row.address_information ||
                          !data.row.official_id
                      ) {
                        this.$router.push("/start");
                      } else if (
                          data.row.basic_information &&
                          data.row.basic_information2 &&
                          data.row.address_information &&
                          data.row.official_id &&
                          !data.row.credit_report_signature
                      ) {
                        this.$router.push({
                          path: "/AuthorizationBC",
                        });
                      } else if (
                          data.row.basic_information &&
                          data.row.basic_information2 &&
                          data.row.address_information &&
                          data.row.official_id &&
                          data.row.credit_report_signature &&
                          !data.row.credit_report &&
                          !data.row.program_calculation
                      ) {
                        this.$router.push({
                          path: "/calcular",
                        });
                      } else if (
                          data.row.basic_information &&
                          data.row.basic_information2 &&
                          data.row.address_information &&
                          data.row.official_id &&
                          data.row.credit_report_signature &&
                          data.row.credit_report &&
                          data.row.program_calculation &&
                          !data.row.contract
                      ) {
                        this.$router.push({
                          path: "/start2",
                        });
                      } else if (
                          data.row.basic_information &&
                          data.row.basic_information2 &&
                          data.row.address_information &&
                          data.row.official_id &&
                          data.row.credit_report_signature &&
                          data.row.credit_report &&
                          data.row.program_calculation &&
                          data.row.contract &&
                          data.row.active
                      ) {
                        this.$router.push({
                          path: "/profile",
                        });
                      } else {
                        let url = V.SERVER + V.OPS.GET_PROGRAM_CALCULATION;
                        this.$axios.get(url, session).then((response) => {
                          let data = response.data;
                          if (data[V.API.SUCCESS]) {
                            if (data.row.debts) {
                              // Si !(contrato && firma) pero sí cálculos guardados
                              this.$router.push("/start"); // A completar registro
                            } else {
                              this.$router.push("/calculator").catch((err) => {
                              });
                            }
                          } else if (data.reason === 1) {
                            this.$router.push("/calculator").catch((err) => {
                            });
                          }
                        });
                      }
                    }
                  });
                }
              } else {
                switch (res.data.reason) {
                  case 7:
                    this.request_status = "Datos inválidos, intenta nuevamente";
                    break;
                  case 6:
                    this.request_status = "Datos incorrectos, intenta nuevamente";
                    break;
                  default:
                    this.request_status = "Algo salió mal, intenta nuevamente";

                    break;
                }
              }
            },
            (er) => {
              console.log(er);
            }
        );
      }
    },
    m_insertLead() {
      if (this.leadSent) {
        this.$showLoader(true);
        var _data = {
          phone: this.leadInfo.phone,
          code: this.leadValidationCode,
        };
        gtag("event", "conversion", {
          send_to: "AW-718930865/eqePCI6_sacBELGH6NYC",
          event_callback: null,
        });

        let url = V.SERVER + V.OPS.VALIDATE_PREREGISTER;

        this.$http.post(url, _data).then(
            (res) => {
              this.$showLoader(false);
              if (res.data.success === true) {
                this.$alert("Código Correcto");
                this.leadDat = true;
              } else {
                this.$alert("Código inválido, intenta nuevamente");
              }
            },
            (er) => {
              console.log(er);
            }
        );
      } else {
        this.$showLoader(true);
        var _data = {
          phone: this.leadInfo.phone,
        };
      }
      let url = V.SERVER + V.OPS.PREREGISTER;
      gtag("event", "conversion", {
        send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
      });
      this.$http.post(url, _data).then(
          (res) => {
            this.$showLoader(false);
            if (res.data.success) {
              this.$alert("Código Envíado");
              this.leadSent = res.data.success;
              this.leadSent = true;
            } else if (!this.leadSent) {
              this.$alert("Algo salió mal, tal vez tu número ya este registrado");
            }
          },
          (er) => {
            console.log(er);
          }
      );
    },
  },
}
</script>

<style scoped>

.bg-principal {
  background-color: #f1f1f1;
}

.primary-text {
  font-family: OpenSans-Bold;
}

.secondary-text {
  font-family: OpenSans-Regular;
}

.simple-text {
  font-family: OpenSans-Regular;
}

.btn-text {
  font-family: OpenSans-SemiBold;
}

.text-blue {
  color: #2a3d99 !important;
}

.text-gray {
  color: #808080;
}

.text-transform {
  text-transform: none;
}

@media (max-width: 575px) {

  .primary-text {
    font-size: .9rem;
  }

  .secondary-text {
    font-size: .8rem;
  }

  .primary-text br, .secondary-text br {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .primary-text {
    font-size: 1rem;
  }

  .secondary-text {
    font-size: .9rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .primary-text {
    font-size: 1.1rem;
  }

  .secondary-text {
    font-size: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .primary-text {
    font-size: 1.1rem;
  }

  .secondary-text {
    font-size: 1rem;
  }
}

@media (min-width: 1200px) and (max-width: 2459px) {
  .primary-text {
    font-size: 1.2rem;
  }

  .secondary-text {
    font-size: 1rem;
  }
}

@media (min-width: 2560px) {
  .primary-text {
    font-size: 2rem;
  }

  .secondary-text {
    font-size: 1.9rem;
  }
}

</style>