import { render, staticRenderFns } from "./ProposalStep.vue?vue&type=template&id=13b9c230&scoped=true&"
import script from "./ProposalStep.vue?vue&type=script&lang=js&"
export * from "./ProposalStep.vue?vue&type=script&lang=js&"
import style0 from "./ProposalStep.vue?vue&type=style&index=0&id=13b9c230&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13b9c230",
  null
  
)

export default component.exports