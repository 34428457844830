<template>
    <div>
        <section style="background-color:#3953D3;">
        <div class="row justify-content-center">
        <div class="col-lg-10" style="background-color:#FFFFFF; width:80%; margin-top:5rem; margin-bottom:10rem; border-radius:1rem; height: 100%;">
         <b-tabs class="text-black" content-class="mt-3" style="background-color:#FFFFF;">
            <b-tab title="Guía del Envío">
                <div class="container pt-lg-md-3 text-center" v-if="token">
                     <small style="font-size:20px;">Hola {{client.name}}, por favor toma foto al ticket que te proporcionaron en la paquetería donde envíaste los documentos, verifica que se logra visualizar bien el código de rastreo del envío y de que paquetería fue enviado.</small>
                    <div class="row justify-content-center">
                         <div class="col-lg-4 py-4">
                                <div
                                    class="col-lg-12 col-sm-12"
                                    style="text-align: center;text-transform: uppercase;"
                                    >
                                    <label>Guía de Envío</label>
                                    </div>
                                     <div>
                                     <dropbox
                                        text
                                        type="GUIDE_SEND"
                                        :files="this.userFiles"
                                        :contractRisk=true
                                        :userid="this.client.id"
                                        v-on:uploadFile="uploadFileGuideSend"
                                        v-on:resentUploadFile="uploadFileGuideSend"
                                    ></dropbox>
                                </div>
                         </div>
                    </div>
                </div>
                <div class="text-center" style=" margin-top:10rem; margin-bottom:10rem;" v-if="!token">
                <h5 style="font-size:25px;">No logramos validar tu enlace, comúnicate con tu asesor</h5>
                <img src="https://dbmenos.com/img/theme/close-circle.png"></div>
            </b-tab>
         </b-tabs>
        </div>    
            </div>
        </section>
       
    </div>
</template>

<script>
    import Vue from "vue";
    import modal from "@/components/Modal"
    import {V} from "@/V"
    import BaseInput from '../components/BaseInput.vue';
    import Dropbox from "../components/Dropbox";
    export default {
        name: "home",
        components: {
            modal,
            BaseInput,
            Dropbox
        },
        created() {
            this.getDataUser();
        },
        data() {
            return {
                V: V,
                client: {},
                token:false,
                userFiles: [],
                files: {
                GUIDE_SEND:null
                },
                banks: [],
                SERVER: "https://s3.amazonaws.com/dbmenos.frontend/",
                leadModal: false,
                estimatedDiscountModal: false,
                averageDiscount: 0
            }
        },
        methods: {
            getClientProfile() {
            let url =
                this.$v.SERVER +
                this.$v.OPS.GET_PROFILE +
                "?id=" +
                this.client.id;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[this.$v.API.SUCCESS]) {
                    this.client = response.data[this.$v.API.ROW];

                    if (data.row.locality) this.locality = data.row.locality;
                    if (data.row.gender) this.gender = data.row.gender;
                    if (data.row.state_of_birth)
                        this.stateOfBirth = data.row.state_of_birth;
                }
            });
        },
        uploadFileGuideSend(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let date= this.$moment().format("DD_MM_YYYY_HH_mm_ss")
            let headers = {
                "user-id": this.client.id,
                "file-type": "GUIDE_SEND",
                "filename": "guide_send_"+this.client.id+"_"+date+".png"
            };
            this.$showLoader(true);
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE_CONTRACT_HIGH_RISK, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.files.GUIDE_SEND = true;
                        this.verificationFiles();
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 5) {
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 7) {
                        localStorage.clear();
                        localStorage.setItem('uploadFile', '1')
                        this.$router.push({ path: "/register" });
                    } else {
                        this.$notify({
                        text: "No se logró subir, intente nuevamente",
                        type: "error",
                    });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        getFilesInfo() {
            let url = V.SERVER + V.OPS.GET_FILES_USER_RISK;
            let request ={
                user: this.client.id
            }
            this.$axios.post(url,request).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.userFiles = data.rows;
                    for (var i = 0; i < this.userFiles.length; i++) {
                        if (this.userFiles[i].type == "GUIDE_SEND") {
                            this.files.GUIDE_SEND = true;
                        }
                    }
                } else {
                    console.log("error d_userFiles");
                }
            });
        },

        verificationFiles() {
                let url = V.SERVER + V.OPS.VERIFICATIONS_UPLOAD_FILES;
                let request = {user:this.client.id};
            this.$axios.post(url,request).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.$notify({
                        text: "Se subieron todos los archivos correctamente",
                        type: "success",
                    });
                } else {
                     this.$notify({
                        text: "No se logró subir alguno, intente nuevamente",
                        type: "error",
                    });
                }
            });
        },getDataUser() {
            this.$showLoader(true);
            let url = V.SERVER + V.OPS.GET_USER_FILE_GUIDE_RISK+ "?token=" +this.$route.params['pathMatch'];
            this.$axios.get(url).then(async (res) => {
                this.$showLoader(false);
                let data = res.data;
                    if (res.data.success) {
                        this.token = true;
                        this.client = data.row;
                        this.getFilesInfo()
                        this.$showLoader(false);
                    } 
                });
            }
        }
    };
</script>
