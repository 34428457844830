<template>
    <div class="main-container">

        <div class="title-ctn">
            <h5>Historial de usuarios</h5>
        </div>

        <div>

            <!-- <v-data-table
                :headers="headersFilter"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            ></v-data-table> -->

            <v-data-table
                :headers="headers"
                :items="items"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
            >
                <template v-slot:body="{ items }">
                    <tbody>
                        <tr
                            v-for="item in items"
                            :key="item.id"
                        >
                            <td>{{ item.history }}</td>
                            <td>
                                <img src="/img/winoh/user-grey.png" alt="user-icon" class="user-icon">
                                {{ item.user }}
                            </td>
                            <td>
                                <a href="#">{{ item.name }}</a>
                            </td>
                            <td>{{ item.consultor }}</td>
                            <td>{{ item.event }}</td>
                            <td>{{ item.dispositive }}</td>
                            <td>{{ item.date }}</td>
                        </tr>
                    </tbody>
                </template>        
            </v-data-table>

            <div class="text-center pt-2">
                <div class="input-span-ctn">
                    <v-select
                        :items="itemsFilter"
                        label="Dispositivos"
                        item-text="name"
                        dense
                        solo
                        flat
                        hide-details
                        class="select-field"
                        @change="toggleOrder"
                    ></v-select>
                </div>
                <v-btn
                class="mr-2 btn-styles"
                text
                color="transparent"
                @click="toggleOrder"
                >
                Eventos
                </v-btn>
                <v-btn
                class="mr-2"
                @click="toggleOrder"
                >
                Dispositivos
                </v-btn>
                <v-btn
                class="mr-2"
                @click="toggleOrder"
                >
                Consultores
                </v-btn>
                <v-btn
                class="mr-2"
                @click="toggleOrder"
                >
                Fecha
                </v-btn>
                <!-- <v-btn
                color="primary"
                @click="nextSort"
                >
                Sort next column
                </v-btn> -->
            </div>
        </div>



    </div>
</template>

<script>
import consultasDemo from "./consultasDemo.json";

export default {
        name: "ConsultaHistorial",
        data () {
            return {
                sortBy: 'history',
                sortDesc: false,
                headers: [
                    {
                        text: 'Historia',
                        align: 'start',
                        value: 'history',
                    },
                    { text: 'Usuario', value: 'user' },
                    { text: 'Nombre', value: 'name' },
                    { text: 'Consultor', value: 'consultor' },
                    { text: 'Evento', value: 'event' },
                    { text: 'Dispositivo', value: 'dispositive' },
                    { text: 'Fecha', value: 'date' },
                ],
                itemsFilter: [
                    { name: 'A a la Z ',        number: 1 },
                    { name: 'Z a la A',      number: 2 },
                ]
            };
        },
        methods: {
            toggleOrder () {
            this.sortDesc = !this.sortDesc
            },
            nextSort () {
            let index = this.headers.findIndex(h => h.value === this.sortBy)
            index = (index + 1) % this.headers.length
            this.sortBy = this.headers[index].value
            },
        },
        computed: {
            items() {
                return consultasDemo.map((item) => {
                    return item;
                    })
                }
            }
    }
</script>

<style lang="scss" scoped>
    @import './scss/ConsultaHistorial.scss';
</style>