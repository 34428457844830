<template>

  <div class="bg-principal">

    <div class="my-5 text-center bg-sm-video">

      <video
          controls
          muted
          autoplay
          type="video/mp4"
          class="sm-video"
          src="/videos/fabiola_debia_v.mp4"
      ></video>

    </div>

    <div class="bg-lg-video text-center">

      <video
          controls
          muted
          autoplay
          type="video/mp4"
          class="text-center lg-video"
          src="/videos/fabiola_debia_h.mp4"
      ></video>

    </div>

  </div>

</template>

<script>
export default {
  name: "VideoWomen"
}
</script>

<style scoped>

@media (max-width: 319px) {

  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 374px) {

  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }

  .sm-video {
    width: 100%;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }

  .sm-video {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .bg-sm-video {
    display: none;
  }

  .bg-lg-video {
    background-color: #FFFFFF !important;
  }

  .lg-video {
    width: 100%;
  }

}

@media (min-width: 1024px) and (max-width: 1439px) {

  .bg-sm-video {
    display: none;
  }

  .lg-video {
    width: 100%;
  }

}

@media (min-width: 1440px) and (max-width: 2559px) {

  .bg-sm-video {
    display: none;
  }

  .lg-video {
    width: 100%;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .bg-sm-video {
    display: none;
  }

  .lg-video {
    width: 50%;
  }

  .lg-video {
    width: 100%;
  }
}

@media (min-width: 2561px) {

  .bg-sm-video {
    display: none;
  }

  .lg-video {
    width: 50%;
  }

  .lg-video {
    width: 100%;
  }
}

</style>