<template>
    <div v-if="window.width < window.height" id="pleaserotate-backdrop" style="display: block;">
        <div id="pleaserotate-container">
            <svg
                xmlns:xlink="http://www.w3.org/1999/xlink"
                id="pleaserotate-graphic"
                viewBox="0 0 250 250"
            >
                <g id="pleaserotate-graphic-path">
                    <path
                        d="M190.5,221.3c0,8.3-6.8,15-15,15H80.2c-8.3,0-15-6.8-15-15V28.7c0-8.3,6.8-15,15-15h95.3c8.3,0,15,6.8,15,15V221.3zM74.4,33.5l-0.1,139.2c0,8.3,0,17.9,0,21.5c0,3.6,0,6.9,0,7.3c0,0.5,0.2,0.8,0.4,0.8s7.2,0,15.4,0h75.6c8.3,0,15.1,0,15.2,0s0.2-6.8,0.2-15V33.5c0-2.6-1-5-2.6-6.5c-1.3-1.3-3-2.1-4.9-2.1H81.9c-2.7,0-5,1.6-6.3,4C74.9,30.2,74.4,31.8,74.4,33.5zM127.7,207c-5.4,0-9.8,5.1-9.8,11.3s4.4,11.3,9.8,11.3s9.8-5.1,9.8-11.3S133.2,207,127.7,207z"
                    />
                </g>
            </svg>
            <div id="pleaserotate-message">
                Por favor, activa la rotación automática de tu dispositivo y rótalo
                <small>(Esta página sólo funciona en orientación horizontal)</small>
            </div>
        </div>
    </div>
    <section
        v-else
        class="section section-shaped section-lg my-0"
        style="background-size: cover;
            background-repeat: no-repeat;
            background-image: url('img/brand/Header_1440_100.png');"
    >
        <div class="container pt-lg-md" style="margin-top:-3rem;">
            <div class="row justify-content-center">
                <div class="col-lg-10" style="opacity: 0.95">
                    <card
                        type="secondary"
                        shadow
                        header-classes="bg-white pb-5"
                        body-classes="px-lg-10 py-lg-10"
                        class="border-0"
                    >
                        <template>
                            <div>
                                <div class="text-center text-muted mb-4">
                                    <div class="row justify-content-around align-items-center">
                            <div class="col-lg-4 col-md-4 col-sm-5 col-8 text-center">
                                <!--<img src="https://dbmenos.com/img/finamigo.png" class="img-fluid" alt="Finamigo Logo">!-->
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-6 col-11" style="text-align: center;">
                                    <small>Autoriza el uso de tu firma para generar el contrato con DB Menos</small>
                                    <p>
                                        <a :href="urlContract" target="_blank">
                                            <b>Ver el contrato</b>
                                        </a>
                                    </p>
                            </div>
                        </div>
                                    <div>
                                        <div
                                            style="background-color: #FFFFFF; margin-top: 16px; position: relative;"
                                        >
                                            <VueSignaturePad height="300px" ref="signaturePad" />
                                            <div class="signature mx-5 text-center noselect">
                                                <br />Firma
                                                <br />
                                                <br />
                                            </div>
                                        </div>
                                        <div class="row d-flex justify-content-around">
                                            <base-button
                                                type="secondary"
                                                class="my-4"
                                                v-on:click="clearSignature()"
                                            >Borrar firma</base-button>
                                            <base-button
                                                type="primary"
                                                class="my-4"
                                                v-on:click="sendSignature()"
                                            >{{'Cargar firma'}}</base-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import firebase from 'firebase/app';
    import 'firebase/firestore';
    import 'firebase/analytics';
    import 'firebase/messaging';
import Vue from "vue";
import VueResource from "vue-resource";
import { V } from "@/V";
import VueSignaturePad from "vue-signature-pad";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
Vue.use(VueFacebookPixel);
Vue.use(VueResource);
export default {
    components: {
        VueFacebookPixel,
        VueSignaturePad
    },
    computed: {
        banks() {
            return this.$store.state.banks;
        },
        nombre_banco: function() {
            return this.getBank(this.account.bank).name;
        }
    },
    created() {
        //Vue.analytics.fbq.init(V.FACEBOOK_PIXEL_ID, {}),

        fbq("track", "step_six");
        firebase.analytics().logEvent("step_six");
        gtag("event", "step_six", {
            send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
        });
        window.addEventListener("resize", this.handleResize);
        this.handleResize();
    },
    destroyed() {
        window.removeEventListener("resize", this.handleResize);
    },
    mounted() {
        this.handleResize();
        this.createPreContract();
    },
    data() {
        return {
            token: null,
            sign: null,
            validToken: false,
            account: null,
            signToken: null,
            signed: null,
            waitingSignature: true,
            alreadySigned: false,
            signedForCreditReport: false,
            signedForContract: false,
            signaturePath: null,
            SIGN_CONTRACT: "contract",
            SIGN_ACCOUNT_SETTLEMENT: "account",
            urlContract:"",
            user: {
                id:null,
                name: null,
                address: null,
                address_2: null,
                phone: null,
                email: null
            },

            window: {
                width: 0,
                height: 0
            },
            today:""
        };
    },
    methods: {
        getBank(id) {
            for (let i = 0; i < this.banks.length; i++)
                if (this.banks[i].id === id) return this.banks[i];
            return null;
        },
        clearSignature() {
            this.$refs.signaturePad.undoSignature();
        },
        async updateSignature(signature, filename) {
            this.$showLoader(true);
            let url =
                V.SERVER + V.OPS.GET_UPLOAD_FILE_URL + "?filename=" + filename;
            await this.$axios
                .get(url)
                .then(async res => {
                    this.$showLoader(false);

                    if (res.status == 200) {
                        this.$showLoader(false);
                        let data = res.data;
                        if (data[this.$v.API.SUCCESS]) {
                            fbq("track", "signature_submitted");
                            firebase
                                .analytics()
                                .logEvent("signature_submitted");
                            gtag("event", "signature_submitted", {
                                send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
                            });
                            
                            var upImgUrl =
                                res.data[this.$v.API.ROW].uploadImgUrl;
                            var upRes = await this.$axios.put(
                                upImgUrl,
                                signature,
                                { "Content-Type": signature.type }
                            );
                            return upRes != null && upRes.status == 200;
                        }
                    }
                })

                .catch(e => {
                    this.$showLoader(false);
                    console.log(e);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        urltoFile(url, filename, mimeType) {
            return fetch(url)
                .then(function(res) {
                    return res.arrayBuffer();
                })
                .then(function(buf) {
                    return new File([buf], filename, { type: mimeType });
                });
        },
        async sendSignature(signature, filename) {
            this.$showLoader(true);
            let f = new Date();
                let month = f.getMonth() + 1;
                let day = f.getDate();
                let minute = f.getMinutes();
                let hour= f.getHours();
                let second= f.getSeconds();
                if (month < 10) month = "0" + month;
                if (day < 10) day = "0" + day;
                if (minute < 10) minute = "0" + minute;
                if (hour < 10) hour = "0" + hour;
                if (second < 10) second = "0" + second;
                this.today = "signature_" +f.getFullYear() + "-" + month + "-" + day + ".png";
            const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
            if (!isEmpty && data) {
                const img = await this.urltoFile(data);
                if (await this.updateSignature(img, this.today)) 
                    return false;
                
                let url = V.SERVER + V.OPS.SET_CONTRACT_SIGNATURE;
                this.$axios
                    .post(url, { filename: this.today })
                    .then(async res => {
                        //this.$showLoader(false);
                        if (res.status == 200) {
                            if (res.data.success || res.data.reason == 5 ) {
                                 this.$showLoader(false);
                                this.checkStatus();
                                this.$alert("Firma Enviada");
                            }else if(res.data.reason == 1){
                                 this.$showLoader(false);
                                this.sendSignature(img,  this.today)
                            }
                        }
                    })
                    .catch(e => {
                        this.$showLoader(false);
                        console.log(e);
                        alert("No se logró enviar, intente nuevamente");
                    });
            }
        },
        createPreContract() {
            this.$showLoader(true)
            let url = V.SERVER + V.OPS.CREATE_PRE_CONTRACT
            this.$axios.get(url).then(response => {
             let data = response.data;
              this.$showLoader(false)
                if (data[V.API.SUCCESS]) {
                        this.urlContract = data.row.url;
                    }
                });
        },
        checkStatus() {
            let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                        this.$router.push({
                            path: "/finish"
                        });
                }
            });
        },
        base64ToBlob(b64Data, contentType, sliceSize) {
            contentType = contentType || "";
            sliceSize = sliceSize || 512;

            let byteCharacters = atob(b64Data);
            let byteArrays = [];

            for (
                let offset = 0;
                offset < byteCharacters.length;
                offset += sliceSize
            ) {
                let slice = byteCharacters.slice(offset, offset + sliceSize);

                let byteNumbers = new Array(slice.length);
                for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                }

                let byteArray = new Uint8Array(byteNumbers);

                byteArrays.push(byteArray);
            }

            return new Blob(byteArrays, { type: contentType });
        },
        /*
            async validateToken() {
                this.$showLoader(true)
                if (this.$route.query.token) {
                    this.sign = this.$route.query.sign
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?token=" + this.$route.query.token
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row
                        if (data.success) {
                            this.token = this.$route.query.token
                            this.validToken = true
                            this.alreadySigned = _token.signed
                            this.signedForCreditReport = _token.for_credit_report
                            this.signedForContract = _token.for_contract
                            this.signaturePath = _token.path

                            this.user.name = data.user.name
                            this.user.address = 'Calle ' + data.user.street + ' #' + data.user.extnum + ', ' + data.user.sublocality
                            this.user.address_2 = 'C.P. ' + data.user.postal_code + ', ' + data.user.state_of_birth
                            this.user.phone = 'Tel. ' + data.user.phone
                            this.user.email = 'e-mail: ' + data.user.email
//                            console.log(_token)
                            this.$forceUpdate()
                        }
                        else if(data.reason == 5) {
                            this.alreadySigned = true
                        }
                    })
                }
                else if (this.$route.query.t) {
                    this.sign = this.SIGN_ACCOUNT_SETTLEMENT
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?t=" + this.$route.query.t
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row.token
                        let _account = data.row.account
                        if (data.success) {
                            this.signToken = _token
                            this.account = _account
                            this.token = this.$route.query.t
                            this.validToken = true
                            this.signed = _token.signed
//                            console.log(data)
                            this.$forceUpdate()
                        }
                    })
                }
                else this.$showLoader(false)
            },
            */
        handleResize() {
            this.window.width = window.innerWidth;
            this.window.height = window.innerHeight;

            let footer = document.getElementById("footer");

            if (this.window.width < this.window.height) {
                if (footer) footer.style.display = "none";
            } else {
                if (footer) footer.style.display = "block";
            }
        }
    }
};
</script>
<style>
.signature {
    border: 0;
    border-top: 1px solid #000;

    left: 0;
    right: 0;
    bottom: 10px;
    margin: auto;
    position: absolute;
    pointer-events: none;
}
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
#pleaserotate-graphic {
    margin-left: 50px;
    width: 200px;
    animation: pleaserotateframes ease 2s;
    animation-iteration-count: infinite;
    transform-origin: 50% 50%;
    -webkit-animation: pleaserotateframes ease 2s;
    -webkit-animation-iteration-count: infinite;
    -webkit-transform-origin: 50% 50%;
    -moz-animation: pleaserotateframes ease 2s;
    -moz-animation-iteration-count: infinite;
    -moz-transform-origin: 50% 50%;
    -ms-animation: pleaserotateframes ease 2s;
    -ms-animation-iteration-count: infinite;
    -ms-transform-origin: 50% 50%;
}
#pleaserotate-backdrop {
    background-color: white;
    top: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 100%;
}
#pleaserotate-container {
    width: 300px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
}
#pleaserotate-message {
    margin-top: 20px;
    font-size: 1.3em;
    text-align: center;
    font-family: Verdana, Geneva, sans-serif;
    text-transform: uppercase;
}
#pleaserotate-message small {
    opacity: 0.5;
    display: block;
    font-size: 0.6em;
}
@keyframes pleaserotateframes {
    0% {
        transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
    }
    49% {
        transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
    }
    100% {
        transform: rotate(90deg);
        -moz-transform: rotate(-90deg);
        -webkit-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
    }
}
</style>
