<template>

  <div class="bg-principal">

    <div class="row title-section">
      <div class="col-12 col-sm-10 mx-auto">
        <p class="font-weight-bold text-center f-title">Síguenos en nuestras redes</p>
      </div>
    </div>

    <div class="row col-12 col-sm-6 mx-auto">
      <div class="mx-auto text-center" v-for="item of images">
        <a :href="item.url" target="_blank">
          <img :src="item.image" :alt="item.index" width="10%">
        </a>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "SocialMedia",
  data: () =>
      ({
        images:
            [
              {
                id: 1,
                image: '/img/HomepageNew/Desktop/Facebook logo.svg',
                url: 'https://www.facebook.com/grupodbmenos/'
              },
              {
                id: 2,
                image: '/img/HomepageNew/Desktop/Instagram logo.svg',
                url: 'https://www.instagram.com/db_menos/'
              },
              {
                id: 3,
                image: '/img/HomepageNew/Desktop/WhatsApp logo verde.svg',
                url:'https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos'
              },
              {
                id: 4,
                image: '/img/HomepageNew/Desktop/YouTube logo.svg',
                url:'https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw'
              },
              {
                id: 5,
                image: '/img/HomepageNew/Desktop/Twitter logo.svg',
                url:'https://www.facebook.com/grupodbmenos/'
              },
              {
                id: 6,
                image: '/img/HomepageNew/Desktop/LinkedIn logo.svg',
                url:'https://www.linkedin.com/company/grupo-db-menos/'
              },
              {
                id: 7,
                image: '/img/HomepageNew/Desktop/Blog logo.svg',
                url:'https://www.dbmenos.com/blogs'
              },
            ]
      })
}
</script>

<style scoped>

.bg-principal {
  background-color: #FFFFFF;
}

.f-title {
  font-family: OpenSans-Bold;
  color: #2a3d9a;
}

img {
  width: 5rem !important;
}

@media (min-width: 320px) and (max-width: 374px) {
  .f-title {
    font-size: 1.4rem;
  }

}

@media (min-width: 375px) and (max-width: 424px) {
  .f-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .f-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .f-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .f-title {
    font-size: 2rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .f-title {
    font-size: 2rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .f-title {
    font-size: 3rem;
  }
}

</style>