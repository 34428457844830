<template>
  <v-carousel
      cycle
      height="170"
      hide-delimiters
      hide-delimiter-background
      show-arrows-on-hover
  >
    <v-carousel-item v-for="(item, index) of items" :key="index">
      <v-sheet height="100%">
        <v-row justify="center">
          <div class="text-center col-10">
            <v-card elevation="5">
              <v-card-text>
                <p class="text-blue card-number gotham-ultra">{{ item.amount }}</p>
                <p class="text-gray card-text open-sans-regular" v-html="item.desc"></p>
              </v-card-text>
            </v-card>
          </div>
        </v-row>
      </v-sheet>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "CarouselStatistics",
  props:
      {
        items: []
      },
  data: () => ({})
}
</script>

<style scoped>


@font-face {
  font-family: OpenSans-Italic;
  src: url(/fonts/HomepageNew/OpenSans-Italic.ttf);
}

@font-face {
  font-family: OpenSans-Bold-Italic;
  src: url(/fonts/HomepageNew/OpenSans-BoldItalic.ttf);
}

/*Fonts*/

.open-sans-regular {
  font-family: OpenSans-Regular;
}

.open-sans-italic {
  font-family: OpenSans-Italic;
}

.open-sans-bold-italic {
  font-family: OpenSans-Bold-Italic;
}

.gotham-ultra {
  font-family: Gotham-Ultra;
}

/*Colors*/
.text-gray {
  color: #808080;
}

.text-blue {
  color: #2a3d99 !important;
}

@media (max-width: 575px) {
  .card-number {
    font-size: 2rem;
  }
}
</style>