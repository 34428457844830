<template>
    <div>
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0" style="background-color:#092699;">
                <div class="shape shape-style-1 shape-default shape-skew" >
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </section>
            <div class="container" style="margin-top:5rem;">
                <div class="row justify-content-center">
                    <div class="col-lg-12" v-if="find">
                          <div class="jumbotron">
                  <div class="container-image">
                    <img
                      :src="'https://www.dbmenos.com/img/postWebinar/' + info.og_picture"
                      alt="Imagen no Encontrada"
                      class="image"
                    />
                    <div class="overlay" >
                      <h1 class="text-black overlay-title" >{{ info.title }}</h1>
                      <h1 class="text-black overlay-description" >{{ info.description }}</h1>
                      <br>
                      <h1 class="text-black overlay-date">El día {{ formatDate(info.date) }} a las {{ formatHour(info.hour) }} con duración de {{ info.duration }}</h1>


                    <a class="btn btn-primary btn-lg overlay-button font-sizebu" :href="info.link" role="button"
                      >Unirse</a>
                    </div>
                  </div>
                  
                    <div align="right" style="margin-top:2rem">
                    </div>
                    <button
                     class="btn btn-primary btn-lg btn-block"
                     style="margin-top:4rem"
                     @click="back">Regresar</button>
                  </div>
                    </div>
                    <div v-if="!find" class="col-lg-12">
                        <div class="text-center">
                            <h1>Video Conferencia no Encontrada</h1>
                            <br>
                            <button
                                class="btn btn-primary btn-lg btn-block"
                                @click="back"
                            >Regresar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vue from "vue";
import VueResource from "vue-resource";
import { V } from "@/V";
import VueSignaturePad from "vue-signature-pad";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import moment, { max } from "moment";
Vue.use(VueFacebookPixel);
export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: "DBMenos",
        // all titles will be injected into this template
        titleTemplate: "%s | Blog"
    },

    components: {
        VueFacebookPixel
    },
    computed: {
        banks() {
            return this.$store.state.banks;
        },
        nombre_banco: function() {
            return this.getBank(this.account.bank).name;
        }
    },
    created() {
        //Vue.analytics.fbq.init(V.FACEBOOK_PIXEL_ID, {})
        this.getBlogEntry();
    },
    mounted() {
        this.idB = this.$route.query.id;
    },
    data() {
        return {
            info: [],
            title: "Video Conferencias | DBMENOS",
            idB: "",
            titleB: "",
            descriptionB: "",
            fechaB: "",
            og_pictureB: "",
            postB: "",
            miniURL: "",
            find: true
        };
    },
    methods: {
        formatDate(date) {
            return moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
        },
        back(){
            this.$router.push("/postWebinars");
        },
          formatDate(date) {
      return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");    
      },
    formatHour(hour) {
      return moment(hour, "H:m").format("LT");    
      },
        async getBlogEntry(idBlog) {
            this.idB = this.$route.query.id;
            this.$showLoader(true);
            let url = V.SERVER + V.OPS.GET_BLOG_ENTRY_WEBINAR + "?id=" + this.idB;
            let response = await this.$axios.get(url);
            this.$showLoader(false);
            let data = response.data;
            if (data.success) {
                this.info = data.row;
                this.find = true;
                this.$forceUpdate();
            } else {
                switch (data.reason) {
                    case 1:
                        this.find = false;
                }
            }
        }
    }
};
</script>
<style>
.ql-align-center {
    text-align: center;
}
.ql-align-justify {
    text-align: justify;
}
iframe {
    display: table;
    margin: 0 auto;
}
img {
    max-width: 90%;
    height: auto;
}
p {
    padding: 0 20px;
}

.overlay-title {
  font-size: 4rem;
}
.overlay-description {
  font-size: 2rem;
}
.overlay-date {
  font-size: 1.5rem;
}
.overlay-button{
        font-family:Verdana,Helvetica;
        font-weight:bold;
        color:white;
        background:#638cb5;
        border:0px;
        width:50%;
        height:15%;
}
.font-sizebu{
    font-size:1.5rem;
}
.overlay {
  position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  font-size: 1rem;
  padding: 6rem;
  text-align: center;
  border-radius:1rem;
}
@media screen and (max-width: 770px) {
  .overlay {
    position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 4rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 3rem;
}
.overlay-description {
  font-size: 1.5rem;
}
.overlay-date {
  font-size: 1rem;
}
.overlay-button{
        font-size:1rem;
        font-family:Verdana,Helvetica;
        font-weight:bold;
        color:white;
        background:#638cb5;
        border:0px;
        width:50%;
        height:20%;
}
.font-sizebu{
    font-size:1rem;
}
}
@media screen and (max-width: 430px) {
  .overlay {
    position: absolute;
  bottom: 0;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 2rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 1.5rem;
}
.overlay-description {
  font-size: 1rem;
}
.overlay-date {
  font-size: 0.8rem;
}
.overlay-button{
        font-size:1rem;
        font-family:Verdana,Helvetica;
        font-weight:bold;
        color:white;
        background:#638cb5;
        border:0px;
        width:35%;
        height:30%;
}
}
@media screen and (max-width: 380px) {
  .overlay {
  position: absolute;
  bottom: 1rem;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 1rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 2rem;
}
.overlay-description {
  font-size: 1rem;
}
.overlay-date {
  font-size: 0.7rem;
}
.overlay-button{
        font-size:0.5rem;
        border:0px;
        width:35%;
        height:30%;
}
}
@media screen and (max-width: 325px) {
  .overlay {
  position: absolute;
  bottom: 1rem;
  left:0;
  color: #000000;
  background: #ffffff6c;
  width: 100%;
  height: 100%;
  opacity: 0;
  color: rgb(0, 0, 0);
  padding: 0.3rem;
  text-align: center;
  border-radius:1rem;
  }
  .overlay-title {
  font-size: 1.5rem;
}
.overlay-description {
  font-size: 0.8rem;
}
.overlay-date {
  font-size: 0.8rem;
}
.overlay-button{
        font-size:0.1rem;
        border:0px;
        width:40%;
        height:33%;
}
}
</style>
