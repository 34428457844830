<template>
  <div class="position-relative" v-if="this.client">
    <section class="section-shaped my-4" style="background-color:#FFFF;">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="container shape-container" style=" margin:-12rem;">
        <div class="col px-0">
          <div class="row"></div>
        </div>
      </div>
    </section>

    <section class="section section-lg pt-lg-0 mt--300 sta">
      <!--
      <div class="mt-3">
          <b-pagination v-model="pag" :total-rows="rows" align="center"></b-pagination>
      </div>
      !-->
      <div v-if="pag==1" class="container mt--7" v-bind:style="{cursor:pointer2}">
        <div class="col-lg-12 text-center">
          <h1 style="color: #2a3d9a; font-size: 3rem; margin-bottom:40px;" class="text-black">
            ¡Completa tus últimos datos!
          </h1>
        </div>
        <div class="row">
          <div class="col-lg-12 center">
            <card shadow type="secondary">
              <template>
                <form @submit.prevent>
                  <div class="pl-lg-12 text-center">
                    <div class="row">
                      <div class="col-lg-12 text-center">
                        <div class="form-check text-center">
                          <b-form-group
                              style="margin-bottom:4%;"
                              v-slot="{ ariaDescribedby }"
                          ><b>Estado civil:</b>
                            <b-form-checkbox-group
                                v-model="client.civil_status"
                                :options="options"
                                :aria-describedby="ariaDescribedby"
                                buttons
                                style="margin-left:5%;"
                                button-variant="primary"
                                size="lg"
                                name="buttons-2"
                            ></b-form-checkbox-group>
                          </b-form-group>
                          ¿A qué te dedicas?
                          <base-dropdown style="margin-bottom:3%; margin-left:5%;">
                            <base-button
                                slot="title"
                                block
                                type="primary"
                                class="dropdown-toggle"
                                size="lg"
                                style=" white-space: normal"
                                :class="client.economic_activity === null ? 'text-danger' : ''"
                            >{{ client.economic_activity ? getEconomicActivity() : "Actividad económica" }}
                            </base-button>
                            <a
                                class="dropdown-item"
                                v-for="(activity, index) in economicActivities"
                                :key="index"
                                v-on:click="setEconomicActivity(activity.id,activity.activity)"
                            >{{ activity.activity }}</a>
                          </base-dropdown>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </template>
              <template>
                <form @submit.prevent>
                  <!--
                  <div class="container pt-lg-md-3">
                      <div class="row justify-content-center">
                              <div
                                  class="col-3"
                                  style="text-align:center; margin-top:3.5%; text-transform: uppercase;"
                              >
                                  <label>Sube tu comprobante de domicilio</label>
                              </div>
                              <div class="col">
                                  <dropbox
                                      text
                                      type="ADDRESS"
                                      :filesBandera="true"
                                      :userid="this.client.id"
                                      :files="this.userFiles"
                                      v-on:uploadFile="uploadFileComprobante"
                                      v-on:resentUploadFile="uploadFileComprobante"
                                  ></dropbox>
                          </div>
                      </div>
                  </div>
                  !-->
                  <!--
                  <div class="text-center">

                      <hr class="my-4" />
                      <h1 style="color: #2a3d9a; font-size: 1.5rem; margin-top:5%" class="text-black">Con <img
                      src="img/brand/DB Menos logo azul 2020_.png"
                      style="width: 20%; margin-top:-1%;"
                  /> tu cuenta está segura. <br>
                  Dinos quien será el beneficiario de tu cuenta</h1>
                      <div class="text-center" style="margin-top:5%">
                          <template>
                              <form @submit.prevent>
                                  <div class="pl-lg-12">
                                      <div class="pl-lg-4">
                                          <div class="row">
                                              <div class="col-lg-4">
                                                  <base-input
                                                      alternative
                                                      label="Nombre"
                                                      input-classes="form-control-alternative"
                                                      v-model="beneficiary.name"
                                                  />
                                              </div>
                                              <div class="col-lg-4">
                                                  <base-input
                                                      alternative
                                                      label="Primer apellido"
                                                      input-classes="form-control-alternative"
                                                      v-model="beneficiary.lastname_1"
                                                  />
                                              </div>
                                              <div class="col-lg-4">
                                                  <base-input
                                                      alternative
                                                      label="Segundo apellido"
                                                      input-classes="form-control-alternative"
                                                      v-model="beneficiary.lastname_2"
                                                  />
                                              </div>
                                          </div>
                                          <div class="row">
                                              <div class="col-lg-3">
                                                  <label
                                                      class="form-control-label"
                                                  >Fecha de nacimiento</label>
                                                  <flat-pickr
                                                      v-model="beneficiary.birthday"
                                                      :config="{}"
                                                      class="form-control form-control-alternative"
                                                      data-vv-as="Fecha"
                                                      name="date"
                                                      style="background-color: #FFFFFF"
                                                  />
                                              </div>
                                              <div class="col-lg-3 text-center">
                                                  <base-dropdown
                                                      style="margin-top: 25px"
                                                  >
                                                      <base-button
                                                      block
                                                          slot="title"
                                                          type="primary"
                                                          size="lg"
                                                          class="dropdown-toggle"
                                                          :class="beneficiary.gender ? '' : 'text-danger'"
                                                      >{{beneficiary.gender ? beneficiary.gender == 'M' ? 'GÉNERO MASCULINO' : 'GÉNERO FEMENINO' : "Selecciona género"}}</base-button>
                                                      <a
                                                          class="dropdown-item"
                                                          v-on:click="setBeneficiaryGender('Masculino')"
                                                      >Masculino</a>
                                                      <a
                                                          class="dropdown-item"
                                                          v-on:click="setBeneficiaryGender('Femenino')"
                                                      >Femenino</a>
                                                  </base-dropdown>
                                              </div>
                                              <div class="col-lg-3 text-center">
                                                  <base-dropdown
                                                      style="margin-top: 25px"
                                                  >
                                                      <base-button block
                                                          slot="title"
                                                          type="primary"
                                                          size="lg"
                                                          class="dropdown-toggle"
                                                          large="true"
                                                          :class="beneficiary.civil_status ? '' : 'text-danger'"
                                                      >{{beneficiary.civil_status ? beneficiary.civil_status == 'M' ? 'CASADO' : 'SOLTERO' : "Estado civil"}}</base-button>
                                                      <a
                                                          class="dropdown-item"
                                                          v-on:click="beneficiaryCivilStatusSelected('Casado')"
                                                      >Casado</a>
                                                      <a
                                                          class="dropdown-item"
                                                          v-on:click="beneficiaryCivilStatusSelected('Soltero')"
                                                      >Soltero</a>
                                                  </base-dropdown>
                                              </div>
   <div class="col-lg-3 text-center">
    <base-dropdown style="margin-top: 30px">
      <base-button
        slot="title"
        type="primary"
        class="dropdown-toggle"
        large="true"
        :class="
          beneficiary.relationship === null
            ? 'text-danger'
            : ''
        "
        >{{
          beneficiary.relationship
            ? getRelationBeneficiary()
            : "Parentesco"
        }}</base-button
      >
      <a
        class="dropdown-item"
        v-for="(value, index) in relationBeneficiary"
        :key="index"
        v-on:click="beneficiaryRelationSelected(value.id)"
        >{{ value.value }}</a
      >
    </base-dropdown>
  </div>
                                          </div>
                                      </div>
                                      <hr class="my-4" />
                                  </div>
                              </form>
                          </template>
                      </div>
                      <h1 style="color: #2a3d9a; font-size: 2rem; margin-top:2%;" class="text-black">MIS DEPOSITOS</h1>
                      <div class="row" style=" margin-bottom:5%">
                      <div class="col-lg-6" style="margin-top:3%">
                          <label
                              class="form-control-label"
                          >Fecha de mi primer pago</label>
                          <flat-pickr
                              v-model="client.first_payment"
                              :config="{}"
                              class="form-control form-control-alternative"
                              data-vv-as="Fecha"
                              name="date"
                              style="background-color: #FFFFFF"
                          />
                      </div>
                          <div class="col-lg-6" style="margin-top:2%">
                                              <h6
                                                  class="text-center" style="color: #2a3d9a; font-size: 1rem; margin-top:2%;"
                                              >¿Qué días depositaré? </h6> <b style="color: #2a3d9a; font-size: 1.5rem; margin-top:1%;">{{payday}}</b>
                                              <base-slider
                                                  :range="{min: 1, max: 30}"
                                                  :options="{step: 1}"
                                                  v-model.number="payday"
                                              ></base-slider>
                      </div>

                      </div>

                                      <hr class="my-4" />
                  </div>
                  !-->

                  <b-alert
                      :show="dismissCountDown_doc_success"
                      dismissible
                      fade
                      variant="success"
                      @dismissed="dismissCountDown_doc_success=0"
                      @dismiss-count-down="countDownChanged(5)"
                  >{{ fileToUpload }} cargado exitosamente.
                  </b-alert>
                  <b-alert
                      :show="dismissCountDown_doc_failure"
                      dismissible
                      fade
                      variant="danger"
                      @dismissed="dismissCountDown_doc_failure=0"
                      @dismiss-count-down="countDownChanged(6)"
                  >Error en la carga de {{ fileToUpload }}
                  </b-alert>

                  <b-alert
                      :show="dismissCountDown_end"
                      dismissible
                      fade
                      variant="danger"
                      @dismissed="dismissCountDown_end=0"
                      @dismiss-count-down="countDownChanged(4)"
                  >Campos incompletos:{{ incompletos }}
                  </b-alert>
                  <div class="row d-flex justify-content-end">
                    <div class="col-6 text-right my-2">
                      <base-button
                          slot="title"
                          type="primary"
                          class="btn"

                          style="background-color:#FFD527; border-color:#FFD527; color:#3953D3; width:60%; border-radius:20px;"
                          size="xl"
                          v-on:click="finalCheck"
                      >Guardar
                      </base-button>
                    </div>
                  </div>
                </form>
              </template>
            </card>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/messaging';
import flatPickr from "vue-flatpickr-component";
import BaseDropdown from "../components/BaseDropdown";
import UploadFilesForm from "@/views/components/UploadFilesForm.vue";
import Dropbox from "../components/Dropbox";
import {V} from "../V";

export default {
  data() {
    return {
      rows: 100,
      options: [
        {text: 'Casado', value: 'M'},
        {text: 'Soltero', value: 'S'},
      ],
      client: {},
      pag: 1,
      gender: null,
      stateOfBirth: null,
      postalCodes: [],
      locality: "",
      sublocalities: [],
      beneficiary: {},
      genderBeneficiary: null,

      dismissSecs: 8,
      //            dismissCountDown_telefono: 0,
      dismissCountDown_cp: 0,
      dismissCountDown_edad: 0,
      dismissCountDown_curp: 0,
      dismissCountDown_end: 0,
      dismissCountDown_ine: 0,
      dismissCountDown_doc_success: 0,
      dismissCountDown_doc_failure: 0,

      pointer: "pointer",
      pointer2: "default",
      regEmail: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
      regPhone: /[0-9]{10}?/,
      payday: 15,
      userFiles: [],
      filetype: null,
      archivo: null,
      files: {
        ADDRESS: null
      },
      fileId: true,
      fileId_b: true,
      urlAsesor: "",
    };
  },
  created() {
    this.getAsesor();
    fbq("track", "lead_complete_automated");
    firebase.analytics().logEvent("lead_complete_automated");
    gtag("event", "lead_complete_automated", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
    });

    fbq("track", "step_one");
    firebase.analytics().logEvent("step_one");
    gtag("event", "step_one", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
    });

    this.getFilesInfo();
    this.client = JSON.parse(
        JSON.stringify(JSON.parse(localStorage.getItem(V.STORE_KEYS.USER)))
    );
    if (this.client) {
      this.getClientProfile();
    } else {
      this.$router.push("/register");
    }
  },
  computed: {
    mxStates() {
      return this.$store.state.mxStates;
    },
    relationBeneficiary() {
      return this.$store.state.relationBeneficiary;
    },
    economicActivities() {
      let activities = this.$store.state.economicActivities;
      if (!activities) this.$store.dispatch("downloadEconomicActivities");
      return activities;
    },
    incompletos: function () {
      this.client.payday = this.payday;
      return (
          (!this.client.civil_status ? " - Estado civil" : "") +
          (!this.client.economic_activity
              ? " - Actividad económica"
              : "") +
          (!this.beneficiary.name ? " - Nombre del beneficiario" : "") +
          (!this.beneficiary.lastname_1
              ? " - Primer apellido del beneficiario"
              : "") +
          (!this.beneficiary.lastname_2
              ? " - Segundo apellido del beneficiario"
              : "") +
          (!this.beneficiary.birthday
              ? " - Fecha de nacimiento del beneficiario"
              : "") +
          (!this.beneficiary.gender ? " - Género del beneficiario" : "") +
          (!this.beneficiary.civil_status
              ? " - Estado civil del beneficiario"
              : "")
      );
    },

    fileToUpload: function () {
      return this.archivo;
    }
  },
  methods: {
    getRelationBeneficiary() {
      for (let i = 0; i < this.relationBeneficiary.length; i++)
        if (
            this.relationBeneficiary[i].id ===
            this.beneficiary.relationship
        )
          return this.relationBeneficiary[i].value;
      return null;
    },
    beneficiaryRelationSelected(relation) {
      this.beneficiary.relationship = relation;
      this.getRelationBeneficiary();
      this.$forceUpdate();
    },
    getAsesor() {
      let url = this.$v.SERVER + this.$v.OPS.GET_CONSULTANT_INFO;
      this.$axios.get(url).then(response => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.phone = data.row.phone;
          this.urlAsesor = "https://wa.me/52" + this.phone;
          // window.open(this.urlAsesor);
        }
      });
    },
    getFilesInfo() {
      this.getAsesor();
      let url = V.SERVER + V.OPS.SELECT + V.TABLES.USER_FILES;
      this.$axios.post(url).then(response => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          this.userFiles = data.rows;
          for (var i = 0; i < this.userFiles.length; i++) {
            if (this.userFiles[i].type == "ADDRESS") {
              //                            console.log("Sí hay " + 'ID' + " en la BD")
              this.files.ADDRESS = true;
            }
          }
        } else {
          console.log("error d_userFiles");
        }
      });
    },
    increm: function () {
      this.pag++;
      /* Marketing events */
      let event;
      switch (this.pag) {
        case 1:
          event = "step_one";
          break;
        case 2:
          event = "step_two";
          break;
        case 3:
          event = "step_three";
          break;
        case 4:
          event = "step_four";
          break;
        case 5:
          event = "step_five";
          break;
        case 6:
          event = "step_six";
          break;
      }
      fbq("track", event);
      firebase.analytics().logEvent(event);
      gtag("event", event, {
        send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"
      });

      this.set_beneficiary();
      this.updateClientProfile();
    },
    decrem: function () {
      if (--this.pag === 0) {
        this.$router.push({
          path: "/"
        });
      }
    },
    finalCheck() {
      this.$forceUpdate();
      this.client.first_payment = this.$moment().format("YYYY-MM-DD")
      this.client.payday = this.$moment().format("DD")
      console.log(this.client)
      if (
          this.client.civil_status &&
          this.client.economic_activity &&
          this.client.first_payment &&
          this.client.payday
      ) {
        this.updateClientProfile();
        //this.set_beneficiary();
        this.$notify({
          text: "Información Guardada Correctamente",
          type: "success",
        });
        this.$router.push({
          path: "/signatureContract"
        });
        this.createPreContract();
      } else {
        this.dismissCountDown_end = this.dismissSecs;
      }
    },
    getClientProfile() {
      this.getAsesor();
      let url = this.$v.SERVER + this.$v.OPS.GET_PROFILE + "?id=" + this.client.id;
      this.$axios.get(url).then(response => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.client = response.data[this.$v.API.ROW];
          if (data.row.payday) this.payday = data.row.payday;
          let url2 = this.$v.SERVER + this.$v.OPS.GET_BENEFICIARY + "?id=" + this.client.id;
          this.$axios.get(url2).then(response2 => {
            let data2 = response2.data;
            if (data2[this.$v.API.SUCCESS]) {
              this.beneficiary = data2[this.$v.API.ROW];
              if (data.row.beneficiary.name)
                this.beneficiary.name = data.row.beneficiary.name;
              if (data.row.beneficiary.lastname_1)
                this.beneficiary.lastname_1 =
                    data.row.beneficiary.lastname_1;
              if (data.row.beneficiary.lastname_2)
                this.beneficiary.lastname_2 =
                    data.row.beneficiary.lastname_2;
              if (data.row.beneficiary.birthday)
                this.beneficiary.birthday =
                    data.row.beneficiary.birthday;
              if (data.row.beneficiary.gender)
                this.beneficiary.gender = data.row.beneficiary.gender;
              if (data.row.beneficiary.civil_status)
                this.beneficiary.civil_status =
                    data.row.beneficiary.civil_status;
              if (data.rows.beneficiary.relationship) {
                this.beneficiary.relationship = data.row.beneficiary.relationship
              }
              this.getRelationBeneficiary()
              console.log(this.relationBeneficiary.relationship)
            }
          });
        }
      });
    },
    updateClientProfile() {
      let url = this.$v.SERVER + this.$v.OPS.SET_CONTRACT_AND_ACCOUNTS;
      this.$axios.post(url, this.client).then(response => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
        }
      });
      url = this.$v.SERVER + this.$v.OPS.UPDATE + this.$v.TABLES.USERS;
      this.$axios.post(url, this.client).then(response => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
        }
      });
    },
    async set_beneficiary() {
      this.getAsesor();
      if (this.beneficiary) {
        if (this.client.id > 0) {
          this.beneficiary.user = this.client.id;
        } else {
          this.beneficiary.user = data[this.$v.API.ROW].id;
        }
        let url = this.$v.SERVER + this.$v.OPS.SET_BENEFICIARY;
        let response = await this.$axios.post(url, this.beneficiary);
        let data = response.data;
        if (!data[this.$v.API.SUCCESS]) {
          if (data[this.$v.API.REASON] === 10) {
            console.log(
                "Usuario duplicado, contacta con el administrador"
            );
          } else {
            console.log("Intenta nuevamente");
          }
        } else {
        }
      }
    },
    countDownChanged(dismissCountDown, type) {
      switch (type) {
        case 1:
          this.dismissCountDown_cp = dismissCountDown;
          break;
        case 2:
          this.dismissCountDown_curp = dismissCountDown;
          break;
        case 3:
          this.dismissCountDown_ine = dismissCountDown + 5;
          break;
        case 4:
          this.dismissCountDown_end = dismissCountDown - 4;
          break;
        case 5:
          this.dismissCountDown_doc_success = dismissCountDown - 4;
          break;
        case 6:
          this.dismissCountDown_doc_failure = dismissCountDown - 4;
          break;
        case 7:
          this.dismissCountDown_edad = dismissCountDown;
          break;
      }
    },
    setBeneficiaryGender(gender) {
      console.log(gender)
      this.beneficiary.gender = gender.substring(0, 1).toUpperCase();
      this.$forceUpdate();
    },
    createPreContract() {
      let url = V.SERVER + V.OPS.CREATE_PRE_CONTRACT
      this.$axios.get(url).then(response => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          console.log('Generando....')
        }
      });
    },
    beneficiaryCivilStatusSelected(civiStatus) {
      if (civiStatus == "Soltero") this.beneficiary.civil_status = "S";
      else if (civiStatus == "Casado")
        this.beneficiary.civil_status = "M";
      this.$forceUpdate();
    },
    getStatusBeneficiary(status) {
      if (status == "S" || status == "Soltero") {
        return "Soltero";
      } else if (status == "M" || status == "Casado") {
        return "Casado";
      }
      this.$forceUpdate();
    },
    setEconomicActivity(activity, activityTxt) {
      this.client.economic_activity = activity;
      this.client.economic_activity_desc = activityTxt;
      console.log(this.client.economic_activity_desc)
      console.log(this.client.economic_activity)
      this.$forceUpdate();
    },
    getEconomicActivity() {
      for (let i = 0; i < this.economicActivities.length; i++)
        if (
            this.economicActivities[i].id ===
            this.client.economic_activity
        )
          return this.economicActivities[i].activity;
      return null;
    },
    clientCivilStatusSelected(civiStatus) {
      if (civiStatus == "Soltero") this.client.civil_status = "S";
      else if (civiStatus == "Casado")
        this.client.civil_status = "M";
      this.$forceUpdate();
    },
    uploadFileComprobante(obj) {
      let fileType = obj.type;
      let file = obj.file;
      this.archivo = obj.file.name;
      let formData = new FormData();
      formData.append("file", file);
      let headers = {
        "user-id": this.client.id,
        "file-type": "ADDRESS"
      };
      this.$showLoader(true);
      this.dismissCountDown_doc_success = 0;
      this.dismissCountDown_doc_failure = 0;
      this.$axios
          .post(V.SERVER + V.OPS.UPLOAD_FILE, formData, {
            headers: headers
          })
          .then(response => {
            this.$showLoader(false);
            if (response.data.success) {
              this.fileType = null;
              this.files.ADDRESS = true;
              this.dismissCountDown_doc_success = this.dismissSecs;
            } else {
              this.dismissCountDown_doc_failure = this.dismissSecs;
            }
          })
          .catch(e => {
            console.log(e);
            this.$showLoader(false);
            alert("No se logró subir, intente nuevamente");
          });
    },
    uploadFileCurp(obj) {
      let fileType = obj.type;
      let file = obj.file;
      this.archivo = obj.file.name;
      let formData = new FormData();
      formData.append("file", file);
      let headers = {
        "user-id": this.client.id,
        "file-type": "CURP"
      };
      this.$showLoader(true);
      this.dismissCountDown_doc_success = 0;
      this.dismissCountDown_doc_failure = 0;
      this.$axios
          .post(V.SERVER + V.OPS.UPLOAD_FILE, formData, {
            headers: headers
          })
          .then(response => {
            this.$showLoader(false);
            if (response.data.success) {
              this.fileType = null;
              this.dismissCountDown_doc_success = this.dismissSecs;
            } else {
              this.dismissCountDown_doc_failure = this.dismissSecs;
            }
          })
          .catch(e => {
            console.log(e);
            this.$showLoader(false);
            alert("No se logró subir, intente nuevamente");
          });
    }
  },
  components: {
    flatPickr,
    BaseDropdown,
    UploadFilesForm,
    Dropbox
  }
};
</script>

<style>
#tit {
  font-weight: 300;
  font-size: large;
}

@media (min-width: 300px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }

  .calcularbtn {
    margin-left: 1.3rem;
  }

  .separar {
    margin-bottom: 1rem;
  }

  .sta {
    margin-top: -420px;
  }
}

@media (min-width: 370px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }

  .calcularbtn {
    margin-left: 2.8rem;
  }

  .separar {
    margin-bottom: 1rem;
  }

  .sta {
    margin-top: -420px;
  }
}

@media (min-width: 420px) {
  .calcular {
    margin: 5%;
    padding: 6%;
  }

  .calcularbtn {
    margin-left: 4.2rem;
  }

  .separar {
    margin-bottom: 1rem;
  }

  .sta {
    margin-top: -420px;
  }
}

@media (min-width: 768px) {
  .calcular {
    margin: 5%;
    padding: 3%;
    align-content: center;
  }

  .calcularbtn {
    margin-left: 13.5rem;
  }

  .sta {
    margin-top: -410px;
  }
}

@media (min-width: 992px) {
  .calcular {
    margin-left: 70%;
  }

  .calcularbtn {
    margin-left: 20.5rem;
  }
}

@media (min-width: 1200px) {
  .calcular {
    margin-left: 70%;
  }

  .calcularbtn {
    margin-left: 23rem;
  }
}
</style>
