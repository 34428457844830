<template>
    <div class="card shadow"
         :class="type === 'dark' ? 'bg-default': ''">
        <div class="card-header border-0"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <div class="row align-items-center">
                <div class="col">
                    <h3 class="mb-0" :class="type === 'dark' ? 'text-white': ''" v-bind:style="{cursor:pointer2}">
                        {{title}}
                    </h3>
                </div>
                <div class="col text-right">
                    <base-button v-if="showCreateContractButton" type="warning" size="sm" v-on:click="onCreateDBMenosContract">{{create_contract_button_text}}</base-button>
                    <base-button type="primary" size="sm" v-on:click="onNewItem">Nuevo</base-button>
                </div>
            </div>
        </div>

        <div class="table-responsive">
            <base-table class="table align-items-center table-flush"
                        :class="type === 'dark' ? 'table-dark': ''"
                        :thead-classes="type === 'dark' ? 'thead-dark': 'thead-light'"
                        tbody-classes="list"
                        :data="data">
                <template slot="columns">
                    <th v-bind:style="{cursor:pointer2}">Tipo</th>
                    <th v-bind:style="{cursor:pointer2}">Archivo</th>
                    <th v-bind:style="{cursor:pointer2}">Cargado el</th>
                </template>

                <template slot-scope="{row}" v-if="row.type !== 'PROFILE'">
                    <th scope="row">
                        <div class="media align-items-center" v-bind:style="{cursor:pointer2}">
                            <div class="media-body">
                                <span class="name mb-0 text-sm">{{getFileType(row.type)}}</span>
                            </div>
                        </div>
                    </th>
                    <td>
                        <div class="d-flex align-items-center">
                            <span><a :href="getFileUrl(row)" target="_blank">Ver</a></span>
                        </div>
                    </td>
                    <td v-bind:style="{cursor:pointer2}" v-if="row.created">{{formatDate(row.created)}}</td>
                    <td v-bind:style="{cursor:pointer2}" v-else><span class="text-danger">No cargado</span></td>

                </template>

            </base-table>
        </div>

        <div v-if="pagination.total > pagination.perPage" class="card-footer d-flex justify-content-end"
             :class="type === 'dark' ? 'bg-transparent': ''">
            <base-pagination
                    size="sm"
                    :total="pagination.total"
                    :per-page="pagination.perPage"
                    :value="pagination.currentPage"
                    v-on:pageSelected="pageSelected"/>
        </div>

    </div>
</template>
<script>
    export default {
        name: 'clients-table',
        components: {
        },
        props: {
            type: {
                type: String
            },
            title: String,
            data: Array,
            showCreateContractButton: Boolean,
            pagination: Object,
            create_contract_button_text: String,
            client: Object
        },
        data() {
            return {
                tableData: [],
                pointer: 'pointer',
                pointer2: 'default'
            }
        },
        computed: {
            banks() { return this.$store.state.banks }
        },
        async created() { },
        methods: {
            getFileType(type) {
                switch (type) {
                    case 'FILE': return "Otro"
                    case 'ID': return "Identificación frente"
                    case 'ID_B': return "Identificación reverso"
                    case 'CURP': return "CURP"
                    case 'ADDRESS': return "Comprobante domicilio"
                    case 'CREDIT_INFO': return "Reporte de crédito"
                    case 'CONTRACT': return "Contrato DBM firmado"
                    case 'FIN_CONTRACT': return "Contrato apertura de cuenta"
                    case 'FIN_MANIFEST': return "Carátula de apertura de cuenta"
                }
                return ""
            },
            pageSelected(page) {
                this.$emit("pageSelected", page);
            },
            getValidatedClass(validated) {
                if (validated) return "text-success"
                return "text-danger"
            },
            formatCurrency(value) {
                return this.$formatCurrency(value)
            },
            getBanks(array) {
                let banks = []
                for (let a=0 ; a<=array.length ; a++) {
                    for (let b = 0; b < this.banks.length; b++) {
                        if (this.banks[b].id === array[a]) {
                            banks.push(this.banks[b])
                            break
                        }
                    }
                }
                return banks
            },
            formatDate(date) {
//                return this.$moment(date).format("DD MMM, YYYY")
               return this.$formatDate(date)
            },
            onCreateDBMenosContract() {
                this.$emit("onCreateDBMenosContract")
            },
            onNewItem() {
                this.$emit("onNewItem")
            },
            uploadFile(obj) {
                let fileType = obj.type
                let file = obj.file
                let text = obj.text
                let formData = new FormData();
                formData.append("file", file);
                let headers = {
                    "client-id": this.client.id,
                    "file-type": fileType
                }
                this.$showLoader(true)
                this.$axios.post(this.$v.SERVER + this.$v.SELLER_OPS.UPLOAD_FILE, formData, {headers: headers}).then(response => {
                    this.$showLoader(false)
                    if (response.data.success) {
                        this.$notify({
                            text: text + " cargado exitosamente",
                            type: 'success'
                        })
                    } else {
                        this.$notify({
                            text: "No se logró cargar " + text,
                            type: 'warn'
                        })
                    }
                }).catch(e => {
                    console.log(e)
                    this.$showLoader(false)
                    alert("No se logró subir, intente nuevamente")
                })
            },
            getFileUrl(userFile) {
                let session = JSON.parse(localStorage.getItem(this.$v.STORE_KEYS.SESSION))
                if (session) {
                    return this.$v.SERVER + this.$v.OPS.GET_USER_FILE + "?user=" + this.client.id + "&filename=" +
                        userFile.filename + "&redirect=true" + "&session=" + session[this.$v.SESSION]
                }
            },
        }
    }
</script>
<style>
</style>
