<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0" style="background-color: #ffff">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container" style="margin: -10rem">
          <div class="col px-0">
            <div class="row"></div>
          </div>
        </div>
      </section>
      <section
          v-if="reportListo"
          class="section section-lg pt-lg-0 mt--300 subir"
      >
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <br/>
              <div class="row row-grid justify-content-center">
                <div v-if="bandera && !notCredit" class="col-lg-12 text-center">
                  <b-card
                      title="Estamos buscando tu información"
                      img-top
                      style="max-width: 90rem; max-heigth: 20rem"
                  >
                    <b-card-text>
                      El proceso de consulta de reporte de crédito puede tardar
                      unos minutos en aparecer, te enviaremos una notificación a
                      tu correo cuando esté disponible.
                    </b-card-text>
                  </b-card>
                </div>
                <div
                    v-if="error && !bandera && !notCredit"
                    class="col-lg-12 text-center"
                >
                  <b-card
                      title="No podemos continuar"
                      img-top
                      style="max-width: 70rem; max-heigth: 20rem"
                  >
                    <b-card-text
                    >¡UPS, parece que algún dato es incorrecto por favor
                      contáctate con tu asesor para terminar el
                      proceso!
                    </b-card-text
                    >
                  </b-card>
                </div>
                <div v-if="notCredit && !bandera" class="col-lg-12 text-center">
                  <b-card
                      img-top
                      style="max-width: 80rem; max-heigth: 20rem"
                  >
                    <img src="img/dbm/No_Podemos_Continuar.jpg"/>
                    <!--
                      <b-card-text>
                        Ups, por el momento no identificamos ninguna cuenta que
                        podamos ingresar al programa, pronto alguien del equipo se
                        contactará contigo Hola.
                        <br />
                        <small
                          >Te invitamos a seguirnos en nuestras redes sociales
                          para más información.</small
                        >
                      </b-card-text>
                      !-->
                  </b-card>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <div v-if="!amountClientMDI">
            <div class="row d-flex justify-content-center">
              <div
                  class="col-3 d-flex align-items-center"
                  style="max-width: 300px !important"
                  v-if="minBoton"
              >
                <card
                    v-if="reportListo && !notCredit"
                    style="
                    margin-top: 1rem;
                    border-radius: 2rem;
                    border: none;
                  "
                >
                  <div class="container">
                    <div class="row justify-content-center text-center">
                      <div class="col-lg-12">
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 1.5rem;
                          "
                        >

                        </h1>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
              <div
                  class="col-3 d-flex align-items-center"
                  style="max-width: 300px !important"
                  v-if="!minBoton"
              >
                <card
                    v-if="reportListo && !notCredit"
                    style="
                    margin-top: 1rem;
                    border-radius: 2rem;
                    border: 1px solid gray;
                  "
                >
                  <div class="container" v-on:click="deleteOnePeriod()">
                    <div class="row justify-content-center text-center">
                      <div class="col-lg-12">
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 1.5rem;
                          "
                        >
                          {{ calculatorDebtsBefore.period }} meses
                        </h1>
                      </div>
                      <div class="col-lg-12">
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 1.5rem;
                          "
                        >
                          {{ formatCurrency(calculatorDebtsBefore.monthlyFee) }}
                        </h1>
                      </div>
                      <i class="ni ni-bold-left"></i>
                    </div>
                  </div>
                </card>
              </div>
              <div
                  class="col-6 d-flex align-items-center"
                  style="max-width: 700px !important"
              >
                <card
                    v-if="reportListo && !notCredit"
                    style="
                    margin-top: 1rem;
                    border-radius: 2rem;
                    border: 1px solid gray;
                  "
                >
                  <div
                      class="container shape-container d-flex"
                      style="margin-top: 10px"
                  >
                    <div class="col px-0">
                      <div class="row">
                        <div class="col-lg-12">
                          <h1
                              class="display-1 text-center"
                              id="titleprogram"
                              style="color: #2a3d9a"
                          >
                            Programa
                            <img
                                src="img/brand/DB Menos logo azul 2020_.png"
                                style="width: 40%"
                            />
                            <br/>
                            <b style="font-size: 2.5rem"
                            >¡Vive libre de deudas!</b
                            >
                          </h1>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="container" style="max-width: 1600px !important">
                    <!--
              <div
                class="row justify-content-center"
              >
                <div class="col-lg-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <card class="border-0">
                        <div class="row justify-content-center">
                          <div class="col text-center">
                            <h1
                              class="h1 font-weight-bold mb-0 text-success"
                              style="font-size: 2rem"
                            >
                              {{ periodOptimal }} meses
                            </h1>
                            <h5
                              class="
                                card-title
                                text-uppercase text-muted
                                mb-0
                                margin-blue-h6
                              "
                              style="font-size: 1rem; margin-top: 0.2rem"
                            >
                              Duración del Programa Recomendado
                            </h5>
                            <br />
                            <base-slider
                              :range="{ min: minSlider, max: maxSlider }"
                              :options="{ step: 1 }"
                              v-model.number="period"
                              v-on:input="m_ReturnDiscountNew()"
                            ></base-slider>
                            !-->
                    <!--
                            <h6 class="text-primary text-center">
                              <h1
                                class="text-primary"
                                style="font-size: 1.5rem"
                              >
                                {{ period }} meses
                              </h1>
                              <h4
                                class="
                                  text-primary
                                  card-title
                                  text-uppercase text-muted
                                  margin-blue-h6
                                "
                                style="font-size: 1rem; margin-top: 0.2rem"
                              >
                                Duración del Programa
                              </h4>
                            </h6>
                          </div>
                        </div>
                      </card>
                    </div>
                  </div>
                </div>
              </div>

                            !-->
                    <div
                        class="row justify-content-center text-center"
                        style="margin-bottom: 40px"
                    >
                      <div class="col-lg-12">
                        <h4
                            style="
                            color: #5f6062;
                            font-family: HomePage-Book;
                            margin-bottom: 20px;
                          "
                        >
                          Ahorra
                        </h4>
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 2.5rem;
                          "
                        >
                          {{ formatCurrency(calculatorDebts.monthlyFee) }}
                        </h1>
                      </div>
                      <div class="col-lg-12">
                        <h4
                            style="
                            color: #5f6062;
                            font-family: HomePage-Book;
                            margin-bottom: 20px;
                          "
                        >
                          Durante
                        </h4>
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 2.5rem;
                          "
                        >
                          {{ period }} meses
                        </h1>
                      </div>
                      <!--
                <div class="col-lg-3" style="margin-top: 30px">
                  <h3 class="h1 font-weight-bold mb-0" style="font-size: 2rem">
                    {{
                      formatDecPost(calculatorDebts.discount)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}%
                  </h3>
                  <h4
                    class="
                      card-title
                      text-uppercase text-muted
                      mb-0
                      margin-yellow-h6
                    "
                    style="margin-top: 1rem"
                  >
                    Descuento
                  </h4>
                  <br />
                </div>
                !-->

                      <!--
                <div class="col-lg-3" style="margin-left: 5rem">
                  <h6
                    class="
                      card-title
                      text-uppercase text-muted
                      mb-0
                      margin-yellow-h6
                    "
                    style="margin-top: 1rem"
                  >
                    Deuda total
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    formatCurrency(calculatorDebts.totalDebt)
                  }}</span>

                  <h6
                    class="
                      card-title
                      text-uppercase text-muted
                      mb-0
                      margin-blue-h6
                    "
                  >
                    Pagarás*
                  </h6>
                  <span class="h2 font-weight-bold mb-0">
                    {{ formatCurrency(calculatorDebts.willPay) }}</span
                  >
                </div>
                <div class="col-lg-4">
                  <div
                    class="d-flex justify-content-between margin-yellow"
                    style="
                      width: 70%;
                      height: 2rem;
                      padding: 5px;
                      background: #ffd527;
                      margin-top: 2.5rem;
                      color: black;
                      text-align: left;
                    "
                  ></div>
                  <div
                    class="d-flex justify-content-between margin-blue"
                    style="
                      width: 10%;
                      height: 2rem;
                      padding: 5px;
                      background: #3953d3;
                      color: black;
                      text-align: left;
                    "
                    :style="{
                      width:
                        calculatorDebts.totalDebt > 0
                          ? Math.ceil(
                              64 /
                                (calculatorDebts.totalDebt /
                                  calculatorDebts.willPay)
                            ) + '%'
                          : '0%',
                    }"
                  ></div>
                </div>
                !-->
                    </div>
                  </div>
                  <!--
            <base-button
              block
              v-if="reportListo && !notCredit"
              v-on:click="back()"
              class="mt-4"
            >
              <b>Regresar</b>
            </base-button>
            !-->
                </card>
              </div>
              <div
                  class="col-3 d-flex align-items-center"
                  style="max-width: 300px !important"
                  v-if="!maxBoton"
              >
                <card
                    v-if="reportListo && !notCredit"
                    style="
                    margin-top: 1rem;
                    border-radius: 2rem;
                    border: 1px solid gray;
                  "
                >
                  <div class="container" v-on:click="addOnePeriod()">
                    <div class="row justify-content-center text-center">
                      <div class="col-lg-12">
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 1.5rem;
                          "
                        >
                          {{ calculatorDebtsAfter.period }} meses
                        </h1>
                      </div>
                      <div class="col-lg-12">
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 1.5rem;
                          "
                        >
                          {{ formatCurrency(calculatorDebtsAfter.monthlyFee) }}
                        </h1>
                      </div>
                      <i class="ni ni-bold-right"></i>
                    </div>
                  </div>
                </card>
              </div>
              <div
                  class="col-3 d-flex align-items-center"
                  style="max-width: 300px !important"
                  v-if="maxBoton"
              >
                <card
                    v-if="reportListo && !notCredit"
                    style="
                    margin-top: 1rem;
                    border-radius: 2rem;
                    border: none;
                  "
                >
                  <div class="container">
                    <div class="row justify-content-center text-center">
                      <div class="col-lg-12">
                        <h1
                            class="h1 font-weight-bold mb-0"
                            style="
                            color: #2a3d9a;
                            font-family: Gotham-Medium;
                            font-size: 1.5rem;
                          "
                        >
                        </h1>
                      </div>
                    </div>
                  </div>
                </card>
              </div>
            </div>
          </div>
          <div class="container d-flex align-items-center" v-if="!amountClientMDI">
            <base-button
                block
                v-if="reportListo && !notCredit"
                v-on:click="startProgram"
                style="
                background-color: #2a3d9a;
                border-color: #2a3d9a;
                color: white;
                width:100%;
                margin-bottom: 20px;
                border-radius: 12rem;
              "
                class="mt-4 speech-bubble-want text-center"
            >
              <b style="font-size: 3.5rem">¡LO QUIERO!</b>
            </base-button>
          </div>
          <div class="container text-center" v-if="!amountClientMDI">
            <div class="row">
              <div
                  class="col card-debts"
                  v-for="(debt, id) in calculatorDebts.debts"
                  :key="id"
              >
                <div v-if="reportListo">
                  <b-card
                      class="tam mb-4"
                      shadow
                      body-classes="py-5"
                      style="
                      margin-top: 5px;
                      border-radius: 1rem;
                      height: auto;
                      border: 1px solid gray;
                    "
                  >
                    <div class="row">
                      <div class="media-body ml-2" style="margin-top: 1rem">
                        <h4 style="color: #2a3d9a" id="labelBanks">
                          {{ debt.bank }}
                        </h4>
                      </div>
                      <div class="col-md-3">
                        <div class="g-item" style="height: 80%; width: 100%">
                          <img
                              class="img-responsive"
                              v-bind:src="getBank(debt.bankid).url"
                              style="
                              height: 100%;
                              width: 100%;
                              object-fit: contain;
                            "
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row text-center" style="margin-top: 10px">
                      <div class="col-6">
                        <div
                            class="g-item"
                            style="margin-top: 0.5rem; height: 100%; width: 100%"
                        >
                          <h4
                              id="h6amount"
                              style="
                              color: #2a3d9a;
                              font-family: Gotham-Medium;
                              font-size: 1.2rem;
                              margin-bottom: -5px;
                            "
                          >
                            {{
                              formatDecPost(
                                  (1 - debt.willPay / debt.amount) * 100
                              )
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}%
                          </h4>
                          <label
                              for="h6amount"
                              style="
                              width: 100%;
                              color: #5f6062;
                              font-family: HomePage-Book;
                            "
                          >Descuento</label
                          >
                        </div>
                      </div>
                      <div class="col-6">
                        <div
                            class="g-item"
                            style="margin-top: 0.5rem; height: 100%; width: 100%"
                        >
                          <h4
                              id="h6amount"
                              style="
                              color: #2a3d9a;
                              font-family: Gotham-Medium;
                              font-size: 1.2rem;
                              margin-bottom: -5px;
                            "
                          >
                            $
                            {{
                              Number(debt.amount)
                                  .toFixed(2)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                          </h4>
                          <label
                              for="h6amount"
                              style="
                              width: 100%;
                              color: #5f6062;
                              font-family: HomePage-Book;
                            "
                          >Adeudado</label
                          >
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <div style="margin-top: 1rem">
                        <h3
                            class="display-2"
                            style="
                            color: #5f6062;
                            font-size: 1.2rem;
                            font-family: HomePage-Book;
                          "
                        >
                          Paga
                          <b
                              style="
                              color: #2a3d9a;
                              font-size: 1.5rem;
                              font-family: Gotham-Medium;
                            "
                              class="text-black"
                          >$
                            {{
                              formatDecPost(debt.willPay)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}</b
                          >
                        </h3>
                      </div>
                      <div style="margin-top: 1rem">
                        <h3
                            class="display-2"
                            style="
                            color: #5f6062;
                            font-size: 1.2rem;
                            font-family: HomePage-Book;
                          "
                        >
                          Liquida el
                          <b
                              style="
                              color: #2a3d9a;
                              font-size: 1.5rem;
                              font-family: Gotham-Medium;
                            "
                              class="text-black"
                          >{{
                              $moment()
                                  .add(debt.willPayOnMonth, "M")
                                  .format("YYYY-MM-DD")
                            }}</b
                          >
                        </h3>
                      </div>
                      <div></div>
                    </div>
                  </b-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
          v-if="!reportListo"
          class="section section-lg pt-lg-0 mt--300 subir"
          style="background-color: #ffff"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12" style="margin-top: 3rem">
              <div class="row row-grid justify-content-center">

                <div v-if="amountClientMDI" class=" row row-grid justify-content-center">
                  <b-card
                      img-top
                      style="max-width: 80rem; max-heigth: 20rem"
                  >
                  <div class="d-flex justify-content-center">
                    <img src="img/dbm/No_Podemos_Continuar.jpg"/>
                  </div>
                    <!-- <p>TEST1</p> -->
                    <!--
                      <b-card-text>
                        Ups, por el momento no identificamos ninguna cuenta que
                        podamos ingresar al programa, pronto alguien del equipo se
                        contactará contigo Hola.
                        <br />
                        <small
                          >Te invitamos a seguirnos en nuestras redes sociales
                          para más información.</small
                        >
                      </b-card-text>
                      !-->
                  </b-card>
                </div>
                <!-- <p>TEST1 COMPONENTE ACTUAL</p> -->
                <div v-if="bandera && !notCredit" class="col-lg-12 text-center row row-grid justify-content-center">
                  <b-card
                      title="Estamos buscando tu información"
                      img-top
                      style="max-width: 70rem; max-heigth: 20rem"
                  >
                    <b-card-text>
                      El proceso de consulta de reporte de crédito puede tardar
                      unos minutos en aparecer, te enviaremos una notificación a
                      tu correo cuando esté disponible.
                    </b-card-text>
                  </b-card>
                </div>
                <div
                    v-if="error && !bandera && !notCredit"
                    class="col-lg-12 text-center"
                >
                  <b-card
                      title="No podemos continuar"
                      img-top
                      style="max-width: 70rem; max-heigth: 20rem"
                  >
                    <b-card-text
                    >¡UPS, parece que algún dato es incorrecto por favor en
                      unos momentos nos pondremos en contacto contigo para
                      verificar tus datos!
                    </b-card-text
                    >
                  </b-card>
                </div>
                <div v-if="notCredit && !bandera" class="col-lg-12 text-center">
                  <b-card
                      img-top
                      style="max-width: 80rem; max-heigth: 20rem"
                  >
                    <img src="img/dbm/No_Podemos_Continuar.jpg"/>
                    <!--
                      <b-card-text>
                        Ups, por el momento no identificamos ninguna cuenta que
                        podamos ingresar al programa, pronto alguien del equipo se
                        contactará contigo Hola.
                        <br />
                        <small
                          >Te invitamos a seguirnos en nuestras redes sociales
                          para más información.</small
                        >
                      </b-card-text>
                      !-->
                  </b-card>
                </div>

                <div v-if="amountNotDebts && !bandera" class="col-lg-12 text-center">
                  <b-card
                      img-top
                      style="max-width: 80rem; max-heigth: 20rem"
                  >
                    <img src="img/dbm/No_Podemos_Continuar.jpg"/>
                    <!--
                      <b-card-text>
                        Ups, por el momento no identificamos ninguna cuenta que
                        podamos ingresar al programa, pronto alguien del equipo se
                        contactará contigo Hola.
                        <br />
                        <small
                          >Te invitamos a seguirnos en nuestras redes sociales
                          para más información.</small
                        >
                      </b-card-text>
                      !-->
                  </b-card>
                </div>
                <!--
                <div
                  class="col-lg-4 card-debts"
                  v-for="(debt, indexdeb) in debts"
                  :key="indexdeb"
                >
                  <div v-if="debt.amount > 0 && !reportListo">
                    <b-card
                      class="border-0 tam mb-4"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 5px; border-radius: 1rem; height: auto"
                    >
                      <div class="row">
                        <div class="media-body ml-2" style="margin-top: 1rem">
                          <h4 style="color: #2a3d9a;" id="labelBanks">
                            {{ debt.bank }}
                          </h4>
                        </div>
                        <div class="col-md-3">
                          <div
                            class="g-item"
                            style="
                              height: 100%;
                              width: 100%;
                            "
                          >
                            <img
                              class="img-responsive"
                              v-bind:src="getBank(debt.bankid).url"
                              style="
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-center" style="margin-top: -20px">
                        <br />
                        <label for="h6amount" style="width: 100%; color: #5f6062;"
                          >Deuda total</label
                        >
                        <h4 id="h6amount" style="color: #2a3d9a;">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h4>
                        <h3
                        class="display-2"
                        style="
                          color: #5f6062;
                          font-size: 1.2rem;
                        "
                      >
                        Cuenta
                        <b
                          style="color: #2a3d9a; font-size: 1.3rem"
                          class="text-black"
                          >{{
                            debt.account.substring(
                              debt.account.length - 4,
                              debt.account.length
                            )
                          }}</b
                        >
                      </h3>
                      <h3
                        class="display-2"
                        style="
                          color: #5f6062;
                          font-size: 1.2rem;
                        "
                      >
                        Último Pago
                        <b
                          style="color: #2a3d9a; font-size: 1.3rem;"
                          class="text-black"
                          >{{ debt.last_bank_payment_date }}</b
                        >
                      </h3>
                        <div class="row" style="margin-top:1rem;">
                          <base-button
                            block
                            v-if="debt.addProgram"
                            v-on:click="m_spliceDebt(indexdeb)"
                            type="primary"
                            id="buttonDeshabilitar"
                            style="background-color:#2a3d9a; border:none;"
                            class="mt-1"
                            >Deshabilitar Cuenta</base-button
                          >
                          <base-button
                            block
                            v-else
                            v-on:click="m_pushDebt(indexdeb)"
                            type="secondary"
                            id="buttonDeshabilitar"
                            class="mt-1"
                            >Habilitar Cuenta</base-button
                          >
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
                !-->
              </div>
              <!--
              <div>
                <base-button
                  block
                  v-if="!bandera && !notCredit && !reportListo"
                  v-on:click="m_calculateDiscount()"
                  style="
                    background-color: #ffd527;
                    border-color: #ffd527;
                    color: #3953d3;
                  "
                  class="mt-4"
                >
                  <b>Calcula tu descuento</b>
                </base-button>
              </div>
              !-->
            </div>
          </div>
          <br/>
          <!--
          <div class="row justify-content-center">
            <a :href="urlAsesor" target="_blank" class="nav-link navbar-brand">
              <base-button block v-if="!reportListo || notCredit || error">
                <b>Contacta a tu asesor</b>
              </base-button>
            </a>
          </div>
          !-->
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/messaging';
import BaseDropdown from "@/components/BaseDropdown";
import Vue from "vue";
import modal from "@/components/Modal";
import {V} from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import VueSimpleAlert from "vue-simple-alert";

Vue.use(VueFacebookPixel);
Vue.use(VueSimpleAlert);
export default {
  name: "home",
  components: {
    BaseDropdown,
    modal,
  },

  data() {
    return {
      V: V,
      listsIsLoaded: false,
      lists: [],
      debts: [
        {
          bankid: 0,
          bank: "",
          amount: "",
          account: "",
          last_bank_payment_date: "",
          addProgram: false,
          digitAccount: "",
          willPay: "",
          willPayOnMonth: "",
        },
      ],
      accounts: [
        {
          account: "",
          addProgram: false,
          amount: "",
          bank: "",
          bankid: "",
          discount: "",
          due_months: "",
          last_bank_payment_date: "",
          m: "",
          willPayOnMonth: "",
        },
      ],
      calculatorDebts: [
        {
          debts: [
            {
              account: "",
              addProgram: false,
              amount: "",
              bank: "",
              bankid: "",
              discount: "",
              due_months: "",
              last_bank_payment_date: "",
              m: "",
              willPayOnMonth: "",
            },
          ],
          monthlyFee: "",
          period: "",
          savings: "",
          totalDebt: "",
          willPay: "",
        },
      ],
      calculatorDebtsAfter: [
        {
          debts: [
            {
              account: "",
              addProgram: false,
              amount: "",
              bank: "",
              bankid: "",
              discount: "",
              due_months: "",
              last_bank_payment_date: "",
              m: "",
              willPayOnMonth: "",
            },
          ],
          monthlyFee: "",
          period: "",
          savings: "",
          totalDebt: "",
          willPay: "",
        },
      ],
      calculatorDebtsBefore: [
        {
          debts: [
            {
              account: "",
              addProgram: false,
              amount: "",
              bank: "",
              bankid: "",
              discount: "",
              due_months: "",
              last_bank_payment_date: "",
              m: "",
              willPayOnMonth: "",
            },
          ],
          monthlyFee: "",
          period: "",
          savings: "",
          totalDebt: "",
          willPay: "",
        },
      ],
      discount: {
        debts: [
          {
            addProgram: false,
          },
        ],
        addProgram: false,
        totalDebt: 0,
        savings: 0,
        monthlyFee: 0,
        discount: 0,
        o_progress: 0,
      },
      cambiardebts: false,
      reportListo: false,
      notCredit: false,
      amountNotDebts: false,
      bandera: true,
      error: false,
      fullPage: true,
      disableCuenta: false,
      period: 12,
      periodStart: null,
      overf: "hidden",
      banksmodal: false,
      digitAccount: "",
      dueMonthsBank: "",
      sliderRange: "",
      maxSlider: "",
      minSlider: "",
      calcData: {
        period: 18,
      },
      urlAsesor: "",
      phone: "",
      jsonCalculator: "",
      maxBoton: false,
      minBoton: false,
      periodAfter: 0,
      periodBefore: 0,
      amountTotal: 0,
      amountClientMDI: false,
    };
  },

  created() {
    firebase.analytics().logEvent("starting_leads_2");
    fbq("track", "starting_leads_2");
    gtag("event", "starting_leads_2", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });

    fbq("track", "calculator_automated");
    firebase.analytics().logEvent("calculator_automated");
    gtag("event", "calculator_automated", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });
  },
  mounted() {
    this.doAjax();
    this.$forceUpdate();
    this.getAsesor();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    banks() {
      return this.$store.state.banks;
    },
  },
  methods: {
    back() {
      this.reportListo = false;
    },
    calcDate(date2) {
      let dateCorrect = new Date(this.interprateDate(date2));
      var diff = Math.floor(new Date() - dateCorrect.getTime());
      var day = 1000 * 60 * 60 * 24;

      var days = Math.floor(diff / day);
      var months = Math.floor(days / 31);
      var years = Math.floor(months / 12);

      var message = months;

      return message;
    },
    m_ReturnDiscountNew() {
      this.discount = [];
      this.accounts = [];
      this.sliderRange = [];
      this.periodOptimal = this.periodOptimal;
      this.period = this.period;
      this.calculatorDebts = this.calculatorDebts;
      this.reportListo = true;
      for (let i = 0; i < this.calculatorDebts.length; i++) {
        for (let p = 0; p < this.calculatorDebts.length; p++) {
          if (this.period === this.calculatorDebts[p].period) {
            this.calculatorDebts.debts = this.calculatorDebts[p].debts;
            this.calculatorDebts.monthlyFee =
                this.calculatorDebts[p].monthlyFee;
            this.calculatorDebts.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebts.discount = this.calculatorDebts[p].discount;
          }
          if (this.periodBefore === this.calculatorDebts[p].period) {
            this.calculatorDebtsBefore.period = this.calculatorDebts[p].period;
            this.calculatorDebtsBefore.debts = this.calculatorDebts[p].debts;
            this.calculatorDebtsBefore.monthlyFee = this.calculatorDebts[p].monthlyFee;
            this.calculatorDebtsBefore.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebtsBefore.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebtsBefore.discount = this.calculatorDebts[p].discount;
          }
          if (this.periodAfter === this.calculatorDebts[p].period) {
            this.calculatorDebtsAfter.debts = this.calculatorDebts[p].debts;
            this.calculatorDebtsAfter.period = this.calculatorDebts[p].period;
            this.calculatorDebtsAfter.monthlyFee = this.calculatorDebts[p].monthlyFee;
            this.calculatorDebtsAfter.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebtsAfter.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebtsAfter.discount = this.calculatorDebts[p].discount;
          }
        }
      }
      let myArray = this.sliderRange;
      this.maxSlider = Math.max.apply(Math, myArray);
      this.minSlider = Math.min.apply(Math, myArray);

      this.$forceUpdate();
    },
    startProgram() {
      this.amountTotal = 0;
      console.log(this.discount.debts);
      let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATOR_CR;
      for (let p = 0; p < this.calculatorDebts.length; p++) {
        if (this.period === this.calculatorDebts[p].period) {
          this.amountTotal = this.calculatorDebts[p].totalDebt
          console.log(this.calculatorDebts[p].totalDebt)
          if (this.calculatorDebts[p].totalDebt < 10000) {
            this.$notify({
              text: "Se requiere una deuda mayor a $10,000 para continuar",
              type: "error",
            });
          } else {
            this.jsonCalculator = JSON.parse(
                JSON.stringify(this.calculatorDebts[p])
            );
          }
        }
      }
      this.$axios.post(url, this.jsonCalculator).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          firebase
              .analytics()
              .logEvent("calculator_complete", this.jsonCalculator);

          gtag("event", "starting_leads_2", {
            send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
          });
          fbq("track", "calcuator_complete");
          console.log(this.amountTotal)
          if (this.amountTotal < 10000) {
            console.log('No se puede continuar')
          } else {
            this.$router.push({
              path: "/regisB",
            });
          }
        }
      });
    },
    formatCurrency(value) {
      return this.$formatCurrency(value);
    },
    formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
    async m_calculateDiscount() {
      this.$showLoader(true);
      let _debts = JSON.parse(JSON.stringify(this.debts));
      let _debts2 = _debts.filter((_debts) => _debts.addProgram);
      for (let d = 0; d < _debts2.length; d++) {
        _debts2[d].amount = Number(_debts2[d].amount);
        delete _debts2[d].edit;
      }
      let user = this.user;
      if (user === undefined || user === null) {
        user = {
          name: "",
          lastname: "",
          id: 1,
        };
      }

      let period = this.period;
      let _data = {
        name: user.name,
        lastname: user.lastname_1,
        userid: user.id,
        period: period,
        debts: _debts2,
      };
      if (_data.name === undefined) _data.name = "";
      if (_data.lastname === undefined) _data.lastname = "";

      await this.$axios
          .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATION, this.debts)
          .then(
              (res) => {
                this.$showLoader(false);
                let data = res.data;
                this.discount = [];
                this.calculatorDebts = [];
                this.accounts = [];
                this.sliderRange = [];
                if (data.rows == 'El Monto Total de Deuda del cliente debe ser mayor a 10k') {
                  this.amountClientMDI = true;
                } else {
                  this.amountClientMDI = false;
                  this.period = data.rows.optimalperiod;
                  this.periodOptimal = data.rows.optimalperiod;
                  if (data.success) {
                    this.reportListo = true;

                    for (let i = 0; i < data.rows.programs.length; i++) {
                      this.sliderRange.push(data.rows.programs[i].period);
                      data.rows.programs[i].addProgram = true;
                      this.calculatorDebts.push(data.rows.programs[i]);
                      this.accounts = data.rows.programs[i].debts;
                      this.accounts.sort(function (a, b) {
                        return b.amount - a.amount;
                      });
                      this.periodAfter = this.period + 1;
                      this.periodBefore = this.period - 1;
                      for (let p = 0; p < this.calculatorDebts.length; p++) {
                        if (this.period === this.calculatorDebts[p].period) {
                          this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                          this.calculatorDebts.monthlyFee =
                              this.calculatorDebts[p].monthlyFee;
                          this.calculatorDebts.willPay =
                              this.calculatorDebts[p].willPay;
                          this.calculatorDebts.totalDebt =
                              this.calculatorDebts[p].totalDebt;
                          this.calculatorDebts.discount =
                              this.calculatorDebts[p].discount;
                        }
                        if (this.periodBefore === this.calculatorDebts[p].period) {
                          this.calculatorDebtsBefore.period = this.calculatorDebts[p].period;
                          this.calculatorDebtsBefore.debts = this.calculatorDebts[p].debts;
                          this.calculatorDebtsBefore.monthlyFee = this.calculatorDebts[p].monthlyFee;
                          this.calculatorDebtsBefore.willPay = this.calculatorDebts[p].willPay;
                          this.calculatorDebtsBefore.totalDebt = this.calculatorDebts[p].totalDebt;
                          this.calculatorDebtsBefore.discount = this.calculatorDebts[p].discount;
                        }
                        if (this.periodAfter === this.calculatorDebts[p].period) {
                          this.calculatorDebtsAfter.debts = this.calculatorDebts[p].debts;
                          this.calculatorDebtsAfter.period = this.calculatorDebts[p].period;
                          this.calculatorDebtsAfter.monthlyFee = this.calculatorDebts[p].monthlyFee;
                          this.calculatorDebtsAfter.willPay = this.calculatorDebts[p].willPay;
                          this.calculatorDebtsAfter.totalDebt = this.calculatorDebts[p].totalDebt;
                          this.calculatorDebtsAfter.discount = this.calculatorDebts[p].discount;
                        }
                      }
                    }

                    if (this.calculatorDebts.totalDebt < 10000) {
                      this.amountNotDebts = true;
                      this.bandera = false;
                      this.reportListo = false;
                      this.amountClientMDI = false;
                    }
                    let myArray = this.sliderRange;
                    this.maxSlider = Math.max.apply(Math, myArray);
                    this.minSlider = Math.min.apply(Math, myArray);
                    if (this.maxSlider == this.period) {
                      this.maxBoton = true;
                    } else if (this.minSlider == this.period) {
                      this.minBoton = true;
                    }
                    this.$forceUpdate();
                  }
                }
              }
          );
    },
    m_ReturnDiscountNew() {
      this.discount = [];
      this.accounts = [];
      this.sliderRange = [];
      this.periodOptimal = this.periodOptimal;
      this.period = this.period;
      this.calculatorDebts = this.calculatorDebts;
      this.reportListo = true;
      for (let i = 0; i < this.calculatorDebts.length; i++) {
        for (let p = 0; p < this.calculatorDebts.length; p++) {
          if (this.period === this.calculatorDebts[p].period) {
            this.calculatorDebts.debts = this.calculatorDebts[p].debts;
            this.calculatorDebts.monthlyFee =
                this.calculatorDebts[p].monthlyFee;
            this.calculatorDebts.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebts.discount = this.calculatorDebts[p].discount;
          }
          if (this.periodBefore === this.calculatorDebts[p].period) {
            this.calculatorDebtsBefore.period = this.calculatorDebts[p].period;
            this.calculatorDebtsBefore.debts = this.calculatorDebts[p].debts;
            this.calculatorDebtsBefore.monthlyFee = this.calculatorDebts[p].monthlyFee;
            this.calculatorDebtsBefore.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebtsBefore.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebtsBefore.discount = this.calculatorDebts[p].discount;
          }
          if (this.periodAfter === this.calculatorDebts[p].period) {
            this.calculatorDebtsAfter.debts = this.calculatorDebts[p].debts;
            this.calculatorDebtsAfter.period = this.calculatorDebts[p].period;
            this.calculatorDebtsAfter.monthlyFee = this.calculatorDebts[p].monthlyFee;
            this.calculatorDebtsAfter.willPay = this.calculatorDebts[p].willPay;
            this.calculatorDebtsAfter.totalDebt = this.calculatorDebts[p].totalDebt;
            this.calculatorDebtsAfter.discount = this.calculatorDebts[p].discount;
          }
        }
      }
      if (this.maxSlider == this.period) {
        this.maxBoton = true;
      } else {
        this.maxBoton = false;
      }

      if (this.minSlider == this.period) {
        this.minBoton = true;
      } else {
        this.minBoton = false;
      }
      this.$forceUpdate();
    },
    deleteOnePeriod() {
      if (this.period == this.minSlider) {
        this.minBoton = true;
        console.log("Ya es todo");
      } else {
        this.periodAfter = this.period--;
        this.periodBefore = this.period - 1;
        this.m_ReturnDiscountNew();
      }
    },
    addOnePeriod() {
      if (this.period == this.maxSlider) {
        this.maxBoton = true;
        console.log("Ya es todo");
      } else {
        this.periodBefore = this.period++;
        this.periodAfter = this.period + 1;
        this.m_ReturnDiscountNew();
      }
    },
    getAsesor() {
      let url = this.$v.SERVER + this.$v.OPS.GET_CONSULTANT_INFO;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.phone = data.row.phone;
          this.urlAsesor = "https://wa.me/52" + this.phone;
          //window.open(this.urlAsesor);
        }
      });
    },
    m_findInDiscount(account) {
      for (let i = 0; i < this.discount.debts.length; i++)
        if (this.discount.debts[i].account === account)
          return this.discount.debts[i];
      return null;
    },
    m_spliceDebt(index) {
      let count = 0;
      for (let i = 0; i < this.debts.length; i++)
        if (this.debts[i].addProgram) count += 1;
      if (count > 1) {
        this.debts[index].addProgram = false;
        this.$showLoader(true);
        setTimeout(() => {
          this.$showLoader(false);
        }, 500);
      } else if (this.discount.debts.length <= 1) {
        this.$notify({
          text: "Se requiere al menos una cuenta para continuar",
          type: "info",
        });
      }
    },
    m_pushDebt(index) {
      this.debts[index].addProgram = true;
      this.$showLoader(true);
      setTimeout(() => {
        this.$showLoader(false);
      }, 500);
    },
    getBank(id) {
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    getAccount() {
      for (let i = 0; i < this.debts.length; i++) {
        this.digitAccount = JSON.parse(JSON.stringify(this.debts[i].account));

        this.debts[i].digitAccount = this.digitAccount.substring(
            this.digitAccount.length - 4,
            this.digitAccount.length
        );
      }
    },
    interprateDate(cadena) {
      let fec = cadena + "";
      let day = fec.substr(0, 2);
      let month = fec.substr(2, 2);
      let year = fec.substr(4, 7);
      let date = day + "-" + month + "-" + year;
      var fecha = new Date(
          this.$moment(date, "DD MM YYYY").format("DD MM YYYY")
      );
      return this.$moment(fecha).format("YYYY-MM-DD");
    },
    async getCreditReport() {
      this.$showLoader(true);
      this.getAsesor();
      let url = this.$v.SERVER + this.$v.OPS.GET_CR_ACCOUNTS;
      await this.$axios.get(url).then(
          (response) => {
            let data = response.data;
            if (data.success) {
              this.debts = [];
              this.dueMonthsBank = [];
              for (let i = 0; i < data.rows.length; i++) {
                // this.dueMonthsBank.push(this.calcDate(data.rows[i].last_bank_payment_date));
                // data.rows[i].last_bank_payment_date = this.dueMonthsBank;
                data.rows[i].addProgram = true;
                if (data.rows[i].amount > 0) {
                  this.debts.push(data.rows[i]);
                  this.debts.sort(function (a, b) {
                    return b.amount - a.amount;
                  });
                }
              }
              this.m_calculateDiscount();
              this.$showLoader(false);
              this.bandera = false;
              this.notCredit = false;
              this.error = false;
            } else {
              switch (data.reason) {
                case 9:
                  this.$showLoader(false);
                  this.notCredit = false;
                  this.bandera = false;
                  this.error = true;
                  this.reportListo = false;
              }
            }
            if (this.debts.length === 0) {
              this.$showLoader(false);
              this.notCredit = true;
              this.bandera = false;
              this.error = false;
              this.reportListo = false;
            }
          },
          (er) => {
            this.$showLoader(false);
            console.log(er);
          }
      );
    },
    formatAmountString(v) {
      return v
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    async doAjax() {
      this.$showLoader(true);
      await this.getCreditReport();
      this.$showLoader(false);
    },
  },
};
</script>
<style>
.v-progress-circular {
  margin: 1rem;
}

.margin-yellow {
  margin-top: 3rem;
}

.margin-yellow-h6 {
  margin-top: 1.5rem;
}

.margin-blue {
  margin-top: 3.5rem;
  margin-bottom: 4rem;
}

.margin-blue-h6 {
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}

@media (min-width: 300px) {
  .alerta {
    margin: 5%;
    padding: 6%;
  }

  .subir {
    margin-top: -23rem;
  }

  .tam {
    height: 31rem;
  }

  .card-debts {
    margin-bottom: -2rem;
  }

  .img-deb {
    margin-top: 1rem;
    width: 40%;
    height: 40%;
    margin-left: 5rem;
  }

  .margin-yellow-h6 {
    margin-left: 1rem;
    margin-top: 6rem;
  }

  .margin-blue-h6 {
    margin-left: 1rem;
    margin-top: 2rem;
  }
}

@media (min-width: 370px) {
  .card-debts {
    margin-bottom: 1rem;
  }

  .tam {
    height: 34rem;
  }

  .img-deb {
    margin-top: 1rem;
    width: 50%;
    height: 50%;
    margin-left: 5rem;
  }

  .margin-yellow-h6 {
    margin-left: 1rem;
    margin-top: 6rem;
  }

  .margin-blue-h6 {
    margin-left: 1rem;
    margin-top: 2rem;
  }
}

@media (min-width: 420px) {
  .card-debts {
    margin-bottom: 1rem;
  }

  .tam {
    height: 35rem;
  }

  .img-deb {
    margin-top: 1rem;
    width: 50%;
    height: 50%;
    margin-left: 5.5rem;
  }
}

@media (min-width: 768px) {
  .calcular {
    margin: 2%;
    padding: 3%;
  }

  .subir {
    margin-top: -27rem;
  }

  .tam {
    height: 27rem;
  }

  .margin-yellow {
    margin-left: 6.5rem;
    width: 10%;
  }

  .margin-yellow-h6 {
    margin-left: 1rem;
    margin-top: 6rem;
  }

  .margin-blue-h6 {
    margin-left: 1rem;
    margin-top: 2rem;
  }

  .margin-blue {
    margin-left: 6.5rem;
  }

  .card-debts {
    margin-bottom: 1rem;
  }

  .img-deb {
    margin-top: 1rem;
    width: 60%;
    height: 60%;
    margin-left: 4rem;
  }
}

@media (min-width: 992px) {
  .calcular {
    margin-left: 70%;
  }

  .subir {
    margin-top: -40rem;
  }
}

@media (min-width: 1000px) {
  .margin-yellow {
    margin-top: 8rem;
    margin-left: -1rem;
  }

  .tam {
    height: 23rem;
  }

  .margin-yellow-h6 {
    margin-top: 6.5rem;
  }

  .img-deb {
    margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    margin-left: 0.5rem;
  }

  .margin-blue {
    margin-top: 3.5rem;
    margin-bottom: 4rem;
    margin-left: -1rem;
  }

  .margin-blue-h6 {
    margin-top: 1.5rem;
    margin-bottom: 4rem;
  }
}

@media (min-width: 1200px) {
  .calcular {
    margin-left: 70%;
  }

  .tam {
    height: 24rem;
  }
}

.custom-dot-d {
  padding: 20%;
  margin-top: -0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1f3ac0;
  transition: all 0.3s;
  animation: pulse 2s infinite;
}

.custom-dot-d:hover {
  animation: pulse2 0s infinite;
}

.custom-dot-d.focus {
  animation: pulse2 0s infinite;
}

@font-face {
  font-family: bahnschrift;
  src: url(/fonts/Bahnschrift_font/bahnschrift.ttf);
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: url(/fonts/Roboto_font/Roboto-ThinItalic.ttf);
}

@font-face {
  font-family: Roboto-Thin;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(/fonts/Roboto_font/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(/fonts/Roboto_font/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}

@font-face {
  font-family: Roboto-LightItalic;
  src: url(/fonts/Roboto_font/Roboto-LightItalic.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: url(/fonts/Roboto_font/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-Italic;
  src: url(/fonts/Roboto_font/Roboto-Italic.ttf);
}

@font-face {
  font-family: Roboto-BoldItalic;
  src: url(/fonts/Roboto_font/Roboto-BoldItalic.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(/fonts/Roboto_font/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-BlackItalic;
  src: url(/fonts/Roboto_font/Roboto-BlackItalic.ttf);
}

@font-face {
  font-family: Roboto-Black;
  src: url(/fonts/Roboto_font/Roboto-Black.ttf);
}

@font-face {
  font-family: Gotham-Black;
  src: url(/fonts/Gotham_Font/Gotham-Black.otf);
}

@font-face {
  font-family: Gotham-Thin;
  src: url(/fonts/Gotham_Font/Gotham-Thin.otf);
}

@font-face {
  font-family: Gotham-ThinItalic;
  src: url(/fonts/Gotham_Font/Gotham-ThinItalic.otf);
}

@font-face {
  font-family: Gotham-ultra;
  src: url(/fonts/Gotham_Font/gotham-ultra.ttf);
}

@font-face {
  font-family: Gotham-UltraItalic;
  src: url(/fonts/Gotham_Font/Gotham-UltraItalic.otf);
}

@font-face {
  font-family: Gotham-Bold;
  src: url(/fonts/Gotham_Font/GothamBold.ttf);
}

@font-face {
  font-family: Gotham-BoldItalic;
  src: url(/fonts/Gotham_Font/GothamBoldItalic.ttf);
}

@font-face {
  font-family: Gotham-Book;
  src: url(/fonts/Gotham_Font/GothamBook.ttf);
}

@font-face {
  font-family: Gotham-BookItalic;
  src: url(/fonts/Gotham_Font/GothamBookItalic.ttf);
}

@font-face {
  font-family: Gotham-Light;
  src: url(/fonts/Gotham_Font/GothamLight.ttf);
}

@font-face {
  font-family: Gotham-Medium;
  src: url(/fonts/Gotham_Font/GothamMedium.ttf);
}

@font-face {
  font-family: Gotham-LightItalic;
  src: url(/fonts/Gotham_Font/GothamLightItalic.ttf);
}

@font-face {
  font-family: Metropolis-ExtraLight;
  src: url(/fonts/Metropolis/Metropolis-ExtraLight.otf);
}

@font-face {
  font-family: HomePage-Book;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Book.ttf);
}

@font-face {
  font-family: HomePage-Bold;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Bold.ttf);
}

#titleprogram {
  font-family: Gotham-Medium;
}

#titlereport {
  color: #2a3d9a;
  font-family: HomePage-Bold;
}

#buttonDeshabilitar {
  font-family: HomePage-Book;
}

#labelBanks {
  font-family: Gotham-Medium;
}

.speech-bubble-want {
  color: #2a3d9a;
  font-family: Roboto-Bold;
  font-size: 4rem;
  animation: pulse2want 2s infinite;
}

@keyframes pulse2want {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(3, 17, 218);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(6, 226, 255, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(18, 199, 231, 0);
  }
}
</style>
