<template>
    <div>
        <section style="background-color:#3953D3;">
        <div class="row justify-content-center">
        <div class="col-lg-10" style="background-color:#FFFFFF; width:80%; margin-top:5rem; margin-bottom:10rem; border-radius:1rem; height: 100%;">
         <b-tabs class="text-black" content-class="mt-3" style="background-color:#FFFFF;">
            <b-tab  title="Documentos Contrato">
            <div v-if="token" class="col-lg-12 text-center" style="width:100%;">
                <div class="container pt-lg-md-3">
                    <small style="font-size:20px;">Hola {{client.name}}, por favor toma fotos a los hojas de los documentos donde está tu firma y subirla en el lugar que corresponda, no es necesario subir todo el documento.</small>
                    <div class="row justify-content-center">
                         <div class="col-lg-4 py-4">
                                    <div
                                    class="col-lg-12 col-sm-12"
                                    style="text-align: center;text-transform: uppercase;"
                                    >
                                    <label>CONTRATO DE DEPOSITO DE DINERO</label>
                                    </div>
                                     <div>
                                     <dropbox
                                        text
                                        type="CONTRACT_SAV_DBM"
                                        :files="this.userFiles"
                                        :contractRisk=true
                                        :userid="this.client.id"
                                        v-on:uploadFile="uploadFileContractSav"
                                        v-on:resentUploadFile="uploadFileContractSav"
                                    ></dropbox>
                                </div>
                         </div>
                            <div class="col-lg-4 py-4">
                                <div
                                    class="col-lg-12 col-sm-12"
                                    style="text-align: center;text-transform: uppercase;"
                                    >
                                    <label>MANIFESTO FINAMIGO</label>
                                    </div>
                                     <div>
                                     <dropbox
                                        text
                                        type="FA_MANIFEST"
                                        :files="this.userFiles"
                                        :contractRisk=true
                                        :userid="this.client.id"
                                        v-on:uploadFile="uploadFileFaManifest"
                                        v-on:resentUploadFile="uploadFileFaManifest"
                                    ></dropbox>
                                </div>
                         </div>
                         <div class="col-lg-4 py-4">
                                <div
                                    class="col-lg-12 col-sm-12"
                                    style="text-align: center;text-transform: uppercase;"
                                    >
                                    <label>CARTA DE PAGO PARA FINAMIGO</label>
                                    </div>
                                     <div>
                                     <dropbox
                                        text
                                        type="LETTER_PAYMENT_FA"
                                        :files="this.userFiles"
                                        :userid="this.client.id"
                                        :contractRisk=true
                                        v-on:uploadFile="uploadFileLetterPaymentFA"
                                        v-on:resentUploadFile="uploadFileLetterPaymentFA"
                                    ></dropbox>
                                </div>
                         </div>
                    </div>
                    <div class="row justify-content-center">
                            <div class="col-lg-4 py-4">
                                <div
                                    class="col-lg-12 col-sm-12"
                                    style="text-align: center;text-transform: uppercase;"
                                    >
                                    <label>SOLICITUD DE APERTURA DE CUENTA</label>
                                    </div>
                                     <div>
                                     <dropbox
                                        text
                                        type="ACCOUNT_OPENING"
                                        :files="this.userFiles"
                                        :contractRisk=true
                                        :userid="this.client.id"
                                        v-on:uploadFile="uploadFileAccountOpening"
                                        v-on:resentUploadFile="uploadFileAccountOpening"
                                    ></dropbox>
                                </div>
                         </div>
                         <div class="col-lg-4 py-4">
                                <div
                                    class="col-lg-12 col-sm-12"
                                    style="text-align: center;text-transform: uppercase;"
                                    >
                                    <label>CARTA DE BENEFICIARIOS</label>
                                    </div>
                                     <div>
                                     <dropbox
                                        text
                                        type="LETTER_BENEFICIARIES"
                                        :files="this.userFiles"
                                        :contractRisk=true
                                        :userid="this.client.id"
                                        v-on:uploadFile="uploadFileLetterBeneficiaries"
                                        v-on:resentUploadFile="uploadFileLetterBeneficiaries"
                                    ></dropbox>
                                </div>
                         </div>
                    </div>
                </div>
            </div>
                <div class="text-center" style=" margin-top:10rem; margin-bottom:10rem;" v-if="!token">
                <h5 style="font-size:25px;">No logramos validar tu enlace, comúnicate con tu asesor</h5>
                <img src="https://dbmenos.com/img/theme/close-circle.png"></div>
            </b-tab>
         </b-tabs>
        </div>    
            </div>
        </section>
       
    </div>
</template>

<script>
    import Vue from "vue";
    import modal from "@/components/Modal"
    import {V} from "@/V"
    import BaseInput from '../components/BaseInput.vue';
    import Dropbox from "../components/Dropbox";
    export default {
        name: "home",
        components: {
            modal,
            BaseInput,
            Dropbox
        },
        created() {
            this.getDataUser();
        },
        data() {
            return {
                V: V,
                client: {},
                userFiles: [],
                token:false,
                files: {
                CONTRACT_SAV_DBM: null,
                FA_MANIFEST:null,
                LETTER_PAYMENT_FA:null,
                LETTER_BENEFICIARIES:null,
                ACCOUNT_OPENING:null
                },
                banks: [],
                SERVER: "https://s3.amazonaws.com/dbmenos.frontend/",
                leadModal: false,
                estimatedDiscountModal: false,
                averageDiscount: 0
            }
        },
        methods: {
           uploadFileContractSav(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let headers = {
                "user-id": this.client.id,
                "file-type": "CONTRACT_SAV_DBM",
                "filename": "contract_sav_dbm_"+this.client.id+".png"
            };
            this.$showLoader(true);
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE_CONTRACT_HIGH_RISK, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.files.CONTRACT_SAV_DBM = true;
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 5) {
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 7) {
                        localStorage.clear();
                        localStorage.setItem('uploadFile', '1');
                        this.$router.push({ path: "/register" });
                    } else {
                        this.$notify({
                        text: "No se logró subir, intente nuevamente",
                        type: "error",
                    });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        uploadFileFaManifest(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let date= this.$moment().format("DD_MM_YYYY_HH_mm_ss")
            let headers = {
                "user-id": this.client.id,
                "file-type": "FA_MANIFEST",
                "filename": "fa_manifest_"+this.client.id+".png"
            };
            this.$showLoader(true);
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE_CONTRACT_HIGH_RISK, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                   if (response.data.success) {
                        this.files.FA_MANIFEST = true;
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 5) {
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 7) {
                        localStorage.clear();
                        localStorage.setItem('uploadFile', '1')
                        this.$router.push({ path: "/register" });
                    } else {
                        this.$notify({
                        text: "No se logró subir, intente nuevamente",
                        type: "error",
                    });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        uploadFileLetterPaymentFA(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let date= this.$moment().format("DD_MM_YYYY_HH_mm_ss")
            let headers = {
                "user-id": this.client.id,
                "file-type": "LETTER_PAYMENT_FA",
                "filename": "letter_payment_fa_"+this.client.id+".png"
            };
            this.$showLoader(true);
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE_CONTRACT_HIGH_RISK, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.files.LETTER_PAYMENT_FA = true;
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 5) {
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 7) {
                        localStorage.clear();
                        localStorage.setItem('uploadFile', '1')
                        this.$router.push({ path: "/register" });
                    } else {
                        this.$notify({
                        text: "No se logró subir, intente nuevamente",
                        type: "error",
                    });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        uploadFileLetterBeneficiaries(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let date= this.$moment().format("DD_MM_YYYY_HH_mm_ss")
            let headers = {
                "user-id": this.client.id,
                "file-type": "LETTER_BENEFICIARIES",
                "filename": "letter_beneficiaries_"+this.client.id+".png"
            };
            this.$showLoader(true);
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE_CONTRACT_HIGH_RISK, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.files.LETTER_BENEFICIARIES = true;
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    this.verificationFiles();
                    }else if(response.data.reason == 5) {
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    this.verificationFiles();
                    }else if(response.data.reason == 7) {
                        localStorage.clear();
                        localStorage.setItem('uploadFile', '1')
                        this.$router.push({ path: "/register" });
                    } else {
                        this.$notify({
                        text: "No se logró subir, intente nuevamente",
                        type: "error",
                    });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },

        uploadFileAccountOpening(obj) {
            let fileType = obj.type;
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let date= this.$moment().format("DD_MM_YYYY_HH_mm_ss")
            let headers = {
                "user-id": this.client.id,
                "file-type": "ACCOUNT_OPENING",
                "filename": "account_opening_"+this.client.id+".png"
            };
            this.$showLoader(true);
            this.$axios
                .post(V.SERVER + V.OPS.UPLOAD_FILE_CONTRACT_HIGH_RISK, formData, {
                    headers: headers
                })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.files.FA_MANIFEST = true;
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 5) {
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    }else if(response.data.reason == 7) {
                        localStorage.clear();
                        localStorage.setItem('uploadFile', '1')
                        this.$router.push({ path: "/register" });
                    } else {
                        this.$notify({
                        text: "No se logró subir, intente nuevamente",
                        type: "error",
                    });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        getFilesInfo() {
            let url = V.SERVER + V.OPS.GET_FILES_USER_RISK;
            let request ={
                user: this.client.id
            }
            this.$axios.post(url,request).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.userFiles = data.rows;
                    console.log(this.userFiles)
                    for (var i = 0; i < this.userFiles.length; i++) {
                        if (this.userFiles[i].type == "CONTRACT_SAV_DBM") {
                            this.files.CONTRACT_SAV_DBM = true;
                        }
                        if (this.userFiles[i].type == "FA_MANIFEST") {
                            this.files.FA_MANIFEST = true;
                        }
                        if (this.userFiles[i].type == "LETTER_PAYMENT_FA") {
                            this.files.LETTER_PAYMENT_FA = true;
                        }
                        if (this.userFiles[i].type == "LETTER_BENEFICIARIES") {
                            this.files.LETTER_BENEFICIARIES = true;
                        }
                        if (this.userFiles[i].type == "ACCOUNT_OPENING") {
                            this.files.ACCOUNT_OPENING = true;
                        }
                    }
                } else {
                    console.log("error d_userFiles");
                }
            });
        },
        verificationFiles() {
                let url = V.SERVER + V.OPS.VERIFICATIONS_UPLOAD_FILES;
                let request = {user:this.client.id};
            this.$axios.post(url,request).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.$notify({
                        text: "Se subieron todos los archivos correctamente",
                        type: "success",
                    });
                } else {
                     this.$notify({
                        text: "No se logró subir alguno, intente nuevamente",
                        type: "error",
                    });
                }
            });
        },
        getDataUser() {
            this.$showLoader(true);
            let url = V.SERVER + V.OPS.GET_USER_FILE_CONTRACT_RISK+ "?token=" +this.$route.params['pathMatch'];
            this.$axios.get(url).then(async (res) => {
                this.$showLoader(false);
                let data = res.data;
                    if (res.data.success) {
                        this.token = true;
                        this.client = data.row;
                        this.getFilesInfo()
                        this.$showLoader(false);
                    } 
                });
            }
        }
    };
</script>
