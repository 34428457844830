<template>

  <div class="bg-principal py-0 py-sm-5">

    <div class="row col-12 col-sm-11 col-lg-10 mx-auto p-0 p-sm-3 my-0 my-sm-5">
      <div class="col-12 col-sm-12 col-lg-7 p-0 py-sm-3">

        <div>
          <p class="d-none d-sm-block col-12 col-sm-10 mx-auto text-white primary-text open-sans-light">
            Te ayudamos a tener una vida sin deudas. <br>
            <span class="primary-text open-sans-bold">Obtén hasta un 70% descuento hoy mismo.</span>
          </p>
        </div>

        <video
            v-on:play="play=true"
            controls
            width="100%"
            class="d-sm-none mt-5"
            src="/videos/intro_edwin_v.mp4"
            poster="/img/financial_freedom/images/v_phone.png"
        ></video>

        <div class="d-block d-sm-none" :class="!play ? 'v-phone' :''">
          <p class="col-12 col-sm-10 mx-auto text-white primary-text open-sans-light"
             :class="play ? 'd-none' :''">
            Te ayudamos a tener una vida sin deudas. <br>
            <span class="primary-text open-sans-bold">Obtén hasta un 70% descuento hoy mismo.</span>
          </p>
        </div>

        <video
            controls
            autoplay
            muted
            width="100%"
            class="d-none d-sm-block mt-5 video"
            src="/videos/intro_edwin_h.mp4"
        ></video>

      </div>
      <div class="col-12 col-sm-12 col-lg-5 p-0 p-sm-3 div-form d-flex align-items-center">

        <v-card class="p-sm-3 bg-form">
          <v-card-title>
            <div class="text-center mb-2">
              <img src="/img/landing_page/desktop/logo.png" alt="logo" width="50%">
            </div>
            <p class="mx-auto text-center text-white secondary-text open-sans-light pl-3 pr-3">
              <span class="open-sans-bold">Regístrate para consultar tus deudas y obtener tu descuento</span> <br>
            </p>

            <!-- <v-btn
                block
                color="#183a9d"
                class="mb-2 btn-text-transform btn-facebook"
                v-on:click="onFacebook()"
            >
              <i class="fa fa-facebook fa-lg mr-2"></i>
              <span class="text-white simple-text">Regístrate con Facebook</span>
            </v-btn>
            <p class="mx-auto text-white secondary-text open-sans-semi-bold">o ingresa tus datos</p> -->
          
          </v-card-title>
          <v-card-text>

            <v-form v-model="valid" ref="form">

              <p class="mx-auto mb-0 text-center text-white secondary-text open-sans-light">
                <span class="open-sans-bold">¿Cuánto debes?</span> <br>
              </p>

              <div class="col-lg-12">
                <h2 class="mx-auto text-center text-white">
                  $
                  {{
                    Number(rangeDebt)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                </h2>

                <base-slider
                  :range="{ min: 3000, max: 200000 }"
                  :options="{ step: 10 }"
                  v-model="rangeDebt"
                >
                </base-slider>

              </div>

              <v-select
                  v-model="lead.banks"
                  label="¿A quién le debes?"
                  :items="banks"
                  item-text="name"
                  item-value="id"
                  :rules="rules.banks"
                  multiple
                  dense
                  solo
              ></v-select>

              <v-text-field
                  label="Nombre"
                  v-model="lead.name"
                  :rules="rules.name"
                  required
                  dense
                  solo
              ></v-text-field>

              <v-text-field
                  label="Correo"
                  v-model="lead.email"
                  :rules="rules.email"
                  required
                  dense
                  solo
              ></v-text-field>

              <v-text-field
                  label="Teléfono"
                  v-model="lead.phone"
                  :rules="rules.phone"
                  pattern="\d*"
                  type="number"
                  required
                  dense
                  solo
              ></v-text-field>

              <v-select
                  v-if="false"
                  v-model="lead.debt"
                  label="¿Cuánto debes?"
                  :items="debts"
                  item-text="name"
                  item-value="value"
                  :rules="rules.debt"
                  dense
                  solo
              ></v-select>

              <div class="text-center mt-5">
                <v-btn
                    :loading="loading"
                    color="#fdd318"
                    elevation="2"
                    class="btn-text-transform"
                    v-on:click="validateData"
                >
                  <span class="open-sans-bold simple-text" style="color: #183a9d">Registrarme</span>
                </v-btn>
              </div>

            </v-form>

          </v-card-text>
        </v-card>

      </div>
    </div>



    <!-- POPUP CUENTA YA EXISTENTE -->
    <div v-if="dialogUser">
      <v-dialog
        persistent
        v-model="dialogUser"
        max-width="800"
      >
        <div class="alert-close">
          <a @click="remove()"><span class="close-x">×</span></a>
        </div>

        <v-card >
          <div class="alert-container">
            <div class="alert-card">
              <v-card-text class="alert-img">
                <img src="/img/alert_account.png" alt="Existing account">
              </v-card-text>
      
              <v-card-text class="alert-text">
                Ya existe una cuenta asociada a este teléfono y correo. ¡Da click aquí!
              </v-card-text>
      
              <v-card-actions class="alert-btn-box">
      
                <v-btn
                  text                  
                  href="/register"
                  class="btn btn-green text-white alert-btn"
                  height="100%"
                >
                  Iniciar sesión
                </v-btn>

              </v-card-actions>
            </div>
          </div>              
        </v-card>
      </v-dialog>
    </div>



    <!-- POPUP CODIGO WHATSAPP -->
    <!-- Modal -->
    <div class="text-center">
      <v-dialog v-model="dialog" width="500" persistent>
        <v-card>
          <v-card-text class="pt-4">

            <div class="text-end">
              <v-icon
                  class="mb-3"
                  color="#ff0033"
                  @click="dialog=false"
              >
                mdi-close
              </v-icon>
            </div>

            <p class="simple-text text-center p-0" style="color: #000">
              <b>Para recibir el cálculo de tu descuento enviamos un código por <span
                  style="color: #25D366">WhatsApp</span> colócalo para continuar.</b>
            </p>

            <v-container style="background: #FFFFFF">
              <v-row>
                <v-col class="col-12 col-sm-8 mx-auto">
                  <v-form v-model="valid" ref="formCode">
                    <v-otp-input
                        v-model="lead.code"
                        :length="length"
                        dark
                    ></v-otp-input>
                  </v-form>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>

            <v-col class="my-3 ml-auto">
              <v-btn block :disabled="!isActive" color="#2A3D99" v-on:click="insertLead">
                <span style="text-transform: none; color: #FFFFFF">
                  Validar
                </span>
              </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

  </div>

</template>

<script>

import {V} from "@/V";
import Vue from "vue";

export default {
  name: "Principal",
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    isActive() {
      return this.lead.code.length === this.length
    },
  },
  mounted() {
    window.fbAsyncInit = function () {
      FB.init({
        appId: "441775824299701",
        cookie: true,
        xfbml: true,
        version: "v14.0",
      });

      FB.AppEvents.logPageView();
    };

    (function (d, s, id) {
      var js,
          fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  },
  data: () =>
      ({
        valid: false,
        loading: false,
        play: false,
        dialog: false,
        dialogUser: false,
        leadSent: false,
        length: 4,
        rangeDebt: 0,
        step: 1,
        lead:
            {
              name: null,
              phone: null,
              email: null,
              banks: [],
              debt: null,
              code: ''
            },

        rules:
            {
              name: [v => !!v || 'El nombre es requerido'],
              phone:
                  [
                    v => !!v || 'El teléfono es requerido',
                    v => /^([0-9]{10,13})$/.test(v) || 'Min: 10 - Max 13, dígitos'
                  ],
              email:
                  [
                    v => !!v || 'El correo es requerido',
                    v => /.+@.+/.test(v) || 'Formato invalido'
                  ],
              banks: [v => v.length !== 0 || 'El o los banco(s) son requeridos'],
              debt:
                  [
                    v => !!v || 'La deuda es requerida',
                    v => v >= 10000 || 'La deuda mínima es de  10,000 MXN'
                  ],
            },

        averageDiscount: 0,

        debts:
            [
              {id: 1, name: '10,000 a 30,000', value: 10000},
              {id: 2, name: '30,000 a 50,000', value: 30000},
              {id: 3, name: '50,000 a 100,000', value: 50000},
              {id: 4, name: '100,000 a 300,000', value: 100000},
              {id: 5, name: '300,000 a 500,000', value: 300000},
              {id: 6, name: 'más de 500,000', value: 500000},
            ],
      }),
  methods:
    {
      remove() {
          return this.dialogUser = !this.dialogUser;
        },
    
        onFacebook() {
          FB.login((response) => {
            if (response.authResponse) {
              FB.api("/me?fields=name,email", (response) => {

                console.log("Data:", response)
                this.lead.name = response.name;
                this.lead.email = response.email;

              });
            }
          }, {scope: 'email'});
        },

        clearForm() {
          this.$refs.form.reset();
        },

        validateData() {

          this.loading = true

          if (this.$refs.form.validate()) {

            for (const item of this.lead.banks) {
              this.calcDiscount(item);
            }

            this.insertLead()

          } else {
            console.log("Info incompleta")
          }

          this.loading = false
        },

        calcDiscount(index) {
          let bank = this.banks[index];
          bank.selected = !bank.selected;

          Vue.set(this.banks, index, bank);

          let count = 0;
          let neg = 0;

          this.banks.forEach((bank) => {
            if (bank.selected) {
              count += 1;
              neg += bank.neg;
            }
          });

          this.averageDiscount = (count > 0) ? neg / count : 0;
        },

        getMarketing() {

          let marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_${this.$route.query.utm_campaign}`;

          if (marketing === "undefined_undefined_undefined") {
            marketing = null;
          } else if (marketing === `${this.$route.query.utm_source}_undefined_undefined`) {
            marketing = this.$route.query.utm_source;
          } else if (marketing === `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_undefined`) {
            marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}`
          } else if (marketing === `${this.$route.query.utm_source}_undefined_${this.$route.query.utm_campaign}`) {
            marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_campaign}`;
          }

          return marketing;
        },

        async insertLead() {

          this.loading = true

          if (this.leadSent) {

            if (this.$refs.formCode.validate()) {

              let _data = {
                phone: this.lead.phone,
                code: this.lead.code,
                marketing: this.getMarketing(),
              };

              let calculate = {
                estimated: this.averageDiscount,
                total: this.lead.debt
              }

              let _dataLead = {
                name: this.lead.name,
                phone: this.lead.phone,
                email: this.lead.email,
                amount: this.lead.debt,
                banks: JSON.stringify(this.lead.banks),
                same_bank_debt_paysheet: false,
                device: 'web-f'
              };

              Vue.analytics.fbq.event("LeadComplete", {
                content_name: this.lead.name + " " + this.lead.phone + " lead validated successfully",
              });

              let url = V.SERVER + V.OPS.VALIDATE_LEAD_SMS_CODE;

              this.$http.post(url, _data).then((response) => {

                    if (response.data.success) {

                      this.leadSent = false;

                      localStorage.setItem('phone', JSON.stringify(_data))
                      localStorage.setItem('calculate', JSON.stringify(calculate))
                      localStorage.setItem('Lead', JSON.stringify(_dataLead))
                      localStorage.setItem('marketing', this.getMarketing())

                      //Flujo anterior
                      // this.$router.push({path: "/regis"});

                      //Flujo nuevo
                      this.$router.push({name: "plan-personalizado"});
                      window.location.reload();


                      gtag("event", "Datos-validados", {
                        event_category: "Landing-liquidar-deuda-1",
                        event_label: "datos-validados",
                      });

                    } else {
                      alert("Algo salió mal :(, intenta nuevamente");
                    }

                  }
              );

            }

          } else {

            this.leadSent = true;

            let headers = {"device": 'web'};

            let _data = {
              name: this.lead.name,
              email: this.lead.email,
              phone: this.lead.phone,
              amount: this.lead.debt,
              banks: JSON.stringify(this.lead.banks),
              same_bank_debt_paysheet: false,
              device: 'web-f'
            };

            Vue.analytics.fbq.event("LeadStarting", {
              content_name: this.lead.name + " " + this.lead.phone + " lead successful started",
            });

            let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS;

            gtag("event", "conversion", {send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"});

            gtag("event", "Datos-contacto", {
              event_category: "Landing-liquidar-deuda-1",
              event_label: "datos-contacto"
            });

            this.$http.post(url, _data, {headers: headers}).then((response) => {

                  if (response.data.success) {
                    this.leadSent = response.data.success;
                    this.dialog = true
                  } else {
                    // alert("Algo salió mal :(, intenta nuevamente");
                    this.dialogUser = true;
                    this.dialog = false;
                  }
                },
            );
          }
        },

      },
  watch:
      {
        'lead.email': function dialog(value) {
          this.lead.email = this.lead.email.toLowerCase()
        }
      }
}
</script>

<style lang="scss" scoped>
@import './scss/Principal.scss';

$orange-color: #fdd318;

::v-deep{
  //input range color
  .noUi-connect{
    background: $orange-color;
  }
  .noUi-horizontal .noUi-handle{
    background-color: $orange-color;
  }
}

.v-phone {
  position: absolute;
  width: 90%;
  left: 0;
  right: 0;
  bottom: 4rem;
  margin: auto;
}

@font-face {
  font-family: OpenSans-SemiBold;
  src: url(/fonts/HomepageNew/OpenSans-SemiBold.ttf);
}

/*Fonts*/
.open-sans-light {
  font-family: OpenSans-Light;
}

.open-sans-bold {
  font-family: OpenSans-Bold;
}

.open-sans-semi-bold {
  font-family: OpenSans-SemiBold;
}

/*Icons*/
.fa {
  font-size: 2rem;
  color: #FFFFFF;
}

/*Backgrounds*/
.bg-principal {
  background: linear-gradient(to bottom, #2A3D99, #29AAE1)
}

.bg-form {
  background-image: url("/img/financial_freedom/backgrounds/background_desktop.png");
  background-size: cover;
  border-radius: 20px !important;
}

/*Buttons*/
.btn-text-transform {
  text-transform: none
}

.btn-facebook {
  animation: pulse2 2s infinite;
}

@media (max-width: 575px) {

  .bg-principal {
    background: linear-gradient(to bottom, #FFFFFF, #FFFFFF)
  }

  .primary-text {
    font-size: 1rem;
  }

  .secondary-text {
    font-size: .9rem;
  }

  .primary-text br {
    display: none;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .primary-text {
    font-size: 1.2rem;
  }

  .secondary-text {
    font-size: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .primary-text {
    font-size: 1.2rem;
  }

  .secondary-text {
    font-size: 1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .div-form {
    padding: 0 !important;
  }

  .bg-form {
    padding: 0 !important;
  }

  .primary-text {
    font-size: 1.1rem;
  }

  .secondary-text {
    font-size: .9rem;
  }
}

@media (min-width: 1200px) {

  .primary-text {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1rem;
  }
}

@media (min-width: 2560px) {

  .primary-text {
    font-size: 2rem;
  }

  .secondary-text {
    font-size: 1.8rem;
  }

  .simple-text {
    font-size: 1.4rem;
  }

  img {
    width: 50%;
  }
}

@media (min-width: 1600px) {
  .video {
    margin-right: auto;
    margin-left: auto;
    max-width: 80%;
  }
}

@media (min-width: 2000px) {
  .video {
    margin-right: auto;
    margin-left: auto;
    max-width: 70%;
  }
}

</style>