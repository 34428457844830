<template>

  <div>

    <principal/>

    <steps/>

    <why-with-us/>

    <frequent-questions/>

  </div>

</template>

<script>
import Principal from "@/views/components/loans/Principal";
import Steps from "@/views/components/loans/Steps";
import WhyWithUs from "@/views/components/loans/WhyWithUs";
import FrequentQuestions from "@/views/components/loans/FrequentQuestions";
export default {
  name: "Loans",
  components: {FrequentQuestions, WhyWithUs, Steps, Principal},
}
</script>

<style scoped>

</style>