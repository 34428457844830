<template>

  <div>

    <principal/>

    <what-is-it/>

    <differences/>

    <results/>

    <banks/>

  </div>

</template>

<script>

import Principal from "@/views/components/how_does_it_work/Principal";
import WhatIsIt from "@/views/components/how_does_it_work/WhatIsIt";
import Differences from "@/views/components/how_does_it_work/Differences";
import Results from "@/views/components/how_does_it_work/Results";
import Banks from "@/views/components/how_does_it_work/Banks";
import Applications from "@/views/components/landing_page/Applications";
import SocialMedia from "@/views/components/landing_page/SocialMedia";
import FooterTemplate from "@/views/components/templates/FooterTemplate";

export default {
  name: "HowDoesItWork",
  components: {FooterTemplate, SocialMedia, Applications, Banks, Results, Differences, WhatIsIt, Principal}
}

</script>

<style scoped>

</style>