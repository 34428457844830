<template>

  <div class="bg-principal">

    <div class="row px-sm-5 p-lg-5 p-xl-0">

      <div class="col-12 bg-logo">
        <div class="text-center text-sm-left mt-3 mb-5">
          <img src="/img/loans/logo.png" alt="logo" class="text-center img-logo">
        </div>
      </div>

      <div class="col-12 col-sm-6 my-sm-5 pb-0 text-center bg-left">

        <p class="primary-text pb-1">
          Te prestamos desde
        </p>
        <p class="primary-text-bold">
          <span class="dollar">$</span>10,000
        </p>
        <p class="primary-text">
          para pagar tus deudas
        </p>

        <p class="secondary-text pt-3">
          Paga todas tus deudas con nuestros <br> préstamos a una mejor tasa.
        </p>

        <div class="bg-men-sm">
          <img src="/img/loans/men.png" alt="men">
        </div>

      </div>

      <div class="col-12 col-sm-6 my-sm-5 bg-right">

        <div class="col-12 col-sm-10 col-lg-7 ml-sm-auto mx-lg-auto p-0">

          <v-card-text class="px-sm-0">

            <p class="text-center text-form">¡Solicita tu préstamo ahora!</p>

            <v-form v-model="valid" ref="form" class="ml-sm-5">

              <v-text-field
                  class="text-input"
                  label="Nombre"
                  v-model="client.name"
                  :rules="rules.name"
                  color="#00B2CE"
                  required
                  outlined
                  rounded
                  dense
              ></v-text-field>

              <v-text-field
                  label="Teléfono"
                  v-model="client.phone"
                  :rules="rules.phone"
                  color="#00B2CE"
                  type="number"
                  pattern="\d*"
                  required
                  outlined
                  rounded
                  dense
              ></v-text-field>

              <v-text-field
                  label="¿Cuánto debes?"
                  v-model="client.debt"
                  :rules="rules.debt"
                  color="#00B2CE"
                  type="number"
                  pattern="\d*"
                  required
                  outlined
                  rounded
                  dense
              ></v-text-field>

              <v-select
                  label="¿A quién le debes?"
                  v-model="client.banks"
                  :rules="rules.banks"
                  color="#00B2CE"
                  :items="banks"
                  item-text="name"
                  item-value="id"
                  multiple
                  outlined
                  rounded
                  dense
              ></v-select>

              <div class="text-center mt-5">
                <v-btn
                    :loading="loading"
                    color="#00B2CE"
                    elevation="2"
                    class="m-sm-3 m-lg-0"
                    depressed
                    dark
                    v-on:click="saveRegister"
                >
                  <span class="text-button">
                    <b style="text-transform: none">Solicitar mi préstamo</b>
                  </span>

                </v-btn>
              </div>

            </v-form>

          </v-card-text>

        </div>

      </div>

    </div>

    <div class="row bg-men-lg">
      <div class="col-12 text-center">
        <img src="/img/loans/men.png" alt="men" class="img-men">
      </div>
    </div>

    <!-- Dialog Pin -->

    <v-dialog
        v-model="dialog"
        persistent
        width="500"
    >

      <v-card>

        <v-card-text class="text-center">

          <div class="text-end">
            <v-icon
                class="mt-3"
                color="#00B2CE"
                @click="dialog=false"
            >
              mdi-close
            </v-icon>
          </div>

          <p class="pt-5 modal-title">Autorización de buró de crédito</p>
          <p class="modal-text">Para consultar la información de tu buró <br> te enviamos un PIN a tu teléfono.</p>

          <div class="col-12 col-sm-8 mx-auto">

            <v-form v-model="valid" ref="form">

              <v-otp-input
                  v-model="client.code"
                  :length="length"
                  small
              ></v-otp-input>

              <v-btn
                  block
                  color="#00B2CE"
                  :disabled="!isActive"
                  v-on:click="saveRegister"
              >
                <span style="text-transform: none; color: #FFFFFF">Aceptar</span>
              </v-btn>

              <div class="text-center mt-3">
                <a href="/files/aviso_de_privacidad_grupo_dbmenos_2018.pdf" target="_blank">
                  <p class="link" style="color: #2A3D99">
                    <img src="/img/new_process/icons/eye.svg" alt="eye" class="icon-eye mr-1">
                    <ins>Aviso de privacidad</ins>
                  </p>
                </a>
              </div>

            </v-form>

          </div>

        </v-card-text>

      </v-card>

    </v-dialog>


    <!-- Snack Notification -->
    <div class="text-center">
      <v-snackbar
          v-model="snackbar"
          :color="color"
      >
        <span style="color: #000000">{{ text }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn
              text
              color="#FFFFFF"
              v-bind="attrs"
              @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>

</template>

<script>
import {V} from "@/V";

export default {
  name: "Principal",
  computed:
      {
        banks() {
          return this.$store.state.banks;
        },
        isActive() {
          return this.client.code.length === this.length
        },
      },
  data: () =>
      ({
        valid: false,
        loading: false,
        dialog: false,
        length: 4,
        snackbar: false,
        text: '',
        color: '',

        client:
            {
              id: null,
              name: null,
              phone: null,
              debt: null,
              banks: null,
              code: ''
            },

        rules:
            {
              name: [v => !!v || 'El nombre es requerido'],
              phone:
                  [
                    v => !!v || 'El teléfono es requerido',
                    v => /^([0-9]{10,13})$/.test(v) || 'Min: 10 - Max 13, dígitos'
                  ],
              email:
                  [
                    v => !!v || 'El correo es requerido',
                    v => /.+@.+/.test(v) || 'Formato invalido'
                  ],
              debt: [v => !!v || 'La deuda es requerida'],
              banks: [v => !!v || 'El o los banco(s) son requeridos'],
              code:
                  [
                    v => !!v || 'El código es requerido',
                    v => (v && v.length === 4) || 'El código debe ser de 4 dígitos',
                  ],
            }
      }),
  methods:
      {
        saveRegister() {
          (this.$refs.form.validate()) ? this.insertClient() : this.notification('warning', 'Favor de completar el formulario');
        },

        async insertClient() {

          this.$showLoader(true);

          let url = V.SERVER + V.CREDITS.SET_CREDITS_USERS
          let data =
              {
                name: this.client.name,
                phone: this.client.phone,
                amount: this.client.debt,
                banks: JSON.stringify(this.client.banks),
              }

          await this.$axios.post(url, data).then((response) => {

            if (response.data[this.$v.API.SUCCESS]) {
              this.notification('success', 'Su registro se guardo correctamente')

              setTimeout(() => {
                this.$router.push({path: "/gracias"})
              }, 3000)

            } else {
              this.notification('warning', 'Ocurrió un error al guardar su registro')
            }

          }).catch((e) => {
            console.log("Error:", e);

            this.$showLoader(false);

            this.notification('danger', 'Oops...Ocurrió un error inesperado')

          })

          this.$showLoader(false);
        },

        notification(type, text) {
          this.snackbar = true
          this.text = text
          switch (type) {
            case "success":
              this.color = '#90FF33'
              break
            case "warning":
              this.color = '#FFC433'
              break
            case "danger":
              this.color = '#FF4C33'
              break
          }
        }
      }
}
</script>

<style scoped>

@font-face {
  font-family: OpenSans-Medium-Italic;
  src: url(/fonts/HomepageNew/OpenSans-MediumItalic.ttf);
}

.bg-principal {
  background: linear-gradient(to right, #00B2CE 0%, #00B2CE 50%, #FFFFFF 50%, #FFFFFF 100%);
}

.primary-text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.primary-text-bold, .dollar {
  font-family: Gotham-Ultra;
  color: #FFFFFF;
}

.secondary-text {
  font-family: OpenSans-Medium-Italic;
  color: #FFFFFF;
  line-height: 1.2rem;
}

.text-form {
  font-family: OpenSans-Bold;
  color: #00B2CE;
}

.v-text-field--outlined >>> fieldset {
  border-color: #00B2CE;
}

.img-men {
  width: 30%;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}

.modal-title {
  font-family: OpenSans-Bold;
  color: #00B2CE;
}

.modal-text {
  font-family: OpenSans-Regular;
  color: #4d4d4d;
}

@media (max-width: 576px) {
  .bg-logo, .bg-left {
    background-color: #00B2CE;
  }

  .bg-right {
    background-color: #FFFFFF;
  }

  .img-logo {
    width: 35%;
  }

  .primary-text {
    font-size: 1.2rem;
  }

  .primary-text-bold {
    font-size: 2rem;
  }

  .secondary-text {
    font-size: 1.2rem;
  }

  .primary-text, .primary-text-bold {
    line-height: .5rem;
  }

  .dollar {
    font-size: 1.4rem;
  }

  .bg-men-lg {
    display: none;
  }

  .icon-eye {
    width: .8rem;
  }

  .link {
    font-size: .8rem;
  }

  .secondary-text br {
    display: none;
  }
}

@media (min-width: 576px) {
  .img-logo {
    width: 20%;
  }

  .primary-text {
    font-size: 1.5rem;
  }

  .primary-text-bold {
    font-size: 2.5rem;
  }

  .primary-text, .primary-text-bold {
    line-height: .5rem;
  }

  .dollar {
    font-size: 1.4rem;
  }

  .bg-men-sm {
    display: none;
  }

  .icon-eye {
    width: .8rem;
  }

  .link {
    font-size: 1rem;
  }
}

@media (min-width: 768px) {

  .img-logo {
    width: 20%;
  }

  .primary-text {
    font-size: 1.2rem;
  }

  .primary-text-bold {
    font-size: 2rem;
  }

  .primary-text, .primary-text-bold {
    line-height: .5rem;
  }

  .dollar {
    font-size: 1.6rem;
  }

  .bg-men-sm {
    display: none;
  }

  .img-men {
    width: 30%;
  }

  .icon-eye {
    width: .9rem;
  }

  .link {
    font-size: 1rem;
  }


}

@media (min-width: 992px) {

  .img-logo {
    width: 20%;
  }

  .primary-text {
    font-size: 1.2rem;
  }

  .primary-text-bold {
    font-size: 4rem;
  }

  .primary-text, .primary-text-bold {
    line-height: 1.3rem;
  }

  .dollar {
    font-size: 2.3rem;
  }

  .bg-men-sm {
    display: none;
  }

  .img-men {
    width: 30%;
  }

  .icon-eye {
    width: 1rem;
  }

  .link {
    font-size: 1rem;
  }
}

@media (min-width: 1200px) {
  .img-logo {
    width: 12% !important;
  }

  .primary-text {
    font-size: 1.2rem;
  }

  .primary-text-bold {
    font-size: 4rem;
  }

  .primary-text, .primary-text-bold {
    line-height: 1.3rem;
  }

  .bg-men-sm {
    display: none;
  }

  .img-men {
    width: 25%;
  }

  .icon-eye {
    width: 1rem;
  }

  .link {
    font-size: 1rem;
  }
}

@media (min-width: 1400px) {
  .img-logo {
    width: 30%;
  }

  .secondary-text {
    font-size: 1.4rem;
  }

  .primary-text-bold {
    font-size: 4rem;
  }

  .primary-text, .primary-text-bold {
    line-height: 1.5rem;
  }

  .bg-men-sm {
    display: none;
  }

  .img-men {
    width: 25%;
  }

  .icon-eye {
    width: 1rem;
  }

  .link {
    font-size: 1.2rem;
  }
}

@media (min-width: 2560px) {

  .bg-principal {
    padding: 3rem;
  }

  .img-logo {
    width: 15%;
  }

  .primary-text {
    font-size: 1.8rem;
  }

  .primary-text-bold {
    font-size: 4.5rem;
  }

  .primary-text, .primary-text-bold {
    line-height: 1.8rem;
  }

  .dollar {
    font-size: 2.5rem;
  }

  .bg-men-sm {
    display: none;
  }

  .img-men {
    width: 20%;
  }

  .icon-eye {
    width: 1rem;
  }

  .link {
    font-size: 1.2rem;
  }
}

</style>