<template>
    <div id="app">
        <v-app id="inspire">
            <div class="window-container">
                <v-carousel v-model="model" class="card-container" height="100%">
                    <v-carousel-item
                        v-for="(steps, index) in cardSteps"
                        :key="index"
                    >
                        <v-sheet
                        height="100%"
                        tile
                        color="transparent"
                        >
                        <div class="card-remove">
                            <a @click="close()"><span class="card-x">×</span></a>
                        </div>

                        <div class="items-container" >

                            <div class="img-container">
                                <img
                                    :src="`${steps.img}`"
                                    :alt="`${steps.name} icon`"
                                />
                            </div>

                            <div class="text-container">
                            {{steps.text}}
                            </div>

                        </div>

                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </div>


            <div class="next-btn" @click="nextStep()">
                <span class="next-text">
                    Siguiente                                    
                </span>
                <span class="next-icon">
                    <!-- <b-icon-chevron-right ></b-icon-chevron-right> -->
                    <!-- <img src="../../../../public/img/icons/Next.png" alt=""> -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-tabler icon-tabler-chevron-right" width="100" height="100" viewBox="0 0 24 24" stroke-width="2.5" stroke="#ffffff" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                        <polyline points="9 6 15 12 9 18" />
                    </svg>
                </span> 
            </div>

        </v-app>
    </div>
</template>

<script>
import { BIconChevronRight } from 'bootstrap-vue';

export default {
    name: 'PrevSteps',
    components: {
        BIconChevronRight
    },
    data () {
        return {
            cardSteps: [
                {
                    name: "Step 1",
                    img: "/img/icons/Step1.png",
                    text: "Agrega tus cuentas y el monto de deuda"
                },
                {
                    name: "Step 2",
                    img: "/img/icons/Step2.png",
                    text: "Elige el plazo y monto mensual que más se adapte a tus posibilidades"
                },
                {
                    name: "Step 3",
                    img: "/img/icons/Step3.png",
                    text: "Obtén tu propuesta con tu descuento y vive una vida sin deudas"
                }
            ],
            colors: [
                'primary',
                'secondary',
                'yellow darken-2',
                'red',
                'orange',
            ],
            model: 0,
            startApp: true,
        }
    },
    methods: {
        nextStep() {
            if (this.model === 2) {
                //this.startApp = false;
                return this.$emit('closeDialog');
            } else {
                return this.model++;
            }
        },
        close() {
          this.$emit('closeDialog')
        },
    },
    watch: {
        startApp: function (item) {
            if (item === false) {
                this.close()
            }
        }
    }
}
</script>

<style lang="scss" scoped>
    @import './scss/PrevSteps.scss'; 
</style>>