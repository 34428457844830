<template>
  <div >
    <section>
      <div class="d-block d-sm-none">
        <header v-if="!session">
          <base-nav
            transparent
            style="
              width: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 20px;
                maring-top: -1rem;
                margin-bottom: 10px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-8 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img src="img/brand/DB Menos logo 2020_membrete.png" />
                </a>
              </div>
              <div class="col-4 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <router-link
              style="width: auto; background-color: #4667a3"
              to="/register"
              class="btn btn-icon text-white"
            >
              <span class="btn-inner--icon" style="margin-left: -2px">
                <img
                  src="img/brand/DB Menos logo 2020_Monograma original.png"
                />
              </span>
              <span class="nav-link-inner--text init" style="margin-left: 15px"
                >INICIA SESIÓN</span
              >
            </router-link>
          </base-nav>
        </header>
        <header v-else>
          <base-nav
            transparent
            type
            expand
            style="
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand mr-lg-12"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 1px;
                margin-bottom: 1px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-8 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img src="img/brand/DB Menos logo 2020_membrete.png" />
                </a>
              </div>
              <div class="col-4 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  BLOG
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link
                    to="/blogs"
                    style="text-black"
                    class="dropdown-item"
                    >Blog</router-link
                  >
                  <router-link
                    to="/postWebinars"
                    style="text-black"
                    class="dropdown-item"
                    >Video Conferencias</router-link
                  >
                </div>
              </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.facebook.com/grupodbmenos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Facebook"
                >
                  <i class="fa fa-facebook fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Facebook</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.instagram.com/db_menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Instagram"
                >
                  <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Instagram</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Youtube"
                >
                  <i class="fa fa-youtube-play fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Youtube</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.linkedin.com/company/grupo-db-menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Linkedin"
                >
                  <i class="fa fa-linkedin fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Linkendin</span>
                </a>
              </li>
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <base-dropdown>
                    <base-button
                      slot="title"
                      type="secondary"
                      class="dropdown-toggle"
                      >{{
                        this.user
                          ? this.user.lastname_1
                            ? this.user.name + " " + this.user.lastname_1
                            : this.user.name
                          : "Perfil"
                      }}</base-button
                    >
                    <button
                      v-if="this.user.contract"
                      class="dropdown-item"
                      @click="goProfile()"
                    >
                      Perfil
                    </button>
                    <button class="dropdown-item" @click="logout()">
                      Cerrar
                    </button>
                  </base-dropdown>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
      </div>
      <div class="d-none d-sm-block d-md-none">
        <header v-if="!session">
          <base-nav
            transparent
            style="
              position: absolute;
              width: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 20px;
                maring-top: -1rem;
                margin-bottom: 10px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-8 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img src="img/brand/DB Menos logo 2020_membrete.png" />
                </a>
              </div>
              <div class="col-4 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <router-link
              style="width: auto; background-color: #4667a3"
              to="/register"
              class="btn btn-icon text-white"
            >
              <span class="btn-inner--icon" style="margin-left: -2px">
                <img
                  src="img/brand/DB Menos logo 2020_Monograma original.png"
                />
              </span>
              <span class="nav-link-inner--text init" style="margin-left: 15px"
                >INICIA SESIÓN</span
              >
            </router-link>
          </base-nav>
        </header>
        <header v-else>
          <base-nav
            transparent
            type
            expand
            style="
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand mr-lg-12"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 1px;
                margin-bottom: 1px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-8 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img src="img/brand/DB Menos logo 2020_membrete.png" />
                </a>
              </div>
              <div class="col-4 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  BLOG
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link
                    to="/blogs"
                    style="text-black"
                    class="dropdown-item"
                    >Blog</router-link
                  >
                  <router-link
                    to="/postWebinars"
                    style="text-black"
                    class="dropdown-item"
                    >Video Conferencias</router-link
                  >
                </div>
              </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.facebook.com/grupodbmenos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Facebook"
                >
                  <i class="fa fa-facebook fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Facebook</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.instagram.com/db_menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Instagram"
                >
                  <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Instagram</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Youtube"
                >
                  <i class="fa fa-youtube-play fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Youtube</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.linkedin.com/company/grupo-db-menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Linkedin"
                >
                  <i class="fa fa-linkedin fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Linkendin</span>
                </a>
              </li>
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <base-dropdown>
                    <base-button
                      slot="title"
                      type="secondary"
                      class="dropdown-toggle"
                      >{{
                        this.user
                          ? this.user.lastname_1
                            ? this.user.name + " " + this.user.lastname_1
                            : this.user.name
                          : "Perfil"
                      }}</base-button
                    >
                    <button
                      v-if="this.user.contract"
                      class="dropdown-item"
                      @click="goProfile()"
                    >
                      Perfil
                    </button>
                    <button class="dropdown-item" @click="logout()">
                      Cerrar
                    </button>
                  </base-dropdown>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
      </div>
      <div class="d-none d-md-block d-lg-none">
        <header v-if="!session">
          <base-nav
            transparent
            style="
              position: absolute;
              width: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 20px;
                maring-top: -1rem;
                margin-bottom: 10px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-6 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img src="img/brand/DB Menos logo 2020_membrete.png" />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <router-link
              style="width: auto; background-color: #4667a3"
              to="/register"
              class="btn btn-icon text-white"
            >
              <span class="btn-inner--icon">
                <img
                  src="img/brand/DB Menos logo 2020_Monograma original.png"
                />
              </span>
              <span class="nav-link-inner--text init" style="margin-left: 15px"
                >INICIA SESIÓN</span
              >
            </router-link>
          </base-nav>
        </header>
        <header v-else>
          <base-nav
            transparent
            type
            expand
            style="
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand mr-lg-12"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 1px;
                margin-bottom: 1px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-8 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img src="img/brand/DB Menos logo 2020_membrete.png" />
                </a>
              </div>
              <div class="col-4 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  BLOG
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link
                    to="/blogs"
                    style="text-black"
                    class="dropdown-item"
                    >Blog</router-link
                  >
                  <router-link
                    to="/postWebinars"
                    style="text-black"
                    class="dropdown-item"
                    >Video Conferencias</router-link
                  >
                </div>
              </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.facebook.com/grupodbmenos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Facebook"
                >
                  <i class="fa fa-facebook fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Facebook</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.instagram.com/db_menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Instagram"
                >
                  <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Instagram</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Youtube"
                >
                  <i class="fa fa-youtube-play fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Youtube</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.linkedin.com/company/grupo-db-menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Linkedin"
                >
                  <i class="fa fa-linkedin fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Linkendin</span>
                </a>
              </li>
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <base-dropdown>
                    <base-button
                      slot="title"
                      type="secondary"
                      class="dropdown-toggle"
                      >{{
                        this.user
                          ? this.user.lastname_1
                            ? this.user.name + " " + this.user.lastname_1
                            : this.user.name
                          : "Perfil"
                      }}</base-button
                    >
                    <button
                      v-if="this.user.contract"
                      class="dropdown-item"
                      @click="goProfile()"
                    >
                      Perfil
                    </button>
                    <button class="dropdown-item" @click="logout()">
                      Cerrar
                    </button>
                  </base-dropdown>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
      </div>
      <div class="d-none d-lg-block d-xl-none">
        <header v-if="!session" class="header-global">
          <base-nav
            transparent
            type
            expand
            style="
              position: absolute;
              width: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 20px;
                maring-top: -1rem;
                margin-bottom: 10px;
              "
            >
              <img
                style="margin-top: 0.5rem"
                src="/img/brand/DB Menos logo original 2020_.png"
              />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-6 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 20%;
                    margin-bottom: 15px;
                  "
                >
                  <img
                    style="width: 80%"
                    src="img/brand/DB Menos logo 2020_membrete.png"
                  />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <router-link
                    style="width: 13rem; background-color: #4667a3"
                    to="/register"
                    class="btn btn-icon text-white"
                  >
                    <span class="btn-inner--icon" style="margin-left: -2px">
                      <img
                        src="img/brand/DB Menos logo 2020_Monograma original.png"
                      />
                    </span>
                    <span
                      class="nav-link-inner--text init"
                      style="margin-left: 15px"
                      >INICIA SESIÓN</span
                    >
                  </router-link>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
        <header v-else class="header-global">
          <base-nav
            transparent
            type
            expand
            style="
              background-size: cover;
              background-repeat: no-repeat;
              height: 100px;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand mr-lg-5"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 1px;
                margin-bottom: 1px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-6 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img
                    style="width: 9rem"
                    src="img/brand/DB Menos logo 2020_membrete.png"
                  />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  BLOG
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link
                    to="/blogs"
                    style="text-black"
                    class="dropdown-item"
                    >Blog</router-link
                  >
                  <router-link
                    to="/postWebinars"
                    style="text-black"
                    class="dropdown-item"
                    >Video Conferencias</router-link
                  >
                </div>
              </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.facebook.com/grupodbmenos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Facebook"
                >
                  <i class="fa fa-facebook fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Facebook</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.instagram.com/db_menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Instagram"
                >
                  <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Instagram</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Youtube"
                >
                  <i class="fa fa-youtube-play fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Youtube</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.linkedin.com/company/grupo-db-menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Linkedin"
                >
                  <i class="fa fa-linkedin fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Linkendin</span>
                </a>
              </li>
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <base-dropdown>
                    <base-button
                      slot="title"
                      type="secondary"
                      class="dropdown-toggle"
                      >{{
                        this.user
                          ? this.user.lastname_1
                            ? this.user.name + " " + this.user.lastname_1
                            : this.user.name
                          : "Perfil"
                      }}</base-button
                    >
                    <button
                      v-if="this.user.contract"
                      class="dropdown-item"
                      @click="goProfile()"
                    >
                      Perfil
                    </button>
                    <button class="dropdown-item" @click="logout()">
                      Cerrar
                    </button>
                  </base-dropdown>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
      </div>
      <div class="d-none d-xl-block">
        <header v-if="!session" class="header-global">
          <base-nav
            transparent
            type
            expand
            style="
              position: absolute;
              width: 100%;
              background-size: cover;
              background-repeat: no-repeat;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 20px;
                maring-top: -1rem;
                margin-bottom: 10px;
              "
            >
              <img
                style="margin-top: 0.5rem"
                src="/img/brand/DB Menos logo original 2020_.png"
              />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-6 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 20%;
                    margin-bottom: 15px;
                  "
                >
                  <img
                    style="width: 80%"
                    src="img/brand/DB Menos logo 2020_membrete.png"
                  />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <router-link
                    style="width: 13rem; background-color: #4667a3"
                    to="/register"
                    class="btn btn-icon text-white"
                  >
                    <span class="btn-inner--icon" style="margin-left: -2px">
                      <img
                        src="img/brand/DB Menos logo 2020_Monograma original.png"
                      />
                    </span>
                    <span
                      class="nav-link-inner--text init"
                      style="margin-left: 15px"
                      >INICIA SESIÓN</span
                    >
                  </router-link>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
        <header v-else class="header-global">
          <base-nav
            transparent
            type
            expand
            style="
              background-size: cover;
              background-repeat: no-repeat;
              height: 100px;
              background-image: url('img/brand/Header_1440_100.png');
            "
          >
            <a
              slot="brand"
              class="navbar-brand mr-lg-5"
              href="/"
              style="
                color: rgba(0, 0, 0, 0);
                font-size: 1px;
                margin-bottom: 1px;
              "
            >
              <img src="/img/brand/DB Menos logo original 2020_.png" />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-6 collapse-brand">
                <a
                  href="/"
                  style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 25px;
                    margin-bottom: 15px;
                  "
                >
                  <img
                    style="width: 9rem"
                    src="img/brand/DB Menos logo 2020_membrete.png"
                  />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>
            <ul class="navbar-nav navbar-nav-hover align-items-lg-center">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  BLOG
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <router-link
                    to="/blogs"
                    style="text-black"
                    class="dropdown-item"
                    >Blog</router-link
                  >
                  <router-link
                    to="/postWebinars"
                    style="text-black"
                    class="dropdown-item"
                    >Video Conferencias</router-link
                  >
                </div>
              </li>
            </ul>
            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.facebook.com/grupodbmenos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Facebook"
                >
                  <i class="fa fa-facebook fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Facebook</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.instagram.com/db_menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Instagram"
                >
                  <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Instagram</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Youtube"
                >
                  <i class="fa fa-youtube-play fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Youtube</span>
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link nav-link-icon"
                  href="https://www.linkedin.com/company/grupo-db-menos/"
                  target="_blank"
                  data-toggle="tooltip"
                  title="Siguenos en Linkedin"
                >
                  <i class="fa fa-linkedin fa-lg" aria-hidden="true"></i>
                  <span class="nav-link-inner--text d-lg-none">Linkendin</span>
                </a>
              </li>
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <base-dropdown>
                    <base-button
                      slot="title"
                      type="secondary"
                      class="dropdown-toggle"
                      >{{
                        this.user
                          ? this.user.lastname_1
                            ? this.user.name + " " + this.user.lastname_1
                            : this.user.name
                          : "Perfil"
                      }}</base-button
                    >
                    <button
                      v-if="this.user.contract"
                      class="dropdown-item"
                      @click="goProfile()"
                    >
                      Perfil
                    </button>
                    <button class="dropdown-item" @click="logout()">
                      Cerrar
                    </button>
                  </base-dropdown>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
      </div>
    </section>
  </div>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import moment from "moment";
import Vue from "vue";
import { V } from "../V";
export default {
  async created() {
    this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
    this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    this.$forceUpdate();
    //await this.checkSession()
    this.getAccountData();
  },
  computed: {
    //        user() { return localStorage.getItem(V.STORE_KEYS.USER) },
    //        session() { return localStorage.getItem(V.STORE_KEYS.SESSION) }
  },
  data() {
    return {
      session: null,
      account: {},
      user: {},
    };
  },
  mounted() {
    this.$root.$on("refreshHeader", (query) => {
      this.$forceUpdate();
      this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
      this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    });
  },
  methods: {
    async logout() {
      this.$store.commit("setUser", {});
      localStorage.clear();
      await this.checkSession();
      this.getAccountData();
      this.$root.$emit("refreshHeader");
      //console.log("Cerrar sesión")
      this.$router.push("/").catch((err) => {});
    },
    async checkSession() {
      let _session = localStorage.getItem(this.$v.STORE_KEYS.SESSION);
      if (_session) {
        this.session = _session;
        let currentDate = moment();
        let expiresDate = moment(this.session.expires, "YYYY-MM-DD");

        if (
          expiresDate.isSame(currentDate) ||
          expiresDate.isBefore(currentDate)
        ) {
          this.logout();
        } else {
          try {
            let validSession = await this.verifySession();
            if (!validSession) {
              this.logout();
            }
          } catch (error) {
            this.logout();
          }
        }
      } else {
        this.session = {
          session: "",
        };
      }
    },
    getAccountData() {
      let _account = this.$store.getters.getAccount;
      if (_account) {
        this.account = _account;
      } else {
        this.account = {};
      }
    },
    goProfile() {
      let url = V.SERVER + V.OPS.GET_SIGNATURE_INFO;
      this.$axios.get(url, this.session).then((response) => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          this.$router.push("/profile").catch((err) => {});
        }
      });
    },
    goRegister() {
      let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          if (
            data.row.basic_information == 0 ||
            data.row.basic_information2  == 0  ||
            data.row.address_information  == 0 ||
            data.row.official_id  == 0
          ) {
            this.$router.push("/start");
          } else if (
            data.row.basic_information == 1 &&
            data.row.basic_information2 == 1 &&
            data.row.address_information == 1 &&
            data.row.official_id == 1 &&
            data.row.credit_report == 0
          ) {
            this.$router.push({
              path: "/AuthorizationBC",
            });
          } else if (
            data.row.basic_information == 1 &&
            data.row.basic_information2 == 1 &&
            data.row.address_information == 1 &&
            data.row.official_id == 1 &&
            data.row.credit_report == 1 &&
            data.row.program_calculation == 0 
          ) {
            this.$router.push({
              path: "/calcular",
            });
          } else if (
            data.row.basic_information == 1 &&
            data.row.basic_information2 == 1 &&
            data.row.address_information == 1 &&
            data.row.official_id == 1 &&
            data.row.credit_report == 1 &&
            data.row.program_calculation == 1 &&
            data.row.contract == 0 &&
            data.row.active == 0
          ) {
            this.$router.push({
              path: "/start2",
            });
          } else if (
            data.row.basic_information == 1 &&
            data.row.basic_information2 == 1 &&
            data.row.address_information == 1 &&
            data.row.official_id == 1 &&
            data.row.credit_report == 1 &&
            data.row.program_calculation == 1 &&
            data.row.contract == 1 &&
            data.row.active == 0
          ) {
            this.$router.push({
              path: "/finish",
            });
          } else if (
            (data.row.basic_information == 1 &&
              data.row.basic_information2 == 1 &&
              data.row.address_information == 1 &&
              data.row.official_id == 1 &&
              data.row.credit_report  == 1 &&
              data.row.program_calculation == 1 &&
              data.row.contract == 1) ||
            data.row.active == 1
          ) {
            this.$router.push({
              path: "/profile",
            });
          }
        }
      });
    },
    async verifySession() {
      try {
        let res = await this.$axios.post(V.OPS.VALIDATE_SESSION);
        if (res.data.valid) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        //                console.log(error)
        return false;
      }
    },
  },
  updated() {
    //this.$forceUpdate()
    //this.getAccountData()
  },
  watch: {
    $route(to, from) {
      if (
        (to.name === "profile" || to.name === "calculator") &&
        from.name === "login"
      ) {
        this.checkSession();
        this.getAccountData();
      }
    },
  },
  components: {
    BaseNav,
    CloseButton,
    BaseDropdown,
  },
};
</script>
<style>
@font-face {
  font-family: Roboto-Bold;
  src: url(/fonts/Roboto_font/Roboto-Bold.ttf);
}
@font-face {
  font-family: Roboto-Black;
  src: url(/fonts/Roboto_font/Roboto-Black.ttf);
}
.init {
  font-size: 1.1rem;
  font-family: Roboto-Black;
}
</style>
