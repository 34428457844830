<template>

  <div class="bg-principal">

    <div class="row pt-5">
      <div class="col-12 text-center">
        <img :src="images.logo" alt="logo" class="img-logo">
      </div>
    </div>

    <div class="row">
      <div class="col-12 text-center">
        <p class="font-weight-bold primary-text">
          SOMOS UNA REPARADORA <br>
          DE CRÉDITO MEXICANA</p>
        <p class="secondary-text">Ayudamos a liquidar tus deudas hasta un <b class="secondary-text-bold">70% de descuento.</b></p>
        <img :src="images.women" alt="women" class="img-women">
      </div>

    </div>


  </div>

</template>

<script>
export default {
  name: "Principal",
  data: () =>
      ({
        images:
            {
              logo: '/img/landing_page/desktop/logo.png',
              women: '/img/how_does_it_work/desktop/women.png'
            },
      })
}
</script>

<style scoped>

.bg-principal {
  background: linear-gradient(to bottom, #2A3D99, #29AAE1);
}

.primary-text
{
  font-family: Gotham-Ultra;
  color: #FFFFFF;
}

.secondary-text
{
  font-family: "OpenSans-Regular";
  color: #FFFFFF;
}

.secondary-text-bold
{
  font-family: "OpenSans-ExtraBold";
  color: #FFFFFF;
}

@media (min-width: 320px) and (max-width: 374px)
{
  /*.bg-principal
  {
    margin-top: 5rem;
  }*/

  .primary-text
  {
    font-size: 1rem;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .img-logo
  {
    width: 8rem;
  }

  .img-women
  {
    width: 15rem;
  }
}
@media (min-width: 375px) and (max-width: 424px)
{
  /*.bg-principal
  {
    margin-top: 5rem;
  }*/

  .primary-text
  {
    font-size: 1rem;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .img-logo
  {
    width: 8rem;
  }

  .img-women
  {
    width: 15rem;
  }
}
@media (min-width: 425px) and (max-width: 767px)
{
  /*.bg-principal
  {
    margin-top: 5rem;
  }*/

  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.4rem;
  }

  .img-logo
  {
    width: 10rem;
  }

  .img-women
  {
    width: 17rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px)
{
  /*.bg-principal
  {
    margin-top: 5rem;
  }*/

  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.4rem;
  }

  .img-logo
  {
    width: 10rem;
  }

  .img-women
  {
    width: 17rem;
  }
}
@media (min-width: 1024px) and (max-width: 1439px)
{
  /*.bg-principal
  {
    margin-top: 6rem;
  }*/

  .primary-text
  {
    font-size: 2.5rem;
  }

  .secondary-text
  {
    font-size: 1.5rem;
  }

  .img-logo
  {
    width: 14rem;
  }

  .img-women
  {
    width: 20rem;
  }
}
@media (min-width: 1440px) and (max-width: 2559px)
{
  /*.bg-principal
  {
    margin-top: 6rem;
  }*/

  .primary-text
  {
    font-size: 2.5rem;
  }

  .secondary-text
  {
    font-size: 1.5rem;
  }

  .img-logo
  {
    width: 14rem;
  }

  .img-women
  {
    width: 20rem;
  }
}
@media (min-width: 2560px) and (max-width: 2560px)
{

  /*.bg-principal
  {
    margin-top: 7rem;
  }*/

  .primary-text
  {
    font-size: 3rem;
  }

  .secondary-text
  {
    font-size: 1.8rem;
  }

  .img-logo
  {
    width: 18rem;
  }

  .img-women
  {
    width: 27rem;
  }
}

</style>