<template>
    <div>
        <!--div v-if="hasFileOnServer()" style="text-align: center;"><span>{{text}} <a :href="getFileUrl()" target="_blank"><i class="ni ni-cloud-download-95 text-primary"></i></a></span></div-->
        <div v-if="(hasFileOnServer() || uploaded)" style="display:flex; justify-content:center;">
            <div style="font-weight:400; font-size: 12px;">Cargada</div>
            <div style="opacity:0">n</div>
            <div><a :href="getFileUrl()" target="_blank"><i class="ni ni-cloud-download-95 text-primary"></i></a></div>
        </div>
        <div class="dropbox">
            <input type="file" :name="text" accept="*/*" class="input-file" @change="fileSelected($event.target.name, $event.target.files)">
            <img v-if="(hasFileOnServer() && !selectedFile)" :src="getFileUrl()" :alt="text" style="width: 100%; max-height: 500px" />
            <p v-if="(!selectedFile && !hasFileOnServer())">{{selectedFile? selectedFile.name : "Arrastra o da click para seleccionar el archivo"}}</p>
            <img v-if="selectedFile" :src="previewURL" :alt="text" style="width: 100%; max-height: 500px"/>
        </div>
        <div >
        <base-button v-if="!(hasFileOnServer() || uploaded) && !(ocrFile || filesBandera)" style="width: 100%; margin-top: 8px; background-color:#FFD527; border-color:#FFD527; color:#3953D3;" v-on:click="requestUploadFile()">{{buttonText ? buttonText : 'Subir'}}</base-button>
        <!-- <base-button v-if="(hasFileOnServer() || uploaded) && (contractRisk || ocrFile || filesBandera)" style="width: 100%; margin-top: 8px; background-color:#FFD527; border-color:#FFD527; color:#3953D3;" v-on:click="resendUploadFile()">{{buttonText ? buttonText : 'Reenviar'}}</base-button> -->

    </div>    
    </div>
</template>

<script>
    export default {
        name: "Dropbox",
        data() {
            return {
                selectedFile: null,
                previewURL: null,
                uploaded: null,
                 isHidden: false,
            }
        },
        props: {
            userid: Number,
            text: String,
            type: String,
            files: Array,
            buttonText: String,
            contractRisk:Boolean,
            ocrFile:Boolean,
            filesBandera:Boolean,
            filePayment:Boolean,
        },
        methods: {
            fileSelected(fieldName, files) {
                if (!files.length) return
                let f = files[0]
                this.selectedFile = f
                if (!f.type.match('image.*')){
                    if(!f.type.match('application.*')){
                        return
                    }
                }
                const reader = new FileReader()
                const c = this
                reader.onload = function (e) {
                    c.previewURL = e.target.result
                }
                reader.readAsDataURL(f)
                if(this.ocrFile || this.filesBandera || this.filePayment){
                this.requestUploadFile();
                }
            },
            requestUploadFile() {
                this.$emit('uploadFile', {
                    type: this.type,
                    file: this.selectedFile,
                    text: this.text
                })
                this.uploaded = true;
                this.isHidden =true;
            },
            resendUploadFile() {
                this.$emit('resentUploadFile', {
                    type: this.type,
                    file: this.selectedFile,
                    text: this.text
                })
                this.uploaded = true;
                this.isHidden =true;
            },
            getFileUrl() {
                let filename = ""
                for (var i = 0; i < this.files.length; i++) {
                    if (this.files[i].type == this.type) {
                        filename = this.files[i].filename;
                    }
                }
                let session = localStorage.getItem(this.$v.STORE_KEYS.SESSION)
                if (session) {
                    return this.$v.SERVER + this.$v.OPS.GET_USER_FILE + "?user=" + this.userid + "&filename=" +
                        filename + "&redirect=true" + "&session=" + session
                }else{
                    return this.$v.SERVER + this.$v.OPS.GET_FILE_URL_API + "?user=" + this.userid + "&filename=" +
                        filename
                }
            },
            hasFileOnServer() {
                for (var i = 0; i < this.files.length; i++) {
                    if (this.files[i].type == this.type) {
                        return true
                    }
                }
                if (!this.files) return false
                for (let i=0 ; i<this.files.length ; i++) {
                    let file = this.files[i]
                    if (file.type === this.type) {
                        files = file
                        return true
                    }
                }
                return false
            }
        },
    }
</script>

<style lang="scss">
    .dropbox {
        outline: 2px dashed blue; /* the dash box */
        outline-offset: -10px;
        background: #FFFFFF;
        border-radius: 10px;
        color: #3953D3;
        padding: 10px 10px;
        min-height: 100px; /* minimum height */
        position: relative;
        cursor: pointer;
    }

    .input-file {
        opacity: 0; /* invisible but it's there! */
        width: 100%;
        height: 100px;
        position: absolute;
        cursor: pointer;
    }

    .dropbox:hover {
        background: #f7fafc; /* when mouse over to the drop zone, change color */
    }

    .dropbox p {
        font-size: 1em;
        text-align: center;
        padding: 25px;
    }
</style>