<template>
    <div v-if="window.width < window.height" id="pleaserotate-backdrop" style="display: block;">
        <div id="pleaserotate-container">
            <svg xmlns:xlink="http://www.w3.org/1999/xlink" id="pleaserotate-graphic" viewBox="0 0 250 250">
                <g id="pleaserotate-graphic-path">
                    <path d="M190.5,221.3c0,8.3-6.8,15-15,15H80.2c-8.3,0-15-6.8-15-15V28.7c0-8.3,6.8-15,15-15h95.3c8.3,0,15,6.8,15,15V221.3zM74.4,33.5l-0.1,139.2c0,8.3,0,17.9,0,21.5c0,3.6,0,6.9,0,7.3c0,0.5,0.2,0.8,0.4,0.8s7.2,0,15.4,0h75.6c8.3,0,15.1,0,15.2,0s0.2-6.8,0.2-15V33.5c0-2.6-1-5-2.6-6.5c-1.3-1.3-3-2.1-4.9-2.1H81.9c-2.7,0-5,1.6-6.3,4C74.9,30.2,74.4,31.8,74.4,33.5zM127.7,207c-5.4,0-9.8,5.1-9.8,11.3s4.4,11.3,9.8,11.3s9.8-5.1,9.8-11.3S133.2,207,127.7,207z">
                    </path>
                </g>
            </svg>
            <div id="pleaserotate-message">
                Por favor, activa la rotación automática de tu dispositivo y rótalo
                <small>(Esta página sólo funciona en orientación horizontal)</small>
            </div>
        </div>
    </div>
    <section v-else class="section section-shaped section-lg my-0" style="background-color: #3953D3; background-size: cover; opacity: 0.80">
        <div class="container" style="padding-bottom:15px">
            <div class="row justify-content-center">
                <div class="col-lg-10" style="opacity: 0.95">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-10 py-lg-10"
                          class="border-0">
                        <div class="row justify-content-around align-items-center">
                            <div class="col-lg-4 col-md-4 col-sm-5 col-8 text-center">
                                <!--<img src="https://dbmenos.com/img/finamigo.png" class="img-fluid" alt="Finamigo Logo">!-->
                            </div>
                            <div class="col-lg-7 col-md-7 col-sm-6 col-11" style="text-align: right;">
                                <h6>{{user.name}}</h6>
                                <h6>{{user.address}}</h6>
                                <h6>{{user.address_2}}</h6>
                                <h6>{{user.phone}}</h6>
                                <h6>{{user.email}}</h6>
                            </div>
                        </div>
                    </card>
                </div>
            </div>
        </div>
        <div class="container pt-lg-md" style="min-height: 730px">
            <div class="row justify-content-center">
                <div class="col-lg-10" style="opacity: 0.95">
                    <card type="secondary" shadow
                          header-classes="bg-white pb-5"
                          body-classes="px-lg-10 py-lg-10"
                          class="border-0">
                        <template>
                            <div v-if="validToken">
                                  <div v-if="signed == 1 && !cancelAddendum">
                                    <div class="text-center">
                                        <h5>Ya recibimos tu firma, gracias por confiar en nosotros</h5>
                                        <img src="img/theme/checkbox-marked-circle.png">
                                    </div>
                                </div>
                                <div v-else-if="sign === SIGN_ADDENDUM && !cancelAddendum">
                                    <div class="text-center text-muted mb-4">
                                        <small>Firma en el recuadro inferior despúes de haber leído los documentos adjuntos para agregar este anexo a tu contrato actual.</small>
                                        <p>
                                            <a :href="urlAddendum" target="_blank"><b>Ver Anexo</b></a>
                                        </p>
                                    </div>
                                     <div style="background-color: #FFFFFF; position: relative; margin-top:10px; width:100%; display: inline-block;">
                                        <VueSignaturePad
                                                height="300px"
                                                ref="signaturePad"/>
                                                
                                        <div class="signature mx-5 text-center noselect"><br>Firma<br><br></div>
                                    </div>
                                    <div class="row d-flex justify-content-around">
                                        <base-button type="secondary" class="my-4" v-on:click="clearSignature()">Borrar firma</base-button>
                                        <base-button type="danger" v-on:click="cancelAdd()" class="my-4">Rechazar Propuesta</base-button>
                                        <base-button type="primary" class="my-4" v-on:click="sendSignatureModify()">Firmar Documentos</base-button>
                                    </div>
                                </div>
                                <div v-else-if="sign === SIGN_LETTER_DBM">
                                    <div class="text-center text-muted mb-4">
                                        <small>Firma en el recuadro inferior despúes de haber leído los documentos adjuntos de tu carta DBM.</small>
                                        <p>
                                            <a :href="urlLetter" target="_blank"><b>Ver Anexo</b></a>
                                        </p>
                                    </div>
                                     <div style="background-color: #FFFFFF; position: relative; margin-top:10px; width:100%; display: inline-block;">
                                        <VueSignaturePad
                                                height="300px"
                                                ref="signaturePad"/>
                                                
                                        <div class="signature mx-5 text-center noselect"><br>Firma<br><br></div>
                                    </div>
                                    <div class="row d-flex justify-content-around">
                                        <base-button type="secondary" class="my-4" v-on:click="clearSignature()">Borrar firma</base-button>
                                        <base-button type="primary" class="my-4" v-on:click="sendSignatureLetterDBM()">Firmar Documentos</base-button>
                                    </div>
                                </div>
                                <div v-else-if="sign === SIGN_ACCOUNT_SETTLEMENT">
                                    <div class="text-center mb-4">
                                        <small>Ingresa tu firma para autorizar a Grupo DB Menos liquidar tu cuenta {{account.account}} perteneciente a {{nombre_banco}}</small>
                                    </div>
                                     <div style="background-color: #FFFFFF; position: relative; margin-top:10px; width:100%; display: inline-block;">
                                        <VueSignaturePad
                                                height="300px"
                                                ref="signaturePad"/>
                                                
                                        <div class="signature mx-5 text-center noselect"><br>Firma<br><br></div>
                                    </div>
                                    <div class="row d-flex justify-content-around">
                                        <base-button type="secondary" class="my-4" v-on:click="clearSignature()">Borrar firma</base-button>
                                        <base-button type="primary" class="my-4" v-on:click="sendSignature()">Solicitar liquidación de cuenta</base-button>
                                    </div>
                                </div>
                                <div v-else-if="waitingSignature && !(sign === SIGN_ADDENDUM)  && !(sign === SIGN_CONTRACT && signedForContract === 1) && !(sign !== SIGN_CONTRACT && signedForCreditReport === 1)">
                                    <div v-if="sign===SIGN_CONTRACT" class="text-center text-muted mb-4">
                                        <small>Autoriza el uso de tu firma para generar el contrato con DB Menos</small>
                                        <p>
                                            <a :href="urlContract" target="_blank"><b>Ver el contrato</b></a>
                                        </p>
                                    </div>
                                     <div v-else class="text-center text-muted mb-4">
                                        <small class="h6">Firma para autorizar la consulta del reporte de crédito</small>
                                       <small>
                                            <br>
                                            Por este conducto autorizo expresamente a FINANCIERA MEXICANA
                                            PARA EL DESARROLLO RURAL, S.A. DE C.V., S.F.P., Sociedad Financiera Popular para que por conducto de sus funcionarios facultados lleve a cabo Investigaciones, sobre mi comportamiento crediticio o el de la Empresa que represento en Trans Union de México, S.A. SIC, Círculo de Crédito, S.A. de C.V., SIC. y/o Dun & Bradstreet, S.A. SIC.
                                            Asimismo, declaro que conozco la naturaleza y alcance de las sociedades de información crediticia y de la información contenida en los reportes de crédito y reporte de crédito especial; declaro que conozco la naturaleza y alcance de la información que se solicitará, del uso que FINANCIERA MEXICANA PARA EL DESARROLLO RURAL, S.A. DE C.V., S.F.P., Sociedad Financiera Popular, hará de tal información y de que ésta podrá realizar consultas periódicas sobre mi historial o el de la empresa que represento, consintiendo que esta autorización se encuentre vigente por un período de 3 años contados a partir de su expedición y en todo caso durante el tiempo que se mantenga la relacion jurídica.
                                            En caso de que la solicitante sea una Persona Moral, declaro bajo protesta de decir verdad Ser Representante Legal de la empresa mencionada en esta autorización; manifestando que a la fecha de firma de la presente autorización los poderes no me han sido revocados, limitados, ni modificados en forma alguna.
                                        </small>
                                    </div>
                                    <div v-if="alreadySigned === 0 && !signaturePath">
                                     <div style="background-color: #FFFFFF; position: relative; margin-top:10px; width:100%; display: inline-block;">
                                        <VueSignaturePad
                                                height="300px"
                                                ref="signaturePad"/>
                                                
                                        <div class="signature mx-5 text-center noselect"><br>Firma<br><br></div>
                                    </div>
                                        <div class="row d-flex justify-content-around">
                                            <base-button type="secondary" class="my-4" v-on:click="clearSignature()">Borrar firma</base-button>
                                            <base-button type="primary" class="my-4" v-on:click="sendSignature()">{{sign===SIGN_CONTRACT?'Firmar contrato':'Solicitar consulta de reporte de crédito'}}</base-button>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <div style="background-color: #FFFFFF; margin-top: 5px">
                                            <img :src="signaturePath" alt="Tu firma">
                                        </div>
                                        <div class="row">
                                            <div class="col-lg-12 col-sm-12 text-center">
                                                <base-button type="primary" class="my-4" v-on:click="updateSignature()">{{sign===SIGN_CONTRACT?'Firmar contrato':'Solicitar consulta de reporte de crédito'}}</base-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="!cancelAddendum" class="text-center">
                                    <h5>Ya recibimos tu firma, gracias por confiar en nosotros</h5>
                                    <img src="img/theme/checkbox-marked-circle.png">
                                </div>
                            </div>
                            <div v-else-if="alreadySigned && !cancelAddendum" class="text-center">
                                    <h5>Ya recibimos tu firma, gracias por confiar en nosotros</h5>
                                    <img src="img/theme/checkbox-marked-circle.png">
                                </div>
                            <div v-else class="text-center">
                                <h5>No logramos validar tu enlace, comúnicate con tu asesor</h5>
                                <img src="img/theme/close-circle.png">
                            </div>
                            <div v-if="cancelAddendum" class="text-center">
                                <h5>Esta propuesta a sido Rechazada, comúnicate con tu asesor</h5>
                                <img src="img/theme/close-circle.png">
                            </div>
                        </template>
                    </card>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import firebase from 'firebase/app';
    import 'firebase/firestore';
    import 'firebase/analytics';
    import 'firebase/messaging';
    import Vue from "vue"
    import VueResource from "vue-resource"
    import {V} from "@/V"
    import VueSignaturePad from 'vue-signature-pad'
    import VueFacebookPixel from 'vue-analytics-facebook-pixel'
    Vue.use(VueFacebookPixel)
    Vue.use(VueResource)
    export default {
        components: {
            VueFacebookPixel,
            VueSignaturePad
        },
        computed: {
            banks() { return this.$store.state.banks },
            nombre_banco: function() {
                return this.getBank(this.account.bank).name
            }
        },
        async created() {
            fbq('track', 'step_six');
           firebase.analytics().logEvent('step_six');
            gtag('event', 'step_six', {'send_to': 'AW-718930865/2GivCITQvKcBELGH6NYC'});
            window.addEventListener('resize', this.handleResize)
            this.handleResize()
            //this.getFilesInfo();
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            this.validateToken()
            this.handleResize()  
        },
        data() {
            return {
                token: null,
                sign: null,
                validToken: false,
                account: null,
                signToken: null,
                signed: null,
                signedAdd: null,
                waitingSignature: true,
                alreadySigned: false,
                signedForCreditReport: false,
                signedForContract: false,
                signaturePath: null,
                SIGN_CONTRACT: 'contract',
                SIGN_ACCOUNT_SETTLEMENT: 'account',
                SIGN_ADDENDUM: 'addendum',
                SIGN_LETTER_DBM : 'letter_dbm',
                cancelAddendum:false,
                urlLetter:"",
                urlContract:"",
                user: {
                    name: null,
                    address: null,
                    address_2: null,
                    phone: null,
                    email: null
                },

                window: {
                    width: 0,
                    height: 0
                },
                userFiles: [],
                urlAddendum:"",
                files: {
                APPROACH_ADDENDUM: null

            },
            }
        },
        methods: {
            cancelAdd(){
            let url = V.SERVER + V.OPS.CANCEL_ADDENDUMS;
            this.$axios.post(url,{token: this.token}).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.cancelAddendum = true;
                    this.$notify({
                        text: "Propuesta Rechazada",
                        type: "danger",
                    });
                }
            });
            },/*
            getFilesInfo() {
            let token= this.$route.query.tkadd;
            let token_split=token.split("_")
            let idAdd= token_split[token_split.length - 1]
            let idUser=token_split[0]
            let url = V.SERVER + V.OPS.GET_USER_FILE_ADD + "?user=" +idUser;
            let request ={
                user: idUser
            };
            this.$axios.post(url,request).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.userFiles = data.rows;
                    for (var i = 0; i < this.userFiles.length; i++) {
                        if (this.userFiles[i].type == "APPROACH_ADDENDUM") {
                            let filename_a=this.userFiles[i].filename;
                            if(filename_a.includes(idAdd)){
                            this.files.APPROACH_ADDENDUM = true;
                            let url2 = V.SERVER + V.OPS.GET_FILE_URL +
                            "?filename=" +
                            filename_a +
                            "&user="+
                            idUser;
                            this.$axios.get(url2).then(res => {
                                let data2 = res.data; 
                                if (data[V.API.SUCCESS]) {
                                this.urlAddendum = data2.row.url
                                }
                            });
                            }
                        }
                    }
                } else {
                    console.log("error d_userFiles");
                }
            });
        },
        */
            getBank(id) {
                for (let i=0 ; i<this.banks.length ; i++)
                    if (this.banks[i].id === id)
                        return this.banks[i]
                return null
            },
            clearSignature() {
                this.$refs.signaturePad.undoSignature()
            },
            updateSignature() {
                let body = {
                    "token": this.token
                }
                if (this.sign === this.SIGN_CONTRACT) {
                    body.for_contract = 1
                } else {
                    body.for_credit_report = 1
                }
                this.$showLoader(true)
                this.$axios.post(V.SERVER + V.OPS.UPDATE_SIGNATURE, body).then(res => {
                    this.$showLoader(false)

                    fbq('track', 'signature_submitted');
                  firebase.analytics().logEvent("signature_submitted");
                    gtag('event', 'signature_submitted', {'send_to': 'AW-718930865/2GivCITQvKcBELGH6NYC'});

//                    console.log(res)
                    if (res.data.success  || res.data.reason==5) {
                        this.signed = true
                    }
                }).catch(e => {
//                    console.log(e)
                    this.$showLoader(false)
                    alert("No se logró subir, intente nuevamente")
                })
            },
            sendSignatureModify(){
                 this.$showLoader(true)
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                if (!isEmpty && data) {
                    this.signatureData = data
                    let block = data.split(";")
                    let contentType = block[0].split(":")[1]
                    let sData = block[1].split(',')[1]
                    let blob = this.base64ToBlob(sData, contentType)
                    let form = new FormData();
                    form.append("signature", blob);
                    let headers = {
                        "token": this.token
                    }
                    let url = V.SERVER + V.OPS.SET_ADDENDUM_SIGNATURE
                    this.$axios.post(url, form, {headers: headers}).then(res => {
                        this.$showLoader(false)
                        if (res.data.success || res.data.reason==5) {
                            this.signed = true
                        }
                    }).catch(e => {
//                        console.log(e)
                        this.$showLoader(false)
                        alert("No se logró subir, intente nuevamente")
                    })
                }
            },
            sendSignatureLetterDBM(){
                 this.$showLoader(true)
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                if (!isEmpty && data) {
                    this.signatureData = data
                    let block = data.split(";")
                    let contentType = block[0].split(":")[1]
                    let sData = block[1].split(',')[1]
                    let blob = this.base64ToBlob(sData, contentType)
                    let form = new FormData();
                    form.append("signature", blob);
                    let headers = {
                        "token": this.token
                    }
                    let url = V.SERVER + V.OPS.SET_LETTER_DBM_SIGNATURE
                    this.$axios.post(url, form, {headers: headers}).then(res => {
                        this.$showLoader(false)
                        if (res.data.success || res.data.reason==5) {
                            this.signed = true
                        }else{
                            this.signed = true
                            window.location.reload();
                        }
                    }).catch(e => {
//                        console.log(e)
                        this.$showLoader(false)
                        alert("No se logró subir, intente nuevamente")
                    })
                }
            },
            sendSignature() {
                this.$showLoader(true)
                const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
                if (!isEmpty && data) {
                    this.signatureData = data
                    let block = data.split(";")
                    let contentType = block[0].split(":")[1]
                    let sData = block[1].split(',')[1]
                    let blob = this.base64ToBlob(sData, contentType)
                    let form = new FormData();
                    form.append("signature", blob);
                    let headers = {
                        "token": this.token
                    }
                    if (this.sign === this.SIGN_CONTRACT) {
                        headers.for_contract = 1
                        headers.for_credit_report = 0
                    } else {
                        headers.for_contract = 0
                        headers.for_credit_report = 1
                    }
                    let url = this.sign === this.SIGN_ACCOUNT_SETTLEMENT
                        ? V.SERVER + V.OPS.SET_ACCOUNT_SIGNATURE
                        : V.SERVER + V.OPS.SET_CREDIT_REPORT_SIGNATURE
                    this.$axios.post(url, form, {headers: headers}).then(res => {
                        this.$showLoader(false)
//                        console.log(res)
                        if (res.data.success || res.data.reason==5) {
                            this.signed = true
                            //this.$router.push({ path: '/FinishWin'})
                        }
                    }).catch(e => {
//                        console.log(e)
                        this.$showLoader(false)
                        alert("No se logró subir, intente nuevamente")
                    })
                }
            },
            openDocument(filename) {
                window.open(
                    V.SERVER +
                        V.OPS.GET_USER_FILE +
                        "?filename=" +
                        filename +
                        "&session=" +
                        this.session.session +
                        "&redirect=true"
                );
            },
            base64ToBlob(b64Data, contentType, sliceSize) {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                let byteCharacters = atob(b64Data);
                let byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    let slice = byteCharacters.slice(offset, offset + sliceSize);

                    let byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    let byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

                return new Blob(byteArrays, {type: contentType});
            },
            async validateToken() {
                this.$showLoader(true)
                if (this.$route.query.token) {
                    this.sign = this.$route.query.sign
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?token=" + this.$route.query.token
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row
                        if (data.success) {
                            this.token = this.$route.query.token
                            this.validToken = true
                            this.alreadySigned = _token.signed
                            this.signedForCreditReport = _token.for_credit_report
                            this.signedForContract = _token.for_contract
                            this.signaturePath = _token.path
                            this.urlContract= data.url
                            this.user.name = data.user.name
                            this.user.address = 'Calle ' + data.user.street + ' #' + data.user.extnum + ', ' + data.user.sublocality
                            if((data.user.postal_code + "").length === 4){
                            this.user.address_2 = 'C.P. 0' + data.user.postal_code + ', ' + data.user.state_of_birth
                            }else if ((data.user.postal_code + "").length === 5){
                            this.user.address_2 = 'C.P. ' + data.user.postal_code + ', ' + data.user.state_of_birth
                            }
                            this.user.phone = 'Tel. ' + data.user.phone
                            this.user.email = 'e-mail: ' + data.user.email
                            this.$forceUpdate()
                        }
                        else if(data.reason == 5) {
                            this.alreadySigned = true
                        }
                    })
                }
                else if (this.$route.query.t) {
                    this.sign = this.SIGN_ACCOUNT_SETTLEMENT
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?t=" + this.$route.query.t
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row.token
                        let _account = data.row.account
                        if (data.success) {
                            this.signToken = _token
                            this.account = _account
                            this.token = this.$route.query.t
                            this.validToken = true
                            this.signed = _token.signed
                            this.$forceUpdate()
                        }
                    })
                }
                else if (this.$route.query.tkadd) {
                    this.sign = this.SIGN_ADDENDUM
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?tkadd=" + this.$route.query.tkadd
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row
                        if (data.success) {
                            this.token = this.$route.query.tkadd
                            this.validToken = true
                            this.urlAddendum= data.url
                            this.signed = _token.signed
                            this.user.name = data.user.name
                            this.user.address = 'Calle ' + data.user.street + ' #' + data.user.extnum + ', ' + data.user.sublocality
                            if((data.user.postal_code + "").length === 4){
                            this.user.address_2 = 'C.P. 0' + data.user.postal_code + ', ' + data.user.state_of_birth
                            }else if ((data.user.postal_code + "").length === 5){
                            this.user.address_2 = 'C.P. ' + data.user.postal_code + ', ' + data.user.state_of_birth
                            }
                            this.user.phone = 'Tel. ' + data.user.phone
                            this.user.email = 'e-mail: ' + data.user.email
                           
//                            console.log(_token)
                            this.$forceUpdate()
                        }else if(data.reason == 5) {
                            this.alreadySigned = true
                        }else if(data.reason === 8){
                            this.token = this.$route.query.tkadd
                            let url = V.SERVER + V.OPS.OUT_TIME_ADD;
                            this.$axios.post(url,{token: this.token}).then(response => {
                            let data = response.data;
                            if (data[V.API.SUCCESS]) {
                            this.$notify({
                                text: "Propuesta Fuera de Tiempo",
                                type: "danger",
                            });
                        }
                            });             
                        }
                    })
                }
                else if (this.$route.query.tkLetter) {
                    console.log(this.$route.query.tkLetter)
                    this.sign = this.SIGN_LETTER_DBM
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?tkLetter=" + this.$route.query.tkLetter
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row
                        if (data.success) {
                            this.token = this.$route.query.tkLetter
                            this.validToken = true
                            this.urlLetter= data.url
                            this.signed = _token.signed
                            this.user.name = data.user.name
                            this.user.address = 'Calle ' + data.user.street + ' #' + data.user.extnum + ', ' + data.user.sublocality
                            if((data.user.postal_code + "").length === 4){
                            this.user.address_2 = 'C.P. 0' + data.user.postal_code + ', ' + data.user.state_of_birth
                            }else if ((data.user.postal_code + "").length === 5){
                            this.user.address_2 = 'C.P. ' + data.user.postal_code + ', ' + data.user.state_of_birth
                            }
                            this.user.phone = 'Tel. ' + data.user.phone
                            this.user.email = 'e-mail: ' + data.user.email
                           
//                            console.log(_token)
                            this.$forceUpdate()
                        }
                        else if(data.reason == 5) {
                            this.alreadySigned = true
                        }
                    })
                }
                else this.$showLoader(false)
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;

                let footer = document.getElementById("footer")

                if (this.window.width < this.window.height) {
                    if (footer) footer.style.display = "none";
                } else {
                    if (footer) footer.style.display = "block";
                }
            }
        }
    };
</script>
<style>
    .signature {
        border: 0;
        border-top: 1px solid #000;

        left: 0;
        right: 0;
        bottom: -40px;
        margin: auto;
        position: absolute;
        pointer-events: none;
    }
    .noselect {
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
    }
    #pleaserotate-graphic {
        margin-left: 50px;
        width: 200px;
        animation:
        pleaserotateframes ease 2s;
        animation-iteration-count: infinite;
        transform-origin: 50% 50%;
        -webkit-animation: pleaserotateframes ease 2s;
        -webkit-animation-iteration-count: infinite;
        -webkit-transform-origin: 50% 50%;
        -moz-animation: pleaserotateframes ease 2s;
        -moz-animation-iteration-count: infinite;
        -moz-transform-origin: 50% 50%;
        -ms-animation: pleaserotateframes ease 2s;
        -ms-animation-iteration-count: infinite;
        -ms-transform-origin: 50% 50%;
    }
    #pleaserotate-backdrop {
        background-color: white;
        top: 0; left: 0; 
        position: fixed; 
        width: 100%; 
        height: 100%; 
    }
    #pleaserotate-container {
        width: 300px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
    }
    #pleaserotate-message {
        margin-top: 20px;
        font-size: 1.3em;
        text-align: center;
        font-family: Verdana, Geneva, sans-serif;
        text-transform: uppercase
    }
    #pleaserotate-message small {
        opacity: .5;
        display: block;
        font-size: .6em
    }
    @keyframes pleaserotateframes {
        0% {
            transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -ms-transform: rotate(0deg) ;
        }
        49% { 
            transform: rotate(-90deg);
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
        }
        100% {
            transform: rotate(90deg);
            -moz-transform: rotate(-90deg);
            -webkit-transform: rotate(-90deg);
            -ms-transform: rotate(-90deg);
        }
    }
</style>
