<template>
  <div class="text-center">
    <v-dialog
        persistent
        width="500px"
        v-model="this.video.play"
    >
      <template v-slot:activator="{ on, attrs }">
        <p v-bind="attrs"
           v-on="on"
           class="my-5 simple-text"
           v-on:click="changeStatusVideo({play:true, version:1})">
          <img src="/img/new_process/icons/eye.svg" alt="eye" class="icon-eye mx-1">
          <ins>¿Por qué te pedimos esta información?</ins>
        </p>
      </template>

      <v-card>
        <v-card-text>

          <div class="text-end">
            <v-icon
                class="mt-3"
                color="#2A3D99"
                v-on:click="changeStatusVideo({play:false, version:0})"
            >
              mdi-close
            </v-icon>
          </div>

          <p class="title-modal">¿Por qué te pedimos esta información?</p>
          <p class="text-modal" v-if="this.video.version===1">
            Al solicitar tu dirección es para consultar tu buró de crédito y así poder ofrecerte el
            mejor plan de pagos
            para ti. Tus datos son confidenciales.
          </p>
          <p class="text-modal" v-if="this.video.version===2">
            Para poder consultar tu buró de crédito.
            Tus datos son confidenciales.
          </p>

          <div class="text-center" v-if="this.video.version===1">
            <video
                class="i-frame"
                controls
                muted
                autoplay
                src="/videos/datos_buro_de_credito_v.mp4"
            ></video>
          </div>

          <div class="text-center" v-if="this.video.version===2">
            <video
                class="i-frame"
                controls
                muted
                autoplay
                src="/videos/detalles_del_programa_v.mp4"
            ></video>
          </div>

        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {mapState, mapActions} from 'vuex'

export default {
  name: "VideoModal",
  computed:
      {
        ...mapState(['video'])
      },
  methods:
      {
        ...mapActions(['changeStatusVideo']),
      },
}
</script>

<style scoped>

.simple-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.title-modal {
  font-family: OpenSans-Bold;
  color: #2A3D99;
}

.text-modal {
  font-family: OpenSans-Regular;
  color: gray;
}

video {
  width: 80%;
}

.icon-eye {
  width: 1rem;
}

@media (min-width: 320px) and (max-width: 374px) {

  .simple-text {
    font-size: .9rem;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: center;*/
  }

  .icon-eye {
    width: 1rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .simple-text {
    font-size: .9rem;
  }

  .title-modal {
    font-size: .9rem;
  }

  .text-modal {
    font-size: .8rem;
    /*text-align: justify;*/
  }

  .icon-eye {
    width: 1rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .simple-text {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: .9rem;
    /*text-align: justify;*/
  }

  .icon-eye {
    width: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .simple-text {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .icon-eye {
    width: 1rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .simple-text {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .icon-eye {
    width: 1rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  .simple-text {
    font-size: 1rem;
  }

  .title-modal {
    font-size: 1.1rem;
  }

  .text-modal {
    font-size: 1.1rem;
    /*text-align: justify;*/
  }

  .icon-eye {
    width: 1.2rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .simple-text {
    font-size: 1rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .icon-eye {
    width: 1.2rem;
  }
}

</style>