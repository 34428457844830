<template>
  <div >
    <section>
      <div class="d-block d-sm-none">
        <div class="position-relative text-center">
            <!-- shape Hero -->
            <section class="section" style="background-image: url('img/dbm/legal.jpg'); margin-left:-400px; height: 100vh; background-size: cover; margin-top:-100px; background-repeat: no-repeat; opacity: 0.90; !important;">

                <div class="text-center" style="position: absolute; color: #808080; width: 35%; top: 50%; left:58%;">
                    <div class="col">
                        <div class="row">
                            <div class="col-lg-12">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF">
                                              <span class="btn-inner--icon">
                                                  <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                              </span>
                                    <span class="nav-link-inner--text">Aviso de privacidad</span>
                                </a>
                            </div>
                            <div class="col-lg-12" style="margin-top: 20px">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF">
                                            <span class="btn-inner--icon">
                                                <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                            </span>
                                    <span class="nav-link-inner--text">Términos y condiciones</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
      <div class="d-none d-sm-block d-md-none">
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section" style="background-image: url('img/dbm/legal.jpg'); margin-left:-300px; height: 90vh; background-size: cover; margin-top:-100px; background-repeat: no-repeat; opacity: 0.90; !important;">

                <div class="text-center" style="position: absolute; color: #808080; width: 35%; top: 50%; left:45%;">
                    <div class="col">
                        <div class="row">
                            <div class="col-lg-12">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF">
                                              <span class="btn-inner--icon">
                                                  <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                              </span>
                                    <span class="nav-link-inner--text">Aviso de privacidad</span>
                                </a>
                            </div>
                            <div class="col-lg-12" style="margin-top: 20px">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF">
                                            <span class="btn-inner--icon">
                                                <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                            </span>
                                    <span class="nav-link-inner--text">Términos y condiciones</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
      <div class="d-none d-md-block d-lg-none">
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section" style="background-image: url('img/dbm/legal.jpg'); margin-left:-250px; height: 100vh; background-size: cover; margin-top:-100px; background-repeat: no-repeat; opacity: 0.90; !important;">

                <div class="text-center" style="position: absolute; color: #808080; width: 35%; top: 50%; left:45%;">
                    <div class="col">
                        <div class="row">
                            <div class="col-lg-12">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF; width:90%">
                                              <span class="btn-inner--icon">
                                                  <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                              </span>
                                    <span class="nav-link-inner--text">Aviso de privacidad</span>
                                </a>
                            </div>
                            <div class="col-lg-12" style="margin-top: 20px">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF; width:90%">
                                            <span class="btn-inner--icon">
                                                <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                            </span>
                                    <span class="nav-link-inner--text">Términos y condiciones</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
      <div class="d-none d-lg-block d-xl-none">
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section" style="background-image: url('img/dbm/legal.jpg'); margin-left:-110px; height: 110vh; background-size: cover; margin-top:-100px; background-repeat: no-repeat; opacity: 0.90; !important;">

                <div class="text-center" style="position: absolute; color: #808080; width: 35%; top: 32%; left:15%;">
                    <div class="col">
                        <div class="row">
                            <div class="col-lg-12">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF; width:80%">
                                              <span class="btn-inner--icon">
                                                  <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                              </span>
                                    <span class="nav-link-inner--text">Aviso de privacidad</span>
                                </a>
                            </div>
                            <div class="col-lg-12" style="margin-top: 20px">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF; width:80%">
                                            <span class="btn-inner--icon">
                                                <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                            </span>
                                    <span class="nav-link-inner--text">Términos y condiciones</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
      <div class="d-none d-xl-block">
            <div class="position-relative">
            <!-- shape Hero -->
            <section class="section" style="background-image: url('img/dbm/legal.jpg'); height: 110vh; background-size: cover; margin-top:-100px; background-repeat: no-repeat; opacity: 0.90; !important;">

                <div class="text-center" style="position: absolute; color: #808080; width: 35%; top: 32%; left:10%;">
                    <div class="col">
                        <div class="row">
                            <div class="col-lg-12">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF; width:60%">
                                              <span class="btn-inner--icon">
                                                  <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                              </span>
                                    <span class="nav-link-inner--text">Aviso de privacidad</span>
                                </a>
                            </div>
                            <div class="col-lg-12" style="margin-top: 20px">
                                <a href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf" target="_blank" class="btn btn-round btn-lg" style="background-color:#2a3d9a; color:#FFFF; width:60%">
                                            <span class="btn-inner--icon">
                                                <i class="fa fa-cloud-download fa-lg mr-2"></i>
                                            </span>
                                    <span class="nav-link-inner--text">Términos y condiciones</span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Modal from "@/components/Modal.vue";
export default {
  components: {
    Modal
  },
  data() {
    return {
      modals: {
        modal1: false,
        modal2: false,
        modal3: false
      }
    };
  }
};
</script>
<style>
</style>
