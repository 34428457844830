<template>
  <div>
    <section class="section-shaped my-0" style="background-color: #3953d3">
      <div class="shape shape-style-1 shape-default shape-skew">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
      <div class="row justify-content-center">
        <div class="container shape-container justify-content-center">
          <card
            v-if="token"
            class="col-lg-12"
            style="margin-top: 2rem; border-radius: 1rem; height: 95%"
          >
            <div class="text-center" style="margin-bottom: 2rem">
              <img
                src="https://dbmenos.com/img/brand/DB Menos logo 2020_membrete.png"
              />
            </div>
            <div class="row justify-content-center mb-lg">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col">
                    <base-input
                      alternative
                      style="font-size: 20px"
                      label="Nacionalidad:"
                      input-classes="form-control-alternative"
                      v-model="questions.nacionality"
                    />
                  </div>
                  <div class="col">
                    <base-input
                      alternative
                      style="font-size: 20px"
                      label="País de Nacimiento:"
                      input-classes="form-control-alternative"
                      v-model="questions.country"
                    />
                  </div>
                </div>
                <!--
                <label>¿ESTÁS CASADO?</label>
                <b-form-checkbox
                  v-model="questions.relationship_people"
                  value="1"
                  >SI</b-form-checkbox
                >
                <b-form-checkbox
                  v-model="questions.relationship_people"
                  value="0"
                  >NO</b-form-checkbox
                >
                !-->
                <div v-if="questions.relationship_people == 'M'">
                  <br />
                  <label>Tipo de régimen conyugal:</label>
                  <div class="row" style="margin-top: 1rem">
                    <div class="col">
                      <b-form-checkbox
                        v-model="questions.matrimonial_regime"
                        value="Separados"
                        >Separados</b-form-checkbox
                      >
                    </div>
                    <div class="col">
                      <b-form-checkbox
                        v-model="questions.matrimonial_regime"
                        value="Mancomunados"
                        >Mancomunados</b-form-checkbox
                      >
                    </div>
                    <!--
                    <div class="col">
                      <b-form-checkbox
                        v-model="questions.matrimonial_regime"
                        value="Mixtos"
                        >Mixtos</b-form-checkbox
                      >
                    </div>
                    !-->
                  </div>
                </div>
                <br />
                <label>ACTIVIDAD ECONÓMICA</label>
                <b-form-checkbox v-model="questions.type_work" value="i"
                  >INDEPENDIENTE</b-form-checkbox
                >
                <b-form-checkbox v-model="questions.type_work" value="e"
                  >EMPLEADO</b-form-checkbox
                >
                <br />
                <div
                  v-if="
                    questions.type_work == 'i' && questions.type_work != null
                  "
                >
                  <base-input
                    alternative
                    style="font-size: 20px"
                    label="Giro de Negocio:"
                    input-classes="form-control-alternative"
                    v-model="questions.line_business"
                  />
                </div>
                <div
                  v-if="
                    questions.type_work == 'e' && questions.type_work != null
                  "
                >
                  <base-input
                    alternative
                    style="font-size: 20px"
                    label="Nombre de empresa:"
                    input-classes="form-control-alternative"
                    v-model="questions.company_name"
                  />
                </div>
                <div v-if="beneficiary.relationship == null">
                <label v-if="!beneficiary.name"
                  >¿Qué parentesco tiene con su Beneficiario(a)?</label
                >
                <label v-if="beneficiary.name"
                  >¿Qué parentesco tiene con el Beneficiario:
                  {{ beneficiary.name }} {{ beneficiary.lastname_1 }}?</label
                >
                <base-dropdown>
                  <base-button
                    slot="title"
                    type="none"
                    size="lg"
                    class="dropdown-toggle"
                    :class="questions.relationship ? '' : 'text-danger'"
                    >{{
                      questions.relationship?
                      questions.relationship
                        : "Selecciona parentesco"
                    }}</base-button
                  >
                  <a
                    class="dropdown-item"
                    v-on:click="setRelationShip('Cónyuge')"
                    >Cónyuge</a
                  >
                  <a
                    class="dropdown-item"
                    v-on:click="setRelationShip('Descendiente')"
                    >Descendiente</a
                  >
                   <a
                    class="dropdown-item"
                    v-on:click="setRelationShip('Hermano(a)')"
                    >Hermano(a)</a
                  >
                   <a
                    class="dropdown-item"
                    v-on:click="setRelationShip('Ascendiente')"
                    >Ascendiente</a
                  >
                   <a
                    class="dropdown-item"
                    v-on:click="setRelationShip('Conocido')"
                    >Conocido</a
                  >
                </base-dropdown>

                  <base-input
                  v-if="otherRelaship"
                  alternative
                  style="font-size: 20px"
                  input-classes="form-control-alternative"
                  v-model="otherRelashipPeople"
                />
                </div>
                         <!--<label>*Persona Políticamente Expuesta: Personas que desempeñan o han desempeñado funciones públicas destacadas (ejemplo: jefes de estado, políticos de alta jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales o funcionarios importantes de partidos políticos).</label>!-->
                        <label>¿Desempeña o ha desempeñado algún cargo público Nacional o Extranjero? (Ejemplo: jefes de estado, políticos de alta jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales o funcionarios importantes de partidos políticos). </label>
                        <b-form-checkbox v-model="questions.public_office" value="1">SI</b-form-checkbox>
                        <b-form-checkbox v-model="questions.public_office" value="0">NO</b-form-checkbox>
                        <div v-if="questions.public_office  == 1">
                        <label>Si su respuesta es “SI” especifique:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Dependencia "
                        input-classes="form-control-alternative"
                        v-model="questions.public_office_dependencies"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Cargo "
                        input-classes="form-control-alternative"
                        v-model="questions.public_office_position"/>
                        </div>
                        <!--
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Período "
                        input-classes="form-control-alternative"
                        v-model="questions.public_office_period"/>
                        <label>¿Está o estuvo en algún proceso de elección para ocupar el cargo público?</label>
                        <b-form-checkbox v-model="questions.pub_office_choice" value="1">SI</b-form-checkbox>
                        <b-form-checkbox v-model="questions.pub_office_choice" value="0">NO</b-form-checkbox>
                        <div v-if="questions.pub_office_choice  == 1 && questions.public_office  == 1">
                        <label>Si su respuesta es “SI” especifique el Cago y el Periodo:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Domicilio Principal: Especifique las Características Principales del domicilio principal (ej. color de fachada, entre calles, es una privada) "
                        input-classes="form-control-alternative"
                        v-model="questions.pub_office_c_addprincipal"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Domicilio de su trabajo "
                        input-classes="form-control-alternative"
                        v-model="questions.pub_office_c_addrwork"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Antigüedad "
                        input-classes="form-control-alternative"
                        v-model="questions.pub_office_c_antiquity"/>
                         <base-input
                        alternative
                        style="font-size:20px;"
                        label="Nombre de su cónyuge y/o dependientes económicos, con fechas de nacimiento y parentesco. "
                        input-classes="form-control-alternative"
                        v-model="questions.pubOff_data_matrimonial"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Ingreso Mensual "
                        input-classes="form-control-alternative"
                        v-model="questions.office_ingress_mensual"/>
                        </div>
                        !-->
                        <label>Tiene algún tipo de parentesco con alguna persona que desempeñe o ha desempeñado algún tipo de Cargo público nacional o extranjero?</label>
                        <b-form-checkbox v-model="questions.relationship_public" value="1">SI</b-form-checkbox>
                        <b-form-checkbox v-model="questions.relationship_public" value="0">NO</b-form-checkbox>
                        <div v-if="questions.relationship_public == 1">
                        <label>Si su respuesta es “SI” especifique:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Nombre"
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_name"/>
                         <base-input
                        alternative
                        style="font-size:20px;"
                        label="Primer Apellido"
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_lastname"/>
                         <base-input
                        alternative
                        style="font-size:20px;"
                        label="Segundo Apellido"
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_lastname2"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Cargo"
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_position"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Parentesco "
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_type"/>
                         <base-input
                        alternative
                        style="font-size:20px;"
                        label="Dependencia "
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_dependece"/>
                        </div>
                <div class="text-center">
                  <base-button
                    slot="title"
                    type="success"
                    class="btn text-center"
                    style="margin-top: 1rem"
                    v-on:click="sendQuestions"
                    size="lg"
                    >Enviar Cuestionario</base-button
                  >
                </div>
              </div>
            </div>
          </card>
          <card
            class="col-lg-12"
            style="margin-top: 2rem; border-radius: 1rem; height: 80%"
            v-if="!token && !existQuestion"
          >
            <div
              class="text-center"
              style="margin-top: 10rem; margin-bottom: 10rem"
            >
              <h5 style="font-size: 25px">
                No logramos validar tu enlace, comúnicate con tu asesor
              </h5>
              <img src="https://dbmenos.com/img/theme/close-circle.png" />
            </div>
          </card>
          <card
            class="col-lg-12"
            style="margin-top: 2rem; border-radius: 1rem; height: 80%"
            v-if="!token && existQuestion"
          >
            <div
              class="text-center"
              style="margin-top: 10rem; margin-bottom: 10rem"
            >
              <h5 style="font-size: 25px">
                Ya recibimos tus respuestas al cuestionario, muy pronto un
                asesor se comunicará contigo para continuar con el proceso
              </h5>
              <img
                src="https://dbmenos.com/img/theme/checkbox-marked-circle.png"
              />
            </div>
          </card>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import modal from "@/components/Modal";
import { V } from "@/V";
import BaseInput from "../components/BaseInput.vue";
import Card from "../components/Card.vue";
import BaseDropdown from "../components/BaseDropdown";
export default {
  name: "home",
  components: {
    modal,
    BaseInput,
    Card,
    BaseDropdown,
  },
  created() {
    this.getDataUser();
  },
  data() {
    return {
      V: V,
      token: false,
      existQuestion: false,
      simpleCalc: {
        amount: 10,
        banks: [],
      },
      questions: {
        token: "",
        phone: "",
        country: "",
        nacionality: "",
        relationship_people: null,
        matrimonial_regime: "",
        line_business: "",
        type_work: null,
        company_name: "",
        company_position: "",
        relationship: "",
        public_office: "",
        public_office_dependencies: "",
        public_office_position: "",
        public_office_period: "",
        pub_office_choice: "",
        pub_office_c_addprincipal: "",
        pub_office_c_addrwork: "",
        pub_office_c_antiquity: "",
        pubOff_data_matrimonial: "",
        office_ingress_mensual: "",
        relationship_public: "",
        relationship_public_name: "",
        relationship_public_lastname:"",
        relationship_public_lastname2:"",
        relationship_position: "",
        relationship_public_type: "",
        relationship_public_dependece: "",
      },
      banks: [],
      SERVER: "https://s3.amazonaws.com/dbmenos.frontend/",
      leadModal: false,
      estimatedDiscountModal: false,
      averageDiscount: 0,
      user: {},
      beneficiary: {},
      otherRelaship:false,
      RelashipPeople:null,
    };
  },
  methods: {
    setRelationShip(value) {
     console.log(value)
     if(value !='Otro'){
    this.questions.relationship = value;
    this.otherRelaship=false;
     }else{
         this.questions.relationship = value;
    this.otherRelaship=true;
     }
     console.log(this.otherRelaship)
    this.$forceUpdate();
    },
    sendQuestions() {
      if(this.beneficiary.relationship>0){
         if(this.beneficiary.relationship == 1){
           this.RelashipPeople = 'Cónyuge'
         }else if(this.beneficiary.relationship == 2){
           this.RelashipPeople = 'Descendiente'
         }else if(this.beneficiary.relationship == 3){
           this.RelashipPeople = 'Hermano(a)'
         }else if(this.beneficiary.relationship == 4){
           this.RelashipPeople = 'Ascendiente '
         }else if(this.beneficiary.relationship == 5){
           this.RelashipPeople = 'Conocido '
         }
         this.questions.relationship = this.RelashipPeople;
      }
      console.log(this.questions.relationship)
      if (!this.questions.phone) {
        this.$notify({
          text: "Falta Ingresar Número de Telefono",
          type: "error",
        });
      } else if (
        (this.questions.country == "" || this.questions.nacionality == "",
        this.questions.relationship_people == null ||
          this.questions.type_work == null ||
          this.questions.relationship == "")
      ) {
        this.$notify({
          text: "Falta Datos por Ingresas",
          type: "error",
        });
      } else if (
        this.questions.matrimonial_regime == "" &&
        this.questions.relationship_people == 'M'
      ) {
        this.$notify({
          text: "Falta seleccionar el tipo de regimen",
          type: "error",
        });
      } else if (
        this.questions.line_business == "" &&
        this.questions.type_work == "i"
      ) {
        this.$notify({
          text: "Falta ingresar el Giro de Negocio",
          type: "error",
        });
      } else if (
        this.questions.company_name == "" &&
        this.questions.type_work == "e"
      ) {
        this.$notify({
          text: "Falta ingresar el Nombre de Empresa",
          type: "error",
        });
      } else {
        this.$showLoader(true);
        if (this.questions.relationship_people == 'S') {
          this.questions.matrimonial_regime = " ";
        }
        this.questions.token = this.$route.params['pathMatch'];
        //this.questions.public_office = 0;
        //this.questions.relationship_public = 0;
        let url = V.SERVER + V.OPS.SET_QUESTIONS_CONTRACT;
        this.$axios.post(url, this.questions).then(async (res) => {
          this.$showLoader(false);
          if (res.data.success) {
            this.$showLoader(false);
            this.$notify({
              text: "Cuestionario Guardado Correctamente",
              type: "success",
            });
            window.location.reload();
          }
        });
      }
    },
    getDataUser() {
      this.$showLoader(true);
      let url =
        V.SERVER +
        V.OPS.GET_USER_QUESTION_RISK +
        "?token=" +
        this.$route.params['pathMatch'];
      this.$axios.get(url).then(async (res) => {
        this.$showLoader(false);
        let data = res.data;
        if (res.data.success) {
          this.token = true;
          this.questions.phone = data.row.phone;
          this.questions.relationship_people = data.row.civil_status;
          if (data.beneficiary) {
            this.beneficiary = data.beneficiary;
          }
          console.log(this.beneficiary);
          this.$showLoader(false);
        } else {
          switch (res.data.reason) {
            case 5:
              this.existQuestion = true;
              this.token = false;
              break;
            default:
              this.token = false;
              this.existQuestion = false;
              break;
          }
        }
      });
    },
  },
};
</script>
