<template>

  <div class="bg-principal py-sm-5">

    <div class="row pt-sm-5">

      <div class="col-12 col-md-8 col-lg-6 mx-auto pt-0 mt-0">

        <v-stepper v-model="step" elevation="0" style="border-radius: 1rem;">

          <v-stepper-header class="shadow-none px-5">

            <v-stepper-step
                :complete="this.validF1"
                :color="(this.auxColor && !this.validF1) ? '#2A3D99' : (this.validF1) ? '#8ac43f': '#ff0033'"
                step="1"

            />
            <!--@click="step = 1"-->
            <!--Agregar al componente v-stepper-step solo en local-->

            <v-divider></v-divider>

            <v-stepper-step
                :complete="this.validF2"
                :color="(this.auxColor && !this.validF2) ? '#2A3D99' : (this.validF2) ? '#8ac43f': '#ff0033'"
                step="2"

            />
            <!--@click="step = 2"-->
            <!--Agregar al componente v-stepper-step solo en local-->

            <v-divider></v-divider>

            <v-stepper-step
                :complete="this.validF3"
                :color="(this.auxColor && !this.validF3) ? '#2A3D99' : (this.validF3) ? '#8ac43f': '#ff0033'"
                step="3"

            />
            <!--@click="step = 3"-->
            <!--Agregar al componente v-stepper-step solo en local-->

          </v-stepper-header>

          <v-stepper-items>

            <div>

              <div v-if="step === 1">
                <p class="text-center my-3 secondary-text">
                  Sube tu INE para consultar tu <br> buró de crédito y calcular con <br> exactitud tu descuento.
                </p>

                <p class="text-center my-3 simple-text" style="color: #808080">
                  Revisamos tu buró para buscar las cuentas <br>
                  con las que podemos ayudarte a liquidar y <br>
                  con ello, ofrecerte un plan con descuento.
                </p>
              </div>

              <div v-if="step === 2">
                <p class="text-center my-3 secondary-text">
                  <img src="/img/registration_steps/team.png" alt="team"> <br>
                  Felicidades {{ client.name }} eres apto (a) para <br> obtener un descuento por tus deudas.
                </p>
              </div>

              <div v-if="step === 3">
                <p class="text-center my-3 secondary-text">
                  Estás muy cerca de poder conseguir tu <br>
                  libertad financiera. Comienza a disfrutar <br>
                  de una vida sin deudas.
                </p>
              </div>

            </div>

            <v-stepper-content step="1" class="p-0">

              <v-card>

                <v-card-text>

                  <v-card elevation="0">
                    <v-card-text class="m-0 p-0">
                      <div class="row mx-auto my-5">
                        <div class="col-12">
                          <p class="text-center"><b>SUBIR IDENTIFICACIÓN FRENTE</b></p>
                          <dropbox
                              text
                              :ocrFile="true"
                              type="ID"
                              :files="this.client.files.front"
                              v-on:uploadFile="uploadFile"
                              v-on:resentUploadFile="uploadFile"
                          ></dropbox>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>

                  <!-- SE AGREGO UNA CONDICION A side EN uploadFile -->
                  <v-card elevation="0" :disabled="side === true ? false : true">
                    <v-card-text class="m-0 p-0">
                      <div class="row mx-auto my-5">
                        <div class="col-12">
                          <p class="text-center"><b>SUBIR IDENTIFICACIÓN REVERSO</b></p>
                          <dropbox
                              text
                              :ocrFile="true"
                              type="ID_B"
                              :files="this.client.files.back"
                              v-on:uploadFile="uploadFile"
                              v-on:resentUploadFile="uploadFile"
                          ></dropbox>
                        </div>
                      </div>
                    </v-card-text>
                  </v-card>

                  <div class="row">
                    <div class="col-12 text-center">
                      <v-btn
                          dark
                          elevation="2"
                          color="#2a3d98"
                          v-on:click="getPin"
                          class="btn-action"
                          :disabled="ocr.step !== 2"
                      >Siguiente
                      </v-btn>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 text-center">
                      <v-btn
                          dark
                          color="error"
                          elevation="2"
                          class="btn-action"
                          v-on:click="getCredit"
                          :disabled="btnCredit"
                      >
                        <v-icon>mdi-cached</v-icon>
                        Volver a intentar
                      </v-btn>
                    </div>
                  </div>

                </v-card-text>

              </v-card>

            </v-stepper-content>

            <v-stepper-content step="2" class="p-0">

              <program-d-b-m :proposal="proposal" @startProgram="startProgram"/>

              <div class="col-12 col-sm-10 mx-auto mt-5">

                <v-slider
                    ticks
                    class="mt-3"
                    :min="slider.min"
                    :max="slider.max"
                    v-model="slider.val"
                    :thumb-color="slider.color"
                    :track-color="slider.color"
                />

                <div class="m-0 p-0 text-center">
                  <span class="text-center p-0 m-0">{{ proposal.period }} meses</span>
                </div>

                <div class="mt-3 text-center">
                  <v-btn
                      dark
                      elevation="2"
                      color="#8ac43f"
                      class="btn-action"
                      @click="startProgram"
                  >Lo quiero
                  </v-btn>
                </div>

              </div>

              <carousel-cards version="v2" :debts="debts" @calculator="calculator"/>

            </v-stepper-content>

            <v-stepper-content step="3" class="p-0">

              <v-card elevation="5" class="m-1 m-sm-5 my-2 shadow-lg">

                <v-card-text>

                  <span><b>Nombre:</b> {{ client.name }} {{ client.last_name_1 }} {{ client.last_name_2 }}</span> <br>
                  <span><b>Teléfono:</b> {{ client.phone }}</span> <br>
                  <span><b>Corre:</b> {{ client.email }}</span> <br>
                  <span><b>Estado:</b> {{ client.address.state }}</span> <br>
                  <span><b>Ciudad:</b> {{ client.address.city }}</span> <br>
                  <span><b>Código Postal:</b> {{ client.address.cp }}</span> <br>
                  <span><b>Colonia:</b> {{ client.address.suburb }}</span> <br>
                  <span><b>Calle:</b> {{ client.address.street }} {{ client.address.number }}</span>

                </v-card-text>

              </v-card>

              <v-card elevation="5" class="m-1 m-sm-5 my-2 shadow-lg">

                <v-card-text>

                  <p class="text-center simple-text-gray">
                    <b>Autoriza el uso de tu firma para <br>generar el contrato con DB Menos</b>
                  </p>

                  <a :href="this.client.files.contract" v-if="this.client.files.contract!==''" target="_blank"
                     class="text-center d-block"
                     style="color: #2a3d9a">
                    <img src="/img/new_process/icons/eye.svg" alt="eye" class="icon-eye mx-1">
                    <b>Ver contrato</b>
                  </a>

                  <a href="#" v-else target="_blank" class="text-center d-block" style="color: #2a3d9a">
                    <b>Tu contrato aún esta en proceso de creación</b>
                  </a>

                  <div style="background-color: #F8F8F8">
                    <VueSignaturePad width="500px" height="300px" :options="{ onBegin, onEnd }" ref="signaturePad"/>

                    <div class="signature mx-5 text-center noselect">Firma</div>
                  </div>
                </v-card-text>

              </v-card>

              <div class="row m-1 ">
                <div class="col-12 col-sm-10 mx-auto">
                  <v-btn
                      dark
                      block
                      elevation="2"
                      color="#2a3d9a"
                      class="my-2"
                      style="text-transform: none"
                      v-on:click="clearSignature"
                  >Borrar firma
                  </v-btn>
                  <v-btn
                      dark
                      block
                      elevation="2"
                      color="#8ac43f"
                      class="my-2"
                      style="text-transform: none"
                      v-on:click="sendSignature"
                      :disabled="btnSignature"
                  >Firmar
                  </v-btn>
                </div>
              </div>

            </v-stepper-content>

          </v-stepper-items>

        </v-stepper>

      </div>

    </div>

    <!-- Data -->
    <div class="text-center">
      <v-dialog
          persistent
          width="500px"
          v-model="dialogContact"
      >

        <v-card>
          <v-card-text>

            <div class="row">
              <div class="col-12 my-5 text-center">
                <p class="title-modal" style="color: #ff5252">Oh oh...</p>
                <p class="text-modal text-left">
                  Aún no tenemos toda tu información de contacto, favor de ingresarla.
                </p>
              </div>
            </div>

            <v-form v-model="validF4" ref="form4">
              <div class="row">
                <div class="col-12 col-sm-6 py-0">
                  <v-text-field
                      v-model="client.phone"
                      label="Teléfono"
                      type="number"
                      :rules="rules.phone"
                      pattern="\d*"
                      solo
                  ></v-text-field>
                </div>

                <div class="col-12 col-sm-6 py-0">
                  <v-text-field
                      v-model="client.email"
                      label="Correo electrónico"
                      :rules="rules.email"
                      solo
                  ></v-text-field>
                </div>
              </div>
            </v-form>

            <div class="row my-1">
              <div class="col-12">
                <v-btn
                    dark
                    block
                    elevation="2"
                    color="#2A3D99"
                    style="text-transform: none"
                    v-on:click="toComplete"
                >Completar
                </v-btn>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- Modal -->
    <div class="text-center">
      <v-dialog
          persistent
          width="500px"
          v-model="dialog"
      >

        <v-card>
          <v-card-text>

            <div class="text-end">
              <v-icon
                  class="mt-3"
                  color="#2A3D99"
                  @click="dialog=false"
              >
                mdi-close
              </v-icon>
            </div>

            <div class="row">
              <div class="col-12 my-5 text-center">
                <p class="title-modal">Autorización de buró de crédito</p>
                <p class="text-modal text-left">
                  Para comprobar que no eres un robot, te enviaremos un código a tu teléfono.
                </p>

                <a href="/files/aviso_de_privacidad_grupo_dbmenos_2018.pdf" target="_blank">
                  <img src="/img/new_process/icons/eye.svg" alt="eye" class="d-inline icon-eye">
                  <p class="d-inline link" style="color: #2A3D99">
                    <ins>Aviso de privacidad</ins>
                  </p>
                </a>
              </div>
            </div>

            <v-form v-model="validF3" ref="form3">
              <div class="row">
                <div class="col-12">
                  <v-text-field
                      v-model="client.code"
                      label="Código"
                      type="number"
                      :rules="rules.code"
                      pattern="\d*"
                      solo
                  ></v-text-field>
                </div>
              </div>
            </v-form>

            <div class="row my-1">
              <div class="col-12">
                <v-btn
                    dark
                    block
                    elevation="2"
                    color="#8ac43f"
                    style="text-transform: none"
                    v-on:click="validatePin"
                >Enviar
                </v-btn>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- Alert -->
    <div class="text-center">
      <v-dialog
          v-model="alert"
          width="500"
          persistent
      >
        <v-card>
          <v-card-text>
            <div class="pt-5">
              <p class="font-weight-bold text-alert"
                 style="color: #ff5252">
                Oh Oh...
              </p>
              <p class="font-weight-bold text-alert">
                {{ message }}
              </p>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  text
                  v-on:click="redirectToSession"
              >
                Inicia sesión
              </v-btn>
            </v-card-actions>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!--Snak bar-->
    <div class="text-center ">
      <v-snackbar
          v-model="snackbar"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="#ff0033"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

    <!-- Finish -->
    <template>
      <div class="text-center">
        <v-dialog
            v-model="congrats"
            width="500"
            persistent
        >
          <!--
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="red lighten-2"
                dark
                v-bind="attrs"
                v-on="on"
            >
              Click Me
            </v-btn>
          </template>
          -->

          <v-card>

            <v-card-text>
              <p class="pt-5 text-center">
                <span style="color: #2a3d9a"><b>¡Felicidades!</b></span> ya hemos recibido tu <span
                  class="text-dark"><b>firma</b></span>.
                El siguiente paso es crear tu cuenta en donde registrarás tus ahorros mensuales.
              </p>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  text
                  @click="congrats = false"
              >
                <a href="https://cuenta.finamigodigital.com.mx/" style="color: #2a3d9a">Aceptar</a>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </template>

    <!-- Instructions -->
    <template>
      <div
        class="popsteps-container"
      >
        <v-dialog
          persistent
          v-model="instructions"                
        >
          <carousel-instructions @closeDialog="closeDialog"/>
        </v-dialog>
      </div>
    </template>

    <!-- CARROUSEL INSTRUCTIONS OLD -->
    <!-- <template>
      <div class="text-center">
        <v-dialog
          persistent
          width="500"
          v-model="instructions"
        >
          <v-card>
            <carousel-instructions @closeDialog="closeDialog"/>
          </v-card>
        </v-dialog>
      </div>
    </template> -->

  </div>

</template>

<script>

import VideoModal from "@/views/components/simulator/VideoModal";
import Carousel from "@/views/components/simulator/Carousel";
import {V} from "@/V";
import Dropbox from "@/components/Dropbox";
import IneImage from "@/views/components/simulator/IneImage";
import {mapActions, mapState} from 'vuex'
import Alert from "@/views/components/simulator/Alert";
import CarouselCards from "@/views/components/registration_steps/CarouselCards";
import ProgramDBM from "@/views/components/registration_steps/ProgramDBM";
import VueSignaturePad from 'vue-signature-pad';
import SignaturePad from "@/views/SignaturePad";
import firebase from "firebase";
import CarouselInstructions from "@/views/components/registration_steps/CarouselInstructions";

export default {
  name: "SignatureStep",
  computed:
      {
        ...mapState(['program_debts'])
      },
  components: {
    CarouselInstructions,
    SignaturePad,
    ProgramDBM,
    CarouselCards,
    Alert,
    IneImage,
    Dropbox,
    Carousel,
    VideoModal,
    VueSignaturePad
  },
  created() {

    this.client.phone = JSON.parse(localStorage.getItem("ph"));
    this.client.email = JSON.parse(localStorage.getItem("email"));

    if (this.client.phone === null || this.client.email === null) {
      this.dialogContact = true
    }

    this.instructions = true;

  },
  data: () => ({

    //Validation Steps
    step: 1,

    validF1: false,
    validF2: false,
    validF3: false,
    validF4: false,

    //Snack Bar
    alert: false,
    message: '',
    snackbar: false,

    //Modals
    modal: false,
    dialog: false,
    dialogContact: false,
    test: true,
    congrats: false,
    instructions: false,

    //Color
    auxColor: true,

    //OCR
    // side: true,
    side: false,
    ocr:
        {
          moment: null,
          auxMoment: false,
          auxCont: 1,
          step: 1
        },

    //Props
    clientAux: {},
    client:
        {
          id: '',
          name: '',
          last_name_1: '',
          last_name_2: '',
          curp: '',
          rfc: '',
          date_of_birth: '',
          gender: '',
          phone: '',
          email: '',
          id_ine: '',
          code: '',
          address: {
            state: '',
            city: '',
            suburb: '',
            cp: '',
            street: '',
            number: '',
          },
          files: {
            front: [],
            back: [],
            contract: ''
          },
          device: ''
        },

    proposal:
        {
          totalDebt: 0,
          willPay: 0,
          monthlyFee: 0,
          period: '',
          percent: 0,
        },

    debts: [],
    rules:
        {
          name: [v => !!v || 'El nombre es requerido'],
          last_name: [v => !!v || 'El apellido es requerido'],
          date_of_birth:
              [
                v => !!v || 'La fecha es requerida',
                v => /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) || 'Formato invalido. Ej: (01/01/2000)',
              ],
          gender: [v => !!v || 'El género es requerido'],
          email:
              [
                v => !!v || 'El correo es requerido',
                v => /.+@.+/.test(v) || 'Formato invalido.'
              ],
          phone:
              [
                v => !!v || 'El teléfono es requerido',
                v => /^([0-9]{10,13})$/.test(v) || 'Min: 10 - Max 13, dígitos'
              ],
          code: [v => !!v || 'El código es requerido'],
          street: [v => !!v || 'La calle es requerida'],
          number:
              [
                v => !!v || 'El número es requerido',
                v => (/^([0-9]{1,})$/.test(v) || /^(S\/N)$/i.test(v)) || 'Formato invalido. Ej: (6 o s/n)',
              ],
          cp: [v => !!v || 'El código postal es requerido'],
          city: [v => !!v || 'El municipio es requerido'],
          suburb: [v => !!v || 'La colonia es requerida'],
        },

    //Auxiliares de peticiones
    auxBC: 0,
    auxProgram: 0,
    auxContract: 0,

    //Auxiliares de botones
    btnCredit: true,
    btnSignature: true,

    //Barra de programa
    slider: {label: 'Meses', min: 1, max: 12, val: 0, color: '#f2d238'},


    urlContract: '',

    today: "",
    payday: 15,
    beneficiary: {},

    selected_program: {},

    //Ya no sirve (proceso optimizado)
    amountTotal: 0,
    period: 12,
    amountClientMDI: false,
    periodOptimal: '',
    reportListo: false,
    sliderRange: [],

    accounts: [
      {
        account: "",
        addProgram: false,
        amount: "",
        bank: "",
        bankid: "",
        discount: "",
        due_months: "",
        last_bank_payment_date: "",
        m: "",
        willPayOnMonth: "",
      },
    ],

    discount: {
      debts: [
        {
          addProgram: false,
        },
      ],
      addProgram: false,
      totalDebt: 0,
      savings: 0,
      monthlyFee: 0,
      discount: 0,
      o_progress: 0,
    },

    calculatorDebts: [
      {
        debts: [
          {
            account: "",
            addProgram: false,
            amount: "",
            bank: "",
            bankid: "",
            discount: "",
            due_months: "",
            last_bank_payment_date: "",
            m: "",
            willPayOnMonth: "",
          },
        ],
        monthlyFee: "",
        period: "",
        savings: "",
        totalDebt: "",
        willPay: "",
      },
    ],

    calculatorDebtsAfter: [
      {
        debts: [
          {
            account: "",
            addProgram: false,
            amount: "",
            bank: "",
            bankid: "",
            discount: "",
            due_months: "",
            last_bank_payment_date: "",
            m: "",
            willPayOnMonth: "",
          },
        ],
        monthlyFee: "",
        period: "",
        savings: "",
        totalDebt: "",
        willPay: "",
      },
    ],
    calculatorDebtsBefore: [
      {
        debts: [
          {
            account: "",
            addProgram: false,
            amount: "",
            bank: "",
            bankid: "",
            discount: "",
            due_months: "",
            last_bank_payment_date: "",
            m: "",
            willPayOnMonth: "",
          },
        ],
        monthlyFee: "",
        period: "",
        savings: "",
        totalDebt: "",
        willPay: "",
      },
    ],


  }),
  methods:
      {
        ...mapActions(['addDebts']),

        //Validar información de contacto
        toComplete() {
          if (this.$refs.form4.validate()) {

            localStorage.setItem("ph", JSON.stringify(this.client.phone));
            localStorage.setItem("email", JSON.stringify(this.client.email));

            this.dialogContact = false
          }
        },
        //Mensajes de peticiones
        showMessage(message, status = true) {
          this.snackbar = status
          this.message = message
        },
        //Formato de número
        numberFormat(number) {

          let cant = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            roundingIncrement: 5,
            minimumFractionDigits: 2
          }).format(number);

          return cant;
        },
        //Redirigir
        redirectToSession() {
          this.$router.push({path: "/register"})
        },
        //Cerrar el dialogo de instrucciones
        closeDialog() {
          this.instructions = false
        },
        //Obtener los datos de la INE
        async uploadFile(obj) {

          this.side = true;
          this.$showLoader(true);

          let file = obj.file;

          if (!this.ocr.auxMoment) {
            this.ocr.moment = this.$moment().format('DDMMHHmmss')
            this.ocr.auxMoment = true;
          }

          let body =
              {
                phone: this.client.phone,
                filename: obj.file.name,
              };

          //Ubicación del archivo
          await this.$axios.post(V.SERVER + V.PREREGISTER.UPLOAD_FILE, body).then((response) => {
            console.log("LOCATION FILE:", response)
            let data = response.data;

            if (data.success) {

              this.showMessage('Ruta obtenida correctamente')

              let url = data[V.API.ROW].url;

              //Subir el archivo
              this.$axios.put(url, file).then((response) => {

                console.log("UPLOAD FILE:", response)

                if (response.status === 200) {

                  this.showMessage('INE Cargada Correctamente')

                  let url = (this.side)
                      ? V.SERVER + V.PREREGISTER.PREUSER_OCR
                      : V.SERVER + V.PREREGISTER.PREUSER_OCR_IDB

                  //Análisis ORC
                  this.$axios.post(url, body).then((response) => {

                    console.log("ANALYSIS OCR:", response)

                    if (response.status === 200) {

                      this.showMessage('Información obtenida correctamente')

                      this.ocr.auxCont += 1;

                      if (this.ocr.auxCont === 2) {
                        this.ocr.auxMoment = false;
                      }

                      let data = response.data.row;

                      if (obj.type === "ID") {

                        let user = response.data.row;

                        this.client.id = user.session.user

                        //sessión del usuario
                        this.$store.commit("setUser", user);
                        localStorage.setItem(this.$v.STORE_KEYS.SESSION, user.session.session);
                        localStorage.setItem(this.$v.STORE_KEYS.USER, JSON.stringify(user));

                        this.side = false
                        this.ocr.step = 1

                        this.client.name = data.name
                        this.client.last_name_1 = data.lastname_1
                        this.client.last_name_2 = data.lastname_2
                        this.client.date_of_birth = data.birthday.split("-").reverse().join("/")
                        this.client.gender = (data.gender === 'M') ? 'H' : 'M'
                        this.client.curp = data.curp
                        this.client.rfc = data.rfc

                        this.client.address.street = data.addr_street
                        this.client.address.number = data.addr_extnum
                        this.client.address.cp = data.addr_postal_code
                        this.client.address.suburb = data.addr_sublocality
                        this.client.address.state = data.state_of_birth

                        this.client.device = data.device

                      } else {
                        this.ocr.step = 2

                        this.client.id_ine = data.id_number
                      }

                    }

                  }).catch((e) => {
                    this.$showLoader(false);
                    this.showMessage('Ocurrio un error al obtener la información')
                  });

                }

              }).catch((e) => {
                this.$showLoader(false);
                this.showMessage('Ocurrio un error cargar la INE')
              });

            }

          }).catch((e) => {
            this.$showLoader(false);
            this.showMessage('Ocurrio un error al obtener la ruta')
          });

          this.$showLoader(false);

        },
        //Enviar el pin para consultar el buró de crédito
        async getPin() {

          this.$showLoader(true);

          let url = V.SERVER + V.PREREGISTER.REQUEST_CREDIT_REPORT_PIN
          let data = {user: this.client.id}

          await this.$axios.post(url, data).then((response) => {

            console.log("GET PIN", response)

            if (response.data.success) {
              this.dialog = true;
            } else {
              this.showMessage('Error al enviar el PIN')
            }

          });

          this.$showLoader(false);
        },
        //Validar el pin
        async validatePin() {

          this.$showLoader(true);

          let url = V.SERVER + V.PREREGISTER.VALIDATE_CREDIT_REPORT_PIN
          let body =
              {
                user: this.client.id,
                pin: parseInt(this.client.code)
              }

          await this.$axios.post(url, body).then((response) => {

            console.log("VALIDATE PIN", response.data)

            if (response.data[this.$v.API.SUCCESS]) {
              this.dialog = false;
              this.getCredit()
            } else {
              this.showMessage('Código incorrecto')
            }
          })

          this.$showLoader(false);
        },
        //Obtener las cuentas de buró
        async getCredit() {

          this.$showLoader(true);

          let url = V.SERVER + V.PREREGISTER.REQUEST_CREDIT_REPORT
          let data = {user: this.client.id}

          await this.$axios.post(url, data).then((response) => {

            console.log("GET CREDIT", response.data)

            if (response.data[this.$v.API.SUCCESS]) {

              let accounts = response.data[V.API.ROW].row.accounts.dbmAccounts

              let debts = []

              for (const item of accounts) {
                if (item.amount !== 0) {
                  debts.push(item)
                }
              }

              if (debts.length === 0) {
                this.$router.push({path: "/error"});
              }

              this.addDebts(debts)
              this.calculator(this.program_debts)

              this.step = 2;

            } else {

              this.showMessage('Intentalo nuevamente')

              this.btnCredit = false;
            }

          });

          this.$showLoader(false);
        },
        //Calcular el programa
        async calculator(debts) {

          this.$showLoader(true);

          let url = V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR

          await this.$axios.post(url, debts).then((response) => {

            console.log("CALCULATOR:", response.data)

            if (response.data[this.$v.API.SUCCESS]) {

              let data = response.data.row;
              let opc = this.slider.val - 1;
              this.debts = []

              //Program
              this.selected_program = data.programs[opc];

              //Slider
              this.slider.max = data.programs.length;
              this.slider.label = (data.programs[opc].period);

              //Info para el programa
              this.proposal.totalDebt = this.numberFormat(data.programs[opc].totalDebt)
              this.proposal.willPay = this.numberFormat(data.programs[opc].willPay)
              this.proposal.monthlyFee = this.numberFormat(data.programs[opc].monthlyFee)
              this.proposal.period = data.programs[opc].period
              let percent = this.numberFormat(data.programs[opc].discount)
              this.proposal.percent = percent.substring(1, percent.lenght);

              //Info para el carrusel
              for (const item of data.programs[opc].debts) {

                let newDebt =
                    {
                      id: item.index,
                      debt: this.numberFormat(item.amount),
                      pay: this.numberFormat(item.willPay),
                      percent: 0,
                      bank: item.bankid,
                      account: item.account
                    }

                let percent = (item.discount * 100) / item.amount
                newDebt.percent = percent

                this.debts.push(newDebt)

              }

              //Obtener el perfil del usuario
              this.getClientProfile()

            }

            this.$showLoader(false);

          }).catch((e) => {
            this.$showLoader(false);
            this.showMessage('Ocurrio un error al obtener la calculadora')
          });

        },
        //Guardar programa
        async startProgram() {

          this.$showLoader(true)

          let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATOR_CR;

          await this.$axios.post(url, this.selected_program).then((response) => {

            console.log("GUARDAR PROGRAMA", response)

            let data = response.data;

            if (data[this.$v.API.SUCCESS]) {

              if (this.selected_program.totalDebt < 10000) {
                this.$router.push({path: "/error"});
              }

              //Obtener el perfil del usuario
              this.getClientProfile()

              //Actualizar el perfil del cliente
              this.updateClientProfile()

            } else {

              if (this.auxProgram === 0) {
                this.auxProgram = 1
                this.startProgram()
              }

            }

            this.$showLoader(false)

          }).catch((e) => {
            this.$showLoader(false);
            this.showMessage('Ocurrio un error al guardar el programa')
          });

        },
        //Obtener info del asesor
        async getAsesor() {

          let url = this.$v.SERVER + this.$v.OPS.GET_CONSULTANT_INFO;

          await this.$axios.get(url).then((response) => {

            console.log("GET ASESOR", response)

            let data = response.data;

            if (data[this.$v.API.SUCCESS]) {
              this.phone = data.row.phone;
              this.urlAsesor = "https://wa.me/52" + this.phone;
              //window.open(this.urlAsesor);
            }

          }).catch((e) => {
            this.$showLoader(false);
            this.showMessage('Ocurrio un error al obtener el asesor')
          });

        },
        //Beneficiario
        getRelationBeneficiary() {
          for (let i = 0; i < this.relationBeneficiary.length; i++)
            if (this.relationBeneficiary[i].id === this.beneficiary.relationship)
              return this.relationBeneficiary[i].value;
          return null;
        },
        //Obtener el perfil del cliente
        async getClientProfile() {

          await this.getAsesor();

          let url = this.$v.SERVER + this.$v.OPS.GET_PROFILE + "?id=" + this.client.id;

          await this.$axios.get(url).then(response => {

            console.log("GET PROFILE", response)

            let data = response.data;

            if (data[this.$v.API.SUCCESS]) {
              this.clientAux = response.data[this.$v.API.ROW];
              if (data.row.payday) this.payday = data.row.payday;
              let url2 = this.$v.SERVER + this.$v.OPS.GET_BENEFICIARY + "?id=" + this.client.id;
              this.$axios.get(url2).then(response2 => {
                let data2 = response2.data;
                if (data2[this.$v.API.SUCCESS]) {
                  this.beneficiary = data2[this.$v.API.ROW];
                  if (data.row.beneficiary.name)
                    this.beneficiary.name = data.row.beneficiary.name;
                  if (data.row.beneficiary.lastname_1)
                    this.beneficiary.lastname_1 =
                        data.row.beneficiary.lastname_1;
                  if (data.row.beneficiary.lastname_2)
                    this.beneficiary.lastname_2 =
                        data.row.beneficiary.lastname_2;
                  if (data.row.beneficiary.birthday)
                    this.beneficiary.birthday =
                        data.row.beneficiary.birthday;
                  if (data.row.beneficiary.gender)
                    this.beneficiary.gender = data.row.beneficiary.gender;
                  if (data.row.beneficiary.civil_status)
                    this.beneficiary.civil_status =
                        data.row.beneficiary.civil_status;
                  if (data.rows.beneficiary.relationship) {
                    this.beneficiary.relationship = data.row.beneficiary.relationship
                  }
                  this.getRelationBeneficiary()
                  console.log(this.relationBeneficiary.relationship)
                }
              });
            }
          });
        },
        //Actualizar e
        async updateClientProfile() {

          this.clientAux.payday = this.$moment().format("DD")
          this.clientAux.first_payment = this.$moment().format("YYYY-MM-DD")

          let url = this.$v.SERVER + this.$v.OPS.SET_CONTRACT_AND_ACCOUNTS;

          await this.$axios.post(url, this.clientAux).then(response => {

            console.log("CONTRACT AND ACCOUNTS", response)

            let data = response.data;

            if (data[this.$v.API.SUCCESS]) {
              this.getClientProfile()
              this.step = 3
            }

          }).catch((e) => {
            this.$showLoader(false);
            this.showMessage('Ocurrio un error al generar las cuentas del contrato')
          });

          url = this.$v.SERVER + this.$v.OPS.UPDATE + this.$v.TABLES.USERS;

          this.$axios.post(url, this.clientAux).then(response => {

            console.log("USER TABLE", response)

            let data = response.data;
            if (data[this.$v.API.SUCCESS]) {

            }

          }).catch((e) => {
            this.$showLoader(false);
            this.showMessage('Ocurrio un error al actualizar la tabla de usuarios')
          });

        },
        //Crear el precontrato
        async createPreContract(contract = false) {

          this.$showLoader(true)

          let url = V.SERVER + V.OPS.CREATE_PRE_CONTRACT

          await this.$axios.get(url).then(response => {

            console.log("CONTRACT", response)

            let data = response.data;

            if (data[V.API.SUCCESS]) {

              this.client.files.contract = data.row.url;

            } else {

              if (this.auxContract === 0 && contract === false) {
                this.auxContract = 1;
                this.createPreContract()
              }
            }

            this.$showLoader(false)

          }).catch((e) => {
            this.$showLoader(false);
            this.showMessage('Error al generar el contrato')
          });
        },
        onBegin() {
          this.$refs.signaturePad.resizeCanvas();
        },
        onEnd() {
          this.btnSignature = false;
        },
        //Limpiar firma
        clearSignature() {

          this.btnSignature = true

          this.$refs.signaturePad.undoSignature()
        },
        //Actualizar firma
        async updateSignature(signature, filename) {

          this.$showLoader(true);

          let url = V.SERVER + V.OPS.GET_UPLOAD_FILE_URL + "?filename=" + filename;

          await this.$axios.get(url).then(async res => {

            if (res.status === 200) {
              this.$showLoader(false);
              let data = res.data;
              if (data[this.$v.API.SUCCESS]) {

                fbq("track", "signature_submitted");

                firebase.analytics().logEvent("signature_submitted");

                gtag("event", "signature_submitted", {send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"});

                var upImgUrl = res.data[this.$v.API.ROW].uploadImgUrl;
                var upRes = await this.$axios.put(upImgUrl, signature, {"Content-Type": signature.type});

                return upRes != null && upRes.status == 200;
              }
            }

            this.$showLoader(false);

          }).catch(e => {
            this.$showLoader(false);
            this.showMessage('Ocurrio un error al cargar la firma')
          });
        },
        urltoFile(url, filename, mimeType) {
          return fetch(url)
              .then(function (res) {
                return res.arrayBuffer();
              })
              .then(function (buf) {
                return new File([buf], filename, {type: mimeType});
              });
        },
        //Enviar firma
        async sendSignature(signature, filename) {

          this.$showLoader(true);

          let f = new Date();
          let month = f.getMonth() + 1;
          let day = f.getDate();
          let minute = f.getMinutes();
          let hour = f.getHours();
          let second = f.getSeconds();

          if (month < 10) month = "0" + month;
          if (day < 10) day = "0" + day;
          if (minute < 10) minute = "0" + minute;
          if (hour < 10) hour = "0" + hour;
          if (second < 10) second = "0" + second;

          this.today = "signature_" + f.getFullYear() + "-" + month + "-" + day + ".png";

          const {isEmpty, data} = this.$refs.signaturePad.saveSignature();

          if (!isEmpty && data) {

            const img = await this.urltoFile(data);

            if (await this.updateSignature(img, this.today))
              return false;

            let url = V.SERVER + V.OPS.SET_CONTRACT_SIGNATURE;

            await this.$axios.post(url, {filename: this.today}).then(async res => {

              if (res.status === 200) {

                this.$showLoader(false);

                if (res.data.success || res.data.reason === 5) {

                  await this.createPreContract(true)

                  this.congrats = true;

                } else if (res.data.reason === 1) {

                  await this.sendSignature(img, this.today)
                }
              }

            }).catch(e => {
              this.$showLoader(false);
              this.showMessage('Ocurrio un error al enviar la firma')
            });
          }
        },
      },
  watch: {
    'slider.val': function dialog(value) {
      if (this.program_debts.length !== 0) {
        this.calculator(this.program_debts)
      }
    },
    step: function (val) {
      if (val === 3) {
        this.createPreContract()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import './scss/SignatureStep.scss';

.bg-principal {
  background: linear-gradient(to bottom, #2A3D99, #29AAE1) !important;
}

.primary-text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.primary-text-bold {
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.secondary-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.simple-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.simple-text-gray {
  font-family: OpenSans-Regular;
  color: gray;
}

.simple-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.list {
  font-family: OpenSans-Regular;
}

.list-bold {
  font-family: OpenSans-Bold;
}

.title-modal {
  font-family: OpenSans-Bold;
  color: #2A3D99;
}

.text-modal {
  font-family: OpenSans-Regular;
  color: gray;
}

ul {
  list-style: none;
}

.btn-action {
  text-transform: none
}

.text-alert {
  color: #ff5252;
}

.icon-camera, .icon-eye, .icon-check {
  width: 1rem;
}

.simple-text br {
  display: none;
}

@media (max-width: 319px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .simple-text-gray br {
    display: none;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .simple-text-gray br {
    display: none;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: .9rem;
  }

  .text-modal {
    font-size: .8rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .8rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.2rem;
    color: #2a3d98;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
  }

  .secondary-text br {
    display: none;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: .9rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .9rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.4rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: .8rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.6rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  /*.bg-principal {
    margin-top: 6rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.7rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.5rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1.1rem;
  }

  .text-modal {
    font-size: 1.1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1.2rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

@media (min-width: 2561px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

</style>