import Vue from "vue";
import store from './store'
import Router from "vue-router";

//import AppHeader from "@/layout/AppHeader";
//import AppHeaderNew from "@/layout/AppHeaderNew";
//import AppFooter from "@/layout/AppFooter";
//import AppHeaderLanding from "@/layout/AppHeaderLanding";
//import AppFooterNew from "@/layout/AppFooterNew";
import AppFooterResponsive from "@/layout/AppFooter-Responsive";
import AppHeaderResponsive from "@/layout/AppHeader-Reponsive";
import AppHeaderResponsiveWhite from "@/layout/AppHeader-ReponsiveWhite";
import AppHeaderResponsiveWhiteGBtn from "@/layout/AppHeader-ReponsiveWhite-GBtn";


import Marketing from "@/views/Marketing.vue";
import Propuesta from "@/views/PropuestaClient.vue";
import DownloadApps from "@/views/DownloadApps.vue";


import LandingMobile1 from "@/views/LandingMobile1.vue";
import LandingMobile2 from "@/views/LandingMobile2.vue";
import Homepage from "@/views/Homepage.vue";

import Questions from "@/views/Questions.vue";
import RegisterOCRINEA from "@/views/RegisterOCRINEA.vue";
import RegisterOCRINEB from "@/views/RegisterOCRINEB.vue";
import LoginRegister from "@/views/LoginRegister.vue";
import Legal from "@/views/Legal.vue"
import Legalreader from "@/views/Legalread.vue"

import Calculator from "@/views/CalculatorDev.vue"
import CalculatorOld from "@/views/Calculator.vue"
import CalculatorNew from "@/views/CalculatorNew.vue"
import Start from "@/views/Start.vue"
import Start2 from "@/views/Start-2.vue"
import Calcular from "@/views/Calculator1.vue"
import Components from "@/views/Components.vue";
import FinishWin from "@/views/FinishWindows.vue";
import BankSelect from "@/views/BanksSelected.vue";
import Notification from "@/views/messageNotification.vue";
import WindowsProceso from "@/views/WindowsProceso.vue";
import Profile from "@/views/Profile.vue";
import QuestionsContractHigh from "@/views/QuestionsContractHigh.vue";
import QuestionsContractFA from "@/views/QuestionsContractFA.vue";
import SignaturePad from "./views/SignaturePad";
import SignaturePad2 from "./views/SignaturePad2";
import Blog from "./views/Blog";
import BlogEntry from "./views/BlogEntry";
import BlogWebinars from "./views/BlogWebinars";
import BlogEntryWebinars from "./views/BlogEntryWebinars";
import AuthorizationBCPin from "./views/AuthorizationBCPin";
import UploadFileContractHighRisk from "./views/UploadFileContractHighRisk";
import UploadFileGuideHighRisk from "./views/UploadFileGuideHighRisk";
import DownloadFileContractHighRisk from "./views/DownloadFileContractHighRisk";

import UpdatePaymentUser from "./views/UpdatePaymentUser";
import InfoVirtual from "./views/InfoVirtual";

//import Landing_Mativ2H from "@/views/Landing_Mativ2H.vue";
//import Landing_Mativ2M from "@/views/Landing_Mativ2M.vue";

import Mati_Landing_3 from "@/views/MatiLanding3.vue";
import Mati_Landing_2 from "@/views/MatiLanding2.vue";
import Mati_Landing_1 from "@/views/MatiLanding1.vue";
import Mati_Landing_4 from "@/views/MatiLanding4.vue";

import Marketing1 from "@/views/Marketing1.vue";

import Homepage2 from "@/views/HomePage2.vue";

import {V} from "./V"
import HomePage3 from "@/views/HomePage3";
import HomePage4 from "@/views/HomePage4";
import HowDoesItWork from "@/views/HowDoesItWork";
import Error from "@/views/Error";
import Simulator from "@/views/Simulator";
import FooterTemplate from "@/views/components/templates/FooterTemplate";
import footerTemplate from "@/views/components/templates/FooterTemplate";
import headerTemplate from "@/views/components/templates/HeaderTemplate";
import HeaderTemplateLan from "@/views/components/templates/HeaderTemplateLan";
import Loans from "@/views/Loans";
import HeaderTemplate from "@/views/components/templates/HeaderTemplate";
import Thanks from "@/views/Thanks";
import FinancialFreedom from "@/views/FinancialFreedom";
import UploadIneFile from "@/views/components/UploadIneFile";
import IneFront from "@/views/components/ine_image_ocr/IneFront";
import IneBack from "@/views/components/ine_image_ocr/IneBack";
import RegistrationSteps from "@/views/RegistrationSteps";
// import CarouselInstructions from "@/views/components/registration_steps/CarouselInstructions";
import CarouselSteps from "@/views/components/calculator_steps/PrevSteps.vue";


// PARA WINOH
// import HomeWinoh from './views/Winoh/homeWinoh.vue';
// import HomeWinoh from '@/views/Winoh/login.vue'
import HomeWinoh from '@/views/Winoh/Home.vue'



Vue.use(Router);

//const AppHeader = localStorage.getItem(V.STORE_KEYS.SESSION) ? AuthAppHeader : AppHeader

const router = new Router({
    mode: 'history',
    linkActiveClass: 'active',
    routes: [
        {
            path: "/",
            name: "home2",
            components: {
                header: HeaderTemplateLan,
                // default: HomePage3,
                default: FinancialFreedom,
                footer: FooterTemplate
            }
        },
        {
            path: "/liquidar-deudas-con-descuento",
            name: "home",
            components: {
                header: AppHeaderResponsive,
                default: Homepage,
                footer: AppFooterResponsive
            }
        },

    //RUTA PARA HOME WINOH ELIMINAR DESPUES
        {
            path: "/home-winoh",
            name: "home-winoh",
            components: {
                //header: AppHeaderResponsive,
                default: HomeWinoh,
                //footer: AppFooterResponsive
            }
        },
    //RUTA PARA HOME WINOH ELIMINAR DESPUES
    
        {
            path: "/reparadora-de-credito",
            name: "reparadora-de-credito",
            components: {
                header: headerTemplate,
                default: Homepage2,
                footer: footerTemplate
            }
        },
        {
            path: "/solicita-tu-asesoria",
            name: "solicita-tu-asesoria",
            components: {
                header: headerTemplate,
                default: HomePage4,
                footer: footerTemplate
            }
        },
        {
            path: "/como-funciona-una-reparadora-de-credito",
            name: "como-funciona-una-reparadora-de-credito",
            components:
                {
                    header: headerTemplate,
                    default: HowDoesItWork,
                    footer: footerTemplate
                }
        },
        {
            path: "/simula-tu-plan-de-ahorro",
            name: "simula-tu-plan-de-ahorro",
            components:
                {
                    header: headerTemplate,
                    default: Simulator,
                    footer: footerTemplate
                },
        },
        {
            path: "/error",
            name: "error",
            components:
                {
                    header: AppHeaderResponsiveWhite,
                    default: Error
                }
        },
        {
            path: "/prestamo-para-consolidar-tu-deuda",
            name: "prestamo-para-consolidar-tu-deuda",
            components:
                {
                    default: Loans,
                    footer: footerTemplate
                }
        },
        {
            path: "/gracias",
            name: "gracias",
            components:
                {
                    default: Thanks,
                    footer: footerTemplate
                }
        },
        {
            path: "/repara-tus-deudas",
            name: "repara-tus-deudas",
            components:
                {
                    header: headerTemplate,
                    // default: FinancialFreedom,
                    default: HomePage3,
                    footer: footerTemplate
                }
        },
        {
            path: "/sube-tu-ine",
            name: "sube-tu-ine",
            components:
                {
                    header: headerTemplate,
                    default: IneBack,
                    footer: footerTemplate
                }
        },
        {
            path: "/crea-tu-plan-personalizado-db-menos",
            name: "plan-personalizado",
            components: {
                header: HeaderTemplate,
                default: RegistrationSteps,
                footer: FooterTemplate
            }
        },
        {
            path: "/marketing1",
            name: "marketing1",
            components: {
                header: AppHeaderResponsive,
                default: Marketing1,
                footer: AppFooterResponsive
            }
        },
        /*
        {
            path: "/landing",
            name: "landing2",
            components: {
                header: AppHeaderLanding,
                default: LandingPrincipal,
                footer: AppFooterNew
            }
        },
        {
            path: "/applanding",
            name: "applanding",
            components: {
                header: AppHeaderNew,
                default: LandingApps
            }
        },
        {
            path: "/matilanding",
            name: "matilanding",
            components: {
                header: AppHeaderNew,
                default: LandingMati
            }
        },
        */
        {
            path: "/bankselect",
            name: "bankSelected",
            components: {
                header: AppHeaderResponsive,
                default: BankSelect,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/windowsProceso",
            name: "windowsProceso",
            components: {
                header: AppHeaderResponsive,
                default: WindowsProceso,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/regis",
            name: "regis",
            components: {
                //header: AppHeaderResponsive,
                //default: RegisterOCRINEA,
                //footer: AppFooterResponsive

                header: headerTemplate,
                default: IneFront,
                footer: footerTemplate
            },
            beforeEnter: (to, from, next) => {
                let calc = JSON.parse(localStorage.getItem("calculate"));
                let phone = JSON.parse(localStorage.getItem("phone"));
                if (!phone) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/regisB",
            name: "regisB",
            components: {
                //header: AppHeaderResponsive,
                //default: RegisterOCRINEB,
                //footer: AppFooterResponsive,

                header: headerTemplate,
                default: IneBack,
                footer: footerTemplate
            }
        },
        {
            path: "/DBM_SERVER_MESSAGES",
            name: "DBM_SERVER_MESSAGES",
            components: {
                header: AppHeaderResponsive,
                default: Notification,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/marketing/*",
            name: "marketing",
            components: {
                default: Marketing
            }
        },
        {
            path: "/sht/*",
            name: "sht",
            components: {
                default: Propuesta
            }
        },
        {
            path: "/app/*",
            name: "app",
            components: {
                default: DownloadApps
            }
        },
        {
            path: "/liquidardeuda1",
            name: "liquidardeuda1",
            components: {
                default: LandingMobile1
            }
        },
        {
            path: "/liquidardeuda2",
            name: "liquidardeuda2",
            components: {
                default: LandingMobile2
            }
        },

        /*
        {
            path: "/mati_1",
            name: "landingMativ2H",
            components: {
                default: Landing_Mativ2H
            }
        },
        {
            path: "/mati_2",
            name: "landingMativ2M",
            components: {
                default: Landing_Mativ2M
            }
        },
        */
        {
            path: "/landingMati",
            name: "landingMati3",
            components: {
                default: Mati_Landing_3
            }
        },
        {
            path: "/mati_2",
            name: "landingMati2",
            components: {
                default: Mati_Landing_2
            }
        },
        {
            path: "/mati_1",
            name: "landingMati1",
            components: {
                default: Mati_Landing_1
            }
        },
        {
            path: "/dbmati",
            name: "dbmati",
            components: {
                default: Mati_Landing_4
            }
        },
        {
            path: "/questions",
            name: "questions",
            components: {
                header: headerTemplate,
                default: Questions,
                footer: footerTemplate
            }
        },
        {
            path: "/register",
            name: "register",
            components: {
                header: AppHeaderResponsive,
                //default: Register,
                default: LoginRegister,
                footer: FooterTemplate

            },

            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                if (sess && !uploadFile) {
                    next('/profile')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/uploadFilesContracts/*",
            name: "uploadFilesContracts",
            components: {
                default: UploadFileContractHighRisk,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/uploadGuideFiles/*",
            name: "uploadGuideFiles",
            components: {
                default: UploadFileGuideHighRisk,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/calcularNew",
            name: "calcularNew",
            components: {
                header: AppHeaderResponsive,
                default: Calcular,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/calcular",
            name: "calcular",
            components: {
                header: AppHeaderResponsive,
                default: CalculatorNew,
                footer: AppFooterResponsive
            },
            meta: {
                auth: true
            },
            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/legal",
            name: "legal",
            components: {
                header: AppHeaderResponsive,
                default: Legal,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/legal/read",
            name: "legalread",
            components: {
                header: AppHeaderResponsive,
                default: Legalreader,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/components",
            name: "components",
            components: {
                header: AppHeaderResponsive,
                default: Components,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/profile",
            name: "profile",
            components: {
                header: AppHeaderResponsive,
                default: Profile,
                footer: AppFooterResponsive
            },
            meta: {
                auth: true
            },
            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                let user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER))
                let uploadFile = JSON.parse(localStorage.getItem("uploadFile"))
                let uploadGuide = JSON.parse(localStorage.getItem("uploadGuide"))
                if (!sess) {
                    next('/register')
                    return
                } else if (!user.contract) {
                    next('/start')
                    return
                } else if (uploadFile) {
                    next('/uploadFilesContracts')
                    return
                } else if (uploadGuide) {
                    next('/uploadGuideFiles')
                    return
                } else {
                    next()
                }
            }
        },
        {
            // RUTA PARA TESTING - IGNORAR
            path: "/CarouselSteps",
            name: "CarouselSteps",
            components: {
                //header: AppHeaderResponsiveWhite,
                default: CarouselSteps,
                //footer: AppFooterResponsive
            },/*
            beforeEnter: (to, from, next) => {
                let discountdoc = JSON.parse(localStorage.getItem("discountdoc"))
                if (discountdoc) {
                    next('/start')
                } else {
                    next()
                }
            }*/
        },
        {
            //Calculadora V1
            path: "/calculatorOld",
            name: "calculatorOld",
            components: {
                header: AppHeaderResponsiveWhite,
                default: CalculatorOld,
                footer: AppFooterResponsive
            },
            beforeEnter: (to, from, next) => {
                let discountdoc = JSON.parse(localStorage.getItem("discountdoc"))
                if (discountdoc) {
                    next('/start')
                } else {
                    next()
                }
            }
        },
        {
            //Calculadora V2 - 14/10/22
            path: "/calculator",
            name: "calculator",
            components: {
                header: AppHeaderResponsiveWhiteGBtn,
                default: Calculator,
                footer: FooterTemplate
            },
            beforeEnter: (to, from, next) => {
                let discountdoc = JSON.parse(localStorage.getItem("discountdoc"))
                if (discountdoc) {
                    next('/start')
                } else {
                    next()
                }
            }
        },
        {
            path: "/signature",
            name: "signature",
            components: {
                default: SignaturePad,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/questionContract/*",
            name: "questionContract",
            components: {
                default: QuestionsContractHigh,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/questionContractFA/*",
            name: "questionContractFA",
            components: {
                default: QuestionsContractFA,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/signatureContract",
            name: "signatureContract",
            components: {
                default: SignaturePad2,
                footer: AppFooterResponsive
            },
            meta: {
                auth: true
            },
            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/AuthorizationBC",
            name: "AuthorizationBC",
            components: {
                default: AuthorizationBCPin,
                footer: AppFooterResponsive
            },
            meta: {
                auth: true
            },
            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/finish",
            name: "finish",
            components: {
                header: AppHeaderResponsive,
                default: FinishWin,
                footer: AppFooterResponsive
            },
            meta: {
                auth: true
            },
            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/blogs",
            name: "blogs",
            components: {
                header: AppHeaderResponsive,
                default: Blog,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/blog/*",
            name: "blog",
            components: {
                header: AppHeaderResponsive,
                default: BlogEntry,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/postWebinars",
            name: "postWebinars",
            components: {
                header: AppHeaderResponsive,
                default: BlogWebinars,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/postWebinar",
            name: "postWebinar",
            components: {
                header: AppHeaderResponsive,
                default: BlogEntryWebinars,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/start",
            name: "start",
            components: {
                default: Start,
                footer: AppFooterResponsive
            },
            meta: {
                auth: true
            },
            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/start2",
            name: "start2",
            components: {
                header: AppHeaderResponsive,
                default: Start2,
                footer: AppFooterResponsive
            },
            meta: {
                auth: true
            },
            beforeEnter: (to, from, next) => {
                let sess = localStorage.getItem(V.STORE_KEYS.SESSION)
                if (!sess) {
                    next('/')
                    return
                } else {
                    next()
                }
            }
        },
        {
            path: "/filesContracts/*",
            name: "filesContracts",
            components: {
                default: DownloadFileContractHighRisk,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/updatePayment",
            name: "updatePayment",
            components: {
                default: UpdatePaymentUser,
                footer: AppFooterResponsive
            }
        },
        {
            path: "/infoVirtual",
            name: "infoVirtual",
            components: {
                default: InfoVirtual,
                footer: AppFooterResponsive
            }
        },
    ],
    scrollBehavior: to => {
        if (to.hash) {
            return {selector: to.hash};
        } else {
            return {x: 0, y: 0};
        }
    }
});

router.beforeEach((to, from, next) => {
    let session = localStorage.getItem(V.STORE_KEYS.SESSION)
    if (to.meta.requiresAuth && (session === undefined || session === null)) {
        next('/register')
    } else {
        next()
    }
});

export default router;
