<template>
  <v-app>
        <notifications></notifications>
        <router-view name="header"></router-view>
        <main>
            <fade-transition origin="center" mode="out-in" :duration="250">
                <router-view/>
            </fade-transition>
            <loading-overlay></loading-overlay>
        </main>
        <router-view name="footer"></router-view>
  </v-app>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import LoadingOverlay from "./views/components/LoadingOverlay"
import { V } from "./V"

export default {
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'DBMenos',
        // all titles will be injected into this template
        //titleTemplate: '%s | My Awesome Webapp'
        titleTemplate: '%s'
    },
    components: {
        FadeTransition,
        LoadingOverlay
    },
    mounted() {
        this.$store.dispatch('loadUser')
        this.$store.dispatch('downloadBanks')
        this.$store.dispatch('downloadEconomicActivities')
        this.$store.dispatch('downloadRelationBeneficiary')
        this.$store.dispatch('downloadMxStates')
        if (getLS(V.STORE_KEYS.SESSION)) {
            /*this.$axios.get(this.V.SERVER + this.C.OPS.VALIDATE_SESSION).then(response => {
                if (response.data.valid !== true) {
                    this.$router.push("/login")
                }
            })*/
        }
    }
};
function getLS(key) {
    let item = localStorage.getItem(key)
    if (item) {
        try {
            return JSON.parse(item)
        } catch (e) {
            return item
        }
    }
    else if (key === V.STORE_KEYS.SESSION) {
        //this.$router.push("/login")
    }
    return null
}
</script>
