<template>
  <div class="sticky">
    <section>
      <div class="d-sm-none">
        <header v-if="!session" class="header-global">
          <div
              transparent
              type
              expand
              style="position: absolute; width: 100%; background-color: white; z-index:15"
          >
            <nav class="navbar navbar-expand-lg">
              <a
                  slot="brand"
                  class="navbar-brand d-flex flex-row bd-highlight mb-3"
                  href="/"
                  style="
                  color: rgba(0, 0, 0, 0);
                  font-size: 20px;
                  maring-top: -1rem;
                  margin-bottom: 10px;
                "
              >
                <img
                    style="margin-top: 0.5rem"
                    src="/img/HomepageNew/Desktop/Logotipo azul para fondo blanco.png"
                />DBMenos
              </a>
              <button
                  v-b-toggle.sidebar-right
                  style="border: none; background-color: white"
              >
                <span class="navbar-toggler-icon">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      color="#2b2b58"
                      fill="currentColor"
                      class="bi bi-list"
                      viewBox="0 0 16 16"
                  >
                    <path
                        fill-rule="evenodd"
                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </span>
              </button>
              <b-sidebar
                  id="sidebar-right"
                  right
                  no-header
                  shadow
                  backdrop
                  sidebar-class="bg-light"
              >
                <div>
                  <ul
                      class="
                      navbar-nav
                      align-items-lg-center
                      border-bottom border-dark
                    "
                  >
                    <li
                        class="nav-item"
                        style="
                        padding: 1rem;
                        background-color: #2a3d99;
                        font-family: OpenSans-Bold;
                        font-size: 15pt;
                      "
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Isotipo blanco para fondo azul.png"
                          width="30"
                          height="30"
                      />
                      <a id="a-menu" href="/home2"> Menú</a>
                    </li>
                    <li class="nav-item border-bottom border-dark"
                        style="
                        padding: 1rem;
                        color: black;
                        background-color: #fff;
                        font-family: OpenSans-Bold;
                        font-size: 12pt;">

                      <a id="a-simulator" href="/calculator">Simula tu descuento</a>
                      <!-- <a id="a-simulator" href="/simula-tu-plan-de-ahorro">Simula tu descuento</a> -->

                    </li>
                    <li
                        class="nav-item border-bottom border-dark"
                        style="
                        padding: 1rem;
                        color: black;
                        background-color: #fff;
                        font-family: OpenSans-Bold;
                        font-size: 12pt;
                      "
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Inicio de sesión icono.svg"
                          width="30"
                      />
                      <a id="a-others" href="/register"> Inicia sesión</a>
                    </li>
                    <li
                        class="nav-item border-bottom border-dark"
                        v-on:click="showModalAboutUs()"
                        id="li-others"
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Quienes somos icono.svg"
                          width="35"
                      />
                      Conócenos
                    </li>
                    <li
                        class="nav-item border-bottom border-dark"
                        v-on:click="showModalWhatDoWeDo()"
                        id="li-others"
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Qué hacemos icono.svg"
                          width="30"
                      />
                      Cómo funciona
                    </li>

                    <li
                        class="nav-item border-bottom border-dark"
                        v-on:click="openWhatsApp()"
                        id="li-others"
                    >
                      <img
                          src="/img/landing_page/desktop/whats-app.png"
                          width="30"
                      />
                      Contáctanos
                    </li>

                    <li
                        class="nav-item"
                        v-on:click="friend()"
                        id="li-others"
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Quienes somos icono.svg"
                          width="30"
                      />
                      Refiere a un amigo
                    </li>

                  </ul>
                </div>
              </b-sidebar>
            </nav>
            <bar-template/>
          </div>
        </header>
      </div>

      <div class="d-none d-sm-block ">
        <header v-if="!session" class="header-global">
          <div
              transparent
              type
              expand
              style="position: absolute; width: 100%; background-color: white; z-index:15"
          >
            <nav class="navbar ">
              <a
                  slot="brand"
                  class="navbar-brand d-flex flex-row bd-highlight mb-3"
                  href="/"
                  style="
                  color: rgba(0, 0, 0, 0);
                  font-size: 20px;
                  maring-top: -1rem;
                  margin-bottom: 10px;
                "
              >
                <img
                    style="margin-top: 0.5rem"
                    src="/img/HomepageNew/Desktop/Logotipo azul para fondo blanco.png"
                />DBMenos
              </a>
              <button
                  v-b-toggle.sidebar-right
                  style="border: none; background-color: white"
              >
                <span class="navbar-toggler-icon">
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      class="bi bi-list"
                      viewBox="0 0 16 16"
                  >
                    <path
                        fill-rule="evenodd"
                        d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
                    />
                  </svg>
                </span>
              </button>
              <b-sidebar
                  id="sidebar-right"
                  right
                  no-header
                  shadow
                  backdrop
                  sidebar-class="bg-light"
              >
                <div class="text-left">
                  <ul
                      class="
                      navbar-nav
                      border-bottom border-dark
                    "
                  >
                    <li
                        class="nav-item"
                        style="
                        padding: 1rem;
                        background-color: #2a3d99;
                        font-family: OpenSans-Bold;
                        font-size: 15pt;
                      "
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Isotipo blanco para fondo azul.png"
                          width="30"
                          height="30"
                      />
                      <a id="a-menu" href="/home2"> Menú</a>
                    </li>
                    <li class="nav-item border-bottom border-dark"
                        style="
                        padding: 1rem;
                        color: black;
                        background-color: #fff;
                        font-family: OpenSans-Bold;
                        font-size: 12pt;">

                      <a id="a-simulator" href="/calculator">Simula tu descuento</a>
                      <!-- <a id="a-simulator" href="/simula-tu-plan-de-ahorro">Simula tu descuento</a> -->

                    </li>
                    <li
                        class="nav-item border-bottom border-dark"
                        style="
                        padding: 1rem;
                        color: black;
                        background-color: #fff;
                        font-family: OpenSans-Bold;
                        font-size: 12pt;
                      "
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Inicio de sesión icono.svg"
                          width="30"
                      />
                      <a id="a-others" href="/register"> Inicia sesión</a>
                    </li>
                    <li
                        class="nav-item border-bottom border-dark"
                        v-on:click="showModalAboutUs()"
                        id="li-others"
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Quienes somos icono.svg"
                          width="35"
                      />
                      Conócenos
                    </li>
                    <li
                        class="nav-item border-bottom border-dark"
                        v-on:click="showModalWhatDoWeDo()"
                        id="li-others"
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Qué hacemos icono.svg"
                          width="30"
                      />
                      Cómo funciona
                    </li>

                    <li
                        class="nav-item border-bottom border-dark"
                        v-on:click="openWhatsApp()"
                        id="li-others"
                    >
                      <img
                          src="/img/landing_page/desktop/whats-app.png"
                          width="30"
                      />
                      Contáctanos
                    </li>

                    <li
                        class="nav-item"
                        v-on:click="friend()"
                        id="li-others"
                    >
                      <img
                          src="/img/HomepageNew/Mobile/Quienes somos icono.svg"
                          width="30"
                      />
                      Refiere a un amigo
                    </li>

                  </ul>
                </div>
              </b-sidebar>
            </nav>
          </div>
        </header>
      </div>

      <div class="d-none d-lg-block">
        <header v-if="!session" class="header-global">
          <base-nav
              transparent
              type
              expand
              style="position: absolute; width: 100%; background-color: white"
          >
            <a
                slot="brand"
                class="navbar-brand"
                href="/"
                style="
                color: rgba(0, 0, 0, 0);
                font-size: 20px;
                maring-top: -1rem;
                margin-bottom: 10px;
              "
            >
              <img
                  style="margin-top: 0.5rem"
                  src="/img/HomepageNew/Desktop/Logotipo azul para fondo blanco.png"
              />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-6 collapse-brand">
                <a
                    href="/"
                    style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 20%;
                    margin-bottom: 15px;
                  "
                >
                  <img
                      style="width: 80%"
                      src="img/brand/DB Menos logo 2020_membrete.png"
                  />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto">

              <li v-on:click="openWhatsApp()" class="hand-cursor" style="color: #8f8f8f">
                <div>
                  <img src="/img/landing_page/desktop/whats-app.png" alt="whatsapp" width="20%" class="d-inline">
                  <span style="color: #0f52ad" class="d-inline ml-2"><b>Contáctanos</b></span>
                </div>
              </li>

              <li class="nav-item d-lg-block ml-lg-4 hand-cursor" v-on:click="friend()" style="color: #8f8f8f">Refiere a un amigo</li>

              <li
                  class="nav-item d-lg-block ml-lg-4 hand-cursor" style="color: #8f8f8f"
                  v-on:click="showModalAboutUs()"
              >
                Conócenos
              </li>

              <li
                  class="nav-item d-lg-block ml-lg-4 hand-cursor" style="color: #8f8f8f"
                  v-on:click="showModalWhatDoWeDo()"
              >
                Cómo funciona
              </li>

              <li class="nav-item d-lg-block ml-lg-4 hand-cursor" style="color: #8f8f8f"
                  v-on:click="register()">
                <img
                    src="/img/HomepageNew/Mobile/user.svg"
                    width="20"
                />
                Inicia sesión
              </li>
              <li class="nav-item d-lg-block ml-lg-4"
                  v-on:click="simulator()">
                <div>
                  <router-link
                      style="
                      width: 13rem;
                      background-color: #2a3d99;
                      font-family: OpenSans-Bold;
                      font-size: 12pt;
                    "
                      to="/calculator"
                      class="btn btn-icon text-white"
                  >
                    <span class="nav-link-inner--text">SIMULA TU AHORRO</span>
                  </router-link>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
        <header v-else class="header-global">
          <base-nav
              transparent
              type
              expand
              style="position: absolute; width: 100%; background-color: white"
          >
            <a
                slot="brand"
                class="navbar-brand"
                href="/"
                style="
                color: rgba(0, 0, 0, 0);
                font-size: 20px;
                maring-top: -1rem;
                margin-bottom: 10px;
              "
            >
              <img
                  style="margin-top: 0.5rem"
                  src="/img/HomepageNew/Desktop/Logotipo azul para fondo blanco.png"
              />DBMenos
            </a>
            <div class="row" slot="content-header" slot-scope="{ closeMenu }">
              <div class="col-6 collapse-brand">
                <a
                    href="/"
                    style="
                    color: rgba(0, 0, 0, 0);
                    font-size: 20%;
                    margin-bottom: 15px;
                  "
                >
                  <img
                      style="width: 80%"
                      src="img/brand/DB Menos logo 2020_membrete.png"
                  />
                </a>
              </div>
              <div class="col-6 collapse-close">
                <close-button @click="closeMenu"></close-button>
              </div>
            </div>

            <ul class="navbar-nav align-items-lg-center ml-lg-auto">
              <li
                  class="nav-item d-lg-block ml-lg-4"
                  v-on:click="showModalAboutUs()"
              >
                Conócones
              </li>
              <li class="nav-item d-lg-block ml-lg-4">|</li>
              <li
                  class="nav-item d-lg-block ml-lg-4"
                  v-on:click="showModalWhatDoWeDo()"
              >
                Cómo funciona
              </li>
              <li class="nav-item d-lg-block ml-lg-4">
                <div>
                  <base-dropdown>
                    <base-button
                        slot="title"
                        style="
                        width: 13rem;
                        background-color: #2a3d99;
                        font-family: OpenSans-Bold;
                        font-size: 12pt;
                      "
                        class="dropdown-toggle"
                    >{{
                        this.user
                            ? this.user.lastname_1
                                ? this.user.name + " " + this.user.lastname_1
                                : this.user.name
                            : "Perfil"
                      }}
                    </base-button
                    >
                    <button
                        v-if="this.user.contract"
                        class="dropdown-item"
                        @click="goProfile()"
                    >
                      Perfil
                    </button>
                    <button class="dropdown-item" @click="logout()">
                      Cerrar
                    </button>
                  </base-dropdown>
                </div>
              </li>
            </ul>
          </base-nav>
        </header>
      </div>
    </section>

    <modal
        :show.sync="modalAboutUs"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
    >
      <card shadow class="border-0">
        <template>
          <button
              type="button"
              class="close"
              aria-label="Close"
              @click="modalAboutUs = false"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="text-left">
            <p class="p-title-about-us">Conócenos</p>
            <p class="p-description-about-us">
              DB Menos es una plataforma digital enfocada en liquidar deudas
              atrasadas con tarjetas de crédito, departamentales o préstamos
              personales.
            </p>
          </div>
          <div class="embed-responsive embed-responsive-16by9">
            <video controls="true" class="embed-responsive-item"
                   src="/img/HomepageNew/Videos/Quiénes somos/Quiénes somos - DB Menos.mp4" autoplay muted>
            </video>
          </div>
          <div class="text-center p-button-init-about-us">
            <base-button
                v-on:click="redirectHome()"
                style="
                width: 13rem;
                background-color: #2a3d99;
                font-family: OpenSans-Bold;
                font-size: 12pt;
              "
            >
              INICIA AHORA
            </base-button>
          </div>
        </template>
      </card>
    </modal>
    <modal
        :show.sync="modalWhatDoWeDo"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
    >
      <card shadow class="border-0">
        <template>
          <button
              type="button"
              class="close"
              aria-label="Close"
              @click="modalWhatDoWeDo = false"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="text-left">
            <p class="p-title-about-us">Cómo funciona</p>
            <p class="p-description-about-us">
              A través de un proceso digital de registro en línea en nuestra
              página web o mediante un Asistente Virtual Inteligente en
              WhatsApp, creamos un plan de liquidación de deuda a tu medida, una
              cuenta y un contrato digital.
            </p>
          </div>
          <div class="embed-responsive embed-responsive-16by9">
            <video controls="true" class="embed-responsive-item"
                   src="/img/HomepageNew/Videos/Qué hacemos/Proceso automático MATI - WhatsApp.mp4" autoplay muted>
            </video>
          </div>
          <div class="text-center p-button-init-about-us">
            <base-button
                v-on:click="openWhatsApp()"
                style="
                width: 13rem;
                background-color: #2a3d99;
                font-family: OpenSans-Bold;
                font-size: 12pt;
              "
            >
              <img
                  src="/img/HomepageNew/Desktop/WhatsApp logo.svg"
                  width="15%"
              />
              PRUÉBAME
            </base-button>
          </div>
        </template>
      </card>
    </modal>
  </div>
</template>
<script>
import BaseNav from "@/components/BaseNav";
import BaseDropdown from "@/components/BaseDropdown";
import CloseButton from "@/components/CloseButton";
import moment from "moment";
import modal from "@/components/Modal";
import Vue from "vue";
import {V} from "../V";
import BarTemplate from "@/views/components/templates/BarTemplate";

export default {
  async created() {
    this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
    this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    this.$forceUpdate();
    //await this.checkSession()
    this.getAccountData();
    this.modalAboutUs = false;
    this.modalWhatDoWeDo = false;
  },
  computed: {
    //        user() { return localStorage.getItem(V.STORE_KEYS.USER) },
    //        session() { return localStorage.getItem(V.STORE_KEYS.SESSION) }
  },
  data() {
    return {
      session: null,
      account: {},
      user: {},
      modalAboutUs: false,
      modalWhatDoWeDo: false,
    };
  },
  mounted() {
    this.$root.$on("refreshHeader", (query) => {
      this.$forceUpdate();
      this.session = localStorage.getItem(V.STORE_KEYS.SESSION);
      this.user = JSON.parse(localStorage.getItem(V.STORE_KEYS.USER));
    });
  },
  methods: {
    showModalAboutUs() {
      this.modalAboutUs = true;
    },
    showModalWhatDoWeDo() {
      this.modalWhatDoWeDo = true;
    },
    register()
    {
      window.location.href="/register"
    },
    simulator()
    {
      // window.location.href="/simula-tu-plan-de-ahorro"
      window.location.href="/calculator"
    },
    friend()
    {
      window.open("https://mate.dbmenos.com/#/login")
    },
    openWhatsApp() {
      window.open(
          "https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos"
      );
    },
    redirectHome() {
      window.location.reload();
    },
    async logout() {
      this.$store.commit("setUser", {});
      localStorage.clear();
      await this.checkSession();
      this.getAccountData();
      this.$root.$emit("refreshHeader");
      //console.log("Cerrar sesión")
      this.$router.push("/").catch((err) => {
      });
    },
    async checkSession() {
      let _session = localStorage.getItem(this.$v.STORE_KEYS.SESSION);
      if (_session) {
        this.session = _session;
        let currentDate = moment();
        let expiresDate = moment(this.session.expires, "YYYY-MM-DD");

        if (
            expiresDate.isSame(currentDate) ||
            expiresDate.isBefore(currentDate)
        ) {
          this.logout();
        } else {
          try {
            let validSession = await this.verifySession();
            if (!validSession) {
              this.logout();
            }
          } catch (error) {
            this.logout();
          }
        }
      } else {
        this.session = {
          session: "",
        };
      }
    },
    getAccountData() {
      let _account = this.$store.getters.getAccount;
      if (_account) {
        this.account = _account;
      } else {
        this.account = {};
      }
    },
    goProfile() {
      let url = V.SERVER + V.OPS.GET_SIGNATURE_INFO;
      this.$axios.get(url, this.session).then((response) => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          this.$router.push("/profile").catch((err) => {
          });
        }
      });
    },
    goRegister() {
      let url = V.SERVER + V.OPS.GET_PROGRAM_STEP;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[V.API.SUCCESS]) {
          if (
              data.row.basic_information == 0 ||
              data.row.basic_information2 == 0 ||
              data.row.address_information == 0 ||
              data.row.official_id == 0
          ) {
            this.$router.push("/start");
          } else if (
              data.row.basic_information == 1 &&
              data.row.basic_information2 == 1 &&
              data.row.address_information == 1 &&
              data.row.official_id == 1 &&
              data.row.credit_report == 0
          ) {
            this.$router.push({
              path: "/AuthorizationBC",
            });
          } else if (
              data.row.basic_information == 1 &&
              data.row.basic_information2 == 1 &&
              data.row.address_information == 1 &&
              data.row.official_id == 1 &&
              data.row.credit_report == 1 &&
              data.row.program_calculation == 0
          ) {
            this.$router.push({
              path: "/calcular",
            });
          } else if (
              data.row.basic_information == 1 &&
              data.row.basic_information2 == 1 &&
              data.row.address_information == 1 &&
              data.row.official_id == 1 &&
              data.row.credit_report == 1 &&
              data.row.program_calculation == 1 &&
              data.row.contract == 0 &&
              data.row.active == 0
          ) {
            this.$router.push({
              path: "/start2",
            });
          } else if (
              data.row.basic_information == 1 &&
              data.row.basic_information2 == 1 &&
              data.row.address_information == 1 &&
              data.row.official_id == 1 &&
              data.row.credit_report == 1 &&
              data.row.program_calculation == 1 &&
              data.row.contract == 1 &&
              data.row.active == 0
          ) {
            this.$router.push({
              path: "/finish",
            });
          } else if (
              (data.row.basic_information == 1 &&
                  data.row.basic_information2 == 1 &&
                  data.row.address_information == 1 &&
                  data.row.official_id == 1 &&
                  data.row.credit_report == 1 &&
                  data.row.program_calculation == 1 &&
                  data.row.contract == 1) ||
              data.row.active == 1
          ) {
            this.$router.push({
              path: "/profile",
            });
          }
        }
      });
    },
    async verifySession() {
      try {
        let res = await this.$axios.post(V.OPS.VALIDATE_SESSION);
        if (res.data.valid) {
          return true;
        } else {
          return false;
        }
      } catch (error) {
        //                console.log(error)
        return false;
      }
    },
  },
  updated() {
    //this.$forceUpdate()
    //this.getAccountData()
  },
  watch: {
    $route(to, from) {
      if (
          (to.name === "profile" || to.name === "calculator") &&
          from.name === "login"
      ) {
        this.checkSession();
        this.getAccountData();
      }
    },
  },
  components: {
    BarTemplate,
    BaseNav,
    CloseButton,
    BaseDropdown,
    modal,
  },
};
</script>
<style>

.sticky {
  position: sticky;
  top: 0;
  z-index: 2;
}

.hand-cursor
{
  cursor: pointer;
}

@font-face {
  font-family: OpenSans-Bold;
  src: url(/fonts/HomepageNew/OpenSans-Bold.ttf);
}

 {
  font-family: OpenSans-Light;
  src: url(/fonts/HomepageNew/OpenSans-Light.ttf);
}

 {
  font-family: OpenSans-Regular;
  src: url(/fonts/HomepageNew/OpenSans-Regular.ttf);
}

 {
  font-family: Gotham-ultra;
  src: url(/fonts/Gotham_Font/gotham-ultra.ttf);
}

@font-face {
  font-family: OpenSans-ExtraBold;
  src: url(/fonts/HomepageNew/OpenSans-ExtraBold.ttf);
}

#a-menu {
  color: white !important;
  padding: 10pt;
  font-family: OpenSans-Bold;
}

#a-others {
  color: #2a3d99 !important;
  padding: 10pt;
  font-size: 15pt;
  font-family: OpenSans-Bold;
}

#a-simulator {
  color: #2a3d99 !important;
  padding: 10pt;
  font-size: 15pt;
  font-family: OpenSans-Bold;
}


#li-others {
  color: #2a3d99 !important;
  padding: 15pt !important;
  font-family: OpenSans-Bold;
  padding: 1rem;
  font-family: OpenSans-Bold;
  font-size: 15pt;
}

.p-title-about-us {
  color: #2a3d99;
  font-family: OpenSans-Bold;
  font-size: 20pt;
}

.p-description-about-us {
  color: #808080;
  font-family: OpenSans-Regular;
  font-size: 12pt;
}

.p-button-init-about-us {
  padding: 12pt;
}

#sidebar-right {
  background-color: white !important;
}

@media (min-width: 1200px)
{
  .container {
    max-width: 100% !important;
  }
}


</style>
