<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto p-0 p-sm-3">

      <div class="col-12 mt-5 mb-5">
        <p class="text-center section-title open-sans-bold text-blue">
          Calcula el descuento total de tu deuda
        </p>
        <p class="text-center section-title open-sans-light text-blue">Obtén hasta un 70% de descuento por tarjetas de crédito y préstamos personales</p>
      </div>


      <!-- <div class="col-12 col-sm-10 col-lg-6 mx-auto text-center text-lg-right">
        <img src="/img/financial_freedom/images/focus.png" alt="focus" class="mr-sm-5" width="50%">
      </div> -->

      <!-- <div class="col-12 col-sm-10 col-lg-6 mx-auto p-0">
        <v-stepper
            v-model="step"
            vertical
            height="100%"
            elevation="0"
        >
          <v-stepper-step
              class="p-sm-0"
              step="1"
              color="#2a3d99"
              @click="step = 1"
          >
            <p class="mt-3 secondary-text text-blue">
              Utilizamos tecnología para ofrecerte <br>
              la mejor solución personalizada.
            </p>
          </v-stepper-step>

          <v-stepper-content step="1">
          </v-stepper-content>

          <v-stepper-step
              class="p-sm-0"
              step="2"
              color="#2a3d99"
              @click="step = 2"
          >
            <p class="mt-3 secondary-text text-blue">
              Te ayudamos con las molestas <br>
              llamadas de cobranza.
            </p>
          </v-stepper-step>

          <v-stepper-content step="2">
          </v-stepper-content>

          <v-stepper-step
              class="p-sm-1"
              step="3"
              color="#2a3d99"
              @click="step = 3"
          >
            <p class="mt-3 secondary-text text-blue">
              Te acompañamos en el proceso hasta <br>
              reincorporarte al sistema crediticio.
            </p>
          </v-stepper-step>

          <v-stepper-content step="3">
          </v-stepper-content>

        </v-stepper>
      </div> -->
      
      <!-- <div class="col-12 text-center">
        <v-btn
            color="#2a3d99"
            :loading="loading"
            class="btn-text-transform"
        >
          <span class="text-white simple-text open-sans-bold ">Diseña tu plan en 5 minutos</span>
        </v-btn>
      </div> -->

      <CalculatorFreedom/>
    </div>

  </div>

</template>

<script>
import CalculatorFreedom from './CalculatorFreedom.vue';

export default {
    name: "Differences",
    data: () => ({
        step: 1,
        loading: false
    }),
    components: { CalculatorFreedom }
}
</script>

<style scoped>

/*Fonts*/
.open-sans-light {
  font-family: OpenSans-Light;
}

.open-sans-bold {
  font-family: OpenSans-Bold;
}

/*Colors*/

.text-blue {
  color: #2a3d99 !important;
}

.btn-text-transform {
  text-transform: none
}

@media (max-width: 575px) {
  .section-title {
    font-size: 1.2rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-title {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-title {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1.2rem;
  }

  .simple-text {
    font-size: 1.1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .secondary-text {
    font-size: 1.1rem;
  }

  .simple-text {
    font-size: 1.1rem;
  }
}

@media (min-width: 1200px) and (max-width: 2459px) {
  .section-title {
    font-size: 1.4rem;
    margin-bottom: 0;
  }

  .secondary-text {
    font-size: 1.2rem;
  }
}

@media (min-width: 2560px) {
  .section-title {
    font-size: 2.3rem;
  }

  .secondary-text {
    font-size: 2rem;
  }

  .simple-text {
    font-size: 1.2rem;
  }
}

</style>