<template>
    <div class="main-container">
        <div class="container col-12 col-md-8 col-lg-4">

            <div class="row justify-content-center">            

                <div class="title-ctn col-10 col-md-6 col-lg-6">
                    <h5>Descarga el periodo que quieres consultar</h5>
                </div>

                <div class="inputs-ctn">

                    <div class="input-span-ctn">
                        <span>De</span>                        
                        <v-select
                            :items="months"
                            label="ENE"
                            item-text="name"
                            dense
                            solo
                            flat
                            hide-details
                            class="select-field"
                        ></v-select>
                    </div>



                    <div class="input-span-ctn">
                        <span>Hasta</span>                            
                        <v-select
                            :items="months"
                            label="DIC"
                            item-text="name"
                            dense
                            solo
                            flat
                            hide-details
                            class="select-field"
                        ></v-select>
                    </div>
                </div>
                
            </div>

            <div class="btn-ctn row" >

                <!-- <base-button
                    tag="a"
                    plain
                    class="mr-0 text-white download-btn col-7"
                >
                    <img class="mt-6 mb-5" src="/img/winoh/download-circle-grey.png" alt="download-icon">
                </base-button> -->

                <a class="mr-0 text-white download-btn col-7 col-md-7 col-lg-6">
                    <img class="mt-6 mb-5" src="/img/winoh/download-circle-grey.png" alt="download-icon">
                </a>

                <div class="col-12 download-text">
                    <h5>Descarga en el botón</h5>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
export default {
        name: "DownloadCSV",
        data() {
            return {
                months: [
                    { name: 'Enero',        number: 1 },
                    { name: 'Febrero',      number: 2 },
                    { name: 'Marzo',        number: 3 },
                    { name: 'Abril',        number: 4 },
                    { name: 'Mayo',         number: 5 },
                    { name: 'Junio',        number: 6 },
                    { name: 'Julio',        number: 7 },
                    { name: 'Agosto',       number: 8 },
                    { name: 'Septiembre',   number: 9 },
                    { name: 'Octubre',      number: 10 },
                    { name: 'Noviembre',    number: 11 },
                    { name: 'Diciembre',    number: 12 },
                ]
            }
        },
    }
</script>

<style lang="scss" scoped>
    @import './scss/DownloadCSV.scss';
</style>