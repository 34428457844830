<template>
  <v-carousel v-model="model">
    <v-carousel-item v-for="(item, index) in debts" :key="index">
      <v-card elevation="3" class="mt-5 mx-auto" style="width: 80%">
        <v-card-text>
          <div class="text-center">
            <img :src="image(item.bank)" :alt="item.bank" class="mb-5 img-bank">
          </div>
          <div class="">
            <p class="list">Debes:</p>
            <p class="list-bold" style="color: #000000">{{ item.debt }}</p>
            <p class="list">Con DB Menos paga:</p>
            <p class="list-bold" style="color: #2A3D99">{{ item.pay }}</p>
            <p class="list">Descuento del:</p>
            <p class="list-bold" style="color: #8ac43f">{{ item.percent }}%</p>
          </div>
        </v-card-text>
      </v-card>
    </v-carousel-item>
  </v-carousel>
</template>

<script>
export default {
  name: "Carousel",
  props:
      {
        debts: []
      },
  data: () => ({
    model: 0,
  }),
  methods: {
    image(idBank) {
      let prefix = '/img/new_process/desktop/banks/'
      switch (idBank) {

        case 1:
          prefix += 'bbva.svg'
          break
        case 2:
          prefix += 'citibanamex.svg'
          break
        case 3:
          prefix += 'santander.svg'
          break
        case 4:
          prefix += 'hsbc.svg'
          break
        case 5:
          prefix += 'banorte.svg'
          break
        case 6:
          prefix += 'sears.svg'
          break
        case 7:
          prefix += 'liverpool.svg'
          break
        case 8:
          prefix += 'american_express.svg'
          break
        case 9:
          prefix += 'scotiabank.svg'
          break
        case 10:
          prefix += 'global_card.svg'
          break
        case 11:
          prefix += 'inbursa.svg'
          break
        case 12:
          break
        case 13:
          prefix += 'invex.svg'
          break
        case 14:
          prefix += 'credito_familiar.svg'
          break
        case 15:
          prefix += 'suburbia.svg'
          break
        case 16:
          prefix += 'waltmart.svg'
          break
        case 17:
          prefix += 'credomatic.svg'
          break
        case 18:
          prefix += 'secorse.svg'
          break
        case 19:
          prefix += 'recremex.svg'
          break
        case 20:
          prefix += 'c_a.svg'
          break
        case 21:
          prefix += 'palacio_de_hierro.svg'
          break
      }

      return prefix
    }
  }
}
</script>

<style scoped>

.list {
  font-family: OpenSans-Regular;
}

.list-bold {
  font-family: OpenSans-Bold;
}

@media (min-width: 320px) and (max-width: 374px) {
  .list {
    font-size: 1rem;
  }

  .list-bold {
    font-size: 1.2rem;
  }

  .img-bank {
    width: 80%;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .list {
    font-size: 1rem;
  }

  .list-bold {
    font-size: 1.2rem;
  }

  .img-bank {
    width: 80%;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .list {
    font-size: 1rem;
  }

  .list-bold {
    font-size: 1rem;
  }

  .img-bank {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .list {
    font-size: 1.2rem;
  }

  .list-bold {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 50%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .list {
    font-size: 1.2rem;
  }

  .list-bold {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 40%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .list {
    font-size: 1.2rem;
  }

  .list-bold {
    font-size: 1.2rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 40%;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .list {
    font-size: 1.5rem;
  }

  .list-bold {
    font-size: 1.5rem;
    margin-bottom: .5rem;
  }

  .img-bank {
    width: 20%;
  }
}

</style>