<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto p-0 p-sm-3 my-5">

      <div class="col-12">
        <p class="text-center section-title open-sans-light text-blue">Preguntas <span
            class="open-sans-bold">frecuentes</span></p>
      </div>

      <div class="row col-12 mx-auto">
        <div class="col-12 col-sm-10 col-lg-6 mx-auto p-0 p-sm-3">
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="(item, index) of questionsLeft"
                               class="mt-5 border" :key="index">
              <v-expansion-panel-header color="#f1f1f1" class="px-0">
                <p class="pt-1 secondary-text text-question open-sans-regular" v-html="item.title"></p>
                <template v-slot:actions>
                  <v-icon color="#2b2b58">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="p-0">
                <p class="mt-3 secondary-text text-answer open-sans-regular" v-html="item.answer"></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div class="col-12 col-sm-10 col-lg-6 mx-auto p-0 p-sm-3">
          <v-expansion-panels multiple>
            <v-expansion-panel v-for="(item, index) of questionsRight" class="mt-5 border" :key="index">
              <v-expansion-panel-header color="#f1f1f1" class="px-0">
                <p class="pt-1 secondary-text text-question open-sans-regular" v-html="item.title"></p>
                <template v-slot:actions>
                  <v-icon color="#2b2b58">
                    $expand
                  </v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="p-0">
                <p class="mt-3 secondary-text text-answer open-sans-regular" v-html="item.answer"></p>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "FrequentQuestions",
  data: () => ({
    questionsLeft:
        [
          {
            title: '¿Por qué no debo recibir depósitos en los bancos <br> donde tengo adeudo?',
            answer: 'Cuando ingresas al programa, nosotros reportamos al acreedor tu incapacidad de pago para poder  ' +
                'conseguir el descuento inicial, si el acreedor nota que tienes una cuenta donde ingresan fondos, no  ' +
                'podremos avanzar con la negociación. Te dejamos este video en donde te lo explicamos: ' +
                '<a href="https://www.youtube.com/watch?v=CxoX4EHkKK8" target="_blank">Ver video</a>\n'
          },
          {
            title: '¿Cómo sabe el banco que estoy negociando mis deudas?',
            answer: 'Cuando realizas tu primer ahorro, informamos al banco que estás dentro del programa DB Menos.  ' +
                'Posteriormente cada que realizas un ahorro mensual le informamos de tu cumplimiento en el  programa ' +
                'para solicitar el mejor descuento posible. Te dejamos este video en donde te explicamos a detalle: ' +
                '<a href="https://www.youtube.com/watch?v=B7WIpL9dw0Q" target="_blank">Ver video</a>'
          },
          {
            title: '¿Cómo queda mi buró de crédito?',
            answer: 'Cuando queda liquidada tu cuenta, las deudas quedan marcadas en Buró de Crédito con Clave 97, ' +
                'esto  significa que la deuda se ha liquidado a través de una quita o descuento. Esto es mucho mejor a ' +
                'que se vea reflejado como que "no pagaste tu deuda". Te dejamos este video en donde te lo explicamos: ' +
                '<a href="https://www.youtube.com/watch?v=vTI28Dvzw40" target="_blank">Ver video</a>'
          },
        ],
    questionsRight:
        [
          {
            title: '¿Qué garantía tengo de que me ofrezcan una <br> línea de crédito si liquido con ustedes?',
            answer: 'Es algo que no podemos garantizar por escrito, ya que no depende de DB Menos el que seas 100%  ' +
                'puntual con tus ahorros en el programa. Si en el programa fuiste puntual con tus ahorros, podemos ' +
                'ofrecerte una  nueva línea de crédito y de esta manera mejorar tu SCORE Crediticio.  Te dejamos este ' +
                'video en donde te explicamos a detalle:  <a href="https://www.youtube.com/watch?v=ObOyZB3MFyQ" target="_blank">Ver video</a>\n'
          },
          {
            title: '¿Si hablo con el banco me van a decir si están negociando mi deuda?',
            answer: 'Al ser una institución nacional es difícil que sepan el historial completo de cada cliente, no  ' +
                'obstante, a través de nuestro contrato te garantizamos un descuento si eres puntual con tus  ahorros. ' +
                'Te dejamos este video en donde te lo explicamos: <a href="https://www.youtube.com/watch?v=B7WIpL9dw0Q" target="_blank">Ver video</a>'
          },
          {
            title: '¿Cuánto tiempo más me van a seguir llamando de cobranza?',
            answer: 'Las llamadas de cobranza irán disminuyendo conforme avances en tu plan de ahorro de manera  ' +
                'puntual, ya que al reportar a tu acreedor el progreso de tu programa siempre y cuando cumplas en  ' +
                'tiempo y forma tus ahorros, el banco entenderá que estás tomando acción para solucionar tu deuda.  ' +
                'Independientemente de esto, nuestra App te ayuda a bloquear las llamadas de cobranza para que no  ' +
                'tengas que lidiar con ellos. Te dejamos este video en donde te lo explicamos:  <a href="https://www.youtube.com/watch?v=2202iy-Vt8A" target="_blank">Ver video</a>'
          },

        ]
  })
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

/*Fonts*/
.open-sans-light {
  font-family: OpenSans-Light;
}

.open-sans-bold {
  font-family: OpenSans-Bold;
}

.open-sans-regular {
  font-family: OpenSans-Regular;
}

/*Colors*/
.text-blue {
  color: #2a3d99 !important;
}

.text-question {
  font-size: .8rem;
}

.text-answer {
  font-size: .8rem;
}

.border {
  border-style: solid !important;
  /* border-color: #2b2b58 !important; */
  border-color: $db-blue !important;
  border-width: 0px 0px 0px 10px !important;
}

@media (max-width: 575px) {
  .section-title {
    font-size: 1.2rem;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-title {
    font-size: 1.2rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 1200px) and (max-width: 2459px) {
  .section-title {
    font-size: 1.4rem;
  }
}

@media (min-width: 2560px) {
  .section-title {
    font-size: 2.3rem;
  }

  .secondary-text {
    font-size: 1.8rem;
  }
}

</style>