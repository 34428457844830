<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto">
      <div class="col-12">
        <p class="text-center primary-text">
          Negociamos con los siguientes <br>
          bancos e instituciones
        </p>
      </div>
    </div>

    <div class="row col-12 col-sm-10 mx-auto">

      <div class="col-3 mobile" v-for="(item,index) of images">
        <img :src="prefixMobile+item" :alt="index">
      </div>

      <div class="col-3 desktop" v-for="(item,index) of images">
        <img :src="prefixDesktop+item" :alt="index">
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Banks",
  data: () =>
      ({
        prefixDesktop: '/img/how_does_it_work/desktop/banks/',
        prefixMobile: '/img/how_does_it_work/mobile/banks/',
        images:
            [
              "BBVA.svg",
              "Banamex.svg",
              "Santander.svg",
              "Scotiabank.svg",
              "Banorte.svg",
              "Liverpool.svg",
              "HSBC.svg",
              "SEARS.svg",
              "C_A.svg",
              "Walmart.svg",
              "Suburbia.svg",
              "Inbursa.svg",
              "Palacio_de_Hierro.svg",
              "Recremex.svg",
              "Credito_Familiar.svg",
              "Secorse.svg",
              "Invex.svg",
              "American_Express.svg",
              "Global_Card.svg",
              "Lion.svg",
            ],
      })
}
</script>

<style scoped>

.bg-principal {
  padding-bottom: 3rem;
  background-color: #FFFFFF;
}

.primary-text {
  font-family: OpenSans-Bold;
  color: #2a3d99;
}

img
{
  width: 70%;
}

@media (min-width: 320px) and (max-width: 374px) {
  .primary-text {
    font-size: 1rem;
    text-align: left !important;
  }

  .desktop {
    display: none;
  }

  img {
    width: 3rem;
    height: 1.5rem;
  }

  .primary-text br
  {
    display: none;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .primary-text {
    font-size: 1rem;
  }

  .desktop {
    display: none;
  }

  img {
    width: 3rem;
    height: 2rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .primary-text {
    font-size: 1rem;
    text-align: center;
  }

  .desktop {
    display: none;
  }

  img {
    width: 4rem;
    height: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .primary-text {
    font-size: 1.2rem;
    text-align: center;
  }

  .mobile {
    display: none;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .primary-text {
    font-size: 1.4rem;
  }

  .mobile {
    display: none;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .primary-text {
    font-size: 1.4rem;
  }

  .mobile {
    display: none;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .primary-text {
    font-size: 2rem;
  }

  .mobile {
    display: none;
  }
}

</style>