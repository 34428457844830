<template>

  <div class="bg-principal">

    <div class="row">
      <div class="col-12 col-sm-10 mx-auto">
        <p class="font-weight-bold text-center text-md-left f-title">¿Qué obtienes al ingresar al programa?</p>
      </div>
    </div>

    <div class="row col-12 col-sm-11 mx-auto">
      <div class="col-6 col-sm-3 text-center" v-for="item of imagesUp">
        <img :src="item.image" :alt="item.index">
        <p class="text-left text-sm-center text">{{ item.textUp }} <br> {{ item.textDown }}</p>
      </div>
    </div>

    <div class="row col-12 col-sm-11 mx-auto">
      <div class="col-6 col-sm-3 text-center" v-for="item of imagesDown">
        <img :src="item.image" :alt="item.index">
        <p class="text-left text-sm-center text">{{ item.textUp }} <br> {{ item.textDown }}</p>
      </div>
    </div>

  </div>

</template>

<script>
export default {
  name: "WhatDoYouGet",
  data: () =>
      ({
        imagesUp:
            [
              {
                id: 1,
                textUp: 'Asesoría legal',
                textDown: 'y financiera',
                image: '/img/HomepageNew/Mobile/Asesoría legal y financiera.png'
              },
              {
                id: 2,
                textUp: 'Ayuda con la',
                textDown: 'cobranza',
                image: '/img/HomepageNew/Mobile/Ayuda con la cobranza.png'
              },
              {
                id: 3,
                textUp: 'Reincorporación al',
                textDown: 'sistema crediticio',
                image: '/img/HomepageNew/Mobile/Reincorporación al sistema crediticio.png'
              },
              {
                id: 4,
                textUp: 'Ningún cobro',
                textDown: 'oculto',
                image: '/img/HomepageNew/Mobile/Ningún cobro oculto.png'
              },
            ],
        imagesDown:
            [
              {
                id: 5,
                textUp: 'Control de tu',
                textDown: 'programa',
                image: '/img/HomepageNew/Mobile/Control de tu programa.png'
              },
              {
                id: 6,
                textUp: 'Compromiso',
                textDown: 'por escrito',
                image: '/img/HomepageNew/Mobile/Compromiso por escrito.png'
              },
              {
                id: 7,
                textUp: 'Atención',
                textDown: 'personalizada',
                image: '/img/HomepageNew/Mobile/Atención personalizada.png'
              },
              {
                id: 8,
                textUp: 'Mejora en tus',
                textDown: 'finanzas personales',
                image: '/img/HomepageNew/Mobile/Mejora en tus finanzas personales.png'
              },
            ]
      })
}
</script>

<style scoped>

.bg-principal {
  background-color: #2a3d99;
}

.f-title
{
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.text {
  font-family: Poppins, sans-serif;
  color: #FFFFFF;
}

@media (min-width: 320px) and (max-width: 374px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }

  img {
    width: 100%;
  }

}

@media (min-width: 375px) and (max-width: 424px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }

  img {
    width: 70%;
  }

}

@media (min-width: 425px) and (max-width: 767px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }

  img {
    width: 80%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .f-title {
    font-size: 1.4rem;
  }

  .text {
    font-size: 1rem;
  }

  img {
    width: 80%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  .f-title {
    font-size: 2rem;
  }

  .text {
    font-size: 1.2rem;
  }

  img {
    width: 70%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  .f-title {
    font-size: 2rem;
  }

  .text {
    font-size: 1.3rem;
  }

  img {
    width: 40%;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .f-title {
    font-size: 3rem;
  }

  .text {
    font-size: 2.2rem;
  }

  img {
    width: 40%;
  }
}

</style>