<template>

  <div class="bg-principal">

    <div class="cont-form pt-sm-5">

      <div class="d-sm-none pt-5">
        <p class="text-center mt-1 mb-5 secondary-text" v-if="step<3">
          Déjanos tus datos y calcula tu plan <br> personalizado en <span class="secondary-text-bold">3 pasos</span>
        </p>
      </div>

      <div class="col-12 col-md-8 col-lg-6 mx-auto pb-0 mb-0 d-none d-sm-inline">
        <p class="text-center primary-text" v-if="step<3">
          Te ayudamos a liquidar tus deudas <br> hasta con un <span class="primary-text-bold">70% de descuento</span>.
        </p>
        <p class="text-center primary-text" v-else>
          No pierdas tu descuento y la oportunidad de tener una vida sin deudas.
        </p>
      </div>

      <div class="col-12 col-md-8 col-lg-6 mx-auto pt-0 mt-0">

        <v-stepper v-model="step" elevation="0" style="border-radius: 1rem;">

          <div class="d-none d-sm-inline">
            <p class="text-center my-3 secondary-text" v-if="step<3">
              Déjanos tus datos y calcula tu plan <br> personalizado en <span class="secondary-text-bold">3 pasos</span>
            </p>

            <p class="text-center my-3 secondary-text" v-else style="font-family: OpenSans-Bold">
              Programa DB Menos
            </p>
          </div>

          <v-stepper-header class="shadow-none px-5">

            <v-stepper-step
                :complete="this.validF1"
                :color="(this.auxColor && !this.validF1) ? '#2A3D99' : (this.validF1) ? '#8ac43f': '#ff0033'"
                step="1"
            ></v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="this.validF2"
                :color="(this.auxColor && !this.validF2) ? '#2A3D99' : (this.validF2) ? '#8ac43f': '#ff0033'"
                step="2"
            ></v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
                :complete="this.validF3"
                :color="(this.auxColor && !this.validF3) ? '#2A3D99' : (this.validF3) ? '#8ac43f': '#ff0033'"
                step="3"
            ></v-stepper-step>

          </v-stepper-header>

          <v-stepper-items>

            <div class="d-sm-none">
              <p class="text-center mt-1 mb-5 secondary-text" v-if="step === 3" style="font-family: OpenSans-Bold">
                Programa DB Menos
              </p>
            </div>

            <v-stepper-content step="1" class="p-0">

              <v-card>

                <v-card-text>

                  <p class="text-center simple-text-bold mb-5">O carga tu INE y hazlo más rápido </p>

                  <div class="row">
                    <div class="col-12 text-center">
                      <v-btn
                          dark
                          elevation="2"
                          color="#2a3d98"
                          class="btn-action"
                          @click="ine='a'"
                      >
                        <img src="/img/new_process/icons/camera.svg" alt="camera" class="mx-3 icon-camera">
                        Frente
                      </v-btn>
                    </div>
                  </div>

                  <div class="row mx-auto my-5">
                    <div v-if="ine === 'a'" class="col-12">
                      <dropbox
                          text
                          :ocrFile="true"
                          type="ID"
                          :files="this.client.files"
                          v-on:uploadFile="uploadFile"
                          v-on:resentUploadFile="uploadFile"
                      ></dropbox>
                    </div>
                  </div>

                  <v-form v-model="validF1" ref="form1">

                    <div class="row">
                      <div class="col-12 py-0">
                        <v-text-field
                            v-model="client.name"
                            label="Nombre"
                            placeholder="Nombre"
                            :rules="rules.name"
                            solo
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.last_name_1"
                            label="Apellido Paterno"
                            placeholder="Apellido Paterno"
                            :rules="rules.last_name"
                            solo
                        ></v-text-field>
                      </div>
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.last_name_2"
                            label="Apellido Materno"
                            placeholder="Apellido Materno"
                            :rules="rules.last_name"
                            solo
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.date_of_birth"
                            label="Fecha de Nacimiento"
                            placeholder="Día/Mes/Año"
                            :rules="rules.date_of_birth"
                            solo
                        ></v-text-field>
                      </div>
                      <div class="col-12 col-sm-6 py-0">
                        <v-select
                            label="Género"
                            v-model="client.gender"
                            :rules="rules.gender"
                            :items="genders"
                            item-value="value"
                            item-text="name"
                            solo
                        ></v-select>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.phone"
                            label="Teléfono"
                            placeholder="Teléfono"
                            :rules="rules.phone"
                            type="number"
                            pattern="\d*"
                            solo
                        ></v-text-field>
                      </div>
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.email"
                            label="Correo"
                            placeholder="Correo"
                            :rules="rules.email"
                            solo
                        ></v-text-field>
                      </div>


                    </div>

                  </v-form>

                  <div class="my-3">
                    <video-modal/>
                  </div>

                  <div class="row">
                    <div class="col-12 text-center">
                      <v-btn
                          dark
                          elevation="2"
                          color="#8ac43f"
                          v-on:click="next"
                          class="btn-action"
                      >Siguiente
                      </v-btn>
                    </div>
                  </div>

                </v-card-text>

              </v-card>

            </v-stepper-content>

            <v-stepper-content step="2" class="p-0">

              <v-card>

                <v-card-text>

                  <v-form v-model="validF2" ref="form2">

                    <div class="row">
                      <div class="col-12 py-0">
                        <v-text-field
                            v-model="client.address.street"
                            label="Calle"
                            placeholder="Calle"
                            :rules="rules.street"
                            solo
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.address.number"
                            label="Número"
                            placeholder="Número o S/N"
                            :rules="rules.number"
                            solo
                        ></v-text-field>
                      </div>
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.address.cp"
                            label="Código Postal"
                            placeholder="Código Postal"
                            :rules="rules.cp"
                            type="number"
                            pattern="\d*"
                            solo
                        ></v-text-field>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 col-sm-6 py-0">
                        <v-text-field
                            v-model="client.address.city"
                            label="Municipio"
                            placeholder="Municipio"
                            :rules="rules.city"
                            solo
                        ></v-text-field>
                      </div>

                      <div class="col-12 col-sm-6 py-0" v-if="sublocalities.length!==0 && !ocr">
                        <v-autocomplete
                            v-model="client.address.suburb"
                            :items="sublocalities"
                            solo
                            label="Colonia"
                            placeholder="Colonia"
                            :rules="rules.suburb">
                        </v-autocomplete>
                      </div>

                      <div class="col-12 col-sm-6 py-0" v-else>
                        <v-text-field
                            v-model="client.address.suburb"
                            label="Colonia"
                            :rules="rules.suburb"
                            solo
                        ></v-text-field>
                      </div>

                    </div>

                  </v-form>

                  <div class="row">
                    <div class="col-12 text-center">
                      <v-btn
                          dark
                          class="btn-action"
                          elevation="2"
                          color="#8ac43f"
                          v-on:click="showProposal"
                      >Ver propuesta
                      </v-btn>
                    </div>
                  </div>

                </v-card-text>

              </v-card>

            </v-stepper-content>

            <v-stepper-content step="3" class="p-0">

              <v-card>

                <v-card-text>

                  <div class="row border-bottom mb-1">
                    <div class="col-12 col-sm-6 p-0">
                      <p class="list">En total debes:</p>
                    </div>
                    <div class="col-12 col-sm-6 p-0">
                      <p class="text-sm-right list-bold" style="color: #000000">{{ totalDebt }}</p>
                    </div>
                  </div>

                  <div class="row border-bottom mb-1">
                    <div class="col-12 col-sm-6 p-0">
                      <p class="list">Con DB Menos paga:</p>
                    </div>
                    <div class="col-12 col-sm-6 p-0">
                      <p class="text-sm-right list-bold" style="color: #2A3D99">{{ willPay }}</p>
                    </div>
                  </div>

                  <div class="row border-bottom mb-1">
                    <div class="col-12 col-sm-6 p-0">
                      <p class="list">Mensualidad de:</p>
                    </div>
                    <div class="col-12 col-sm-6 p-0">
                      <p class="text-sm-right list-bold" style="color: #000000">{{ monthlyFee }}</p>
                    </div>
                  </div>

                  <div class="row border-bottom mb-1">
                    <div class="col-12 col-sm-6 p-0">
                      <p class="list">Duración del programa:</p>
                    </div>
                    <div class="col-12 col-sm-6 p-0">
                      <p class="text-sm-right list-bold" style="color:#000000;"> {{ period }} meses</p>
                    </div>
                  </div>

                  <div class="row border-bottom mb-1">
                    <div class="col-12 col-sm-6 p-0">
                      <p class="list">Descuento obtenido:</p>
                    </div>
                    <div class="col-12 col-sm-6 p-0">
                      <p class="text-sm-right list-bold" style="color: #8ac43f">{{ percent }}%</p>
                    </div>
                  </div>

                  <p class="text-center list-bold" style="color:#2A3D99">Detalle de las cuentas</p>
                  <carousel :debts="debts"/>

                  <div class="row">
                    <div class="col-12 mt-2 text-center">
                      <v-btn
                          dark
                          elevation="2"
                          color="#8ac43f"
                          class="btn-action"
                          @click="iWantIt"
                      >Lo quiero
                      </v-btn>
                    </div>
                  </div>

                </v-card-text>

              </v-card>

            </v-stepper-content>

          </v-stepper-items>

        </v-stepper>

      </div>

    </div>

    <!-- Modal -->
    <div class="text-center">
      <v-dialog
          persistent
          width="500px"
          v-model="dialog"
      >

        <v-card>
          <v-card-text>

            <div class="text-end">
              <v-icon
                  class="mt-3"
                  color="#2A3D99"
                  @click="dialog=false"
              >
                mdi-close
              </v-icon>
            </div>

            <div class="row">
              <div class="col-12 my-5 text-center">
                <p class="title-modal">Autorización de buró de crédito</p>
                <p class="text-modal text-left">Para comprobar que no eres un robot, te enviaremos un código a tu
                  teléfono.</p>

                <a href="/files/aviso_de_privacidad_grupo_dbmenos_2018.pdf" target="_blank">
                  <img src="/img/new_process/icons/eye.svg" alt="eye" class="d-inline icon-eye">
                  <p class="d-inline link" style="color: #2A3D99">
                    <ins>Aviso de privacidad</ins>
                  </p>
                </a>
              </div>
            </div>

            <v-form v-model="validF3" ref="form3">
              <div class="row">
                <div class="col-12">
                  <v-text-field
                      v-model="client.code"
                      label="Código"
                      type="number"
                      :rules="rules.code"
                      pattern="\d*"
                      solo
                  ></v-text-field>
                </div>
              </div>
            </v-form>

            <div class="row my-1">
              <div class="col-12">
                <v-btn
                    dark
                    block
                    elevation="2"
                    color="#8ac43f"
                    style="text-transform: none"
                    v-on:click="validatePin"
                >Enviar
                </v-btn>
              </div>
            </div>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!-- Alert -->
    <div class="text-center">
      <v-dialog
          v-model="alert"
          width="500"
          persistent
      >
        <v-card>
          <v-card-text>
            <div class="pt-5">
              <p class="font-weight-bold text-alert"
                 style="color: #ff5252">
                Oh Oh...
              </p>
              <p class="font-weight-bold text-alert">
                {{ message }}
              </p>
            </div>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="error"
                  text
                  v-on:click="redirect"
              >
                Inicia sesión
              </v-btn>
            </v-card-actions>

          </v-card-text>
        </v-card>
      </v-dialog>
    </div>

    <!--Snak bar-->
    <div class="text-center ">
      <v-snackbar
          v-model="snackbar"
      >
        {{ message }}

        <template v-slot:action="{ attrs }">
          <v-btn
              color="#ff0033"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Cerrar
          </v-btn>
        </template>
      </v-snackbar>
    </div>

  </div>

</template>

<script>

import VideoModal from "@/views/components/simulator/VideoModal";
import Carousel from "@/views/components/simulator/Carousel";
import {V} from "@/V";
import Dropbox from "@/components/Dropbox";
import IneImage from "@/views/components/simulator/IneImage";
import {mapActions} from 'vuex'
import Alert from "@/views/components/simulator/Alert";

export default {
  name: "Principal",
  components: {Alert, IneImage, Dropbox, Carousel, VideoModal},
  data: () => ({
    step: 1,

    validF1: false,
    validF2: false,
    validF3: false,
    auxColor: true,
    alert: false,
    message: '',
    snackbar: false,

    modal: false,
    dialog: false,
    ine: '',
    ocr: false,

    genders:
        [
          {name: 'Masculino', value: 'H'},
          {name: 'Femenino', value: 'M'},
        ],
    sublocalities: [],


    client:
        {
          id: '',
          name: '',
          last_name_1: '',
          last_name_2: '',
          curp: '',
          rfc: '',
          date_of_birth: '',
          gender: '',
          phone: '',
          email: '',
          code: '',
          address: {
            state: '',
            city: '',
            suburb: '',
            cp: '',
            street: '',
            number: ''
          },
          files: [],
        },

    moment: null,
    auxMoment: false,
    auxCont: 1,

    rules:
        {
          name:
              [v => !!v || 'El nombre es requerido'],
          last_name:
              [v => !!v || 'El apellido es requerido'],
          date_of_birth:
              [
                v => !!v || 'La fecha es requerida',
                v => /^[0-9]{2}\/[0-9]{2}\/[0-9]{4}$/.test(v) || 'Formato invalido. Ej: (01/01/2000)',
              ],
          gender:
              [v => !!v || 'El género es requerido'],
          email:
              [
                v => !!v || 'El correo es requerido',
                v => /.+@.+/.test(v) || 'Formato invalido.'
              ],
          phone:
              [
                v => !!v || 'El teléfono es requerido',
                v => /^([0-9]{10,13})$/.test(v) || 'Min: 10 - Max 13, dígitos'
              ],
          code:
              [v => !!v || 'El código es requerido'],
          street:
              [v => !!v || 'La calle es requerida'],
          number:
              [
                v => !!v || 'El número es requerido',
                v => (/^([0-9]{1,})$/.test(v) || /^(S\/N)$/i.test(v)) || 'Formato invalido. Ej: (6 o s/n)',
              ],
          cp:
              [v => !!v || 'El código postal es requerido'],
          city:
              [v => !!v || 'El municipio es requerido'],
          suburb:
              [v => !!v || 'La colonia es requerida'],
        },

    totalDebt: 0,
    willPay: 0,
    monthlyFee: 0,
    period: '',
    percent: 0,
    debts: []
  }),
  methods:
      {
        ...mapActions(['changeStatusVideo']),
        async next() {

          if (this.$refs.form1.validate()) {

            await this.getCurpAndRfc()

            if (this.client.curp !== '' && this.client.rfc !== '') {
              await this.insertLead()
            }

          } else {
            this.auxColor = false
          }

        },
        async showProposal() {
          (this.$refs.form2.validate()) ? await this.insertUser() : this.auxColor = false;
        },
        async uploadFile(obj) {

          let file = obj.file;

          this.$showLoader(true);

          if (!this.auxMoment) {
            this.moment = this.$moment().format('DDMMHHmmss')
            this.auxMoment = true;
          }

          let body = {
            key: this.moment,
            filename: obj.file.name,
            type: obj.type
          };

          await this.$axios.post(V.SERVER + V.PREREGISTER.UPLOAD_FILE_TMP, body).then((response) => {

            let data = response.data;

            if (response.data.success) {

              let fileURL = data[V.API.ROW].url;

              this.$axios.put(fileURL, file).then((putObjectResponse) => {

                if (putObjectResponse.status === 200) {

                  this.snackbar = true
                  this.message = 'INE Cargada Correctamente'

                  this.$axios.post(V.SERVER + V.PREREGISTER.GET_OCR_DATA, body).then(async (response) => {

                    this.$showLoader(false);

                    console.log("OCR:", response.data)

                    if (response.data.success) {

                      this.auxCont += 1;

                      if (this.auxCont === 2) {
                        this.auxMoment = false;
                      }

                      if (obj.type === "ID") {

                        this.ine = ''
                        this.ocr = true

                        let data = response.data.row;
                        this.client.name = data.name
                        this.client.last_name_1 = data.lastname_1
                        this.client.last_name_2 = data.lastname_2
                        this.client.date_of_birth = data.birthday.split("-").reverse().join("/")
                        this.client.gender = (data.gender === 'M') ? 'H' : 'M'
                        this.client.curp = data.curp
                        this.client.rfc = data.rfc

                        this.client.address.street = data.addr_street
                        this.client.address.number = data.addr_extnum
                        this.client.address.cp = data.addr_postal_code
                        this.client.address.suburb = data.addr_sublocality
                        this.client.address.state = data.state_of_birth

                      } else {
                        console.log("Error:", response.data)
                      }
                    }

                  }).catch((e) => {
                    console.log(e);
                    this.$showLoader(false);
                    //alert("No se logró subir, intente nuevamente");
                  });
                } else {
                  this.$notify({
                    text: "No se logró subir la INE",
                    type: "warn",
                  });
                }
              });
            } else {
              this.$notify({
                text: "Error, intenta nuevamente",
                type: "warn",
              });
            }
          }).catch((e) => {
            console.log(e);
            this.$showLoader(false);
            //alert("No se logró subir, intente nuevamente");
          });
        },
        async getCurpAndRfc() {

          this.$showLoader(true);

          let CURP_API_URL = "https://conectame.ddns.net/rest/api.php"
          let METHOD_CURP_BY_NAME = "curpxNombre"
          let CRED = "user=Jerry&pass=bF6g$W!pE5_08Kx]"

          const removeAccents = (str) => {
            return str.normalize("NFD").replace(/[\u0300-\u036f]/g, ""); // Y eliminar espacios (\u0020)
          }

          try {

            let name = removeAccents(this.client.name.toUpperCase())
            let params = {
              nombre: name.split(' ').join('%2B'),
              paterno: this.client.last_name_1.toUpperCase(),
              materno: this.client.last_name_2.toUpperCase(),
              fecha: this.client.date_of_birth.split('/').join('%252F'),
              sexo: (this.client.gender === 'H') ? 'h' : 'm'
            }

            let query = Object.entries(params).map((key) => key.join('%3D')).join('%26')

            query.split('/').join('%252F')

            let url = `${CURP_API_URL}?m=${METHOD_CURP_BY_NAME}&${CRED}&val=${query}`

            await this.$axios.get(url).then((response) => {

              console.log("CURP-RFC:", response.data)

              this.client.curp = response.data.Curp
              this.client.rfc = response.data.DatosFiscales.Rfc
              this.client.address.state = this.client.curp.substring(11, 13)

            }).catch((e) => {
              this.snackbar = true
              this.message = "Error al calcular el CURP | RFC"
            })

          } catch (e) {
            console.log("Error:", e.response.data.Response + '\n' + e.response.data.Msg)
          }

          this.$showLoader(false);
        },
        getMarketing() {

          let marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_${this.$route.query.utm_campaign}`;

          if (marketing === "undefined_undefined_undefined") {
            marketing = null;
          } else if (marketing === `${this.$route.query.utm_source}_undefined_undefined`) {
            marketing = this.$route.query.utm_source;
          } else if (marketing === `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_undefined`) {
            marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}`
          } else if (marketing === `${this.$route.query.utm_source}_undefined_${this.$route.query.utm_campaign}`) {
            marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_campaign}`;
          }

          return marketing;
        },
        async insertLead() {

          this.$showLoader(true);

          let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS;
          let headers = {"device": 'web'};
          let _data = {
            name: this.client.name,
            email: this.client.email,
            phone: this.client.phone,
            amount: 0,
            banks: JSON.stringify([]),
            same_bank_debt_paysheet: false,
            device: 'web-s'
          };

          await this.$axios.post(url, _data, {headers: headers}).then((response) => {

            if (response.data.success) {
              this.step = 2
            } else {
              this.snackbar = true
              this.message = 'Error al agregar en la base de datos'
            }
          });
          this.$showLoader(false);
        },
        async insertUser() {

          this.$showLoader(true);

          let url = V.SERVER + V.PREREGISTER.CREATE_NEW_USER
          let headers = {"phone": this.client.phone};
          let _data = {
            name: this.client.name,
            lastname_1: this.client.last_name_1,
            lastname_2: this.client.last_name_2,
            birthday: this.client.date_of_birth.split("/").reverse().join("-"),
            gender: (this.client.gender === 'H') ? 'M' : 'F',
            curp: this.client.curp,
            rfc: this.client.rfc,
            phone: this.client.phone,
            email: this.client.email,

            addr_postal_code: this.client.address.cp,
            addr_street: this.client.address.street,
            addr_extnum: this.client.address.number,
            addr_sublocality: this.client.address.suburb,
            state_of_birth: this.client.address.state
          };

          await this.$axios.post(url, _data, {headers: headers}).then((response) => {

            if (response.data.success) {

              this.client.id = response.data.row.id
              this.getPin()

            } else {

              if (response.data.reason === 5) {
                this.alert = true
                this.message = 'ya existe una cuenta asociada a este usuario.'
              } else {
                this.snackbar = true
                this.message = 'Error al agregar en la base de datos'
              }
            }

          });
          this.$showLoader(false);
        },
        async getPin() {

          this.$showLoader(true);

          let url = V.SERVER + V.PREREGISTER.REQUEST_CREDIT_REPORT_PIN

          await this.$axios.post(url, {user: this.client.id}).then((response) => {

            console.log("PIN", response.data)

            if (response.data.success) {
              this.dialog = true;
            } else {
              this.snackbar = true
              this.message = 'Error al enviar el PIN'
            }

          });
          this.$showLoader(false);
        },
        async validatePin() {

          this.$showLoader(true);

          let url = V.SERVER + V.PREREGISTER.VALIDATE_CREDIT_REPORT_PIN

          let _data = {
            user: this.client.id,
            pin: parseInt(this.client.code)
          }

          await this.$axios.post(url, _data).then((response) => {

            console.log("VALIDATE", response.data)

            if (response.data[this.$v.API.SUCCESS]) {
              this.dialog = false;
              this.getCredit()
            } else {
              this.snackbar = true
              this.message = 'Código incorrecto'
            }
          })
          this.$showLoader(false);
        },
        async getCredit() {

          this.$showLoader(true);

          let url = V.SERVER + V.PREREGISTER.REQUEST_CREDIT_REPORT

          await this.$axios.post(url, {user: this.client.id}).then((response) => {

            console.log("GET CREDIT", response.data)

            if (response.data[this.$v.API.SUCCESS]) {

              let accounts = response.data[V.API.ROW].row.accounts.dbmAccounts

              let debts = [];
              console.log("DEBTS", debts)

              for (const item of accounts) {
                if (item.amount !== 0) {
                  debts.push(item)
                }
              }

              (debts.length === 0) ? this.$router.push({path: "/error"}) : this.calculator(debts)

            }

          });
          this.$showLoader(false);
        },
        async calculator(debts) {

          this.$showLoader(true);

          let url = V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR

          await this.$axios.post(url, debts).then((response) => {

            console.log("CALCULATOR:", response.data)

            if (response.data[this.$v.API.SUCCESS]) {

              for (const item of response.data.row.programs[0].debts) {

                let newDebt =
                    {
                      id: item.index,
                      debt: this.numberFormat(item.amount),
                      pay: this.numberFormat(item.willPay),
                      percent: 0,
                      bank: item.bankid
                    }

                let percent = (item.discount * 100) / item.amount
                newDebt.percent = percent

                this.debts.push(newDebt)

              }

              this.totalDebt = this.numberFormat(response.data.row.programs[0].totalDebt)
              this.willPay = this.numberFormat(response.data.row.programs[0].willPay)
              this.monthlyFee = this.numberFormat(response.data.row.programs[0].monthlyFee)
              this.period = response.data.row.programs[0].period
              this.percent = 70

            }
          });
          this.$showLoader(false);

          this.step = 3
          this.changeStatusVideo({play: true, version: 2})

        },
        iWantIt() {
          window.open("https://cuenta.finamigodigital.com.mx/")
        },
        getPostalCode(cp) {

          if (!this.ocr) {
            this.client.address.city = null
            this.client.address.suburb = null
            this.sublocalities = []
          }

          this.$axios.get(this.$v.SERVER + this.$v.OPS.GET_POSTAL_CODE + "?pc=" + cp).then(response => {

            let data = response.data;

            if (data[this.$v.API.SUCCESS]) {

              this.client.address.city = data[this.$v.API.ROWS][0].locality

              this.sublocalities = data[this.$v.API.ROWS][0].sublocality.split(";")

            }
          });
        },
        redirect() {
          this.$router.push({path: "/register"})
        },
        numberFormat(number) {

          let cant = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD',
            roundingIncrement: 5,
            minimumFractionDigits: 2
          }).format(number);

          return cant;

        },
      },
  watch:
      {
        step: function dialog(value) {
          this.$emit('showSection', value);
        },

        'client.date_of_birth': function dialog(value) {
          let date = this.client.date_of_birth

          if (date.length === 2) {
            this.client.date_of_birth = this.client.date_of_birth + "/"
          }
          if (date.length === 5) {
            this.client.date_of_birth = this.client.date_of_birth + "/"
          }
        },

        'client.address.cp': function dialog(value) {
          if (this.client.address.cp.toString().length === 5) {
            this.getPostalCode(value)
          }
        },
      }
}
</script>

<style scoped>

.bg-principal {
  background: linear-gradient(to bottom, #2A3D99, #29AAE1) !important;
}

.primary-text {
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.primary-text-bold {
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.secondary-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.secondary-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.simple-text {
  font-family: OpenSans-Regular;
  color: #2a3d98;
}

.simple-text-bold {
  font-family: OpenSans-Bold;
  color: #2a3d98;
}

.list {
  font-family: OpenSans-Regular;
}

.list-bold {
  font-family: OpenSans-Bold;
}

.title-modal {
  font-family: OpenSans-Bold;
  color: #2A3D99;
}

.text-modal {
  font-family: OpenSans-Regular;
  color: gray;
}

ul {
  list-style: none;
}

.btn-action {
  text-transform: none
}

.text-alert {
  color: #ff5252;
}

.icon-camera, .icon-eye, .icon-check {
  width: 1rem;
}

@media (max-width: 319px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 320px) and (max-width: 374px) {
  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: .9rem;
    color: #2a3d98;
    line-height: 1.2rem;
  }

  .simple-text-bold {
    font-size: .8rem;
  }

  .title-modal {
    font-size: .7rem;
  }

  .text-modal {
    font-size: .7rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .7rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
    line-height: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: .9rem;
  }

  .text-modal {
    font-size: .8rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .8rem;
  }

  .icon-camera, .icon-eye, .icon-check {
    width: .8rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2rem;
    height: 2rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .bg-principal {
    background: #FFFFFF !important;
    /*margin-top: 5rem*/
  }

  .primary-text, .primary-text-bold {
    font-size: 1.2rem;
    color: #2a3d98;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.1rem;
    color: #2a3d98;
  }

  .secondary-text br {
    display: none;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: .9rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: .9rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 95%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.5rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.4rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: .8rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {

  /*.bg-principal {
    margin-top: 5rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.6rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.3rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1rem;
  }

  .text-modal {
    font-size: 1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  /*.bg-principal {
    margin-top: 6rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 1.7rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 1.5rem;
  }

  .simple-text-bold {
    font-size: .9rem;
  }

  .title-modal {
    font-size: 1.1rem;
  }

  .text-modal {
    font-size: 1.1rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1rem;
  }

  .icon-camera, .icon-eye {
    width: 1.2rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 2.5rem;
    height: 2.5rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

@media (min-width: 2561px) {

  /*.bg-principal {
    margin-top: 7rem;
  }*/
  .primary-text, .primary-text-bold {
    font-size: 2.3rem;
  }

  .secondary-text, .secondary-text-bold {
    font-size: 2rem;
  }

  .simple-text-bold {
    font-size: 1.5rem;
  }

  .title-modal {
    font-size: 1.4rem;
  }

  .text-modal {
    font-size: 1.3rem;
    /*text-align: justify;*/
  }

  .link {
    font-size: 1.3rem;
  }

  .icon-camera, .icon-eye {
    width: 1.4rem;
  }

  .btn-action {
    width: 80%;
  }

  ::v-deep .v-stepper__step__step {
    width: 3rem;
    height: 3rem;
  }
}

</style>