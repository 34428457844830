<template>

  <div class="bg-principal">
    <div class="row col-12 col-sm-10 mx-auto p-0 p-sm-3 py-5">

      <div class="col-12">
        <p class="text-center section-title open-sans-light text-blue">
          Los medios que <span class="open-sans-bold">hablan de nosotros</span></p>
      </div>

      <div class="col-12 col-sm-10 col-lg-6 mx-auto">
        <a :href="forbes" target="_blank">
          <div class="row p-3 p-sm-0">
            <div class="col-12 col-lg-4 text-center text-lg-right">
              <img src="/img/financial_freedom/icons/forbes.png" alt="forbes" width="100%">
            </div>
            <div class="col-12 col-lg-8 p-0">
              <p class="p-0 secondary-text open-sans-regular text-gray">
                Un salvavidas digital para tus
                deudas en las tarjetas de crédito
              </p>
            </div>
          </div>
        </a>

      </div>
      <div class="col-12 col-sm-10 col-lg-6 mx-auto" v-on:click="redirect('bbva')">
        <a :href="bbva" target="_blank">
          <div class="row p-3 p-sm-0">
            <div class="col-12 col-lg-4 text-center text-lg-right">
              <img src="/img/financial_freedom/icons/bbva.png" alt="bbva" width="100%">
            </div>
            <div class="col-12 col-lg-8 p-0">
              <p class="p-0 secondary-text open-sans-regular text-gray">
                DB Menos crea un plan personalizado
                que se ajusta a la capacidad de pago del
                deudor para liquidar entre 12 y 16 meses.
              </p>
            </div>
          </div>
        </a>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: "References",
  data: () => ({
    forbes:'https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/',
    bbva:'https://www.bbvaedufin.com/practica/db-menos/'
  }),
}
</script>

<style scoped>

/*Fonts*/
.open-sans-light {
  font-family: OpenSans-Light;
}

.open-sans-bold {
  font-family: OpenSans-Bold;
}

.open-sans-regular {
  font-family: OpenSans-Regular;
}

/*Colors*/

.text-blue {
  color: #2a3d99 !important;
}

.text-gray {
  color: #989898;
}

@media (max-width: 575px) {
  .section-title {
    font-size: 1.2rem;
  }

  img {
    width: 50%;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-title {
    font-size: 1.2rem;
  }

  img {
    width: 50%;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .section-title {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1.2rem;
  }

  img {
    width: 40%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 1.4rem;
  }

  img {
    width: 100%;
  }
}

@media (min-width: 1200px) and (max-width: 2459px) {
  .section-title {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1.1rem;
  }
}

@media (min-width: 2560px) {
  .section-title {
    font-size: 2.3rem;
  }

  .secondary-text {
    font-size: 2rem;
  }
}

</style>