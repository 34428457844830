var render = function render(){var _vm=this,_c=_vm._self._c;return _c('footer',{staticClass:"footer",staticStyle:{"background-size":"cover","background-repeat":"no-repeat","background-image":"url('img/brand/Header_1440_100.png')"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row align-items-center justify-content-md-between"},[_c('div',{staticClass:"col-md-12 col-sm-12",staticStyle:{"margin-top":"5%"}},[_c('ul',{staticClass:"nav nav-footer justify-content-center"},[_c('li',{staticClass:"nav-item",staticStyle:{"color":"white","font-size":"100%"}},[_vm._v(" © "+_vm._s(_vm.year)+" DBMenos ")]),_c('p',{staticStyle:{"margin-top":"-1%","color":"white"}},[_vm._v("|")]),_c('a',{staticStyle:{"color":"white","font-size":"100%"},attrs:{"href":"https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos","target":"_blank"}},[_vm._v("Contáctanos")]),_c('p',{staticStyle:{"margin-top":"-1%","color":"white"}},[_vm._v("|")]),_c('router-link',{staticStyle:{"color":"white","font-size":"100%"},attrs:{"to":"/legal"}},[_vm._v("Legal")]),_c('p',{staticStyle:{"margin-top":"-1%","color":"white"}},[_vm._v("|")]),_c('router-link',{staticStyle:{"color":"white","font-size":"100%"},attrs:{"to":"/questions"}},[_vm._v("faq")])],1),_c('ul',{staticClass:"nav nav-footer justify-content-center",staticStyle:{"padding":"5%"}},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('router-link',{staticStyle:{"font-family":"Roboto-Black","color":"white","padding":"0.5%"},attrs:{"to":"/blogs"}},[_vm._v("BLOG")])],1),_vm._m(5)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link nav-link-icon",attrs:{"href":"https://www.facebook.com/grupodbmenos/","target":"_blank","data-toggle":"tooltip","title":"Siguenos en Facebook"}},[_c('i',{staticClass:"fa fa-facebook fa-lg text-white",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link nav-link-icon",attrs:{"href":"https://www.instagram.com/db_menos/","target":"_blank","data-toggle":"tooltip","title":"Siguenos en Instagram"}},[_c('i',{staticClass:"fa fa-instagram fa-lg text-white",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link nav-link-icon",attrs:{"href":"https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw","target":"_blank","data-toggle":"tooltip","title":"Siguenos en Youtube"}},[_c('i',{staticClass:"fa fa-youtube-play fa-lg text-white",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link nav-link-icon",attrs:{"href":"https://www.linkedin.com/company/grupo-db-menos/","target":"_blank","data-toggle":"tooltip","title":"Siguenos en Linkedin"}},[_c('i',{staticClass:"fa fa-linkedin fa-lg text-white",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{staticClass:"nav-link nav-link-icon",attrs:{"href":"https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos","target":"_blank","data-toggle":"tooltip","title":"Mandar mensaje por whatsapp"}},[_c('i',{staticClass:"fa fa-whatsapp fa-lg text-white",attrs:{"aria-hidden":"true"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"nav nav-footer justify-content-center"},[_c('p',{staticStyle:{"color":"white","padding":"0.5%"}},[_vm._v("Av. Homero 407, Polanco, Polanco V Secc, Miguel Hidalgo, CP 11550 Ciudad de México, CDMX, México")])])
}]

export { render, staticRenderFns }