<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-8 mx-auto">

      <div class="col-12 mt-5 mt-sm-0">
        <p class="text-center primary-text">
          Hasta ahora hemos ayudado a más de <br>
          1,000 personas a liquidar sus deudas
        </p>
      </div>

      <div class="col-12">
        <p class="text-center secondary-text">
          <span class="secondary-text-bold">+1,000</span> <br>personas han pagado con nosotros</p>
        <hr>
        <p class="text-center secondary-text">
          <span class="secondary-text-bold">$70 millones</span> <br>de pesos (MXN) en deudas liquidadas</p>
        <hr>
        <p class="text-center secondary-text">
          <span class="secondary-text-bold">+80%</span><br>de descuentos totales</p>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Results"
}
</script>

<style scoped>

.bg-principal
{
  background-image: url("/img/how_does_it_work/desktop/family.png");
  background-size: cover;
}

.primary-text
{
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.secondary-text
{
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

.secondary-text-bold
{
  font-family: Gotham-Ultra;
  color: #FFFFFF;
}

hr {
  background-color: #FFF;
  height: 1px;
  border: 0;
}

@media (min-width: 320px) and (max-width: 374px)
{

  .bg-principal
  {
    background-image: url("/img/how_does_it_work/mobile/family.png");
  }

  .primary-text
  {
    font-size: 1rem;
    text-align: left !important;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .secondary-text-bold
  {
   font-size: 1.5rem;
  }
}
@media (min-width: 375px) and (max-width: 424px)
{
  .bg-principal
  {
    background-image: url("/img/how_does_it_work/mobile/family.png");
  }

  .primary-text
  {
    font-size: 1rem;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .secondary-text-bold
  {
    font-size: 1.5rem;
  }
}
@media (min-width: 425px) and (max-width: 767px)
{
  .primary-text
  {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .secondary-text-bold
  {
    font-size: 1.5rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px)
{
  .primary-text
  {
    font-size: 1.2rem;
    text-align: center;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .secondary-text-bold
  {
    font-size: 1.5rem;
  }
}
@media (min-width: 1024px) and (max-width: 1439px)
{
  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.2rem;
  }

  .secondary-text-bold
  {
    font-size: 2.2rem;
  }
}
@media (min-width: 1440px) and (max-width: 2559px)
{
  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.2rem;
  }

  .secondary-text-bold
  {
    font-size: 2.2rem;
  }
}
@media (min-width: 2560px) and (max-width: 2560px)
{
  .primary-text
  {
    font-size: 2rem;
  }

  .secondary-text
  {
    font-size: 1.8rem;
  }

  .secondary-text-bold
  {
    font-size: 2rem;
  }
}

</style>