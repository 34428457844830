<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto">

      <div class="col-12">
        <p class="text-center primary-text">
          ¿Qué nos hace diferentes de otras <br>
          reparadoras de crédito en México?
        </p>
      </div>

    </div>

    <div class="row col-12 col-sm-10 mx-auto">

      <div class="col-12 col-md-6">

        <ol>
          <li class="pb-3 secondary-text my-3 my-sm-5">
            Utilizamos tecnología para ofrecerte la mejor solución personalizada.
          </li>
          <li class="pb-3 secondary-text my-3 my-sm-5">
            Te acompañamos en todo el proceso hasta reincorporarte al sistema crediticio.
          </li>
          <li class="pb-3 secondary-text my-3 my-sm-5">
            Te ayudamos a reincorporarte al sistema financiero.
          </li>
        </ol>

      </div>

      <div class="col-12 col-md-6">

        <iframe  class="i-frame" width="560" height="315" src="https://www.youtube.com/embed/8P5Dbs69jjY"
                 title="YouTube video player"
                 allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                 allowfullscreen></iframe>


      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Differences",
  data: () =>
      ({
        images:
            {
              key: '/img/how_does_it_work/desktop/key.png',
              pig: '/img/how_does_it_work/desktop/pig.png'
            },
      })
}
</script>

<style scoped>

.bg-principal {
  background-color: #FFFFFF;
}

.primary-text
{
  font-family: OpenSans-Bold;
  color: #2a3d99;
}

.secondary-text
{
  font-family: OpenSans-Regular;
  color: #2a3d99;
}

ol
{
  list-style: none;
  font-weight: normal;
}

ol {
  counter-reset: item;
}

ol li:before {
  content: counter(item) ". ";
  counter-increment: item;
  font-weight: bold;
}

.i-frame
{
  width: 100%;
}

@media (min-width: 320px) and (max-width: 374px)
{
  .primary-text
  {
    font-size: 1rem;
    text-align: left !important;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .primary-text br
  {
    display: none;
  }

}
@media (min-width: 375px) and (max-width: 424px)
{
  .primary-text
  {
    font-size: 1rem;
  }

  .secondary-text
  {
    font-size: 1rem;
  }
}
@media (min-width: 425px) and (max-width: 767px)
{
  .primary-text
  {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text
  {
    font-size: 1rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px)
{
  .primary-text
  {
    font-size: 1.2rem;
    text-align: center;
  }

  .secondary-text
  {
    font-size: 1rem;
  }
}
@media (min-width: 1024px) and (max-width: 1439px)
{
  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.2rem;
  }

}
@media (min-width: 1440px) and (max-width: 2559px)
{
  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.2rem;
  }
}
@media (min-width: 2560px) and (max-width: 2560px)
{
  .primary-text
  {
    font-size: 2rem;
  }

  .secondary-text
  {
    font-size: 1.8rem;
  }
}

</style>