<template>
<div style="background-color: #ebebeb">
    <section>
      <div class="d-block d-sm-none" style="background-color: #ebebeb; align-content: center">
        <section
          style="background-color: #ebebeb; align-content: center"
        >
          <div class="container" id="main-principal-xs-mati2">
            <div class="row">
              <div class="col">
                <img
                  src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Mujer.png"
                  id="img-background-xs-mati2"
                />
                <div class="d-flex justify-content-end">
                  <div id="principal-tittle-xs-mati2">
                    <span id="span-principal-xs-mati2">
                      "Liquidé mi deuda con
                      <b id="b-principal-xs-mati2">70% de descuento </b>a
                      <b id="b-principal-xs-mati2"> 15 pagos mensuales </b>de
                      <b id="b-principal-xs-mati2">$1,834. </b>
                      ¡Se adaptaron a mi presupuesto!"
                    </span>
                    <br>
                    <img
                    src="https://dbmenos.com/img/brand/DB Menos logo 2020_Monograma azul.png"
                    style="
                      padding: 2%;
                      position:absolute;
                      top: 85%;
                      left:35%;
                      width: 28%;
                    "
                  />
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-calculate-xs-mati2"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="div-calculate-xs-mati2">
                    <span id="span-calculate-xs-mati2">¿Debes a alguna de estas instituciones?</span>
                     <div
                    class="row justify-content-center"
                    style="
                      margin-bottom: 5%;
                      margin-top: -2%;
                      align-items: center;
                      padding:0%;
                    "
                  >
                    <div
                      class="col-3 text-center"
                      style="align-items: center; margin-top:7%;"
                      v-for="(bank, index) in banks"
                      :key="index"
                    >
                          <img
                            :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                            :title="bank.name"
                            style="width:100%;"
                          />
                    </div>
                  </div>
                    <p id="span-banks-xs-mati2" style="margin-top: 1.5rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <div id="div-data-xs-mati2">
                    <span id="span-sol-xs-mati2">Somos la solución a tus deudas</span>
                  <base-input
                    alternative
                    style="margin-top:1rem;"
                    v-model="user.name"
                    placeholder="Nombre Completo"
                  ></base-input>
                  <base-input
                    alternative
                    type="number"
                    v-model="user.phone"
                    placeholder="Teléfono"
                  ></base-input>
                  <base-button
                  v-on:click="m_update_mati()"
                    style="
                      color: #2a3d9a;
                      background-color: #f3d338;
                      border: none;
                      width: 80%;
                      font-size: 15pt;
                      font-family: Gotham-Black;
                    "
                  >
                    INICIAR
                  </base-button>
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="div-comments-xs-mati2">
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-xs-mati2"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="div-span-comments-xs-mati2">
                    <span id="span-comments-xs-mati2">
                      La opinión de<b id="b-comments-xs-mati2"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; margin-top:30%; align-items: center">
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
                <section>
          <div class="container-fluid text-center" id="div-comments-xs-mati2">
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-xs-mati2"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="div-span-comments-xs-mati2">
                    <span id="span-comments-xs-mati2">
                      La que la prensa<b id="b-comments-xs-mati2"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; margin-top:30%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  <section>
      <div class="d-none d-sm-block d-md-none" style="background-color: #ebebeb; align-content: center">
        <section
          style="background-color: #ebebeb; align-content: center"
        >
          <div class="container" id="main-principal-sm-mati2">
            <div class="row">
              <div class="col">
                <img
                  src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Mujer.png"
                  id="img-background-sm-mati2"
                />
                <div class="d-flex justify-content-end">
                  <div id="principal-tittle-sm-mati2">
                    <span id="span-principal-sm-mati2">
                      "Liquidé mi deuda con
                      <b id="b-principal-sm-mati2">70% de descuento </b>a
                      <b id="b-principal-sm-mati2"> 15 pagos mensuales </b>de
                      <b id="b-principal-sm-mati2">$1,834. </b>
                      ¡Se adaptaron a mi presupuesto!"
                    </span>
                    <br>
                    <img
                    src="https://dbmenos.com/img/brand/DB Menos logo 2020_Monograma azul.png"
                    style="
                      padding: 2%;
                      position:absolute;
                      top: 85%;
                      left:35%;
                      width: 28%;
                    "
                  />
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-calculate-sm-mati2"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="div-calculate-sm-mati2">
                    <span id="span-calculate-sm-mati2">¿Debes a alguna de estas instituciones?</span>
                     <div
                    class="row justify-content-center"
                    style="
                      margin-bottom: 5%;
                      margin-top: -2%;
                      align-items: center;
                      padding:2%;
                    "
                  >
                    <div
                      class="col-3 text-center"
                      style="align-items: center; margin-top:8%;"
                      v-for="(bank, index) in banks"
                      :key="index"
                    >
                          <img
                            :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                            :title="bank.name"
                            style="width:100%;"
                          />
                    </div>
                  </div>
                    <p id="span-banks-sm-mati2" style="margin-top: 1.5rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <div id="div-data-sm-mati2">
                    <span id="span-sol-sm-mati2">Somos la solución a tus deudas</span>
                  <base-input
                    alternative
                    style="margin-top:1rem;"
                    v-model="user.name"
                    placeholder="Nombre Completo"
                  ></base-input>
                  <base-input
                    alternative
                    type="number"
                    v-model="user.phone"
                    placeholder="Teléfono"
                  ></base-input>
                  <base-button
                  v-on:click="m_update_mati()"
                    style="
                      color: #2a3d9a;
                      background-color: #f3d338;
                      border: none;
                      width: 80%;
                      font-size: 15pt;
                      font-family: Gotham-Black;
                    "
                  >
                    INICIAR
                  </base-button>
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="div-comments-sm-mati2">
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-sm-mati2"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="div-span-comments-sm-mati2">
                    <span id="span-comments-sm-mati2">
                      La opinión de<b id="b-comments-sm-mati2"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; margin-top:25%; align-items: center">
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
                <section>
          <div class="container-fluid text-center" id="div-comments-sm-mati2">
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-sm-mati2"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="div-span-comments-sm-mati2">
                    <span id="span-comments-sm-mati2">
                      La que la prensa<b id="b-comments-sm-mati2"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; margin-top:25%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
      <section>
      <div class="d-none d-md-block d-lg-none" style="background-color: #ebebeb; align-content: center">
        <section
          style="background-color: #ebebeb; align-content: center"
        >
          <div class="container" id="main-principal-md-mati2">
            <div class="row">
              <div class="col">
                <img
                  src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Mujer.png"
                  id="img-background-md-mati2"
                />
                <div class="d-flex justify-content-end">
                  <div id="principal-tittle-md-mati2">
                    <span id="span-principal-md-mati2">
                      "Liquidé mi deuda con
                      <b id="b-principal-md-mati2">70% de descuento </b>a
                      <b id="b-principal-md-mati2"> 15 pagos mensuales </b>de
                      <b id="b-principal-md-mati2">$1,834. </b>
                      ¡Se adaptaron a mi presupuesto!"
                    </span>
                    <br>
                    <img
                    src="https://dbmenos.com/img/brand/DB Menos logo 2020_Monograma azul.png"
                    style="
                      padding: 2%;
                      position:absolute;
                      top: 90%;
                      left:32%;
                      width: 35%;
                    "
                  />
                  </div>
                </div>
              </div>
              </div>
            </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-calculate-md-mati2"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="div-calculate-md-mati2">
                    <span id="span-calculate-md-mati2">¿Debes a alguna de estas instituciones?</span>
                     <div
                    class="row justify-content-center"
                    style="
                      margin-bottom: 5%;
                      margin-top: -2%;
                      align-items: center;
                      padding:2%;
                    "
                  >
                    <div
                      class="col-3 text-center"
                      style="align-items: center; margin-top:8%;"
                      v-for="(bank, index) in banks"
                      :key="index"
                    >
                          <img
                            :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                            :title="bank.name"
                            style="width:100%;"
                          />
                    </div>
                  </div>
                    <p id="span-banks-md-mati2" style="margin-top: 1.5rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <div id="div-data-md-mati2">
                    <span id="span-sol-md-mati2">Somos la solución a tus deudas</span>
                  <base-input
                    alternative
                    style="margin-top:1rem;"
                    v-model="user.name"
                    placeholder="Nombre Completo"
                  ></base-input>
                  <base-input
                    alternative
                    type="number"
                    v-model="user.phone"
                    placeholder="Teléfono"
                  ></base-input>
                  <base-button
                  v-on:click="m_update_mati()"
                    style="
                      color: #2a3d9a;
                      background-color: #f3d338;
                      border: none;
                      width: 80%;
                      font-size: 15pt;
                      font-family: Gotham-Black;
                    "
                  >
                    INICIAR
                  </base-button>
              </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid text-center" id="div-comments-md-mati2">
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-md-mati2"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="div-span-comments-md-mati2">
                    <span id="span-comments-md-mati2">
                      La opinión de<b id="b-comments-md-mati2"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 2%; align-items: center">
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
                <section>
          <div class="container-fluid text-center" id="div-comments-md-mati2">
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-md-mati2"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="div-span-comments-md-mati2">
                    <span id="span-comments-md-mati2">
                      La que la prensa<b id="b-comments-md-mati2"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-lg-block d-xl-none">
        <section>
          <div class="container-grid" id="main-section1-lg-mati2">
            <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-lg-4">
                <div class="d-flex justify-content-end">
                  <div id="principal-tittle-lg-mati2">
                    <span id="span-principal-lg-mati2">
                      "Liquidé mi deuda con
                      <b id="b-principal-lg-mati2">70% de descuento </b>a
                      <b id="b-principal-lg-mati2"> 15 pagos mensuales </b>de
                      <b id="b-principal-lg-mati2">$1,834. </b>
                      ¡Se adaptaron a mi presupuesto!"
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-1"></div>
              <div class="col-lg-6 text-center">
                <div id="div-banks-lg-mati2">
                  <span id="span-calculate-lg-mati2" style="font-size: 25pt;">
                    ¿Debes a alguna de estas instituciones?</span
                  >
                  <div
                    class="row justify-content-center"
                    style="
                      margin-bottom: 5%;
                      margin-top: -2%;
                      align-items: center;
                      padding:5%;
                    "
                  >
                    <div
                      class="col-3 text-center"
                      style="align-items: center; margin-top:5%;"
                      v-for="(bank, index) in banks"
                      :key="index"
                    >
                          <img
                            :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                            :title="bank.name"
                            style="width:100%;"
                          />
                    </div>
                  </div>
                  <p id="span-institution-lg-mati2">
                    <span>
                      Si tu institución financiera o banco no se encuentra aquí,
                      por el momento no podemos ayudarte.</span
                    >
                  </p>
                  <div id="div-data-lg-mati2">
                    <span id="span-sol-lg-mati2">Somos la solución a tus deudas</span>
                  <base-input
                    alternative
                    style="margin-top:1rem;"
                    v-model="user.name"
                    placeholder="Nombre Completo"
                  ></base-input>
                  <base-input
                    alternative
                    type="number"
                    v-model="user.phone"
                    placeholder="Teléfono"
                  ></base-input>
                  <base-button
                  v-on:click="m_update_mati()"
                    style="
                      color: #2a3d9a;
                      background-color: #f3d338;
                      border: none;
                      width: 80%;
                      font-size: 15pt;
                      font-family: Gotham-Black;
                    "
                  >
                    INICIAR
                  </base-button>
              </div>
                </div>
              </div>
            </div>
          </div>
        </section>
         <section>
          <div
            class="container-fluid text-center"
            id="main-comments-lg-mati2"
          >
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-lg-mati2"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex text-center">
                  <div id="div-text-lg-mati2">
                    <span id="span-comments-lg-mati2">
                      La opinión de<b id="b-comments-lg-mati2"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%; margin-top:10%; align-items: center">
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-comments-lg-mati2"
          >
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-lg-mati2"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">  
              <div id="div-text-lg-mati2">
                    <span id="span-comments-lg-mati2">
                      La que la prensa<b id="b-comments-lg-mati2">
                        opina de nosotros
                      </b>
                    </span>
                  </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%; margin-top:10%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-xl-block">
        <section>
          <div class="container-grid" id="main-section1-xl-mati2">
            <div class="row">
              <div class="col-lg-1"></div>
              <div class="col-4">
                <div class="d-flex justify-content-end">
                  <div id="principal-tittle-xl-mati2">
                    <span id="span-principal-xl-mati2">
                      "Liquidé mi deuda con
                      <b id="b-principal-xl-mati2">70% de descuento </b>a
                      <b id="b-principal-xl-mati2"> 15 pagos mensuales </b>de
                      <b id="b-principal-xl-mati2">$1,834. </b>
                      ¡Se adaptaron a mi presupuesto!"
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-lg-1"></div>
              <div class="col-6 text-center">
                <div id="div-banks-xl-mati2">
                  <span id="span-calculate-xl-mati2" style="font-size: 25pt;">
                    ¿Debes a alguna de estas instituciones?</span
                  >
                  <div
                    class="row justify-content-center"
                    style="
                      margin-bottom: 5%;
                      margin-top: -2%;
                      align-items: center;
                      padding:5%;
                    "
                  >
                    <div
                      class="col-3 text-center"
                      style="align-items: center; margin-top:8%;"
                      v-for="(bank, index) in banks"
                      :key="index"
                    >
                          <img
                            :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                            :title="bank.name"
                            style="width:100%;"
                          />
                    </div>
                  </div>
                  <p id="span-institution-xl-mati2">
                    <span>
                      Si tu institución financiera o banco no se encuentra aquí,
                      por el momento no podemos ayudarte.</span
                    >
                  </p>
                  <div id="div-data-xl-mati2">
                    <span id="span-sol-xl-mati2">Somos la solución a tus deudas</span>
                  <base-input
                    alternative
                    style="margin-top:1rem;"
                    v-model="user.name"
                    placeholder="Nombre Completo"
                  ></base-input>
                  <base-input
                    alternative
                    type="number"
                    v-model="user.phone"
                    placeholder="Teléfono"
                  ></base-input>
                  <base-button
                  v-on:click="m_update_mati()"
                    style="
                      color: #2a3d9a;
                      background-color: #f3d338;
                      border: none;
                      width: 80%;
                      font-size: 15pt;
                      font-family: Gotham-Black;
                    "
                  >
                    INICIAR
                  </base-button>
              </div>
                </div>
              </div>
            </div>
          </div>
        </section>
         <section>
          <div
            class="container-fluid text-center"
            id="main-comments-xl-mati2"
          >
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-xl-mati2"
                  src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex text-center">
                  <div id="div-text-xl-mati2">
                    <span id="span-comments-xl-mati2">
                      La opinión de<b id="b-comments-xl-mati2"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 10%; align-items: center">
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/6.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/3.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/1.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/4.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/5.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                      target="_blank"
                    >
                      <img
                        src="img/Comentarios/Escritorio/2.png"
                        style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
            class="container-fluid text-center"
            id="main-comments-xl-mati2"
          >
            <div class="row">
              <div class="col-6">
                <img
                  id="img-comments-xl-mati2"
                  src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">  
              <div id="div-text-xl-mati2">
                    <span id="span-comments-xl-mati2">
                      La que la prensa<b id="b-comments-xl-mati2">
                        opina de nosotros
                      </b>
                    </span>
                  </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 10%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                      target="_blank"
                    >
                      <img src="img/prensa/El Economista.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                      href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                      target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png" />
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                      href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                      target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png" />
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                      href="https://www.forbes.com.mx/las-primeras-11-empresas-en-las-que-500-startups-invertira-este-ano/"
                      target="_blank"
                    >
                      <img src="img/prensa/Forbes.png" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
</div>
</template>
<script>
import Vue from "vue";
import modal from "@/components/Modal";
import { V } from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import { LazyYoutube } from "vue-lazytube";

Vue.use(VueFacebookPixel);
export default {
  name: "registerNew",
  components: {
    modal,
    VueFacebookPixel,
    LazyYoutube,
  },
  created() {
    this.m_register_mati();
    this.m_getBanks();
    this.width ='90%';
    this.height = 10;

    this.processStyle = {
      backgroundColor: '#f3d338',
    }
    this.sliderStyle = {
      width:'18px',
      height:'18px',
      backgroundColor: '#f3d338',
    }
  },
  data() {
    return {
      V: V,
      dataMark: "",
      from: null,
      typeapp: {
        appStore: 0,
        playStore: 0,
      },
      simpleCalc: {
        amount: 100,
        banks: [],
      },
      banks: [],
      leadInfo: {
        name: "",
        email: "",
        phone: "",
      },
      user: {
        name: "",
        phone: "",
      },
      leadValidationCode: "",
      leadSent: false,
      sameBankDebtPaysheet: false,
      leadModal: false,
      estimatedDiscountModal: false,
      averageDiscount: 0,
      videosModal: false,
    };
  },
  methods: {
    // PATH ?utm_source=SOURCE&utm_medium=MEDIUM&utm_campaign=CAMPAIGN&utm_term=TERM&utm_content=CONTENT
     m_getBanks() {
      let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS_LANDINGS;
      this.$http.post(url, {}).then((res) => {
        if (res.body.success) {
          this.banks = res.body.rows;
          this.banks.forEach((b) => {
            b.selected = false;
          });
        }
      });
    },
    m_hasValidPhone() {
      return (
        !isNaN(this.user.phone) &&
        this.user.phone.length > 9 &&
        this.user.phone.length < 11
      );
    },
    m_hasValidName() {
      return this.user.name.length > 2;
    },
    m_register_mati() {
      this.$showLoader(true);
      let marketing = this.$route.query.utm_source +"_" +this.$route.query.utm_medium +"_" +this.$route.query.utm_campaign;
      console.log(marketing)
      if (marketing == "undefined_undefined_undefined") {
        marketing = null;
      }else if(marketing == this.$route.query.utm_source+"_undefined_undefined"){
        marketing = this.$route.query.utm_source;
          } else if(marketing == this.$route.query.utm_source+"_"+this.$route.query.utm_medium+"_undefined"){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_medium;
          }else if(marketing == this.$route.query.utm_source+"_undefined_"+this.$route.query.utm_campaign){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_campaign;
          }else{
            marketing = marketing;
          }
      let url = V.SERVER + V.OPS.SET_MARKETING_MATI;
      this.$showLoader(false);
      return this.$axios
        .post(url, { marketing: marketing })
        .then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data.success) {
            this.dataMark = data.row;

            console.log(this.dataMark);
          }
        });
    },
    m_update_mati() {
      let marketing = this.$route.query.utm_source +"_" +this.$route.query.utm_medium +"_" +this.$route.query.utm_campaign;
      console.log(marketing)
      if (
        this.user.name &&
        this.user.phone.length > 9 &&
        this.user.phone.length < 11
      ) {
     if (marketing == "undefined_undefined_undefined") {
        marketing = null;
      }else if(marketing == this.$route.query.utm_source+"_undefined_undefined"){
        marketing = this.$route.query.utm_source;
          } else if(marketing == this.$route.query.utm_source+"_"+this.$route.query.utm_medium+"_undefined"){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_medium;
          }else if(marketing == this.$route.query.utm_source+"_undefined_"+this.$route.query.utm_campaign){
            marketing = this.$route.query.utm_source+"_"+this.$route.query.utm_campaign;
          }else{
            marketing = marketing;
          }
        this.$showLoader(true);
        let id = this.dataMark.id;
        let updateMati = {
          id: id,
          name: this.user.name,
          phone: this.user.phone,
          marketing: marketing,
        };
        let url = V.SERVER + V.OPS.UPDATE_MARKETING_MATI;
        return this.$axios.post(url, updateMati).then((response) => {
          this.$showLoader(false);
          let data = response.data;
          if (data.success) {
            fbq("track", "Lead");
            gtag("event", "click-iniciar-mati", {
              event_category: "Landing-mati",
              event_label: "iniciar-mati",
            });
            this.$notify({
              text: "Mati se comunicará contigo por medio de whatsapp",
              type: "success",
            });
            this.user.name = null;
            this.user.phone = null;
          }
        });
      } else {
        this.$notify({
          text: "Tienes que ingresar tu nombre y télefono para poder continuar",
          type: "error",
        });
      }
    },
  },
};
</script>
<style>
@font-face {
  font-family: bahnschrift;
  src: url(/fonts/Bahnschrift_font/bahnschrift.ttf);
}
@font-face {
  font-family: Roboto-ThinItalic;
  src: url(/fonts/Roboto_font/Roboto-ThinItalic.ttf);
}
@font-face {
  font-family: Roboto-Thin;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}
@font-face {
  font-family: Roboto-Regular;
  src: url(/fonts/Roboto_font/Roboto-Regular.ttf);
}
@font-face {
  font-family: Roboto-MediumItalic;
  src: url(/fonts/Roboto_font/Roboto-MediumItalic.ttf);
}
@font-face {
  font-family: Roboto-Medium;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}
@font-face {
  font-family: Roboto-LightItalic;
  src: url(/fonts/Roboto_font/Roboto-LightItalic.ttf);
}
@font-face {
  font-family: Roboto-Light;
  src: url(/fonts/Roboto_font/Roboto-Light.ttf);
}
@font-face {
  font-family: Roboto-Italic;
  src: url(/fonts/Roboto_font/Roboto-Italic.ttf);
}
@font-face {
  font-family: Roboto-BoldItalic;
  src: url(/fonts/Roboto_font/Roboto-BoldItalic.ttf);
}
@font-face {
  font-family: Roboto-Bold;
  src: url(/fonts/Roboto_font/Roboto-Bold.ttf);
}
@font-face {
  font-family: Roboto-BlackItalic;
  src: url(/fonts/Roboto_font/Roboto-BlackItalic.ttf);
}
@font-face {
  font-family: Roboto-Black;
  src: url(/fonts/Roboto_font/Roboto-Black.ttf);
}
@font-face {
  font-family: Gotham-Black;
  src: url(/fonts/Gotham_Font/Gotham-Black.otf);
}
@font-face {
  font-family: Gotham-Thin;
  src: url(/fonts/Gotham_Font/Gotham-Thin.otf);
}
@font-face {
  font-family: Gotham-ThinItalic;
  src: url(/fonts/Gotham_Font/Gotham-ThinItalic.otf);
}
@font-face {
  font-family: Gotham-ultra;
  src: url(/fonts/Gotham_Font/gotham-ultra.ttf);
}
@font-face {
  font-family: Gotham-UltraItalic;
  src: url(/fonts/Gotham_Font/Gotham-UltraItalic.otf);
}
@font-face {
  font-family: Gotham-Bold;
  src: url(/fonts/Gotham_Font/GothamBold.ttf);
}
@font-face {
  font-family: Gotham-BoldItalic;
  src: url(/fonts/Gotham_Font/GothamBoldItalic.ttf);
}
@font-face {
  font-family: Gotham-Book;
  src: url(/fonts/Gotham_Font/GothamBook.ttf);
}
@font-face {
  font-family: Gotham-BookItalic;
  src: url(/fonts/Gotham_Font/GothamBookItalic.ttf);
}
@font-face {
  font-family: Gotham-Light;
  src: url(/fonts/Gotham_Font/GothamLight.ttf);
}
@font-face {
  font-family: Gotham-Medium;
  src: url(/fonts/Gotham_Font/GothamMedium.ttf);
}
@font-face {
  font-family: Gotham-LightItalic;
  src: url(/fonts/Gotham_Font/GothamLightItalic.ttf);
}
@font-face {
  font-family: Metropolis-ExtraLight;
  src: url(/fonts/Metropolis/Metropolis-ExtraLight.otf);
}
@font-face {
  font-family: HomePage-Book;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Book.ttf);
}
@font-face {
  font-family: HomePage-Bold;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Bold.ttf);
}
/* Styles xs */
#main-principal-xs-mati2 {
    text-align: center;
    background-color: #efefef;
    height:100%;
    width: 105%;
    margin-left: 0.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://dbmenos.com/img/landings-pantallas/pantallas-mobile/MATI fondo mขvil.png");
}
#img-background-xs-mati2 {
    height: 100%;
    width: 60%;
  margin-top:1%;
  margin-left: -40%;
  align-items: center;
}

#principal-tittle-xs-mati2 {
  position: absolute;
  padding: 10%;
  color: #808080;
  width: 60%;
  font-size: 10pt;
  top: 0rem;
  left: 40%;
}

#span-principal-xs-mati2 {
  color: #808080;
  font-family: Metropolis-ExtraLight;
}
#b-principal-xs-mati2 {
  font-family: HomePage-Bold;
}

#main-calculate-xs-mati2 {
  background-image: url("https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Fondo+azul+movil.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5%;
  height: 100%;
  width: 100%;
  margin-top: 0.8%;
}

#div-calculate-xs-mati2 {
  margin-bottom: 10%;
  padding: 1%;
}

#span-calculate-xs-mati2 {
  color: white;
  font-size: 15pt;
  font-family: HomePage-Bold;
}

#span-banks-xs-mati2 {
  font-size:10pt;
  color: #f3d338;
  margin-top: -10%;
  font-family: HomePage-Book;
}

#div-data-xs-mati2 {
  padding: 1%;
}
#span-sol-xs-mati2 {
  color: white;
  font-size:12pt;
  font-family: HomePage-Bold;
}
#div-comments-xs-mati2 {
  align-items: center;
  padding: 5%;
  height: 100%;

}
#img-comments-xs-mati2 {
  position: absolute;
  width: 60%;
  right: 20%;
}
#div-span-comments-xs-mati2 {
  position: absolute;
  text-align: left;
  color: #808080;
  width: 100%;
  padding: 0%;
  margin-top: 10%;
  left:-10%;
}
#span-comments-xs-mati2 {
  font-size: 12pt;
  margin-left: 0%;
  font-family: HomePage-Bold;
}
#b-comments-xs-mati2 {
  font-family: HomePage-Book;
}

/* Styles sm */
#main-principal-sm-mati2 {
    text-align: center;
    background-color: #efefef;
    height:100%;
    width: 105%;
    margin-left: 0.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://dbmenos.com/img/landings-pantallas/pantallas-mobile/MATI fondo mขvil.png");
}
#img-background-sm-mati2 {
    height: 100%;
    width: 60%;
  margin-top:1%;
  margin-left: -45%;
  align-items: center;
}

#principal-tittle-sm-mati2 {
  position: absolute;
  padding: 10%;
  color: #808080;
  width: 70%;
  font-size: 15pt;
  top: 1rem;
  left: 45%;
}

#span-principal-sm-mati2 {
  color: #808080;
  font-family: Metropolis-ExtraLight;
}
#b-principal-sm-mati2 {
  font-family: HomePage-Bold;
}

#main-calculate-sm-mati2 {
  background-image: url("https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Fondo+azul+movil.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5%;
  height: 100%;
  width: 100%;
  margin-top: 0.8%;
}

#div-calculate-sm-mati2 {
  margin-bottom: 10%;
  padding: 1%;
}

#span-calculate-sm-mati2 {
  color: white;
  font-size: 30pt;
  font-family: HomePage-Bold;
}

#span-banks-sm-mati2 {
  font-size:15pt;
  color: #f3d338;
  margin-top: -10%;
  font-family: HomePage-Book;
}

#div-data-sm-mati2 {
  padding: 1%;
}
#span-sol-sm-mati2 {
  color: white;
  font-size:20pt;
  font-family: HomePage-Bold;
}
#div-comments-sm-mati2 {
  align-items: center;
  padding: 5%;
  height: 100%;

}
#img-comments-sm-mati2 {
  position: absolute;
  width: 60%;
  right: 20%;
}
#div-span-comments-sm-mati2 {
  position: absolute;
  text-align: left;
  color: #808080;
  width: 100%;
  padding: 2%;
  margin-top: 7%;
  left:2%;
}
#span-comments-sm-mati2 {
  font-size: 20pt;
  margin-left: -20%;
  font-family: HomePage-Bold;
}
#b-comments-sm-mati2 {
  font-family: HomePage-Book;
}

/* Styles md */
#main-principal-md-mati2 {
    text-align: center;
    background-color: #efefef;
    height:100%;
    width: 105%;
    margin-left: 0.5%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url("https://dbmenos.com/img/landings-pantallas/pantallas-mobile/MATI fondo mขvil.png");
}
#img-background-md-mati2 {
    height: 100%;
    width: 60%;
  margin-top:1%;
  margin-left: -30%;
  align-items: center;
}

#principal-tittle-md-mati2 {
  position: absolute;
  padding: 10%;
  color: #808080;
  width: 60%;
  font-size: 20pt;
  top: -1rem;
  left: 50%;
}

#span-principal-md-mati2 {
  color: #808080;
  font-family: Metropolis-ExtraLight;
}
#b-principal-md-mati2 {
  font-family: HomePage-Bold;
}

#main-calculate-md-mati2 {
  background-image: url("https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Fondo+azul+movil.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5%;
  height: 100%;
  width: 100%;
  margin-top: 0.8%;
}

#div-calculate-md-mati2 {
  margin-bottom: 10%;
  padding: 1%;
}

#span-calculate-md-mati2 {
  color: white;
  font-size: 30pt;
  font-family: HomePage-Bold;
}

#span-banks-md-mati2 {
  font-size:18pt;
  color: #f3d338;
  margin-top: -10%;
  font-family: HomePage-Book;
}

#div-data-md-mati2 {
  padding: 1%;
}
#span-sol-md-mati2 {
  color: white;
  font-size:17pt;
  font-family: HomePage-Bold;
}
#div-comments-md-mati2 {
  align-items: center;
  padding: 3%;
  height: 100%;

}
#img-comments-md-mati2 {
  width: 50%;
  right: 20%;
}
#div-span-comments-md-mati2 {
  position: absolute;
  text-align: left;
  color: #808080;
  width: 70%;
  top: 30%;
  right: 30%;
}
#span-comments-md-mati2 {
  font-size: 20pt;
  margin-left: -20%;
  font-family: HomePage-Bold;
}
#b-comments-md-mati2 {
  font-family: HomePage-Book;
}

/*Styles lg*/
#main-section1-lg-mati2{
  padding: 0;
  height: 100%;
  margin-left: -12%;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Landing2Mati2Desktop_lg.png");
}
#principal-tittle-lg-mati2 {
  position: absolute;
  color: #808080;
  width: 40%;
  font-size: 120%;
  top: 10%;
  left: 120%;
  line-height: 2rem;
}

#span-principal-lg-mati2 {
  color: #808080;
  font-family: Metropolis-ExtraLight;
}
#b-principal-lg-mati2 {
  font-family: HomePage-Bold;
}
#div-banks-lg-mati2 {
  margin-left: 12rem;
  height: 100%;
  width: 60%;
  padding: 5%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Fondo azul escritorio.png");
}
#span-calculate-lg-mati2 {
  color: #ffff;
  padding: 5%;
  font-family: HomePage-Bold;
}
#span-institution-lg-mati2 {
  font-size: 110%;
  color: #f3d338;
  font-family: HomePage-Book;
}
#div-data-lg-mati2 {
  padding: 2%;
}
#span-sol-lg-mati2 {
  color: white;
  font-size:17pt;
  font-family: HomePage-Bold;
}
#main-comments-lg-mati2 {
  height: 100%;
  padding: 5%;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background 1500 px.png");
}

#img-comments-lg-mati2 {
  width: 30%;
  position: absolute;
  left: 40%;
  top: 0;
}
#div-text-lg-mati2 {
  position: absolute;
  color: #2a3d9a;
  width: 80%;
  margin-top: 7%;
  left: 0;
}
#span-comments-lg-mati2{
  font-size: 20pt;
  margin-left: -45%;
  font-family: HomePage-Bold;
}
#b-comments-lg-mati2 {
  font-family: HomePage-Book;
}

/*Styles xl*/
#main-section1-xl-mati2{
  padding: 0;
  height: 120%;
  margin-left: -2%;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Mati2_Escritorio.png");
}
#principal-tittle-xl-mati2 {
  position: absolute;
  color: #808080;
  width: 40%;
  font-size: 120%;
  top: 15%;
  left: 105%;
  line-height: 2rem;
}

#span-principal-xl-mati2 {
  color: #808080;
  font-family: Metropolis-ExtraLight;
}
#b-principal-xl-mati2 {
  font-family: HomePage-Bold;
}
#div-banks-xl-mati2 {
  margin-left: 10rem;
  height: 100%;
  width: 68%;
  padding: 5%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Fondo azul escritorio.png");
}
#span-calculate-xl-mati2 {
  color: #ffff;
  padding: 5%;
  font-family: HomePage-Bold;
}
#span-institution-xl-mati2 {
  font-size: 110%;
  color: #f3d338;
  font-family: HomePage-Book;
}
#div-data-xl-mati2 {
  padding: 2%;
}
#span-sol-xl-mati2 {
  color: white;
  font-size:17pt;
  font-family: HomePage-Bold;
}
#main-comments-xl-mati2 {
  height: 100%;
  padding: 5%;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background 1500 px.png");
}

#img-comments-xl-mati2 {
  width: 20%;
  position: absolute;
  left: 70%;
  top: 5%;
}
#div-text-xl-mati2 {
  position: absolute;
  color: #2a3d9a;
  width: 50%;
  margin-top: 4%;
  left: 0;
}
#span-comments-xl-mati2{
  font-size: 20pt;
  margin-left: -10%;
  font-family: HomePage-Bold;
}
#b-comments-xl-mati2 {
  font-family: HomePage-Book;
}
</style>
