<template>

  <div>

    <!--
    <div style="background-color: #FFFFFF; position: relative; margin-top:10px; width:100%; display: inline-block;">
      <VueSignaturePad
          height="300px"
          ref="signaturePad"/>

      <div class="signature mx-5 text-center noselect"><br>Firma<br><br></div>
    </div>
    -->
    <!--
        <proposal-step/>
    -->

    <signature-step/>

  </div>

</template>

<script>

import VueSignaturePad from 'vue-signature-pad'
import ProposalStep from "@/views/components/registration_steps/ProposalStep";
import SignatureStep from "@/views/components/registration_steps/SignatureStep";

export default {
  name: "RegistrationSteps",
  components: {SignatureStep, ProposalStep, VueSignaturePad},
}
</script>

<style scoped>

</style>