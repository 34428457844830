<template>
  <div>
    <div class="position-relative">
      <!-- shape Hero -->
      <section class="section-shaped my-0" style="background-color: #3953d3">
        <div class="shape shape-style-1 shape-default shape-skew">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div class="container shape-container" style="margin: -10rem">
          <div class="col px-0">
            <div class="row"></div>
          </div>
        </div>
      </section>
      <section
        class="section section-lg pt-lg-0 mt--300 subir"
        style="background-color: #3953d3"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="col-lg-12">
                <h1 class="display-3 text-white">REPORTE DE CRÉDITO</h1>
              </div>
              <br />
              <div class="row row-grid justify-content-center">
                <div v-if="bandera && !notCredit" class="col-lg-12 text-center">
                  <b-card
                    title="Estamos buscando tu información"
                    img-top
                    style="max-width: 70rem; max-heigth: 20rem"
                  >
                    <b-card-text>
                      El proceso de consulta de reporte de crédito puede tardar
                      unos minutos en aparecer, te enviaremos una notificación a
                      tu correo cuando esté disponible.
                    </b-card-text>
                  </b-card>
                </div>
                <div
                  v-if="error && !bandera && !notCredit"
                  class="col-lg-12 text-center"
                >
                  <b-card
                    title="No podemos continuar"
                    img-top
                    style="max-width: 70rem; max-heigth: 20rem"
                  >
                    <b-card-text
                      >¡UPS, parece que algún dato es incorrecto por favor
                      contáctate con tu asesor para terminar el
                      proceso!</b-card-text
                    >
                  </b-card>
                </div>
                <div v-if="notCredit && !bandera" class="col-lg-12 text-center">
                  <b-card
                    title="No podemos continuar"
                    img-top
                    style="max-width: 50rem; max-heigth: 20rem"
                  >
                    <b-card-text>
                      Ups, por el momento no identificamos ninguna cuenta que
                      podamos ingresar al programa, pronto alguien del equipo se
                      contactará contigo.
                      <br />
                      <small
                        >Te invitamos a seguirnos en nuestras redes sociales
                        para más información.</small
                      >
                    </b-card-text>
                  </b-card>
                </div>
                <div
                  class="col-lg-4 card-debts"
                  v-for="(debt, indexdeb) in debts"
                  :key="indexdeb"
                >
                  <div v-if="debt.amount > 0 && reportListo">
                    <b-card
                      class="border-0 tam mb-4"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 5px; border-radius: 1rem; height: auto"
                    >
                      <div class="row">
                        <div class="col-md-9">
                          <h3 class="text-primary mb-md-1">{{ debt.bank }}</h3>
                        </div>
                        <div class="col-md-3">
                          <div class="g-item">
                            <img
                              class="img-responsive img-deb"
                              v-bind:src="getBank(debt.bankid).url"
                              style="object-fit: contain"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-center" style="margin-top: -10px">
                        <br />
                        <label for="h6amount" style="width: 100%"
                          >Deuda total</label
                        >

                        <h3 id="h6amount" class="text-primary">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h3>
                        <label for="h5amount2" style="width: 100%"
                          >Cuenta
                          {{
                            debt.account.substring(
                              debt.account.length - 4,
                              debt.account.length
                            )
                          }}</label
                        >

                        <div class="col">
                            <br />
                            <base-slider
                              :range="{ min: minSlider, max: maxSlider }"
                              :options="{ step: 1 }"
                              v-model.number="period"
                              v-on:input="m_calculateDiscount()"
                            ></base-slider>
                            <h6 class="text-primary text-center">
                              Duración del programa {{ period }} meses
                            </h6>
                          </div>
                        <label for="h5amount2" style="width: 100%"
                          >Último Pago: {{ debt.last_bank_payment_date }}
                        </label>
                        <div class="row">
                          <base-button
                            block
                            v-if="debt.addProgram"
                            v-on:click="m_spliceDebt(indexdeb)"
                            type="primary"
                            class="mt-1"
                            >Deshabilitar Cuenta</base-button
                          >
                          <base-button
                            block
                            v-else
                            v-on:click="m_pushDebt(indexdeb)"
                            type="secondary"
                            class="mt-1"
                            >Habilitar Cuenta</base-button
                          >
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br />
          <card
            v-if="reportListo && !notCredit"
            style="margin-top: 1rem; border-radius: 2rem"
          >
            <div
              class="container shape-container d-flex"
              style="margin-top: 10px"
            >
              <div class="col px-0">
                <div class="row">
                  <div class="col-lg-12">
                    <h1 class="display-3 text-black text-center">
                      Programa DB Menos
                    </h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="container" style="max-width: 1440px !important">
              <div
                class="row justify-content-center"
                style="margin-bottom: 40px"
              >
                <div class="col-lg-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <card class="border-0">
                        <div class="row justify-content-center">
                          <div class="col">
                            <br />
                            <base-slider
                              :range="{ min: 6, max: 48 }"
                              :options="{ step: 1 }"
                              v-model.number="period"
                              v-on:input="m_calculateDiscount()"
                            ></base-slider>
                            <h6 class="text-primary text-center">
                              Duración del programa {{ period }} meses
                            </h6>
                          </div>
                        </div>
                      </card>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="row justify-content-center text-center"
                style="margin-bottom: 40px; margin-top: -80px"
              >
                <div class="col-lg-2"></div>
                <div class="col-lg-3">
                  <h6
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                  >
                    Deuda total
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      discount
                        ? discount.totalDebt
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }}</span
                  >

                  <h6
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Pagarás*
                  </h6>
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      discount
                        ? discount.willPay
                            .toFixed(2)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                        : ""
                    }}</span
                  >
                </div>

                <div class="col-lg-4">
                  <div
                    class="d-flex justify-content-between margin-yellow"
                    style="
                      width: 70%;
                      height: 2rem;
                      padding: 5px;
                      background: #ffd527;
                      color: black;
                      text-align: left;
                    "
                  ></div>
                  <div
                    class="d-flex justify-content-between margin-blue"
                    style="
                      width: 10%;
                      height: 2rem;
                      padding: 5px;
                      background: #3953d3;
                      color: black;
                      text-align: left;
                    "
                    :style="{
                      width:
                        discount.discount > 0
                          ? Math.ceil(
                              64 / (discount.totalDebt / discount.willPay)
                            ) + '%'
                          : '0%',
                    }"
                  ></div>
                </div>

                <div class="col-lg-6">
                  <span class="h2 font-weight-bold mb-0"
                    >${{
                      discount.montlyFee
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}</span
                  >
                  <h6
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Depósito mensual
                  </h6>
                </div>
              </div>
            </div>
            <span class="h6 font-weight-bold mb-0"
              >* La cantidad a pagar es un estimado, puede variar al momento de
              liquidar la deuda.</span
            >
            <br />
            <span class="h6 font-weight-bold mb-0"
              >** El mes de pago es un estimado, puede variar al momento de
              liquidar la deuda.</span
            >
          </card>
          <br />

          <base-button
            block
            v-if="reportListo && !notCredit"
            v-on:click="startProgram"
            style="
              background-color: #ffd527;
              border-color: #ffd527;
              color: #3953d3;
            "
            class="mt-4"
          >
            <b>Quiero Iniciar en DB Menos</b>
          </base-button>
          <a :href="urlAsesor" target="_blank" class="nav-link navbar-brand">
            <base-button
              block
              v-if="reportListo || notCredit || error"
              class="btn btn-primary"
            >
              <b>Contacta a tu asesor</b>
            </base-button>
          </a>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase/app';
    import 'firebase/firestore';
    import 'firebase/analytics';
    import 'firebase/messaging';
import BaseDropdown from "@/components/BaseDropdown";
import Vue from "vue";
import modal from "@/components/Modal";
import { V } from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueFacebookPixel);
Vue.use(VueSimpleAlert);
export default {
  name: "home",
  components: {
    BaseDropdown,
    modal,
  },

  data() {
    return {
      V: V,
      listsIsLoaded: false,
      lists: [],
      debts: [
        {
          bankid: 0,
          bank: "",
          amount: "",
          account: "",
          last_bank_payment_date: "",
          addProgram: false,
          digitAccount: "",
          willPay: "",
          willPayOnMonth: "",
        },
      ],
      discount: {
        debts: [
          {
            addProgram: false,
          },
        ],
        addProgram: false,
        totalDebt: 0,
        savings: 0,
        montlyFee: 0,
        discount: 0,
        o_progress: 0,
      },
      cambiardebts: false,
      reportListo: false,
      notCredit: false,
      bandera: true,
      error: false,
      fullPage: true,
      disableCuenta: false,
      period: 18,
      overf: "hidden",
      banksmodal: false,
      digitAccount: "",
      dueMonthsBank: "",
      sliderRange: "",
      maxSlider: "",
      minSlider: "",
      calcData: {
        period: 18,
      },
      urlAsesor: "",
      phone: "",
    };
  },

  created() {
    firebase.analytics().logEvent("starting_leads_2");
    fbq("track", "starting_leads_2");
    gtag("event", "starting_leads_2", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });

    fbq("track", "calculator_automated");
    firebase.analytics().logEvent("calculator_automated");
    gtag("event", "calculator_automated", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });
  },
  mounted() {
    this.doAjax();
    this.$forceUpdate();
    this.getAsesor();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    banks() {
      return this.$store.state.banks;
    },
  },
  methods: {
    calcDate(date2) {
      let dateCorrect = new Date(this.interprateDate(date2));
      var diff = Math.floor(new Date() - dateCorrect.getTime());
      var day = 1000 * 60 * 60 * 24;

      var days = Math.floor(diff / day);
      var months = Math.floor(days / 31);
      var years = Math.floor(months / 12);

      var message = months;

      return message;
    },
    startProgram() {
      let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATION;
      let json = JSON.parse(JSON.stringify(this.discount.debts));
      this.$axios
        .post(url, json, { from_credit_report: true })
        .then((response) => {
          let data = response.data;
          if (data[this.$v.API.SUCCESS]) {
            firebase.analytics().logEvent("calculator_complete", json);

            gtag("event", "starting_leads_2", {
              send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
            });
            fbq("track", "calcuator_complete");

            this.$router.push({
              path: "/start2",
            });
          }
        });
    },
    m_calculateDiscount() {
      let _debts = JSON.parse(JSON.stringify(this.debts));
      let _debts2 = _debts.filter((_debts) => _debts.addProgram);
      for (let d = 0; d < _debts2.length; d++) {
        _debts2[d].amount = Number(_debts2[d].amount);
        delete _debts2[d].edit;
      }
      let user = this.user;
      if (user === undefined || user === null) {
        user = {
          name: "",
          lastname: "",
          id: 1,
        };
      }

      let period = this.period;
      let _data = {
        name: user.name,
        lastname: user.lastname_1,
        userid: user.id,
        period: period,
        debts: _debts2,
      };
      let _data2 = {
        name: user.name,
        lastname: user.lastname_1,
        userid: user.id,
        period: period,
        debts: _debts,
      };

      if (_data.name === undefined) _data.name = "";
      if (_data.lastname === undefined) _data.lastname = "";

       this.$axios.post(V.SERVER + V.OPS.CALCULATE, _data).then(
                res => {
                    this.discount = [];
                    if (res.data.success) {
                        this.discount = res.data.row;
                        let current = 0;
                        for (let i = 0; i < this.discount.debts.length; i++) {
                            current += Number(
                                (this.discount.debts[i].willPay /
                                    this.discount.willPay) *
                                    100
                            );
                            this.discount.debts[i].o_progress = current;
                        }
                        this.$forceUpdate();
                    }
                },
                er => {
                    alert("Algo salió mal, intenta nuevamente");
                    console.log(er);
                }
            );
        },
    getAsesor() {
      let url = this.$v.SERVER + this.$v.OPS.GET_CONSULTANT_INFO;
      this.$axios.get(url).then((response) => {
        let data = response.data;
        if (data[this.$v.API.SUCCESS]) {
          this.phone = data.row.phone;
          this.urlAsesor = "https://wa.me/52" + this.phone;
          // window.open(this.urlAsesor);
        }
      });
    },
    m_findInDiscount(account) {
      for (let i = 0; i < this.discount.debts.length; i++)
        if (this.discount.debts[i].account === account)
          return this.discount.debts[i];
      return null;
    },
    m_spliceDebt(index) {
      let count = 0;
      for (let i = 0; i < this.debts.length; i++)
        if (this.debts[i].addProgram) count += 1;
      if (count > 1) {
        this.debts[index].addProgram = false;
        this.$showLoader(true);
        setTimeout(() => {
          this.$showLoader(false);
          this.m_calculateDiscount();
        }, 500);
      } else if (this.discount.debts.length <= 1) {
        this.$alert("Se requiere al menos una cuenta para continuar");
      }
    },
    m_pushDebt(index) {
      this.debts[index].addProgram = true;
      this.$showLoader(true);
      setTimeout(() => {
        this.$showLoader(false);
        this.m_calculateDiscount();
      }, 500);
    },
    getBank(id) {
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    getAccount() {
      for (let i = 0; i < this.debts.length; i++) {
        this.digitAccount = JSON.parse(JSON.stringify(this.debts[i].account));

        this.debts[i].digitAccount = this.digitAccount.substring(
          this.digitAccount.length - 4,
          this.digitAccount.length
        );
      }
    },
    interprateDate(cadena) {
      let fec = cadena + "";
      let day = fec.substr(0, 2);
      let month = fec.substr(2, 2);
      let year = fec.substr(4, 7);
      let date = day + "-" + month + "-" + year;
      var fecha = new Date(
        this.$moment(date, "DD MM YYYY").format("DD MM YYYY")
      );
      return this.$moment(fecha).format("YYYY-MM-DD");
    },
    getCreditReport() {
      this.getAsesor();
      let url = this.$v.SERVER + this.$v.OPS.GET_CR_ACCOUNTS;
      this.$axios.get(url).then(
        (response) => {
          let data = response.data;
          if (data.success) {
            this.debts = [];
            this.dueMonthsBank = [];
            for (let i = 0; i < data.rows.length; i++) {
              // this.dueMonthsBank.push(this.calcDate(data.rows[i].last_bank_payment_date));
              // data.rows[i].last_bank_payment_date = this.dueMonthsBank;
              data.rows[i].addProgram = true;
              if (data.rows[i].amount > 0) {
                this.debts.push(data.rows[i]);
                this.debts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
              }
            }
            this.m_calculateDiscount();
            this.bandera = false;
            this.notCredit = false;
            this.error = false;
            this.reportListo = true;
          } else {
            switch (data.reason) {
              case 9:
                this.notCredit = false;
                this.bandera = false;
                this.error = true;
                this.reportListo = false;
            }
          }
          if (this.debts.length === 0) {
            this.notCredit = true;
            this.bandera = false;
            this.error = false;
            this.reportListo = false;
          }
        },
        (er) => {
          console.log(er);
        }
      );
    },
    formatAmountString(v) {
      return v
        .toFixed(2)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    doAjax() {
      this.$showLoader(true);
      setTimeout(() => {
        this.$showLoader(false);
        this.getCreditReport();
        this.m_calculateDiscount();
      }, 10);
    },
  },
};
</script>
<style>
.v-progress-circular {
  margin: 1rem;
}

.margin-yellow {
  margin-top: 3rem;
}

.margin-yellow-h6 {
  margin-top: 1.5rem;
}

.margin-blue {
  margin-top: 3.5rem;
  margin-bottom: 4rem;
}

.margin-blue-h6 {
  margin-top: 1.5rem;
  margin-bottom: 4rem;
}

@media (min-width: 300px) {
  .alerta {
    margin: 5%;
    padding: 6%;
  }
  .subir {
    margin-top: -23rem;
  }
  .tam {
    height: 31rem;
  }
  .card-debts {
    margin-bottom: -2rem;
  }
  .img-deb {
    margin-top: 1rem;
    width: 40%;
    height: 40%;
    margin-left: 5rem;
  }
  .margin-yellow-h6 {
    margin-left: 1rem;
    margin-top: 6rem;
  }
  .margin-blue-h6 {
    margin-left: 1rem;
    margin-top: 2rem;
  }
}
@media (min-width: 370px) {
  .card-debts {
    margin-bottom: 1rem;
  }
  .tam {
    height: 34rem;
  }
  .img-deb {
    margin-top: 1rem;
    width: 50%;
    height: 50%;
    margin-left: 5rem;
  }
  .margin-yellow-h6 {
    margin-left: 1rem;
    margin-top: 6rem;
  }
  .margin-blue-h6 {
    margin-left: 1rem;
    margin-top: 2rem;
  }
}

@media (min-width: 420px) {
  .card-debts {
    margin-bottom: 1rem;
  }
  .tam {
    height: 35rem;
  }
  .img-deb {
    margin-top: 1rem;
    width: 50%;
    height: 50%;
    margin-left: 5.5rem;
  }
}

@media (min-width: 768px) {
  .calcular {
    margin: 2%;
    padding: 3%;
  }
  .subir {
    margin-top: -27rem;
  }
  .tam {
    height: 27rem;
  }
  .margin-yellow {
    margin-left: 6.5rem;
    width: 10%;
  }
  .margin-yellow-h6 {
    margin-left: 1rem;
    margin-top: 6rem;
  }
  .margin-blue-h6 {
    margin-left: 1rem;
    margin-top: 2rem;
  }

  .margin-blue {
    margin-left: 6.5rem;
  }
  .card-debts {
    margin-bottom: 1rem;
  }
  .img-deb {
    margin-top: 1rem;
    width: 60%;
    height: 60%;
    margin-left: 4rem;
  }
}

@media (min-width: 992px) {
  .calcular {
    margin-left: 70%;
  }
  .subir {
    margin-top: -40rem;
  }
}
@media (min-width: 1000px) {
  .margin-yellow {
    margin-top: 8rem;
    margin-left: -1rem;
  }
  .tam {
    height: 23rem;
  }
  .margin-yellow-h6 {
    margin-top: 6.5rem;
  }
  .img-deb {
    margin-top: 0.5rem;
    width: 100%;
    height: 100%;
    margin-left: 0.5rem;
  }

  .margin-blue {
    margin-top: 3.5rem;
    margin-bottom: 4rem;
    margin-left: -1rem;
  }

  .margin-blue-h6 {
    margin-top: 1.5rem;
    margin-bottom: 4rem;
  }
}
@media (min-width: 1200px) {
  .calcular {
    margin-left: 70%;
  }
  .tam {
    height: 24rem;
  }
}
</style>
