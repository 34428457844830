export let V = Object.freeze({
    //SERVER: "http://localhost:1025", // LOCAL
    //SERVER: "https://y0brxipd2d.execute-api.us-west-1.amazonaws.com/latest", // TEST
    SERVER: "https://8upvsokftb.execute-api.us-west-1.amazonaws.com/latest", // PROD
    CURRENT_VERSION: "60.1",
    STORE_KEYS: {
        GENERAL_INFO: "generalInfo",
        BANKS: "banks",
        SESSION: "session",
        USER: "user",
        SET_SHOW_LOADER: "setShowLoader"
    },
    PREREGISTER: {
        UPLOAD_FILE: '/pub/getOcrUploadUrl',
        PREUSER_OCR: '/pub/preUserOCR',
        PREUSER_OCR_IDB: '/pub/preUserOCRIDB',


        GET_OCR_DATA: '/pub/getOCRData',
        UPLOAD_FILE_TMP: '/pub/getOcrUploadUrlTMP',
        CREATE_NEW_USER: '/pub/createNewUser',
        REQUEST_CREDIT_REPORT_PIN: '/pub/requestCreditReportPin',
        VALIDATE_CREDIT_REPORT_PIN: '/pub/validateCreditReportPin',
        REQUEST_CREDIT_REPORT: '/pub/requestCreditReport',
    },
    CREDITS:
        {
            SET_CREDITS_USERS: '/dbm/setCreditsUsers',
        },
    USER_OPS:
        {
            UPLOAD_URL: '/user/uploadUrl',
            ANALYZE_OCR: '/user/analyzeOCR',
        },
    OPS: {
        /* ENDPOINTS /pub/ */
        PREREGISTER: "/pub/preregister",
        VALIDATE_PREREGISTER: "/pub/validatePreregister",
        REGISTER: "/pub/register",
        GENERATE_PROGRAM_CALCULATOR: '/pub/generateProgramCalculator',

        /* ENDPOINTS /dbm/ */
        VALIDATE_TOKEN_MATI: '/dbm/validateTokenMATI',
        VALIDATE_TOKEN_PAYMENT: '/dbm/validateTokenPayment',
        EDIT_PAYMENT_USER: '/dbm/editPaymentUser',
        INSERT: "/dbm/insert/",
        UPDATE: "/dbm/update/",
        SELECT: "/dbm/select/",
        DELETE: "/dbm/delete/",
        CALCULATE: "/dbm/calculate",
        UPLOAD_PAYMENT: "/dbm/uploadpayment",
        UPLOAD_FILE: "/dbm/uploadfile",
        GET_USER_FILE: "/dbm/getfile",
        VALIDATE_SESSION: "/dbm/validatesess",
        GET_MX_STATES: "/dbm/getstates",
        GET_RFC_CURP: "/dbm/getrfccurp",
        GET_POSTAL_CODE: "/dbm/getpostalcode",
        VALIDATE_TOKEN: "/dbm/validateToken",
        SET_LETTER_DBM_SIGNATURE: '/dbm/setLetterDBMSignature',
        UPDATE_SIGNATURE: "/dbm/updateSignature",
        VALIDATE_LEAD_SMS_CODE: "/dbm/validate_lead_sms_code",
        SET_ACCOUNT_SIGNATURE: "/dbm/setAccountSignature",
        SET_CREDIT_REPORT_SIGNATURE: "/dbm/setCreditReportSignature",
        GET_FILE_URL: '/dbm/getsignedurl',
        GET_PROFILE_PICTURE_URL: "/dbm/getProfilepictureUrl",
        UPLOAD_PAYMENT_MATI: '/dbm/uploadPaymentMati',
        REQUEST_FORGOTTEN_PASSWORD_CODE: '/dbm/requestForgottenPasswordCode',
        VALIDATE_SMS_CODE: '/dbm/validate_sms_code',
        SET_ADDENDUM_SIGNATURE: '/dbm/setAddendumSignature',


        /* ENDPOINTS /api/ */
        URL_SHORT_PROPUESTA: '/api/urlshortener',
        GET_FILE_URL_API: '/api/getsignedurl',
        GET_FILES_USER_RISK: '/api/getFilesUserRisk',
        GET_USER_FILE_CONTRACT_RISK: '/api/getUserFileContractRisk',
        GET_USER_FILE_GUIDE_RISK: '/api/getUserFileGuideRisk',
        UPLOAD_FILE_CONTRACT_HIGH_RISK: '/api/uploadFileContractRisk',
        GET_USER_QUESTION_RISK: '/api/getUserQuestionRisk',
        VERIFICATIONS_UPLOAD_FILES: '/api/verificationUploadFile',
        URLS_FILES_CONTRACT_HIGH_RISK: '/api/urlsFilesHighRisk',
        SET_QUESTIONS_CONTRACT: '/api/setQuestionsContract',
        GET_USER_FILE_ADD: '/api/getUserFileAdd',
        OUT_TIME_ADD: '/api/outTimeAdd',
        CANCEL_ADDENDUMS: '/api/cancelAddendums',
        GET_BLOG_ENTRIES: "/api/blogEntries",
        GET_BLOG_ENTRY: "/api/blogEntry",
        GET_BLOG_ENTRY_WEBINAR: '/api/blogEntryWebinar',
        GET_BLOG_WEBINAR: '/api/blogWebinar',
        SET_MARKETING_DOWNLOAD_APPS: '/api/setMarketingDownloadApps',
        UPDATE_MARKETING_DOWNLOAD_APPS: '/api/updateMarketingDownloadApps',
        SET_MARKETING_MATI: '/api/setMarketingMati',
        UPDATE_MARKETING_MATI: '/api/updateMarketingMati',
        SET_MARKETING_LANMOBILES: '/api/setMarketingLanMobiles',


        /* ENDPOINTS /usr/ */
        LOGIN: "/usr/login",
        SIGNUP: "/usr/signup",
        LINK_ACCOUNT: "/usr/linkacc",
        NEW_LOGIN: '/usr/newLogin',
        CHECK_USER_LOGIN: '/usr/checkUserLogin',

        /* ENDPOINTS /user/ */
        GET_BENEFICIARY: '/user/getBeneficiary',
        SET_REPORT_SIGNATURE: "/user/setCreditReportSignature",
        SET_CONTRACT_SIGNATURE: "/user/setContractSignature",
        SET_CONTRACT_AND_ACCOUNTS: "/user/createContractAAccounts",
        SET_PROGRAM_CALCULATOR_CR: '/user/setProgramCalulatorCr',
        GENERATE_PROGRAM_CALCULATION: '/user/generateProgramCalculation',
        SET_FCM_TOKEN: "/user/setFCMToken",
        GET_UPLOAD_FILE_URL: "/user/getUploadFileUrl",
        GET_CONSULTANT_INFO: "/user/getConsultantInfo",
        REQUEST_SIGNATURE_TOKEN: "/user/requestSignatureUrl",
        SET_BENEFICIARY: "/user/setBeneficiary",
        GET_PROFILE: "/user/getProfile",
        SET_PROGRAM_CALCULATION: "/user/setProgramCalulation",
        GET_PROGRAM_CALCULATION: "/user/getProgramCalulation",
        GET_SIGNATURE_INFO: "/user/getSignatureInfo",
        GET_CREDIT_REPORT: "/user/getCreditReport",
        REQUEST_CR_PIN: '/user/requestCRPin',
        VALIDATE_CR_PIN: '/user/validateCRPin',
        GET_CR_ACCOUNTS: '/user/getCRAccounts',
        SET_PASSWORD: '/user/setPassword',
        CREATE_PRE_CONTRACT: '/user/createPreContract',
        GET_PROGRAM_STEP: "/user/programStep",
        VERIFICATION_FINAMIGO_ACCOUNT: '/user/verificationFinamigoAccount',
        //SET_BENEFICIARY: '/user/setBeneficiary',
    },
    TABLES: {
        ACCOUNTS: "accounts",
        ACCOUNTS_TRACE_STATUS: "account_trace_status",
        ACCOUNTS_TRACING: "account_tracing",
        BANKS: "banks",
        BANKS_LANDINGS: 'banks_landings',
        ECONOMIC_ACTIVITIES: "economic_activities",
        CLIENTS: "clients",
        DEFS: "defs",
        FINAMIGO: "finamigo",
        FUTURE_ACCOUNTS: "future_accounts",
        LEADS: "leads",
        LEADS_STATUS: "leads_status",
        USER_TRACE_STATUS: "user_trace_status",
        USER_TRACING: "user_tracing",
        MARKETING_LEADS: "marketing_leads",
        PAYMENTS: "payments",
        COMMISSIONS: "commissions",
        SESSIONS: "sessions",
        USER_FILES: "user_files",
        USERS: "users",
        USER_FILES_CONTRACT: 'user_files_contract',
        RELATION_BENEFICIARY: 'relation_beneficiary',
    },
    VIEWS: {
        PROFILE: "profile"
    },
    ROUTES: {
        PROFILE: "/profile"
    },
    API: {
        SUCCESS: "success",
        ROWS: "rows",
        ROW: "row",
        REASON: "reason",
    },
    TERMS_CONDITIONS: "https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf",
    PRIVACY_NOTICE: "https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf",
    FACEBOOK_PIXEL_ID: "175290776628728"
})