<template>
    <div>
        <section class="section-shaped my-0" style="background-color:#3953D3;">
            <div class="shape shape-style-1 shape-default shape-skew">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <div  class="row justify-content-center">
            <div class="container shape-container justify-content-center">
            <card  v-if="token"  class="col-lg-12" style="margin-top:2rem; border-radius:1rem; height: 95%;">
                <div class="text-center" style="margin-bottom:2rem;">
                <img src="https://dbmenos.com/img/brand/DB Menos logo 2020_membrete.png"/>
                </div>
                <div class="row justify-content-center  mb-lg">
                    <div class="col-lg-12">
                        <div class="row">
                        <div class="col">
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="Nacionalidad:"
                        input-classes="form-control-alternative"
                        v-model="questions.nacionality"/>
                        </div>
                        <div class="col">
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="País de Nacimiento:"
                        input-classes="form-control-alternative"
                        v-model="questions.country"/>
                        </div>
                        </div>
                        <label>En caso de ser “CASADO”:</label>
                        <br>
                        <label>1. Tipo de régimen conyugal:</label>
                        <div class="row" style="margin-top:1rem;">
                        <div class="col">
                        <b-form-checkbox v-model="questions.matrimonial_regime" value="Individual">Individual</b-form-checkbox>
                        </div>
                        <div class="col">
                        <b-form-checkbox v-model="questions.matrimonial_regime" value="Solidaria">Solidaria</b-form-checkbox>
                        </div>
                        <div class="col">
                        <b-form-checkbox v-model="questions.matrimonial_regime" value="Mancomunada">Mancomunada</b-form-checkbox>
                        </div>
                        </div>
                        <label style="margin-top:1rem;">En caso de ser “INDEPENDIENTE”:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="2. Giro de Negocio:"
                        input-classes="form-control-alternative"
                        v-model="questions.line_business"/>
                        <label>En caso de ser “EMPLEADO”:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="3. Nombre de empresa:"
                        input-classes="form-control-alternative"
                        v-model="questions.company_name"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="4. Puesto que ocupa"
                        input-classes="form-control-alternative"
                        v-model="questions.company_position"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="5. ¿Que parentesco tiene con el Beneficiario (a)?"
                        input-classes="form-control-alternative"
                        v-model="questions.relationship"/>
                         <label>*Persona Políticamente Expuesta: Personas que desempeñan o han desempeñado funciones públicas destacadas (ejemplo: jefes de estado, políticos de alta jerarquía, funcionarios gubernamentales, judiciales o militares de alta jerarquía, altos ejecutivos de empresas estatales o funcionarios importantes de partidos políticos).</label>
                        <label>6. ¿Desempeña o ha desempeñado algún cargo público Nacional o Extranjero? </label>
                        <b-form-checkbox v-model="questions.public_office" value="1">SI</b-form-checkbox>
                        <b-form-checkbox v-model="questions.public_office" value="0">NO</b-form-checkbox>
                        <div v-if="questions.public_office  == 1">
                        <label>Si su respuesta es “SI” especifique:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="7. Dependencia "
                        input-classes="form-control-alternative"
                        v-model="questions.public_office_dependencies"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="8. Cargo "
                        input-classes="form-control-alternative"
                        v-model="questions.public_office_position"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="9-. Período "
                        input-classes="form-control-alternative"
                        v-model="questions.public_office_period"/>
                        <label>10. ¿Está o estuvo en algún proceso de elección para ocupar el cargo público?</label>
                        <b-form-checkbox v-model="questions.pub_office_choice" value="1">SI</b-form-checkbox>
                        <b-form-checkbox v-model="questions.pub_office_choice" value="0">NO</b-form-checkbox>
                        </div>
                        <div v-if="questions.pub_office_choice  == 1 && questions.public_office  == 1">
                        <label>Si su respuesta es “SI” especifique el Cago y el Periodo:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="11. Domicilio Principal: Especifique las Características Principales del domicilio principal (ej. color de fachada, entre calles, es una privada) "
                        input-classes="form-control-alternative"
                        v-model="questions.pub_office_c_addprincipal"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="12. Domicilio de su trabajo "
                        input-classes="form-control-alternative"
                        v-model="questions.pub_office_c_addrwork"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="13. Antigüedad "
                        input-classes="form-control-alternative"
                        v-model="questions.pub_office_c_antiquity"/>
                         <base-input
                        alternative
                        style="font-size:20px;"
                        label="14. Nombre de su cónyuge y/o dependientes económicos, con fechas de nacimiento y parentesco. "
                        input-classes="form-control-alternative"
                        v-model="questions.pubOff_data_matrimonial"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="15. Ingreso Mensual "
                        input-classes="form-control-alternative"
                        v-model="questions.office_ingress_mensual"/>
                        </div>
                        <label>16 -. Tiene algún tipo de parentesco con alguna persona que desempeñe o ha desempeñado algún tipo de Cargo público nacional o extranjero?</label>
                        <b-form-checkbox v-model="questions.relationship_public" value="1">SI</b-form-checkbox>
                        <b-form-checkbox v-model="questions.relationship_public" value="0">NO</b-form-checkbox>
                        <div v-if="questions.relationship_public == 1">
                        <label>Si su respuesta es “SI” especifique:</label>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="17. Nombre"
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_name"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="18. Cargo"
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_position"/>
                        <base-input
                        alternative
                        style="font-size:20px;"
                        label="19. Parentesco "
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_type"/>
                         <base-input
                        alternative
                        style="font-size:20px;"
                        label="20. Dependencia "
                        input-classes="form-control-alternative"
                        v-model="questions.relationship_public_dependece"/>
                        </div>
                        <div class="text-center">
                        <base-button
                        slot="title"
                        type="success"
                        class="btn text-center"
                        style="margin-top:1rem;"
                        v-on:click="sendQuestions"
                        size="lg">Enviar Cuestionario</base-button>
                        </div>
                    </div>
                </div>
            </card>
                <card class="col-lg-12" style="margin-top:2rem; border-radius:1rem; height: 80%;" v-if="!token && !existQuestion">
                <div class="text-center" style=" margin-top:10rem; margin-bottom:10rem;">
                <h5 style="font-size:25px;">No logramos validar tu enlace, comúnicate con tu asesor</h5>
                <img src="https://dbmenos.com/img/theme/close-circle.png"></div>
                </card>
                 <card class="col-lg-12" style="margin-top:2rem; border-radius:1rem; height: 80%;" v-if="!token && existQuestion">
                <div class="text-center" style=" margin-top:10rem; margin-bottom:10rem;">
                <h5 style="font-size:25px;">Ya hemos recibido tu cuestionario, en unos segundo nos comunicaremos contigo.</h5>
                <img src="https://dbmenos.com/img/theme/checkbox-marked-circle.png"></div>
                </card>
                    </div>
            </div>
        </section>
       
    </div>
</template>

<script>
    import Vue from "vue";
    import modal from "@/components/Modal"
    import {V} from "@/V"
    import BaseInput from '../components/BaseInput.vue';
import Card from '../components/Card.vue';
    export default {
        name: "home",
        components: {
            modal,
            BaseInput,
                Card
        },
        created() {
            this.getDataUser()
        },
        data() {
            return {
                V: V,
                token:false,
                existQuestion:false,
                simpleCalc: {
                    amount: 10,
                    banks: []
                },
                questions:{
                    token:"",
                    phone:"",
                    country:"",
                    nacionality:"",
                    matrimonial_regime:"",
                    line_business:"",
                    company_name:"",
                    company_position:"",
                    relationship:"",
                    public_office:"",
                    public_office_dependencies:"",
                    public_office_position:"",
                    public_office_period:"",
                    pub_office_choice:"",
                    pub_office_c_addprincipal:"",
                    pub_office_c_addrwork:"",
                    pub_office_c_antiquity:"",
                    pubOff_data_matrimonial:"",
                    office_ingress_mensual:"",
                    relationship_public:"",
                    relationship_public_name:"",
                    relationship_position:"",
                    relationship_public_type:"",
                    relationship_public_dependece:""
                },
                banks: [],
                SERVER: "https://s3.amazonaws.com/dbmenos.frontend/",
                leadModal: false,
                estimatedDiscountModal: false,
                averageDiscount: 0,
                user:{},
            }
        },
        methods: {
            sendQuestions() {
                if(!this.questions.phone){
                    this.$notify({
                        text: "Falta Ingresar Número de Telefono",
                        type: "error",
                    });
                }else{
                this.$showLoader(true);
                this.questions.token = this.$route.params['pathMatch'];
                let url = V.SERVER + V.OPS.SET_QUESTIONS_CONTRACT;
                this.$axios.post(url,this.questions).then(async (res) => {
                    this.$showLoader(false);
                    if (res.data.success) {
                        this.$showLoader(false);
                        this.$notify({
                            text: "Cuestionario Guardado Correctamente",
                            type: "success",
                        });
                        window.location.reload();
                     } 
                });
                }
            },
            getDataUser() {
                this.$showLoader(true);
                let url = V.SERVER + V.OPS.GET_USER_QUESTION_RISK+ "?token=" +this.$route.params['pathMatch'];
                this.$axios.get(url).then(async (res) => {
                    this.$showLoader(false);
                    let data = res.data;
                    if (res.data.success) {
                        this.token = true;
                        this.questions.phone = data.row.phone;
                        this.$showLoader(false);
                    } else {
              switch (res.data.reason) {
                case 5:
                  this.existQuestion=true;
                  this.token=false;
                  break;
                default:
                  this.token=false;
                  this.existQuestion=false;
                  break;
              }
            }
                });
            }
        }
    };
</script>
