<template>
    <div>
         <div  v-if="whoMarketing == 'coru_web_202203'" >
        <img :src="urlPixel" width="1" height="1"/>
        </div>
        <div class="position-relative" v-if="!varificationAccount && !status">
            <!-- shape Hero -->
            <section class="section-shaped my-0" style="background-color:#ffff;">
                <div class="shape shape-style-1 shape-default shape-skew"></div>
                <div class="container shape-container d-flex" style=" margin:-10rem;"></div>
            </section>
            <section
                class="section section-lg pt-lg-0 mt--300 subir"
                style="background-color:#ffff;"
            >
                <div class="container text-center">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-12 text-center">
                            <div class="row row-grid justify-content-center text-center">
                                <b-card style="border-radius:2rem; border:1px solid" class="border-1">
                                    <br />
                                    <img style="width:60%;" class="imgApple" src="img/brand/DB Menos logo 2020_membrete.png" />
                                        <p
                                        class="h1" style="margin-bottom:50px; color:blue; font-size:2rem; margin-top:30px;"
                                    >Por disposición oficial debes aperturar una cuenta donde harás tus ahorros. 
                                    Una vez que hayas aperturado tu cuenta regresa aquí y para vivir libre de deudas.</p>
                                    <base-button
                                    block
                                        v-on:click="openFA"
                                        style="background-color:#FFD527; border-color:#FFD527; color:#3953D3;"
                                    >
                                        <b>Aperturar cuenta</b>
                                    </base-button>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="position-relative" v-else-if="!varificationAccount && status">
            <!-- shape Hero -->
            <section class="section-shaped my-0" style="background-color:#ffff;">
                <div class="shape shape-style-1 shape-default shape-skew"></div>
                <div class="container shape-container d-flex" style=" margin:-10rem;"></div>
            </section>
            <section
                class="section section-lg pt-lg-0 mt--300 subir"
                style="background-color:#ffff;"
            >
                <div class="container text-center">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-12 text-center">
                            <div class="row row-grid justify-content-center text-center">
                                <b-card style="border-radius:2rem; border:1px solid" class="border-1">
                                    <br />
                                    <img style="width:60%;" class="imgApple" src="img/brand/DB Menos logo 2020_membrete.png" />
                                        <p
                                        class="h1" style="margin-bottom:50px; color:blue; font-size:2rem; margin-top:30px;"
                                    >Estamos buscando tu cuenta, en poco tiempo harás tu primer ahorro y comenzarás a vivir sin deudas.</p>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="position-relative" v-else-if="varificationAccount && status">
            <!-- shape Hero -->
            <section class="section-shaped my-0" style="background-color:#ffff;">
                <div class="shape shape-style-1 shape-default shape-skew"></div>
                <div class="container shape-container d-flex" style=" margin:-10rem;"></div>
            </section>
            <section
                class="section section-lg pt-lg-0 mt--300 subir"
                style="background-color:#ffff;"
            >
                <div class="container text-center">
                    <div class="row justify-content-center text-center">
                        <div class="col-lg-12 text-center">
                            <div class="row row-grid justify-content-center text-center">
                                <b-card style="border-radius:2rem; border:1px solid" class="border-1">
                                    <br />
                                    <img style="width:60%;" class="imgApple" src="img/brand/DB Menos logo 2020_membrete.png" />
                                    <br />
                                    <br />
                                    <h1 class="display-2" style="margin-top: 10px; color:#5f6062">
                                        Hola <b style="color:#2a3d9a">{{client.name}}</b>
                                    </h1>
                                    <h1
                                        class="display-3" style="color:#5f6062"
                                    >{{welcome}} a la revolución contra las deudas</h1>
                                    <br />
                                    <p
                                        class="h1" style="margin-bottom:50px; color:#2a3d9a; font-size:4rem;"
                                    >¡Haz tu primer ahorro!</p>
                                    <div v-if="(client.monthly_savings * client.program_duration) >= 3000">
                                    <p
                                        class="h1" style="margin-top:-50px; color:#5f6062; font-size:2.5rem;"
                                    >Con nuestro programa ahorrarás</p>
                                    <card style="border:none;">
                                        <p class="h1" style="margin-top:-30px; color:#2a3d9a; margin-bottom:-20px; 
                                        font-size:4rem;">$ {{Math.ceil(client.monthly_savings * client.program_duration).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</p>
                                    </card>
                                    </div>
                                    <div v-if="client.bbva == 'En proceso' && client.clabe == 'En proceso'">
                                        <p
                                        class="h1" style="margin-bottom:50px; color:blue; font-size:2rem; margin-top:30px;"
                                    >Por el momento se estan generando tus cuentas para poder realizar tu depósito, en cuanto esten generadas te avisaremos.</p>
                                    </div>
                                    <div v-if="client.bbva != 'En proceso' || client.clabe !='En proceso'">
                                        <!--
                                        <br />
                                        <h1 class="display-2">ó</h1>
                                        <br />
                                        !-->
                                        <b-card
                                            no-body
                                            class="overflow-hidden"
                                            style="max-width: 100%; border:none;"
                                             v-if="transfer || otherOptionP"
                                        >
                                            <b-row no-gutters>
                                                <b-col class="acomodar" md="6">
                                                    <b-card-body style="margin-top:8%;"
                                                    >
                                                    <h1 class="h2" style="font-size:1.9rem; margin-top: 10px; color:#2a3d9a">Transferencia interbancaria</h1>
                                                        <b-card-text>
                                                            <div>
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.2rem; margin-top: 10px; color:#5f6062"
                                                                >Deposita en tu cuenta </span>
                                                                <span>
                                                                    <b v-if="client.clabe" style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> {{client.clabe != 'En proceso' ? client.clabe : '012180001105538407'}}</b>
                                                                    <b v-else style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> {{finamigo.clabe != 'En proceso' ? finamigo.clabe : '012180001105538407'}}</b>
                                                                </span>
                                                                <!-- ? client.clabe : '012180001105538407'!-->
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.2rem; margin-top: 10px; color:#5f6062"
                                                                > a nombre de </span>
                                                                <span>
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> {{client.clabe == 'En proceso' ? 'Financiera Mexicana para el Desarrollo Rural' : client.name+' '+client.lastname_1+' '+client.lastname_2}}</b>
                                                                </span>
                                                                <span v-if="client.clabe == 'En proceso'" style="font-size:1.2rem; margin-top: 10px; color:#5f6062"> Anota la referencia
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a">{{this.client.bbva ? this.client.bbva : this.finamigo.bbva}}</b>
                                                                </span>
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.2rem; margin-top: 10px; color:#5f6062"
                                                                > depositando la cantidad de </span>
                                                                <span>
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> ${{this.client.monthly_savings}}</b>
                                                                </span>
                                                                <!--
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.2rem; margin-top: 10px; color:#5f6062"
                                                                > con tu referencia</span>
                                                                <span>
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> {{this.client.bbva}}</b>
                                                                </span>
                                                                !-->
                                                            </div>
                                                            <br>
                                                            <strong v-if="!otherOptionT && !notTransfer" style="color:blue" @click="notTransfer = true"><b>No puedo hacer transferencias</b></strong>
                                                        <!--<base-button
                                                            block
                                                            @click="transfer = false"
                                                            type="primary"
                                                            class="mt-4"
                                                        >
                                                            <b>No Puedo Hacer Transferencias</b>
                                                        </base-button>
                                                        !-->
                                                        </b-card-text>
                                                    </b-card-body>
                                                </b-col>
                                                <b-col md="6" class="foto">
                                                    <b-card-img
                                                        src="img/trans.png"
                                                        alt="Image"
                                                        img-right
                                                        class="imgApple rounded-0"
                                                    ></b-card-img>
                                                </b-col>
                                            </b-row>
                                        </b-card>

                                        <br />
                                        <b-card
                                            no-body
                                            class="overflow-hidden"
                                            style="max-width: 100%; border:none;"
                                             v-if="notTransfer || otherOptionT"
                                        >
                                            <b-row no-gutters>
                                                <b-col md="6">
                                                    <b-card-img
                                                        src="img/practicaja.jpg"
                                                        alt="Image"
                                                        style="max-height:25rem; margin-top:3rem;"
                                                        class="rounded-0"
                                                    ></b-card-img>
                                                </b-col>
                                                <b-col class="acomodar" md="6">
                                                    <b-card-body
                                                    >
                                                    <h1 class="h2" style="font-size:1.8rem; color:#2a3d9a;">Depositar en practicaja BBVA</h1>
                                                        <b-card-text>
                                                            <div>
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.3rem; margin-top: 10px; color:#5f6062"
                                                                >1. Pago de servicios</span>
                                                                <br>
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.3rem; margin-top: 10px; color:#5f6062"
                                                                >2. Ir a opción</span>
                                                                <span>
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> Otro</b>
                                                                </span>
                                                                <br>
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.3rem; margin-top: 10px; color:#5f6062"
                                                                >3. Anotar número de Convenio</span>
                                                                <span>
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> 1436295</b>
                                                                </span>
                                                                <br>
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.3rem; margin-top: 10px; color:#5f6062"
                                                                >4. Teclear la referencia </span>
                                                                <span>
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> {{this.client.bbva ? this.client.bbva : this.finamigo.bbva}}</b>
                                                                </span>
                                                                 <br>
                                                                <span
                                                                    class="font-weight-light"
                                                                    style="font-size:1.3rem; margin-top: 10px; color:#5f6062"
                                                                >5. Ingresa la cantidad </span>
                                                                <span>
                                                                    <b style="font-size:1.5rem; margin-top: 10px; color:#2a3d9a"> ${{this.client.monthly_savings}}</b>
                                                                </span>
                                                            </div>
                                                            <br>
                                                            <strong v-if="!otherOptionP && !transfer" style="color:blue" @click="transfer = true"><b>Quiero hacer una transferencia</b></strong>
                                                        <!--<base-button
                                                            block
                                                            @click="transfer = true"
                                                            type="primary"
                                                            class="mt-4"
                                                        >
                                                            <b>Quiero hacer una transferencia</b>
                                                        </base-button>
                                                        !-->
                                                             
                                                        </b-card-text>
                                                    </b-card-body>
                                                </b-col>
                                            </b-row>
                                        </b-card>
                                    </div>
                                    <base-button
                                        block
                                        v-on:click="checkStatus"
                                        style="background-color:#FFD527; border-color:#FFD527; color:#3953D3;"
                                        class="mt-4"
                                    >
                                        <b>Aceptar</b>
                                    </base-button>
                                </b-card>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import BaseDropdown from "../components/BaseDropdown";
import UploadFilesForm from "@/views/components/UploadFilesForm.vue";
import Dropbox from "../components/Dropbox";
import moment, { max } from "moment";
import { V } from "../V";

export default {
    data() {
        return {
            client: {},
            finamigo: {},
            day: "",
            month: "",
            year: "",
            urlAsesor: "",
            phone: "",
            welcome:"",
            transfer:false,
            otherOptionT:false,
            notTransfer:false,
            otherOptionP:false,
            varificationAccount:false,
            status:false,
            urlPixel: null,
            whoMarketing: null,
            dataCal: null,
        };
    },
    watch: {
        user(val) {
            if (val) {
                this.client;
                //this.getAsesor();
                this.getStatusAccount();
            }
        }
    },
    created() {
        if (this.client) {
            this.getClientProfile();
            this.getStatusAccount();
        } else {
            this.$router.push("/register");
        }
        this.whoMarketing=localStorage.getItem('marketing');
        this.dataCal = JSON.parse(localStorage.getItem("calculate"));
        if(this.whoMarketing == 'coru_web_202203' ){
        this.urlPixel='https://api.coru.com/p/analytics/dbmenos.gif?event=firmaelectronica&pixelID=[idCoru]&step=&alfa=&beta='+((this.dataCal.estimated)*100);
        }
    },

    methods: {
        formatDecPost(val, pos) {
      if (!pos) pos = 2;
      if (val && Number(val)) return Number(val).toFixed(pos);
      return "0.00";
    },
    openFA() {
      window.open('https://cuenta.finamigodigital.com.mx/');
    },
    createContract() {
            let url = V.SERVER + V.OPS.CREATE_PRE_CONTRACT
            this.$axios.get(url).then(response => {
             let data = response.data;
                if (data[V.API.SUCCESS]) {
                        this.urlContract = data.row.url;
                    }
                });
        },
        getAsesor() {
            let url = this.$v.SERVER + this.$v.OPS.GET_CONSULTANT_INFO;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[this.$v.API.SUCCESS]) {
                    this.phone = data.row.phone;
                    this.urlAsesor = "https://wa.me/52" + this.phone;
                    // window.open(this.urlAsesor);
                }
            });
        },
        getStatusAccount() {
            let url = this.$v.SERVER + this.$v.OPS.VERIFICATION_FINAMIGO_ACCOUNT;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[this.$v.API.SUCCESS]) {
                    if(data.rows.status==1){
                        this.varificationAccount = true;
                        this.status = true;
                        this.finamigo=data.rows;
                        this.createContract();
                    }else if(data.rows.status != 1){
                        this.status = true;
                        this.varificationAccount=false;
                    }else{
                        this.varificationAccount=false;
                        this.status = false;
                    }
                }
            });
        },
        formatDate(date) {
            return moment(date, "YYYY-MM-DD").format("DD/MM/YYYY");
        },
        checkStatus() {
                        this.$router.push({
                            path: "/profile"
                        });
        },
        getClientProfile() {
            let url =
                this.$v.SERVER +
                this.$v.OPS.GET_PROFILE;
            this.$axios.get(url).then(response => {
                let data = response.data;
                if (data[this.$v.API.SUCCESS]) {
                    this.client = response.data[this.$v.API.ROW];
                    if(this.client.clabe != 'En proceso') this.transfer = true;
                    if(this.client.clabe == 'En proceso') this.notTransfer=true;
                    if(this.client.gender == 'F'){
                        this.welcome = 'Bienvenida'
                    }else{
                        this.welcome='Bienvenido'
                    }
                }
            });
        }
    },
    components: {
        flatPickr,
        BaseDropdown,
        UploadFilesForm,
        Dropbox
    }
};
</script>
<style>
@media (min-width: 300px) {
    .imgGoogle {
        width: 70%;
    }
    .subir {
        margin-top: -23rem;
    }
    .imgApple {
        width: 60%;
    }
    .acomodar {
        margin-top: -1rem;
    }
}

@media (min-width: 768px) {
    .imgGoogle {
        width: 60%;
    }
    .imgApple {
        width: 50%;
    }
    .subir {
        margin-top: -27rem;
    }
    .foto {
        margin-top: 5rem;
    }
}

@media (min-width: 992px) {
    .imgGoogle {
        width: 50%;
    }
    .imgApple {
        width: 40%;
    }
    .subir {
        margin-top: -40rem;
    }
    .foto {
        margin-top: 1rem;
    }
    .acomodar {
        margin-top: 4rem;
    }
}

@media (min-width: 1200px) {
    .imgGoogle {
        width: 30%;
    }
    .imgApple {
        width: 25%;
    }
}
</style>
