<template>
  <div >
    <div class="position-relative" >
      <!-- shape Hero -->
      <section class="section section-shaped section-sm my-0" style="background-color: #092699; background-size: cover;">
        <div class="shape shape-style-2 shape-default shape-skew" >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div
          class="text-center"
          style="
            margin-top: 5rem;
            padding: 1rem;
            background-color: #092699;
            color: white;
            text-transform: uppercase;
          "
        >
          <p style="margin-top: 2.5rem; font-size: 18px">
            Con<b> DB Menos </b> dile adiós a tus deudas
          </p>
          <router-link
            to="/"
            class="btn btn-info"
            style="border: none; font-size: 18px; margin-bottom: 2.5rem"
          >
            ¡Unirme ahora!
          </router-link>
        </div>
      </section>
      <div class="container" style="margin-top: 3rem; margin-bottom: 10rem">
        <div class="row justify-content-center" style="margin-top: 1rem">
          <div class="col-lg-12" v-if="find">
            <div class="text-center">
              <h1>{{ infos.title }}</h1>
              <br />
              <div class="text-left" v-html="infos.post" />
              <br />
              <div align="right">
                <span class="h6">{{ formatDate(infos.created) }}</span>
              </div>
              <br />
              <button class="btn btn-primary btn-lg btn-block" @click="back">
                Regresar
              </button>
            </div>
          </div>
          <div v-if="!find" class="col-lg-12">
            <div class="text-center">
              <h1>Blog no Encontrado</h1>
              <br />
              <button class="btn btn-primary btn-lg btn-block" @click="back">
                Regresar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import VueResource from "vue-resource";
import { V } from "@/V";
import VueSignaturePad from "vue-signature-pad";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import moment, { max } from "moment";
Vue.use(VueFacebookPixel);
export default {
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "DBMenos",
    // all titles will be injected into this template
    titleTemplate: "%s | Blog",
  },

  components: {
    VueFacebookPixel,
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    nombre_banco: function () {
      return this.getBank(this.account.bank).name;
    },
  },
  created() {
    //Vue.analytics.fbq.init(V.FACEBOOK_PIXEL_ID, {})
    this.getBlogEntry();
  },
  mounted() {
    this.idB = this.$route.query.id;
  },
  data() {
    return {
      infos: [],
      title: "Blog | DBMENOS",
      idB: "",
      name: "",
      titleB: "",
      descriptionB: "",
      fechaB: "",
      og_pictureB: "",
      postB: "",
      miniURL: "",
      find: true,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm");
    },
    back() {
      this.$router.push("/blogs");
    },
    async getBlogEntry() {
      console.log(this.$route.params)
      if (this.$route.query.id) {
          console.log('Entro')
        this.idB = this.$route.query.id;
        this.$showLoader(true);
        let url = V.SERVER + V.OPS.GET_BLOG_ENTRY + "?id=" + this.idB;
        let response = await this.$axios.get(url);
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.info = data.row;
          console.log(this.info)
          this.find = true;
          this.$forceUpdate();
        } else {
          switch (data.reason) {
            case 1:
              this.find = false;
          }
        }
      } else {
          this.$showLoader(true);
        this.name = this.$route.params['pathMatch'];
        console.log(this.name);
        if(this.name == "{{url}}"){
          this.find = false;
              this.$showLoader(false);
        }else{
        let url = V.SERVER + V.OPS.GET_BLOG_ENTRY + "?name=" + this.name;
        let response = await this.$axios.get(url);
        this.$showLoader(false);
        let data = response.data;
        if (data.success) {
          this.infos = data.row;
          this.find = true;
          this.$forceUpdate();
        } else {
          switch (data.reason) {
            case 1:
              this.find = false;
              this.$showLoader(false);
          }
        }
        }
      }
    },
  },
};
</script>
<style>
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
iframe {
  display: table;
  margin: 0 auto;
}
img {
  max-width: 90%;
  height: auto;
}
p {
  padding: 0 20px;
}
</style>
