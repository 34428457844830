<template>

  <div class="">

    <l-p-form version="v1"/>

    <our-clients/>

    <how-do-we-do-it/>

    <what-do-you-get/>

    <reviews/>

    <!--
    <social-media/>
    <hr>
    <aplications/>
    -->

  </div>

</template>

<script>

import LPForm from "@/views/components/landing_page/Principal";
import OurClients from "@/views/components/landing_page/OurClients";
import HowDoWeDoIt from "@/views/components/landing_page/HowDoWeDoIt";
import WhatDoYouGet from "@/views/components/landing_page/WhatDoYouGet";
import Reviews from "@/views/components/landing_page/Reviews";
import SocialMedia from "@/views/components/landing_page/SocialMedia";
import Aplications from "@/views/components/landing_page/Applications";

export default {
  name: "HomePage3",
  components: {
    Aplications,
    SocialMedia,
    Reviews,
    WhatDoYouGet,
    HowDoWeDoIt,
    OurClients,
    LPForm,
  },

};
</script>

<style scoped>

</style>
