<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto">

      <div class="col-12 col-sm-3 text-center text-sm-right">
          <img :src="images.key" alt="key" class="img-item">
      </div>

      <div class="col-12 col-sm-9">
        <p class="primary-text">¿Qué es una reparadora de crédito?</p>
        <p class="secondary-text">
          Es una empresa que ofrece alternativas de apoyo para <br>
          liquidar y negociar deudas con un descuento. Una reparadora <br>
          funciona como intermediario entre el deudor y el acreedor.
        </p>
      </div>

    </div>

    <div class="row col-12 col-sm-10 mx-auto">

      <div class="col-12 col-sm-3 text-center text-sm-right ">
        <img :src="images.pig" alt="key" class="img-item">
      </div>

      <div class="col-12 col-sm-9">
        <p class="primary-text">Nuestro programa en 3 sencillos pasos</p>
        <ol>
          <li class="pb-3 secondary-text">Creamos un plan de ahorro acorde a tu situación.</li>
          <li class="pb-3 secondary-text">Negociamos hasta obtener el mejor descuento.</li>
          <li class="pb-3 secondary-text">Te ayudamos a reincorporarte al sistema financiero.</li>
        </ol>
      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "WhatIsIt",
  data: () =>
      ({
        images:
            {
              key: '/img/how_does_it_work/desktop/key.png',
              pig: '/img/how_does_it_work/desktop/pig.png'
            },
      })
}
</script>

<style scoped>

.bg-principal
{
  background-color: #2a3d99;
}

.primary-text
{
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

.secondary-text
{
  font-family: OpenSans-Regular;
  color: #FFFFFF;
}

ol
{
  list-style: none;
  font-weight: normal;
}

ol {
  counter-reset: item;
}

ol li:before {
  content: counter(item) ". ";
  counter-increment: item;
  font-weight: bold;
}

@media (min-width: 320px) and (max-width: 374px)
{
  .primary-text
  {
    font-size: 1rem;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .img-item
  {
    width: 8rem;
  }

  .secondary-text br
  {
    display: none;
  }
}
@media (min-width: 375px) and (max-width: 424px)
{
  .primary-text
  {
    font-size: 1rem;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .img-item
  {
    width: 8rem;
  }

  .secondary-text br
  {
    display: none;
  }
}
@media (min-width: 425px) and (max-width: 767px)
{
  .primary-text
  {
    font-size: 1rem;
    text-align: center;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .img-item
  {
    width: 8rem;
  }

  .secondary-text br
  {
    display: none;
  }
}
@media (min-width: 768px) and (max-width: 1023px)
{
  .primary-text
  {
    font-size: 1.2rem;
    text-align: center;
  }

  .secondary-text
  {
    font-size: 1rem;
  }

  .img-item
  {
    width: 12rem;
  }

  .secondary-text br
  {
    display: none;
  }
}
@media (min-width: 1024px) and (max-width: 1439px)
{
  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.2rem;
  }

  .img-item
  {
    width: 8rem;
  }
}
@media (min-width: 1440px) and (max-width: 2559px)
{
  .primary-text
  {
    font-size: 1.4rem;
  }

  .secondary-text
  {
    font-size: 1.2rem;
  }

  .img-item
  {
    width: 8rem;
  }
}
@media (min-width: 2560px) and (max-width: 2560px)
{
  .primary-text
  {
    font-size: 2rem;
  }

  .secondary-text
  {
    font-size: 1.8rem;
  }

  .img-item
  {
    width: 12rem;
  }
}

</style>