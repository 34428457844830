<template>

  <div>

    <principal/>

    <reviews/>

    <check-description/>

    <differences/>

    <references/>

    <frequent-questions/>

  </div>

</template>

<script>
import Principal from "@/views/components/financial_freedom/Principal";
import Reviews from "@/views/components/financial_freedom/Reviews";
import CheckDescription from "@/views/components/financial_freedom/CheckDescription";
import Differences from "@/views/components/financial_freedom/Differences";
import References from "@/views/components/financial_freedom/References";
import FrequentQuestions from "@/views/components/financial_freedom/FrequentQuestions";

export default {
  name: "FinancialFreedom",
  components: { FrequentQuestions, References, Differences, CheckDescription, Reviews, Principal }
}
</script>

<style scoped>

</style>