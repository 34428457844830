<template>
  <div>

    <section>

      <div class="d-block d-sm-none" style="background-color: #ebebeb;"
      >
        <section
            class="section section-xs"
            style="background-color: #ebebeb;"
        >
          <div
              class="container-fluid"
              id="main-container-hp"
          >
            <div class="row">
              <div class="col">
                <img
                    src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Chico Home-01.png"
                    id="guy-background-hp"
                />
                <div class="d-flex justify-content-end">
                  <div id="quotexs-hp">
                    <span
                        id="spanxs-hp"
                        style="font-size: 100%; align-items: center"
                    >
                      Liquida tus deudas con hasta
                    </span>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 40pt;
                        position: absolute;
                        margin-top: -1rem;
                      "
                    >
                      70<b style="font-size: 20pt">%</b>
                    </p>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 12pt;
                        position: absolute;
                        margin-top: 3rem;
                        line-height: 100%;
                      "
                    >
                      de descuento
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-calculator-hp"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="quote-calculator">
                    <span id="spanCalxs"> Calcula tu descuento </span>
                    <div
                        class="row justify-content-center"
                        style="align-items: center; padding: 1rem"
                    >
                      <p
                          class="speech-bubble-mobile"
                          style="margin-top: -0.5rem"
                      >
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                    <h2 id="hAmountxs" style="margin-top: -1.5rem; margin-bottom: -1rem">
                      ${{
                        simpleCalc.amount < 300
                            ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                                ? Number(simpleCalc.amount * 1000)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " ó menos"
                                : Number(simpleCalc.amount * 1000)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : Number(simpleCalc.amount * 1000)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                      }}
                    </h2>
                    <div
                        class="row justify-content-center"
                        style="align-items: center; padding: 8%;"
                    >
                      <vue-slider
                          :width="width"
                          :height="height"
                          style="animation:pulse2 2s infinite"
                          :min="30"
                          :max="300"
                          :step="5"
                          :tooltip="'none'"
                          :process-style="processStyle"
                          v-model.number="simpleCalc.amount"
                      >
                        <template v-slot:dot="{ focus }">
                          <div :class="['custom-dot', { focus }]"></div>
                        </template>
                      </vue-slider>
                    </div>
                    <p id="spanwhoxs" style="margin-top: -1rem">
                      <span>Selecciona a quién le debes: </span>
                    </p>
                    <div
                        class="row justify-content-center"
                        style="
                        padding: 1%;
                        margin-top: -8%;
                        margin-bottom: -3%;
                        align-items: center;
                      "
                    >
                      <div
                          class="col-md-3 col-sm-3 col-3 text-center"
                          style="align-items: center; margin-top: 1rem"
                          v-for="(bank, index) in banks"
                          :key="index"
                      >
                        <div
                            @click="m_switchSelected(index)"
                            style="margin-top: 10%"
                        >
                          <div class="g-item">
                            <img
                                :src="
                                bank.selected ? bank.url_y : bank.url_w_mobile
                              "
                                :title="bank.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p id="spaninstxs" style="margin-top: 1rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <base-button
                        v-on:click="m_startinglead()"
                        style="
                        background-color: #f3d338;
                        color: #2a3d9a;
                        border: none;
                        font-size: 100%;
                        font-family: Gotham-Black;
                      "
                        class="btndisxs"
                    >
                      Dame mi descuento
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-comments-hp">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgxs-hp"
                    src="img/icons/Iluestración Pasos.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteproxs">
                    <span id="spanproxs">
                      Nuestro programa<b id="spanprobxs">
                        en 3 simples pasos
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div id="quotethreexs">
                    <span id="spanaxs"
                    ><i class="fa fa-check" aria-hidden="true"></i> Asesoría
                      gratuita</span
                    >
                    <p id="paxs">
                      Analizamos tu caso y creamos un plan de ahorro y
                      liquidación de acuerdo a tu situación.
                    </p>
                    <span id="spanaxs">
                      <i class="fa fa-check" aria-hidden="true"></i> Negociamos
                      tu descuento</span
                    >
                    <p id="paxs">
                      Mientras ahorras mes con mes, negociaremos para obtener el
                      mejor descuento para ti.
                    </p>
                    <span id="spanaxs">
                      <i class="fa fa-check" aria-hidden="true"></i> Liquidamos
                      tus deudas</span
                    >
                    <p id="paxs">
                      Cuando alcances tu meta de ahorro, liquidaremos la deuda
                      con tu descuento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-questions-hp">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgxs-que-hp"
                    src="img/icons/Ilustracion Pregunta.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotequexs">
                    <span id="spanquexs">
                      ¿Qué necesito
                      <b id="spanquebxs">para solucionar mi deuda?</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div style="align-items: center">
                  <div
                      class="row"
                      style="
                      padding: 8%;
                      margin-left: 3%;
                      align-items: center;
                    "
                  >
                    <div class="col-12">
                      <span id="spanquexs-r"
                      ><i class="fa fa-user" aria-hidden="true"></i> Ser mayor
                        de
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >18 años</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquexs-r"
                      ><i class="fa fa-money" aria-hidden="true"></i> Deuda
                        mayor a
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >$10,000</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquexs-r"
                      ><i class="fa fa-clock-o" aria-hidden="true"></i> Tener
                        pagos
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >atrasados</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquexs-r"
                      ><i class="fa fa-credit-card" aria-hidden="true"></i>
                        Deudas con
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >tarjetas de crédito</b
                        >
                        o préstamos personales</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-hp"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgxs-hp"
                    src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsx">
                    <span id="spanopxs">
                      La opinión de<b id="spanopbxs"> nuestros graduados </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%; align-items: center">
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/6.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/3.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/1.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/4.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/5.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/2.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-hp"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgxs-hp"
                    src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsx">
                    <span id="spanopxs">
                      Lo que la prensa<b id="spanopbxs"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%">
                  <div class="col-6">
                    <a
                        href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                        target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                        target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                        target="_blank"
                    >
                      <img src="img/prensa/El Economista.png"/>
                    </a>
                  </div>

                  <div class="col-12">
                    <a
                        href="https://www.forbes.com.mx/nuestra-revista-las-100-startups-de-mayor-crecimiento-en-mexico/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-sm-block d-md-none"
           style="background-color: #ebebeb; align-content: center"
      >
        <section
            class="section section-md"
            style="background-color: #ebebeb; align-content: center"
        >
          <div class="container-fluid" id="main-container-hp">
            <div class="row">
              <div class="col">
                <img
                    src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Chico Home-01.png"
                    id="guy-background-hp"
                />
                <div class="d-flex justify-content-end">
                  <div id="quotesm-hp">
                    <span
                        id="spansm-hp"
                        style="
                        position: relative;
                        font-size: 23pt;
                        align-items: center;
                      "
                    >
                      Liquida tus deudas con hasta
                    </span>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 60pt;
                        position: absolute;
                        margin-top: -1rem;
                      "
                    >
                      70<b style="font-size: 35pt"> %</b>
                    </p>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 25pt;
                        position: absolute;
                        margin-top: 5.5rem;
                        line-height: 100%;
                      "
                    >
                      de descuento
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-calculator-hp"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="quote-calculator-sm">
                    <span id="spanCalsm"> Calcula tu descuento </span>
                    <div
                        class="row justify-content-center"
                        style="align-items: center; padding: 1rem"
                    >
                      <p
                          class="speech-bubble-mobile"
                          style="margin-top: -0.5rem"
                      >
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                    <h2 id="hAmountsm" style="margin-top: -1.5rem; margin-bottom:-1rem;">
                      ${{
                        simpleCalc.amount < 300
                            ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                                ? Number(simpleCalc.amount * 1000)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " ó menos"
                                : Number(simpleCalc.amount * 1000)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : Number(simpleCalc.amount * 1000)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                      }}
                    </h2>
                    <div
                        class="row justify-content-center"
                        style="align-items: center; padding: 5%"
                    >
                      <vue-slider
                          :width="width"
                          :height="height"
                          style="animation:pulse2 2s infinite"
                          :min="30"
                          :max="300"
                          :step="5"
                          :tooltip="'none'"
                          :process-style="processStyle"
                          v-model.number="simpleCalc.amount"
                      >
                        <template v-slot:dot="{ focus }">
                          <div :class="['custom-dot', { focus }]"></div>
                        </template>
                      </vue-slider>
                    </div>
                    <p id="spanwhosm" style="margin-top: -0.5rem">
                      <span>Selecciona a quién le debes: </span>
                    </p>
                    <div
                        class="row justify-content-center"
                        style="
                        padding: 1%;
                        margin-top: -8%;
                        margin-bottom: -3%;
                        align-items: center;
                      "
                    >
                      <div
                          class="col-md-3 col-sm-3 col-3 text-center"
                          style="align-items: center; margin-top: 1rem"
                          v-for="(bank, index) in banks"
                          :key="index"
                      >
                        <div
                            @click="m_switchSelected(index)"
                            style="margin-top: 10%"
                        >
                          <div class="g-item">
                            <img
                                :src="
                                bank.selected ? bank.url_y : bank.url_w_mobile
                              "
                                :title="bank.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p id="spaninstsm" style="margin-top: 1.5rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <base-button
                        v-on:click="m_startinglead()"
                        style="
                        background-color: #f3d338;
                        color: #2a3d9a;
                        border: none;
                        font-size: 100%;
                        font-family: Gotham-Black;
                      "
                        class="btndissm"
                    >
                      Dame mi descuento
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-comments-hp">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgsm-hp"
                    src="img/icons/Iluestración Pasos.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteprosm">
                    <span id="spanprosm">
                      Nuestro programa<b id="spanprobsm">
                        en 3 simples pasos
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div id="quotethreesm">
                    <span id="spanasm"
                    ><i class="fa fa-check" aria-hidden="true"></i> Asesoría
                      gratuita</span
                    >
                    <p id="pasm">
                      Analizamos tu caso y creamos un plan de ahorro y
                      liquidación de acuerdo a tu situación.
                    </p>
                    <span id="spanasm">
                      <i class="fa fa-check" aria-hidden="true"></i> Negociamos
                      tu descuento</span
                    >
                    <p id="pasm">
                      Mientras ahorras mes con mes, negociaremos para obtener el
                      mejor descuento para ti.
                    </p>
                    <span id="spanasm">
                      <i class="fa fa-check" aria-hidden="true"></i> Liquidamos
                      tus deudas</span
                    >
                    <p id="pasm">
                      Cuando alcances tu meta de ahorro, liquidaremos la deuda
                      con tu descuento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-questions-hp">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgsm-que-hp"
                    src="img/icons/Ilustracion Pregunta.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotequesm">
                    <span id="spanquesm">
                      ¿Qué necesito
                      <b id="spanquebsm">para solucionar mi deuda?</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div style="align-items: center">
                  <div
                      class="row"
                      style="
                      padding: 3%;
                      margin-left: 8%;
                      align-items: center;
                    "
                  >
                    <div class="col-12">
                      <span id="spanquesm-r"
                      ><i class="fa fa-user" aria-hidden="true"></i> Ser mayor
                        de
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >18 años</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquesm-r"
                      ><i class="fa fa-money" aria-hidden="true"></i> Deuda
                        mayor a
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >$10,000</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquesm-r"
                      ><i class="fa fa-clock-o" aria-hidden="true"></i> Tener
                        pagos
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >atrasados</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquesm-r"
                      ><i class="fa fa-credit-card" aria-hidden="true"></i>
                        Deudas con
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >tarjetas de crédito</b
                        >
                        o préstamos personales</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-hp"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgsm-hp"
                    src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsm">
                    <span id="spanopsm">
                      La opinión de<b id="spanopbsm"> nuestros graduados </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%; align-items: center">
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/6.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/3.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/1.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/4.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/5.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/2.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-hp"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgsm-hp"
                    src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptsm">
                    <span id="spanopsm">
                      Lo que la prensa<b id="spanopbsm"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%">
                  <div class="col-6">
                    <a
                        href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                        target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                        target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                        target="_blank"
                    >
                      <img src="img/prensa/El Economista.png"/>
                    </a>
                  </div>

                  <div class="col-12">
                    <a
                        href="https://www.forbes.com.mx/nuestra-revista-las-100-startups-de-mayor-crecimiento-en-mexico/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-md-block d-lg-none"
           style="background-color: #ebebeb; align-content: center"
      >
        <section
            class="section section-md"
            style="background-color: #ebebeb; align-content: center"
        >
          <div class="container" id="main-container-hp-md">
            <div class="row">
              <div class="col">
                <img
                    src="https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Chico Home-01.png"
                    id="guy-background-hp"
                />
                <div class="d-flex justify-content-end">
                  <div id="quotemd-hp">
                    <span
                        id="spanmd-hp"
                        style="font-size: 30pt; align-items: center"
                    >
                      Liquida tus deudas con hasta
                    </span>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 65pt;
                        position: absolute;
                        margin-top: -1.5rem;
                      "
                    >
                      70<b style="font-size: 40pt"> %</b>
                    </p>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 35pt;
                        position: absolute;
                        margin-top: 5.5rem;
                        line-height: 100%;
                      "
                    >
                      de descuento
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-calculator-hp"
          >
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div id="quote-calculator-md">
                    <span id="spanCalmd"> Calcula tu descuento </span>
                    <div
                        class="row justify-content-center"
                        style="align-items: center; padding: 1rem"
                    >
                      <p
                          class="speech-bubble-mobile"
                          style="margin-top: -0.5rem"
                      >
                        ¿Cuánto dinero debes?
                      </p>
                    </div>
                    <h2 id="hAmountmd" style="margin-top: -1.5rem; margin-bottom: -1rem">
                      ${{
                        simpleCalc.amount < 300
                            ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                                ? Number(simpleCalc.amount * 1000)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                                " ó menos"
                                : Number(simpleCalc.amount * 1000)
                                    .toFixed(0)
                                    .toString()
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            : Number(simpleCalc.amount * 1000)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                      }}
                    </h2>
                    <div
                        class="row justify-content-center"
                        style="align-items: center; padding: 5%"
                    >
                      <vue-slider
                          :width="width"
                          :height="height"
                          style="animation:pulse2 2s infinite"
                          :min="30"
                          :max="300"
                          :step="5"
                          :tooltip="'none'"
                          :process-style="processStyle"
                          v-model.number="simpleCalc.amount"
                      >
                        <template v-slot:dot="{ focus }">
                          <div :class="['custom-dot', { focus }]"></div>
                        </template>
                      </vue-slider>
                    </div>
                    <p id="spanwhomd" style="margin-top: 1rem">
                      <span>Selecciona a quién le debes: </span>
                    </p>
                    <div
                        class="row justify-content-center"
                        style="
                        padding: 1%;
                        margin-top: -6%;
                        margin-bottom: -3%;
                        align-items: center;
                      "
                    >
                      <div
                          class="col-md-3 col-sm-3 col-3 text-center"
                          style="align-items: center; margin-top: 1rem"
                          v-for="(bank, index) in banks"
                          :key="index"
                      >
                        <div
                            @click="m_switchSelected(index)"
                            style="margin-top: 10%"
                        >
                          <div class="g-item">
                            <img
                                :src="
                                bank.selected ? bank.url_y : bank.url_w_mobile
                              "
                                :title="bank.name"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p id="spaninstmd" style="margin-top: 1.5rem">
                      <span>
                        Si tu institución financiera o banco no se encuentra
                        aquí, por el momento no podemos ayudarte.</span
                      >
                    </p>
                    <base-button
                        v-on:click="m_startinglead()"
                        style="
                        background-color: #f3d338;
                        color: #2a3d9a;
                        border: none;
                        font-size: 100%;
                        font-family: Gotham-Black;
                      "
                        class="btndismd"
                    >
                      Dame mi descuento
                    </base-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-comments-hp">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgmd-hp"
                    src="img/icons/Iluestración Pasos.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotepromd">
                    <span id="spanpromd">
                      Nuestro programa<b id="spanprobmd">
                        en 3 simples pasos
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div id="quotethreemd">
                    <span id="spanamd"
                    ><i class="fa fa-check" aria-hidden="true"></i> Asesoría
                      gratuita</span
                    >
                    <p id="pamd">
                      Analizamos tu caso y creamos un plan de ahorro y
                      liquidación de acuerdo a tu situación.
                    </p>
                    <span id="spanamd">
                      <i class="fa fa-check" aria-hidden="true"></i> Negociamos
                      tu descuento</span
                    >
                    <p id="pamd">
                      Mientras ahorras mes con mes, negociaremos para obtener el
                      mejor descuento para ti.
                    </p>
                    <span id="spanamd">
                      <i class="fa fa-check" aria-hidden="true"></i> Liquidamos
                      tus deudas</span
                    >
                    <p id="pamd">
                      Cuando alcances tu meta de ahorro, liquidaremos la deuda
                      con tu descuento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-questions-hp">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgmd-que-hp"
                    src="img/icons/Ilustracion Pregunta.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotequemd">
                    <span id="spanquemd">
                      ¿Qué necesito
                      <b id="spanquebmd">para solucionar mi deuda?</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div style="align-items: center">
                  <div
                      class="row"
                      style="
                      padding:8%;
                      margin-left: 3%;
                      align-items: center;
                    "
                  >
                    <div class="col-12">
                      <span id="spanquemd-r"
                      ><i class="fa fa-user" aria-hidden="true"></i> Ser mayor
                        de
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >18 años</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquemd-r"
                      ><i class="fa fa-money" aria-hidden="true"></i> Deuda
                        mayor a
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >$10,000</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquemd-r"
                      ><i class="fa fa-clock-o" aria-hidden="true"></i> Tener
                        pagos
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >atrasados</b
                        ></span
                      >
                    </div>
                    <div class="col-12">
                      <span id="spanquemd-r"
                      ><i class="fa fa-credit-card" aria-hidden="true"></i>
                        Deudas con
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >tarjetas de crédito</b
                        >
                        o préstamos personales</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-hp"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgmd-hp"
                    src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptmd">
                    <span id="spanopmd" style="font-size: 25pt">
                      La opinión de<b id="spanopbmd"> nuestros graduados </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%; align-items: center">
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/6.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/3.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/1.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/4.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/5.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-12">
                    <a
                        href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/2.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-hp"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgmd-hp"
                    src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteoptmd">
                    <span id="spanopmd" style="font-size: 25pt">
                      Lo que la prensa<b id="spanopbmd"> opina de nosotros </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 5%">
                  <div class="col-6">
                    <a
                        href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                        target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                        target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png"/>
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                        target="_blank"
                    >
                      <img src="img/prensa/El Economista.png"/>
                    </a>
                  </div>

                  <div class="col-12">
                    <a
                        href="https://www.forbes.com.mx/nuestra-revista-las-100-startups-de-mayor-crecimiento-en-mexico/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-lg-block d-xl-none">
        <section class="section section-md">
          <div class="container-grid" id="main-container-des-hp">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotelg-hp">
                    <span
                        id="spanlg-hp"
                        style="font-size: 14pt; align-items: center"
                    >
                      Liquida tus deudas con hasta
                    </span>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 70pt;
                        position: absolute;
                        margin-top: -1.5rem;
                      "
                    >
                      70<b style="font-size: 30pt"> %</b>
                    </p>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 20pt;
                        position: absolute;
                        margin-top: 5.5rem;
                        line-height: 100%;
                      "
                    >
                      de descuento
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6 text-center">
                <div id="quote-calculator-desc-hlg">
                  <span id="spanCallg-hp" style="font-size: 20pt">
                    Calcula tu descuento</span
                  >
                  <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 1.5rem"
                  >
                    <p class="speech-bubble" style="margin-top: -0.5rem">
                      ¿Cuánto dinero debes?
                    </p>
                  </div>
                  <h2 id="hAmountlg-hp" style="margin-top: -1.5rem; margin-bottom:-1rem">
                    ${{
                      simpleCalc.amount < 300
                          ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                              ? Number(simpleCalc.amount * 1000)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " ó menos"
                              : Number(simpleCalc.amount * 1000)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : Number(simpleCalc.amount * 1000)
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                    }}
                  </h2>
                  <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 7%"
                  >
                    <vue-slider
                        :width="width"
                        :height="height"
                        style="animation:pulse2 2s infinite"
                        :min="30"
                        :max="300"
                        :step="5"
                        :tooltip="'none'"
                        :process-style="processStyle"
                        v-model.number="simpleCalc.amount"
                    >
                      <template v-slot:dot="{ focus }">
                        <div :class="['custom-dot', { focus }]"></div>
                      </template>
                    </vue-slider>
                  </div>

                  <p id="spanwholg-hp" style="margin-top: -1rem">
                    <span style="font-size: 12pt"
                    >Selecciona a quién le debes:
                    </span>
                  </p>
                  <div
                      class="row justify-content-center"
                      style="
                      margin-bottom: 2rem;
                      margin-top: -1rem;
                      align-items: center;
                    "
                  >
                    <div
                        class="col-3 text-center"
                        style="align-items: center"
                        v-for="(bank, index) in banks"
                        :key="index"
                    >
                      <div
                          @click="m_switchSelected(index)"
                          style="margin-top: 20%"
                      >
                        <div class="g-item">
                          <img
                              :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                              :title="bank.name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p id="spaninstlg-hp" style="font-size: 10pt">
                    <span>
                      Si tu institución financiera o banco no se encuentra aquí,
                      por el momento no podemos ayudarte.</span
                    >
                  </p>
                  <base-button
                      v-on:click="m_startinglead()"
                      style="
                      background-color: #f3d338;
                      color: #2a3d9a;
                      border: none;
                      font-size: 100%;
                      margin-bottom: -1.5rem;
                      font-family: Gotham-Black;
                    "
                      class="btndislg"
                  >
                    Dame mi descuento
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid"
              id="main-container-comments-hplg"
              style="margin-top: -6rem"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imglg-hp-hp"
                    src="img/icons/Iluestración Pasos.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteprolg">
                    <span id="spanprolg">
                      Nuestro programa<b id="spanproblg">
                        en 3 simples pasos
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div id="quotethreelg">
                    <span id="spanalg"
                    ><i class="fa fa-check" aria-hidden="true"></i> Asesoría
                      gratuita</span
                    >
                    <p id="palg">
                      Analizamos tu caso y creamos un plan de ahorro y
                      liquidación de acuerdo a tu situación.
                    </p>
                    <span id="spanalg">
                      <i class="fa fa-check" aria-hidden="true"></i> Negociamos
                      tu descuento</span
                    >
                    <p id="palg">
                      Mientras ahorras mes con mes, negociaremos para obtener el
                      mejor descuento para ti.
                    </p>
                    <span id="spanalg">
                      <i class="fa fa-check" aria-hidden="true"></i> Liquidamos
                      tus deudas</span
                    >
                    <p id="palg">
                      Cuando alcances tu meta de ahorro, liquidaremos la deuda
                      con tu descuento.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-questions-hplg">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imglg-hp-que-hp"
                    src="img/icons/Ilustracion Pregunta.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotequelg">
                    <span id="spanquelg">
                      ¿Qué necesito
                      <b id="spanqueblg-hp">para solucionar mi deuda?</b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div id="quotethreelg">
                    <div class="row" style="margin-left:10%; align-items: center">
                      <div class="col-2"></div>
                      <div class="col-8">
                    <span id="spanquelg-r"
                    ><i class="fa fa-user" aria-hidden="true"></i> Ser mayor
                        de
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >18 años</b
                        ></span
                    >
                        <br>
                        <span id="spanquelg-r"
                        ><i class="fa fa-money" aria-hidden="true"></i> Deuda
                        mayor a
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >$10,000</b
                        ></span
                        >
                        <br>
                        <span id="spanquelg-r"
                        ><i class="fa fa-clock-o" aria-hidden="true"></i> Tener
                        pagos
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >atrasados</b
                        ></span
                        >
                        <br>
                        <span id="spanquelg-r"
                        ><i class="fa fa-credit-card" aria-hidden="true"></i>
                        Deudas con
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >tarjetas de crédito</b
                        >
                        o préstamos personales</span
                        >
                      </div>
                      <div class="col-2"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-lg"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imglg-hp"
                    src="img/icons/Ilustracion reviews.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <div id="quoteoptlg-hp">
                    <span id="spanoplg-hp">
                      La opinión de<b id="spanopblg-hp"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 2%; align-items: center">
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/6.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/3.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/1.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/4.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/5.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/2.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-lg"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imglg-hp"
                    src="img/icons/Ilustracion de prensa.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <div id="quoteoptlg-hp">
                    <span id="spanoplg-hp">
                      Lo que la prensa<b id="spanopblg-hp">
                        opina de nosotros
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                        href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                        href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                        target="_blank"
                    >
                      <img src="img/prensa/El Economista.png"/>
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                        href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                        target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png"/>
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                        href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                        target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png"/>
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                        href="https://www.forbes.com.mx/nuestra-revista-las-100-startups-de-mayor-crecimiento-en-mexico/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <section>
      <div class="d-none d-xl-block">
        <section class="section section-md">
          <div class="container-grid" id="main-container-des-hp">
            <div class="row">
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotexl-hp">
                    <span
                        id="spanxl-hp"
                        style="font-size: 18pt; align-items: center"
                    >
                      Liquida tus deudas con hasta
                    </span>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 90pt;
                        position: absolute;
                        margin-top: -1.5rem;
                      "
                    >
                      70<b style="font-size: 30pt"> %</b>
                    </p>
                    <p
                        style="
                        color: #2a3d9a;
                        padding: 0;
                        font-family: Gotham-Ultra;
                        font-size: 25pt;
                        position: absolute;
                        margin-top: 8.5rem;
                        line-height: 100%;
                      "
                    >
                      de descuento
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-6 text-center">
                <div id="quote-calculator-desc-hxl">
                  <span id="spanCalxl" style="font-size: 25pt">
                    Calcula tu descuento</span
                  >
                  <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 1.5rem"
                  >
                    <p class="speech-bubble" style="margin-top: -0.5rem">
                      ¿Cuánto dinero debes?
                    </p>
                  </div>
                  <h2
                      id="hAmountxl"
                      style="margin-top: -1.5rem; margin-bottom:-1rem; font-size: 35pt"
                  >
                    ${{
                      simpleCalc.amount < 300
                          ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                              ? Number(simpleCalc.amount * 1000)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " ó menos"
                              : Number(simpleCalc.amount * 1000)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : Number(simpleCalc.amount * 1000)
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                    }}
                  </h2>
                  <div
                      class="row justify-content-center"
                      style="align-items: center; padding:5%"
                  >
                    <vue-slider
                        :width="width"
                        :height="height"
                        style="animation:pulse2 2s infinite"
                        :min="30"
                        :max="300"
                        :step="5"
                        :tooltip="'none'"
                        :process-style="processStyle"
                        v-model.number="simpleCalc.amount"
                    >
                      <template v-slot:dot="{ focus }">
                        <div :class="['custom-dot', { focus }]"></div>
                      </template>
                    </vue-slider>
                  </div>

                  <p id="spanwhoxl" style="margin-top: -2rem">
                    <span style="font-size: 15pt"
                    >Selecciona a quién le debes:
                    </span>
                  </p>
                  <div
                      class="row justify-content-center"
                      style="
                      margin-bottom: 3rem;
                      margin-top: -1.5rem;
                      align-items: center;
                    "
                  >
                    <div
                        class="col-3 text-center"
                        style="align-items: center"
                        v-for="(bank, index) in banks"
                        :key="index"
                    >
                      <div
                          @click="m_switchSelected(index)"
                          style="margin-top: 20%"
                      >
                        <div class="g-item">
                          <img
                              :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                              :title="bank.name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p id="spaninstxl" style="font-size: 12pt">
                    <span>
                      Si tu institución financiera o banco no se encuentra aquí,
                      por el momento no podemos ayudarte.</span
                    >
                  </p>
                  <base-button
                      v-on:click="m_startinglead()"
                      style="
                      background-color: #f3d338;
                      color: #2a3d9a;
                      border: none;
                      font-size: 100%;
                      margin-bottom: -1.5rem;
                      font-family: Gotham-Black;
                    "
                      class="btndisxl"
                  >
                    Dame mi descuento
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid"
              id="main-container-comments-hplg"
              style="margin-top: -5rem"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgxl-hp"
                    src="img/icons/Iluestración Pasos.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quoteproxl">
                    <span id="spanproxl">
                      Nuestro programa<b id="spanproblg">
                        en 3 simples pasos
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div id="quotethreexl">
                    <div
                        class="row "
                        style="align-items: center; margin-left:15%; padding:5%;"
                    >
                      <div class="col-12 text-left">
                        <span id="spanaxl"
                        ><i class="fa fa-check" aria-hidden="true"></i>
                          Asesoría gratuita</span
                        >
                        <p id="paxl">
                          Analizamos tu caso y creamos un plan de ahorro y
                          liquidación de acuerdo a tu situación.
                        </p>
                      </div>
                      <div class="col-12">
                        <span id="spanaxl">
                          <i class="fa fa-check" aria-hidden="true"></i>
                          Negociamos tu descuento</span
                        >
                        <p id="paxl">
                          Mientras ahorras mes con mes, negociaremos para
                          obtener el mejor descuento para ti.
                        </p>
                      </div>
                      <div class="col-12">
                        <span id="spanaxl">
                          <i class="fa fa-check" aria-hidden="true"></i>
                          Liquidamos tus deudas</span
                        >
                        <p id="paxl">
                          Cuando alcances tu meta de ahorro, liquidaremos la
                          deuda con tu descuento.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="main-container-questions-hplg">
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imgxl-que-hp"
                    src="img/icons/Ilustracion Pregunta-2.png"
                />
              </div>
              <div class="col-6">
                <div class="d-flex justify-content-end">
                  <div id="quotequexl">
                    <span id="spanquexl">
                      ¿Qué necesito
                      <b id="spanquebxl">para solucionar mi deuda?</b>
                    </span>
                  </div>
                </div>
              </div>
              <div id="quotethreexl">
                <div
                    class="row"
                    style="align-items: center; margin-left:18%; padding:6%;"
                >
                  <div class="col-12">
                        <span id="spanquexl-r"
                        ><i class="fa fa-user" aria-hidden="true"></i> Ser mayor
                        de
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >18 años</b
                        ></span
                        >
                    <br>
                  </div>
                  <div class="col-12">
                      <span id="spanquexl-r"
                      ><i class="fa fa-money" aria-hidden="true"></i> Deuda
                        mayor a
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >$10,000</b
                        ></span
                      >
                    <br>
                  </div>
                  <div class="col-12">
                    <span id="spanquexl-r"
                    ><i class="fa fa-clock-o" aria-hidden="true"></i> Tener
                        pagos
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >atrasados</b
                        ></span
                    >
                    <br>
                  </div>
                  <div class="col-12">
                      <span id="spanquexl-r"
                      ><i class="fa fa-credit-card" aria-hidden="true"></i>
                        Deudas con
                        <b style="color: #f3d338; font-family: HomePage-Bold"
                        >tarjetas de crédito</b
                        >
                        o préstamos personales</span
                      >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-lg"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imglg-hp"
                    src="img/icons/Ilustracion reviews.png"
                    style="width: 25%"
                />
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <div id="quoteoptxl-hp">
                    <span id="spanoplg-hp">
                      La opinión de<b id="spanopblg-hp"> nuestros graduados</b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 3%; align-items: center">
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/esquivelito.esquivel/posts/4171875732858100"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/6.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/mariacatalina.favelaante/posts/3908845215858802"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/3.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/maribel.zurita.9822/posts/140445648050970"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/1.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/cristhian.lopezsalazar/posts/3909897332432744"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/4.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/arias.roy.92/posts/1084694845345545"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/5.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                  <div class="col-6">
                    <a
                        href="https://www.facebook.com/abril.duenas.16/posts/1071020213426207"
                        target="_blank"
                    >
                      <img
                          src="img/Comentarios/Escritorio/2.png"
                          style="width: 100%"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div
              class="container-fluid text-center"
              id="main-container-comments-lg"
          >
            <div class="row">
              <div class="col-6">
                <img
                    id="reviews-imglg-hp"
                    src="img/icons/Ilustracion de prensa.png"
                    style="width: 25%"
                />
              </div>
              <div class="col-6">
                <div class="d-flex">
                  <div id="quoteoptxl-hp">
                    <span id="spanoplg-hp">
                      Lo que la prensa<b id="spanopblg-hp">
                        opina de nosotros
                      </b>
                    </span>
                  </div>
                </div>
              </div>
              <div style="align-items: center">
                <div class="row" style="padding: 1%; align-items: center">
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                        href="https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                        href="https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html"
                        target="_blank"
                    >
                      <img src="img/prensa/El Economista.png"/>
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col-2"></div>
                  <div class="col-4">
                    <a
                        href="https://www.youtube.com/watch?v=dIpm0veIGBc"
                        target="_blank"
                    >
                      <img src="img/prensa/El Heraldo.png"/>
                    </a>
                  </div>
                  <div class="col-4">
                    <a
                        href="https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es"
                        target="_blank"
                    >
                      <img src="img/prensa/Nacional Financiera.png"/>
                    </a>
                  </div>
                  <div class="col-2"></div>
                  <div class="col">
                    <a
                        href="https://www.forbes.com.mx/nuestra-revista-las-100-startups-de-mayor-crecimiento-en-mexico/"
                        target="_blank"
                    >
                      <img src="img/prensa/Forbes.png"/>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>

    <modal
        :show.sync="discountModal"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
    >
      <card
          id="discountLead"
          shadow
          class="border-0"
      >
        <template>
          <div class="text-center text-muted mb-4 d-block d-sm-none">
             <span id="spanGothamBookxs">
                      Debes<b id="spanGothamBoldxs"> ${{
                 Number(simpleCalc.amount * 1000)
                     .toFixed(0)
                     .toString()
                     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
               }}</b>
                      con:
                    </span>
            <div
                class="row justify-content-center"
                style="
                      margin-bottom: 1rem;
                      align-items: center;
                    "
            >
              <div
                  class="col-6 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div
                    style="margin-top: 10%"
                >
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBookxs">
                      Con DB Menos pagarías
                    </span>
            <br>
            <div style=" margin-top: 0.5rem;">
                    <span id="spanUltraxs"> ${{
                        Number((simpleCalc.amount * 1000) / 2)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span>
            </div>
            <div style=" margin-top: 1rem;">
                            <span id="spanGothamBookxs">
                      En <b id="spanGothamBoldxs"> cómodas mensualidades </b>
                      de acorde a tu capacidad de pago.
                    </span>
            </div>
            <div style="margin-top:20px">
              <base-button
                  v-on:click="m_startinglead()"
                  id="btnWantmd"
              >
                ¡LO QUIERO!
              </base-button>
            </div>

          </div>
          <div class="text-center text-muted mb-4 d-none d-sm-block d-md-none" style="padding:1%">
             <span id="spanGothamBooksm">
                      Debes<b id="spanGothamBoldsm"> ${{
                 Number(simpleCalc.amount * 1000)
                     .toFixed(0)
                     .toString()
                     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
               }}</b>
                      con:
                    </span>
            <div
                class="row justify-content-center"
                style="
                      margin-bottom: 1rem;
                      align-items: center;
                    "
            >
              <div
                  class="col-6 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div
                    style="margin-top: 20%"
                >
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBooksm">
                      Con DB Menos pagarías
                    </span>
            <br>
            <div style=" margin-top: 1rem;">
                    <span id="spanUltrasm"> ${{
                        Number((simpleCalc.amount * 1000) / 2)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span>
            </div>
            <div style=" margin-top: 1.5rem;">
                            <span id="spanGothamBooksm">
                      En <b id="spanGothamBoldsm"> cómodas mensualidades </b>
                      de acorde a tu capacidad de pago.
                    </span>
            </div>
            <div style="margin-top:20px">
              <base-button
                  v-on:click="m_startinglead()"
                  id="btnWantmd"
              >
                ¡LO QUIERO!
              </base-button>
            </div>

          </div>
          <div class="text-center text-muted mb-4 d-none d-md-block d-lg-none">
             <span id="spanGothamBookmd">
                      Debes<b id="spanGothamBoldmd"> ${{
                 Number(simpleCalc.amount * 1000)
                     .toFixed(0)
                     .toString()
                     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
               }}</b>
                      con:
                    </span>
            <div
                class="row justify-content-center"
                style="
                      margin-bottom: 1rem;
                      margin-top: 1rem;
                      align-items: center;
                    "
            >
              <div
                  class="col-6 text-center"
                  style="align-items: center; margin-top:0.5rem;"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div class="g-item">
                  <img
                      v-bind:src="getBank(bank.id).url_w_complete"
                      :title="bank.name"
                  />
                </div>
              </div>
            </div>
            <span id="spanGothamBookmd">
                      Con DB Menos pagarías
                    </span>
            <br>
            <div style=" margin-top: 0.5rem;">
                    <span id="spanUltramd"> ${{
                        Number((simpleCalc.amount * 1000) / 2)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span>
            </div>
            <div style=" margin-top: 0.5rem;">
                            <span id="spanGothamBookmd">
                      En <b id="spanGothamBoldmd"> cómodas mensualidades </b>
                      de acorde a tu capacidad de pago.
                    </span>
            </div>
            <div style="margin-top:20px">
              <base-button
                  v-on:click="m_startinglead()"
                  id="btnWantmd"
              >
                ¡LO QUIERO!
              </base-button>
            </div>
          </div>
          <div class="text-center text-muted mb-4 d-none d-lg-block d-xl-none">
             <span id="spanGothamBooklg">
                      Debes<b id="spanGothamBoldlg"> ${{
                 Number(simpleCalc.amount * 1000)
                     .toFixed(0)
                     .toString()
                     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
               }}</b>
                      con:
                    </span>
            <div
                class="row justify-content-center"
                style="
                      margin-bottom: 1rem;
                      align-items: center;
                    "
            >
              <div
                  class="col-3 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div
                    style="margin-top: 20%"
                >
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBooklg">
                      Con DB Menos pagarías
                    </span>
            <br>
            <div style=" margin-top: 0.5rem;">
                    <span id="spanUltralg"> ${{
                        Number((simpleCalc.amount * 1000) / 2)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span>
            </div>
            <div style=" margin-top: 1rem;">
                            <span id="spanGothamBooklg">
                      En <b id="spanGothamBoldlg"> cómodas mensualidades </b>
                      de acorde a tu capacidad de pago.
                    </span>
            </div>
            <div style="margin-top:25px">
              <base-button
                  v-on:click="m_startinglead()"
                  id="btnWantlg"
              >
                ¡LO QUIERO!
              </base-button>
            </div>

          </div>
          <div class="text-center text-muted mb-4 d-none d-xl-block">
             <span id="spanGothamBookxl">
                      Debes<b id="spanGothamBoldxl"> ${{
                 Number(simpleCalc.amount * 1000)
                     .toFixed(0)
                     .toString()
                     .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
               }}</b>
                      con:
                    </span>
            <div
                class="row justify-content-center"
                style="
                      margin-bottom: 1rem;
                      align-items: center;
                    "
            >
              <div
                  class="col-3 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div
                    style="margin-top: 20%"
                >
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBookxl">
                      Con DB Menos pagarías
                    </span>
            <br>
            <div style=" margin-top: 1.5rem;">
                    <span id="spanUltraxl"> ${{
                        Number((simpleCalc.amount * 1000) / 2)
                            .toFixed(0)
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}</span>
            </div>
            <div style=" margin-top: 2rem;">
                            <span id="spanGothamBookxl">
                      En <b id="spanGothamBoldxl"> cómodas mensualidades </b>
                      de acorde a tu capacidad de pago.
                    </span>
            </div>
            <div style="margin-top:15px">
              <base-button
                  v-on:click="m_startinglead()"
                  id="btnWantxl"
              >
                ¡LO QUIERO!
              </base-button>
            </div>

          </div>
        </template>
      </card>
    </modal>
    <modal
        :show.sync="leadModal"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
    >
      <card
          type="secondary"
          shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          style="opacity: 1 !important"
          class="border-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <small>Ingresa tus datos para obtener tu descuento</small>
          </div>
          <form role="form">
            <base-input
                alternative
                class="mb-3"
                v-model="leadInfo.name"
                :valid="m_hasValidName()"
                placeholder="Nombre Completo"
                addon-left-icon="ni ni-circle-08"
            ></base-input>
            <base-input
                alternative
                class="mb-3"
                type="email"
                v-model="leadInfo.email"
                :valid="m_hasValidEmail()"
                placeholder="Correo electrónico"
                addon-left-icon="ni ni-email-83"
            ></base-input>
            <base-input
                alternative
                type="Phone"
                v-model="leadInfo.phone"
                :valid="m_hasValidPhone()"
                placeholder="Teléfono"
                addon-left-icon="fa fa-phone"
            ></base-input>
            <div v-if="leadSent" class="text-center text-muted mb-4">
              <small>Para recibir el cálculo de tu descuento enviamos un código por WhatsApp colócalo para
                continuar.</small>
            </div>
            <base-input
                alternative
                v-model="leadValidationCode"
                v-if="leadSent"
                :valid="leadValidationCode.length === 4"
                placeholder="Código de verificación"
                addon-left-icon="fa fa-check"
            ></base-input>
            <div class="text-justify">
              <p>
                Al continuar, aceptas los
                <a
                    href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf"
                    target="_blank"
                >Términos y condiciones</a
                >. Consulta nuestro
                <a
                    href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf"
                    target="_blank"
                >Aviso de privacidad</a
                >
              </p>
            </div>
            <!--
            <div>
              <base-checkbox class="mb-3" v-model="sameBankDebtPaysheet"
                >Recibo mi nómina en mismo banco que tengo mis
                deudas</base-checkbox
              >
            </div>
            !-->
            <div class="text-center">
              <base-button
                  type="primary"
                  class="my-4"
                  v-on:click="m_insertLead()"
                  v-bind:disabled="
                  !m_hasValidName() || !m_hasValidPhone() || !m_hasValidEmail()
                "
              >Quiero mi descuento
              </base-button
              >
            </div>
          </form>
        </template>
      </card>
    </modal>
    <!--
    <modal
      :show.sync="estimatedDiscountModal"
      gradient="primary"
      modal-classes="modal-danger modal-dialog-centered"
    >
      <h6 slot="header" class="modal-title" id="modal-title-notification">
        Gracias {{ leadInfo.name }}
      </h6>

      <div class="py-3 text-center">
        <i class="ni ni-bell-55 ni-3x"></i>
        <h4 class="heading mt-4">Tenemos tu descuento</h4>
        <p>En Grupo DB Menos podemos ofrecerte un descuento estimado del</p>
        <h5 class="text-white">{{ averageDiscount.toFixed(2) }}%</h5>
        <p>Por lo que puedes liquidar tu deuda con tan solo</p>
        <h3 class="text-white">
          $
          {{
            ((100 - averageDiscount) * 10 * simpleCalc.amount)
              .toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
          }}
        </h3>
      </div>
      <template>
        <router-link to="/register" class="text-light">
          <base-button block type="white"
            >Quiero tomar el descuento</base-button
          >
        </router-link>
      </template>
    </modal>
    !-->
  </div>
</template>
<script>
import Vue from "vue";
import modal from "@/components/Modal";
import {V} from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import {LazyYoutube} from "vue-lazytube";
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'
import LPForm from "@/views/components/landing_page/Principal";

Vue.component('VueSlider', VueSlider)

Vue.use(VueFacebookPixel);
export default {
  name: "registerNew",
  components: {
    LPForm,
    modal,
    VueFacebookPixel,
    LazyYoutube,
    VueSlider
  },
  created() {
    fbq("track", "PageView");
    fbq("track", "page_view");
    //this.createMarketingLandingMobile1();
    this.m_insertMarketingLead();
    this.m_getBanks();
    this.width = '90%';
    this.height = 10;

    this.processStyle = {
      backgroundColor: '#f3d338',
    }
    this.sliderStyle = {
      width: '18px',
      height: '18px',
      backgroundColor: '#f3d338',
    }
  },
  data() {
    return {
      styleObject: {
        background: "red",
      },
      V: V,
      disabled: true,
      dataMark: "",
      from: null,
      typeapp: {
        appStore: 0,
        playStore: 0,
      },
      simpleCalc: {
        amount: 100,
        banks: [],
      },
      banks: [],
      bancos: [],
      leadInfo: {
        name: "",
        email: "",
        phone: "",
      },
      leadValidationCode: "",
      leadSent: false,
      sameBankDebtPaysheet: false,
      leadModal: false,
      discountModal: false,
      //estimatedDiscountModal: false,
      averageDiscount: 0,
      videosModal: false,
      banksSelected: [],
    };
  },
  methods: {
    // PATH ?utm_source=SOURCE&utm_medium=MEDIUM&utm_campaign=CAMPAIGN&utm_term=TERM&utm_content=CONTENT
    getBank(id) {
      console.log(id);
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    async m_insertMarketingLead() {

      let marketing =
          this.$route.query.utm_source +
          "_" +
          this.$route.query.utm_medium +
          "_" +
          this.$route.query.utm_campaign;
      if (marketing == "undefined_undefined_undefined") {
        marketing = null;
      } else if (marketing == this.$route.query.utm_source + "_undefined_undefined") {
        marketing = this.$route.query.utm_source;
      } else if (marketing == this.$route.query.utm_source + "_" + this.$route.query.utm_medium + "_undefined") {
        marketing = this.$route.query.utm_source + "_" + this.$route.query.utm_medium;
      } else if (marketing == this.$route.query.utm_source + "_undefined_" + this.$route.query.utm_campaign) {
        marketing = this.$route.query.utm_source + "_" + this.$route.query.utm_campaign;
      } else {
        marketing = marketing;
      }
      console.log(marketing);
      this.$store.commit("setMarketing", marketing);
      let url = V.SERVER + V.OPS.INSERT + V.TABLES.MARKETING_LEADS;
      await this.$axios.post(url, {from: marketing});
    },
    getVideos() {
      this.videosModal = true;
    },
    m_getDiscount() {
      if (!this.averageDiscount == 0) {
        this.discountModal = true;
        this.banksSelected = [];
        this.banks.forEach((b) => {
          if (b.selected) {
            this.banksSelected.push(b)
          }
        });
        console.log(this.banksSelected);
      } else {
        this.$notify({
          text: "Debes seleccionar un banco para poder continuar",
          type: "error",
        });
      }
    },
    m_startinglead() {
      this.leadModal = true;
      //this.discountModal = false;
      Vue.analytics.fbq.event("StartingLead", {starting: true});
      gtag("event", "Escoger-monto-deuda", {
        event_category: "Landing-liquidar-deuda-1",
        event_label: "escoger-monto",
      });
      console.log("Start Lead");
    },
    m_hasValidEmail() {
      let regex = new RegExp(
          "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|es|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b"
      );
      return regex.test(this.leadInfo.email);
    },
    m_hasValidPhone() {
      return (
          !isNaN(this.leadInfo.phone) &&
          this.leadInfo.phone.length > 9 &&
          this.leadInfo.phone.length < 11
      );
    },
    m_hasValidName() {
      return this.leadInfo.name.length > 2;
    },
    m_getBanks() {
      let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS_LANDINGS;
      this.$http.post(url, {}).then((res) => {
        if (res.body.success) {
          this.banks = res.body.rows;
          this.banks.forEach((b) => {
            b.selected = false;
          });
        }
      });
    },
    m_switchSelected(index) {
      console.log("here", this.simpleCalc.amount)
      let bank = this.banks[index];
      bank.selected = !bank.selected;
      console.log(bank);
      Vue.set(this.banks, index, bank);
      let count = 0;
      let neg = 0;
      this.banks.forEach((bank) => {
        if (bank.selected) {
          count += 1;
          neg += bank.neg;
        }
      });
      if (count > 0) {
        this.averageDiscount = neg / count;
      } else {
        this.averageDiscount = 0;
      }
    },
    m_insertLead() {
      if (this.leadSent) {
        let marketing =
            this.$route.query.utm_source +
            "_" +
            this.$route.query.utm_medium +
            "_" +
            this.$route.query.utm_campaign;
        if (marketing == "undefined_undefined_undefined") {
          marketing = null;
        } else if (marketing == this.$route.query.utm_source + "_undefined_undefined") {
          marketing = this.$route.query.utm_source;
        } else if (marketing == this.$route.query.utm_source + "_" + this.$route.query.utm_medium + "_undefined") {
          marketing = this.$route.query.utm_source + "_" + this.$route.query.utm_medium;
        } else if (marketing == this.$route.query.utm_source + "_undefined_" + this.$route.query.utm_campaign) {
          marketing = this.$route.query.utm_source + "_" + this.$route.query.utm_campaign;
        } else {
          marketing = marketing;
        }
        let _data = {
          phone: this.leadInfo.phone,
          code: this.leadValidationCode,
          marketing: marketing,
        };

        let calculate = {
          estimated: this.averageDiscount,
          total: this.simpleCalc.amount
        }

        let _dataLead = {
          name: this.leadInfo.name,
          email: this.leadInfo.email,
          phone: this.leadInfo.phone,
          amount: this.simpleCalc.amount * 1000,
          banks: [],
          same_bank_debt_paysheet: this.sameBankDebtPaysheet,
          device: 'web'
        };
        Vue.analytics.fbq.event("LeadComplete", {
          content_name:
              this.leadInfo.name +
              " " +
              this.leadInfo.phone +
              " lead validated successfully",
        });

        let url = V.SERVER + V.OPS.VALIDATE_LEAD_SMS_CODE;
        this.$http.post(url, _data).then(
            (res) => {
              if (res.data.success === true) {
                this.leadModal = false;
                this.leadSent = false;
                console.log(marketing)
                localStorage.setItem('phone', JSON.stringify(_data))
                localStorage.setItem('calculate', JSON.stringify(calculate))
                localStorage.setItem('Lead', JSON.stringify(_dataLead))
                localStorage.setItem('marketing', marketing)
                this.$router.push({path: "/regis"});
                window.location.reload()
                console.log(this.leadModal)
                gtag("event", "Datos-validados", {
                  event_category: "Landing-liquidar-deuda-1",
                  event_label: "datos-validados",
                });
              }
            },
            (er) => {
              //                        console.log("err")
              //                        console.log(er)
            }
        );
      } else {
        this.leadSent = true;
        let marketing =
            this.$route.query.utm_source +
            "_" +
            this.$route.query.utm_medium +
            "_" +
            this.$route.query.utm_campaign;
        if (marketing == "undefined_undefined_undefined") {
          marketing = null;
        } else if (marketing == this.$route.query.utm_source + "_undefined_undefined") {
          marketing = this.$route.query.utm_source;
        } else if (marketing == this.$route.query.utm_source + "_" + this.$route.query.utm_medium + "_undefined") {
          marketing = this.$route.query.utm_source + "_" + this.$route.query.utm_medium;
        } else if (marketing == this.$route.query.utm_source + "_undefined_" + this.$route.query.utm_campaign) {
          marketing = this.$route.query.utm_source + "_" + this.$route.query.utm_campaign;
        } else {
          marketing = marketing;
        }
        let headers = {
          "device": 'web'
        };
        let _data = {
          name: this.leadInfo.name,
          email: this.leadInfo.email,
          phone: this.leadInfo.phone,
          amount: this.simpleCalc.amount * 1000,
          banks: [],
          same_bank_debt_paysheet: this.sameBankDebtPaysheet,
          device: 'web'
        };
        if (marketing) {
          _data.marketing = marketing;
        }
        if (_data.amount > 100000) {
          _data.amount = this.simpleCalc.amount * 2000;
        }
        Vue.analytics.fbq.event("LeadStarting", {
          content_name:
              this.leadInfo.name +
              " " +
              this.leadInfo.phone +
              " lead successful started",
        });
        let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS;
        gtag("event", "conversion", {
          send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
        });
        gtag("event", "Datos-contacto", {
          event_category: "Landing-liquidar-deuda-1",
          event_label: "datos-contacto",
        });
        this.banks.forEach((bank) => {
          if (bank.selected) _data.banks.push(bank.id);
        });
        _data.banks = JSON.stringify(_data.banks);
        this.$http.post(url, _data, {headers: headers}).then(
            (res) => {

              if (res.data.success) {
                this.leadSent = res.data.success;
              } else {
                alert("Algo salió mal :(, intenta nuevamente");
              }
            },
            (er) => {
              //                        console.log("err")
              //                        console.log(er)
            }
        );
      }
    },
  },
};
</script>

<style>
.custom-dot {
  padding: 20%;
  margin-top: -0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eab740;
  transition: all .3s;
  animation: pulse2 2s infinite;
}

.custom-dot:hover {
  animation: pulse2 0s infinite;
}

.custom-dot.focus {
  animation: pulse2 0s infinite;
}

@font-face {
  font-family: bahnschrift;
  src: url(/fonts/Bahnschrift_font/bahnschrift.ttf);
}

@font-face {
  font-family: Roboto-ThinItalic;
  src: url(/fonts/Roboto_font/Roboto-ThinItalic.ttf);
}

@font-face {
  font-family: Roboto-Thin;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}

@font-face {
  font-family: Roboto-Regular;
  src: url(/fonts/Roboto_font/Roboto-Regular.ttf);
}

@font-face {
  font-family: Roboto-MediumItalic;
  src: url(/fonts/Roboto_font/Roboto-MediumItalic.ttf);
}

@font-face {
  font-family: Roboto-Medium;
  src: url(/fonts/Roboto_font/Roboto-Thin.ttf);
}

@font-face {
  font-family: Roboto-LightItalic;
  src: url(/fonts/Roboto_font/Roboto-LightItalic.ttf);
}

@font-face {
  font-family: Roboto-Light;
  src: url(/fonts/Roboto_font/Roboto-Light.ttf);
}

@font-face {
  font-family: Roboto-Italic;
  src: url(/fonts/Roboto_font/Roboto-Italic.ttf);
}

@font-face {
  font-family: Roboto-BoldItalic;
  src: url(/fonts/Roboto_font/Roboto-BoldItalic.ttf);
}

@font-face {
  font-family: Roboto-Bold;
  src: url(/fonts/Roboto_font/Roboto-Bold.ttf);
}

@font-face {
  font-family: Roboto-BlackItalic;
  src: url(/fonts/Roboto_font/Roboto-BlackItalic.ttf);
}

@font-face {
  font-family: Roboto-Black;
  src: url(/fonts/Roboto_font/Roboto-Black.ttf);
}

@font-face {
  font-family: Gotham-Black;
  src: url(/fonts/Gotham_Font/Gotham-Black.otf);
}

@font-face {
  font-family: Gotham-Thin;
  src: url(/fonts/Gotham_Font/Gotham-Thin.otf);
}

@font-face {
  font-family: Gotham-ThinItalic;
  src: url(/fonts/Gotham_Font/Gotham-ThinItalic.otf);
}

@font-face {
  font-family: Gotham-ultra;
  src: url(/fonts/Gotham_Font/gotham-ultra.ttf);
}

@font-face {
  font-family: Gotham-UltraItalic;
  src: url(/fonts/Gotham_Font/Gotham-UltraItalic.otf);
}

@font-face {
  font-family: Gotham-Bold;
  src: url(/fonts/Gotham_Font/GothamBold.ttf);
}

@font-face {
  font-family: Gotham-BoldItalic;
  src: url(/fonts/Gotham_Font/GothamBoldItalic.ttf);
}

@font-face {
  font-family: Gotham-Book;
  src: url(/fonts/Gotham_Font/GothamBook.ttf);
}

@font-face {
  font-family: Gotham-BookItalic;
  src: url(/fonts/Gotham_Font/GothamBookItalic.ttf);
}

@font-face {
  font-family: Gotham-Light;
  src: url(/fonts/Gotham_Font/GothamLight.ttf);
}

@font-face {
  font-family: Gotham-Medium;
  src: url(/fonts/Gotham_Font/GothamMedium.ttf);
}

@font-face {
  font-family: Gotham-LightItalic;
  src: url(/fonts/Gotham_Font/GothamLightItalic.ttf);
}

@font-face {
  font-family: Metropolis-ExtraLight;
  src: url(/fonts/Metropolis/Metropolis-ExtraLight.otf);
}

@font-face {
  font-family: HomePage-Book;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Book.ttf);
}

@font-face {
  font-family: HomePage-Bold;
  src: url(/fonts/HomePageBaukasten/HomepageBaukasten-Bold.ttf);
}

.change-my-color-y {
  filter: grayscale(120%) brightness(75%) sepia(250%) hue-rotate(1deg) saturate(1900%) contrast(0.7);
}

.change-my-color-y-d {
  filter: grayscale(150%) brightness(1000%) sepia(100%) hue-rotate(3deg) saturate(2000%) contrast(0.8);
}

.blob-mobile {
  margin: 10px;
  height: 5px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse2 2s infinite;
}

@keyframes pulse2 {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgb(255, 255, 255);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.blob-desk-2 {
  margin: 10px;
  height: 5px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  transform: scale(1);
  animation: pulse2 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(29, 32, 198, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

/*MODAL*/

#discountLead {
  background-color: transparent;
  height: 100%;
  padding: 2rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/imgsModal/blue_background.png") !important;
}

/*XS*/

#spanGothamBookxs {
  font-family: HomePage-Book;
  font-size: 15pt;
  color: white;
}

#spanGothamBoldxs {
  font-family: HomePage-Bold;
  font-size: 15pt;
  color: white;
}

#spanUltraxs {
  font-family: Gotham-Ultra;
  font-size: 25pt;
  color: white;
}

/*SM*/

#spanGothamBooksm {
  font-family: HomePage-Book;
  font-size: 18pt;
  color: white;
}

#spanGothamBoldsm {
  font-family: HomePage-Bold;
  font-size: 18pt;
  color: white;
}

#spanUltrasm {
  font-family: Gotham-Ultra;
  font-size: 25pt;
  color: white;
}

/*MD*/

#spanGothamBookmd {
  font-family: HomePage-Book;
  font-size: 15pt;
  color: white;
}

#spanGothamBoldmd {
  font-family: HomePage-Bold;
  font-size: 15pt;
  color: white;
}

#spanUltramd {
  font-family: Gotham-Ultra;
  font-size: 25pt;
  color: white;
}

#btnWantmd {
  background-color: #f3d338;
  color: #2a3d9a;
  background-image: linear-gradient(180deg, #f3d338, #f08c1e);
  border: none;
  padding: 8px;
  width: 80%;
  border-radius: 50px;
  font-size: 15pt;
  font-family: Gotham-Black;
}

/*LG*/

#spanGothamBooklg {
  font-family: HomePage-Book;
  font-size: 25pt;
  color: white;
}

#spanGothamBoldlg {
  font-family: HomePage-Bold;
  font-size: 25pt;
  color: white;
}

#spanUltralg {
  font-family: Gotham-Ultra;
  font-size: 32pt;
  color: white;
}

#btnWantlg {
  background-color: #f3d338;
  color: #2a3d9a;
  background-image: linear-gradient(180deg, #f3d338, #f08c1e);
  border: none;
  padding: 8px;
  width: 50%;
  border-radius: 50px;
  font-size: 25pt;
  font-family: Gotham-Black;
}

/*XL*/
#spanGothamBookxl {
  font-family: HomePage-Book;
  font-size: 25pt;
  color: white;
}

#spanGothamBoldxl {
  font-family: HomePage-Bold;
  font-size: 27pt;
  color: white;
}

#spanUltraxl {
  font-family: Gotham-Ultra;
  font-size: 35pt;
  color: white;
}

#btnWantxl {
  background-color: #f3d338;
  color: #2a3d9a;
  background-image: linear-gradient(180deg, #f3d338, #f08c1e);
  border: none;
  padding: 8px;
  width: 50%;
  border-radius: 50px;
  font-size: 25pt;
  font-family: Gotham-Black;
}

#main-container-hp {
  background-color: #ebebeb;
  margin-bottom: -15%;
}

#main-container-hp-md {
  background-color: #ebebeb;
  align-content: center;
  margin-bottom: -7%;
}

#guy-background-hp {
  width: 100%;
}

#quote {
  padding: 5%;
}

#quotexs-hp {
  position: absolute;
  padding: 10%;
  color: #808080;
  width: 60%;
  top: 8%;
  left: 45%;
}

#spanxs-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 100%;
  text-align: left;
}

#spanbxs-dis {
  position: absolute;
  color: #2a3d9a;
  font-family: Gotham-Ultra;
  font-size: 100%;
  top: 60%;
  left: 22%;
}

#spanbxs-dis-d {
  position: absolute;
  color: #2a3d9a;
  font-family: Gotham-Ultra;
  font-size: 100%;
  top: 100%;
  right: 15%;
}

#spanbxs-dis-p {
  color: #2a3d9a;
  font-family: Gotham-Ultra;
  font-size: 10pt;
}

#spansm {
  font-family: Metropolis-ExtraLight;
  font-size: 12pt;
}

#spanbsm {
  font-family: HomePage-Bold;
  font-size: 12pt;
}

#spanmd {
  font-family: Metropolis-ExtraLight;
  font-size: 14pt;
}

#spanbmd {
  font-family: HomePage-Bold;
  font-size: 14pt;
}

#main-container-calculator-hp {
  background-color: #092699;
  padding: 5%;
  height: 100%;
  margin-top: 1%;
}

#quote-calculator {
  margin-bottom: 10%;
  margin-top: 8%;
  padding: 2%;
}

#spanCalxs {
  color: white;
  font-size: 120%;
  font-family: HomePage-Bold;
}

#spanDebxs {
  color: white;
  font-size: 100%;
  font-family: HomePage-Book;
}

#hAmountxs {
  color: white;
  margin-top: -4%;
  font-family: Gotham-Ultra;
}

#sliderxs {
  width: 80%;
  margin-top: -3%;
}

#spanwhoxs {
  color: white;
  margin-top: -2%;
  font-family: HomePage-Book;
}

#spaninstxs {
  font-size: 80%;
  color: white;
  margin-top: -5%;
  font-family: HomePage-Book;
}

#quote-calculator-sm {
  margin-bottom: 10%;
  margin-top: 10%;
  padding: 2%;
}

#quote-calculator-md {
  margin-bottom: 10%;
  padding: 2%;
}

#spanCalsm {
  color: white;
  font-size: 20pt;
  font-family: HomePage-Bold;
}

#spanDebsm {
  color: white;
  font-size: 120%;
  font-family: HomePage-Book;
}

#hAmountsm {
  color: white;
  font-size: 250%;
  margin-top: -3%;
  font-family: Gotham-Ultra;
}

#slidersm {
  width: 80%;
  margin-top: -1%;
  margin-left: 10%;
}

#spanwhosm {
  color: white;
  margin-bottom: 2%;
  font-size: 120%;
  font-family: HomePage-Book;
}

#spaninstsm {
  font-size: 80%;
  font-size: 15pt;
  color: white;
  margin-top: -5%;
  font-family: HomePage-Book;
}

#spanCalmd {
  color: white;
  font-size: 30pt;
  font-family: HomePage-Bold;
}

#spanDebmd {
  color: white;
  font-size: 150%;
  font-family: HomePage-Book;
}

#hAmountmd {
  color: white;
  font-size: 35pt;
  margin-top: -3%;
  font-family: Gotham-Ultra;
}

#slidermd {
  width: 80%;
  margin-top: -1%;
  margin-left: 10%;
}

#spanwhomd {
  color: white;
  margin-top: -1%;
  font-size: 150%;
  font-family: HomePage-Book;
}

#spaninstmd {
  font-size: 15pt;
  color: white;
  margin-top: -10%;
  font-family: HomePage-Book;
}

#main-container-comments-hp {
  align-items: center;
  padding: 5%;
  background-color: white;
  height: 100%;
}

#main-container-comments-hplg {
  height: 100%;
  padding: 5rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background 1500 px.png");
}

#main-container-questions-hp {
  height: 100%;
  width: 105%;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5%;
  margin-left: -0.5%;
  background-image: url("https://dbmenos.com/img/landings-pantallas/pantallas-mobile/Fondo+azul.png");
}

#main-container-questions-hplg {
  height: 100%;
  width: 105%;
  align-items: center;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 5%;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background+blue-01.png");
}

#quoteproxs {
  position: absolute;
  align-items: center;
  color: #2a3d9a;
  width: 100%;
  top: 40%;
  right: 20%;
  line-height: 100%;
}

#quotequexs {
  position: absolute;
  align-items: center;
  color: #ffff;
  width: 100%;
  top: 25%;
  right: 20%;
  line-height: 100%;
}

#quoteoptsx {
  position: absolute;
  align-items: center;
  color: #808080;
  width: 100%;
  top: 15%;
  right: 18%;
  line-height: 100%;
}

#reviews-imgxs-hp {
  width: 50%;
  margin-left: 20%;
}

#reviews-imgxs-que-hp {
  width: 40%;
  margin-left: 30%;
}

#spanproxs {
  font-size: 12pt;
  font-family: HomePage-Bold;
}

#spanprobxs {
  font-family: HomePage-Book;
}

#spanquexs {
  font-size: 15pt;
  font-family: HomePage-Bold;
}

#spanquexs-r {
  font-size: 14pt;
  text-align: left;
  color: #ffff;
  font-family: HomePage-Book;
}

#spanquebxs {
  font-family: HomePage-Book;
}

#spanopxs {
  font-size: 13pt;
  font-family: HomePage-Bold;
}

#spanopbxs {
  font-family: HomePage-Book;
}

#quotethreexs {
  padding: 2%;
}

#spanaxs {
  font-family: HomePage-Bold;
  color: #2a3d9a;
  font-size: 13pt;
  margin-left: 5%;
}

#paxs {
  font-family: HomePage-Book;
  color: #848485;
  font-size: 12pt;
  text-align: justify;
  line-height: 100%;
}

#spanquexs {
  font-family: HomePage-Bold;
  color: #fff;
  font-size: 15pt;
}

#quotesm-hp {
  position: absolute;
  padding: 8%;
  color: #808080;
  width: 60%;
  top: 2.5rem;
  left: 45%;
}

#spansm-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 100%;
  text-align: left;
}

#quoteprosm {
  position: absolute;
  align-items: center;
  color: #2a3d9a;
  width: 80%;
  top: 40%;
  right: 35%;
  line-height: 100%;
}

#quotequesm {
  position: absolute;
  align-items: center;
  color: #ffff;
  width: 80%;
  top: 15%;
  right: 35%;
  line-height: 150%;
}

#quoteoptsm {
  position: absolute;
  align-items: center;
  color: #808080;
  width: 100%;
  top: 23%;
  right: 25%;
  line-height: 100%;
}

#reviews-imgsm-hp {
  width: 45%;
  margin-left: 25%;
}

#reviews-imgsm-que-hp {
  width: 35%;
  margin-left: 30%;
}

#spanprosm {
  font-size: 18pt;
  font-family: HomePage-Bold;
}

#spanprobsm {
  font-family: HomePage-Book;
}

#spanquesm {
  font-size: 15pt;
  font-family: HomePage-Bold;
}

#spanquesm-r {
  font-size: 20pt;
  text-align: left;
  color: #ffff;
  font-family: HomePage-Book;
}

#spanquebsm {
  font-family: HomePage-Book;
}

#spanopbsm {
  font-family: HomePage-Book;
}

#quotethreesm {
  padding: 5%;
}

#spanasm {
  font-family: HomePage-Bold;
  color: #2a3d9a;
  font-size: 18pt;
  margin-left: 5%;
}

#pasm {
  font-family: HomePage-Book;
  color: #848485;
  font-size: 16pt;
  text-align: justify;
  line-height: 100%;
}

#spanquesm {
  font-family: HomePage-Bold;
  color: #fff;
  font-size: 20pt;
}

#spanopsm {
  font-size: 20pt;
  font-family: HomePage-Bold;
}

#spanopbsm {
  font-family: HomePage-Book;
}

#quoteoptsm {
  position: absolute;
  color: #808080;
  width: 100%;
  top: 40%;
  right: 10%;
  line-height: 100%;
}

#reviews-imgsm {
  width: 70%;
  right: 20%;
}

#quotemd-hp {
  position: absolute;
  padding: 8%;
  color: #808080;
  width: 60%;
  top: 3rem;
  left: 45%;
}

#spanmd-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 100%;
  text-align: left;
}

#quotepromd {
  position: absolute;
  align-items: center;
  color: #2a3d9a;
  width: 80%;
  top: 45%;
  right: 35%;
  line-height: 140%;
}

#quotequemd {
  position: absolute;
  align-items: center;
  color: #ffff;
  width: 80%;
  top: 15%;
  right: 35%;
  line-height: 150%;
}

#reviews-imgmd-hp {
  width: 45%;
  margin-left: 35%;
}

#reviews-imgmd-que-hp {
  width: 35%;
  margin-left: 30%;
}

#reviews-imglg-hp-que-hp {
  width: 25%;
  margin-left: 45%;
}

#spanpromd {
  font-size: 22pt;
  font-family: HomePage-Bold;
}

#spanprobmd {
  font-family: HomePage-Book;
}

#spanquemd-r {
  font-size: 25pt;
  text-align: left;
  color: #ffff;
  font-family: HomePage-Book;
}

#spanquebmd {
  font-family: HomePage-Book;
}

#spanopbmd {
  font-family: HomePage-Book;
}

#quotethreemd {
  padding: 5%;
}

#spanamd {
  font-family: HomePage-Bold;
  color: #2a3d9a;
  font-size: 25pt;
  margin-left: 5%;
}

#pamd {
  font-family: HomePage-Book;
  color: #848485;
  font-size: 19pt;
  text-align: justify;
  line-height: 100%;
}

#spanquemd {
  font-family: HomePage-Bold;
  color: #fff;
  font-size: 25pt;
}

#quotelg-hp {
  position: absolute;
  padding: 8%;
  color: #808080;
  width: 50%;
  top: 8rem;
  left: 90%;
}

#spanlg-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 100%;
  text-align: left;
}

#quoteprolg {
  position: absolute;
  align-items: center;
  color: #2a3d9a;
  width: 80%;
  top: 45%;
  right: 35%;
  line-height: 140%;
}

#quotequelg {
  position: absolute;
  align-items: center;
  color: #ffff;
  width: 80%;
  top: 25%;
  right: 40%;
  line-height: 150%;
}

#reviews-imglg-hp-hp {
  width: 35%;
  margin-left: 35%;
}

#reviews-imgmd-que-hp {
  width: 35%;
  margin-left: 30%;
}

#spanprolg {
  font-size: 28pt;
  font-family: HomePage-Bold;
}

#spanproblg {
  font-family: HomePage-Book;
}

#spanquemlg {
  font-size: 19pt;
  font-family: HomePage-Bold;
}

#spanquelg-r {
  font-size: 25pt;
  text-align: left;
  color: #ffff;
  font-family: HomePage-Book;
}

#spanqueblg-hp {
  font-family: HomePage-Book;
}

#spanopblg-hp {
  font-family: HomePage-Book;
}

#quotethreelg {
  padding: 5%;
}

#spanalg {
  font-family: HomePage-Bold;
  color: #2a3d9a;
  font-size: 25pt;
  margin-left: 5%;
}

#palg {
  font-family: HomePage-Book;
  color: #848485;
  font-size: 20pt;
  text-align: justify;
  line-height: 100%;
}

#spanquelg {
  font-family: HomePage-Bold;
  color: #fff;
  font-size: 30pt;
}

#spanoplg-hp {
  font-size: 25pt;
  font-family: HomePage-Bold;
}

#spanopblg-hp {
  font-family: HomePage-Book;
}

#spanopblg-hp {
  font-family: HomePage-Book;
}

#reviews-imglg-hp {
  width: 45%;
  margin-left: 20%;
}

#quotexl-hp {
  position: absolute;
  padding: 10%;
  color: #808080;
  width: 50%;
  top: 10%;
  left: 75%;
}

#spanxl-hp {
  font-family: Metropolis-ExtraLight;
  font-size: 100%;
  text-align: left;
}

#quoteproxl {
  position: absolute;
  align-items: center;
  color: #2a3d9a;
  width: 50%;
  top: 30%;
  right: 60%;
  line-height: 140%;
}

#quotequexl {
  position: absolute;
  align-items: center;
  color: #ffff;
  width: 50%;
  top: 0;
  right: 60%;
  line-height: 150%;
}

#reviews-imgxl-hp {
  width: 20%;
  margin-left: 60%;
}

#reviews-imgxl-que-hp {
  width: 20%;
  margin-top: -5%;
  margin-left: 60%;
}

#spanproxl {
  font-size: 28pt;
  font-family: HomePage-Bold;
}

#spanprobxl {
  font-family: HomePage-Book;
}

#spanquemxl {
  font-size: 19pt;
  font-family: HomePage-Bold;
}

#spanquexl-r {
  font-size: 20pt;
  text-align: left;
  color: #ffff;
  font-family: HomePage-Book;
}

#spanquebxl {
  font-family: HomePage-Book;
}

#spanopbxl {
  font-family: HomePage-Book;
}

#quotethreexl {
  margin: 10%;
  margin-top: -1%;
  margin-bottom: -1%;
  padding: 1%;
}

#spanaxl {
  font-family: HomePage-Bold;
  color: #2a3d9a;
  font-size: 25pt;
  margin-left: 5%;
}

#paxl {
  font-family: HomePage-Book;
  color: #848485;
  font-size: 20pt;
  line-height: 100%;
}

#spanquexl {
  font-family: HomePage-Bold;
  color: #fff;
  font-size: 30pt;
}

#spanoplxl {
  font-size: 25pt;
  font-family: HomePage-Bold;
}

#spanopbxl {
  font-family: HomePage-Book;
}

#spanopbxl {
  font-family: HomePage-Book;
}

#reviews-imgxl {
  width: 50%;
  right: 10%;
}

#spanCalxl {
  color: #ffff;
  font-family: HomePage-Bold;
}

#spanDebxl {
  color: #ffff;
  font-size: 110%;
  font-family: HomePage-Book;
}

#hAmountxl {
  color: #ffff;
  margin-top: -2%;
  font-family: Gotham-Ultra;
}

#sliderxl {
  width: 80%;
  margin-top: 1%;
  margin-left: 10%;
}

#spanwhoxl {
  color: #ffff;
  font-size: 30pt;
  font-family: HomePage-Book;
}

#spaninstxl {
  font-size: 90%;
  color: #ffff;
  margin-top: -5%;
  font-family: HomePage-Book;
}

#spanopbxl {
  font-family: HomePage-Book;
}

#quoteoptxl {
  position: absolute;
  color: #808080;
  width: 85%;
  top: 25%;
  right: 20%;
}

#reviews-imgxl {
  width: 40%;
  margin-left: 10rem;
}

#fb-postlg {
  margin-top: 1rem;
}

#fb-postxs {
  margin-top: 2rem;
}

#main-container-des-hp {
  height: 100%;
  padding: 7%;
  margin-left: -4rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background Man home_Mesa de trabajo 1-01-01.png");
}

#main-container-desxl {
  height: 100%;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background Man 1500px_Mesa de trabajo 1.png");
}

#quote-des-hp {
  position: absolute;
  color: #808080;
  width: 40%;
  top: 15%;
  right: -25%;
}

#quote-calculator-desc-hlg {
  margin-left: 12rem;
  height: 120%;
  margin-top: -4rem;
  border-radius: 2rem;
  width: 70%;
  padding: 3%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Envolvente azul_Mesa de trabajo 1.png");
}

#quote-calculator-desc-hxl {
  margin-left: 12rem;
  height: 120%;
  margin-top: -5rem;
  border-radius: 2rem;
  width: 70%;
  padding: 3%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Envolvente azul_Mesa de trabajo 1.png");
}

#spanCallg-hp {
  color: #ffff;
  font-family: HomePage-Bold;
}

#spanDeblg-hp {
  color: #ffff;
  font-size: 110%;
  font-family: HomePage-Book;
}

#hAmountlg-hp {
  color: #ffff;
  margin-top: -2%;
  font-family: Gotham-Ultra;
}

#sliderlg-hp {
  width: 80%;
  margin-top: 1%;
  margin-left: 10%;
}

#spanwholg-hp {
  color: #ffff;
  font-size: 20pt;
  margin-top: 1%;
  font-family: HomePage-Book;
}

#spaninstlg-hp {
  font-size: 90%;
  color: #ffff;
  margin-top: -5%;
  font-family: HomePage-Book;
}

#quote-des-hp {
  position: absolute;
  color: #808080;
  width: 40%;
  top: 10%;
  right: -25%;
}

#quote-desxl {
  position: absolute;
  color: #808080;
  width: 25%;
  top: 8%;
  right: -20%;
}

#spanopblg-hp {
  font-family: HomePage-Book;
}

#quoteoptlg-hp {
  position: absolute;
  color: #808080;
  width: 100%;
  top: 15%;
  right: 20%;
}

#quoteoptxl-hp {
  position: absolute;
  color: #808080;
  width: 100%;
  top: 35%;
  right: 20%;
}

#main-container-comments-lg {
  height: 100%;
  padding: 2rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background 1500 px.png");
}

.tooltip-inner {
  border-radius: 2rem;
  animation: pulse2 2s infinite;
  background: #f3d338;
  background: -webkit-linear-gradient(
      top,
      #f3d338 0%,
      #f3d338 44%,
      #f3d338 100%
  );
  background: -moz-linear-gradient(top, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  background: linear-gradient(to bottom, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3d338', endColorstr='#f3d338', GradientType=0);
  color: #2a3d9a !important;
  font-family: "Roboto", Arial, sans-serif;
}

.tooltip-inner-des {
  filter: drop-shadow(0px 0px 1px black);
  border-radius: 1rem;
  animation: pulse2 2s infinite;
  background: #f3d338;
  background: -webkit-linear-gradient(
      top,
      #f3d338 0%,
      #f3d338 44%,
      #f3d338 100%
  );
  background: -moz-linear-gradient(top, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  background: linear-gradient(to bottom, #f3d338 0%, #f3d338 44%, #f3d338 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f3d338', endColorstr='#f3d338', GradientType=0);
  color: #2a3d9a !important;
  font-family: "Roboto", Arial, sans-serif;
}

.speech-bubble {
  background: #f3d338; /* el color de fondo */
  color: #2a3d9a !important;
  font-family: HomePage-Bold;
  font-size: 15px;
  padding: 5px 1px 1px 1px;
  text-align: center;
  width: 250px;
  -moz-border-radius: 10px;
  animation: pulse2 2s infinite;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}

.speech-bubble:after {
  border: solid 10px transparent;
  border-top-color: #f3d338; /* igual al color de fondo */
  border-bottom: 0;
  bottom: -10px; /* igual al borde + el padding-bottom de la definición anterior */
  content: "";
  display: block;
  height: 0;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 0;
}

.speech-bubble-mobile {
  background: #f3d338; /* el color de fondo */
  color: #2a3d9a !important;
  font-family: HomePage-Bold;
  font-size: 15px;
  padding: 5px 1px 1px 1px;
  text-align: center;
  width: 250px;
  -moz-border-radius: 10px;
  animation: pulse2 2s infinite;
  -webkit-border-radius: 10px;
  border-radius: 20px;
}

.speech-bubble-mobile:after {
  border: solid 10px transparent;
  border-top-color: #f3d338; /* igual al color de fondo */
  border-bottom: 0;
  bottom: -10px; /* igual al borde + el padding-bottom de la definición anterior */
  content: "";
  display: block;
  height: 0;
  margin: auto;
  overflow: hidden;
  position: relative;
  width: 0;
}

.tooltip.bs-tooltip-right .arrow:before {
  border-right-color: #f3d338 !important;
}

.tooltip.bs-tooltip-left .arrow:before {
  border-left-color: #f3d338 !important;
}

.tooltip.bs-tooltip-bottom .arrow:before {
  border-bottom-color: #f3d338 !important;
}

.tooltip.bs-tooltip-top .arrow:before {
  border-top-color: #f3d338 !important;
}

.custom-tooltip {
  display: inline-block;
  opacity: 1;
  position: relative;
}

input[type="range"] {
  margin: auto;
  -webkit-appearance: none;
  position: relative;
  overflow: hidden;
  height: 15px;
  width: 80%;
  cursor: pointer;
  border-radius: 10px; /*iOS */
}

::-webkit-slider-runnable-track {
  background: #ddd;
}

/*
 * 1. Set to 0 width and remove border for a slider without a thumb
 */
::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 15px;
  width: 15px;
  background: #f1b833;
  border-radius: 100%;
  border: 0px solid #f1b833;
  text-shadow: 1px 1px 1px #2a3d9a;
  box-shadow: -100vw 0 0 99.5vw #f3d338;
}

::-moz-range-track {
  height: 40px;
  background: #f3d338;
}

::-moz-range-thumb {
  background: #fff;
  height: 40px;
  width: 20px;
  border: 3px solid #f3d338;
  border-radius: 0 !important;
  box-shadow: -100vw 0 0 100vw dodgerblue;
  box-sizing: border-box;
}

::-ms-fill-lower {
  background: #f3d338;
}

::-ms-thumb {
  background: #f3d338;
  border: 2px solid #f3d338;
  height: 40px;
  width: 20px;
  box-sizing: border-box;
}

::-ms-ticks-after {
  display: none;
}

::-ms-ticks-before {
  display: none;
}

::-ms-track {
  background: #f3d338;
  color: transparent;
  height: 40px;
  border: none;
}

::-ms-tooltip {
  display: none;
}
</style>
