<template>
    <div>
        <section id="top">
        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6" style="margin-top: 60px;">

                            </div>
                            <div class="col-lg-12">
                                <h6 class="display-1 text-center" style="color: #3953d3">¡Recupera tu libertad financiera!</h6>
                                <p class="lead text-center text-white"><span style="color: #3953d3;">Con <span class="display-4">DB Menos</span>, dile adiós a tus deudas!</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        </section>

    </div>
</template>

<script>
    import {V} from "@/V"
    export default {
      name: "home",
      components: { },
        mounted() {
            this.m_insertMarketingLead()
        },
        data() {
          return {
              V: V
          }
        },
        methods: {
            async m_insertMarketingLead() {
                let from = this.$route.params['pathMatch']
                if (from !== undefined && from.length > 0) {
                    console.log(from)
                    this.$store.commit('setMarketing', from)
                    let url = V.SERVER + V.OPS.INSERT + V.TABLES.MARKETING_LEADS
                    await this.$axios.post(url, {from: from})
                    this.$router.push("/")
                } else {
                    this.$router.push("/")
                }
            }
        }
    };
</script>
