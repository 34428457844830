<template>

  <div class="bg-principal">

    <div class="row col-12 col-sm-10 mx-auto p-0 p-sm-3 py-sm-5 justify-content-center">

      <div class="col-12 order-1 mt-8">
        <p class="text-center text-white section-title open-sans-light mb-0 mb-sm-3">
          Obtén tu propuesta y <br> <span class="open-sans-bold">descuento en minutos</span></p>
      </div>

      <div class="col-12 col-md-6 col-sm-7 p-0 order-3 order-sm-2 mb-7">
      <!-- <div class="col-12 col-md-5 col-sm-7 p-0 order-3 order-sm-2 mb-7"> -->
        <div class="col-12 col-sm-10 col-lg-12 mx-auto text-center text-sm-right">
          <img src="/img/financial_freedom/images/programa.png" alt="focus" class="mr-sm-5 img-border">
        </div>      
      </div>

      <div class="col-10 col-md-6 col-sm-5 my-auto order-2 order-sm-3">
      <!-- <div class="col-12 col-md-7 col-sm-5 my-auto order-2 order-sm-3"> -->
        <div class="d-flex my-2 justify-content-center justify-content-sm-start">
          <p class="text-left text-white secondary-text open-sans-bold pl-0 pr-0">
            Plan de ahorro en 3 sencillos pasos
          </p>
        </div>

        <div class="d-flex my-2">
          <div class="pt-0 pr-5 text-end">
            <i class="fa fa-check fa-lg"></i>
          </div>
          <div class="p-0">
            <p class="p-0 pl-sm-4 text-white secondary-text open-sans-regular">
              Registro con tus datos
            </p>
          </div>
        </div>
        <!-- <div class="row my-2">
          <div class="col-3 col-sm-2 pt-0 text-end">
            <i class="fa fa-check fa-lg"></i>
          </div>
          <div class="col-7 col-sm-10 p-0">
            <p class="p-0 pl-sm-4 text-white secondary-text open-sans-regular">
              Registro con tus datos
            </p>
          </div>
        </div> -->

        <div class="d-flex my-2">
          <div class="pt-0 pr-5 text-end">
            <i class="fa fa-check fa-lg"></i>
          </div>
          <div class="p-0">
            <p class="p-0 pl-sm-4 text-white secondary-text open-sans-regular">
              Consulta de buró de crédito
            </p>
          </div>
        </div>
        <!-- <div class="row my-2">
          <div class="col-3 col-sm-2 pt-0 text-end">
            <i class="fa fa-check fa-lg"></i>
          </div>
          <div class="col-7 col-sm-10 p-0">
            <p class="p-0 pl-sm-4 text-white secondary-text open-sans-regular">
              Consulta de buró de crédito
            </p>
          </div>
        </div> -->

        <div class="d-flex my-2">
          <div class="pt-0 pr-5 text-end">
            <i class="fa fa-check fa-lg"></i>
          </div>
          <div class="p-0">
            <p class="p-0 pl-sm-4 text-white secondary-text open-sans-regular">
              Plan personalizado y descuento
            </p>
          </div>
        </div>
        <!-- <div class="row my-2">
          <div class="col-3 col-sm-2 pt-0 text-end">
            <i class="fa fa-check fa-lg"></i>
          </div>
          <div class="col-7 col-sm-10 p-0">
            <p class="p-0 pl-sm-4 text-white secondary-text open-sans-regular">
              Plan personalizado y descuento
            </p>
          </div>
        </div> -->

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "CheckDescription",
  data: () => ({
    icon: '/img/financial_freedom/icons/check.png',
  })
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.img-border{
  border-radius: 18px;
  width: 90%;
  @media (min-width: $tablet){
    width: 90%;
  }
  @media (min-width: $large-desktop){
    width: 50%;
  }
}

/*Fonts*/
.open-sans-light {
  font-family: OpenSans-Light;
}

.open-sans-bold {
  font-family: OpenSans-Bold;
}

.open-sans-regular {
  font-family: OpenSans-Regular;
}

/*Backgrounds*/

.bg-principal {
  background: linear-gradient(to bottom, #2A3D99, #29AAE1)
}

/*Icons*/

.fa {
  color: #FFFFFF;
}

.section-title br {
  display: none;
}


@media (max-width: 575px) {
  /* .bg-principal {
    background: linear-gradient(to bottom, #FFFFFF, #FFFFFF)
  } */

  .section-title {
    font-size: 1.2rem;
    /* color: #2A3D99 !important; */
  }

  .section-title br {
    display: block;
  }


  /* .secondary-text {
    color: #2A3D99 !important;
  } */

  .secondary-text br {
    display: none;
  }

  /* .fa {
    color: #2A3D99;
  } */
  
}

@media (min-width: 576px) and (max-width: 767px) {
  .section-title {
    font-size: 1.2rem;
  }

  .secondary-text {
    font-size: .8rem;
  }

  .fa {
    color: #FFFFFF;
    font-size: 1.5rem;
  }
}

@media (min-width: 768px) and (max-width: 991px) {

  .section-title {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: .8rem;
  }

  .fa {
    font-size: 1.1rem;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .section-title {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1rem;
  }

  .secondary-text br
  {
    display: none;
  }

  .fa {
    font-size: 1.3rem;
  }
}

@media (min-width: 1200px) and (max-width: 2459px) {
  .section-title {
    font-size: 1.4rem;
  }

  .secondary-text {
    font-size: 1.2rem;
  }

  .fa {
    font-size: 1.4rem;
  }
}

@media (min-width: 2560px) {

  .section-title {
    font-size: 2.3rem;
  }

  .secondary-text {
    font-size: 2rem;
  }

  .fa {
    font-size: 2rem;
  }
}

</style>