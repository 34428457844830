<template>
  <section
    class="section section-shaped section-lg my-0"
    style="background-color: #3953d3; background-size: cover; opacity: 0.8"
  >
    <div class="container" style="padding-bottom: 15px">
      <div class="row justify-content-center">
        <div class="col-lg-10" style="opacity: 0.95">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-10 py-lg-10"
            class="border-0"
          >
            <div class="row justify-content-around align-items-center">
              <div class="col-lg-4 col-md-4 col-sm-5 col-8 text-center">
                <img
                  src="https://dbmenos.com/img/brand/DB Menos logo 2020_membrete.png"
                  class="img-fluid"
                  alt="Db menos Logo"
                />
              </div>
            </div>
          </card>
        </div>
      </div>
    </div>
    <div class="container pt-lg-md" style="min-height: 730px">
      <div class="row justify-content-center">
        <div class="col-lg-10" style="opacity: 0.95">
          <card
            type="secondary"
            shadow
            header-classes="bg-white pb-5"
            body-classes="px-lg-10 py-lg-10"
            class="border-0"
          >
            <template>
              <div v-if="dontProposal" class="text-center">
                <h5>Descargando su propuesta</h5>
                <img src="https://dbmenos.com/img/theme/checkbox-marked-circle.png"/>
              </div>
              <div v-if="downloadComplete" class="text-center">
                <h5>Se ha descargado su propuesta, verifique que todo este correcto y Comúnicate con tu asesor</h5>
                <img src="https://dbmenos.com/img/theme/checkbox-marked-circle.png" />
              </div>
              <div v-if="!dontProposal && !downloadComplete" class="text-center">
                <h5>
                  No logramos validar tu enlace para descargar la propuesta,
                  comúnicate con tu asesor
                </h5>
                <img src="https://dbmenos.com/img/theme/close-circle.png" />
              </div>
            </template>
          </card>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { V } from "@/V";
import { VBModalPlugin } from "bootstrap-vue";
export default {
  name: "home",
  components: {},
  mounted() {
    this.propuesta = this.$route.query.token;
    this.getPropuestaClient();
  },
  data() {
    return {
      V: V,
      propuesta: "",
      dataProposal: "",
      window: {
        width: 0,
        height: 0,
      },
      dontProposal: false,
      user: {
        name: null,
        address: null,
        address_2: null,
        phone: null,
        email: null,
      },
      downloadComplete:false,
    };
  },
  methods: {
    async getPropuestaClient() {
        this.$showLoader(true)
        console.log(this.$route.params['pathMatch'])
      if (this.$route.params['pathMatch']) {
        let url =
          V.SERVER +
          V.OPS.URL_SHORT_PROPUESTA +
          "?token=" +
          this.$route.params['pathMatch'];
        await this.$axios.get(url).then(async (res) => {
            this.$showLoader(false)
          if (res.status == 200) {
            let data = res.data;
            if (data.success) {
                this.$showLoader(false)
              this.dataProposal = res.data[this.$v.API.ROWS].url + "";
              this.dontProposal = true;
              if(this.dontProposal){
              this.downloadComplete = true;
              this.dontProposal = false;
              window.location.href = this.dataProposal;
              }
            } else {
              switch (data.reason) {
                case 1:
                  this.dontProposal = false;
              }
            }
          }
        });
      }
    },
  },
};
</script>
