<template>
    <div>

        <div class="position-relative">
            <!-- shape Hero -->
            <section class="section-shaped my-0">
                <div class="shape shape-style-1 shape-default shape-skew">
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
                <div class="container shape-container d-flex">
                    <div class="col px-0">
                        <div class="row">
                            <div class="col-lg-6">
                                <h1 class="display-3 text-white">Es hora de vivir sin deudas
                                    <span>Calcula tu descuento</span>
                                </h1>
                                <p class="lead  text-white">En db menos te haremos un plan especializado basado en tus necesidades
                                y deudas que deseas cubrir. Ingresa las deudas y obten tu plan de pagos</p>

                                <p class="lead  text-white">  Si a quien le debes no aparece su logo, significa que por el momento no podemos ofrecerte un plan de liquidación, 
                                    estamos trabajando para pronto poder ayudarte</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <!-- 1st Hero Variation -->
        </div>

        <section class="section section-lg pt-lg-0 mt--200 text-center" v-if="!reportListo">
            <div class="container">

                <!--<div class="row justify-content-center" style="margin-bottom: 40px">
                    <div class="col-lg-12">
                        <div class="row justify-content-center">
                            <div class="col-lg-6">
                                <card class="border-0" shadow body-classes="py-5">
                                    <div class="row justify-content-center">
                                        <div class="col">
                                            <h3 class="text-primary text-center">{{period}} meses</h3>
                                        </div>
                                    </div>
                                    <div class="row justify-content-center">
                                        <div class="col">
                                            <base-slider :range="{min: 6, max: 48}" :options="{step: 1}" v-model.number="period"></base-slider>
                                            <h6 class="text-info text-center">Duración del programa</h6>
                                        </div>
                                    </div>
                                </card>
                            </div>
                        </div>
                    </div>
                </div>-->

                <div class="row justify-content-center">
                    <div class="col-lg-12">
                        <div class="row row-grid justify-content-center">

                            <div class="col-lg-4" v-for="(debt, indexdeb) in debts" :key="indexdeb">

                                <card class="border-0" shadow body-classes="py-5" style="margin-top: 15px">

                                    <div v-if="debt.edit">
                                        <base-dropdown class="nav-item" menu-classes="dropdown-menu-lg" style="margin-bottom: 20px; margin-left: -10px">
                                            <a slot="title" class="nav-link" data-toggle="dropdown" role="button">
                                                <base-button type="primary">
                                                    {{debt.bank}}
                                                </base-button>
                                            </a>
                                            <div class="dropdown-menu-inner" style="overflow-x: hidden; overflow-y: scroll; height: 250px">
                                                <ul>
                                                    <li v-for="(bank, index) in banks" :key="index" style="list-style-type: none">
                                                        <a v-on:click="m_bankSelected(indexdeb, bank)" style="margin-left: -12px; margin-top: 3px; margin-bottom: 3px" class="media d-flex align-items-center">
                                                            <div class="icon icon-shape bg-secondary rounded-circle text-white" style="margin-top: 12px">
                                                                <div class="g-item">
                                                                    <img class="img-responsive" v-bind:src="bank.url" style="height: 100%; width: 100%; object-fit: contain">
                                                                </div>
                                                            </div>
                                                            <div class="media-body ml-2" style="margin-top: 10px">
                                                                <h6 class="heading text-primary mb-md-1">{{bank.name}}</h6>
                                                            </div>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </base-dropdown>
                                    </div>
                                    <div v-else>
                                        <div class="row">
                                            <div class="col-md-9" style="margin-top: 2px">
                                                <h3 class="text-primary mb-md-1">{{debt.bank}}</h3>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="g-item">
                                                    <img class="img-responsive" v-bind:src="getBank(debt.bankid).url" style="height: 100%; width: 100%; object-fit: contain">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--<icon name="ni ni-check-bold" type="primary" rounded class="mb-4"></icon>-->

                                    <div v-if="debt.edit">
                                        <label for="amount" style="width: 100%">¿Cuánto debes?</label>
                                        <base-input id="amount" placeholder="0" v-model="debt.amount"></base-input>
                                        <base-slider :range="{min: 3000, max: 100000}" v-model="debt.amount"></base-slider>
                                        <label for="last_bank_payment_date" style="width: 100%"
                                        >Fecha Último Pago</label
                                        >
                                        <flat-pickr
                                        class="btn btn-sm"
                                        data-vv-as="Fecha"
                                        type="primary"
                                        v-model="debt.last_bank_payment_date"
                                        :placeholder="today"
                                        style="
                                            margin-top: -0.5rem;
                                            margin-bottom: 0.5rem;
                                            background-color: #0000;
                                            width: 100%;
                                        "
                                        />
                                        <div class="row">
                                            <div class="col">
                                                <base-button v-on:click="m_spliceDebt(indexdeb)" n block type="danger" class="mt-4">
                                                    Borrar
                                                </base-button>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <base-button v-on:click="m_editDebt(indexdeb, false)" block type="primary" class="mt-2">
                                                    Listo
                                                </base-button>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-else style="margin-top: 20px">
                                        <h3 id="h6amount" class="text-primary">$ {{Number(debt.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}}</h3>
                                        <label for="h6amount" style="width: 100%">Deuda total</label>
                                         <h5 id="h6amount" class="text-primary">{{debt.last_bank_payment_date}}</h5>
                                        <label for="h6amount" style="width: 100%">Último Pago</label>
                                        <div class="row">
                                            <div class="col">
                                                <base-button v-on:click="m_editDebt(indexdeb, true)" block type="primary" class="mt-4">
                                                    Editar
                                                </base-button>
                                            </div>
                                        </div>
                                    </div>

                                </card>

                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" style="margin-top: 40px">
                        <div class="col">
                            <div class="btn-wrapper">
                                <base-button tag="a"
                                             v-on:click="m_appendDebt()"
                                             class="mb-3 mb-sm-0"
                                             type="white"
                                             icon="fa fa-plus">
                                    Agrega nueva deuda
                                </base-button>
                                <base-button tag="a"
                                             v-on:click="m_calculateDiscount()"
                                             class="mb-3 mb-sm-0"
                                             type="success"
                                             icon="fa fa-check">
                                    Estoy listo
                                </base-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section
        v-if="reportListo"
        class="section section-lg pt-lg-0 mt--300"
      >
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-lg-12">
              <div class="row row-grid justify-content-center">
                <div
                  class="col-lg-4 card-debts"
                  v-for="(debt, id) in calculatorDebts.debts"
                  :key="id"
                >
                  <div v-if="reportListo">
                    <b-card
                      class="border-0 tam mb-4"
                      shadow
                      body-classes="py-5"
                      style="margin-top: 5px; border-radius: 1rem; height: auto"
                    >
                      <div class="row">
                        <div class="media-body ml-2" style="margin-top: 1rem">
                          <h4 class="text-primary">
                            {{ debt.bank }}
                          </h4>
                        </div>
                        <div class="col-md-3">
                          <div class="g-item" style="margin-top: 0.5rem; height: 100%; width: 100%;">
                            <img
                              class="img-responsive"
                              v-bind:src="getBank(debt.bankid).url"
                              style="
                                height: 100%;
                                width: 100%;
                                object-fit: contain;
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="text-center" style="margin-top: -10px">
                        <br />

                        <!-- ELIMINAR -->
                        <div class="row">
                          <div class="col">
                              <base-button v-on:click="m_spliceCalculatorDebts(debt.id)" n block type="danger" class="mt-4">
                                delete ID: 
                                {{id}}
                              </base-button>
                          </div>
                        </div>
                        <!-- ELIMINAR -->



                        <label for="h6amount" style="width: 100%"
                          >Deuda total</label
                        >

                        <h3 id="h6amount" class="text-primary">
                          $
                          {{
                            Number(debt.amount)
                              .toFixed(2)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          }}
                        </h3>
                        <div>
                          <label for="h6amount" style="width: 100%"
                            >Descuento</label
                          >
                          <h3 id="h6amount" class="text-primary">
                            {{
                              formatDecPost(
                                (1 - debt.willPay / debt.amount) * 100
                              )
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}%
                          </h3>
                        </div>
                        <div>
                          <label for="h6amount" style="width: 100%"
                            >Pagarás</label
                          >
                          <h3 id="h6amount" class="text-primary">
                            $
                            {{
                              formatDecPost(debt.willPay)
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                            }}
                          </h3>
                        </div>
                        <label for="h5amount2" style="width: 100%"
                          >Meses Atrasados: {{ debt.due_months }}
                        </label>
                        <label for="h5amount2" style="width: 100%"
                          >Último Pago: {{ debt.last_bank_payment_date }}
                        </label>
                        <div>
                          <label for="h6amount2" style="width: 100%"
                            >Se liquidará en
                            {{ debt.willPayOnMonth }} meses</label
                          >
                        </div>
                      </div>
                    </b-card>
                  </div>
                </div>
              </div>
              <div></div>
            </div>
          </div>
          <br />
          <card
            v-if="reportListo"
            style="margin-top: 1rem; border-radius: 2rem"
          >
            <div
              class="container shape-container d-flex"
              style="margin-top: 10px"
            >
              <div class="col px-0">
                <div class="row">
                  <div class="col-lg-12">
                    <h1 class="display-3 text-black text-center">
                      Programa DB Menos
                    </h1>
                  </div>
                </div>
              </div>
            </div>

            <div class="container" style="max-width: 1600px !important">
              <div
                class="row justify-content-center"
                style="margin-bottom: 40px"
              >
                <div class="col-lg-12">
                  <div class="row justify-content-center">
                    <div class="col-lg-12">
                      <card class="border-0">
                        <div class="row justify-content-center">
                          <div class="col text-center">
                            <h1
                              class="h1 font-weight-bold mb-0 text-success"
                              style="font-size: 2rem"
                            >
                              {{ periodOptimal }} meses
                            </h1>
                            <h5
                              class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                               style="font-size: 1rem; margin-top:0.2rem;"
                            >
                              Duración del Programa Recomendado
                            </h5>
                            <br />


                        <!-- ELIMINAR -->
                        <div class="row">
                          <div class="col">
                              <base-button
                              v-on:click="m_discountStep2()" 
                                n 
                                block type="success" 
                                class="mt-4">
                                  OK - BANCOS
                              </base-button>
                          </div>
                        </div>
                        <!-- ELIMINAR -->


                            <base-slider
                              :range="{ min: minSlider, max: maxSlider }"
                              :options="{ step: 1 }"
                              v-model.number="period"
                              v-on:input="m_ReturnDiscountNew()"
                            ></base-slider>
                            <h6 class="text-primary text-center">
                              <h1
                                class="text-primary"
                                style="font-size: 1.5rem"
                              >
                                {{ period }} meses
                              </h1>
                              <h4
                                class="text-primary card-title text-uppercase text-muted margin-blue-h6"
                                style="font-size: 1rem; margin-top:0.2rem;"
                              >
                                Duración del Programa
                              </h4>
                            </h6>
                          </div>
                        </div>
                      </card>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="row justify-content-center text-center"
                style="margin-bottom: 40px; margin-top: -40px"
              >
                <div
                  class="col-lg-4"
                  style="margin-top: 30px; margin-left: 9rem"
                >
                  <h1
                    class="h1 font-weight-bold mb-0"
                    style="font-size: 2rem"
                  >
                    {{ formatCurrency(calculatorDebts.monthlyFee) }}
                  </h1>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                     style="margin-top: 1rem"
                  >
                    Depósito mensual
                  </h4>
                </div>
                <div class="col-lg-3" style="margin-top: 30px">
                  <h3
                    class="h1 font-weight-bold mb-0"
                    style="font-size: 2rem"
                  >
                    {{
                      formatDecPost(calculatorDebts.discount)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}%
                  </h3>
                  <h4
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                    style="margin-top: 1rem"
                  >
                    Descuento
                  </h4>
                  <br />
                </div>
                <div class="col-lg-2"></div>
                <div class="col-lg-3" style="margin-left: 5rem">
                  <h6
                    class="card-title text-uppercase text-muted mb-0 margin-yellow-h6"
                    style="margin-top: 1rem"
                  >
                    Deuda total
                  </h6>
                  <span class="h2 font-weight-bold mb-0">{{
                    formatCurrency(calculatorDebts.totalDebt)
                  }}</span>

                  <h6
                    class="card-title text-uppercase text-muted mb-0 margin-blue-h6"
                  >
                    Pagarás*
                  </h6>
                  <span class="h2 font-weight-bold mb-0">
                    {{ formatCurrency(calculatorDebts.willPay) }}</span
                  >
                </div>

                <div class="col-lg-4">
                  <div
                    class="d-flex justify-content-between margin-yellow"
                    style="
                      width: 70%;
                      height: 2rem;
                      padding: 5px;
                      background: #ffd527;
                      margin-top: 2.5rem;
                      color: black;
                      text-align: left;
                    "
                  ></div>
                  <div
                    class="d-flex justify-content-between margin-blue"
                    style="
                      width: 10%;
                      height: 2rem;
                      padding: 5px;
                      background: #3953d3;
                      color: black;
                      text-align: left;
                    "
                    :style="{
                      width:
                        calculatorDebts.totalDebt > 0
                          ? Math.ceil(
                              64 /
                                (calculatorDebts.totalDebt /
                                  calculatorDebts.willPay)
                            ) + '%'
                          : '0%',
                    }"
                  ></div>
                </div>
              </div>
            </div>
            <base-button
              block
              v-if="reportListo && !notCredit"
              v-on:click="back()"
              class="mt-4"
            >
              <b>Regresar</b>
            </base-button>
          </card>
          <br />

          
          <base-button
            block
            v-if="reportListo"
            style="
              background-color: #ffd527;
              border-color: #ffd527;
              color: #3953d3;
            "
            class="mt-4"
            v-on:click="startProgram"
          >
            <b>Inciar en DBMenos</b>
          </base-button>
        </div>
      </section>

    </div>
</template>
 
<script>
    import firebase from 'firebase/app';
    import 'firebase/firestore';
    import 'firebase/analytics';
    import 'firebase/messaging';
    import BaseDropdown from "@/components/BaseDropdown";
    import {V} from "@/V"
    import modal from "@/components/Modal";
    import flatPickr from "vue-flatpickr-component";
    export default {
        name: "home",
        components: {
            BaseDropdown,
            modal,
            flatPickr
        },
        async created() {
            firebase.analytics().logEvent("calculator");
            gtag('event', 'calculator', {'send_to': 'AW-718930865/2GivCITQvKcBELGH6NYC'});
            fbq('track', 'calculator');
            /*Events v2*/
            fbq('track', 'landing_view_automated');
            firebase.analytics().logEvent("landing_view_automated");
            gtag('event', 'landing_view_automated', {'send_to': 'AW-718930865/2GivCITQvKcBELGH6NYC'});
            this.$showLoader(true)
            this.$showLoader(false)
            this.m_registerMarketing()
            this.getDay();
        },
        data() {
            return {
                debts: [{
                    bankid: 0,
                    bank: "Selecciona el banco",
                    amount: 0,
                    last_bank_payment_date :"",
                    edit: true
                }],
                today:"",
                period: 18,
                overf: 'hidden',
                banksmodal: false,
                cambiardebts: false,
                reportListo: false,
                notCredit: false,
                bandera: true,
                error: false,
                fullPage: true,
                disableCuenta: false,
                period: 12,
                periodStart: null,
                overf: "hidden",
                banksmodal: false,
                digitAccount: "",
                dueMonthsBank: "",
              sliderRange: "",
              debtsNew:[],
                maxSlider: "",
                minSlider: "",
                calcData: {
                    period: 18,
                },
                urlAsesor: "",
                phone: "",
                jsonCalculator: "",
            }
        },
        computed: {
            user() { return this.$store.state.user },
            banks() { return this.$store.state.banks },
            getMarketing() { return this.$store.state.marketing }
        },
        methods: {
            getDay() {
                let f = new Date();
                let month = f.getMonth() + 1;
                if (month < 10) month = "0" + month;
                let today = new Date();
                this.today = this.$moment(today).format("YYYY-MM-DD");
                },
                startProgram() {
                    if(!this.user){
                    firebase
                        .analytics()
                        .logEvent("calculator_complete", this.jsonCalculator);

                    gtag("event", "starting_leads_2", {
                        send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
                    });
                    fbq("track", "calcuator_complete");

                    this.$router.push({
                        path: "/register",
                    });
                    }else{
                        let url = this.$v.SERVER + this.$v.OPS.SET_PROGRAM_CALCULATOR_CR;
                        for (let p = 0; p < this.calculatorDebts.length; p++) {
                        if (this.period === this.calculatorDebts[p].period) {
                        this.jsonCalculator = JSON.parse(
                            JSON.stringify(this.calculatorDebts[p])
                        );
                        }
                    }
                    this.$axios.post(url, this.jsonCalculator).then((response) => {
                        let data = response.data;
                        if (data[this.$v.API.SUCCESS]) {
                        firebase
                            .analytics()
                            .logEvent("calculator_complete", this.jsonCalculator);

                        gtag("event", "starting_leads_2", {
                            send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
                        });
                        fbq("track", "calcuator_complete");

                        this.$router.push({
                            path: "/start",
                        });
                        }
                    });
                    }
                },
            async m_insertMarketing() {
                let from = this.$route.query.marketing
                if (from !== undefined && from.length > 0) {
                    console.log(from)
                    this.$store.commit('setMarketing', from)
                    localStorage.setItem('Marketing', from)
                }
            },
            formatDecPost(val, pos) {
                if (!pos) pos = 2;
                if (val && Number(val)) return Number(val).toFixed(pos);
                return "0.00";
                },
                formatCurrency(value) {
                return this.$formatCurrency(value);
                },
            m_appendDebt() {
                this.debts.push({
                    bankid: 0,
                    bank: "Selecciona el banco",
                    amount: 0,
                    edit: true
                })
            },
            m_spliceDebt(index) {
                this.debts.splice(index, 1)
            },
            m_spliceCalculatorDebts(index) {
              this.calculatorDebts.debts.splice(index, 1)
              this.$forceUpdate();
              //console.log(this.calculatorDebts.debts.splice(index, 1));
        console.log(this.calculatorDebts.debts);
        console.log(this.calculatorDebts);                
            },
            m_bankSelected(debt_index, bank) {
              this.debts[debt_index].bank = bank.name
              this.debts[debt_index].bankid = bank.id
        console.log(debt_index);
        console.log(bank);
            },
            back() {
            this.reportListo = false;
            },
          m_editDebt(index, edit) {
            //console.log(index);
            this.debts[index].edit = edit
            //console.log(edit);
            this.$forceUpdate();
        console.log(this.debts)        
            },
            
            getBank(id) {
                for (let i=0 ; i<this.banks.length ; i++)
                    if (this.banks[i].id === id)
                        return this.banks[i]
                return null
            }, 
            m_ReturnDiscountNew() {
              this.discount = [];
              this.accounts = [];
              this.sliderRange = [];
              this.periodOptimal = this.periodOptimal;
              this.period = this.period;
              this.calculatorDebts = this.calculatorDebts;
        console.log(this.calculatorDebts);
        console.log(this.calculatorDebts.debts);
                this.reportListo = true;
                for (let i = 0; i < this.calculatorDebts.length; i++) {
                    for (let p = 0; p < this.calculatorDebts.length; p++) {
                    if (this.period === this.calculatorDebts[p].period) {
                        this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                        this.calculatorDebts.monthlyFee = this.calculatorDebts[p].monthlyFee;
                        this.calculatorDebts.willPay = this.calculatorDebts[p].willPay;
                        this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
                        this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                    }
                    }
                }
                let myArray = this.sliderRange;
                this.maxSlider = Math.max.apply(Math, myArray);
                this.minSlider = Math.min.apply(Math, myArray);

                this.$forceUpdate();
          },


          m_ReturnDiscountNew2() {
              this.discount = [];
              this.accounts = [];
              this.sliderRange = [];
              this.periodOptimal = this.periodOptimal;
              this.period = this.period;
              this.calculatorDebts = this.calculatorDebts;
        console.log(this.calculatorDebts);
        console.log(this.calculatorDebts.debts);
                this.reportListo = true;
                for (let i = 0; i < this.calculatorDebts.length; i++) {
                    for (let p = 0; p < this.calculatorDebts.length; p++) {
                    if (this.period === this.calculatorDebts[p].period) {
                        this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                        this.calculatorDebts.monthlyFee = this.calculatorDebts[p].monthlyFee;
                        this.calculatorDebts.willPay = this.calculatorDebts[p].willPay;
                        this.calculatorDebts.totalDebt = this.calculatorDebts[p].totalDebt;
                        this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                    }
                    }
                }
                let myArray = this.sliderRange;
                this.maxSlider = Math.max.apply(Math, myArray);
                this.minSlider = Math.min.apply(Math, myArray);

                this.$forceUpdate();
          },
            /*
          m_calculateDiscount() {
            console.log(this.debts);
                for (let d=0 ; d<this.debts.length ; d++) {
                  let debt = this.debts[d]                    
                    if (debt.bankid === 0) {
                         this.$notify({
                                text: "Necesitas seleccionar el banco correspondiente",
                                type: "error",
                            });
                        return false
                    } else if (debt.amount < 1500) {
                        this.$notify({
                                text: "Tienes una deuda menor a $1500, solo podemos negociar mayores",
                                type: "error",
                            });
                        return false
                    } else if (debt.edit) {
                        this.$notify({
                                text: "Estás editando una deuda, cuando termines de editar puedes continuar",
                                type: "error",
                            });
                        return false
                    }
                }
                let _debts = JSON.parse(JSON.stringify(this.debts))
                for (let d=0 ; d<_debts.length ; d++) {
                    _debts[d].amount = Number(_debts[d].amount)
                    delete _debts[d].edit
                }
                let user = this.user
                if (user === undefined || user === null) {
                    user = {
                        name: "",
                        lastname: "",
                        id: 1,
                    }
                }

                let _data = {
                    name: user.name,
                    lastname: user.lastname,
                    userid: user.id,
                    period: this.period,
                    debts: _debts
                }

                if (_data.name === undefined) _data.name = ""
                if (_data.lastname === undefined) _data.lastname = ""
               this.$axios
        .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR, _debts)
        .then(
          (res) => {
            let data = res.data;
            this.discount = [];
            this.calculatorDebts = [];
            this.accounts = [];
            this.sliderRange = [];
            this.period = data.row.optimalperiod;
            this.periodOptimal = data.row.optimalperiod;
            if (data.success) {
              this.reportListo = true;
              for (let i = 0; i < data.row.programs.length; i++) {
                this.sliderRange.push(data.row.programs[i].period);
                data.row.programs[i].addProgram = true;
                this.calculatorDebts.push(data.row.programs[i]);
                this.accounts = data.row.programs[i].debts;
                this.accounts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = this.calculatorDebts[
                      p
                    ].monthlyFee;
                    this.calculatorDebts.willPay = this.calculatorDebts[
                      p
                    ].willPay;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[
                      p
                    ].totalDebt;
                     this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                  }
                }
              }
              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);

              this.$forceUpdate();
            }
          },
          (er) => {
            alert("Algo salió mal, intenta nuevamente");
            console.log(er);
          }
        );
            },*/
          m_calculateDiscount() {
                for (let d=0 ; d<this.debts.length ; d++) {
                  let debt = this.debts[d];
                    if (debt.bankid === 0) {
                         this.$notify({
                                text: "Necesitas seleccionar el banco correspondiente",
                                type: "error",
                            });
                        return false
                    } else if (debt.amount < 1500) {
                        this.$notify({
                                text: "Tienes una deuda menor a $1500, solo podemos negociar mayores",
                                type: "error",
                            });
                        return false
                    } else if (debt.edit) {
                        this.$notify({
                                text: "Estás editando una deuda, cuando termines de editar puedes continuar",
                                type: "error",
                            });
                        return false
                    }
                }
                let _debts = JSON.parse(JSON.stringify(this.debts))
console.log("Array de datos" + (_debts));
                for (let d=0 ; d<_debts.length ; d++) {
                  _debts[d].amount = Number(_debts[d].amount)
console.log("Deduda total:"+(_debts[d].amount = Number(_debts[d].amount)));
                  delete _debts[d].edit
console.log(delete _debts[d].edit);                    
                }
                let user = this.user
                if (user === undefined || user === null) {
                    user = {
                        name: "",
                        lastname: "",
                        id: 1,
                    }
                }

                let _data = {
                    name: user.name,
                    lastname: user.lastname,
                    userid: user.id,
                    period: this.period,
                    debts: _debts
                }

                if (_data.name === undefined) _data.name = ""
                if (_data.lastname === undefined) _data.lastname = ""
               this.$axios
        .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR, _debts)
        .then(
          (res) => {
            let data = res.data;
            this.discount = [];
            this.calculatorDebts = [];
console.log("Calculadora de deudas ⤋");
console.log(this.calculatorDebts);



            
            this.accounts = [];
            this.sliderRange = [];
            this.period = data.row.optimalperiod;
            this.periodOptimal = data.row.optimalperiod;
            if (data.success) {
              this.reportListo = true;
              for (let i = 0; i < data.row.programs.length; i++) {
                this.sliderRange.push(data.row.programs[i].period);
                data.row.programs[i].addProgram = true;
                this.calculatorDebts.push(data.row.programs[i]);
                this.accounts = data.row.programs[i].debts;
                this.accounts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = this.calculatorDebts[
                      p
                    ].monthlyFee;
                    this.calculatorDebts.willPay = this.calculatorDebts[
                      p
                    ].willPay;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[
                      p
                    ].totalDebt;
                     this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                  }
                }
              }
              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);


            this.calculatorAcc = [];
            this.calculatorAcc = [...this.accounts];

            console.log("calculatorAcc:");
            console.log(this.calculatorAcc);


              this.$forceUpdate();
            }
          },
          (er) => {
            alert("Algo salió mal, intenta nuevamente");
            console.log(er);
          }
        );
            },
            async m_registerMarketing() {
                let marketing = this.$route.query.marketing
                this.$store.commit('setMarketing', marketing)
                let url = V.SERVER + V.OPS.INSERT + V.TABLES.MARKETING_LEADS
                await this.$axios.post(url, {from: marketing})
          },




          m_discountStep2() {
                // for (let d=0 ; d<this.debts.length ; d++) {
                //   let debt = this.debts[d];
                //     if (debt.bankid === 0) {
                //          this.$notify({
                //                 text: "Necesitas seleccionar el banco correspondiente",
                //                 type: "error",
                //             });
                //         return false
                //     } else if (debt.amount < 1500) {
                //         this.$notify({
                //                 text: "Tienes una deuda menor a $1500, solo podemos negociar mayores",
                //                 type: "error",
                //             });
                //         return false
                //     } else if (debt.edit) {
                //         this.$notify({
                //                 text: "Estás editando una deuda, cuando termines de editar puedes continuar",
                //                 type: "error",
                //             });
                //         return false
                //     }
                // }
                let _debts = JSON.parse(JSON.stringify(this.calculatorDebts.debts))
console.log("Array de datos" + (_debts));
                for (let d=0 ; d<_debts.length ; d++) {
                  _debts[d].amount = Number(_debts[d].amount)
console.log("Deduda total:"+(_debts[d].amount = Number(_debts[d].amount)));
                  delete _debts[d].edit
console.log(delete _debts[d].edit);                    
                }
                let user = this.user
                if (user === undefined || user === null) {
                    user = {
                        name: "",
                        lastname: "",
                        id: 1,
                    }
                }

                let _data = {
                    name: user.name,
                    lastname: user.lastname,
                    userid: user.id,
                    period: this.period,
                    debts: _debts
                }

                if (_data.name === undefined) _data.name = ""
                if (_data.lastname === undefined) _data.lastname = ""
               this.$axios
        .post(V.SERVER + V.OPS.GENERATE_PROGRAM_CALCULATOR, _debts)
        .then(
          (res) => {
            let data = res.data;
            this.discount = [];
            this.calculatorDebts = [];
console.log("Calculadora de deudas ⤋");
console.log(this.calculatorDebts);
            this.accounts = [];
            this.sliderRange = [];
            this.period = data.row.optimalperiod;
            this.periodOptimal = data.row.optimalperiod;
            if (data.success) {
              this.reportListo = true;
              for (let i = 0; i < data.row.programs.length; i++) {
                this.sliderRange.push(data.row.programs[i].period);
                data.row.programs[i].addProgram = true;
                this.calculatorDebts.push(data.row.programs[i]);
                this.accounts = data.row.programs[i].debts;
                this.accounts.sort(function (a, b) {
                  return b.amount - a.amount;
                });
                for (let p = 0; p < this.calculatorDebts.length; p++) {
                  if (this.period === this.calculatorDebts[p].period) {
                    this.calculatorDebts.debts = this.calculatorDebts[p].debts;
                    this.calculatorDebts.monthlyFee = this.calculatorDebts[
                      p
                    ].monthlyFee;
                    this.calculatorDebts.willPay = this.calculatorDebts[
                      p
                    ].willPay;
                    this.calculatorDebts.totalDebt = this.calculatorDebts[
                      p
                    ].totalDebt;
                     this.calculatorDebts.discount = this.calculatorDebts[p].discount;
                  }
                }
              }


              let myArray = this.sliderRange;
              this.maxSlider = Math.max.apply(Math, myArray);
              this.minSlider = Math.min.apply(Math, myArray);


              this.$forceUpdate();
            }
          },
          (er) => {
            alert("Algo salió mal, intenta nuevamente");
            console.log(er);
          }
        );
            },
        }
    };
</script>
