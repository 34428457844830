<template>

  <div class="bg-principal" id="bg">

    <div class="my-5 text-center bg-sm-video">
      <video
          class="sm-video"
          controls
          muted
          autoplay
          src="/videos/como_te_ayuda_dbmenos_v.mp4"
      ></video>
    </div>

    <div class="bg-lg-video text-center">

      <p class="simple-text-lg">
        Con nuestro programa liquida deudas con <br>
        tarjetas de crédito o préstamos bancarios
      </p>

      <video
          class="lg-video"
          controls
          muted
          autoplay
          src="/videos/como_te_ayuda_dbmenos_h.mp4"
      ></video>

    </div>

  </div>

</template>

<script>

export default {
  name: "VideoExplication",
}
</script>

<style scoped>

.bg-principal {
  background-image: url("/img/new_process/desktop/blue_background.png") !important;
  background-size: cover;
}

.simple-text-lg {
  font-family: OpenSans-Bold;
  color: #FFFFFF;
}

@media (max-width: 319px) {
  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }
}

@media (min-width: 320px) and (max-width: 374px) {

  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }
}

@media (min-width: 375px) and (max-width: 424px) {

  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }

  .sm-video {
    width: 100%;
  }
}

@media (min-width: 425px) and (max-width: 767px) {

  .bg-sm-video {
    background-color: #FFFFFF !important;
  }

  .bg-lg-video {
    display: none;
  }

  .sm-video {
    width: 100%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {

  .bg-sm-video {
    display: none;
  }

  .bg-lg-video {
    padding: 3rem 0;
  }

  .lg-video {
    width: 90%;
  }

}

@media (min-width: 1024px) and (max-width: 1439px) {

  .bg-sm-video {
    display: none;
  }

  .bg-lg-video {
    padding: 5rem 0;
  }

  .lg-video {
    width: 50%;
  }

  .simple-text-lg {
    font-size: 1.6rem;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {

  .bg-sm-video {
    display: none;
  }

  .bg-lg-video {
    padding: 5rem 0;
  }

  .lg-video {
    width: 50%;
  }

  .simple-text-lg {
    font-size: 1.6rem;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {

  .bg-sm-video {
    display: none;
  }

  .bg-lg-video {
    padding: 5rem 0;
  }

  .lg-video {
    width: 50%;
  }

  .simple-text-lg {
    font-size: 2rem;
  }
}

@media (min-width: 2561px) {
  .bg-sm-video {
    display: none;
  }

  .bg-lg-video {
    padding: 5rem 0;
  }

  .lg-video {
    width: 50%;
  }

  .simple-text-lg {
    font-size: 2rem;
  }
}

</style>