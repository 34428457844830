<template>

  <div class="bg-principal p-3">

    <div class="row mb-3 margin-space">

      <div class="col-12 col-md-7 col-lg-8"><!--left content-->

        <div class="row mt-block">

          <div class="order-2 order-sm-1 col-12 col-lg-10 mx-auto">

            <h2 class="text-justify t-title mb-0">
              Te ayudamos a liquidar tus deudas hasta con un 70% de descuento
            </h2>

            <ul class="mt-5">

              <li class="list-title my-5">¿Qué necesito?</li>

              <li class="list-item" v-for="item of requirements">
                <div class="row">
                  <div class="col-3 col-sm-2">
                    <img :src="item.icon" :alt="item.name">
                  </div>
                  <div class="col-9">
                    {{ item.text }}
                  </div>
                </div>
                <hr class="my-4 p-0" v-if="item.id != 4">
              </li>

            </ul>

          </div>

          <!-- Large women -->
          <!--
          <div class="d-flex align-items-center order-1 order-sm-2 col-12 col-lg-4 text-right p-0">
            <div class="mt-5">
              <img src="/img/landing_page/desktop/women.png" alt="women" class="dont-show-sm">
            </div>
          </div>
          -->

          <!-- Small women -->
          <!--
          <div class="order-1 order-sm-2 col-12 col-lg-6 text-center dont-show-lg">
            <p class="text-center message dont-show-lg"><b><i>Conóce más sobre nosotros</i></b></p>
            <img src="/img/landing_page/movil/row.png" alt="row" class="img-movil-row">
            <img src="/img/landing_page/desktop/play-button.png" alt="btn-play" class="btn-play-sm"
                 v-on:click="showVideo">
            <img src="/img/landing_page/movil/women.png" alt="women" width="60%">
          </div>
          -->

        </div>

      </div>

      <div class="col-12 col-md-5 col-lg-4 p-0 m-0">

        <div class="text-left ml-5 p-0 m-0 dont-show-sm">
          <img src="/img/landing_page/desktop/row.png" alt="" width="30%" class="ml-5 img-desk-row">
        </div>

        <p class="text-center dont-show-sm message"><b><i>Conóce más sobre nosotros</i></b></p>


        <div rounded="xl" class="bg-form">
          <img src="/img/landing_page/desktop/play-button.png" alt="btn-play" class="btn-play dont-show-sm"
               v-on:click="showVideo">

          <v-card-title>

            <div class="text-center">
              <img src="/img/landing_page/desktop/logo.png" alt="logo" width="50%">
            </div>

            <h5 class="mx-auto form-title">
              {{ this.version === 'v1' ? 'Calcula tu descuento' : 'Solicita asesoría gratuita' }}
            </h5>

            <p v-if="version === 'v1'" class="text-justify form-text">
              Para ayudarte mejor, necesitamos de los siguientes datos.
            </p>

            <p v-else class="form-text">
              Te pedimos la siguiente información para ofrecerte una asesoría personalizada.
            </p>

          </v-card-title>

          <v-card-text>

            <v-form v-model="valid" ref="form">

              <v-text-field
                  label="Nombre"
                  v-model="lead.name"
                  :rules="rules.name"
                  required
                  dense
                  solo
              ></v-text-field>

              <v-text-field
                  v-if="version === 'v1'"
                  label="Correo"
                  v-model="lead.email"
                  :rules="rules.email"
                  required
                  dense
                  solo
              ></v-text-field>

              <v-text-field
                  label="Teléfono"
                  v-model="lead.phone"
                  :rules="rules.phone"
                  required
                  dense
                  solo
              ></v-text-field>

              <v-select
                  v-if="version === 'v2'"
                  v-model="lead.banks"
                  label="¿A quién le debes?"
                  :items="banks"
                  item-text="name"
                  item-value="id"
                  :rules="rules.banks"
                  multiple
                  dense
                  solo
              ></v-select>

              <v-select
                  v-if="version === 'v2'"
                  v-model="lead.debt"
                  label="¿Cuánto debes?"
                  :items="debts"
                  item-text="name"
                  item-value="value"
                  :rules="rules.debt"
                  dense
                  solo
              ></v-select>

              <div class="text-center mt-5">
                <v-btn
                    :loading="loading"
                    color="#f3d338"
                    elevation="2"
                    depressed
                    @click="getDiscount"
                >
                  <span class="text-button">
                    <b>{{ version === 'v1' ? 'Calcular descuento' : 'Solicitar mi asesoría' }}</b>
                  </span>

                </v-btn>
              </div>

            </v-form>

          </v-card-text>

        </div>

      </div>

    </div>

    <!-- POPUP CUENTA YA EXISTENTE -->
    <div v-if="dialogUser">
      <v-dialog
        persistent
        v-model="dialogUser"
        max-width="800"
      >
        <div class="alert-close">
          <a @click="remove()"><span class="close-x">×</span></a>
        </div>

        <v-card >
          <div class="alert-container">
            <div class="alert-card">
              <v-card-text class="alert-img">
                <img src="/img/alert_account.png" alt="Existing account">
              </v-card-text>
      
              <v-card-text class="alert-text">
                Ya existe una cuenta asociada a este teléfono y correo. ¡Da click aquí!
              </v-card-text>
      
              <v-card-actions class="alert-btn-box">
      
                <v-btn
                  text                  
                  href="/register"
                  class="btn btn-green text-white alert-btn"
                  height="100%"
                >
                  Iniciar sesión
                </v-btn>

              </v-card-actions>
            </div>
          </div>              
        </v-card>
      </v-dialog>
    </div>

    <!-- POPUP CODIGO WHATSAPP -->
    <v-dialog
        v-model="dialog_code"
        width="400"
        persistent        
    >
      <v-card>
        <v-card-title>
          <p style="word-break: break-word !important;">
            Para recibir el cálculo de tu descuento enviamos un código por WhatsApp colócalo para continuar.
          </p>
        </v-card-title>

        <v-card-text class="mt-5">

          <v-form v-model="valid" ref="formCode">

            <v-text-field
                label="Código"
                v-model="lead.code"
                :rules="rules.code"
                type="number"
                required
            ></v-text-field>

          </v-form>

        </v-card-text>

        <v-spacer></v-spacer>

        <div class="text-right">
          <v-btn
              color="#ff0033"
              text
              @click="dialog_code = false"
          >
            Cancelar
          </v-btn>

          <v-btn
              color="primary"
              text
              @click="insertLead"
          >
            Calcular
          </v-btn>
        </div>

      </v-card>

    </v-dialog>


    <div>
      <v-dialog
          v-model="dialog"
          width="700"

      >
        <v-card>
          <v-card-title>
            <h4>¿Quiénes somos?</h4>
            <p>DB Menos es una plataforma digital enfocada en liquidar deudas atrasadas con tarjetas de crédito,
              departamentales o préstamos personales.</p>
          </v-card-title>
          <v-card-text class="mt-5">
            <div class="mt-5">
              <iframe id="inlineFrameExample"
                      title="Inline Frame Example"
                      class="i-frame"
                      src="/img/HomepageNew/Videos/Quiénes somos/Quiénes somos - DB Menos.mp4">
              </iframe>
            </div>
          </v-card-text>
        </v-card>

      </v-dialog>

    </div>

  </div>

</template>

<script>

import Vue from "vue";
import {V} from "@/V";

export default {
  name: "LPForm",
  props:
      {
        version:
            {
              request: true,
              default: "v1"
            }
      },
  computed:
      {
        banks() {
          return this.$store.state.banks;
        },
      },
  data: () =>
      ({
        dialog: false,
        dialog_code: false,
        dialogUser: false,
        valid: false,
        loading: false,
        leadSent: false,
        close: false,

        lead:
            {
              name: null,
              phone: null,
              email: null,
              banks: [],
              debt: null,
              code: null
            },

        //Others
        averageDiscount: 0,

        //Catalogs

        debts:
            [
              {id: 1, name: '10,000 a 30,000', value: 10000},
              {id: 2, name: '30,000 a 50,000', value: 30000},
              {id: 3, name: '50,000 a 100,000', value: 50000},
              {id: 4, name: '100,000 a 300,000', value: 100000},
              {id: 5, name: '300,000 a 500,000', value: 300000},
              {id: 6, name: 'más de 500,000', value: 500000},
            ],

        requirements:
            [
              {
                id: 1, name: 'debt',
                text: 'Deuda mayor a $10,000 pesos',
                icon: '/img/landing_page/desktop/debt.png'
              },
              {
                id: 3, name: 'payroll',
                text: 'No recibir la nómina en el banco donde tienes adeudo',
                icon: '/img/landing_page/desktop/payroll.png'
              },
              {
                id: 4, name: 'target',
                text: 'Deudas con tarjetas de crédito o departamentales',
                icon: '/img/landing_page/desktop/target.png'
              }
            ],

        rules:
            {
              name: [v => !!v || 'El nombre es requerido'],
              phone: [
                v => !!v || 'El teléfono es requerido',
                v => (v && v.length == 10) || 'El número debe tener 10 dígitos',
              ],
              email: [
                v => !!v || 'El correo es requerido',
                v => /.+@.+/.test(v) || 'Formato invalido'
              ],
              banks: [v => !!v || 'El o los banco(s) son requeridos'],
              debt: [
                v => !!v || 'La deuda es requerida',
                v => v >= 10000 || 'La deuda mínima es de  10,000 MXN'
              ],
              code: [
                v => !!v || 'El código es requerido',
                v => (v && v.length == 4) || 'El código es de 4 dígitos',
              ],
            }
      }),
  methods:
        {
        remove() {
          return this.dialogUser = !this.dialogUser;
        },
        clearForm() {
          this.$refs.form.reset();
        },

        showVideo() {
          this.dialog = true
        },

        calcDiscount(index) {
          let bank = this.banks[index];
          bank.selected = !bank.selected;

          Vue.set(this.banks, index, bank);

          let count = 0;
          let neg = 0;

          this.banks.forEach((bank) => {
            if (bank.selected) {
              count += 1;
              neg += bank.neg;
            }
          });

          this.averageDiscount = (count > 0) ? neg / count : 0;
        },

    getDiscount() {



    //console.log(this.loading);
          this.loading = true
    //console.log(this.loading);

          if (this.$refs.form.validate()) {

            for (const item of this.lead.banks) {
              this.calcDiscount(item);
            }

            this.insertLead()

    //this.dialog_code = true;
            /*
            if(this.dialogUser === true) {
              this.dialog_code === false;
        console.log("Sin codigo usuario existente" + " " + this.dialog_code);
            } else {
              this.dialog_code === true;
        console.log("Usuario nuevo - enviar codigo" + " " + this.dialog_code);
            }*/
          }

          this.loading = false
        },

        getMarketing() {

          let marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_${this.$route.query.utm_campaign}`;

          if (marketing === "undefined_undefined_undefined") {
            marketing = null;
          } else if (marketing === `${this.$route.query.utm_source}_undefined_undefined`) {
            marketing = this.$route.query.utm_source;
          } else if (marketing === `${this.$route.query.utm_source}_${this.$route.query.utm_medium}_undefined`) {
            marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_medium}`
          } else if (marketing === `${this.$route.query.utm_source}_undefined_${this.$route.query.utm_campaign}`) {
            marketing = `${this.$route.query.utm_source}_${this.$route.query.utm_campaign}`;
          }

          return marketing;
        },

        insertLead() {

          if (this.leadSent) {

            if (this.$refs.formCode.validate()) {

              let _data = {
                phone: this.lead.phone,
                code: this.lead.code,
                marketing: this.getMarketing(),
              };

              let calculate = {
                estimated: this.averageDiscount,
                total: this.lead.debt
              }

              let _dataLead = {
                name: this.lead.name,
                email: this.lead.email,
                phone: this.lead.phone,
                amount: this.lead.debt,
                banks: JSON.stringify(this.lead.banks),
                same_bank_debt_paysheet: false,
                device: (this.version === 'v1') ? 'web-r' : 'web'
              };

              Vue.analytics.fbq.event("LeadComplete", {
                content_name: this.lead.name + " " + this.lead.phone + " lead validated successfully",
              });

              let url = V.SERVER + V.OPS.VALIDATE_LEAD_SMS_CODE;

              this.$http.post(url, _data).then((response) => {

                    if (response.data.success) {

                      this.leadModal = false;
                      this.leadSent = false;

                      localStorage.setItem('phone', JSON.stringify(_data))
                      localStorage.setItem('calculate', JSON.stringify(calculate))
                      localStorage.setItem('Lead', JSON.stringify(_dataLead))
                      localStorage.setItem('marketing', this.getMarketing())

                      localStorage.setItem('ph', JSON.stringify(this.lead.phone))
                      localStorage.setItem('email', JSON.stringify(this.lead.email))

                      //Flujo anterior
                      //this.$router.push({path: "/regis"});

                      //Flujo nuevo
                      this.$router.push({name: "plan-personalizado"});
                      window.location.reload()

                      gtag("event", "Datos-validados", {
                        event_category: "Landing-liquidar-deuda-1",
                        event_label: "datos-validados",
                      });

                    } else {
                      alert("Algo salió mal :(, intenta nuevamente");
                    }

                  }
              );

            }

          } else {

            this.leadSent = true;

            let headers = {"device": 'web'};

            if (this.version === "v2") {
              this.lead.email = "any@any.com"
            }

            let _data = {
              name: this.lead.name,
              email: this.lead.email,
              phone: this.lead.phone,
              amount: this.lead.debt,
              banks: JSON.stringify(this.lead.banks),
              same_bank_debt_paysheet: false,
              device: (this.version === 'v1') ? 'web-r' : 'web'
            };

            Vue.analytics.fbq.event("LeadStarting", {
              content_name: this.lead.name + " " + this.lead.phone + " lead successful started",
            });

            let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS;

            gtag("event", "conversion", {send_to: "AW-718930865/2GivCITQvKcBELGH6NYC"});

            gtag("event", "Datos-contacto", {
              event_category: "Landing-liquidar-deuda-1",
              event_label: "datos-contacto"
            });

            this.$http.post(url, _data, {headers: headers}).then((response) => {

                  if (response.data.success) {
                    this.leadSent = response.data.success;
                    this.dialog_code = true;
                  } else {
                    //alert("Algo salió mal :(, intenta nuevamente");
                    this.dialogUser = true;
                    this.dialog_code = false;
                  }
                },
            );
          }
        },
      }
}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/variables.scss';
@import '../landing_page/scss/Principal.scss';

/*Backgrounds*/
.bg-principal {

  background: linear-gradient(to bottom, #2A3D99, #29AAE1) !important /*
   background-image: url("https://dbmenos.com/img/landings-pantallas/landings-desktop/Background%201500%20px.png");
   background-size: cover;
 */
}

.bg-form {
  background-image: url("/img/landing_page/desktop/bg-blue.png");

  background-color: #0f52ad !important;
  background-size: cover;
  border-radius: 20px;
}

/*IFrame*/
.i-frame {
  width: 100%;
}

/*Text*/
.t-title {
  font-family: OpenSans-Bold;
  font-weight: bold;
  color: #FFFFFF;
}

.form-title {
  color: #FFFFFF;
  word-break: normal;
  animation: pulse2 2s infinite;
}

.form-text {
  word-break: normal;
  color: #FFFFFF;

  hyphens: auto;
}

/*List*/
.list-title {
  font-family: Metropolis-ExtraLight;
  color: #FFFFFF;

  list-style: none;
}

.list-item {
  font-family: Metropolis-ExtraLight;
  font-weight: normal;
  color: #F6F6F6;

  list-style: none;
}

.text-button {
  color: #2a3d9a;
}

hr {
  background-color: #FFF;
  height: 1px;
  border: 0;
}

.message {
  color: #FFFFFF;
}

@media (min-width: 320px) and (max-width: 374px) {

  /*Margin*/
  /*.margin-space {
    margin-top: 20%
  }*/
  /*Backgrounds*/
  .bg-form {
    width: 100%;
  }

  .i-frame {
    height: 130px;
  }

  /*Text*/
  .t-title {
    font-size: 1.2rem;
  }

  .list-title {
    font-size: 1.1rem;
  }

  .list-item {
    font-size: 1rem;
  }

  .form-title {
    font-size: 1rem;
  }

  .form-text {
    font-size: 1rem;
    text-align: justify;
    word-spacing: -0.05em;
  }

  .dont-show-sm {
    display: none;
  }

  .btn-play-sm {
    position: absolute;
    top: 65px;
    left: 50px;
    width: 4rem;
  }

  .img-movil-row {
    position: absolute;
    top: 20px;
    left: 15px;
    width: 1.8rem;
  }

  .message {
    font-size: 1rem;
  }

}

@media (min-width: 375px) and (max-width: 424px) {

  /*Margin*/
  /*.margin-space {
    margin-top: 20%
  }*/
  /*Backgrounds*/
  .bg-form {
    width: 100%;
  }

  .i-frame {
    height: 160px;
  }

  /*Text*/
  .t-title {
    font-size: 1.2rem;
  }

  .list-title {
    font-size: 1.1rem;
  }

  .list-item {
    font-size: 1rem;
  }

  .form-title {
    font-size: 1rem;
  }

  .form-text {
    font-size: 1rem;
    text-align: justify;
    word-spacing: -0.1em;
  }

  .dont-show-sm {
    display: none;
  }

  .btn-play-sm {
    position: absolute;
    top: 65px;
    left: 60px;
    width: 4rem;
  }

  .img-movil-row {
    position: absolute;
    top: 20px;
    left: 25px;
    width: 1.8rem;
  }

  .message {
    font-size: 1rem;
  }

}

@media (min-width: 425px) and (max-width: 767px) {

  /*Margin*/
  /*.margin-space {
    margin-top: 20%
  }*/
  /*Backgrounds*/
  .bg-form {
    width: 90%;
    margin: 0 auto;
  }

  .i-frame {
    height: 200px;
  }

  /*Text*/
  .t-title {
    font-size: 1.3rem;
  }

  .list-title {
    font-size: 1.2rem;
  }

  .list-item {
    font-size: 1.1rem;
  }

  .form-title {
    font-size: 1.2rem;
  }

  .form-text {
    font-size: 1rem;
    text-align: justify;
  }

  .dont-show-sm {
    display: none;
  }

  .btn-play-sm {
    position: absolute;
    top: 65px;
    left: 70px;
    width: 4rem;
  }

  .img-movil-row {
    position: absolute;
    top: 20px;
    left: 35px;
    width: 1.8rem;
  }

  .message {
    font-size: 1.2rem;
  }

}

@media (min-width: 768px) and (max-width: 1023px) {

  /*Margin*/
  .margin-space {
    /* margin-top: 12%;*/
    padding: 1rem;
  }

  /*Backgrounds*/
  .bg-form {
    width: 100%;
  }

  .i-frame {
    height: 400px;
  }

  /*Text*/
  .t-title {
    font-size: 1.4rem;
  }

  .list-title {
    font-size: 1.3rem;
  }

  .list-item {
    font-size: 1.2rem;
  }

  .form-title {
    font-size: 1.2rem;
  }

  .form-text {
    font-size: 1.1rem;
    text-align: justify;
    word-spacing: -0.1em;
  }

  .dont-show-sm {
    display: none;
  }

  .dont-show-lg {
    display: none;
  }

}

@media (min-width: 1024px) and (max-width: 1439px) {

  /*Margin*/
  .margin-space {
    /* margin-top: 8%;*/
    padding: 0 2rem;
  }

  /*Backgrounds*/
  .bg-form {
    width: 90%;
  }

  .i-frame {
    height: 400px;
  }

  /*Text*/
  .t-title {
    font-size: 1.7rem;
  }

  .list-title {
    font-size: 1.5rem;
  }

  .list-item {
    font-size: 1.3rem;
  }

  .form-title {
    font-size: 1.3rem;
  }

  .form-text {
    font-size: 1.1rem;
    text-align: justify;
  }

  .dont-show-lg {
    display: none;
  }

  .btn-play {
    position: absolute;
    top: 60px;
    left: -40px;
    width: 4.5rem;
  }

  .img-desk-row {
    margin-top: 10px;
    width: 10rem;
  }

  .message {
    font-size: 1.2rem;
  }

  .mt-block {
    margin-top: 3.5rem;
  }

}

@media (min-width: 1440px) and (max-width: 2559px) {

  /*Margin*/
  .margin-space {
    /*margin-top: 6%;*/
    padding: 0 2rem;
  }

  /*Backgrounds*/
  .bg-form {
    width: 90%;
  }

  .i-frame {
    height: 400px;
  }

  /*Text*/
  .t-title {
    font-size: 1.8rem;
  }

  .list-title {
    font-size: 1.5rem;
  }

  .list-item {
    font-size: 1.4rem;
  }

  .form-title {
    font-size: 1.4rem;
  }

  .form-text {
    font-size: 1.2rem;
    text-align: justify;
  }

  .dont-show-lg {
    display: none;
  }

  .btn-play {
    position: absolute;
    top: 60px;
    left: -40px;
    width: 5rem;
  }

  .img-desk-row {
    margin-top: 10px;
    width: 12rem;
  }

  .message {
    font-size: 1.4rem;
  }

  .mt-block {
    margin-top: 5rem;
  }

}

@media (min-width: 2560px) and (max-width: 2560px) {

  /*Backgrounds*/
  .bg-principal {
    padding: 7% !important;
  }

  .bg-form {
    padding-bottom: 6rem;
  }

  .i-frame {
    height: 400px;
  }

  /*Text*/
  .t-title {
    font-size: 3.4rem;
  }

  .list-title {
    font-size: 3rem;
  }

  .list-item {
    font-size: 2.8rem;
  }

  .form-title {
    font-size: 2.7rem;
  }

  .form-text {
    font-size: 2.5rem;
    text-align: justify;
  }

  .dont-show-lg {
    display: none;
  }

  .btn-play {
    position: absolute;
    top: 7rem;
    left: -40px;
    width: 10rem;
  }

  .img-desk-row {
    margin-top: 10px;
    width: 18rem;
  }

  .message {
    font-size: 2.7rem;
  }

  .mt-block {
    margin-top: 7rem;
  }

  /*
  .v-text-field >>> label {
    font-size: 2rem;
    padding: 25px;
  }

  .v-text-field >>> input {
    margin: 25px;
    font-size: 1.5rem;
  }

  .v-text-field >>> button {
    font-size: 2rem;
  }
  */
  }

</style>