<template>

  <div class="bg-principal">

    <div class="row col-12 col-md-10 mx-auto">

      <div class="col-12 col-sm-4 text-center">
        <p class="text-app">Descarga la app</p>
        <div class="col-12" v-for="item of images">
          <a :href="item.url" target="_blank">
            <img :src="item.image" :alt="item.id">
          </a>
        </div>
      </div>

      <div class="col-12 col-sm-8 d-flex align-items-center">

        <div class="row">
          <div class="mx-auto mx-sm-1">
            <p class="text-link p-0">&copy; {{ year }} DBMenos</p>
          </div>

          <div class="mx-auto mx-sm-2" v-for="item of links">
            <span class="text-link">|</span> <a :href="item.url"
                 :target="(item.id === 1) ? '_blank' : '_self'"
                 class="text-link d-inline">{{ item.text }}</a>
          </div>

          <div class="col-12 text-left pl-0">
            <p class="text-address">Av. Homero 407, Polanco, Piso 11, Miguel Hidalgo, C.P. 11550, CDMX, México.</p>
          </div>
        </div>

      </div>

    </div>

  </div>

</template>

<script>
export default {
  name: "Applications",
  data: () =>
      ({
        year: new Date().getFullYear(),

        copy: '',

        images:
            [
              {
                id: 1,
                image: '/img/HomepageNew/Desktop/Google Play logo.svg',
                url: 'https://play.google.com/store/apps/details?id=com.dbmenos.dbm_android_client&hl=es'
              },
              {
                id: 2,
                image: '/img/HomepageNew/Desktop/App Store Logo.svg',
                url: 'https://apps.apple.com/mx/app/db-menos/id1503253844?l=en'
              },
            ],
        links:
            [
              {
                id: 1,
                text: 'Contáctanos',
                url: 'https://wa.me/+12063505973?text=Hola!%20me%20interesa%20información%20de%20DB%20Menos'
              },
              {
                id: 2,
                text: 'Legal',
                url: '/legal'
              },
              {
                id: 3,
                text: 'faq',
                url: '/questions'
              },
            ]
      })
}
</script>

<style scoped>

.text-app {
  font-family: OpenSans-Bold;
}

.text-link
{
  font-family: OpenSans-Bold;
}

.text-address {
  font-family: OpenSans-Regular;
}

@media (min-width: 320px) and (max-width: 374px) {
  .text-app {
    font-size: 1rem;
  }

  .text-link,.text-address {
    font-size: 1rem;
  }
}

@media (min-width: 375px) and (max-width: 424px) {
  .text-app {
    font-size: 1rem;
  }

  .text-link,.text-address {
    font-size: 1rem;
  }
}

@media (min-width: 425px) and (max-width: 767px) {
  .text-app {
    font-size: 1.2rem;
  }

  .text-link,.text-address {
    font-size: 1rem;
  }

  img {
    width: 70%;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .text-app {
    font-size: 1rem;
  }

  .text-link,.text-address {
    font-size: 1rem;
  }

  img {
    width: 90%;
  }
}

@media (min-width: 1024px) and (max-width: 1439px) {
  .text-app {
    font-size: 1.2rem;
  }

  .text-link,.text-address {
    font-size: 1.2rem;
  }

  img {
    width: 70%;
  }
}

@media (min-width: 1440px) and (max-width: 2559px) {
  .text-app {
    font-size: 1.2rem;
  }

  .text-link,.text-address {
    font-size: 1.2rem;
  }

  img {
    width: 70%;
  }
}

@media (min-width: 2560px) and (max-width: 2560px) {
  .text-app {
    font-size: 2.2rem;
  }

  .text-link,.text-address {
    font-size: 2rem;
  }

  img {
    width: 60%;
  }
}

</style>