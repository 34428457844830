<template>
  <section
      class="section "
      style="background-color: #3953D3; background-size: cover; opacity: 0.8"
  >
    <div class="row justify-content-center">
      <div v-if="whoMarketing == 'Finansi_Banner_Finansi202202'">
        <img src="https://clean.tracksacai.com/aff_l?offer_id=3408" width="1" height="1"/>
      </div>
      <div v-if="whoMarketing == 'LeadGenios_LeadGenios202201'">
        <IMG src="https://leadgenios.net/pixel.track?CID=442404&p=img&MerchantReferenceID=" width="1" height="1"/>
      </div>
      <div v-if="whoMarketing == 'coru_web_202203'">
        <img :src="urlPixel" width="1" height="1"/>
      </div>
      <div v-if="whoMarketing == 'DigitalHub_Retargeting_DigitalHub202202'">
        <img src="https://digitalhub.g2afse.com/success.jpg?offer_id=4623" height="1" width="1" alt=""/></div>
      <div class="card text-center" style="width: 60%">
        <div class="card-body">
          <p class="agreement-title">Autoriza la consulta de reporte de crédito</p>

          <p class="agreement-text">
            Autorizo a Grupo DB Menos SAPI de CV para que solicite a Trans
            Unión, S.A.S.I.C. Mi reporte de Crédito Especial a fin de conocer la
            condición con Entidades Financieras y/o Empresas Comerciales, de
            acuerdo con lo establecido en el artículo 40 de la Ley para Regular
            a las Sociedades de Información Crediticia. Así mismo, declaro que
            conozco la naturaleza y alcance de la información que se solicitará.
            Bajo protesta de decir verdad manifesto que los datos que menciono
            en esta solicitud son verídicos.
          </p>
          <hr>
          <p class="agreement-text">
            Teléfono gratuito de atención al público de las sociedades (55)
            5449 4949 <br/>
            Puedes consultar tu reporte de crédito especial una vez por año
            gratuitamente ingresando a https://www.burodecredito.com.mx/
          </p>
          <hr>
          <p class="agreement-text">
            Te enviamos un código de seguridad a través de un mensaje de texto, escríbelo aquí para autorizar la
            consulta de crédito
          </p>

          <!--
          <div class="row justify-content-center">
            <button
              style="
                width: 60%;
                background-color: #ffd527;
                border-color: #ffd527;
                color: #3953d3;
                border-radius: 1rem;
              "
              class="btn btn-lg btn-block"
              v-on:click="requestCrPin"
            >
              Solicitar tu pin
            </button>
          </div>
          !-->
          <div
              v-if="truePin"
              class="row justify-content-center"
              style="margin-top: 1rem"
          >
            <input
                v-model="code"
                style="width: 70%; border-radius: 3rem"
                class="form-control form-control-lg"
                type="text"
                placeholder="Pin de 4 dígitos"
            />
            <button
                v-on:click="validateCrPin"
                style="
                width: 40%;
                background-color: #3953d3;
                border-color: #3953d3;
                color: white;
                border-radius: 1rem;
                margin-top: 1rem;
              "
                class="btn btn-lg btn-block"
            >
              Autoriza la consulta
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/analytics';
import 'firebase/messaging';
import Vue from "vue";
import Notifications from "vue-notification";
import VueResource from "vue-resource";
import {V} from "@/V";
import VueSignaturePad from "vue-signature-pad";
import VueFacebookPixel from "vue-analytics-facebook-pixel";

Vue.use(VueFacebookPixel);
Vue.use(VueResource);
export default {
  components: {
    VueFacebookPixel,
    VueSignaturePad,
  },
  computed: {
    banks() {
      return this.$store.state.banks;
    },
    nombre_banco: function () {
      return this.getBank(this.account.bank).name;
    },
  },
  created() {
    fbq("track", "step_six");
    firebase.analytics().logEvent("step_six");
    gtag("event", "step_six", {
      send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
    });
    this.requestCrPin();
    this.dataCal = JSON.parse(localStorage.getItem("calculate"));
    this.whoMarketing = localStorage.getItem('marketing');
    if (this.whoMarketing == 'coru_web_202203') {
      this.urlPixel = 'https://api.coru.com/p/analytics/dbmenos.gif?event=contact&pixelID=[idCoru]&step=&alfa=' + ((this.dataCal.total) * 100) + '&beta=';
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted() {
  },
  data() {
    return {
      dataCal: null,
      urlPixel: null,
      token: null,
      sign: null,
      validToken: false,
      account: null,
      signToken: null,
      signed: null,
      waitingSignature: true,
      alreadySigned: false,
      signedForCreditReport: false,
      signedForContract: false,
      signaturePath: null,
      SIGN_CONTRACT: "contract",
      SIGN_ACCOUNT_SETTLEMENT: "account",
      code: "",
      truePin: false,
      today: "",
      whoMarketing: null,
    };
  },
  methods: {
    getBank(id) {
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    async requestCrPin() {
      this.$showLoader(true);
      let url = V.SERVER + V.OPS.REQUEST_CR_PIN;
      this.$axios.post(url).then(async (res) => {
        this.$showLoader(false);
        if (res.status == 200) {
          if (res.data.success) {
            this.$showLoader(false);
            this.truePin = true;
            this.$notify({
              text: "Código envíado",
              type: "success",
            });
            //this.checkStatus();
          } else if (res.data.reason == 5) {
            this.$showLoader(false);
            this.$notify({
              text: "El código ya fue validado",
              type: "success",
            });
            this.$router.push({path: "/calcular"});
          }
        }
      });
    },
    async validateCrPin() {
      this.$showLoader(true);
      let url = V.SERVER + V.OPS.VALIDATE_CR_PIN;
      this.$axios.post(url, {pin: this.code}).then(async (res) => {
        this.$showLoader(false);
        if (res.status == 200) {
          if (res.data.success) {
            this.$showLoader(false);
            this.$notify({
              text: "Código validado",
              type: "success",
            });
            this.$router.push({path: "/calcular"});
          } else if (res.data.reason == 5) {
            this.$notify({
              text: "El código ya fue validado",
              type: "success",
            });
            this.$router.push({path: "/calcular"});
            this.$showLoader(false);
          } else if (res.data.reason == 7) {
            this.$notify({
              text: "El código es incorrecto",
              type: "error",
            });
            this.$showLoader(false);
          } else if (res.data.reason == 8) {
            this.$notify({
              text: "El código caduco",
              type: "error",
            });
            this.$showLoader(false);
          }
        }
      });
    },
    base64ToBlob(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      let byteCharacters = atob(b64Data);
      let byteArrays = [];

      for (
          let offset = 0;
          offset < byteCharacters.length;
          offset += sliceSize
      ) {
        let slice = byteCharacters.slice(offset, offset + sliceSize);

        let byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        let byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, {type: contentType});
    },
    /*
            async validateToken() {
                this.$showLoader(true)
                if (this.$route.query.token) {
                    this.sign = this.$route.query.sign
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?token=" + this.$route.query.token
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row
                        if (data.success) {
                            this.token = this.$route.query.token
                            this.validToken = true
                            this.alreadySigned = _token.signed
                            this.signedForCreditReport = _token.for_credit_report
                            this.signedForContract = _token.for_contract
                            this.signaturePath = _token.path

                            this.user.name = data.user.name
                            this.user.address = 'Calle ' + data.user.street + ' #' + data.user.extnum + ', ' + data.user.sublocality
                            this.user.address_2 = 'C.P. ' + data.user.postal_code + ', ' + data.user.state_of_birth
                            this.user.phone = 'Tel. ' + data.user.phone
                            this.user.email = 'e-mail: ' + data.user.email
//                            console.log(_token)
                            this.$forceUpdate()
                        }
                        else if(data.reason == 5) {
                            this.alreadySigned = true
                        }
                    })
                }
                else if (this.$route.query.t) {
                    this.sign = this.SIGN_ACCOUNT_SETTLEMENT
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN + "?t=" + this.$route.query.t
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row.token
                        let _account = data.row.account
                        if (data.success) {
                            this.signToken = _token
                            this.account = _account
                            this.token = this.$route.query.t
                            this.validToken = true
                            this.signed = _token.signed
//                            console.log(data)
                            this.$forceUpdate()
                        }
                    })
                }
                else this.$showLoader(false)
            },
            */
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;

      let footer = document.getElementById("footer");

      if (this.window.width < this.window.height) {
        if (footer) footer.style.display = "none";
      } else {
        if (footer) footer.style.display = "block";
      }
    },
  },
};
</script>
<style>

.agreement-title {
  color: #32325d;
  font-weight: bold;
}

.agreement-text {
  color: #000;
}

@media (min-width: 320px) and (max-width: 374px) {
  .agreement-title {
    font-size: 1rem;
  }

  .agreement-text {
    font-size: 1rem;
  }
}
@media (min-width: 375px) and (max-width: 424px) {
  .agreement-title {
    font-size: 1.1rem;
  }

  .agreement-text {
    font-size: 1rem;
  }
}
@media (min-width: 425px) and (max-width: 767px) {
  .agreement-title {
    font-size: 1.2rem;
  }

  .agreement-text {
    font-size: 1.1rem;
  }
}
@media (min-width: 768px) and (max-width: 1023px) {
  .agreement-title {
    font-size: 1.5rem;
  }

  .agreement-text {
    font-size: 1.3rem;
  }
}
@media (min-width: 1024px) and (max-width: 1439px) {
  .agreement-title {
    font-size: 1.7rem;
  }

  .agreement-text {
    font-size: 1.3rem;
  }
}
@media (min-width: 1440px) and (max-width: 2559px) {
  .agreement-title {
    font-size: 2rem;
  }

  .agreement-text {
    font-size: 1.8rem;
  }
}
@media (min-width: 2560px) and (max-width: 2560px) {
  .agreement-title {
    font-size: 2.8rem;
  }

  .agreement-text {
    font-size: 2.6rem;
  }
}

.signature {
  border: 0;
  border-top: 1px solid #000;

  left: 0;
  right: 0;
  bottom: 40px;
  margin: auto;
  position: absolute;
  pointer-events: none;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                     supported by Chrome, Opera and Firefox */
}

#pleaserotate-graphic {
  margin-left: 50px;
  width: 200px;
  animation: pleaserotateframes ease 2s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: pleaserotateframes ease 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: pleaserotateframes ease 2s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -ms-animation: pleaserotateframes ease 2s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}

#pleaserotate-backdrop {
  background-color: white;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}

#pleaserotate-container {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}

#pleaserotate-message {
  margin-top: 20px;
  font-size: 1.3em;
  text-align: center;
  font-family: Verdana, Geneva, sans-serif;
  text-transform: uppercase;
}

#pleaserotate-message small {
  opacity: 0.5;
  display: block;
  font-size: 0.6em;
}

@keyframes pleaserotateframes {
  0% {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  49% {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  100% {
    transform: rotate(90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
}
</style>
