<template>
  <div
    v-if="window.width < window.height"
    id="pleaserotate-backdrop"
    style="display: block"
  >
    <div id="pleaserotate-container">
      <svg
        xmlns:xlink="http://www.w3.org/1999/xlink"
        id="pleaserotate-graphic"
        viewBox="0 0 250 250"
      >
        <g id="pleaserotate-graphic-path">
          <path
            d="M190.5,221.3c0,8.3-6.8,15-15,15H80.2c-8.3,0-15-6.8-15-15V28.7c0-8.3,6.8-15,15-15h95.3c8.3,0,15,6.8,15,15V221.3zM74.4,33.5l-0.1,139.2c0,8.3,0,17.9,0,21.5c0,3.6,0,6.9,0,7.3c0,0.5,0.2,0.8,0.4,0.8s7.2,0,15.4,0h75.6c8.3,0,15.1,0,15.2,0s0.2-6.8,0.2-15V33.5c0-2.6-1-5-2.6-6.5c-1.3-1.3-3-2.1-4.9-2.1H81.9c-2.7,0-5,1.6-6.3,4C74.9,30.2,74.4,31.8,74.4,33.5zM127.7,207c-5.4,0-9.8,5.1-9.8,11.3s4.4,11.3,9.8,11.3s9.8-5.1,9.8-11.3S133.2,207,127.7,207z"
          ></path>
        </g>
      </svg>
      <div id="pleaserotate-message">
        Por favor, activa la rotación automática de tu dispositivo y rótalo
        <small>(Esta página sólo funciona en orientación horizontal)</small>
      </div>
    </div>
  </div>
  <section
    v-else
    class="section section-shaped section-lg my-0"
    style="
      background-size: cover;
      background-repeat: no-repeat;
      background-image: url('img/brand/Header_1440_100.png');
    "
  >
    <div class="row justify-content-center">
      <div
        class="col-lg-10"
        style="
          background-color: #ffffff;
          width: 80%;
          margin-top: 5rem;
          margin-bottom: 10rem;
          border-radius: 1rem;
          height: 100%;
        "
      >
        <b-tabs
          class="text-black"
          content-class="mt-3"
          style="background-color: #FFFFF"
        >
          <div class="container pt-lg-md-3 text-center" v-if="validToken">
            <small style="font-size: 20px"
              >Hola {{ user.name }}, te pedimos que subas de nuevo una foto de
              tu comprobante completo y legible .</small
            >
            <div class="row justify-content-center">
              <div class="col-lg-4 py-4">
                <div>
                  <dropbox
                    text
                    :filePayment="true"
                    :files="this.userFiles"
                    v-on:uploadFile="uploadFilePayment"
                    v-on:resentUploadFile="uploadFilePayment"
                  ></dropbox>
                </div>
              </div>
            </div>
          </div>
          <div
            class="text-center"
            style="margin-top: 10rem; margin-bottom: 10rem"
            v-if="notvalidToken"
          >
            <h5 style="font-size: 25px">
              No logramos validar tu enlace, comúnicate con tu asesor
            </h5>
            <img src="https://dbmenos.com/img/theme/close-circle.png" />
          </div>
          <div
            class="text-center"
            style="margin-top: 10rem; margin-bottom: 10rem"
            v-if="alreadySigned"
          >
            <h5 style="font-size: 25px">
              Ya se subio el pago en este link, comúnicate con tu asesor
            </h5>
            <img src="https://dbmenos.com/img/theme/close-circle.png" />
          </div>
          <div
            class="text-center"
            style="margin-top: 10rem; margin-bottom: 10rem"
            v-if="caducoToken"
          >
            <h5 style="font-size: 25px">
              Link cadúco, comúnicate con tu asesor
            </h5>
            <img src="https://dbmenos.com/img/theme/close-circle.png" />
          </div>
          <div class="text-center" v-if="checkPayment">
              <h5>Ya recibimos tu pago, gracias por confiar en nosotros</h5>
              <img src="img/theme/checkbox-marked-circle.png">
               </div>
        </b-tabs>
      </div>
    </div>
  </section>
</template>
<script>
    import modal from "@/components/Modal"
    import {V} from "@/V"
    import BaseInput from '../components/BaseInput.vue';
    import Dropbox from "../components/Dropbox";
    export default {
        name: "home",
        components: {
            modal,
            BaseInput,
            Dropbox
        },
        computed: {
            banks() { return this.$store.state.banks },
            nombre_banco: function() {
                return this.getBank(this.account.bank).name
            }
        },
        async created() {
            window.addEventListener('resize', this.handleResize)
            this.handleResize()
        },
        destroyed() {
            window.removeEventListener('resize', this.handleResize)
        },
        mounted() {
            this.validateToken()
            this.handleResize()  
        },
        data() {
            return {
                V: V,
                client: {},
                infoToken: {},
                userFiles: [],
                files: {
                GUIDE_SEND:null
                },
                banks: [],
                SERVER: "https://s3.amazonaws.com/dbmenos.frontend/",
                leadModal: false,
                estimatedDiscountModal: false,
                averageDiscount: 0,
                token: null,
                sign: null,
                validToken: false,
                notvalidToken: false,
                account: null,
                signToken: null,
                signed: null,
                signedAdd: null,
                waitingSignature: true,
                checkPayment:false,
                alreadySigned: false,
                caducoToken: false,
                signedForCreditReport: false,
                signedForContract: false,
                signaturePath: null,
                SIGN_CONTRACT: 'contract',
                SIGN_ACCOUNT_SETTLEMENT: 'account',
                SIGN_ADDENDUM: 'addendum',
                SIGN_LETTER_DBM : 'letter_dbm',
                cancelAddendum:false,
                urlLetter:"",
                urlContract:"",
                user: {
                    name: null,
                    address: null,
                    address_2: null,
                    phone: null,
                    email: null
                },

                window: {
                    width: 0,
                    height: 0
                },
                userFiles: [],
                urlAddendum:"",
                files: {
                APPROACH_ADDENDUM: null
            }
            }
        },
        methods: {
            getBank(id) {
                for (let i=0 ; i<this.banks.length ; i++)
                    if (this.banks[i].id === id)
                        return this.banks[i]
                return null
            },
            clearSignature() {
                this.$refs.signaturePad.undoSignature()
            },
            openDocument(filename) {
                window.open(
                    V.SERVER +
                        V.OPS.GET_USER_FILE +
                        "?filename=" +
                        filename +
                        "&session=" +
                        this.session.session +
                        "&redirect=true"
                );
            },
            base64ToBlob(b64Data, contentType, sliceSize) {
                contentType = contentType || '';
                sliceSize = sliceSize || 512;

                let byteCharacters = atob(b64Data);
                let byteArrays = [];

                for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
                    let slice = byteCharacters.slice(offset, offset + sliceSize);

                    let byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                    }

                    let byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                }

                return new Blob(byteArrays, {type: contentType});
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;

                let footer = document.getElementById("footer")

                if (this.window.width < this.window.height) {
                    if (footer) footer.style.display = "none";
                } else {
                    if (footer) footer.style.display = "block";
                }
            },
            async validateToken() {
                this.$showLoader(true)
                if (this.$route.query.token) {
                    this.sign = this.$route.query.sign
                    let url = V.SERVER + V.OPS.VALIDATE_TOKEN_PAYMENT + "?token=" + this.$route.query.token
                    return this.$axios.get(url).then(response => {
                        this.$showLoader(false)
                        let data = response.data
                        let _token = data.row
                        this.infoToken= data.row
                        if (data.success) {
                            this.token = this.$route.query.token
                            this.validToken = true
                            this.signaturePath = _token.path
                            this.user.name = data.user.name
                            this.$forceUpdate()
                        }
                        else if(data.reason == 5) {
                            this.alreadySigned = true
                        }
                        else if(data.reason == 8) {
                            this.caducoToken = true
                        }
                    })
                }else{
                    this.notvalidToken=true;
                    this.$showLoader(false)
                    }
            },

        uploadFilePayment(obj) {
            console.log(this.infoToken)
            let file = obj.file;
            this.archivo = obj.file.name;
            let formData = new FormData();
            formData.append("file", file);
            let headers = {
                "user": this.infoToken.user,
                "payment":this.infoToken.id_payment
            };
            this.$showLoader(true);
            this.$axios
                .post(V.SERVER + V.OPS.EDIT_PAYMENT_USER, formData, { headers: headers })
                .then(response => {
                    this.$showLoader(false);
                    if (response.data.success) {
                        this.$notify({
                        text: "Se subió correctamente",
                        type: "success",
                    });
                    this.checkPayment=true;
                    } else {
                        this.$notify({
                        text: "No se logró subir, intente nuevamente",
                        type: "error",
                    });
                    }
                })
                .catch(e => {
                    console.log(e);
                    this.$showLoader(false);
                    alert("No se logró subir, intente nuevamente");
                });
        },
        getFilesInfo() {
            let url = V.SERVER + V.OPS.GET_FILES_USER_RISK;
            let request ={
                user: this.client.id
            }
            this.$axios.post(url,request).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.userFiles = data.rows;
                    for (var i = 0; i < this.userFiles.length; i++) {
                        if (this.userFiles[i].type == "GUIDE_SEND") {
                            this.files.GUIDE_SEND = true;
                        }
                    }
                } else {
                    console.log("error d_userFiles");
                }
            });
        },

        verificationFiles() {
                let url = V.SERVER + V.OPS.VERIFICATIONS_UPLOAD_FILES;
                let request = {user:this.client.id};
            this.$axios.post(url,request).then(response => {
                let data = response.data;
                if (data[V.API.SUCCESS]) {
                    this.$notify({
                        text: "Se subieron todos los archivos correctamente",
                        type: "success",
                    });
                } else {
                     this.$notify({
                        text: "No se logró subir alguno, intente nuevamente",
                        type: "error",
                    });
                }
            });
        },
        }
    };
</script>
<style>
.signature {
  border: 0;
  border-top: 1px solid #000;

  left: 0;
  right: 0;
  bottom: 40px;
  margin: auto;
  position: absolute;
  pointer-events: none;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Opera and Firefox */
}
#pleaserotate-graphic {
  margin-left: 50px;
  width: 200px;
  animation: pleaserotateframes ease 2s;
  animation-iteration-count: infinite;
  transform-origin: 50% 50%;
  -webkit-animation: pleaserotateframes ease 2s;
  -webkit-animation-iteration-count: infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: pleaserotateframes ease 2s;
  -moz-animation-iteration-count: infinite;
  -moz-transform-origin: 50% 50%;
  -ms-animation: pleaserotateframes ease 2s;
  -ms-animation-iteration-count: infinite;
  -ms-transform-origin: 50% 50%;
}
#pleaserotate-backdrop {
  background-color: white;
  top: 0;
  left: 0;
  position: fixed;
  width: 100%;
  height: 100%;
}
#pleaserotate-container {
  width: 300px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
}
#pleaserotate-message {
  margin-top: 20px;
  font-size: 1.3em;
  text-align: center;
  font-family: Verdana, Geneva, sans-serif;
  text-transform: uppercase;
}
#pleaserotate-message small {
  opacity: 0.5;
  display: block;
  font-size: 0.6em;
}
@keyframes pleaserotateframes {
  0% {
    transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
  }
  49% {
    transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
  100% {
    transform: rotate(90deg);
    -moz-transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
  }
}
</style>
