<template>
    <!-- <div> -->
        <nav>
            <v-toolbar flat>
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                
                <v-tootlbar-title class="logo-navbar">
                    <img src="/img/winoh/winoh-grey.png" alt="winoh-logo"/>
                </v-tootlbar-title>
            </v-toolbar>

            <v-navigation-drawer
                v-model="drawer" 
                app
                left
                width="410px" 
                class="aqua"
            >
                <div class="close-btn">
                    <v-icon @click="drawer = !drawer"> mdi-close </v-icon>
                </div>

                <v-list class="list-ctn">

                    <v-spacer></v-spacer>
                    <v-list-item class="bar-logo-ctn">
                        <img src="/img/winoh/winoh-grey.png" alt="winoh-logo"/>
                    </v-list-item>
                    <v-spacer></v-spacer>
                    
                    <v-list-item-group
                        v-model="selectedItem"
                        color="primary"
                    >
                        <v-list-item
                        active-class="bg-active"
                            v-for="(option, index) in sideBarOpc"
                            :key="index"
                        >
                            <v-list-item-icon>
                                <div class="icon-bar-ctn">
                                    <img :src="`${option.img}`" :alt="`${option.alt}`">
                                </div>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title v-text="option.text">
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>

        </nav>      
    <!-- </div> -->
</template>

<script>

export default {
    name: 'SideNavigation',
    components: {
        //mdiClose,
        },
    data() {
        return {
            drawer: false,
            mini: true,
            selectedItem: null, //aun no definido es para el valor del select se puede eliminar
            sideBarOpc: [
                {
                    position: 1,
                    alt: "search-icon",
                    img: "/img/winoh/search-grey.png",
                    text: "Consulta de buró de crédito"
                },
                {
                    position: 2,
                    alt: "account-icon",
                    img: "/img/winoh/account-grey.png",
                    text: "Mis consultas realizadas"
                },
                {
                    position: 3,
                    alt: "download-icon",
                    img: "/img/winoh/download-grey.png",
                    text: "Descarga de CSV"
                },
            ]
        }
    },
}
</script>

<style lang="scss" scoped>
    @import './scss/SideNavigation.scss';
</style>