<template>
  <div>
    <section>
      <div class="d-block d-sm-none">
        <section class="section section-md" style="padding-top: 0px!important;">
          <div class="container-grid ">
            <div class="row">
              <div class="col-12">
                <div class="img-principal-xs">
                  <img src="/img/HomepageNew/Mobile/Mujer hispana móvil.png" alt="IMG Presentacion x">
                </div>
                <div class="d-flex justify-content-center containerPrincipalxs">
                  <p class="titlePxs">
                    <b class="titleBxs">Te ayudamos a que tengas una vida sin deudas</b>
                    <br/>Obtén tu descuento de hasta 70% hoy mismo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="d-flex text-center">
            <div class="background-calcxs">
              <span class="spanCalcDiscountxs"> Calcula tu descuento</span>
              <p class="spanMoneyDebtxs">¿Cuánto dinero debes?</p>
              <h2
                  class="h2Amount-xs"
              >
                ${{
                  simpleCalc.amount < 300
                      ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                          ? Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          " ó menos"
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : Number(simpleCalc.amount * 1000)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                }}
              </h2>
              <div
                  class="row justify-content-center"
                  style="align-items: center; padding: 5%"
              >
                <vue-slider
                    :width="width"
                    :height="height"
                    style="animation:pulse2 2s infinite"
                    :min="30"
                    :max="300"
                    :step="5"
                    :tooltip="'none'"
                    :process-style="processStyle"
                    v-model.number="simpleCalc.amount"
                >
                  <template v-slot:dot="{ focus }">
                    <div :class="['custom-dot-xs', { focus }]"></div>
                  </template>
                </vue-slider>
              </div>

              <p class="span-who-debt-xs">Selecciona a quién le debes:</p>
              <div
                  class="row justify-content-center"
                  style="
                      margin-bottom: 3rem;
                      margin-top: -1.5rem;
                      align-items: center;
                    "
              >
                <div
                    class="col-3 text-center"
                    style="align-items: center"
                    v-for="(bank, index) in banks"
                    :key="index"
                >
                  <div
                      @click="m_switchSelected(index)"
                      style="margin-top: 20%"
                  >
                    <div class="g-item">
                      <img
                          :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                          :title="bank.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p class="span-institution-xs">
                Si tu institución financiera o banco no se encuentra aquí,
                por el momento no podemos ayudarte.
              </p>
              <base-button v-on:click="m_getDiscount()" id="btn-calc-xs">
                Dame mi descuento
              </base-button>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid info-asist-web-xs">
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div class="text-first-title-info-div-xs">
                    <p class="span-fist-text-xs">¿Cómo le hacemos?</p>
                  </div>
                  <div class="text-second-title-info-div-xs mt-5">
                    <p class="span-second-text-xs mt-5">
                      A través de un proceso digital de registro, ya sea en
                      nuestra <b class="text-device-xs">página web</b> o
                      mediante un Asistente Virtual Inteligente en
                      <b class="text-device-xs">WhatsApp.</b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12" id="container-info-xs">
                <div class="barra-gradient-xs"></div>
                <div class="div-imgs-barra-xs text-center">
                  <img
                      class="img-barra-xs"
                      src="/img/HomepageNew/Mobile/Laptop.png"
                      width="250"
                  />
                  <img
                      class="img-barra-xs"
                      src="/img/HomepageNew/Mobile/Smartphone.png"
                      width="180"
                  />
                </div>
              </div>
              <div class="col-12 div-instruction-xs">
                <div class="col-12 text-center">
                  <div class="text-first-intruction-info-div-xs">
                    <p class="span-first-intruction-xs">
                      Soluciona tus deudas en 5 minutos
                    </p>
                    <base-button id="btn-prueb-xs" v-on:click="openWhatsApp()">
                      <img
                          src="/img/HomepageNew/Mobile/WhatsApp logo.svg"
                          width="25" height="25"
                      />
                      PRUÉBAME
                    </base-button>
                    <p class="span-first-intruction-xs text-left">
                      <b class="text-device-xs">1.</b> Creamos un plan de ahorro
                      acorde a tu situación.
                      <br/>
                      <b class="text-device-xs">2.</b> Negociamos hasta obtener
                      el mejor descuento.
                      <br/>
                      <b class="text-device-xs">3.</b> Te ayudamos a
                      reincorporarte al sistema financiero.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-get-program-xs">
            <div class="row">
              <div class="col">
                <div class="d-flex">
                  <div class="div-text-title-get-program-xs">
                    <span class="span-title-text-get-program-xs">
                      ¿Qué obtienes al ingresar al programa?
                    </span>
                  </div>
                </div>
              </div>
              <div class="container-imgs-get-program-xs">
                <div class="col-12">
                  <div class="div-imgs-get-xs div-col-imgs-get-xs">
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Asesoría legal y financiera.png"
                      />
                      <p id="p-imgs-xs">Asesoría legal y financiera</p>
                    </div>
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Ayuda con la cobranza.png"
                      />
                      <p id="p-imgs-xs">Ayuda con la cobranza</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="div-imgs-get2-xs div-col-imgs-get-xs">
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Reincorporación al sistema crediticio.png"
                      />
                      <p id="p-imgs-xs">Reincorporación al sistema crediticio</p>
                    </div>
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Ningún cobro oculto.png"
                      />
                      <p id="p-imgs-xs">Ningún cobro oculto</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="div-imgs-get-xs div-col-imgs-get-xs">
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Control de tu programa.png"
                      />
                      <p id="p-imgs-xs">Control de tu programa</p>
                    </div>
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Compromiso por escrito.png"
                          width="61%"
                      />
                      <p id="p-imgs-xs">Compromiso por escrito</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="div-imgs-get2-xs div-col-imgs-get-xs">
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Atención personalizada.png"
                          width="78%"
                      />
                      <p id="p-imgs-xs">Atención personalizada</p>
                    </div>
                    <div class="cols-imgs-get-xs col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Mejora en tus finanzas personales.png"
                          width="75%"
                      />
                      <p id="p-imgs-xs">Mejora en tus finanzas personales</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-what-need-xs">
            <div class="row">
              <div class="col-12">
                <div class="div-what-need-xs text-center">
                  <div class="span-title-need-xs">
                    ¿Qué necesito para solucionar mi deuda?
                  </div>
                </div>
                <div class="div-what-need-xs text-center">
                  <img
                      src="/img/HomepageNew/Mobile/Avatar 3D.png"
                      width="300"
                      height="300"
                  />
                </div>
                <div class="div-what-need-xs">
                  <p class="span-options-need-xs">
                    <img
                        src="/img/HomepageNew/Mobile/Dinero icono.svg"
                        width="30"
                        height="30"
                    />
                    Deuda mayor a $10,000 pesos
                  </p>
                </div>
                <hr class="hr-what-need-xs"/>
                <div class="div-what-need-xs">
                  <p class="span-options-need-xs">
                    <img
                        src="/img/HomepageNew/Mobile/Reloj icono.svg"
                        width="30"
                        height="30"
                    />
                    Tener pagos atrasados
                  </p>
                </div>
                <hr class="hr-what-need-xs"/>
                <div class="div-what-need-xs">
                  <p class="span-options-need-xs">
                    <img
                        src="/img/HomepageNew/Mobile/Nómina icono.svg"
                        width="30"
                        height="30"
                    />
                    No recibir la nómina en el banco donde tienes adeudo
                  </p>
                </div>
                <hr class="hr-what-need-xs"/>
                <div class="div-what-need-xs">
                  <p class="span-options-need-xs">
                    <img
                        src="/img/HomepageNew/Mobile/Tarjeta icono.svg"
                        width="30"
                        height="30"
                    />
                    Deudas con tarjetas de crédito, departamentales o préstamos
                    bancarios
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-graduated-xs">
            <div class="row">
              <div class="col align-self-center">
                <div class="div-title-graduated-xs text-center">
                  <div class="span-title-graduated-xs">Nuestros clientes</div>
                  <div class="p-title-graduated-xs">
                    Casos de éxito que solucionaron sus deudas.
                  </div>
                </div>
                <div class="embed-responsive embed-responsive-16by9">
                  <video controls class="embed-responsive-item"
                         src="/img/HomepageNew/Videos/Nuestros clientes/Graduada final.mp4" autoplay muted>
                  </video>
                </div>
                <div class="div-coments-graduated-xs">
                  <img
                      class="img-coments-graduated-xs"
                      src="/img/HomepageNew/Mobile/Alexander Mendez opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-xs">
                  <img
                      class="img-coments-graduated-xs"
                      src="/img/HomepageNew/Mobile/Claudia Damián opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-xs">
                  <img
                      class="img-coments-graduated-xs"
                      src="/img/HomepageNew/Mobile/Eduardo Albor opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-xs">
                  <img
                      class="img-coments-graduated-xs"
                      src="/img/HomepageNew/Mobile/Claudia Damián opinión.png"
                      width="450"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <div class="d-none d-sm-block d-md-none">
        <section class="section section-md" style="padding-top: 0px !important;">
          <div class="container-grid">
            <div class="row">
              <div class="col-12">
                <div class="img-principal-sm">
                  <img src="/img/HomepageNew/Mobile/Mujer hispana móvil.png" alt="IMG Presentacion">
                </div>
                <div class="d-flex justify-content-center containerPrincipalsm">
                  <p class="titlePsm">
                    <b class="titleBsm">Te ayudamos a que tengas una vida sin deudas</b>
                    <br/>Obtén tu descuento de hasta 70% hoy mismo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="d-flex text-center">
            <div class="background-calcsm">
              <span class="spanCalcDiscountsm"> Calcula tu descuento</span>
              <p class="spanMoneyDebtsm">¿Cuánto dinero debes?</p>
              <h2
                  class="h2Amount-sm"
              >
                ${{
                  simpleCalc.amount < 300
                      ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                          ? Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          " ó menos"
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : Number(simpleCalc.amount * 1000)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                }}
              </h2>
              <div
                  class="row justify-content-center"
                  style="align-items: center; padding: 5%"
              >
                <vue-slider
                    :width="width"
                    :height="height"
                    style="animation:pulse2 2s infinite"
                    :min="30"
                    :max="300"
                    :step="5"
                    :tooltip="'none'"
                    :process-style="processStyle"
                    v-model.number="simpleCalc.amount"
                >
                  <template v-slot:dot="{ focus }">
                    <div :class="['custom-dot-sm', { focus }]"></div>
                  </template>
                </vue-slider>
              </div>

              <p class="span-who-debt-sm">Selecciona a quién le debes:</p>
              <div
                  class="row justify-content-center"
                  style="
                      margin-bottom: 3rem;
                      margin-top: -1.5rem;
                      align-items: center;
                    "
              >
                <div
                    class="col-3 text-center"
                    style="align-items: center"
                    v-for="(bank, index) in banks"
                    :key="index"
                >
                  <div
                      @click="m_switchSelected(index)"
                      style="margin-top: 20%"
                  >
                    <div class="g-item">
                      <img
                          :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                          :title="bank.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p class="span-institution-sm">
                Si tu institución financiera o banco no se encuentra aquí,
                por el momento no podemos ayudarte.
              </p>
              <base-button v-on:click="m_getDiscount()" id="btn-calc-sm">
                Dame mi descuento
              </base-button>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid info-asist-web-sm">
            <div class="row">
              <div class="col">
                <div class="d-none d-sm-flex">
                  <div class="text-first-title-info-div-sm">
                    <p class="span-fist-text-sm">¿Cómo le hacemos?</p>
                  </div>
                  <div class="text-second-title-info-div-sm">
                    <p class="span-second-text-sm">
                      A través de un proceso digital de registro, ya sea en
                      nuestra <b class="text-device-sm">página web</b> o
                      mediante un Asistente Virtual Inteligente en
                      <b class="text-device-sm">WhatsApp.</b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12" id="container-info-sm">
                <div class="barra-gradient-sm"></div>
                <div class="div-imgs-barra-sm text-center">
                  <img
                      class="img-barra-sm"
                      src="/img/HomepageNew/Mobile/Laptop.png"
                      width="320"
                  />
                  <img
                      class="img-barra-sm"
                      src="/img/HomepageNew/Mobile/Smartphone.png"
                      width="220"
                  />
                </div>
              </div>
              <div class="col-12 div-instruction-sm">
                <div class="col-12 text-center">
                  <div class="text-first-intruction-info-div-sm">
                    <p class="span-first-intruction-sm">
                      Soluciona tus deudas en 5 minutos
                    </p>
                    <base-button id="btn-prueb-sm" v-on:click="openWhatsApp()">
                      <img
                          src="/img/HomepageNew/Mobile/WhatsApp logo.svg"
                          width="25" height="25"
                      />
                      PRUÉBAME
                    </base-button>
                    <p class="span-first-intruction-sm text-left">
                      <b class="text-device-sm">1.</b> Creamos un plan de ahorro
                      acorde a tu situación.
                      <br/>
                      <b class="text-device-sm">2.</b> Negociamos hasta obtener
                      el mejor descuento.
                      <br/>
                      <b class="text-device-sm">3.</b> Te ayudamos a
                      reincorporarte al sistema financiero.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-get-program-sm">
            <div class="row">
              <div class="col">
                <div class="d-none d-sm-flex">
                  <div class="div-text-title-get-program-sm">
                    <span class="span-title-text-get-program-sm">
                      ¿Qué obtienes al ingresar al programa?
                    </span>
                  </div>
                </div>
              </div>
              <div class="container-imgs-get-program-sm">
                <div class="col-12">
                  <div class="div-imgs-get-sm div-col-imgs-get-sm">
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Asesoría legal y financiera.png"
                      />
                      <p id="p-imgs-sm">Asesoría legal y financiera</p>
                    </div>
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Ayuda con la cobranza.png"
                      />
                      <p id="p-imgs-sm">Ayuda con la cobranza</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="div-imgs-get2-sm div-col-imgs-get-sm">
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Reincorporación al sistema crediticio.png"
                      />
                      <p id="p-imgs-sm">Reincorporación al sistema crediticio</p>
                    </div>
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Ningún cobro oculto.png"
                      />
                      <p id="p-imgs-sm">Ningún cobro oculto</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="div-imgs-get-sm div-col-imgs-get-sm">
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Control de tu programa.png"
                      />
                      <p id="p-imgs-sm">Control de tu programa</p>
                    </div>
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Compromiso por escrito.png"
                          width="61%"
                      />
                      <p id="p-imgs-sm">Compromiso por escrito</p>
                    </div>
                  </div>
                </div>
                <div class="col-12">
                  <div class="div-imgs-get2-sm div-col-imgs-get-sm">
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Atención personalizada.png"
                          width="78%"
                      />
                      <p id="p-imgs-sm">Atención personalizada</p>
                    </div>
                    <div class="cols-imgs-get-sm col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Mejora en tus finanzas personales.png"
                          width="75%"
                      />
                      <p id="p-imgs-sm">Mejora en tus finanzas personales</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-what-need-sm">
            <div class="row">
              <div class="col-12">
                <div class="div-what-need-sm text-center">
                  <div class="span-title-need-sm">
                    ¿Qué necesito para solucionar mi deuda?
                  </div>
                </div>
                <div class="div-what-need-sm text-center">
                  <img
                      src="/img/HomepageNew/Mobile/Avatar 3D.png"
                      width="300"
                      height="300"
                  />
                </div>
                <div class="div-what-need-sm">
                  <p class="span-options-need-sm">
                    <img
                        src="/img/HomepageNew/Mobile/Dinero icono.svg"
                        width="40"
                        height="40"
                    />
                    Deuda mayor a $10,000 pesos
                  </p>
                </div>
                <hr class="hr-what-need-sm"/>
                <div class="div-what-need-sm">
                  <p class="span-options-need-sm">
                    <img
                        src="/img/HomepageNew/Mobile/Reloj icono.svg"
                        width="40"
                        height="40"
                    />
                    Tener pagos atrasados
                  </p>
                </div>
                <hr class="hr-what-need-sm"/>
                <div class="div-what-need-sm">
                  <p class="span-options-need-sm">
                    <img
                        src="/img/HomepageNew/Mobile/Nómina icono.svg"
                        width="40"
                        height="40"
                    />
                    No recibir la nómina en el banco donde tienes adeudo
                  </p>
                </div>
                <hr class="hr-what-need-sm"/>
                <div class="div-what-need-sm">
                  <p class="span-options-need-sm">
                    <img
                        src="/img/HomepageNew/Mobile/Tarjeta icono.svg"
                        width="40"
                        height="40"
                    />
                    Deudas con tarjetas de crédito, departamentales o préstamos
                    bancarios
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-graduated-sm">
            <div class="row">
              <div class="col align-self-center">
                <div class="div-title-graduated-sm text-center">
                  <div class="span-title-graduated-sm">Nuestros clientes</div>
                  <div class="p-title-graduated-sm">
                    Casos de éxito que solucionaron sus deudas.
                  </div>
                </div>
                <div class="embed-responsive embed-responsive-16by9">
                  <video controls class="embed-responsive-item"
                         src="/img/HomepageNew/Videos/Nuestros clientes/Graduada final.mp4" autoplay muted>
                  </video>
                </div>
                <div class="div-coments-graduated-sm">
                  <img
                      class="img-coments-graduated-sm"
                      src="/img/HomepageNew/Mobile/Abel García opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-sm">
                  <img
                      class="img-coments-graduated-sm"
                      src="/img/HomepageNew/Mobile/Alexander Mendez opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-sm">
                  <img
                      class="img-coments-graduated-sm"
                      src="/img/HomepageNew/Mobile/Eduardo Albor opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-sm">
                  <img
                      class="img-coments-graduated-sm"
                      src="/img/HomepageNew/Mobile/Claudia Damián opinión.png"
                      width="450"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div class="d-none d-md-block d-lg-none">
        <section class="section section-md" style="padding-top: 0px !important;">
          <div class="container-grid">
            <div class="row">
              <div class="col-12">
                <div class="img-principal-md">
                  <img src="/img/HomepageNew/Mobile/Mujer hispana móvil.png" alt="IMG Presentacion">
                </div>
                <div class="d-flex justify-content-center containerPrincipalmd">
                  <p class="titlePmd">
                    <b class="titleBmd">Te ayudamos a que tengas una vida sin deudas</b>
                    <br/>Obtén tu descuento de hasta 70% hoy mismo.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="d-flex text-center">
            <div class="background-calcmd">
              <span class="spanCalcDiscountmd"> Calcula tu descuento</span>
              <p class="spanMoneyDebtmd">¿Cuánto dinero debes?</p>
              <h2
                  class="h2Amount-md"
              >
                ${{
                  simpleCalc.amount < 300
                      ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                          ? Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                          " ó menos"
                          : Number(simpleCalc.amount * 1000)
                              .toFixed(0)
                              .toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      : Number(simpleCalc.amount * 1000)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                }}
              </h2>
              <div
                  class="row justify-content-center"
                  style="align-items: center; padding: 5%"
              >
                <vue-slider
                    :width="width"
                    :height="height"
                    style="animation:pulse2 2s infinite"
                    :min="30"
                    :max="300"
                    :step="5"
                    :tooltip="'none'"
                    :process-style="processStyle"
                    v-model.number="simpleCalc.amount"
                >
                  <template v-slot:dot="{ focus }">
                    <div :class="['custom-dot-md', { focus }]"></div>
                  </template>
                </vue-slider>
              </div>

              <p class="span-who-debt-md">Selecciona a quién le debes:</p>
              <div
                  class="row justify-content-center"
                  style="
                      margin-bottom: 3rem;
                      margin-top: -1.5rem;
                      align-items: center;
                    "
              >
                <div
                    class="col-3 text-center"
                    style="align-items: center"
                    v-for="(bank, index) in banks"
                    :key="index"
                >
                  <div
                      @click="m_switchSelected(index)"
                      style="margin-top: 20%"
                  >
                    <div class="g-item">
                      <img
                          :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                          :title="bank.name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <p class="span-institution-md">
                Si tu institución financiera o banco no se encuentra aquí,
                por el momento no podemos ayudarte.
              </p>
              <base-button v-on:click="m_getDiscount()" id="btn-calc-md">
                Dame mi descuento
              </base-button>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid info-asist-web-md">
            <div class="row">
              <div class="col">
                <div class="d-none d-md-flex">
                  <div class="text-first-title-info-div-md">
                    <p class="span-fist-text-md">¿Cómo le hacemos?</p>
                  </div>
                  <div class="text-second-title-info-div-md">
                    <p class="span-second-text-md">
                      A través de un proceso digital de registro, ya sea en
                      nuestra <b class="text-device-md">página web</b> o
                      mediante un Asistente Virtual Inteligente en
                      <b class="text-device-md">WhatsApp.</b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12" id="container-info-md">
                <div class="barra-gradient-md"></div>
                <div class="div-imgs-barra-md text-center">
                  <img
                      class="img-barra-md"
                      src="/img/HomepageNew/Mobile/Laptop.png"
                      width="400px"
                  />
                  <img
                      class="img-barra-md"
                      src="/img/HomepageNew/Mobile/Smartphone.png"
                      width="250px"
                  />
                </div>
              </div>
              <div class="col-12 div-instruction-md">
                <div class="col-12 text-center">
                  <div class="text-first-intruction-info-div-md">
                    <p class="span-first-intruction-md">
                      Soluciona tus deudas en 5 minutos
                    </p>
                    <base-button id="btn-prueb-md" v-on:click="openWhatsApp()">
                      <img
                          src="/img/HomepageNew/Mobile/WhatsApp logo.svg"
                          width="30" height="30"
                      />
                      PRUÉBAME
                    </base-button>
                    <p class="span-first-intruction-md text-left">
                      <b class="text-device-md">1.</b> Creamos un plan de ahorro
                      acorde a tu situación.
                      <br/>
                      <b class="text-device-md">2.</b> Negociamos hasta obtener
                      el mejor descuento.
                      <br/>
                      <b class="text-device-md">3.</b> Te ayudamos a
                      reincorporarte al sistema financiero.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-get-program-md">
            <div class="row">
              <div class="col">
                <div class="d-none d-md-flex">
                  <div class="div-text-title-get-program-md">
                    <span class="span-title-text-get-program-md">
                      ¿Qué obtienes al ingresar al programa?
                    </span>
                  </div>
                </div>
              </div>
              <div class="container-imgs-get-program-md">
                <div class="col-12" id="container-imgs-get-program-md">
                  <div class="div-imgs-get-md div-col-imgs-get-md">
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Asesoría legal y financiera.png"
                      />
                      <p id="p-imgs-md">Asesoría legal y financiera</p>
                    </div>
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Ayuda con la cobranza.png"
                      />
                      <p id="p-imgs-md">Ayuda con la cobranza</p>
                    </div>
                  </div>
                </div>
                <div class="col-12" id="container-imgs-get-program-md">
                  <div class="div-imgs-get2-md div-col-imgs-get-md">
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Reincorporación al sistema crediticio.png"
                      />
                      <p id="p-imgs-md">Reincorporación al sistema crediticio</p>
                    </div>
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Ningún cobro oculto.png"
                      />
                      <p id="p-imgs-md">Ningún cobro oculto</p>
                    </div>
                  </div>
                </div>
                <div class="col-12" id="container-imgs-get-program-md">
                  <div class="div-imgs-get-md div-col-imgs-get-md">
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Control de tu programa.png"
                      />
                      <p id="p-imgs-md">Control de tu programa</p>
                    </div>
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Compromiso por escrito.png"
                          width="61%"
                      />
                      <p id="p-imgs-md">Compromiso por escrito</p>
                    </div>
                  </div>
                </div>
                <div class="col-12" id="container-imgs-get-program-md">
                  <div class="div-imgs-get2-md div-col-imgs-get-md">
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Atención personalizada.png"
                          width="78%"
                      />
                      <p id="p-imgs-md">Atención personalizada</p>
                    </div>
                    <div class="cols-imgs-get-md col-12">
                      <img
                          src="/img/HomepageNew/Mobile/Mejora en tus finanzas personales.png"
                          width="75%"
                      />
                      <p id="p-imgs-md">Mejora en tus finanzas personales</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-what-need-md">
            <div class="row">
              <div class="col-12">
                <div class="div-what-need-md text-center">
                  <div class="span-title-need-md">
                    ¿Qué necesito para solucionar mi deuda?
                  </div>
                </div>
                <div class="div-what-need-md text-center">
                  <img
                      src="/img/HomepageNew/Mobile/Avatar 3D.png"
                      width="500"
                      height="500"
                  />
                </div>
                <div class="div-what-need-md">
                  <p class="span-options-need-md">
                    <img
                        src="/img/HomepageNew/Mobile/Dinero icono.svg"
                        width="40"
                        height="40"
                    />
                    Deuda mayor a $10,000 pesos
                  </p>
                </div>
                <hr class="hr-what-need-md"/>
                <div class="div-what-need-md">
                  <p class="span-options-need-md">
                    <img
                        src="/img/HomepageNew/Mobile/Reloj icono.svg"
                        width="40"
                        height="40"
                    />
                    Tener pagos atrasados
                  </p>
                </div>
                <hr class="hr-what-need-md"/>
                <div class="div-what-need-md">
                  <p class="span-options-need-md">
                    <img
                        src="/img/HomepageNew/Mobile/Nómina icono.svg"
                        width="40"
                        height="40"
                    />
                    No recibir la nómina en el banco donde tienes adeudo
                  </p>
                </div>
                <hr class="hr-what-need-md"/>
                <div class="div-what-need-md">
                  <p class="span-options-need-md">
                    <img
                        src="/img/HomepageNew/Mobile/Tarjeta icono.svg"
                        width="40"
                        height="40"
                    />
                    Deudas con tarjetas de crédito, departamentales o préstamos
                    bancarios
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-graduated-md">
            <div class="row">
              <div class="col align-self-center">
                <div class="div-title-graduated-md text-center">
                  <div class="span-title-graduated-md">Nuestros clientes</div>
                  <div class="p-title-graduated-md">
                    Casos de éxito que solucionaron sus deudas.
                  </div>
                </div>
                <div class="embed-responsive embed-responsive-16by9">
                  <video controls class="embed-responsive-item"
                         src="/img/HomepageNew/Videos/Nuestros clientes/Graduada final.mp4" autoplay muted>
                  </video>
                </div>
                <div class="div-coments-graduated-md">
                  <img
                      class="img-coments-graduated-md"
                      src="/img/HomepageNew/Mobile/Abel García opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-md">
                  <img
                      class="img-coments-graduated-md"
                      src="/img/HomepageNew/Mobile/Alexander Mendez opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-md">
                  <img
                      class="img-coments-graduated-md"
                      src="/img/HomepageNew/Mobile/Eduardo Albor opinión.png"
                      width="450"
                  />
                </div>
                <div class="div-coments-graduated-md">
                  <img
                      class="img-coments-graduated-md"
                      src="/img/HomepageNew/Mobile/Claudia Damián opinión.png"
                      width="450"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <div class="d-none d-lg-block">
        <section class="section section-md" style="padding-top: 0px !important;">
          <div class="container-grid" id="img-principal-xl">
            <div class="row">
              <div class="col-6">
                <div class="d-none d-lg-flex containerPrincipalxl">
                  <p class="titlePxl">
                    <b class="titleBxl">Te ayudamos a que tengas una vida sin deudas</b>
                    <br/>Obtén tu descuento de hasta 70% hoy mismo.
                  </p>
                </div>
              </div>
              <div class="col-6 text-center">
                <div class="background-calcxl">
                  <span class="spanCalcDiscountxl"> Calcula tu descuento</span>
                  <p class="spanMoneyDebtxl">¿Cuánto dinero debes?</p>
                  <h2
                      class="h2Amount-xl"
                      style="
                      margin-top: -1.5rem;
                      margin-bottom: -1rem;
                      font-size: 35pt;
                    "
                  >
                    ${{
                      simpleCalc.amount < 300
                          ? Number(simpleCalc.amount * 1000).toFixed(0) <= 30000
                              ? Number(simpleCalc.amount * 1000)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") +
                              " ó menos"
                              : Number(simpleCalc.amount * 1000)
                                  .toFixed(0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                          : Number(simpleCalc.amount * 1000)
                          .toFixed(0)
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",") + " ó más"
                    }}
                  </h2>
                  <div
                      class="row justify-content-center"
                      style="align-items: center; padding: 5%"
                  >
                    <vue-slider
                        :width="width"
                        :height="height"
                        style="animation:pulse2 2s infinite"
                        :min="30"
                        :max="300"
                        :step="5"
                        :tooltip="'none'"
                        :process-style="processStyle"
                        v-model.number="simpleCalc.amount"
                    >
                      <template v-slot:dot="{ focus }">
                        <div :class="['custom-dot-xl', { focus }]"></div>
                      </template>
                    </vue-slider>
                  </div>

                  <p class="span-who-debt-xl">Selecciona a quién le debes:</p>
                  <div
                      class="row justify-content-center"
                      style="
                      margin-bottom: 3rem;
                      margin-top: -1.5rem;
                      align-items: center;
                    "
                  >
                    <div
                        class="col-3 text-center"
                        style="align-items: center"
                        v-for="(bank, index) in banks"
                        :key="index"
                    >
                      <div
                          @click="m_switchSelected(index)"
                          style="margin-top: 20%"
                      >
                        <div class="g-item">
                          <img
                              :src="
                              bank.selected ? bank.url_y : bank.url_w_mobile
                            "
                              :title="bank.name"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <p class="span-institution-xl">
                    Si tu institución financiera o banco no se encuentra aquí,
                    por el momento no podemos ayudarte.
                  </p>
                  <base-button v-on:click="m_getDiscount()" id="btn-calc-xl">
                    Dame mi descuento
                  </base-button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid info-asist-web-xl">
            <div class="row">
              <div class="col">
                <div class="d-none d-lg-flex">
                  <div class="text-first-title-info-div-xl">
                    <p class="span-fist-text-xl">¿Cómo le hacemos?</p>
                  </div>
                  <div class="text-second-title-info-div-xl">
                    <p class="span-second-text-xl">
                      A través de un proceso digital de registro, ya sea en
                      nuestra <b class="text-device-xl">página web</b> o
                      mediante un Asistente Virtual Inteligente en
                      <b class="text-device-xl">WhatsApp.</b>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12" id="container-info-xl">
                <div class="barra-gradient-xl"></div>
                <div class="div-imgs-barra-xl">
                  <img
                      class="img-barra-xl"
                      src="/img/HomepageNew/Desktop/Laptop.png"
                      width="450px"
                  />
                  <img
                      class="img-barra-xl"
                      src="/img/HomepageNew/Desktop/Smartphone.png"
                      width="300px"
                  />
                </div>
              </div>
              <div class="col-12 div-instruction-xl">
                <div class="col-7">
                  <div class="text-first-intruction-info-div-xl">
                    <p class="span-first-intruction-xl">
                      <b class="text-device-xl">1.</b> Creamos un plan de ahorro
                      acorde a tu situación.
                      <br/>
                      <b class="text-device-xl">2.</b> Negociamos hasta obtener
                      el mejor descuento.
                      <br/>
                      <b class="text-device-xl">3.</b> Te ayudamos a
                      reincorporarte al sistema financiero.
                    </p>
                  </div>
                </div>
                <div class="col-4 text-center">
                  <div class="d-none d-lg-flex">
                    <div class="text-first-intruction-info-div-xl">
                      <p class="span-first-intruction-xl">
                        Soluciona tus deudas en 5 minutos
                      </p>
                      <base-button id="btn-prueb-xl" v-on:click="openWhatsApp()">
                        <img
                            src="/img/HomepageNew/Desktop/WhatsApp logo.svg"
                            width="15%"
                        />
                        PRUÉBAME
                      </base-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-get-program-xl">
            <div class="row">
              <div class="col">
                <div class="d-none d-lg-flex">
                  <div class="div-text-title-get-program-xl">
                    <span class="span-title-text-get-program-xl">
                      ¿Qué obtienes al ingresar al programa?
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-12" id="container-imgs-get-program-xl">
                <div class="div-imgs-get-xl div-col-imgs-get-xl">
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Asesoría legal y financiera.png"
                        width="80%"
                    />
                    <p id="p-imgs-xl">Asesoría legal y financiera</p>
                  </div>
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Ayuda con la cobranza.png"
                        width="85%"
                    />
                    <p id="p-imgs-xl">Ayuda con la cobranza</p>
                  </div>
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Reincorporación al sistema crediticio.png"
                    />
                    <p id="p-imgs-xl">Reincorporación al sistema crediticio</p>
                  </div>
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Ningún cobro oculto.png"
                    />
                    <p id="p-imgs-xl">Ningún cobro oculto</p>
                  </div>
                </div>
              </div>
              <div class="col-12" id="container-imgs-get-program-xl">
                <div class="div-imgs-get2-xl div-col-imgs-get-xl">
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Control de tu programa.png"
                    />
                    <p id="p-imgs-xl">Control de tu programa</p>
                  </div>
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Compromiso por escrito.png"
                        width="61%"
                    />
                    <p id="p-imgs-xl">Compromiso por escrito</p>
                  </div>
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Atención personalizada.png"
                        width="78%"
                    />
                    <p id="p-imgs-xl">Atención personalizada</p>
                  </div>
                  <div class="cols-imgs-get-xl col-12">
                    <img
                        src="/img/HomepageNew/Desktop/Mejora en tus finanzas personales.png"
                        width="75%"
                    />
                    <p id="p-imgs-xl">Mejora en tus finanzas personales</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-what-need-xl">
            <div class="row">
              <div class="col-6">
                <div class="div-what-need-xl">
                  <div class="span-title-need-xl">
                    ¿Qué necesito para solucionar mi deuda?
                  </div>
                </div>
                <div class="div-what-need-xl">
                  <p class="span-options-need-xl">
                    <img
                        src="/img/HomepageNew/Desktop/Dinero icono.svg"
                        width="40"
                        height="40"
                    />
                    Deuda mayor a $10,000 pesos
                  </p>
                </div>
                <hr class="hr-what-need-xl"/>
                <div class="div-what-need-xl">
                  <p class="span-options-need-xl">
                    <img
                        src="/img/HomepageNew/Desktop/Reloj icono.svg"
                        width="40"
                        height="40"
                    />
                    Tener pagos atrasados
                  </p>
                </div>
                <hr class="hr-what-need-xl"/>
                <div class="div-what-need-xl">
                  <p class="span-options-need-xl">
                    <img
                        src="/img/HomepageNew/Desktop/Nómina icono.svg"
                        width="40"
                        height="40"
                    />
                    No recibir la nómina en el banco donde tienes adeudo
                  </p>
                </div>
                <hr class="hr-what-need-xl"/>
                <div class="div-what-need-xl">
                  <p class="span-options-need-xl">
                    <img
                        src="/img/HomepageNew/Desktop/Tarjeta icono.svg"
                        width="40"
                        height="40"
                    />
                    Deudas con tarjetas de crédito, departamentales o préstamos
                    bancarios
                  </p>
                </div>
              </div>
              <div class="col-6 text-center">
                <div class="div-img-avatar-xl">
                  <img
                      src="/img/HomepageNew/Desktop/Avatar 3D.png"
                      width="500"
                      height="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div class="container-fluid" id="container-graduated-xl">
            <div class="row">
              <div class="col align-self-center">
                <div class="div-title-graduated-xl">
                  <div class="span-title-graduated-xl">Nuestros clientes</div>
                  <div class="p-title-graduated-xl">
                    Casos de éxito que solucionaron sus deudas.
                  </div>
                </div>
                <div class="embed-responsive embed-responsive-16by9">
                  <video controls
                         src="/img/HomepageNew/Videos/Nuestros clientes/Graduada final.mp4" autoplay muted></video>
                </div>
              </div>
              <div class="col">
                <div class="div-coments-graduated-xl">
                  <img
                      class="img-coments-graduated-xl"
                      src="/img/HomepageNew/Desktop/Abel García opinión.png"
                      width="400"
                  />
                </div>
                <div class="div-coments-graduated-xl">
                  <img
                      class="img-coments-graduated-xl"
                      src="/img/HomepageNew/Desktop/Alexander Mendez opinión.png"
                      width="400"
                  />
                </div>
                <div class="div-coments-graduated-xl">
                  <img
                      class="img-coments-graduated-xl"
                      src="/img/HomepageNew/Desktop/Eduardo Albor opinión.png"
                      width="400"
                  />
                </div>
                <div class="div-coments-graduated-xl">
                  <img
                      class="img-coments-graduated-xl"
                      src="/img/HomepageNew/Desktop/Claudia Damián opinión.png"
                      width="400"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
    <modal
        :show.sync="discountModal"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
    >
      <card id="discountLead" shadow class="border-0">
        <template>
          <div class="text-center text-muted mb-4 d-block d-sm-none">
            <span id="spanGothamBookxs">
              Debes<b id="spanGothamBoldxs">
                ${{
                Number(simpleCalc.amount * 1000)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}</b
            >
              con:
            </span>
            <div
                class="row justify-content-center"
                style="margin-bottom: 1rem; align-items: center"
            >
              <div
                  class="col-6 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div style="margin-top: 1%">
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBookxs"> Con DB Menos pagarías </span>
            <br/>
            <div style="margin-top: 0.5rem">
              <span id="spanUltraxs">
                ${{
                  Number((simpleCalc.amount * 1000) / 2)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}</span
              >
            </div>
            <div style="margin-top: 1rem">
              <span id="spanGothamBookxs">
                En <b id="spanGothamBoldxs"> cómodas mensualidades </b>
                de acorde a tu capacidad de pago.
              </span>
            </div>
            <div style="margin-top: 20px">
              <base-button v-on:click="m_startinglead()" id="btnWantmd">
                ¡LO QUIERO!
              </base-button>
            </div>
          </div>
          <div
              class="text-center text-muted mb-4 d-none d-sm-block d-md-none"
              style="padding: 1%"
          >
            <span id="spanGothamBooksm">
              Debes<b id="spanGothamBoldsm">
                ${{
                Number(simpleCalc.amount * 1000)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}</b
            >
              con:
            </span>
            <div
                class="row justify-content-center"
                style="margin-bottom: 1rem; align-items: center"
            >
              <div
                  class="col-6 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div style="margin-top: 20%">
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBooksm"> Con DB Menos pagarías </span>
            <br/>
            <div style="margin-top: 1rem">
              <span id="spanUltrasm">
                ${{
                  Number((simpleCalc.amount * 1000) / 2)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}</span
              >
            </div>
            <div style="margin-top: 1.5rem">
              <span id="spanGothamBooksm">
                En <b id="spanGothamBoldsm"> cómodas mensualidades </b>
                de acorde a tu capacidad de pago.
              </span>
            </div>
            <div style="margin-top: 20px">
              <base-button v-on:click="m_startinglead()" id="btnWantmd">
                ¡LO QUIERO!
              </base-button>
            </div>
          </div>
          <div class="text-center text-muted mb-4 d-none d-md-block d-lg-none">
            <span id="spanGothamBookmd">
              Debes<b id="spanGothamBoldmd">
                ${{
                Number(simpleCalc.amount * 1000)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}</b
            >
              con:
            </span>
            <div
                class="row justify-content-center"
                style="margin-bottom: 1rem; margin-top: 1rem; align-items: center"
            >
              <div
                  class="col-6 text-center"
                  style="align-items: center; margin-top: 0.5rem"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div class="g-item">
                  <img
                      v-bind:src="getBank(bank.id).url_w_complete"
                      :title="bank.name"
                  />
                </div>
              </div>
            </div>
            <span id="spanGothamBookmd"> Con DB Menos pagarías </span>
            <br/>
            <div style="margin-top: 0.5rem">
              <span id="spanUltramd">
                ${{
                  Number((simpleCalc.amount * 1000) / 2)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}</span
              >
            </div>
            <div style="margin-top: 0.5rem">
              <span id="spanGothamBookmd">
                En <b id="spanGothamBoldmd"> cómodas mensualidades </b>
                de acorde a tu capacidad de pago.
              </span>
            </div>
            <div style="margin-top: 20px">
              <base-button v-on:click="m_startinglead()" id="btnWantmd">
                ¡LO QUIERO!
              </base-button>
            </div>
          </div>
          <div class="text-center text-muted mb-4 d-none d-lg-block d-xl-none">
            <span id="spanGothamBooklg">
              Debes<b id="spanGothamBoldlg">
                ${{
                Number(simpleCalc.amount * 1000)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}</b
            >
              con:
            </span>
            <div
                class="row justify-content-center"
                style="margin-bottom: 1rem; align-items: center"
            >
              <div
                  class="col-3 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div style="margin-top: 20%">
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBooklg"> Con DB Menos pagarías </span>
            <br/>
            <div style="margin-top: 0.5rem">
              <span id="spanUltralg">
                ${{
                  Number((simpleCalc.amount * 1000) / 2)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}</span
              >
            </div>
            <div style="margin-top: 1rem">
              <span id="spanGothamBooklg">
                En <b id="spanGothamBoldlg"> cómodas mensualidades </b>
                de acorde a tu capacidad de pago.
              </span>
            </div>
            <div style="margin-top: 25px">
              <base-button v-on:click="m_startinglead()" id="btnWantlg">
                ¡LO QUIERO!
              </base-button>
            </div>
          </div>
          <div class="text-center text-muted mb-4 d-none d-xl-block">
            <span id="spanGothamBookxl">
              Debes<b id="spanGothamBoldxl">
                ${{
                Number(simpleCalc.amount * 1000)
                    .toFixed(0)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }}</b
            >
              con:
            </span>
            <div
                class="row justify-content-center"
                style="margin-bottom: 1rem; align-items: center"
            >
              <div
                  class="col-3 text-center"
                  style="align-items: center"
                  v-for="(bank, index) in banksSelected"
                  :key="index"
              >
                <div style="margin-top: 20%">
                  <div class="g-item">
                    <img
                        v-bind:src="getBank(bank.id).url_w_complete"
                        :title="bank.name"
                    />
                  </div>
                </div>
              </div>
            </div>
            <span id="spanGothamBookxl"> Con DB Menos pagarías </span>
            <br/>
            <div style="margin-top: 1.5rem">
              <span id="spanUltraxl">
                ${{
                  Number((simpleCalc.amount * 1000) / 2)
                      .toFixed(0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                }}</span
              >
            </div>
            <div style="margin-top: 2rem">
              <span id="spanGothamBookxl">
                En <b id="spanGothamBoldxl"> cómodas mensualidades </b>
                de acorde a tu capacidad de pago.
              </span>
            </div>
            <div style="margin-top: 15px">
              <base-button v-on:click="m_startinglead()" id="btnWantxl">
                ¡LO QUIERO!
              </base-button>
            </div>
          </div>
        </template>
      </card>
    </modal>
    <modal
        :show.sync="leadModal"
        body-classes="p-0"
        modal-classes="modal-dialog-centered modal-sm"
    >
      <card
          id="discountLead"
          type="secondary"
          shadow
          header-classes="bg-white pb-5"
          body-classes="px-lg-5 py-lg-5"
          style="opacity: 1 !important"
          class="border-0"
      >
        <template>
          <div class="text-center text-muted mb-4">
            <small id="spanGothamBoldLead"
            >Ingresa tus datos para obtener tu descuento</small
            >
          </div>
          <form role="form">
            <base-input
                alternative
                class="mb-3"
                v-model="leadInfo.name"
                :valid="m_hasValidName()"
                placeholder="Nombre Completo"
                addon-left-icon="ni ni-circle-08"
            ></base-input>
            <base-input
                alternative
                class="mb-3"
                type="email"
                v-model="leadInfo.email"
                :valid="m_hasValidEmail()"
                placeholder="Correo electrónico"
                addon-left-icon="ni ni-email-83"
            ></base-input>
            <base-input
                alternative
                type="Phone"
                v-model="leadInfo.phone"
                :valid="m_hasValidPhone()"
                placeholder="Teléfono"
                addon-left-icon="fa fa-phone"
            ></base-input>
            <div v-if="leadSent" class="text-center text-muted mb-4">
              <small id="spanGothamBookLead2"
              >Para recibir el cálculo de tu descuento enviamos un código por
                WhatsApp colócalo para continuar.</small
              >
            </div>
            <base-input
                alternative
                v-model="leadValidationCode"
                v-if="leadSent"
                :valid="leadValidationCode.length === 4"
                placeholder="Código de verificación"
                addon-left-icon="fa fa-check"
            ></base-input>
            <div class="text-justify" id="spanGothamBookLead">
              <p>
                Al continuar, aceptas los
                <a
                    style="color: #f3d338"
                    href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/TERMINOS+Y+CONDICIONES+GRUPO+DB+MENOS+2018.pdf"
                    target="_blank"
                >Términos y condiciones</a
                >. Consulta nuestro
                <a
                    style="color: #f3d338"
                    href="https://s3-us-west-1.amazonaws.com/dbmenos/documentos/AVISO+DE+PRIVACIDAD+GRUPO+DB+MENOS+2018.pdf"
                    target="_blank"
                >Aviso de privacidad</a
                >
              </p>
            </div>
            <!--
            <div>
              <base-checkbox class="mb-3" v-model="sameBankDebtPaysheet"
                >Recibo mi nómina en mismo banco que tengo mis
                deudas</base-checkbox
              >
            </div>
            !-->
            <div class="text-center">
              <base-button
                  type="primary"
                  class="my-4"
                  id="btnWantLead"
                  v-on:click="m_insertLead()"
                  v-bind:disabled="
                  !m_hasValidName() || !m_hasValidPhone() || !m_hasValidEmail()
                "
              >Quiero mi descuento
              </base-button
              >
            </div>
          </form>
        </template>
      </card>
    </modal>

  </div>
</template>
<script>
import Vue from "vue";
import modal from "@/components/Modal";
import {V} from "@/V";
import VueFacebookPixel from "vue-analytics-facebook-pixel";
import {LazyYoutube} from "vue-lazytube";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";

Vue.component("VueSlider", VueSlider);

Vue.use(VueFacebookPixel);
export default {
  name: "registerNew",
  components: {
    modal,
    VueFacebookPixel,
    LazyYoutube,
    VueSlider,
  },
  created() {
    fbq("track", "PageView");
    fbq("track", "page_view");
    //this.createMarketingLandingMobile1();
    this.m_insertMarketingLead();
    this.m_getBanks();
    this.width = "90%";
    this.height = 10;

    this.processStyle = {
      backgroundColor: "#f3d338",
    };
    this.sliderStyle = {
      width: "18px",
      height: "18px",
      backgroundColor: "#f3d338",
    };

  },
  data() {
    return {
      year: new Date().getFullYear(),
      styleObject: {
        background: "red",
      },
      V: V,
      disabled: true,
      dataMark: "",
      from: null,
      typeapp: {
        appStore: 0,
        playStore: 0,
      },
      simpleCalc: {
        amount: 160,
        banks: [],
      },
      banks: [],
      bancos: [],
      leadInfo: {
        name: "",
        email: "",
        phone: "",
      },
      leadValidationCode: "",
      leadSent: false,
      sameBankDebtPaysheet: false,
      leadModal: false,
      discountModal: false,
      //estimatedDiscountModal: false,
      averageDiscount: 0,
      videosModal: false,
      banksSelected: [],
    };
  },
  methods: {
    // PATH ?utm_source=SOURCE&utm_medium=MEDIUM&utm_campaign=CAMPAIGN&utm_term=TERM&utm_content=CONTENT
    getBank(id) {
      console.log(id);
      for (let i = 0; i < this.banks.length; i++)
        if (this.banks[i].id === id) return this.banks[i];
      return null;
    },
    async m_insertMarketingLead() {
      let marketing =
          this.$route.query.utm_source +
          "_" +
          this.$route.query.utm_medium +
          "_" +
          this.$route.query.utm_campaign;
      if (marketing == "undefined_undefined_undefined") {
        marketing = null;
      } else if (
          marketing ==
          this.$route.query.utm_source + "_undefined_undefined"
      ) {
        marketing = this.$route.query.utm_source;
      } else if (
          marketing ==
          this.$route.query.utm_source +
          "_" +
          this.$route.query.utm_medium +
          "_undefined"
      ) {
        marketing =
            this.$route.query.utm_source + "_" + this.$route.query.utm_medium;
      } else if (
          marketing ==
          this.$route.query.utm_source +
          "_undefined_" +
          this.$route.query.utm_campaign
      ) {
        marketing =
            this.$route.query.utm_source + "_" + this.$route.query.utm_campaign;
      } else {
        marketing = marketing;
      }
      console.log(marketing);
      this.$store.commit("setMarketing", marketing);
      let url = V.SERVER + V.OPS.INSERT + V.TABLES.MARKETING_LEADS;
      await this.$axios.post(url, {from: marketing});
    },
    getVideos() {
      this.videosModal = true;
    },
    m_getDiscount() {
      if (!this.averageDiscount == 0) {
        this.discountModal = true;
        this.banksSelected = [];
        this.banks.forEach((b) => {
          if (b.selected) {
            this.banksSelected.push(b);
          }
        });
        console.log(this.banksSelected);
      } else {
        this.$notify({
          text: "Debes seleccionar un banco para poder continuar",
          type: "error",
        });
      }
    },
    m_startinglead() {
      this.leadModal = true;
      //this.discountModal = false;
      Vue.analytics.fbq.event("StartingLead", {starting: true});
      gtag("event", "Escoger-monto-deuda", {
        event_category: "Landing-liquidar-deuda-1",
        event_label: "escoger-monto",
      });
      console.log("Start Lead");
    },
    m_hasValidEmail() {
      let regex = new RegExp(
          "[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+(?:[A-Z]{2}|com|es|org|net|gov|mil|biz|info|mobi|name|aero|jobs|museum)\\b"
      );
      return regex.test(this.leadInfo.email);
    },
    m_hasValidPhone() {
      return (
          !isNaN(this.leadInfo.phone) &&
          this.leadInfo.phone.length > 9 &&
          this.leadInfo.phone.length < 11
      );
    },
    m_hasValidName() {
      return this.leadInfo.name.length > 2;
    },
    m_getBanks() {
      let url = V.SERVER + V.OPS.SELECT + V.TABLES.BANKS_LANDINGS;
      this.$http.post(url, {}).then((res) => {
        if (res.body.success) {
          this.banks = res.body.rows;
          this.banks.forEach((b) => {
            b.selected = false;
          });
        }
      });
    },
    m_switchSelected(index) {
      let bank = this.banks[index];
      bank.selected = !bank.selected;
      console.log(bank);
      Vue.set(this.banks, index, bank);
      let count = 0;
      let neg = 0;
      this.banks.forEach((bank) => {
        if (bank.selected) {
          count += 1;
          neg += bank.neg;
        }
      });
      if (count > 0) {
        this.averageDiscount = neg / count;
      } else {
        this.averageDiscount = 0;
      }
    },
    m_insertLead() {
      if (this.leadSent) {
        let marketing =
            this.$route.query.utm_source +
            "_" +
            this.$route.query.utm_medium +
            "_" +
            this.$route.query.utm_campaign;
        if (marketing == "undefined_undefined_undefined") {
          marketing = null;
        } else if (
            marketing ==
            this.$route.query.utm_source + "_undefined_undefined"
        ) {
          marketing = this.$route.query.utm_source;
        } else if (
            marketing ==
            this.$route.query.utm_source +
            "_" +
            this.$route.query.utm_medium +
            "_undefined"
        ) {
          marketing =
              this.$route.query.utm_source + "_" + this.$route.query.utm_medium;
        } else if (
            marketing ==
            this.$route.query.utm_source +
            "_undefined_" +
            this.$route.query.utm_campaign
        ) {
          marketing =
              this.$route.query.utm_source + "_" + this.$route.query.utm_campaign;
        } else {
          marketing = marketing;
        }
        let _data = {
          phone: this.leadInfo.phone,
          code: this.leadValidationCode,
          marketing: marketing,
        };

        let calculate = {
          estimated: this.averageDiscount,
          total: this.simpleCalc.amount,
        };

        let _dataLead = {
          name: this.leadInfo.name,
          email: this.leadInfo.email,
          phone: this.leadInfo.phone,
          amount: this.simpleCalc.amount * 1000,
          banks: [],
          same_bank_debt_paysheet: this.sameBankDebtPaysheet,
          device: "web",
        };
        Vue.analytics.fbq.event("LeadComplete", {
          content_name:
              this.leadInfo.name +
              " " +
              this.leadInfo.phone +
              " lead validated successfully",
        });

        let url = V.SERVER + V.OPS.VALIDATE_LEAD_SMS_CODE;
        this.$http.post(url, _data).then(
            (res) => {
              if (res.data.success === true) {
                this.leadModal = false;
                this.leadSent = false;
                console.log(marketing);
                localStorage.setItem("phone", JSON.stringify(_data));
                localStorage.setItem("calculate", JSON.stringify(calculate));
                localStorage.setItem("Lead", JSON.stringify(_dataLead));
                localStorage.setItem("marketing", marketing);

                //Flujo anterior
                //this.$router.push({path: "/regis"});

                //Flujo nuevo
                this.$router.push({name: "plan-personalizado"});                
                window.location.reload();
                console.log(this.leadModal);
                gtag("event", "Datos-validados", {
                  event_category: "Landing-liquidar-deuda-1",
                  event_label: "datos-validados",
                });
              }
            },
            (er) => {
              //                        console.log("err")
              //                        console.log(er)
            }
        );
      } else {
        this.leadSent = true;
        let marketing =
            this.$route.query.utm_source +
            "_" +
            this.$route.query.utm_medium +
            "_" +
            this.$route.query.utm_campaign;
        if (marketing == "undefined_undefined_undefined") {
          marketing = null;
        } else if (
            marketing ==
            this.$route.query.utm_source + "_undefined_undefined"
        ) {
          marketing = this.$route.query.utm_source;
        } else if (
            marketing ==
            this.$route.query.utm_source +
            "_" +
            this.$route.query.utm_medium +
            "_undefined"
        ) {
          marketing =
              this.$route.query.utm_source + "_" + this.$route.query.utm_medium;
        } else if (
            marketing ==
            this.$route.query.utm_source +
            "_undefined_" +
            this.$route.query.utm_campaign
        ) {
          marketing =
              this.$route.query.utm_source + "_" + this.$route.query.utm_campaign;
        } else {
          marketing = marketing;
        }
        let headers = {
          device: "web",
        };
        let _data = {
          name: this.leadInfo.name,
          email: this.leadInfo.email,
          phone: this.leadInfo.phone,
          amount: this.simpleCalc.amount * 1000,
          banks: [],
          same_bank_debt_paysheet: this.sameBankDebtPaysheet,
          device: "web",
        };
        if (marketing) {
          _data.marketing = marketing;
        }
        if (_data.amount > 100000) {
          _data.amount = this.simpleCalc.amount * 2000;
        }
        Vue.analytics.fbq.event("LeadStarting", {
          content_name:
              this.leadInfo.name +
              " " +
              this.leadInfo.phone +
              " lead successful started",
        });
        let url = V.SERVER + V.OPS.INSERT + V.TABLES.LEADS;
        gtag("event", "conversion", {
          send_to: "AW-718930865/2GivCITQvKcBELGH6NYC",
        });
        gtag("event", "Datos-contacto", {
          event_category: "Landing-liquidar-deuda-1",
          event_label: "datos-contacto",
        });
        this.banks.forEach((bank) => {
          if (bank.selected) _data.banks.push(bank.id);
        });
        _data.banks = JSON.stringify(_data.banks);
        this.$http.post(url, _data, {headers: headers}).then(
            (res) => {
              if (res.data.success) {
                this.leadSent = res.data.success;
              } else {
                alert("Algo salió mal :(, intenta nuevamente");
              }
            },
            (er) => {
              //                        console.log("err")
              //                        console.log(er)
            }
        );
      }
    },


    openFB() {
      window.open("https://www.facebook.com/grupodbmenos/")
    },
    openInstagram() {
      window.open("https://www.instagram.com/db_menos/")
    },
    openWhatsApp() {
      window.open("https://api.whatsapp.com/send/?phone=%2B5215544401008&text=Hola%21+me+interesa+informaci%C3%B3n+de+DB+Menos&app_absent=0")
    },
    openYouTube() {
      window.open("https://www.youtube.com/channel/UC47Sg1_7oDmNhWEcPlgi1kw")
    },
    openTwitter() {
      window.open("https://www.facebook.com/grupodbmenos/")
    },
    openIn() {
      window.open("https://www.linkedin.com/company/grupo-db-menos/")
    },
    openBlog() {
      window.open("https://www.dbmenos.com/blogs")
    },
    openForbes() {
      window.open("https://www.forbes.com.mx/un-salvavidas-digital-para-tus-deudas-en-las-tarjetas-de-credito/")
    },
    openEconomista() {
      window.open("https://www.eleconomista.com.mx/finanzaspersonales/Liquide-su-deuda-con-reparadoras-de-credito-online-20181105-0074.html")
    },
    openHeraldo() {
      window.open("https://www.youtube.com/watch?v=dIpm0veIGBc")
    },
    openNacionalFin() {
      window.open("https://www.gob.mx/nafin/prensa/premia-nafin-a-ganadores-de-applicando-mexico-2018?idiom=es")
    },
  },
};
</script>

<style>
@font-face {
  font-family: OpenSans-Bold;
  src: url(/fonts/HomepageNew/OpenSans-Bold.ttf);
}

@font-face {
  font-family: OpenSans-Light;
  src: url(/fonts/HomepageNew/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSans-Regular;
  src: url(/fonts/HomepageNew/OpenSans-Regular.ttf);
}

@font-face {
  font-family: Gotham-ultra;
  src: url(/fonts/Gotham_Font/gotham-ultra.ttf);
}

@font-face {
  font-family: OpenSans-ExtraBold;
  src: url(/fonts/HomepageNew/OpenSans-ExtraBold.ttf);
}

/*  SIZE XS */

/* Section principal */
.img-principal-xs {
  width: 115%;
  height: 100%;
}

.img-principal-xs img {
  width: 100%;
  height: 100%;
}


.containerPrincipalxs {
  color: white;
  position: absolute;
  top: 60%;
  left: 20px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titlePxs {
  font-size: 10pt;
  font-family: OpenSans-Light;
  text-align: left;
}

.titleBxs {
  font-family: OpenSans-Bold;
}

.background-calcxs {
  width: 100%;
  height: auto;
  margin-top: -4rem;
  padding: 5%;
  margin-bottom: 5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/HomepageNew/Mobile/Calculadora fondo azul móvil.png");
}

.spanCalcDiscountxs {
  font-family: OpenSans-Bold;
  color: white;
  font-size: 20pt;
}

.spanMoneyDebtxs {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 15pt;
  margin-bottom: 2rem;
  border-radius: 10px;
  animation: pulse2 2s infinite;
  width: 90%;
  margin: 0 auto;
}

.custom-dot-xs {
  padding: 20%;
  margin-top: -0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eab740;
}

.h2Amount-xs {
  color: #ffff;
  letter-spacing: 0.2rem;
  margin-top: -4%;
  font-family: Gotham-Ultra;
  margin-bottom: -1rem;
  font-size: 30pt;
}

.span-who-debt-xs {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 15pt;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.span-institution-xs {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 10pt;
  margin-top: -2rem;
}

#btn-calc-xs {
  background-color: #f2d238;
  color: #2a3d9a;
  border: none;
  font-size: 10pt;
  margin-bottom: 2rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section principal */

/* Section info Asesor virtual o web */

.info-asist-web-xs {
  height: 100%;
  padding: 10rem;
  margin-top: -12rem;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-first-title-info-div-xs {
  color: #2a3d9a;
  position: absolute;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-fist-text-xs {
  text-align: left;
  font-size: 20pt;
  font-family: OpenSans-Bold;
}

.text-second-title-info-div-xs {
  color: #808080;
  position: absolute;
  top: 55px;
  left: 10%;
  margin-right: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-second-text-xs {
  text-align: left;
  font-size: 13pt;
  line-height: 25px;
  font-family: OpenSans-Regular;
}

.text-device-xs {
  font-family: OpenSans-Bold;
}

#container-info-xs {
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  /* Center vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}

.barra-gradient-xs {
  width: 110%;
  height: 100px;
  background: rgb(42, 61, 153);
  background: linear-gradient(
      90deg,
      rgba(42, 61, 153, 1) 0%,
      rgba(41, 170, 225, 1) 100%
  );
  /* Center vertically and horizontally */
  position: absolute;
  top: 220px;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}

.div-imgs-barra-xs {
  position: absolute;
  top: 150px;
  left: -60px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-barra-xs {
  height: 100%;
  padding: 20px;
  margin-left: -20px;
  margin-right: -20px;
}

.div-instruction-xs {
  color: #808080;
  font-family: OpenSans-Regular;
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-first-intruction-xs {
  padding: 10px;
  font-size: 15pt;
  line-height: 25px;
  font-family: OpenSans-Regular;
}

#btn-prueb-xs {
  background-color: #2a3d99;
  color: white;
  border: none;
  border-radius: 10px;
  width: 60%;
  font-size: 13pt;
  margin-bottom: 0.5rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section info Asesor virtual o web */

/* SECTION OBTIENES PROGRAMA */

#container-get-program-xs {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-text-title-get-program-xs {
  position: absolute;
  top: 0;
  left: 5%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-title-text-get-program-xs {
  text-align: left;
  font-size: 18pt;
  padding: 10px;
  color: white;
  font-family: OpenSans-Bold;
}

.container-imgs-get-program-xs {
  color: white;
  margin-top: 22%;
  margin-bottom: 10%;
}

#container-imgs-get-program-xs {
  padding: 1rem 1rem;
  color: white;
  font-family: OpenSans-Regular;
  margin: 1rem;
}

.div-imgs-get-xs {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.div-imgs-get2-xs {
  width: 200px;
  margin: -40px auto;
}

.div-col-imgs-get-xs {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-get-xs {
  padding: 10pt;
  margin: 0;
  display: inline-block !important;
}

#p-imgs-xs {
  padding: 10px;
  font-size: 12pt;
}

/* END SECTION OBTIENES PROGRAMA */

/* SECTION QUE SE NECESITA*/

#container-what-need-xs {
  align-items: center;
  padding: 5%;
  background-color: white;
  height: 100%;
}

.div-what-need-xs {
  padding: 0 1rem;
  margin: 1rem;
}

.span-title-need-xs {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #2a3d99;
  font-family: OpenSans-Bold;
  font-size: 18pt;
}

.span-options-need-xs {
  width: 100%;
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 12pt;
}

.hr-what-need-xs {
  text-align: center;
  margin: 0 30px;
  border: 1px solid #2a3d99;
}

.div-img-avatar-xs {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* END SECTION QUE SE NECESITA*/

/* SECTION GRADUADOS*/

#container-graduated-xs {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-title-graduated-xs {
  color: white;
}

.span-title-graduated-xs {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
  font-family: OpenSans-Bold;
  font-size: 20pt;
}

.p-title-graduated-xs {
  width: 100%;
  margin-bottom: 30px;
  font-family: OpenSans-Regular;
  font-size: 12pt;
}

.div-coments-graduated-xs {
  padding: 0 1rem;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-coments-graduated-xs {
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 18pt;
}

/* END SECTION GRADUADOS*/

/* SECTION PRENSA */

#container-prensa-xs {
  align-items: center;
  text-align: center;
  padding: 5%;
  background-color: white;
  height: 100%;
}

.div-title-prensa-xs {
  color: #2a3d99;
  padding: 10px;
}

.span-title-prensa-xs {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -20px;
  font-family: OpenSans-Bold;
  font-size: 20pt;
}

#container-imgs-prensa-xs {
  color: white;
  padding: 5px;
  font-family: OpenSans-Regular;
}

.div-imgs-prensa-xs {
  margin-left: auto;
  margin-right: auto;
}

#container-imgs-logos-redes-xs {
  color: white;
  padding: 5px;
  font-family: OpenSans-Regular;
}

.div-imgs-logos-redes-xs {
  margin-left: auto;
  margin-right: auto;
}

.div-col-imgs-logos-redes-xs {
  width: 40%;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.div-col-imgs-prensa-xs {
  width: 50%;
  text-align: center;
  padding: 1px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-prensa-xs {
  width: 100%;
  margin: 0;
  padding: 5px;
}

.div-title-siguenos-xs {
  color: #000000;
}

.span-title-siguenos-xs {
  width: 100%;
  margin-top: 1px;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Bold;
  font-size: 15pt;
}

.hr-footer-xs {
  text-align: center;
  margin: 0 5px;
  border: 0.5px solid gray;
}


/* END SECTION PRENSA */

/*SECTION FOOTER*/


.div-title-download-xs {
  padding: 30px;
}

.div-icons-apps-xs {
  padding: 1px;
}

.span-title-download-xs {
  color: #000000;
  font-family: OpenSans-Bold;
  margin-bottom: 10px;
  font-size: 15pt;
}

.div-footer-text-xs {
  padding: 5px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Bold;
}

.text-footer-xs {
  margin-left: 5px;
  margin-right: 5px;
  font-family: OpenSans-Bold;
  color: black;
  font-size: 10pt;
}

.text-footer-direction-xs {
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Regular;
  color: black;
  padding: 5px;
  margin-bottom: 20px;
  font-size: 10pt;
}

/*  END SIZE XS */


/*  SIZE SM */

/* Section principal */
.img-principal-sm {
  width: 115%;
  height: 100%;
}

.img-principal-sm img {
  width: 100%;
  height: auto;
}


.containerPrincipalsm {
  color: white;
  position: absolute;
  top: 60%;
  left: 2px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titlePsm {
  font-size: 22pt;
  font-family: OpenSans-Light;
  text-align: left;
}

.titleBsm {
  font-family: OpenSans-Bold;
}

.background-calcsm {
  width: 100%;
  height: auto;
  margin-top: -4rem;
  padding: 5%;
  margin-bottom: 5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/HomepageNew/Mobile/Calculadora fondo azul móvil.png");
}

.spanCalcDiscountsm {
  font-family: OpenSans-Bold;
  color: white;
  font-size: 25pt;
}

.spanMoneyDebtsm {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 18pt;
  margin-bottom: 2rem;
  border-radius: 10px;
  animation: pulse2 2s infinite;
  width: 50%;
  margin: 0 auto;
}

.custom-dot-sm {
  padding: 20%;
  margin-top: -0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eab740;
}

.h2Amount-sm {
  color: #ffff;
  letter-spacing: 0.2rem;
  margin-top: -4%;
  font-family: Gotham-Ultra;
  margin-bottom: -1rem;
  font-size: 35pt;
}

.span-who-debt-sm {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 18pt;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.span-institution-sm {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 14pt;
  margin-top: -2rem;
}

#btn-calc-sm {
  background-color: #f2d238;
  color: #2a3d9a;
  border: none;
  font-size: 15pt;
  margin-bottom: 2rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section principal */

/* Section info Asesor virtual o web */

.info-asist-web-sm {
  height: 100%;
  padding: 10rem;
  margin-top: -12rem;
  margin-bottom: 10rem;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-first-title-info-div-sm {
  color: #2a3d9a;
  position: absolute;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-fist-text-sm {
  text-align: left;
  font-size: 25pt;
  font-family: OpenSans-Bold;
}

.text-second-title-info-div-sm {
  color: #808080;
  position: absolute;
  top: 70px;
  left: 10%;
  margin-right: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-second-text-sm {
  text-align: left;
  font-size: 20pt;
  line-height: 30px;
  font-family: OpenSans-Regular;
}

.text-device-sm {
  font-family: OpenSans-Bold;
}

#container-info-sm {
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  /* Center vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}

.barra-gradient-sm {
  width: 110%;
  height: 100px;
  background: rgb(42, 61, 153);
  background: linear-gradient(
      90deg,
      rgba(42, 61, 153, 1) 0%,
      rgba(41, 170, 225, 1) 100%
  );
  /* Center vertically and horizontally */
  position: absolute;
  top: 280px;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}

.div-imgs-barra-sm {
  position: absolute;
  top: 190px;
  left: -60px;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-barra-sm {
  height: 100%;
  padding: 10px;
}

.div-instruction-sm {
  color: #808080;
  font-family: OpenSans-Regular;
  position: absolute;
  top: 220px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-first-intruction-sm {
  padding: 20px;
  font-size: 20pt;
  line-height: 35px;
  font-family: OpenSans-Regular;
}

#btn-prueb-sm {
  background-color: #2a3d99;
  color: white;
  border: none;
  border-radius: 10px;
  width: 60%;
  font-size: 15pt;
  margin-bottom: 0.5rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section info Asesor virtual o web */

/* SECTION OBTIENES PROGRAMA */

#container-get-program-sm {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-text-title-get-program-sm {
  position: absolute;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-title-text-get-program-sm {
  text-align: left;
  font-size: 30pt;
  padding: 10px;
  color: white;
  font-family: OpenSans-Bold;
}

.container-imgs-get-program-sm {
  color: white;
  margin-top: 25%;
  margin-bottom: 10%;
}

#container-imgs-get-program-sm {
  padding: 1rem 1rem;
  color: white;
  font-family: OpenSans-Regular;
  margin: 1rem;
}

.div-imgs-get-sm {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.div-imgs-get2-sm {
  width: 200px;
  margin: -40px auto;
}

.div-col-imgs-get-sm {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-get-sm {
  padding: 10pt;
  margin: 0;
  display: inline-block !important;
}

#p-imgs-sm {
  padding: 10px;
  font-size: 15pt;
}

/* END SECTION OBTIENES PROGRAMA */

/* SECTION QUE SE NECESITA*/

#container-what-need-sm {
  align-items: center;
  padding: 5%;
  background-color: white;
  height: 100%;
}

.div-what-need-sm {
  padding: 0 1rem;
  margin: 1rem;
}

.span-title-need-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #2a3d99;
  font-family: OpenSans-Bold;
  font-size: 23pt;
}

.span-options-need-sm {
  width: 100%;
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 18pt;
}

.hr-what-need-sm {
  text-align: center;
  margin: 0 30px;
  border: 1px solid #2a3d99;
}

.div-img-avatar-sm {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* END SECTION QUE SE NECESITA*/

/* SECTION GRADUADOS*/

#container-graduated-sm {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-title-graduated-sm {
  color: white;
}

.span-title-graduated-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
  font-family: OpenSans-Bold;
  font-size: 25pt;
}

.p-title-graduated-sm {
  width: 100%;
  margin-bottom: 30px;
  font-family: OpenSans-Regular;
  font-size: 15pt;
}

.div-coments-graduated-sm {
  padding: 0 1rem;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-coments-graduated-sm {
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 18pt;
}

/* END SECTION GRADUADOS*/

/* SECTION PRENSA */

#container-prensa-sm {
  align-items: center;
  text-align: center;
  padding: 5%;
  background-color: white;
  height: 100%;
}

.div-title-prensa-sm {
  color: #2a3d99;
  padding: 10px;
}

.span-title-prensa-sm {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -40px;
  font-family: OpenSans-Bold;
  font-size: 30pt;
}

#container-imgs-prensa-sm {
  color: white;
  padding: 5px;
  font-family: OpenSans-Regular;
}

.div-imgs-prensa-sm {
  margin-left: auto;
  margin-right: auto;
}

.div-col-imgs-prensa-sm {
  width: 50%;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-prensa-sm {
  width: 100%;
  margin: 0;
  padding: 5px;
}

.div-title-siguenos-sm {
  color: #000000;
}

.span-title-siguenos-sm {
  width: 100%;
  margin-top: -5px;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Bold;
  font-size: 20pt;
}

.hr-footer-sm {
  text-align: center;
  margin: 0 5px;
  border: 0.5px solid gray;
}


/* END SECTION PRENSA */

/*SECTION FOOTER*/


.div-title-download-sm {
  padding: 30px;
}

.div-icons-apps-sm {
  padding: 20px;
}

.span-title-download-sm {
  color: #000000;
  font-family: OpenSans-Bold;
  margin-bottom: 10px;
  font-size: 20pt;
}

.div-footer-text-sm {
  padding: 1px;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Bold;
}

.text-footer-sm {
  font-family: OpenSans-Bold;
  color: black;
  font-size: 12pt;
}

.text-footer-direction-sm {
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Regular;
  color: black;
  margin-bottom: 20px;
  font-size: 12pt;
}

/*  END SIZE SM */

/*  SIZE MD */

/* Section principal */
.img-principal-md {
  width: 115%;
  height: 100%;
}

.img-principal-md img {
  width: 100%;
  height: auto;
}


.containerPrincipalmd {
  color: white;
  position: absolute;
  top: 60%;
  left: 5px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titlePmd {
  font-size: 30pt;
  font-family: OpenSans-Light;
  text-align: left;
}

.titleBmd {
  font-family: OpenSans-Bold;
}

.background-calcmd {
  width: 100%;
  height: auto;
  margin-top: -4rem;
  padding: 5%;
  margin-bottom: 5rem;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/HomepageNew/Mobile/Calculadora fondo azul móvil.png");
}

.spanCalcDiscountmd {
  font-family: OpenSans-Bold;
  color: white;
  font-size: 25pt;
}

.spanMoneyDebtmd {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 20pt;
  margin-bottom: 2rem;
  border-radius: 10px;
  animation: pulse2 2s infinite;
  width: 50%;
  margin: 0 auto;
}

.custom-dot-md {
  padding: 20%;
  margin-top: -0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eab740;
}

.h2Amount-md {
  color: #ffff;
  letter-spacing: 0.2rem;
  margin-top: -2%;
  font-family: Gotham-Ultra;
  margin-bottom: -1rem;
  font-size: 40pt;
}

.span-who-debt-md {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 20pt;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.span-institution-md {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 17pt;
  margin-top: -2rem;
}

#btn-calc-md {
  background-color: #f2d238;
  color: #2a3d9a;
  border: none;
  font-size: 100%;
  margin-bottom: 2rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section principal */

/* Section info Asesor virtual o web */

.info-asist-web-md {
  height: 100%;
  padding: 10rem;
  margin-top: -12rem;
  margin-bottom: 10rem;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-first-title-info-div-md {
  color: #2a3d9a;
  position: absolute;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-fist-text-md {
  text-align: left;
  font-size: 30pt;
  font-family: OpenSans-Bold;
}

.text-second-title-info-div-md {
  color: #808080;
  position: absolute;
  top: 70px;
  left: 10%;
  margin-right: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-second-text-md {
  text-align: left;
  font-size: 20pt;
  line-height: 30px;
  font-family: OpenSans-Regular;
}

.text-device-md {
  font-family: OpenSans-Bold;
}

#container-info-md {
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  /* Center vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}

.barra-gradient-md {
  width: 120%;
  height: 100px;
  background: rgb(42, 61, 153);
  background: linear-gradient(
      90deg,
      rgba(42, 61, 153, 1) 0%,
      rgba(41, 170, 225, 1) 100%
  );
  /* Center vertically and horizontally */
  position: absolute;
  top: 280px;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}

.div-imgs-barra-md {
  position: absolute;
  top: 160px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-barra-md {
  height: 100%;
  padding: 10px;
}

.div-instruction-md {
  color: #808080;
  font-family: OpenSans-Regular;
  position: absolute;
  top: 220px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-first-intruction-md {
  padding: 20px;
  font-size: 25pt;
  line-height: 40px;
  font-family: OpenSans-Regular;
}

#btn-prueb-md {
  background-color: #2a3d99;
  color: white;
  border: none;
  border-radius: 10px;
  width: 60%;
  font-size: 20pt;
  margin-bottom: 1rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section info Asesor virtual o web */

/* SECTION OBTIENES PROGRAMA */

#container-get-program-md {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-text-title-get-program-md {
  position: absolute;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-title-text-get-program-md {
  text-align: left;
  font-size: 30pt;
  padding: 10px;
  color: white;
  font-family: OpenSans-Bold;
}

.container-imgs-get-program-md {
  margin-top: 18%;
  margin-bottom: 10%;
}

#container-imgs-get-program-md {
  padding: 1rem 1rem;
  color: white;
  font-family: OpenSans-Regular;
  margin: 1rem;
}

.div-imgs-get-md {
  width: 200px;
  margin-left: auto;
  margin-right: auto;
}

.div-imgs-get2-md {
  width: 200px;
  margin: -80px auto;
}

.div-col-imgs-get-md {
  width: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-get-md {
  padding: 10pt;
  margin: 0;
  display: inline-block !important;
}

#p-imgs-md {
  padding: 10px;
  font-size: 15pt;
}

/* END SECTION OBTIENES PROGRAMA */

/* SECTION QUE SE NECESITA*/

#container-what-need-md {
  align-items: center;
  padding: 5%;
  background-color: white;
  height: 100%;
}

.div-what-need-md {
  padding: 0 1rem;
  margin: 1rem;
}

.span-title-need-md {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #2a3d99;
  font-family: OpenSans-Bold;
  font-size: 28pt;
}

.span-options-need-md {
  width: 100%;
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 20pt;
}

.hr-what-need-md {
  text-align: center;
  margin: 0 30px;
  border: 1px solid #2a3d99;
}

.div-img-avatar-md {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* END SECTION QUE SE NECESITA*/

/* SECTION GRADUADOS*/

#container-graduated-md {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-title-graduated-md {
  color: white;
}

.span-title-graduated-md {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
  font-family: OpenSans-Bold;
  font-size: 25pt;
}

.p-title-graduated-md {
  width: 100%;
  margin-bottom: 30px;
  font-family: OpenSans-Regular;
  font-size: 15pt;
}

.div-coments-graduated-md {
  padding: 0 1rem;
  margin: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-coments-graduated-md {
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 18pt;
}

/* END SECTION GRADUADOS*/

/* SECTION PRENSA */

#container-prensa-md {
  align-items: center;
  text-align: center;
  padding: 1%;
  background-color: white;
  height: 100%;
}

.div-title-prensa-md {
  color: #2a3d99;
}

.span-title-prensa-md {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -40px;
  font-family: OpenSans-Bold;
  font-size: 30pt;
}

#container-imgs-prensa-md {
  color: white;
  padding: 5px;
  font-family: OpenSans-Regular;
}

.div-imgs-prensa-md {
  margin-left: auto;
  margin-right: auto;
}

.div-col-imgs-prensa-md {
  width: 50%;
  text-align: center;
  padding: 0;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-prensa-md {
  width: 100%;
  margin: 0;
  padding: 5px;
}

.div-title-siguenos-md {
  color: #000000;
}

.span-title-siguenos-md {
  width: 100%;
  margin-top: -5px;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Bold;
  font-size: 20pt;
}

.hr-footer-md {
  text-align: center;
  margin: 0 20px;
  border: 0.5px solid gray;
}


/* END SECTION PRENSA */

/*SECTION FOOTER*/


.div-title-download-md {
  padding: 30px;
}

.div-icons-apps-md {
  padding: 20px;
}

.span-title-download-md {
  color: #000000;
  font-family: OpenSans-Bold;
  margin-bottom: 10px;
  font-size: 20pt;
}

.div-footer-text-md {
  padding: 1px;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Bold;
}

.text-footer-md {
  font-family: OpenSans-Bold;
  color: black;
  font-size: 12pt;
}

.text-footer-direction-md {
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Regular;
  color: black;
  margin-bottom: 20px;
  font-size: 12pt;
}

/*  END SIZE MD */

/*  SIZE XL */

/* Section principal */

#img-principal-xl {
  height: 100%;
  padding: 7%;
  margin-left: -4rem;
  background-color: #ebebeb;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/HomepageNew/Desktop/Mujer hispana escritorio.png");
}

.containerPrincipalxl {
  color: white;
  position: absolute;
  top: 75%;
  left: 20px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.titlePxl {
  font-size: 25pt;
  font-family: OpenSans-Light;
  text-align: left;
}

.titleBxl {
  font-family: OpenSans-Bold;
}

.background-calcxl {
  margin-left: 8rem;
  height: 120%;
  margin-top: -3.5rem;
  border-radius: 2rem;
  width: 80%;
  padding: 4%;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://dbmenos.com/img/HomepageNew/Desktop/Calculadora fondo azul.png");
}

.spanCalcDiscountxl {
  font-family: OpenSans-Bold;
  color: white;
  font-size: 18pt;
}

.spanMoneyDebtxl {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 15pt;
  margin-bottom: 2rem;
  border-radius: 10px;
  animation: pulse2 2s infinite;
  width: 60% !important;
  margin: 0 auto;
}

.custom-dot-xl {
  padding: 20%;
  margin-top: -0.5rem;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #eab740;
}

.h2Amount-xl {
  color: #ffff;
  letter-spacing: 0.2rem;
  margin-top: -2%;
  font-family: Gotham-Ultra;
}

.span-who-debt-xl {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 15pt;
  margin-bottom: 1rem;
  margin-top: -0.5rem;
}

.span-institution-xl {
  font-family: OpenSans-Regular;
  color: white;
  font-size: 12pt;
  margin-top: -2rem;
}

#btn-calc-xl {
  background-color: #f2d238;
  color: #2a3d9a;
  border: none;
  font-size: 100%;
  margin-bottom: -1.5rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section principal */

/* Section info Asesor virtual o web */

.info-asist-web-xl {
  height: 100%;
  padding: 10rem;
  margin-top: -12rem;
  margin-bottom: 10rem;
  background-color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

.text-first-title-info-div-xl {
  color: #2a3d9a;
  position: absolute;
  top: 0;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-fist-text-xl {
  text-align: left;
  font-size: 35pt;
  font-family: OpenSans-Bold;
}

.text-second-title-info-div-xl {
  color: #808080;
  position: absolute;
  top: 70px;
  left: 10%;
  margin-right: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-second-text-xl {
  text-align: left;
  font-size: 20pt;
  line-height: 45px;
  font-family: OpenSans-Regular;
}

.text-device-xl {
  font-family: OpenSans-Bold;
}

#container-info-xl {
  font-size: 24px;
  margin: 25px;
  width: 350px;
  height: 200px;
  /* Center vertically and horizontally */
  display: flex;
  justify-content: center;
  align-items: center;
}

.barra-gradient-xl {
  width: 120%;
  height: 100px;
  background: rgb(42, 61, 153);
  background: linear-gradient(
      90deg,
      rgba(42, 61, 153, 1) 0%,
      rgba(41, 170, 225, 1) 100%
  );
  /* Center vertically and horizontally */
  position: absolute;
  top: 280px;
  margin: -25px 0 0 -25px; /* Apply negative top and left margins to truly center the element */
}

.div-imgs-barra-xl {
  position: absolute;
  top: 90px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-barra-xl {
  height: 80%;
  padding: 20px;
  margin: 70px;
}

.div-instruction-xl {
  color: #808080;
  font-family: OpenSans-Regular;
  position: absolute;
  top: 220px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.span-first-intruction-xl {
  text-align: left;
  padding: 20px;
  font-size: 22pt;
  line-height: 30px;
  font-family: OpenSans-Regular;
}

#btn-prueb-xl {
  background-color: #2a3d99;
  color: white;
  border: none;
  width: 60%;
  font-size: 100%;
  margin-bottom: -1.5rem;
  font-family: OpenSans-ExtraBold;
}

/* END Section info Asesor virtual o web */

/* SECTION OBTIENES PROGRAMA */

#container-get-program-xl {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-text-title-get-program-xl {
  position: absolute;
  top: -40px;
  left: 10%;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: left;
  align-items: left;
}

.span-title-text-get-program-xl {
  text-align: left;
  font-size: 25pt;
  padding: 10px;
  color: white;
  font-family: OpenSans-Bold;
}

#container-imgs-get-program-xl {
  padding: 1rem 1rem;
  color: white;
  font-family: OpenSans-Regular;
  margin: 1rem;
}

.div-imgs-get-xl {
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.div-imgs-get2-xl {
  width: 200px;
  margin: -80px auto;
}

.div-col-imgs-get-xl {
  width: 20%;
  text-align: center;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-get-xl {
  padding: 0;
  margin: 0;
  display: inline-block !important;
}

#p-imgs-xl {
  padding: 10px;
  font-size: 13pt;
}

/* END SECTION OBTIENES PROGRAMA */

/* SECTION QUE SE NECESITA*/

#container-what-need-xl {
  align-items: center;
  padding: 5%;
  background-color: white;
  height: 100%;
}

.div-what-need-xl {
  padding: 0 1rem;
  margin: 1rem;
}

.span-title-need-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  color: #2a3d99;
  font-family: OpenSans-Bold;
  font-size: 25pt;
}

.span-options-need-xl {
  width: 100%;
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 18pt;
}

.hr-what-need-xl {
  text-align: center;
  margin: 0 30px;
  border: 1px solid #2a3d99;
}

.div-img-avatar-xl {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* END SECTION QUE SE NECESITA*/

/* SECTION GRADUADOS*/

#container-graduated-xl {
  align-items: center;
  padding: 5%;
  background-color: #2a3d99;
  height: 100%;
}

.div-title-graduated-xl {
  color: white;
}

.span-title-graduated-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -5px;
  font-family: OpenSans-Bold;
  font-size: 30pt;
}

.p-title-graduated-xl {
  width: 100%;
  margin-bottom: 30px;
  font-family: OpenSans-Regular;
  font-size: 18pt;
}

.div-coments-graduated-xl {
  padding: 0 1rem;
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-coments-graduated-xl {
  margin: 0;
  color: #2a3d99;
  font-family: OpenSans-Regular;
  font-size: 18pt;
}

/* END SECTION GRADUADOS*/

/* SECTION PRENSA */

#container-prensa-xl {
  align-items: center;
  text-align: center;
  padding: 1%;
  background-color: white;
  height: 100%;
}

.div-title-prensa-xl {
  color: #2a3d99;
}

.span-title-prensa-xl {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: -40px;
  font-family: OpenSans-Bold;
  font-size: 35pt;
}

#container-imgs-prensa-xl {
  color: white;
  font-family: OpenSans-Regular;
}

.div-imgs-prensa-xl {
  margin-left: auto;
  margin-right: auto;
}

.div-col-imgs-prensa-xl {
  width: 25%;
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-content: center;
}

.cols-imgs-prensa-xl {
  width: 100%;
  margin: 20px;
}

.div-title-siguenos-xl {
  color: #000000;
}

.span-title-siguenos-xl {
  width: 100%;
  margin-top: -20px;
  margin-left: auto;
  margin-right: auto;
  font-family: OpenSans-Bold;
  font-size: 20pt;
}

.hr-footer-xl {
  text-align: center;
  margin: 0 20px;
  border: 0.5px solid gray;
}


/* END SECTION PRENSA */

/*SECTION FOOTER*/


.div-title-download-xl {
  padding: 20px;
}

.span-title-download-xl {
  color: #000000;
  font-family: OpenSans-Bold;
  margin-bottom: 10px;
  font-size: 15pt;
}

.div-footer-text-xl {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-content: center;
  font-family: OpenSans-Bold;
}

.text-footer-xl {
  font-family: OpenSans-Bold;
  color: black;
  font-size: 100%;
}

.text-footer-direction-xl {
  font-family: OpenSans-Regular;
  color: black;
  font-size: 100%;
}

/* END SIZE XL */
</style>
