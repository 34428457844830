<template>

  <div class="text-center">

    <v-dialog v-model="dialog" width="500">

      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
        >
          Click Me
        </v-btn>
      </template>

      <v-card>

        <v-card-text>

          <dropbox
              text
              :ocrFile="true"
              type="ID"
              :files="this.userFiles"
              v-on:uploadFile="uploadFileFrente"
              v-on:resentUploadFile="uploadFileFrente"
          ></dropbox>


        </v-card-text>

      </v-card>

    </v-dialog>

  </div>

</template>

<script>
import Dropbox from "@/components/Dropbox";
import {V} from "@/V";
export default {
  name: "IneImage",
  components: {Dropbox},
  props:
      {
        opc: 1
      },
  data: () => ({
    dialog: false,
  }),
  methods:
      {
        async uploadFileFrente(obj) {

          console.log("Image:", obj)

          let file = obj.file;
          let formData = new FormData();

          formData.append("file", file);

          //this.$showLoader(true);

          let body = {
            //phone: this.phone.phone ? this.phone.phone : this.phone.login,
            phone: this.client.phone,
            filename: obj.file.name,
          };

          await this.$axios
              .post(V.SERVER + V.PREREGISTER.UPLOAD_FILE, body)
              .then((response) => {
                let data = response.data;
                console.log("Data:", data)
                if (response.data.success) {
                  let fileURL = data[V.API.ROW].url;
                  this.$axios.put(fileURL, file).then((putObjectResponse) => {
                    if (putObjectResponse.status === 200) {
                      this.$notify({
                        text: "INE Cargada Correctamente",
                        type: "success",
                      });
                      this.$axios
                          .post(V.SERVER + V.PREREGISTER.PREUSER_OCR, body)
                          .then(async (response) => {
                            this.$showLoader(false);
                            if (response.data.success) {
                              let user = response.data.row;
                              let session = user.session;
                              //localStorage.removeItem("phone");
                              delete user.session;
                              this.$store.commit("setUser", user);
                              localStorage.setItem(
                                  this.$v.STORE_KEYS.SESSION,
                                  session.session
                              );
                              localStorage.setItem(
                                  this.$v.STORE_KEYS.USER,
                                  JSON.stringify(user)
                              );
                              this.$axios.defaults.headers.common[
                                  this.$v.STORE_KEYS.SESSION
                                  ] = session[this.$v.STORE_KEYS.SESSION];
                              this.$router.push({
                                path: "/start",
                              });
                            } else {
                              this.$showLoader(false);
                              this.$notify({
                                text: "Error, intenta nuevamente",
                                type: "warn",
                              });
                            }
                          })
                          .catch((e) => {
                            console.log(e);
                            this.$showLoader(false);
                            //alert("No se logró subir, intente nuevamente");
                          });
                    } else {
                      this.$notify({
                        text: "No se logró subir la INE",
                        type: "warn",
                      });
                    }
                  });
                } else {
                  this.$notify({
                    text: "Error, intenta nuevamente",
                    type: "warn",
                  });
                }
              })
              .catch((e) => {
                console.log(e);
                this.$showLoader(false);
                //alert("No se logró subir, intente nuevamente");
              });
        },
      }
}
</script>

<style scoped>

</style>